import Variable, {ValueType} from '../../lib/metadata';
import {dataRow} from '../../lib/data-types';
import classNames from '../../lib/class-names';
import {useRef, useState} from 'react';
import Popup from '../popup';

type Props = {
    variable: Variable,
    row: dataRow,
    colorIndex: number,
    onClick?: (row: dataRow) => void,
    left?: string
    sticky?: boolean
    enableSticky?: boolean
    lastSticky?: boolean
    allowPopup?: boolean
};

export default function Cell(props: Props) {
    const {variable, row, colorIndex, onClick, left, sticky, enableSticky, lastSticky, allowPopup} = props;
    const ref = useRef<HTMLTableCellElement>(null);
    const [showAll, setShowAll] = useState<boolean>(false);
    const value = variable.format(row);

    function alignRight() {
        let fieldRoleExclusionList = ['id', 'key'];
        let fieldRole = variable.getTag('FieldRole')?.toLowerCase();
        return variable.getValueType() === ValueType.Number && (!fieldRoleExclusionList.includes(fieldRole || ''));
    }

    const classes = classNames({
        'text-right': alignRight(),
        ['color-' + colorIndex]: colorIndex > -1,
        'sticky-col': sticky,
        'sticky': enableSticky
    });
    return <td data-testid="data-cell" ref={ref} className={classes} style={{left: `${left || 0}`}} onClick={() => typeof onClick === 'function' && onClick(row)} onMouseEnter={() => setShowAll(true)} onMouseLeave={() => setShowAll(false)}>
        <div className={`${lastSticky ? 'last-sticky' : ''} cell`}>
            {value}
        </div>
        {showAll && allowPopup && <Popup targetRef={ref} matchWidth>
            <div className="px-2 py-1 bg-gray-100 border-blue-300 border-2 rounded shadow-xl flex items-center">{value}</div>
        </Popup>}
    </td>;
}