import './checkbox.scss';
import classNames from '../../lib/class-names';

type Props = {
    checked: boolean,
    onChange: () => void,
    color?: string,
    className?: string,
    disabled?: boolean,
};

export default function Checkbox(props: Props) {
    const {checked, disabled, onChange, color, className} = props;
    const classes = classNames('checkbox', color, className, {active: checked, disabled});
    return <label className={classes} data-testid="checkbox-label" onClick={() => !disabled && onChange()}>
        {checked && <i className="icon-checkmark" />}
    </label>;
}