import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {canvas} from '../../lib/api';
import {useNavigate} from 'react-router';
import generateLink, {LinkType} from '../../lib/links';
import Loading from '../../components/loading';
import {QueryObj} from '../../lib/data-types';
import {deserialize, GraphVersions, TestType} from 'quantum-graph';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import fetchData from '../../lib/data';

export default function CanvasRedirect() {
    const {dataset, value} = useParams<{dataset: string, value: string}>();
    const navigate = useNavigate();

    const datasetConversions: {[key: string]: string} = {
        hospital: 'facility',
    };

    function handleFacilityDataset(canvasLink: any, value: string, signal: AbortSignal) {
        const graph = deserialize(generateFacilityQuery(value));
        fetchData(graph, signal).then(data => {
            let navigationUrl = `${canvasLink.link.url}?id=${value}`;
            const category = data[0].category as string;
            for (let categoryLink of canvasLink.categoryLinks) {
                if (categoryLink.title.toLowerCase() === category.toLowerCase())
                    navigationUrl = `${categoryLink.url}?id=${value}`;
            }
            navigate(navigationUrl);
        });
    }

    useEffect(() => {
        if (!dataset || !value)
            return;
        const controller = new AbortController();
        let canvasDataset = datasetConversions[dataset] ? datasetConversions[dataset] : dataset;
        canvas.getLink(canvasDataset, controller.signal).then(canvasLink => {
            if (canvasLink.link) {
                if (canvasDataset === 'facility')
                    handleFacilityDataset(canvasLink, value, controller.signal);
                else
                    navigate(`${canvasLink.link.url}?id=${value}`);
            } else
                navigate(generateLink(LinkType.OldDashboard, value, dataset));
        });

        return () => controller.abort();
    }, [dataset, value]);

    return <Loading large />;
}

function generateFacilityQuery(torchId: string): QueryObj {
    return {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: QueryType.Dim,
                tableNodes: [
                    {
                        id: 'facility',
                        dataset: 'facility',
                        columns: [
                            'category'
                        ]
                    }
                ]
            }
        },
        filters: [
            {
                type: 'basic',
                column: {
                    nodeId: 'facility',
                    column: 'torch_facility_id'
                },
                test: TestType.Eq,
                value: torchId
            }
        ]
    };
}