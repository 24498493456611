import {observer} from 'mobx-react';
import {ReactElement, useContext, useMemo} from 'react';
import {BarChartVis} from '../../../../../insight-container/charts/bar-chart';
import ChartContext, {ChartStore} from '../../../../../insight-container/charts/context';
import EditChart from '../../../../../insight-container/charts/edit-chart';
import {PieChartVis} from '../../../../../insight-container/charts/pie-chart';
import {PlotChartVis} from '../../../../../insight-container/charts/plot-chart';
import {CanvasInsertContext} from '../../../lib/context';
import CvParameterEdit from '../../cv-parameter-edit';
import CvQueryEditor from '../cv-query-editor';
import {QueryTableContext} from '../../../../../insight-container/query-table';
import QueryDataContext from '../../../../../insight-container/renderers/query-data-renderer/context';
import {ScatterChartVis} from '../../../../../insight-container/charts/scatter-plot';
import {ChartType} from '../../../../../insight-container/charts/common';

export const chartConfigs:Record<string, () => ReactElement> = {
    'pie': () => <div className="flex">
        <div className='w-1/3'>
            <EditChart 
                xAxisLabel='Slice By'
                yAxisLabel='Value'
                maxRows={99}
                multiAxis={false}
                chartType={ChartType.Pie}
            />
        </div>
        <div className='w-2/3'> 
            <PieChartVis />
        </div>
    </div>,
    'plot': () => <div className="flex">
        <div className='w-1/3'>
            <EditChart
                xAxisLabel='X Axis'
                yAxisLabel='Y Axes'
                maxRows={99}
                multiAxis
                chartType={ChartType.Line}
            />
        </div>
        <div className='w-2/3'>
            <PlotChartVis />
        </div>
    </div>,
    'bar': () => <div className="flex">
        <div className='w-1/3'> 
            <EditChart
                xAxisLabel='X Axis'
                yAxisLabel='Y Axes'
                maxRows={99}
                multiAxis
                chartType={ChartType.Bar}
            />
        </div>
        <div className='w-2/3'> 
            <BarChartVis />
        </div>
    </div>,
    'scatter': () => <div className="flex">
        <div className='w-1/3'> 
            <EditChart
                xAxisLabel='X Axis'
                yAxisLabel='Y Axis'
                maxRows={99}
                multiAxis={false}
                chartType={ChartType.Scatter}
            />
        </div>
        <div className='w-2/3'> 
            <ScatterChartVis />
        </div>
    </div>,
    'query': () => <div className="flex">
        <CvQueryEditor />
    </div>,
    'parameter': () => <div className="flex">
        <CvParameterEdit />
    </div>
};

const ConfigVisView = observer(() => {
    const queryContext = useContext(QueryDataContext);
    const insertStore = useContext(CanvasInsertContext);
    insertStore.chartContext = useMemo(() => new ChartStore({dataConfig: queryContext.dataConfig, data: queryContext.data, metadata: queryContext.metadata}, 99), [queryContext.dataConfig, queryContext.data, queryContext.metadata]);
    const ChartConfig = chartConfigs[insertStore.visConfig!.type];

    return <ChartContext.Provider value={insertStore.chartContext}>
        <ChartConfig />
    </ChartContext.Provider>;
});

function ConfigVis() {
    const insertStore = useContext(CanvasInsertContext);
    const ChartConfig = chartConfigs[insertStore.visConfig!.type];

    if (insertStore.visConfig!.type === 'query' || insertStore.visConfig!.type === 'parameter')
        return <div>
            <ChartConfig />
        </div>;
    else
        return <QueryTableContext dataConfig={insertStore.dataConfig} pageSize={1000}>
            <ConfigVisView />
        </QueryTableContext>;
}

export default observer(ConfigVis);
