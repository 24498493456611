import {ReactElement, useEffect, useMemo, useState} from 'react';
import Loading from '../../loading';
import {Link} from 'react-router-dom';
import {Bullhorn, Screen} from '@carbon/icons-react';
import {content} from '../../../lib/api';
import handleError from '../../../lib/error';

export type SpotlightItem = {
    title: string,
    type: 'New Release' | 'Upcoming Webinar',
    body: string,
    link?: string,
    linkText?: string
}

const MAX_SPOTLIGHT = 2;
export default function Spotlight() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [spotlightList, setSpotlightList] = useState<Array<SpotlightItem>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    // eslint-disable-next-line
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        content.spotlight.listN(MAX_SPOTLIGHT, controller.signal).then((spotlightList: Array<SpotlightItem>) => setSpotlightList(spotlightList))
            .catch(e => handleError(e, setError))
            .finally(() => setLoading(false));
    }, []);

    if (loading)
        return <Loading small />;

    if (!spotlightList.length)
        return null;

    return <div>
        <h4>Spotlight</h4>
        <ul className="flex flex-col space-y-8 mt-5">
            {spotlightList.slice(0, MAX_SPOTLIGHT).map((item, index) => <li key={index}>
                <SpotlightItem item={item} />
            </li>)}
        </ul>
    </div>;
}

type Props = {
    item: SpotlightItem
}
function SpotlightItem(props: Props) {
    const {item} = props;
    const typeContent: {icon: ReactElement, text: string} = useMemo(() => {
        switch (item.type) {
        case 'New Release':
            return {icon: <Bullhorn className="icon" />, text: 'New Release'};
        case 'Upcoming Webinar':
            return {icon: <Screen className="icon" />, text: 'Webinar'};
        }
    }, [item]);

    return <div className="flex flex-col space-y-3 bg-gray-400 bg-opacity-20 p-5">
        <div className="text-xl">{item.title}</div>
        <div className="flex space-x-3 items-center">
            {typeContent.icon}
            <span>{typeContent.text}</span>
        </div>
        <p>{item.body}</p>
        {item.link && <Link className="text-medinsight-blue-300" to={item.link}>{item.linkText}</Link>}
    </div>;
}