import {POMDatasets} from './datasets';
import {deserialize, QuantumGraph, TestType} from 'quantum-graph';
import {buildMSAPivotQuery, buildStatePivotQuery} from './pivot';
import {DataConfig} from '../../../../insight-container/types';
import {buildDrgQuery} from './drg';
import {buildMdcQuery} from './mdc';
import Variable, {loadDatasetMetadata} from '../../../../lib/metadata';
import {CRBLibrary} from '../library';

export default class CRBQueryBuilder {
    regionType: string;
    reportType: string;

    constructor(regionType: string, reportType: string) {
        this.regionType = regionType;
        this.reportType = reportType;
    }

    getDataset(): string {
        if (this.reportType === 'summary')
            return this.regionType === 'msa' ? POMDatasets.Msa : POMDatasets.State;
        else if (this.reportType === 'breakdown')
            return this.regionType === 'msa' ? POMDatasets.MsaCategory : POMDatasets.StateCategory;
        else if (this.reportType === 'drg')
            return this.regionType === 'msa' ? POMDatasets.MsaDrg : POMDatasets.StateDrg;
        else if (this.reportType === 'mdc')
            return this.regionType === 'msa' ? POMDatasets.MsaMdc : POMDatasets.StateMdc;
        return '';
    }

    private getQueryObj(fields: Array<string>, pivotMeasures: Array<string>, metricType: string, typeOfService: string, region: string): any {

        if (this.reportType === 'summary') {
            if (this.regionType === 'msa')
                return buildMSAPivotQuery(POMDatasets.Msa, pivotMeasures, 'type_of_service', metricType, fields);
            else if (this.regionType === 'state')
                return buildStatePivotQuery(POMDatasets.State, pivotMeasures, 'type_of_service', metricType, fields);
        } else if (this.reportType === 'breakdown') {
            let query: any = {};
            if (this.regionType === 'msa')
                query = buildMSAPivotQuery(POMDatasets.MsaCategory, pivotMeasures, 'category', metricType, fields);
            else if (this.regionType === 'state')
                query = buildStatePivotQuery(POMDatasets.StateCategory, pivotMeasures, 'category', metricType, fields);
            query.filters.push({
                column: {nodeId: 'pom', column: 'type_of_service'},
                test: TestType.Eq,
                value: typeOfService,
            });
            return query;
        } else if (this.reportType === 'drg')
            return buildDrgQuery(this.regionType, metricType, region, fields);
        else if (this.reportType === 'mdc')
            return buildMdcQuery(this.regionType, metricType, region, fields);
    }

    build(fields: Array<string>, pivotMeasures: Array<string>, metricType: string, typeOfService: string, region: string): QuantumGraph {
        const queryObj = this.getQueryObj(fields, pivotMeasures, metricType, typeOfService, region);
        return deserialize(queryObj);
    }

    buildAll(fields: Array<string>, pivotMeasures: Array<string>): QuantumGraph {
        let queryObj: any;
        if (this.reportType === 'breakdown')
            queryObj = this.getQueryObj(['metric_type', 'type_of_service', ...fields], pivotMeasures, '', '', '');
        else
            queryObj = this.getQueryObj(['metric_type', ...fields], pivotMeasures, '', '', '');
        queryObj.filters = [{column: {nodeId: 'pom', column: 'under_age_65_pop'}, test: TestType.NotNull, value: 0}];
        delete queryObj.transforms;
        return deserialize(queryObj);
    }

    buildConfig(selection: Array<Variable>, regions: Array<{name: string, value: string}>, metricType: string, typeOfService: string, region: string): DataConfig {
        let name = null;
        if (this.reportType === 'drg' || this.reportType === 'mdc') {
            name = regions.find(c => c.value === region)?.name;
            name = name?.replaceAll(/[^a-zA-Z]+/g, '_').replaceAll(/_+/g, '_').replace(/^_/, '');
        }
        const fields = selection.map(v => v.name);
        const pivotMeasures = selection.filter(v => v.getTag('Role') === 'pivotMeasure').map(v => v.name);

        return {
            queries: [
                this.build(fields, pivotMeasures, metricType, typeOfService, region),
                this.buildAll(fields, pivotMeasures),
            ],
            options: {
                hideExplore: true,
                regionType: this.regionType,
                region,
                reportType: this.reportType,
                measures: selection.filter(v => v.getTag('Role') === 'pivotMeasure').map(v => v.name),
                name,
                typeOfService,
                metricType,
            }
        };
    }

    async loadMetadata(signal: AbortSignal): Promise<[Array<Variable>, CRBLibrary]> {
        const metadata = await loadDatasetMetadata(this.getDataset(), signal);
        const library = new CRBLibrary(this.getDataset(), metadata);
        return [metadata, library];
    }
}