import checkAccess, {SiteFeature} from '../../lib/check-access';
import React, {useEffect, useState} from 'react';
import iconDashboard from './assets/icon-dashboard.png';
import OneOneOneLayout from '../../components/layout/one-one-one-layout';
import Card from '../../components/card';
import * as api from '../../lib/api';
import auth from '../../lib/auth';
import Loading from '../../components/loading';
import DashboardLink from './dashboard-link';
import handleError from '../../lib/error';
import ErrorMessage from '../../components/error-message';

type Dashboard = {
    id: number,
    name: string,
    lastUpdated?: string,
    lastUpdateDate?: Date,
    userId: number,
    orgId: number,
    owner: string,
};

type SavedMarket = {
    id: number
    regionType: string,
    regionCodes: Array<string | number>,
    dateCreated: string,
    dateCreatedDate: Date,
    name: string,
    dashboard: string,
    description: string,
};

async function loadDashboards(signal: AbortSignal): Promise<Array<Dashboard>> {
    const userDashboards: Array<Dashboard> = await api.dashboards.listByUser(signal);
    const orgDashboards: Array<Dashboard> = await api.dashboards.listByUserOrgs(signal);

    const seen: Record<string, boolean> = {};
    const dashboards = userDashboards.concat(orgDashboards).filter(dashboard => {
        if (seen[dashboard.id])
            return false;
        else {
            seen[dashboard.id] = true;
            return true;
        }
    });
    dashboards.sort((a, b) => b.id - a.id);
    dashboards.forEach(dashboard => {
        if (dashboard.lastUpdated)
            dashboard.lastUpdateDate = new Date(dashboard.lastUpdated);
        if (dashboard.userId === auth.principal?.sub)
            dashboard.owner = auth.principal.name;
        else if (dashboard.orgId)
            dashboard.owner = auth.principal?.orgs.find(org => org.id === dashboard.orgId)?.name || '';
        else
            dashboard.owner = 'Torch Insight';
    });

    return dashboards.slice(0, 4);
}

// TODO: Move this somewhere else when needed
const regions: Record<string, string> = {
    state: 'State',
    county: 'County',
    hrr: 'HRR',
    cbsa: 'CBSA',
    cd: 'Congressional District'
};
const regionsPlural: Record<string, string> = {
    state: 'States',
    county: 'Counties',
    hrr: 'HRRs',
    cbsa: 'CBSAs',
    cd: 'Congressional Districts'
};

async function loadSavedMarkets(signal: AbortSignal): Promise<Array<SavedMarket>> {
    const userMarkets: Array<SavedMarket> = await api.savedMarkets.listByUser(signal);
    const orgMarkets: Array<SavedMarket> = await api.savedMarkets.listByUserOrgs(signal);

    const markets = userMarkets.concat(orgMarkets);
    markets.forEach(market => {
        const {regionType, regionCodes} = market;
        market.dateCreatedDate = new Date(market.dateCreated);
        market.dashboard = `multi-${regionType}?${regions[regionType]}=${regionCodes.join(',')}`;
        market.description = regionCodes.length + ' ' + (regionCodes.length > 1 ? regionsPlural : regions)[regionType];
    });
    markets.sort((a, b) => b.dateCreatedDate.getTime() - a.dateCreatedDate.getTime());

    return markets.slice(0, 4);
}

export default function DashboardSection() {
    const [dashboards, setDashboards] = useState<Array<Dashboard>>([]);
    const [markets, setMarkets] = useState<Array<SavedMarket>>([]);
    const [loadingCount, setLoadingCount] = useState(0);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const controller = new AbortController();
        loadDashboards(controller.signal).then(dashboards => {
            setDashboards(dashboards);
            setLoadingCount(prev => prev + 1);
        }).catch(e => handleError(e, setError));

        loadSavedMarkets(controller.signal).then(markets => {
            setMarkets(markets);
            setLoadingCount(prev => prev + 1);
        }).catch(e => handleError(e, setError));

        return () => controller.abort();
    }, []);

    if (!checkAccess(SiteFeature.Dashboards))
        return null;

    return <div className="py-20 bg-gray-200">
        <h2 className="text-blue-500 flex uppercase items-center justify-center">
            <img src={iconDashboard} alt="Dashboards" className="h-10 mr-2"/>
            Dashboards
        </h2>
        <p className="text-center text-blue-500 font-light">
            Expert curated content, just for you.
        </p>
        <div className="container my-10">
            {error && <ErrorMessage error={error} />}
            <OneOneOneLayout>
                <Card>
                    <div className="font-bold text-gray-500 mb-3">Geography</div>

                    <DashboardLink dashboard="state" name="State" description="Good ol' state boundaries" />
                    <DashboardLink dashboard="county" name="County" description="Based on zipcode-level data" />
                    <DashboardLink dashboard="cbsa" name="CBSA: Core-based Statistical Area" description="Collection of counties around urban areas" />
                </Card>
                <Card>
                    <div className="font-bold text-gray-500 mb-3">Organizations &amp; Topics</div>

                    <DashboardLink dashboard="aco" name="ACOs" description="Accountable Care Organizations" />
                    <DashboardLink dashboard="healthsystem" name="Health Systems" description="Individual health system overview" />
                    <DashboardLink dashboard="hospital" name="Hospitals" description="Individual hospital overview" />
                    <DashboardLink dashboard="ma" name="Medicare Advantage (MA)" description="National overview of MA growth" />
                </Card>
                <Card>
                    <div className="font-bold text-gray-500 mb-3">Custom &amp; Saved Reports</div>
                    {loadingCount < 2 && <div className="text-center"><Loading small /></div>}
                    {loadingCount >= 2 && !dashboards.length && !markets.length && <p>
                        You don&apos;t have any custom or saved reports yet. Want a hand getting what you need? Reach
                        out to us at <a href="mailto:help@torchinsight.com">help@torchinsight.com</a>
                    </p>}

                    {!!markets.length && markets.map(market =>
                        <DashboardLink key={market.id} dashboard={market.dashboard} name={market.name} description={market.description} />
                    )}
                    {!!dashboards.length && dashboards.map(dashboard =>
                        <DashboardLink key={dashboard.id} dashboard={dashboard.id} name={dashboard.name} description={'by ' + dashboard.owner} />
                    )}
                </Card>
            </OneOneOneLayout>
        </div>
        <div className="text-center">
            <a href={'//' + process.env.REACT_APP_TI_DOMAIN + '/user/dashboards'} className="button blue pill">View All Dashboards</a>
        </div>
    </div>;
}