import Button from '../button';
import React, {ChangeEvent, useRef, useState} from 'react';
import Popup from '../popup';
import readFile from './read-file';
import handleError from '../../lib/error';

const MaxFileSize = 1024 * 1024; // 1 MB
const MaxItems = 100;


type Props = {
    onChange: (values: Array<string>) => void,
};

export default function UploadLink(props: Props) {
    const helpRef = useRef<HTMLButtonElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);
    const [err, setErr] = useState('');

    function selectFile() {
        if (fileRef.current) {
            fileRef.current.click();
        }
    }

    function processFile(e: ChangeEvent<HTMLInputElement>) {
        setErr('');
        if (!e.target.files || !e.target.files.length)
            return;
        const file = e.target.files[0];

        if (file.size > MaxFileSize) {
            setErr('Your file is too big. Please select a smaller file.');
            return;
        }

        let ext = '';
        if (file.name.toLowerCase().endsWith('.txt'))
            ext = 'txt';
        else if (file.name.toLowerCase().endsWith('.csv'))
            ext = 'csv';
        else {
            setErr('Please select a .txt or .csv file.');
            return;
        }

        readFile(file, ext, MaxItems)
            .then(results => props.onChange(results))
            .catch(err => handleError(err, setErr));
    }

    return <span>
        <Button color="link" className="text-sm cursor-pointer" onClick={selectFile}>
            Upload a .csv or .txt file of values
        </Button>
        <Button color="link" ref={helpRef}><i className="icon-question" /></Button>
        <Popup targetRef={helpRef}>
            <div className="px-3 py-3 text-sm">
                <p>For .csv files, include one value per row, with a single column.</p>
                <p>For .txt files, include one value per line.</p>
                <p>Limited to {MaxItems} values.</p>
            </div>
        </Popup>
        <input type="file" ref={fileRef} style={{display: 'none'}} accept=".csv,.txt" onChange={processFile} />
        {err && <div className="text-red-500 text-sm">{err}</div>}
    </span>;
}