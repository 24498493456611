import React, {useEffect, useState} from 'react';
import {Module} from '../../../../../lib/data-types';
import {modules as modulesApi} from '../../../../../lib/api';
import handleError from '../../../../../lib/error';
import Loading from '../../../../../components/loading';
import ErrorMessage from '../../../../../components/error-message';
import StandardModulesAdmin from './standard';
import CRBModulesAdmin from './crb';


type Props = {
    modules: Array<Module>,
    onChange: (modules: Array<Module>) => void,
}

export default function ModulesAdmin(props: Props) {
    const {modules, onChange} = props;
    const [allModules, setModules] = useState<Array<Module>>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const controller = new AbortController();
        modulesApi.list(controller.signal).then(modules => {
            setModules(modules);
            setLoading(false);
        }).catch(err => handleError(err, setError));
        return () => controller.abort();
    }, []);

    function handleToggle(module: Module) {
        const newModules = modules.slice();
        const index = newModules.findIndex(m => m.id === module.id);
        if (index >= 0)
            newModules.splice(index, 1);
        else
            newModules.push(module);
        onChange(newModules);
    }

    function addModule(module: Module) {
        const newModules = modules.slice();
        newModules.push(module);
        handleToggle(module);
    }

    return <div className="px-4 border-l">
        {error && <ErrorMessage />}
        {loading ?
            <Loading small /> :
            <div>
                <StandardModulesAdmin modules={modules} allModules={allModules} toggle={handleToggle} />
                <CRBModulesAdmin modules={modules} allModules={allModules} toggle={handleToggle} add={addModule} />
            </div>}
    </div>;

}