import React, {useEffect, useState} from 'react';
import {batchLoadDatasetInfo, DatasetInfo} from '../../lib/metadata';
import handleError from '../../lib/error';


type Props = {
    datasets: Array<string>,
}

export default function DatasetLegend(props: Props) {

    const [loading, setLoading] = useState(true);
    const [dsInfo, setDsInfo] = useState<Array<DatasetInfo>>([]);

    useEffect(() => {
        const controller = new AbortController();
        batchLoadDatasetInfo(props.datasets, controller.signal)
            .then(info => {
                setDsInfo(info);
                setLoading(false);
            })
            .catch(err => handleError(err, null));
        return () => controller.abort();
    }, [props.datasets]);

    if (loading)
        return null;
    return <div className="dataset-legend">
        <div className="legend-items">
            {dsInfo.map((info, i) => <div key={i} className="flex space-x-1 items-center">
                <div className="swatch" />
                <div>{info.label}</div>
            </div>)}
        </div>
    </div>;
}