import {dataRows, FilterGroup, QueryFilter, QueryObj} from '../../../lib/data-types';
import fetchData from '../../../lib/data';
import {deserialize, FilterGroupType, GraphVersions, QuantumGraph, TestType} from 'quantum-graph';


export default abstract class FilterStepsStore {
    protected _changeTimeout: any;
    protected _controller;
    private readonly DEFAULT_MIN_BEDS = 0;
    private readonly DEFAULT_MAX_BEDS = 999;
    public _minNumBeds: number;
    public _maxNumBeds: number;
    public _geographies: Array<{name: string, id: number, key: number, type: string}>;
    public _geographyType: string;
    public _facilityList: dataRows;
    public _facilityTypes: Array<string>;
    public _healthSystems: Array<string>;
    public _loading: boolean;
    public _updateFacilities: boolean;
    public MAX_ENTITIES = 2000;

    protected constructor(updateFacilities: boolean) {
        this._minNumBeds = this.DEFAULT_MIN_BEDS;
        this._maxNumBeds = this.DEFAULT_MAX_BEDS;
        this._geographies = [];
        this._facilityList = [];
        this._facilityTypes = [];
        this._healthSystems = [];
        this._geographyType = '';
        this._updateFacilities = updateFacilities;
        this._loading = false;
        this._controller = new AbortController();
    }

    get minNumBeds() {
        return this._minNumBeds;
    }

    set minNumBeds(numBeds: number) {
        this._minNumBeds = numBeds;
        if (this._updateFacilities)
            this.updateFacilityList();
    }

    get maxNumBeds() {
        return this._maxNumBeds;
    }

    set maxNumBeds(numBeds: number) {
        this._maxNumBeds = numBeds;
        if (this._updateFacilities)
            this.updateFacilityList();
    }

    get facilityTypes() {
        return this._facilityTypes;
    }

    set facilityTypes(types: Array<string>) {
        this._facilityTypes = types;
        if (this._updateFacilities)
            this.updateFacilityList();
    }

    get healthSystems() {
        return this._healthSystems;
    }

    set healthSystems(systems: Array<string>) {
        this._healthSystems = systems;
        if (this._updateFacilities)
            this.updateFacilityList();
    }

    get facilityList() {
        return this._facilityList;
    }

    set facilityList(facilities: dataRows) {
        this._facilityList = facilities;
    }

    get geographies() {
        return this._geographies;
    }

    set geographies(geographies: Array<{name: string, id: number, key: number, type: string}>) {
        this._geographies = geographies;
        if (this._updateFacilities)
            this.updateFacilityList();
    }

    get geographyType() {
        return this._geographyType;
    }

    set geographyType(geoType: string) {
        this._geographyType = geoType.toLowerCase();
        if (geoType === 'national')
            this.geographies = [];
    }

    get loading() {
        return this._loading;
    }

    set loading(loading: boolean) {
        this._loading = loading;
    }

    public abstract canConfigureTable(): boolean;

    public clearAll() {
        this.facilityList = [];
        this.geographies = [];
        this.facilityTypes = [];
        this.healthSystems = [];
        this.minNumBeds = this.DEFAULT_MIN_BEDS;
        this.maxNumBeds = this.DEFAULT_MAX_BEDS;
        this.loading = false;
    }

    public createFacilitiesFilter(): QueryFilter | null {
        let filter: QueryFilter;
        if (this.facilityList.length > 0) {
            filter = {
                type: 'basic',
                column: {
                    nodeId: 'hospital',
                    column: 'facility_dim_key'
                },
                test: TestType.In,
                value: this.facilityList.map(f => f['facility_dim_key'] as number)
            };

            return filter;
        }

        return null;
    }

    public createGeographyFilter(nodeId: string): FilterGroup | QueryFilter | null {
        const filters: Array<QueryFilter> = [];
        this.createCBSAFilter(nodeId, filters);
        this.createStateFilter(nodeId, filters);

        if (filters.length === 1) {
            return filters[0];
        } else if (filters.length > 1) {
            return {
                type: 'group',
                filters,
                filterGroupType: FilterGroupType.Or
            };
        }

        return null;
    }

    protected createCBSAFilter(nodeId: string, filters: Array<QueryFilter>) {
        const cbsaIds = this.geographies.filter(g => g.type === 'cbsa').map(g => g.id.toString());
        if (cbsaIds.length > 0) {
            filters.push({
                type: 'basic',
                column: {
                    nodeId: nodeId,
                    column: 'cbsa'
                },
                test: TestType.In,
                value: [...cbsaIds]
            });
        }
    }

    protected createStateFilter(nodeId: string, filters: Array<QueryFilter>) {
        const stateIds = this.geographies.filter(g => g.type === 'state').map(g => g.id.toString());
        if (stateIds.length > 0) {
            filters.push({
                type: 'basic',
                column: {
                    nodeId: nodeId,
                    column: 'state_fips'
                },
                test: TestType.In,
                value: [...stateIds]
            });
        }
    }

    public createFacilityTypeFilter(): Array<QueryFilter> {
        if (this._facilityTypes.length > 0) {
            const typeFilter: QueryFilter = {
                type: 'basic',
                column: {
                    nodeId: 'hospital',
                    column: 'milliman_facility_type'
                },
                test: TestType.In,
                value: [...this._facilityTypes]
            };

            return [typeFilter];
        }

        return [];
    }

    public createBedFilters(): Array<QueryFilter> {
        const filters = [];
        if (this._minNumBeds > this.DEFAULT_MIN_BEDS) {
            const minBedFilter: QueryFilter = {
                type: 'basic',
                column: {
                    nodeId: 'hospital',
                    column: 'beds'
                },
                test: TestType.GtEq,
                value: this._minNumBeds
            };
            filters.push(minBedFilter);
        }

        if (this._maxNumBeds < this.DEFAULT_MAX_BEDS) {
            const maxBedFilter: QueryFilter = {
                type: 'basic',
                column: {
                    nodeId: 'hospital',
                    column: 'beds'
                },
                test: TestType.LtEq,
                value: this._maxNumBeds
            };
            filters.push(maxBedFilter);
        }

        return filters;
    }

    public createHealthSystemFilter(): Array<QueryFilter> {
        if (this._healthSystems.length > 0) {
            const typeFilter: QueryFilter = {
                type: 'basic',
                column: {
                    nodeId: 'hospital',
                    column: 'owner_health_system_name'
                },
                test: TestType.In,
                value: [...this._healthSystems.map(hs => parseInt(hs))]
            };

            return [typeFilter];
        }

        return [];
    }

    public getFacilityDropDownQuery(variable: string): QuantumGraph {
        const obj: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'facility',
                            dataset: 'facility',
                            columns: []
                        },
                        {
                            id: 'hospital-pricing-service-cf',
                            dataset: 'hospital-pricing-service-cf',
                            columns: []
                        },
                        {
                            id: 'hospital-directory',
                            dataset: 'hospital-directory',
                            columns: [variable]
                        }
                    ]
                },
                joins: []
            },
            sort: [],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hospital-pricing-service-cf',
                        column: 'originalpayer'
                    },
                    test: 'is not null',
                    value: 1
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hospital-directory',
                        column: variable
                    },
                    test: TestType.NotNull,
                    value: 1
                }
            ],
            distinct: true
        };

        if (this.geographies.length)
            obj.filters!.push(this.createGeographyFilter('facility')!);

        return deserialize(obj);
    }

    private updateFacilityList() {
        if (this._changeTimeout)
            clearTimeout(this._changeTimeout);
        this._changeTimeout = setTimeout(this.performFacilityListUpdate, 1000);
    }

    protected generateFacilityListQuery(): QueryObj {
        let query: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'facility',
                            dataset: 'facility',
                            columns: []
                        },
                        {
                            id: 'hospital',
                            dataset: 'hospital-directory',
                            columns: [
                                'torch_facility_id',
                                'facility_dim_key',
                                'facility_name'
                            ]
                        },
                        {
                            id: 'hospital-pricing-service-cf',
                            dataset: 'hospital-pricing-service-cf',
                            columns: []
                        }
                    ]
                }
            },
            sort: [{
                column: {
                    nodeId: 'hospital',
                    column: 'facility_name'
                },
                asc: true
            }],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hospital-pricing-service-cf',
                        column: 'originalpayer'
                    },
                    test: TestType.NotNull,
                    value: 1
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'facility',
                        column: 'category'
                    },
                    test: TestType.Eq,
                    value: 'Hospital'
                },
                ...this.createFacilityTypeFilter(),
                ...this.createBedFilters(),
                ...this.createHealthSystemFilter()
            ],
            distinct: true
        };

        if (this.MAX_ENTITIES)
            query.pagination = {
                page: 0,
                pageSize: this.MAX_ENTITIES
            };

        if (this.geographies.length)
            query.filters!.push(this.createGeographyFilter('facility')!);

        return query;
    }

    private performFacilityListUpdate = async () => {
        if (this.loading) {
            this._controller.abort();
            this._controller = new AbortController();
        }
        this.loading = true;
        if (this._geographies.length === 0 &&
            this._facilityTypes.length === 0 &&
            this._minNumBeds === this.DEFAULT_MIN_BEDS &&
            this._maxNumBeds === this.DEFAULT_MAX_BEDS &&
            this._healthSystems.length === 0) {
            this.facilityList = [];
        } else {
            const query: QueryObj = this.generateFacilityListQuery();
            this.facilityList = await fetchData(deserialize(query), this._controller.signal);
        }
        this.loading = false;
    };
}