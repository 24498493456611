
export default function groupBy<T>(items: Array<T>, grouper: (item: T) => string): Record<string, Array<T>> {
    const groups: Record<string, Array<T>> = {};
    for (const item of items) {
        const key = grouper(item);
        if (!groups[key])
            groups[key] = [];
        groups[key].push(item);
    }

    return groups;
}