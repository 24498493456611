import {Checkbox} from '@carbon/icons-react';
import OpenButton from '../../../components/open-button';
import {useNavigate} from 'react-router-dom';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

export type ToolProps = {
    name: string,
    description: any,
    link: string,
    icon?: React.ReactElement,
    style?: 'light' | 'dark'
}


export default function Tool(props: ToolProps) {
    const {name, description, link, icon, style = 'light'} = props;
    const displayIcon = icon || <Checkbox/>;
    const navigate = useNavigate();

    function navigateToLink() {
        if (link.startsWith(process.env.REACT_APP_BASE_URL || 'https://'))
            window.open(link, '_blank');
        else
            navigate(link);
    }

    return (
        <div className='flex flex-col flex-grow mr-6 w-1/3 h-80'>
            <div className='flex items-center h-1/4'>
                <span className='tool-icon'>{displayIcon}</span>
                <span className='ml-4 text-lg font-bold'>{name}</span>
            </div>
            <div className='my-2 text-sm text-gray-500 h-1/2'>
                {description.hasOwnProperty('data') && documentToReactComponents(description)}
                {!description.hasOwnProperty('data') && description}
            </div>
            <div className="h-1/4">
                <OpenButton text='Open' style={style} onClick={navigateToLink} className='w-1/3'/>
            </div>
        </div>
    );
}