import React from 'react';
import AuthWall from '../../components/auth-wall';
import Template from '../../components/template';
import newLogo from './new-logo.png';
import computer from './computer.png';
import database from './database.png';
import OneTwoLayout from '../../components/layout/one-two-layout';
import Card from '../../components/card';
import CenteredLayout from '../../components/layout/centered-layout';
import Footer from '../../components/footer';
import SiteName from '../../components/SiteName';


export default function WhatsNewPage() {
    return <AuthWall>
        <Template>
            <div className="container">

                <div className="text-center flex flex-col md:flex-row justify-center items-center my-8">
                    <img src={newLogo} alt="What's New with Torch Insight" className="w-64"/>
                    <h1 className="mx-10">New in <SiteName /></h1>
                </div>

                <CenteredLayout size={5}>
                    <p>
                        We've been hard at work creating a new data experience for you. This is all done with the vision of
                        helping you get the most out of your data. Here are some of highlights of what's new and coming soon.
                    </p>
                </CenteredLayout>

                <OneTwoLayout className="my-16">
                    <div className="text-center">
                        <img src={computer} alt="New Look and Feel" className="h-28 inline-block" />
                        <h2>New Look and Feel</h2>
                    </div>
                    <Card>
                        <p>
                            We've put a lot of thought and effort into your Torch Insight experience—from menus and
                            interfaces to the way we query and show data. As a result we've created a new, more
                            consistent UI. You will start to see more consistency across the site as we update more and
                            more of our tools and pages. Plus, a little fresh paint never hurts.
                        </p>
                        <p>
                            Specifically, we are adding:
                        </p>
                        <ul className="list-disc list-inside">
                            <li>Ability to explore and connect any data you see on the site</li>
                            <li>Faster loading times</li>
                            <li>Cleaner and more accurate data tables</li>
                        </ul>
                        <p>
                            We hope you enjoy it! As always, for any questions or comments just reach out to us. We're
                            happy to chat.
                        </p>
                    </Card>
                </OneTwoLayout>

                <OneTwoLayout className="my-16">
                    <div className="text-center">
                        <img src={database} alt="New Look and Feel" className="w-32 inline-block" />
                        <h2>New Data Engine</h2>
                    </div>
                    <Card>
                        <p>
                            We've grown a lot over the years. One thing that we are constantly improvement and building
                            is core to all of Torch Insight: our data engine. We've re-engineered it—thinking about how
                            both data and our minds work—to make it simpler to build data queries while being more
                            powerful than ever before.
                        </p>
                        <p>
                            This new engine will be used in all our upcoming releases. We will also retrofit select
                            existing tools to use the new engine.
                        </p>
                        <p>
                            Also, this new engine will be made available for all users with API access. For more
                            information about the engine or for questions about API access, please contact us.
                        </p>
                    </Card>
                </OneTwoLayout>
            </div>
            <Footer />
        </Template>
    </AuthWall>;
}