import React, {useMemo} from 'react';
import AdminCrudTable from '../crud-table';
import {accessRoles} from '../../../lib/api';

export default function AccessRolesAdmin() {
    const fields = useMemo(() => [
        {name: 'id', label: 'Id', show: true, type: 'numeric'},
        {name: 'name', label: 'Name', show: true, type: 'text'},
        {name: 'description', label: 'Description', show: true, type: 'text'},
    ], []);

    return <AdminCrudTable api={accessRoles} title={'Access Role'} urlPath={'/site-admin/access-roles'} fields={fields}/>;
}