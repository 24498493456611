import {dataRow, QuantumQuery} from '../../lib/data-types';
import {NumberFormatter} from '../../lib/formatter';
import CompareTable from './compare-table';
import Variable from '../../lib/metadata';

/**
 * This will give you a percentage comparison between two queries. Each query much have exactly 1 variable of a number type. All other fields will not have a calculation performed.
 */

export default class PivotedCompareTable extends CompareTable {
    private _compareVariable: Variable;

    constructor(queries: Array<QuantumQuery>, asPercentage: boolean) {
        super(queries, asPercentage);
        this._compareVariable = this._baseQuery.metadata.find(v => v.formatter instanceof NumberFormatter)!;
        this.buildCompareTable();
    }

    private buildCompareTable() {
        const metadata = this._compareQueries.map(q => q.metadata).flat();
        for (const query of this._compareQueries) {
            for (let i = 0; i < query.data.length; i++) {
                const row = query.data[i];
                const calculatedRow: dataRow = {};
                Object.keys(row).forEach(key => {
                    const variable = metadata.find(v => v.name === key)!;
                    if (variable.formatter instanceof NumberFormatter) {
                        const compareValue = variable.getValue(row) as number;
                        let relativityScore;
                        const baseValue = this.getBaseQueryValue(this._compareVariable, i);
                        if (baseValue)
                            relativityScore = (compareValue / baseValue) * (this._asPercentage ? 100 : 1);
                        calculatedRow[key] = relativityScore && !isNaN(relativityScore) ? relativityScore : null;
                    } else {
                        calculatedRow[key] = variable.getValue(row);
                    }
                    if (!this._compareMetadata.some(v => v.name === key))
                        this._compareMetadata.push(variable);
                });
                this._compareData.push(calculatedRow);
            }
        }
    }
}