import {datasetByName} from './data-defs';
import getFormatter, {CanvasLinkFormatter, Formatter} from './formatter';
import Cache from './cache';
import {modules, quantum} from './api';
import {dataRow, dataValue} from './data-types';
import {Aggregation} from 'quantum-graph';

const dateFormats = [
    'date', 'datetime',
];

const numberFormats = [
    'number', 'money', 'currency', 'percent', 'bigpercent', 'star', 'claimscount', 'fte', 'month', 'year', 'yearmonth',
];

const numberTypes = [
    'decimal', 'bigint', 'numeric', 'tinyint', 'smallint', 'int',
];

export enum ValueType {
    Text,
    Number,
    DateTime,
}

export default class Variable {

    dataset?: string;
    name: string;
    label: string;
    description?: string;
    type: string;
    tags: Record<string, string>;
    formatter: Formatter;
    locked?: boolean;

    static from(obj: any) {
        const format = obj.tags.Format || obj.tags.format;
        const time = obj.tags.Time || obj.tags.time;
        const precision = obj.tags.Precision || obj.tags.precision;
        const formatter = getFormatter(time || format, Number(precision) || 0.01, obj.tags.nullmsg);
        return new Variable(
            obj.name.toLowerCase(),
            obj.label || obj.name,
            obj.description,
            obj.type && obj.type.toLowerCase(),
            obj.tags,
            formatter,
            obj.dataset);
    }

    constructor(name: string, label: string, description: string | undefined, type: string, tags: Record<string, string>, formatter: Formatter, dataset?: string) {
        this.name = name;
        this.label = label;
        this.description = description;
        this.type = type;
        this.tags = tags;
        this.formatter = formatter;
        this.dataset = dataset;
    }

    getValue(row: dataRow): dataValue {
        if (!row || !(row.hasOwnProperty(this.name) || row.hasOwnProperty(this.dataset + ':' + this.name)))
            return null;

        let value = row[this.name];
        if (value === undefined && this.dataset)
            value = row[this.dataset + ':' + this.name];
        return value;
    }

    format(row: dataRow) {
        return this.formatter.format(this.getValue(row), row);
    }

    formatSimple(row: dataRow) {
        return this.formatter.formatSimple(this.getValue(row));
    }

    getId(): string {
        if (this.dataset)
            return this.dataset + ':' + this.name;
        return this.name;
    }

    getDsGroup() {
        // If it's hard-coded in metadata, then use that
        const groupDataset = this.getTag('groupDataset');
        if (groupDataset)
            return groupDataset;

        // Get the effective dataset
        const dataset = this.getTag('dataset') || this.dataset;
        if (!dataset)
            return 'other';

        // Check if it's a fact. If so, then use the dim it joins to
        const datasetDef = datasetByName[dataset];
        if (datasetDef && datasetDef.tableType === 'fact') {
            const grain = datasetDef.tableGrain.find(g => g[0] === '!');
            if (grain)
                return grain.substr(1);
        }

        // Otherwise, just use the dataset
        return dataset;
    }

    getDsGroupId(): string {
        return this.getDsGroup() + ':' + this.name;
    }

    getTag(name: string): string | undefined {
        const value = this.tags[name];
        if (value !== undefined)
            return value;
        return this.tags[name.toLowerCase()];
    }

    getValueType(): ValueType {
        const format = this.getTag('Format')?.toLowerCase();
        if (format && dateFormats.includes(format))
            return ValueType.DateTime;
        else if (format && numberFormats.includes(format))
            return ValueType.Number;
        const time = this.getTag('Time')?.toLowerCase();
        if (time)
            return ValueType.Number;
        if (numberTypes.includes(this.type))
            return ValueType.Number;

        return ValueType.Text;
    }

    getCategory(): string {
        return this.getTag('Subcategory') || this.getTag('Category') || 'General';
    }

    getAggregation(): Aggregation | null {
        const aggregate = this.getTag('Aggregate');
        if (aggregate === 'avg')
            return Aggregation.Average;
        if (aggregate === 'sum')
            return Aggregation.Sum;
        return null;
    }
}

/**
 * This can adjust the metadata, specifically formatters, once everything is loaded. For example, if there are 'id' and
 * 'name' fields, then the name formatter should be changed to render a link instead.
  */
export function adjustMetadata(metadata: Array<Variable>) {
    const datasets = new Set<string>();
    const ids: Record<string, Variable> = {};
    const names: Record<string, Variable> = {};
    metadata.forEach(v => {
        const dataset = v.getTag('dataset') || v.dataset;
        if (dataset) {
            datasets.add(dataset);
            const fieldRole = v.getTag('FieldRole')?.toLowerCase();
            if (fieldRole === 'id')
                ids[dataset] = v;
            else if (fieldRole === 'name')
                names[dataset] = v;
        }
    });

    for (const dataset of Array.from(datasets)) {
        const idVar = ids[dataset];
        const nameVar = names[dataset];
        if (idVar && nameVar) {
            nameVar.formatter = new CanvasLinkFormatter(idVar, true);
        }
    }
}

export async function loadDatasetMetadata(dataset: string, signal: AbortSignal): Promise<Array<Variable>> {
    const key = 'ds-md:' + dataset;
    let metadata: Array<Variable>;
    try {
        const mdRaw = Cache.get<Array<Variable>>(key);
        metadata = mdRaw.map(Variable.from);
    } catch (e) {
        if (e instanceof Cache.CacheMiss) {
            const mdRaw = (await quantum.datasetMetadata(dataset, signal)) as Array<object>;
            metadata = mdRaw.map(Variable.from);
            try {
                Cache.set(key, mdRaw);
            } catch (e) {
                console.warn('Failed to cache dataset metadata', e);
            }
        } else
            throw e;
    }
    adjustMetadata(metadata);
    return metadata;
}

export async function batchLoadDatasetMetadata(datasets: Array<string>, signal: AbortSignal): Promise<Array<Array<Variable>>> {
    const allMetadata: Array<Array<Variable>> = datasets.map(() => []);
    const dsToFetch: Array<[string, number]> = [];

    // Attempt to load all datasets from cache
    datasets.forEach((dataset, i) => {
        const key = 'ds-md:' + dataset;
        try {
            const mdRaw = Cache.get<Array<Variable>>(key);
            const metadata = mdRaw.map(Variable.from);
            adjustMetadata(metadata);
            allMetadata[i] = metadata;
        } catch (e) {
            if (e instanceof Cache.CacheMiss) {
                dsToFetch.push([dataset, i]);
            } else
                throw e;
        }
    });

    // Batch fetch those that have not yet been loaded
    if (dsToFetch.length) {
        const datasetNames = dsToFetch.map(ds => ds[0]);
        const allMdRaw = (await quantum.batchDatasetMetadata(datasetNames, signal)) as Array<Array<object>>;
        dsToFetch.forEach(([dataset, index], i) => {
            const mdRaw = allMdRaw[i];
            const metadata = mdRaw.map(Variable.from);
            adjustMetadata(metadata);
            allMetadata[index] = metadata;
            try {
                const key = 'ds-md:' + dataset;
                Cache.set(key, mdRaw);
            } catch (e) {
                console.warn('Failed to cache dataset metadata', e);
            }
        });
    }

    return allMetadata;
}

export type DatasetInfo = {
    label?: string,
    description?: string,
    source?: string,
    sourceDescription?: string,
}

export async function loadDatasetInfo(dataset: string, signal: AbortSignal): Promise<DatasetInfo> {
    const key = 'ds-info:' + dataset;
    try {
        return Cache.get<DatasetInfo>(key);
    } catch (e) {
        if (e instanceof Cache.CacheMiss) {
            const dsInfo = (await quantum.datasetInfo(dataset, signal)) as DatasetInfo;
            try {
                Cache.set(key, dsInfo);
            } catch (e) {
                console.warn('Failed to cache dataset info', e);
            }

            return dsInfo;
        }
        throw e;
    }
}

export async function batchLoadDatasetInfo(datasets: Array<string>, signal: AbortSignal): Promise<Array<DatasetInfo>> {
    const allDsInfo: Array<DatasetInfo> = datasets.map(() => ({}));
    const dsToFetch: Array<[string, number]> = [];

    // Attempt to load all ds info from cache
    datasets.forEach((dataset, i) => {
        const key = 'ds-info:' + dataset;
        try {
            allDsInfo[i] = Cache.get<DatasetInfo>(key);
        } catch (e) {
            if (e instanceof Cache.CacheMiss) {
                dsToFetch.push([dataset, i]);
            } else
                throw e;
        }
    });

    // Batch fetch those that have not yet been loaded
    if (dsToFetch.length) {
        const datasetNames = dsToFetch.map(ds => ds[0]);
        const fetchedDsInfo = (await quantum.batchDatasetInfo(datasetNames, signal)) as Array<DatasetInfo>;
        dsToFetch.forEach(([dataset, index], i) => {
            const dsInfo = fetchedDsInfo[i];
            const key = 'ds-info:' + dataset;
            allDsInfo[index] = dsInfo;
            try {
                Cache.set(key, dsInfo);
            } catch (e) {
                console.warn('Failed to cache dataset info', e);
            }
        });
    }

    return allDsInfo;
}

export async function filterMetadataByPermissions(metadata: Array<Variable>): Promise<Array<Variable>> {
    // First, group by datasets
    let newMetadata: Array<Variable> = [];
    const byDS: Record<string, Array<Variable>> = {};
    for (const v of metadata) {
        if (!v.dataset) {
            newMetadata.push(v);
            continue;
        }
        if (!byDS[v.dataset])
            byDS[v.dataset] = [];
        byDS[v.dataset].push(v);
    }

    const datasets = Object.keys(byDS);
    for (const dataset of datasets) {
        const datasetModules = await modules.datasets.get(dataset);
        let variables = byDS[dataset];
        for (const module of datasetModules) {
            let columns = (module.columns ? JSON.parse(module.columns) : []) as Array<string>;
            if (columns.length) {
                const isBlacklist = columns[0][0] === '!';
                if (isBlacklist) {
                    columns = columns.map(c => c.substr(1));
                    variables = variables.filter(v => !columns.includes(v.name));
                } else { // Whitelist
                    variables = variables.filter(v => columns.includes(v.name));
                }
            }
        }
        newMetadata = newMetadata.concat(variables);
    }

    return newMetadata;
}