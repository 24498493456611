import {authHeader, determineAPIHost} from '../../lib/api';

export default async function fetchContent(file: string, signal: AbortSignal): Promise<string> {
    const url = determineAPIHost() + '/v2/stream/document/' + file;
    const response = await fetch(url, {
        method: 'GET',
        signal,
        headers: authHeader(),
    });
    return await response.text();
}