import React, {useMemo} from 'react';
import AdminCrudTable from '../crud-table';
import {contentAccess} from '../../../lib/api';

export default function ContentAccessAdmin() {
    const fields = useMemo(() => [
        {name: 'id', label: 'Id', show: true, type: 'numeric'},
        {name: 'userId', label: 'User Id', show: true, type: 'user'},
        {name: 'orgId', label: 'Org Id', show: true, type: 'org'},
        {name: 'grantType', label: 'Grant Type', show: true, type: 'grantType'},
        {name: 'userDatasetId', label: 'User Dataset Id', show: true, type: 'userDatasets'},
        {name: 'dashboardId', label: 'Dashboard Id', show: true, type: 'dashboards'},
        {name: 'canvasId', label: 'Canvas Id', show: true, type: 'canvas'}
    ], []);

    return <AdminCrudTable api={contentAccess} title={'Content Access'} urlPath={'/site-admin/content-access'} fields={fields} />;
}