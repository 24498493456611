import React, {ReactNode} from 'react';
import {NavLink, Routes, Route, Link} from 'react-router-dom';
import AuthWall from '../../../components/auth-wall';
import Template from '../../../components/template';
import {SiteFeature} from '../../../lib/check-access';
import OneTwoLayout from '../../../components/layout/one-two-layout';
import Card from '../../../components/card';
import './api-doc.scss';
import APIDocUsage from './usage';
import APIDocQuickStart from './quick-start';
import APIDocAuthorization from './authorization';
import APIDocCompressedData from './compressed-data';
import APIDocDataWarehouse from './data-warehouse';
import APIDocQueryConstruction from './query-construction';
import APIDocNodeTypes from './node-types';
import APIDocPerformQuery from './perform-query';
import APIDocDataFormats from './data-formats';
import APIDocDatasets from './datasets';
import APIDocMetadata from './metadata';
import APIDocV2Guide from './v2-guide';
import APIDocExamples from './examples/examples';
import APIDocHeaders from './headers';
import MillimanInsights from './milliman-insights';
import {observer} from 'mobx-react';
import SiteName from '../../../components/SiteName';

function DocLink(props: {path: string, children: ReactNode}) {
    return <NavLink className={(navData) => navData.isActive ? 'is-active nav-link' : 'nav-link'} to={'/docs/api/' + props.path}>{props.children}</NavLink>;
}

function DefaultContent() {
    return <div>
        <p>
            The <SiteName /> data query API is available for your use. With it, you can perform any data query and analysis
            that can be done one the site.
        </p>
        <p>
            To get started, see our <Link to="/docs/api/usage">Usage Guide</Link> or use one of the links on the left.
        </p>
    </div>;
}

function APIDocumentation() {
    return <AuthWall siteFeature={SiteFeature.API}>
        <Template showFooter>
            <div className="container" id="api-doc-page" style={{minHeight: 'calc(100vh - 97px)'}}>
                <h1 className="text-center mb-4 mt-0 pt-4">API Documentation</h1>
                <OneTwoLayout>
                    <div>
                        <div className="my-4 text-small uppercase font-light text-gray-500">General</div>
                        <DocLink path="usage">Usage Guide</DocLink>
                        <DocLink path="quick-start">Quick Start</DocLink>
                        <DocLink path="authorization">Authorization</DocLink>
                        <DocLink path="headers">Required Headers</DocLink>
                        <DocLink path="compressed-data">Compressed Data</DocLink>
                        <DocLink path="milliman-insights">Milliman Insights</DocLink>

                        <div className="my-4 text-small uppercase font-light text-gray-500">Data and Query Concepts</div>
                        <DocLink path="data-warehouse">Data Warehouse Structure</DocLink>
                        <DocLink path="query-construction">Query Construction</DocLink>

                        <div className="my-4 text-small uppercase font-light text-gray-500">Data Queries</div>
                        <DocLink path="perform-query">Performing Data Queries</DocLink>
                        <DocLink path="data-formats">Supported Data Formats</DocLink>
                        <DocLink path="metadata">Retrieving Metadata</DocLink>

                        <div className="my-4 text-small uppercase font-light text-gray-500">Reference</div>
                        <DocLink path="v1-reference">v1 Query Reference</DocLink>
                        <DocLink path="v2-guide">v2 Query Authoring Guide</DocLink>
                        <DocLink path="examples">Examples</DocLink>
                        <DocLink path="datasets">Available Datasets</DocLink>

                    </div>
                    <Card>
                        <Routes>
                            <Route path="/" element={<DefaultContent />} />
                            <Route path="usage" element={<APIDocUsage />} />
                            <Route path="quick-start" element={<APIDocQuickStart />} />
                            <Route path="authorization" element={<APIDocAuthorization />} />
                            <Route path="headers" element={<APIDocHeaders />} />
                            <Route path="compressed-data" element={<APIDocCompressedData />} />
                            <Route path="milliman-insights" element={<MillimanInsights />} />
                            <Route path="data-warehouse" element={<APIDocDataWarehouse />} />
                            <Route path="query-construction" element={<APIDocQueryConstruction />} />
                            <Route path="perform-query" element={<APIDocPerformQuery />} />
                            <Route path="data-formats" element={<APIDocDataFormats />} />
                            <Route path="metadata" element={<APIDocMetadata />} />
                            <Route path="v1-reference" element={<APIDocNodeTypes />} />
                            <Route path="v2-guide" element={<APIDocV2Guide />} />
                            <Route path="examples" element={<APIDocExamples />} />
                            <Route path="datasets" element={<APIDocDatasets />} />
                        </Routes>
                    </Card>
                </OneTwoLayout>
            </div>
        </Template>
    </AuthWall>;
}

export default observer(APIDocumentation);