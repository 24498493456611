import React, {useContext} from 'react';
import './spinner.scss';
import {HeaderContext} from '../header/context';

export default function Spinner() {
    const headerStore = useContext(HeaderContext);
    return <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Oval</title>
        <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" className="svg-spinner">
            <g stroke={headerStore.useNewDesign ? '#5EB0EB' : '#FF8E3C'}>
                <path d="M15,8 C15,4.13400675 11.8659932,1 8,1 C4.13400675,1 1,4.13400675 1,8" />
            </g>
        </g>
    </svg>;
}