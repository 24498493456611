import {useNavigate, useParams, Navigate} from 'react-router-dom';
import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {dataRow, dataRows, Organization} from '../../lib/data-types';
import {organizations, users} from '../../lib/api';
import Button from '../../components/button';
import Template from '../../components/template';
import AuthWall from '../../components/auth-wall';
import SimpleTable from '../../components/simple-table';
import Loading from '../../components/loading';
import ErrorMessage from '../../components/error-message';
import handleError from '../../lib/error';
import auth from '../../lib/auth';
import EntityInput from '../site-admin/crud-table/inputs/entity';

type Props = {
    modal?: boolean
}

export default function OrgMembership(props: Props) {
    const {modal} = props;
    const [items, setItems] = useState<dataRows>([]);
    const [org, setOrg] = useState<Organization>({name: ''});
    const [loading, setLoading] = useState(true);
    const [newUserId, setNewUserId] = useState<number | null>(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [err, setErr] = useState('');
    const navigate = useNavigate();
    const {id: idStr} = useParams<{id: string}>();
    const id = Number(idStr);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        organizations.get(id, controller.signal)
            .then(org => {
                setOrg(org);
                return organizations.users.list(id, controller.signal);
            })
            .then(items => {
                setItems(items);
                setLoading(false);
            })
            .catch(err => handleError(err, setErr));
        return () => controller.abort();
    }, [id]);

    const fields: Array<[string, string]> = [
        ['name', 'Name'],
        ['email', 'Email'],
        ['status', 'Status'],
    ];

    const removeUser = useCallback((row: any) => {
        const idx = items.findIndex(item => item.id === row.id);
        if (idx === -1)
            return;
        organizations.users.delete(id, row.id)
            .catch(err => handleError(err, setErr));
        const newItems = items.slice();
        newItems.splice(idx, 1);
        setItems(newItems);
    }, [id, items]);

    const actions = [
        {label: 'Remove', color: 'red', icon: 'icon-trash', onClick: removeUser},
        {label: 'Manage', icon: 'icon-user', color: 'light', onClick: (row: dataRow) => navigate(`/org/${id}/membership/${row.id}`)}
    ];

    function inviteUser(e?: FormEvent) {
        e && e.preventDefault();
        if (!email)
            return;

        const invitation = {name, email};
        organizations.users.invite(id, invitation)
            .then(user => setItems(items.slice().concat([user])))
            .catch(err => handleError(err, setErr));
        setName('');
        setEmail('');
    }

    function addUser(e?: FormEvent) {
        e && e.preventDefault();
        if (!newUserId)
            return;

        organizations.users.add(id, newUserId)
            .then(user => setItems(items.slice().concat([user])))
            .catch(err => handleError(err, setErr));
        setNewUserId(null);
    }

    const isOrgAdmin = org.adminUsers?.some(admin => admin === auth.principal?.sub);
    if (!loading && !auth.hasRole('admin') && !isOrgAdmin)
        return <Navigate replace to="/" />;

    function renderBody() {
        return <div className="container">
            <h1>Organization Membership</h1>
            <div className="mb-10">
                <Button color="white" onClick={() => navigate(-1)}>
                    <i className="icon-arrow-left" /> Back
                </Button>
            </div>

            {err && <ErrorMessage error={err} onClick={() => setErr('')} />}

            {loading ?
                <div className="text-center">
                    <Loading />
                </div> :
                <div>
                    <SimpleTable items={items} fields={fields} actions={actions} />
                    <h2>Invite Users</h2>
                    <form onSubmit={inviteUser}>
                        <div className="flex">
                            <div>
                                <strong className="block text-gray-500">Name</strong>
                                <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Enter name..." />
                            </div>
                            <div className="ml-3">
                                <strong className="block text-gray-500">Email</strong>
                                <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter email address..." />
                            </div>
                        </div>
                        <div className="mt-2">
                            <Button color="light" onClick={inviteUser} disabled={!name || !email}>Invite</Button>
                        </div>
                    </form>

                    {auth.hasRole('admin') && <div>
                        <h2>Add Existing Torch Users</h2>
                        <form onSubmit={addUser}>
                            <EntityInput value={newUserId} onChange={setNewUserId} list={users.list} additionalFields={['email']} sortField="name" />
                            <div className="mt-2">
                                <Button color="light" onClick={addUser} disabled={!newUserId}>Add User</Button>
                            </div>
                        </form>
                    </div>}
                </div>}
        </div>;
    }

    return <AuthWall>
        <div>
            {modal && renderBody()}
            {!modal && <Template>
                {renderBody()}
            </Template>}
        </div>
    </AuthWall>;
}