import {ReactElement, useEffect, useState} from 'react';
import ClearAll from './clear-all';
import ControlButtons from './control-buttons';
import StepLabel from './step-label';
import {FilterStep} from './libs/types';
import './filter-steps.scss';

type Props = {
    title: string,
    subtitle?: ReactElement,
    onSubmit: () => void,
    filterSteps: Array<FilterStep>
}

export default function FilterSteps(props: Props) {
    const {title, subtitle, onSubmit, filterSteps} = props;
    const [scrollIndex, setScrollIndex] = useState<number>(0);

    useEffect(() => {
        const scrollListener = () => {
            setScrollIndex(window.pageYOffset);
        };
        window.addEventListener('scroll', scrollListener);

        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, []);

    return <div className="relative pb-8">
        <div className={`filter-steps-header ${scrollIndex > 100 ? 'shadow-md' : ''}`}>
            <div className="container relative">
                <ClearAll />
                <h1>{title}</h1>
                <div>{subtitle}</div>
            </div>
        </div>
        <div className="container filter-steps pb-8">
            <div className="pt-8 flex flex-col space-y-3">
                {filterSteps.map((step, i) => <div key={i} className="border-b pb-3">
                    <StepLabel key={i} name={step.name} subText={step.subText} required={step.required} index={i}/>
                    <step.step />
                </div>)}
            </div>
            <ControlButtons onSubmit={onSubmit} />
        </div>
    </div>;
}