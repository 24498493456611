import {dataRows} from '../lib/data-types';
import Table from './table';
import React, {useMemo} from 'react';
import {ActionFormatter, ActionType, StringFormatter} from '../lib/formatter';
import Variable from '../lib/metadata';


type Props = {
    items: dataRows,
    fields: Array<[string, string]>
    actions?: Array<ActionType>,
}

export default function SimpleTable(props: Props) {
    const {items, fields, actions} = props;

    const headers = useMemo(() => {
        const sf = new StringFormatter();
        const variables = fields.map(([name, label]) => new Variable(name, label, '', '', {}, sf));
        if (actions && actions.length)
            variables.push(new Variable('id', 'Actions', '', '', {}, new ActionFormatter(actions)));
        return variables;
    }, [actions, fields]);

    return <Table headers={headers} data={items} />;
}