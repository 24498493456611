import React from 'react';
import {useNavigate} from 'react-router-dom';
import SavedDataBrowser from '../../../components/saved-data-browser';
import {hcIndex} from '../../../lib/api';

export default function SavedDataPage() {
    const navigate = useNavigate();

    function doNavigate(type: 'query' | 'container', id: number, reference?: string) {
        if (type === 'query') {
            if (reference) {
                hcIndex.getByShortName(reference, new AbortController().signal).then(index => {
                    navigate(`/healthcare-index/${index.shortName}/${id}`);
                }).catch(() => navigate('/data/' + id));
            } else
                navigate('/data/' + id);
        }
        else
            navigate('/ic/' + id);
    }

    return <div className="p-8">
        <div className='text-xl'>My Saved Data</div>
        <SavedDataBrowser allowDelete allowCreateVis onSelect={doNavigate} />
    </div>;
}