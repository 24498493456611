import {HCIndexEntry, HCIndexLink, HCIndexSection} from '../types';


export function searchSections(search: string, sections: Array<HCIndexSection>): Array<HCIndexSection> {
    search = search.toLowerCase();
    const results: Array<HCIndexSection> = [];
    for (const section of sections) {
        const entries = searchEntries(search, section);
        const links = searchLinks(search, section);
        if (entries.length || links.length)
            results.push(Object.assign({}, section, {entries, links}));
    }
    return results;
}

export function searchEntries(search: string, section: HCIndexSection): Array<HCIndexEntry> {
    return section.entries.filter(entry => {
        return entry.name.toLowerCase().includes(search) ||
            (entry.description && JSON.stringify(entry.description).toLowerCase().includes(search)) ||
            entry.details?.some(d => d.toLowerCase().includes(search)) ||
            entry.datasets?.some(d => d.toLowerCase().includes(search)) ||
            entry.shortName.toLowerCase().includes(search);
    }
    );
}

export function searchLinks(search: string, section: HCIndexSection): Array<HCIndexLink> {
    return section.links.filter(link => {
        return link.name.toLowerCase().includes(search) ||
            (link.description && JSON.stringify(link.description).toLowerCase().includes(search)) ||
            link.path?.toLowerCase().includes(search);
    });
}