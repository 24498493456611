import {useMemo, useState} from 'react';
import AuthWall from '../../../components/auth-wall';
import {SiteFeature} from '../../../lib/check-access';
import Template from '../../../components/template';
import FilterStepsContext from '../../../components/filter-steps/libs/context';
import HCGFilterStepsStore from './filter-steps/hcg-filter-steps-store';
import FilterSteps from '../../../components/filter-steps';
import HCGFilterStepsConfig from './filter-steps/hcg-filter-steps-config';
import {DataConfig} from '../../../insight-container/types';
import {QueryTableContext, QueryTableVis} from '../../../insight-container/query-table';
import Breadcrumb from '../../../components/breadcrumb';
import {createCBSAHCGQuery, createNationalHCGQuery, createStateHCGQuery} from './queries';

const crumbs = [
    {name: 'Healthcare Index', url: '/healthcare-index'},
    {name: 'HCG Cost Model', url: '/hcg_cost_model'}
];

export default function HCGCostModelPage() {
    const [showFilterSteps, setShowFilterSteps] = useState<boolean>(true);
    const hcgFilterStore = useMemo(() => HCGFilterStepsStore.empty(), []);
    const [config, setConfig] = useState<DataConfig>();

    const onSubmit = () => {
        setShowFilterSteps(false);
        let query;
        if (hcgFilterStore.geographyType === 'national')
            query = createNationalHCGQuery(hcgFilterStore.getHCGFilters(), hcgFilterStore.includeHCGLineDetail);
        else if (hcgFilterStore.geographyType === 'state')
            query = createStateHCGQuery(hcgFilterStore.getHCGFilters(), hcgFilterStore.includeHCGLineDetail);
        else
            query = createCBSAHCGQuery(hcgFilterStore.getHCGFilters(), hcgFilterStore.includeHCGLineDetail);

        const dataConfig: DataConfig = {
            queries: [query],
            options: {
                hideExplore: true,
            }
        };
        setConfig(dataConfig);
    };

    function buildConfigureTableButton() {
        return <div className="flex space-x-2 items-center text-blue-500 hover:cursor-pointer"
            onClick={() => setShowFilterSteps(true)}>
            <i className="icon-reset"/>
            <div>Reconfigure Table</div>
        </div>;
    }

    return <AuthWall siteFeature={SiteFeature.AccessHCG}>
        <Template>
            <FilterStepsContext.Provider value={hcgFilterStore}>
                {showFilterSteps && <FilterSteps title="HCG Cost Model"
                    subtitle={<p>Referential benchmark data on claims costs and utilization, classified Milliman Health Cost Guidelines (HCGs) and broken down by market, member demographics, and service type.</p>}
                    onSubmit={onSubmit}
                    filterSteps={HCGFilterStepsConfig}/>}
            </FilterStepsContext.Provider>
            {!showFilterSteps && config && <QueryTableContext dataConfig={config}>
                <div className="mt-10">
                    <div className="pl-10">
                        <Breadcrumb crumbs={crumbs}/>
                        <h1>HCG Cost Model</h1>
                        <div className="mt-5">
                            <p>Referential benchmark data on claims costs and utilization, broken down by market, member demographics, and service type. Services are classified using Milliman Health Cost Guidelines (HCGs).</p>
                        </div>
                    </div>
                    <div className="p-10">
                        <QueryTableVis customTableControl={buildConfigureTableButton()} />
                    </div>
                </div>
            </QueryTableContext>}
        </Template>
    </AuthWall>;
}