import Template from '../../components/template';
import AuthWall from '../../components/auth-wall';
import {SiteFeature} from '../../lib/check-access';
import Button from '../../components/button';
import {determineAPIHost} from '../../lib/api';
import auth from '../../lib/auth';

const path = '/v2/quantum/data-dictionary';
export default function DataDictionaryPage() {
    const host = determineAPIHost();
    const url = host + path + '?format=csv&token=' + encodeURIComponent(String(auth.token));

    return <Template showFooter>
        <AuthWall siteFeature={SiteFeature.Admin}>
            <div className="dpp-container pt-10 flex justify-center">
                <div>
                    <h1>Data Dictionary</h1>
                    <p>
                        Download the current version of the data dictionary.
                    </p>
                    <form action={url} method="POST" encType="multipart/form-data">
                        <Button color="milliman-blue">Download</Button>
                    </form>
                </div>
            </div>
        </AuthWall>
    </Template>;
}