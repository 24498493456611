
export default function readFile(file: File, ext: string, numLines: number): Promise<Array<string>> {
    return new Promise<Array<string>>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = e => {
            const text = String(e.target?.result);
            let lines = text
                .split('\n')
                .map(line => line.trim())
                .filter(_ => _)
                .slice(0, numLines);

            if (ext === 'csv') {
                lines = lines.map(line => {
                    if (line[0] === '"') {
                        let quoteCount = 0;
                        for (let i = 0; i < line.length; i++) {
                            if (line[i] === '"')
                                quoteCount++;
                            if (line[i] === ',' && quoteCount % 2 === 0)
                                return line.substring(1, i - 1).replace(/""/g, '"');
                        }
                        return line;
                    } else {
                        return line.split(',')[0];
                    }
                });
            }

            resolve(lines);
        };
        reader.onerror = () => {
            reject('There was a problem reading your file.');
        };

        reader.readAsText(file);
    });
}