import {Link} from 'react-router-dom';
import logoBlue from '../components/images/logo-blue.svg';
import './footer.scss';
import SiteName from './SiteName';


export default function SiteFooter() {
    return <div className="z-20 h-12 bg-milliman-slate-500 w-full pl-4 items-center">
        <div className='flex items-center h-12 w-full'>
            <Link to='/' className='hover:no-underline text-gray-300'>
                <img
                    className='inline h-8 w-auto mr-6'
                    src={logoBlue}
                    alt='Torch Insight'
                />
                <span className='hover:no-underline pr-8'>
                                    Milliman&nbsp;<span className='font-bold text-gray-100'><SiteName /></span>
                </span>
            </Link>
            <a className="footer-link" href="https://torchinsight.com/terms-of-use">Terms of Use</a>
            <a className="footer-link" href="https://torchinsight.com/legal/privacy-policy.html">Privacy Policy</a>
            <a className="footer-link" href="https://torchinsight.com/contact-us.html">Contact Us</a>
            <span className='absolute right-0 footer-link'>© {new Date().getFullYear()} Milliman, Inc. All Rights Reserved</span>
        </div>
    </div>;
}