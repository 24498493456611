import {HCIndexSection} from '../types';
import Popup from '../../../../components/popup';
import React, {Dispatch, useRef} from 'react';

type SelectProps = {
    selectedSection?: HCIndexSection,
    setSelectedSection: Dispatch<HCIndexSection | undefined>,
}

type LinkProps = {
    index: number,
    section: HCIndexSection
} & SelectProps;

export function HCIndexNavLink(props: LinkProps) {
    const {index, section, selectedSection, setSelectedSection} = props;

    return <div key={index} className={'ml-5 text-gray-500 cursor-pointer ' + (selectedSection?.name === section.name && 'font-bold')}
        onClick={() => setSelectedSection(section.name === selectedSection?.name ? undefined : section)}>
        {section.name}<span className="text-gray-300 text-sm ml-1">({section.entries.length + section.links.length})</span>
    </div>;
}

type Props = {
    sections: Array<HCIndexSection>,
} & SelectProps;

export default function HCIndexNav(props: Props) {
    const {sections, setSelectedSection, selectedSection} = props;
    const navMenuRef = useRef<HTMLButtonElement>(null);

    // Pull out the first few of the sections until the total name length reaches 50
    const headerSections = sections.filter((section, i, arr) => {
        const totalLength = arr.slice(0, i).reduce((total, currentSec) => total + currentSec.name.length, 0);
        return totalLength < 50;
    });
    const moreSections = sections.slice(headerSections.length);

    return (
        <nav className='bg-white shadow'>
            <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
                <div className='relative flex items-center justify-between h-16'>
                    {headerSections.map((section, i) => (
                        <HCIndexNavLink
                            key={i}
                            index={i}
                            section={section}
                            selectedSection={selectedSection}
                            setSelectedSection={setSelectedSection}
                        />
                    ))}
                    {headerSections.length < sections.length && (
                        <div className='ml-5 text-gray-500'>
                            <div>
                                <button className='' id='user-menu' aria-haspopup='true' ref={navMenuRef}>
                    More
                                    <i className='text-sm text-gray-300 ml-1 icon-down' />
                                </button>
                            </div>
                            <Popup ariaLabelledBy='user-menu' targetRef={navMenuRef}>
                                {moreSections.map((section, i) => (
                                    <HCIndexNavLink
                                        key={i}
                                        index={i}
                                        section={section}
                                        selectedSection={selectedSection}
                                        setSelectedSection={setSelectedSection}
                                    />
                                ))}
                            </Popup>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
}