import {FilterStep} from '../../../components/filter-steps/libs/types';
import React, {useMemo, useState} from 'react';
import {DataConfig} from '../../../insight-container/types';
import {Link} from 'react-router-dom';
import AuthWall from '../../../components/auth-wall';
import {SiteFeature} from '../../../lib/check-access';
import Template from '../../../components/template';
import {QueryTableContext, QueryTableVis} from '../../../insight-container/query-table';
import Breadcrumb from '../../../components/breadcrumb';
import FilterStepsContext from '../../../components/filter-steps/libs/context';
import FilterSteps from '../../../components/filter-steps';
import {QueryFilter} from '../../../lib/data-types';
import {QuantumGraph} from 'quantum-graph';

const RELATIVITY_CATEGORY = 'Pricing Relativity';
const HOSPITAL_PRICING_DETAIL_CATEGORY = 'Hospital Pricing Detail';
const PAYER_PRICING_DETAIL_CATEGORY = 'Payer Pricing Detail';
const HOSPITAL_PRICING_QUALITY_SCORE = 'Hospital Pricing Quality Scores';
const HOSPITAL_RELATIVITY_CATEGORY = 'Hospital Pricing Relativity';
const PAYER_RELATIVITY_CATEGORY = 'Payer Pricing Relativity';
const MAX_ROW_LIMIT = 1000000;
const MAX_DOWNLOAD_ROW_LIMIT = MAX_ROW_LIMIT;

type Props = {
    filterSteps: Array<FilterStep>,
    hptFilterStore: any,
    createHPTQuery: (view: string, reportedBy: string, filters?: Array<QueryFilter>) => QuantumGraph,
    title?: string
}

export default function HPTMedicareRelativityTool(props: Props) {
    const {filterSteps, hptFilterStore, createHPTQuery, title} = props;
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [config, setConfig] = useState<DataConfig>();

    const crumbs = useMemo(() => ([
        {name: 'Healthcare Index', url: '/healthcare-index'},
        {name: title || 'Milliman Insights', url: '/hpt/medicare-relativity-score'}
    ]), [title]);

    const onSubmit = () => {
        setShowFilter(false);
        const query = createHPTQuery(hptFilterStore.view, hptFilterStore.dataView, hptFilterStore.getHPTFilters());
        const detailBlacklist = hptFilterStore.dataView === 'hospital' ? PAYER_PRICING_DETAIL_CATEGORY : HOSPITAL_PRICING_DETAIL_CATEGORY;
        const relativityBlackList = hptFilterStore.dataView === 'hospital' ? PAYER_RELATIVITY_CATEGORY : HOSPITAL_RELATIVITY_CATEGORY;
        const viewCategoryBlackList = hptFilterStore.view === 'relativity' ? [PAYER_PRICING_DETAIL_CATEGORY, HOSPITAL_PRICING_DETAIL_CATEGORY, relativityBlackList] : [RELATIVITY_CATEGORY, HOSPITAL_RELATIVITY_CATEGORY, PAYER_RELATIVITY_CATEGORY, detailBlacklist];
        if (hptFilterStore.dataView === 'payer')
            viewCategoryBlackList.push(HOSPITAL_PRICING_QUALITY_SCORE);

        const dataConfig = {
            queries: [query],
            options: {
                stickyColumns: ['facility_name', 'payer', 'lob', 'product', 'planname', 'organization_legal_name', 'group_pac_id_or_placeholder'],
                hideExplore: true,
                title: hptFilterStore.view === 'relativity' ? 'Relative Price Index by Service Line' : 'Full Code Details',
                subCategoryBlackList: viewCategoryBlackList,
                countlessPagination: hptFilterStore.view === 'detail',
                maxDownloadRowLimit: hptFilterStore.view === 'detail' ? MAX_DOWNLOAD_ROW_LIMIT : undefined,
                lockFilters: true
            }
        };

        setConfig(dataConfig);
    };

    function buildConfigureTableButton() {
        return <div className="flex space-x-2 items-center text-blue-500 hover:cursor-pointer" onClick={() => setShowFilter(true)}>
            <i className="icon-reset"/>
            <div>Reconfigure Table</div>
        </div>;
    }

    function buildTitleElement() {
        return <div>
            <p>Welcome to Milliman Insights! Please follow the steps below to generate customized output. For details on the data sources and methodology, please refer to the <Link to="/hpt/medicare-relativity-score/overview">Insights Documentation</Link>.</p>
            <p>To download your full dataset in text format please use this <a href="https://secure.milw.milliman.com/Web/Account/Login.htm">SFTP server</a> with your provided credentials.</p>
        </div>;
    }

    return <AuthWall siteFeature={SiteFeature.AccessHPT}>
        <Template>
            {!showFilter && config && <QueryTableContext dataConfig={config}>
                <div className="mt-10">
                    <div className="pl-10">
                        <Breadcrumb crumbs={crumbs}/>
                        <h1>{title || 'Milliman Insights'}</h1>
                        <div className="mt-5">
                            {hptFilterStore.view === 'relativity' && <p>Aggregated results on a percentage of nationwide Medicare basis, meaning Medicare without area or provider-specific adjustments so all provider relativities can be compared directly with each other. The results are limited to records without quality issues, use code-level utilization distributions by line of business and provider type, along with Milliman’s GRVUs.</p>}
                            {hptFilterStore.view === 'detail' && <p>View allowed amount information at the individual code level with Milliman’s enhancements appended to each record including standardized payer name, line of business, product, rate methodology, HCG category, utilization count, GRVU value, and whether or not the data passed quality checks and would be included in the aggregated percent of Medicare values in the Relativities table.</p>}
                        </div>
                    </div>
                    <div className="p-10">
                        <QueryTableVis customTableControl={buildConfigureTableButton()} />
                    </div>
                </div>
            </QueryTableContext>}
            <FilterStepsContext.Provider value={hptFilterStore}>
                {showFilter && <FilterSteps onSubmit={onSubmit} filterSteps={filterSteps} title={title || 'Milliman Insights'} subtitle={buildTitleElement()} />}
            </FilterStepsContext.Provider>
        </Template>
    </AuthWall>;
}