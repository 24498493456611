import {observer} from 'mobx-react';
import {AuthoringButton} from './authoring-panel';
import {useLocation, useNavigate} from 'react-router-dom';
import {useContext} from 'react';
import {CanvasContext} from '../lib/context';


const ApplyVersion = observer(() => {
    const store = useContext(CanvasContext);
    const navigate = useNavigate();
    const location = useLocation();

    function returnToDraft() {
        store.versionId = undefined;
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('versionId');
        navigate({search: searchParams.toString()});
        navigate(0);
    }

    async function apply() {
        await store.applyCanvasVersion();
        returnToDraft();
    }

    return <div className="fixed top-2 right-5 flex space-x-3 align-center bg-gray-500 bg-opacity-30 p-3 rounded-lg shadow">
        <AuthoringButton text="Apply Version" icon="icon-save" color="blue" onClick={apply} />
        <AuthoringButton text="Cancel" icon="icon-trash" color="light" onClick={returnToDraft} />
    </div>;
});

export default ApplyVersion;