import {dataRow, dataRows, QuantumQuery} from '../../lib/data-types';
import Variable from '../../lib/metadata';
import {NumberFormatter} from '../../lib/formatter';
import CompareTable from './compare-table';


export default class CompareTableWithVariable extends CompareTable {
    private readonly _compareVar: Variable;
    private readonly _nameVar: Variable;
    private readonly _displayVars: Array<Variable>;


    constructor(queries: Array<QuantumQuery>, compareVar: string, asPercentage: boolean) {
        super(queries, asPercentage);
        this._compareVar = this._baseQuery.metadata.find(v => v.name === compareVar)!;
        this._nameVar = this._baseQuery.metadata.find((v => v.getTag('FieldRole') === 'name'))!;
        this._displayVars = this._baseQuery.metadata.filter(v => v.getTag('FieldRole') !== 'name' && !(v.formatter instanceof NumberFormatter));
        this.buildCompareTable();
    }

    private isCompareValuesEqual(row1: dataRow, row2: dataRow) {
        return (this._compareVar.getValue(row1) as string)?.toLowerCase() === (this._compareVar.getValue(row2) as string)?.toLowerCase();
    }

    private getRelatedRows(compareRow: dataRow) {
        const relatedRows: dataRows = [];
        this._compareQueries.forEach(cq => {
            const dataRows = cq.data;
            for (const row of dataRows) {
                if (this.isCompareValuesEqual(row, compareRow)) {
                    relatedRows.push(row);
                }
            }
        });

        return relatedRows;
    }

    private getAllCompareRows() {
        const compareRows: dataRows = [];
        this._compareQueries.forEach(q => {
            const dataRows = q.data;
            for (const row of dataRows) {
                if (!compareRows.some(cr => this.isCompareValuesEqual(row, cr)) && this._compareVar.getValue(row))
                    compareRows.push(row);
            }
        });

        return compareRows;
    }

    private buildCompareTable() {
        const calculatedMetadata: Array<Variable> = [];
        const numVarNames = new Set<string>();
        for (const compareRow of this.getAllCompareRows()) {
            const calculatedRow: dataRow = {};
            this._displayVars.forEach(v => calculatedRow[v.name] = v.getValue(compareRow));
            const numberVars = this._baseQuery.metadata.filter(v => v.formatter instanceof NumberFormatter);
            const relatedRows: dataRows = this.getRelatedRows(compareRow);

            for (const row of relatedRows) {
                for (const variable of numberVars) {
                    numVarNames.add(variable.name);
                    const compareValue: number = variable.getValue(row) as number;
                    const name = `${variable.name}::${this._nameVar.getValue(row)}`;
                    let relativityScore;
                    const baseValue = this.getBaseQueryValue(variable, 0);
                    if (baseValue)
                        relativityScore = (compareValue / baseValue) * (this._asPercentage ? 100 : 1);
                    calculatedRow[name] = relativityScore && !isNaN(relativityScore) ? relativityScore : null;
                    if (!calculatedMetadata.some(v => v.name === name))
                        calculatedMetadata.push(new Variable(name, `${variable.label} - ${this._nameVar.getValue(row)}`, '', 'float', {}, new NumberFormatter(.0001)));
                }
            }
            this._compareData.push(calculatedRow);
        }

        const sortedMetadata: Array<Variable> = this._displayVars;
        for (const variable of Array.from(numVarNames)) {
            sortedMetadata.push(...calculatedMetadata.filter(v => v.name.split('::')[0] === variable));
        }
        this._compareMetadata = sortedMetadata;
    }
}