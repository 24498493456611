import React, {useState} from 'react';
import {View, ViewOff, WarningFilled} from '@carbon/icons-react';

type Props = {
    value: string | number,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    tabIndex?: number,
    placeholder?: string,
    type?: string,
    validation?: boolean
}

export default function Input(props: Props) {
    const {value, onChange, tabIndex, placeholder, type, validation} = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    
    return <div>
        <div data-testid="parent" className={`block mt-1 w-full bg-background-gray-100 border-b-2 border-background-gray-300 relative ${validation ? 'border-2 border-red-500' : ''}`}>
            <input data-testid="input" type={showPassword ? 'text' : type || 'text'} placeholder={placeholder}
                className='border-none bg-transparent w-full rounded-none'
                value={value} onChange={onChange}
                tabIndex={tabIndex} />

            {type === 'password' && !validation && <div data-testid="show-password" className='absolute right-5 top-3 hover:cursor-pointer' onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <ViewOff height={20} /> : <View height={20} />}
            </div>}
            {validation && <div className='absolute right-5 top-3 hover:cursor-pointer text-red-500' onClick={() => setShowPassword(!showPassword)}>
                <WarningFilled style={{height: '20px'}}/>
            </div>}
        </div>
        {validation && <div className="text-red-500 mt-1">{placeholder || 'Field'} is required</div>}
    </div>;
}