import React from 'react';
import Button from '../../../components/button';

const preCompress = `[
    {"fieldA": 1,  "fieldB": 2,  "fieldC": 3},
    {"fieldA": 4,  "fieldB": 5,  "fieldC": 6},
    {"fieldA": 7,  "fieldB": 8,  "fieldC": 9},
    {"fieldA": 10, "fieldB": 11, "fieldC": 12},
    {"fieldA": 13, "fieldB": 14, "fieldC": 15}
]`;

const postCompress = `{
    "compressed": true,
    "fields": ["fieldA", "fieldB", "fieldC"],
    "data": [
        [1, 2, 3],
        [4, 5, 6],
        [7, 8, 9],
        [10, 11, 12],
        [13, 14, 15]
    ]
}`;


export default function APIDocCompressedData() {
    return <div>
        <h2>Compressed Data</h2>
        <p>
            When data is returned it is returned in a compressed JSON format that reduces the payload size.
        </p>
        <p>
            Instead of an array of objects being returned, a list of the fields is provided—which are the same across
            all of the objects—along with the values of those fields (same order) for each object. See example below:
        </p>
        <p>Before compression (182 bytes):</p>
        <pre>{preCompress}</pre>
        <p>After compression (112 bytes):</p>
        <pre>{postCompress}</pre>
        <p>
            With more rows and more fields the compression savings is even greater.
        </p>

        <hr/>
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/api/milliman-insights">Milliman Insights</Button>
        </p>
    </div>;
}