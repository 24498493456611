import GraphHandler, {Override} from './index';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import Filter from '../../filter';
import Variable from '../../metadata';
import {QuantumGraph} from 'quantum-graph';
import Library from '../../library';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import {BasicFilter, Sort} from 'quantum-graph/out/v2/filter';
import {V2ColumnSignature} from 'quantum-graph/out/v2/signature';

export default class V2GraphHandler extends GraphHandler<V2QuantumGraph> {
    constructor(graph: V2QuantumGraph) {
        const clone = V2QuantumGraph.deserialize(graph.serialize()) as V2QuantumGraph;
        super(clone);
    }

    applyFilters(filters: Array<Filter>): this {
        const existingFilters: Record<string, boolean> = {};
        this.graph.filters.forEach(filter => {
            if (!(filter instanceof BasicFilter))
                return;
            const key = `${filter.column.nodeId}.${filter.column.column} ${filter.test} ${JSON.stringify(filter.value)}`;
            existingFilters[key] = true;
        });

        for (const filter of filters) {
            const column = this.lookupColumn(filter.variable);
            const key = `${column.nodeId}.${column.column} ${filter.test} ${JSON.stringify(filter.value)}`;
            if (existingFilters[key])
                console.log('Filter exists in graph already. Not adding.');
            else
                this.graph.filters.push(new BasicFilter(column, filter.test, filter.value));
        }

        return this;
    }

    applyPagination(page: number, pageSize: number): this {
        this.graph.pagination = {page, pageSize};
        return this;
    }

    removePagination(): this {
        if (this.graph.pagination)
            delete this.graph.pagination;
        return this;
    }

    applySort(sorts: Array<[Variable, boolean]>): this {
        this.graph.sort = sorts.map(([sort, asc]) => {
            // if sorting on the scaffold variable change the dataset to "dim"_scaffold
            if (this.graph.baseQueryGraph.baseQuery.queryType === QueryType.DimScaffold && this.graph.baseQueryGraph.baseQuery.scaffoldField === sort.name)
                sort.dataset = this.graph.baseQueryGraph.baseQuery.tableNodes[0].id + '_scaffold';
            const column = this.lookupColumn(sort);
            return Sort.deserialize({column, asc});
        });
        return this;
    }

    assembleVariables(metadata: Array<Variable>): Array<{connectingDim: string, variable: Variable}> {
        let newMetadata: Array<{connectingDim: string, variable: Variable}> = [];
        this.graph.forEachTable((node, baseQuery) => {
            let baseDim = baseQuery.queryType === QueryType.Affiliation && baseQuery.tableNodes.length > 1 ? baseQuery.tableNodes[1].dataset : baseQuery.tableNodes[0].dataset;
            const nodeVars = metadata.filter(v => v.dataset === node.dataset && node.columns.includes(v.name));
            newMetadata = newMetadata.concat(nodeVars.map(v => ({connectingDim: baseDim, variable: v})));
        });
        metadata.forEach(v => {
            if (!newMetadata.some(nv => nv.variable === v))
                newMetadata.push({connectingDim: 'other', variable: v});
        });
        return newMetadata;
    }

    build(): QuantumGraph {
        this.graph = V2QuantumGraph.deserialize(this.graph.serialize()) as V2QuantumGraph;
        return this.graph;
    }

    buildCount(): QuantumGraph {
        const graph = V2QuantumGraph.deserialize(this.graph.serialize()) as V2QuantumGraph;
        graph.count = true;
        graph.sort = [];
        return graph;
    }

    buildCountDistinct(variable: Variable): QuantumGraph {
        const graph = V2QuantumGraph.deserialize(this.graph.serialize()) as V2QuantumGraph;
        graph.count = true;
        graph.sort = [];
        graph.distinct = true;
        graph.forEachTable(node => {
            if (node.dataset === variable.dataset)
                node.columns = [variable.name];
            else
                node.columns = [];
        });
        return graph;
    }

    buildDistinct(variable: Variable): QuantumGraph {
        const graph = V2QuantumGraph.deserialize(this.graph.serialize()) as V2QuantumGraph;
        graph.sort = [];
        graph.distinct = true;
        graph.forEachTable(node => {
            if (node.dataset === variable.dataset)
                node.columns = [variable.name];
            else
                node.columns = [];
        });
        return graph;
    }

    getFilters(metadata: Array<Variable>, library: Library): Array<Filter> {
        const filters: Array<Filter> = [];
        let allVars = library.getSections().flatMap(s => s.index.getVariables());
        if (!allVars.length) // If the library hasn't been loaded, then use the graph metadata.
            allVars = metadata;
        this.graph.filters.forEach(filter => {
            // For now, we only support basic filters
            if (!(filter instanceof BasicFilter))
                return;
            const variable = this.findVariable(filter.column, allVars);
            if (variable)
                filters.push({variable, test: filter.test, value: filter.value});
        });
        return filters;
    }

    getSort(metadata: Array<Variable>): Array<[Variable, boolean]> {
        return this.graph.sort.map(sort => {
            const sortVar = this.findVariable(sort.column, metadata);
            if (sortVar)
                return [sortVar, sort.asc];
            return null;
        }).filter(_ => _) as Array<[Variable, boolean]>;
    }

    removeFilter(filter: Filter, ignoreValue?: boolean): this {
        let filterDataset = filter.variable.dataset;
        if (filterDataset?.endsWith('_scaffold'))
            filterDataset = filterDataset?.replace('_scaffold', '');
        const filterIdx = this.graph.filters.findIndex(f => {
            if (!(f instanceof BasicFilter))
                return false;
            if (f.test !== filter.test || (!ignoreValue && f.value !== filter.value))
                return false;
            let nodeId = f.column.nodeId;
            if (nodeId.endsWith('_scaffold'))
                nodeId = nodeId.replace('_scaffold', '');
            const node = this.graph.lookup(nodeId);
            if (!node)
                return false;
            return filterDataset === node.dataset && filter.variable.name === f.column.column;
        });
        if (filterIdx >= 0)
            this.graph.filters.splice(filterIdx, 1);
        return this;
    }

    roughEquals(graph: V2QuantumGraph): boolean {
        const s1: Array<string> = [];
        const s2: Array<string> = [];
        this.graph.forEachTable(node => node.columns.forEach(c => s1.push(`${node.dataset}:${c}`)));
        graph.forEachTable(node => node.columns.forEach(c => s2.push(`${node.dataset}:${c}`)));
        s1.sort();
        s2.sort();
        return s1.join('') === s2.join('');
    }

    setColumnOrder(columns: Array<Variable>): this {
        this.graph.columnOrder.columns = columns.map(c => this.lookupColumn(c));
        return this;
    }

    // getColumnOrder(metadata: Array<Variable>): Array<Variable> {
    //     return this.graph.columnOrder.columns.map(c => this.findVariable(c, metadata)).filter(v => v !== undefined) as Array<Variable>;
    // }

    // Utility methods below
    // --------------------------

    findVariable(columnSig: V2ColumnSignature, metadata: Array<Variable>) {
        const {nodeId, column} = columnSig;
        if (nodeId.endsWith('_scaffold')) {
            // Ignore the dataset, as it will be combined across the facts in the scaffold
            const v = metadata.find(v => v.name === column);
            return v ? new Variable(v.name, v.label, v.description, v.type, {...v.tags, Pinned: 'true'}, v.formatter, nodeId) : undefined;
        }
        const node = this.graph.lookup(nodeId);
        return metadata.find(v => v.dataset === node?.dataset && v.name === column);
    }

    lookupColumn(variable: Variable): V2ColumnSignature {
        const col: V2ColumnSignature = {nodeId: '', column: variable.name};
        if (variable.dataset?.endsWith('_scaffold')) {
            col.nodeId = variable.dataset;
        } else {
            this.graph.forEachTable(node => {
                if (node.dataset === variable.dataset)
                    col.nodeId = node.id;
            });
        }
        return col;
    }

    applyOverride(override: Override): this {
        this.graph.filters.forEach(filter => {
            if (filter instanceof BasicFilter && filter.column.column === override.field) {
                filter.test = override.test;
                filter.value = override.value;
            }
        });
        return this;
    }

    getDatasets(): Array<string> {
        const datasets = new Set<string>();
        this.graph.forEachTable(node => datasets.add(node.dataset));
        return Array.from(datasets);
    }

    getDatasetFromVariable(variable: string) {
        let dataset = '';
        this.graph.forEachTable(node => {
            if (node.columns.includes(variable))
                dataset = node.dataset;
        });

        return dataset;
    }

    extractVariables(): Array<{dataset: string, variable: string}> {
        let variables: Array<{dataset: string, variable: string}> = [];
        this.graph.forEachTable(node => {
            node.columns.forEach(c => variables.push({dataset: node.dataset, variable: c}));
        });
        if (this.graph.baseQueryGraph.joins) {
            this.graph.baseQueryGraph.joins.forEach(bq => {
                bq.node.forEach(tn => {
                    tn.columns.forEach(c => variables.push({dataset: tn.dataset, variable: c}));
                });
            });
        }
        if (this.graph.baseQueryGraph.baseQuery.scaffoldField)
            variables.push({dataset: `${this.graph.baseQueryGraph.baseQuery.tableNodes[0].dataset}_scaffold}`, variable: this.graph.baseQueryGraph.baseQuery.scaffoldField});

        return variables;
    }

    applyScaffoldType(type: string | undefined) {
        this.graph.baseQueryGraph.baseQuery.scaffoldType = type;
    }

    getScaffoldType(): string | undefined {
        return this.graph.baseQueryGraph.baseQuery.scaffoldType;
    }

    applyScaffoldField(field: string) {
        this.graph.baseQueryGraph.baseQuery.queryType = QueryType.DimScaffold;
        this.graph.baseQueryGraph.baseQuery.scaffoldField = field;
    }
}