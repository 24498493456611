import AuthWall from '../../../components/auth-wall';
import {SiteFeature} from '../../../lib/check-access';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import Template from '../../../components/template';
import Button from '../../../components/button';
import PageMenu from '../../../components/page-menu';
import {useNavigate, useParams} from 'react-router-dom';
import Select from '../../../components/select';
import {
    DataSourceLabels,
    FocusLevelLabels, FocusServiceLabels,
    GeographyLabels,
    LinesOfBusiness,
    Years
} from './constants';
import CommonCoreLibrary from './library';
import Variable from '../../../lib/metadata';
import ErrorMessage from '../../../components/error-message';
import CommonCoreStartModal from './start-modal';
import CommonCoreConstraints from './constraints';
import {CCEnumField, listEnum} from './queries/enum-field';
import buildCommonCoreConfig from './queries';
import InsightContainer from '../../../insight-container';
import {insightContainer} from '../../../lib/api';
import handleError from '../../../lib/error';
import DataLibraryModal, {basicMDUpdateStrategy} from '../../../components/data-library-modal';
import SaveModal from '../../../components/save-modal';
import activityTracker from '../../../lib/activity-tracker';
import {buildParamKey, extractParamKey} from './params';
import {Link} from 'react-router-dom';
import SelectMenu from '../../../components/select-menu';
import Badge from '../../../components/badge';
import {getQueryStoreGraph} from '../../../insight-container/renderers/query-data-renderer/store';
import {useFavorite} from '../../../lib/favorites';
import Breadcrumb from '../../../components/breadcrumb';
import {Add, Star, StarFilled, Subtract, Document} from '@carbon/icons-react';
import {HeaderContext} from '../../../components/header/context';
import {OperationalTag, Tag} from '@carbon/react';

function getFocusOptionLabel(focusLevel: string, src: string, cond: string) {
    let label = FocusLevelLabels[focusLevel];
    if (focusLevel === 'cond')
        label += ': ' + cond;
    else if (focusLevel === 'service')
        label += ': ' + FocusServiceLabels[src];
    return label;
}

export default function CommonCoreBenchmarkPage() {
    const {key: paramKey} = useParams<{key: string}>();
    const params = extractParamKey(paramKey!);

    const [year, setYear] = useState(params.year || Years[0]);
    const [lob, setLob] = useState(params.lob || LinesOfBusiness[0]);
    const [dataSource, setDataSource] = useState(params.dataSource || CommonCoreConstraints.dataSources(year, lob)[0]);
    const regionTypes = CommonCoreConstraints.regionTypes(dataSource);
    const [regionType, setRegionType] = useState(params.regionType || regionTypes[0]);
    const [memberCategories, setMemberCategories] = useState<Array<string>>(['']);
    const [memberCategory, setMemberCategory] = useState<string>(params.memberCategory || '');
    const [conditionCategories, setConditionCategories] = useState<Array<string>>(['']);
    const [conditionCategory, setConditionCategory] = useState<string>(params.conditionCategory || '');
    const [srcs, setSrcs] = useState<Array<string>>(['']);
    const [src, setSrc] = useState<string>(params.src || '');
    const focusLevels = CommonCoreConstraints.deriveFocusLevels(srcs);
    const [focusLevel, setFocusLevel] = useState<string>(params.focusLevel || focusLevels[0]);
    const [serviceCategory, setServiceCategory] = useState<string>(params.serviceCategory || CommonCoreConstraints.serviceCategories(dataSource)[0]);
    const [demoBreakout, setDemoBreakout] = useState(params.demoBreakout || false);
    const [startModal, setStartModal] = useState(!params.year);
    const [library, setLibrary] = useState<CommonCoreLibrary>();
    const [loading, setLoading] = useState(true);
    const [libraryModal, setLibraryModal] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const [selection, setSelection] = useState<Array<Variable>>([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {useNewDesign} = useContext(HeaderContext);

    useEffect(() => {
        setLoading(true);
        setLibrary(new CommonCoreLibrary(regionType));
    }, [regionType]);

    useEffect(() => {
        if (library) {
            const controller = new AbortController();
            library.loadAllMetadata(controller.signal).then(() => {
                setSelection(old => library.translateSelection(old));
                setLoading(false);
            });
            return () => controller.abort();
        }
    }, [library]);

    useEffect(() => {
        const key = buildParamKey(year, lob, dataSource, regionType, memberCategory, conditionCategory, serviceCategory, src, focusLevel, demoBreakout);
        navigate(`/common-core/${key}`);
        activityTracker.setRecentActivity('Common Core Benchmarks', GeographyLabels[regionType] + ', ' + lob + ' ' + year);
    }, [year, lob, dataSource, regionType, memberCategory, conditionCategory, serviceCategory, demoBreakout, src, focusLevel]);

    const dataConfig = useMemo(() =>
        buildCommonCoreConfig(regionType, dataSource, memberCategory, conditionCategory, serviceCategory, demoBreakout, lob, src, selection),
    [regionType, dataSource, memberCategory, conditionCategory, serviceCategory, demoBreakout, lob, src, selection]
    );

    useEffect(() => {
        const regionTypes = CommonCoreConstraints.regionTypes(dataSource);
        setRegionType(old => {
            if (!regionTypes.includes(old))
                return regionTypes[0];
            return old;
        });
        const serviceCategories = CommonCoreConstraints.serviceCategories(dataSource);
        setServiceCategory(old => {
            if (!serviceCategories.includes(old))
                return serviceCategories[0];
            return old;
        });
    }, [dataSource]);

    useEffect(() => {
        const controller = new AbortController();
        listEnum(CCEnumField.MemberCategory, regionType, dataSource, controller.signal).then(values => {
            setMemberCategories(values);
            setMemberCategory(old => {
                if (!values.includes(old))
                    return values[0];
                return old;
            });
        });

        listEnum(CCEnumField.ConditionCategory, regionType, dataSource, controller.signal).then(values => {
            values = values.filter(_ => _);
            setConditionCategories(values);
            setConditionCategory(old => {
                if (!values.includes(old))
                    return values[0];
                return old;
            });
        });

        listEnum(CCEnumField.MetricScope, regionType, dataSource, controller.signal).then(values => {
            setSrcs(values);
            setSrc(old => {
                if (!values.includes(old))
                    return values[0];
                return old;
            });
        });

        return () => controller.abort();
    }, [regionType, dataSource]);

    useEffect(() => {
        if (src === 'cond')
            setConditionCategory(conditionCategories[0]);
        else
            setConditionCategory('');
    }, [src, conditionCategories]);

    useEffect(() => {
        setSrc(CommonCoreConstraints.srcFromFocusLevel(focusLevel, srcs));
    }, [focusLevel, srcs]);

    function applyStart(year: string, lob: string, dataSource: string) {
        if (CommonCoreConstraints.dataSources(year, lob).includes(dataSource)) {
            setYear(year);
            setLob(lob);
        } else {
            // Find a year and lob that matches the data source
            if (!CommonCoreConstraints.yearDataSource[year].includes(dataSource))
                year = Object.keys(CommonCoreConstraints.yearDataSource).find(y => CommonCoreConstraints.yearDataSource[y].includes(dataSource))!;
            if (!CommonCoreConstraints.lobDataSources[lob].includes(dataSource))
                lob = CommonCoreConstraints.yearLobs[year].find(l => CommonCoreConstraints.lobDataSources[l].includes(dataSource))!;
            setYear(year);
            setLob(lob);
        }
        setDataSource(dataSource);
        setStartModal(false);
    }

    function updateSelection(newSelection: Array<Variable>) {
        setLibraryModal(false);
        setSelection(old => {
            if (old[0].dataset !== newSelection[0].dataset) {
                setLoading(true);
                setLibrary(new CommonCoreLibrary(regionType));
            }
            return newSelection;
        });
    }

    function createContainer(savedQueryId: number | null) {
        if (savedQueryId) {
            const options = Object.assign(dataConfig!.options);
            delete options.hideExplore;
            insightContainer.create({
                type: 'common-core',
                optionsJSON: JSON.stringify(dataConfig!.options),
                savedQueryId,
            }).catch(err => handleError(err, setError));
        }
        setSaveModal(false);
    }

    const CommonCoreBenchmarkView = useNewDesign ? NewCommonCoreBenchmarkView : DefaultCommonCoreBenchmarkView;
    const commonCoreBenchmarkViewProps = {
        regionTypes, regionType, setRegionType,
        memberCategories, memberCategory, setMemberCategory,
        conditionCategories, conditionCategory, setConditionCategory,
        src, setSrc,
        focusLevels, focusLevel, setFocusLevel,
        serviceCategory, setServiceCategory,
        demoBreakout, setDemoBreakout,
        year, lob, dataSource, setStartModal,
        dataConfig, loading, selection,
        setLibraryModal, setSaveModal,
        error, setError,
    };

    return <AuthWall siteFeature={SiteFeature.AccessCommonCore}>
        <Template>
            <CommonCoreBenchmarkView {...commonCoreBenchmarkViewProps} />

            {saveModal && dataConfig && <SaveModal onClose={createContainer} graph={getQueryStoreGraph() || dataConfig.queries[0]} reference="common-core" />}
            {libraryModal && dataConfig && library && !loading &&
                    <DataLibraryModal graph={dataConfig.queries[0]} selection={selection} library={library}
                        updateStrategy={basicMDUpdateStrategy}
                        onClose={(_, md) => updateSelection(md)} />}

            {startModal && <CommonCoreStartModal year={year} lob={lob} dataSource={dataSource} onClose={applyStart} onCancel={() => setStartModal(false)} />}
        </Template>
    </AuthWall>;
}

interface CommonCoreBenchmarkViewProps {
    regionTypes: Array<string>;
    regionType: string;
    setRegionType: (regionType: string) => void;
    memberCategories: Array<string>;
    memberCategory: string;
    setMemberCategory: (memberCategory: string) => void;
    conditionCategories: Array<string>;
    conditionCategory: string;
    setConditionCategory: (conditionCategory: string) => void;
    src: string;
    setSrc: (src: string) => void;
    focusLevels: Array<string>;
    focusLevel: string;
    setFocusLevel: (focusLevel: string) => void;
    serviceCategory: string;
    setServiceCategory: (serviceCategory: string) => void;
    demoBreakout: boolean;
    setDemoBreakout: (demoBreakout: boolean) => void;
    year: string;
    dataSource: string;
    setStartModal: (startModal: boolean) => void;
    dataConfig: any;
    loading: boolean;
    error: string;
    setError: (error: string) => void;
}

function DefaultCommonCoreBenchmarkView(props: CommonCoreBenchmarkViewProps) {
    const { 
        regionTypes, 
        regionType, 
        setRegionType, 
        memberCategories, 
        memberCategory, 
        setMemberCategory, 
        conditionCategories, 
        conditionCategory, 
        setConditionCategory, 
        src, 
        setSrc, 
        focusLevels, 
        focusLevel, 
        setFocusLevel, 
        serviceCategory, 
        setServiceCategory, 
        demoBreakout, 
        setDemoBreakout, 
        year, 
        dataSource, 
        setStartModal, 
        dataConfig, 
        loading,
        error, 
        setError
    } = props;
    const navigate = useNavigate();

    return <div className="pb-8">
        <PageMenu>
            <Button color="link" onClick={() => navigate('/healthcare-index')}><i className="icon-left" /> Back</Button><div className="flex-grow" />
            <div className="center">
                <h1>Common Core Benchmarks</h1>
            </div>
        </PageMenu>

        <div className="container mt-10">
            {error && <ErrorMessage error={error} onClick={() => setError('')} />}
            <div className="mb-2">
                        For more information on the methodology, please see the <Link to="/common-core/overview">Overview Page</Link>.
            </div>
            <div className="flex">
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                                Geography
                    </div>
                    <Select<string> data={regionTypes} value={regionType} onChange={setRegionType}>
                        {_ => _}
                        {_ => GeographyLabels[_]}
                    </Select>
                </div>
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                                Member Category
                    </div>
                    <Select<string> data={memberCategories} value={memberCategory} onChange={setMemberCategory}>
                        {_ => _}
                        {_ => _ || <em>All</em>}
                    </Select>
                </div>
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                                Service Categories
                    </div>
                    <Select<string> data={CommonCoreConstraints.serviceCategories(dataSource)} value={serviceCategory} onChange={setServiceCategory}>
                        {_ => _}
                        {_ => _}
                    </Select>
                </div>
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                                Focusing Options
                    </div>
                    <SelectMenu label={getFocusOptionLabel(focusLevel, src, conditionCategory)}>
                        <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                                    Focus Level
                        </div>
                        <Select<string> data={focusLevels} value={focusLevel} onChange={setFocusLevel}>
                            {_ => _}
                            {_ => FocusLevelLabels[_]}
                        </Select>

                        {focusLevel === 'cond' && src === 'cond' && <div className="mt-5">
                            <div className="font-semibold uppercase text-sm tracking-wide text-blue-500">
                                        Condition Category
                            </div>
                            <Select<string> data={conditionCategories} value={conditionCategory} onChange={setConditionCategory}>
                                {_ => _}
                                {_ => _ || <em>None</em>}
                            </Select>
                        </div>}

                        {focusLevel === 'service' && <div className="mt-5">
                            <div className="font-semibold uppercase text-sm tracking-wide text-blue-500">
                                        Focused Service
                            </div>
                            <Select<string> data={CommonCoreConstraints.focusLevelSrcs[focusLevel]} value={src} onChange={setSrc}>
                                {_ => _}
                                {() => FocusServiceLabels[src]}
                            </Select>
                        </div>}
                    </SelectMenu>
                </div>
            </div>
            <div className="my-4 flex">
                <div>
                    {CommonCoreConstraints.hasDemoBreakout(dataSource) && (demoBreakout ?
                        <Button color="yellow" onClick={() => setDemoBreakout(false)}><i className="icon-times" /> Remove Demographic Breakout</Button> :
                        <Button color="green" onClick={() => setDemoBreakout(true)}><i className="icon-plus" /> Add Demographic Breakout</Button>)}
                </div>
                <div className="flex-grow" />
                <div>
                    <Badge color="dark">Year: {year}</Badge>
                    <Badge color="dark">Source: {DataSourceLabels[dataSource]}</Badge>
                    <Button color="white" onClick={() => setStartModal(true)}>Change Source</Button>
                </div>
            </div>
        </div>

        <div className="mt-5 mx-10">
            {dataConfig && !loading && <InsightContainer type="query" dataConfig={dataConfig} />}
        </div>
    </div>;
}

function NewCommonCoreBenchmarkView(props: CommonCoreBenchmarkViewProps) {
    const { 
        regionTypes, 
        regionType, 
        setRegionType, 
        memberCategories, 
        memberCategory, 
        setMemberCategory, 
        conditionCategories, 
        conditionCategory, 
        setConditionCategory, 
        src, 
        setSrc, 
        focusLevels, 
        focusLevel, 
        setFocusLevel, 
        serviceCategory, 
        setServiceCategory, 
        demoBreakout, 
        setDemoBreakout, 
        year, 
        dataSource, 
        setStartModal, 
        dataConfig, 
        loading,
        error, 
        setError
    } = props;
    const [myFavorites, setFavorite] = useFavorite('Common Core Benchmarks');

    const isFavorite = useMemo(() => myFavorites.some(favorite => favorite.url === window.location.pathname), [myFavorites, window.location.pathname]);

    const FavoriteIcon = isFavorite ? StarFilled : Star;

    return <div className='px-10 py-8 bg-background-gray-100'>
        <Breadcrumb crumbs={[{name: 'Home', url: '/'}, {name: 'Common Core Benchmarks', url: '/common-core'}]} />
        <div className='flex mt-6 mb-8 leading-10 gap-3'>
            <FavoriteIcon size={32} className='text-gray-800 hover:cursor-pointer' onClick={setFavorite} />
            <h1 className='text-gray-800 text-[32px] font-light m-0'>Common Core Benchmarks</h1>
            <div className='grow'></div>
            <div className='text-sm align-middle'>
                <Link to="/common-core/overview"><Document className='inline pb-1' /> Overview and Notes</Link>
            </div>
        </div>

        <div className="mt-5">
            {error && <ErrorMessage error={error} onClick={() => setError('')} />}
            <div className="flex gap-2">
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide mt-2">
                                Geography
                    </div>
                    <Select<string> data={regionTypes} value={regionType} onChange={setRegionType}>
                        {_ => _}
                        {_ => GeographyLabels[_]}
                    </Select>
                </div>
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide mt-2">
                                Member Category
                    </div>
                    <Select<string> data={memberCategories} value={memberCategory} onChange={setMemberCategory}>
                        {_ => _}
                        {_ => _ || <em>All</em>}
                    </Select>
                </div>
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide mt-2">
                                Service Categories
                    </div>
                    <Select<string> data={CommonCoreConstraints.serviceCategories(dataSource)} value={serviceCategory} onChange={setServiceCategory}>
                        {_ => _}
                        {_ => _}
                    </Select>
                </div>
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide mt-2">
                        Focusing Options
                    </div>
                    <SelectMenu label={getFocusOptionLabel(focusLevel, src, conditionCategory)}>
                        <div className='p-3'>
                            <div className="font-semibold uppercase text-sm tracking-wide mt-2">
                                Focus Level
                            </div>
                            <Select<string> data={focusLevels} value={focusLevel} onChange={setFocusLevel}>
                                {_ => _}
                                {_ => FocusLevelLabels[_]}
                            </Select>

                            {focusLevel === 'cond' && src === 'cond' && <div className="mt-5">
                                <div className="font-semibold uppercase text-sm tracking-wide">
                                            Condition Category
                                </div>
                                <Select<string> data={conditionCategories} value={conditionCategory} onChange={setConditionCategory}>
                                    {_ => _}
                                    {_ => _ || <em>None</em>}
                                </Select>
                            </div>}

                            {focusLevel === 'service' && <div className="mt-5">
                                <div className="font-semibold uppercase text-sm tracking-wide">
                                    Focused Service
                                </div>
                                <Select<string> data={CommonCoreConstraints.focusLevelSrcs[focusLevel]} value={src} onChange={setSrc}>
                                    {_ => _}
                                    {() => FocusServiceLabels[src]}
                                </Select>
                            </div>}
                        </div>
                    </SelectMenu>
                </div>
            </div>
            <div className="my-4 flex">
                <div>
                    {CommonCoreConstraints.hasDemoBreakout(dataSource) && (demoBreakout ?
                        <OperationalTag type='red' renderIcon={Subtract} onClick={() => setDemoBreakout(false)}>Remove Demographic Breakout</OperationalTag> :
                        <OperationalTag type='green' renderIcon={Add} onClick={() => setDemoBreakout(true)}>Add Demographic Breakout</OperationalTag>)}
                </div>
                <div className="flex-grow" />
                <div>
                    <Tag type='warm-gray'>Year: {year}</Tag>
                    <Tag type='warm-gray'>Source: {DataSourceLabels[dataSource]}</Tag>
                    <OperationalTag type='cyan' onClick={() => setStartModal(true)}>Change Source</OperationalTag>
                </div>
            </div>
        </div>

        <div className="mt-5">
            {dataConfig && !loading && <InsightContainer type="query" dataConfig={dataConfig} />}
        </div>
    </div>;
}