import {observer} from 'mobx-react';
import {CvData, CvEmpty, CvRichText} from '../lib/types';
import React, {useContext, useEffect, useState} from 'react';
import {CanvasContext} from '../lib/context';
import CvRichTextContent from './cv-rich-text-content';
import CvInlineContent from './cv-inline-content';
import CvEmptyCell from './cv-empty';
import useConditionalRender from '../lib/useConditionalRender';
import ConditionalRenderAlert from './conditional-render-alert';
import Loading from '../../../components/loading';


type Props = {
    cell: CvRichText | CvData | CvEmpty,
    rowIndex: number,
    cellIndex: number
};

const holderClasses = 'cv-holder';
const conditionalRenderLabel = {
    'rich-text': 'rich text',
    'data': 'data insight container',
    'empty': 'empty cell'
};

const CvCellRenderer = observer((props: Props) => {
    const {cell, rowIndex, cellIndex} = props;
    const store = useContext(CanvasContext);
    const [conditionMet, loading] = useConditionalRender(cell.conditionalRender?.parameterConfigs, cell.conditionalRender, store.parameterRow, store.handleError);
    const [cellToRender, setCellToRender] = useState<CvRichText| CvData | CvEmpty | undefined>();

    useEffect(() => {
        if (!loading) {
            if (!conditionMet && cell.conditionalRender?.alternateIC)
                setCellToRender(cell.conditionalRender.alternateIC);
            else if (conditionMet || store.editing)
                setCellToRender(cell);
            else
                setCellToRender(undefined);
        }
    }, [conditionMet, loading, cell, store.parameterValue, store.editing]);

    function canEdit() {
        return cellToRender && cellToRender.type !== 'rich-text' && cellToRender.type !== 'empty';
    }

    return <div className={`${holderClasses} ${cell.size || 'xlg'}`}>
        <div className="flex space-x-1">
            {store.editing && canEdit() && <div className="cv-side-button cv-cell-control" onClick={() => store.startInsert(rowIndex, cellIndex)}>
                <i className="icon-edit"/>
            </div>}
            {store.editing && <div className="cv-side-button cv-cell-control" onClick={() => store.startConditionalRenderCellInsert(rowIndex, cellIndex)}>
                <i className="icon-question"/>
            </div>}
            {store.editing && !!cell.conditionalRender && <div className="cv-side-button cv-cell-control" onClick={() => store.startConditionalRenderAlternativeInsert(rowIndex, cellIndex)}>
                <i className="icon-plus" />
            </div>}
            {store.editing && cell.type !== 'empty' && <div className="cv-side-button cv-cell-control" onClick={() => store.removeItem(rowIndex, cellIndex)}>
                <i className="icon-times" />
            </div>}
        </div>
        {loading && cell.conditionalRender?.showLoading && <Loading />}
        {cellToRender && <div>
            {/* Figure out what to render */}
            {cellToRender.type === 'rich-text' && <CvRichTextContent rowIndex={rowIndex} index={cellIndex} text={cellToRender.value} />}
            {cellToRender.type === 'data' && <CvInlineContent visType={cellToRender.visType || 'query'} queryObj={cellToRender.queryObj} parameterConfigs={cellToRender.parameterConfigs || []} options={cellToRender.options || {}} />}
            {cellToRender.type === 'empty' && <CvEmptyCell rowIndex={rowIndex} index={cellIndex} />}
        </div>}
        {!!cell.conditionalRender && <ConditionalRenderAlert conditionMet={conditionMet} loading={loading} type={conditionalRenderLabel[cell.type]} hasAlternative={!!cell.conditionalRender?.alternateIC} />}
    </div>;
});

export default CvCellRenderer;