import Variable from '../../lib/metadata';
import {ConditionalTooltip} from '../tooltip';
import Checkbox from '../checkbox';

type Props = {
    variable: Variable
    selected?: boolean
    disabled?: boolean
    premium?: boolean
    onToggle: () => void
}

export default function DataPoint(props: Props) {
    const {variable, selected, disabled, onToggle, premium} = props;
    return (
        <li className={`data-point w-full flex items-center p-1 py-3 border-x-0 border-y border-y-background-gray-300 ${selected ? 'bg-background-gray-200' : ''} `}>
            <div className='pl-2 pt self-start'>
                <ConditionalTooltip
                    position='start'
                    condition={premium || false}
                    content={<PremiumContent />}
                    wide
                >
                    <Checkbox
                        color='dark'
                        checked={!!selected}
                        onChange={onToggle}
                        disabled={disabled || premium}
                    />
                </ConditionalTooltip>
            </div>
            <div className='pl-6 py-0 my-0'>
                <span className='text-lg'>{variable.label}</span>
                {variable.description && (
                    <div className='text-gray-400 text-sm'>{variable.description}</div>
                )}
            </div>
        </li>
    );
}

function PremiumContent() {
    return (
        <div>
            <p>This data is available to Premium access users only</p>
            <p>Contact your Torch representative to learn more.</p>
        </div>
    );
}

