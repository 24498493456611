import {useState} from 'react';
import {HexColorPicker} from 'react-colorful';
import './color-picker.scss';

type Props = {
    color: string;
    onChange: (color: string) => void;
    presetColors: string[];
};

export const ColorPicker = (props: Props) => {
    const {color, onChange, presetColors} = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="picker">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full flex justify-center items-center mb-2"
            >
                {isOpen ? (
                    <i className="icon-arrow-down" />
                ) : (
                    <i className="icon-arrow-up" />
                )}
            </button>

            {isOpen && (
                <HexColorPicker color={color} onChange={onChange} />
            )}

            <div className="picker__swatches">
                {presetColors.map((presetColor) => (
                    <button
                        key={presetColor}
                        className={`picker__swatch ${presetColor === color ? 'selected' : ''}`}
                        style={{background: presetColor}}
                        onClick={() => onChange(presetColor)}
                        data-testid={`color-swatch-${presetColor}`}
                    />
                ))}
            </div>
        </div>
    );
};