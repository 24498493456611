import React, {useEffect, useMemo, useState} from 'react';
import {Module} from '../../../../../lib/data-types';
import Select from '../../../../../components/select';
import {CRBRegions} from '../../../../tools/cr-benchmarks/constants';
import Loading from '../../../../../components/loading';
import Button from '../../../../../components/button';
import Badge from '../../../../../components/badge';
import {modules as modulesApi} from '../../../../../lib/api';
import fetchRegions from '../../../../tools/cr-benchmarks/regions';

type Props = {
    modules: Array<Module>,
    allModules: Array<Module>,
    toggle: (module: Module) => void,
    add: (module: Module) => void,
}

const moduleDatasets = [
    'percent-of-medicare-benchmarks-$',
    'percent-of-medicare-benchmarks-$-category',
    'percent-of-medicare-benchmarks-$-drg',
    'percent-of-medicare-benchmarks-$-mdc',
];

async function createNew(name: string, regionType: string, region: string): Promise<Module> {
    const module = await modulesApi.create({name, groupName: 'crb'});

    let rowCriteria: string | null = JSON.stringify([{
        column: regionType === 'msa' ? 'msa_division' : 'state_fips',
        'test': '=',
        'value': Number(region),
    }]);
    if (region === 'all')
        rowCriteria = null;

    for (let dataset of moduleDatasets) {
        dataset = dataset.replace('$', regionType === 'msa' ? 'msa-division' : 'state');
        await modulesApi.datasets.create({
            moduleId: module.id,
            dataset,
            rowCriteria,
        });
    }

    return module;
}

export default function CRBModulesAdmin(props: Props) {
    const {toggle, allModules} = props;

    const [regionType, setRegionType] = useState(CRBRegions[0].value);
    const [regions, setRegions] = useState<Array<{name: string, value: string}>>([]);
    const [region, setRegion] = useState('');

    useEffect(() => {
        const controller = new AbortController();
        const regions: Array<{name: string, value: string}> = [{name: 'All', value: 'all'}];
        fetchRegions(regionType, controller.signal).then(geoRegions => setRegions(regions.concat(geoRegions)));
        return () => controller.abort();
    }, [regionType]);

    const {stateModules, msaModules} = useMemo(() => {
        const modules = props.modules.filter(m => m.groupName === 'crb');
        modules.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        const stateModules: Array<Module> = [], msaModules: Array<Module> = [];
        for (const module of modules) {
            if (module.name.startsWith('state'))
                stateModules.push(module);
            else if (module.name.startsWith('msa'))
                msaModules.push(module);
        }
        return {stateModules, msaModules};
    }, [props.modules]);

    function add() {
        const regionName = regions.find(r => r.value === region)!.name;
        const moduleName = regionType + ':' + regionName;
        const module = allModules.find(m => m.groupName === 'crb' && m.name === moduleName);
        if (module)
            toggle(module);
        else
            createNew(moduleName, regionType, region).then(props.add);
        setRegion('');
    }

    return <div>
        <div className="text-blue-500 my-2">Commercial Reimbursement Benchmarks</div>
        <div className="px-4 border-l">
            <div className="my-2">
                <div className="font-medium">State</div>
                {!stateModules.length && <em className="text-gray-400">None</em>}
                {stateModules.map(mod => <Badge key={mod.id} color="light" pill onClose={() => toggle(mod)}>{mod.name}</Badge>)}
            </div>
            <div className="my-2">
                <div className="font-medium">MSA/Division</div>
                {!msaModules.length && <em className="text-gray-400">None</em>}
                {msaModules.map(mod => <Badge key={mod.id} color="light" pill onClose={() => toggle(mod)}>{mod.name}</Badge>)}
            </div>
            <div className="flex items-center">
                <div>
                    <Select data={CRBRegions} value={regionType} onChange={setRegionType}>
                        {_ => _.value}
                        {_ => _.name}
                    </Select>
                </div>
                <div>
                    {regions.length ?
                        <Select data={regions} value={region} onChange={setRegion} searchable>
                            {_ => _.value}
                            {_ => _.name}
                        </Select> :
                        <Loading small />}
                </div>
                <div>
                    <Button color="primary" onClick={() => add()}>Add</Button>
                </div>
            </div>
        </div>
    </div>;
}