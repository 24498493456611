import {QuantumGraph} from 'quantum-graph';
import {TransformCBs} from '../lib/data-types';

export type DataConfig = {
    queries: Array<QuantumGraph>,
    options?: any,
};

export function getConfigOption<T>(config: DataConfig, key: string): T | undefined {
    return config.options && config.options[key];
}

export type InsightContainerConfig = {
    id?: number,
    savedQueryId?: number,
    type: string,
    dataConfig: DataConfig,
    loadCB?: (promise: Promise<void>) => void,
    transforms?: TransformCBs
};