import Variable, {loadDatasetMetadata} from './metadata';
import fetchData from './data';
import {deserialize, GraphVersions, TestType} from 'quantum-graph';
import {dataRows, QueryFilter, QueryObj} from './data-types';
import {datasetByName, resolveField} from './data-defs';

function buildGraphObj(dataset: string, columns: Array<string>, timeVar?: Variable) {
    const graphObj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: 'dim',
                tableNodes: [
                    {
                        id: dataset,
                        dataset,
                        columns,
                    }
                ]
            }
        },
        distinct: true,
        filters: []
    };
    if (timeVar) {
        graphObj.filters!.push({
            column: {nodeId: dataset, column: timeVar.name},
            test: TestType.Eq,
            value: timeVar.getTag('LatestTime')!,
        });
    }
    return graphObj;
}

export async function getIdNameVars(dataset: string, signal: AbortSignal): Promise<[Variable, Variable, Variable | undefined]> {
    const metadata = await loadDatasetMetadata(dataset, signal);
    const idVar = metadata.find(v => v.getTag('FieldRole') === 'id');
    const nameVar = metadata.find(v => v.getTag('FieldRole') === 'name');
    const timeVar = metadata.find(v => v.getTag('Time'));
    if (!idVar || !nameVar)
        throw new Error('Cannot find suggestions. Missing metadata.');
    return [idVar, nameVar, timeVar];
}

export function getAffiliationIdFields(dataset: string) {
    const dataSource = datasetByName[dataset];
    return dataSource.foreignKeys.map(fk => {
        const resolution = resolveField(fk, dataset, datasetByName);
        if (resolution[0])
            return resolution[1];
        return fk.split(':')[0];
    });
}

export async function entitySearch(dataset: string, search: string, signal: AbortSignal, filters?: Array<QueryFilter>): Promise<Array<{id: number, key: number, name: string}>> {
    const [idVar, nameVar, timeVar] = await getIdNameVars(dataset, signal);
    const keyVar = datasetByName[dataset].keyField || `${dataset.replace('-', '_')}_dim_key`;
    const columns = [idVar.name, nameVar.name, keyVar];
    const graphObj = buildGraphObj(dataset, columns, timeVar);
    if (search) {
        graphObj.filters!.push({
            column: {nodeId: dataset, column: nameVar.name},
            test: TestType.Like,
            value: '%' + search + '%'
        });
    }

    if (filters) {
        graphObj.filters = [...graphObj.filters!, ...filters];
    }

    const graph = deserialize(graphObj);
    const data = await fetchData(graph, signal);
    return data.map(row => ({
        id: Number(row[idVar!.name]),
        key: Number(row[keyVar]),
        name: String(row[nameVar!.name])
    }));
}

export async function affiliatedEntitySearch(dataset: string, search: string, affiliatedIdColumn: string, affiliationType: string, signal: AbortSignal): Promise<dataRows> {
    const isFields = getAffiliationIdFields(dataset);
    const graphObj = buildGraphObj(dataset, isFields);
    graphObj.filters!.push({
        column: {nodeId: dataset, column: affiliatedIdColumn},
        test: TestType.Eq,
        value: search
    });

    if (affiliationType) {
        graphObj.filters!.push({
            column: {nodeId: dataset, column: 'type'},
            test: TestType.Eq,
            value: affiliationType
        });
    }

    const graph = deserialize(graphObj);
    return await fetchData(graph, signal);
}

export async function getEntityName(dataset: string, id: number, signal: AbortSignal): Promise<string> {
    const [idVar, nameVar, timeVar] = await getIdNameVars(dataset, signal);
    const graphObj = buildGraphObj(dataset, [idVar.name, nameVar.name], timeVar);
    graphObj.filters!.push({
        column: {nodeId: dataset, column: idVar.name},
        test: TestType.Eq,
        value: id
    });

    const graph = deserialize(graphObj);
    const data = await fetchData(graph, signal);
    if (!data.length)
        return '';
    return String(data[0][nameVar.name]);
}