import {ArrowRight} from '@carbon/icons-react';

type OpenButtonProps = {
    text: string
    onClick?: () => any
    style?: 'light' | 'dark'
    className?: string
}

export default function OpenButton({
    text,
    onClick,
    style = 'light',
    className = '',
}: OpenButtonProps) {
    const styleClasses =
        style === 'light'
            ? 'border border-milliman-blue-400 text-milliman-blue-400 hover:border-milliman-blue-500 hover:text-milliman-blue-500'
            : 'bg-milliman-slate-400 text-gray-200';

    return (
        <button
            onClick={onClick}
            className={`flex justify-center p-2 items-center ${styleClasses} ${className}`}
        >
            {text}
            <ArrowRight className='ml-4' />
        </button>
    );
}
