import {useCallback, useContext, useState} from 'react';
import {observer} from 'mobx-react';
import FilterStepsContext from '../libs/context';
import fetchData from '../../../lib/data';
import MultiSelectAutocomplete from '../../multi-select-autocomplete';
import {QuantumGraph} from 'quantum-graph';

type Props = {
    label: string,
    filterStoreVariable: string,
    variable: string,
    getDropdownQuery: (variable: string) => QuantumGraph
    resyncVariables?: Array<string>,
    placeholder?: string,
    popupWidth?: number,
    customDefault?: (values: Array<string>) => void,
}

const FilterStepMultiDropdown = observer((props: Props) => {
    const {label, filterStoreVariable, variable, getDropdownQuery, placeholder, popupWidth, customDefault, resyncVariables} = props;
    const [abortController, setAbortController] = useState<AbortController>();
    const filterStepsStore = useContext(FilterStepsContext);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getDropdownValuesCB = useCallback(async (value: string) => {
        let controller = new AbortController();
        if (abortController && !abortController.signal.aborted)
            abortController.abort();
        setAbortController(controller);
        const query = getDropdownQuery(variable);
        const data = await fetchData(query, controller.signal);
        return data.map(d => d[variable] as string);
        // eslint-disable-next-line
    }, (resyncVariables || []).map(v => filterStepsStore[v]));

    function onSelectAll(values: Array<string>, selected: boolean) {
        if (selected) {
            const uniqueList = new Set([...filterStepsStore[filterStoreVariable], ...values]);
            filterStepsStore[filterStoreVariable] = Array.from(uniqueList);
        } else {
            filterStepsStore[filterStoreVariable] = filterStepsStore[filterStoreVariable].filter((value: string) => !values.includes(value));
        }
    }

    return <div className="flex flex-col">
        <label>{label}</label>
        <MultiSelectAutocomplete
            values={filterStepsStore[filterStoreVariable]}
            onChange={v => filterStepsStore[filterStoreVariable] = v}
            getOptions={getDropdownValuesCB}
            placeholder={placeholder}
            popupWidth={popupWidth}
            onSelectAll={onSelectAll}
            customDefault={customDefault}/>
    </div>;
});

export default FilterStepMultiDropdown;