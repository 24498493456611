import {QuantumGraph} from 'quantum-graph';


type RecentActivity = {
    pageUrl: string,
    pageName?: string,
    toolName: string,
    timestamp: string,
    customQuery?: object
}

class ActivityTracker {
    private readonly ACTIVITY_TRACKER_KEY = 'recent_activity';
    private readonly MAX_ACTIVITY_QUEUE = 50;
    public activityQueue: Array<RecentActivity> = [];

    constructor() {
        this.activityQueue = this.getRecentActivityFromStorage();
    }

    /**
     * Returns the recent activity object from storage. Returns null if not set.
     * @returns RecentActivity | null
     */
    private getRecentActivityFromStorage(): Array<RecentActivity> {
        const payload = localStorage.getItem(this.ACTIVITY_TRACKER_KEY);
        if (payload) {
            let queue = JSON.parse(payload);
            if (!Array.isArray(queue))
                queue = [queue];
            return queue;
        }

        return [];
    }

    /**
     * Sets recent activity based on a page url, name, and an optional custom QuantumQuery
     * @param toolName <string>
     * @param pageName <string>
     * @param customQuery <QuantumGraph>
     * @param pageUrl <string>
     */
    public setRecentActivity(toolName: string, pageName?: string, customQuery?: QuantumGraph, pageUrl: string = window.location.href) {
        const payload: RecentActivity = {
            pageUrl,
            pageName,
            toolName,
            timestamp: new Date().toString(),
            customQuery: customQuery?.serialize()
        };
        let queue: Array<RecentActivity> = [];
        queue.push(payload);
        for (let i = 0; i < Math.min(this.activityQueue.length, this.MAX_ACTIVITY_QUEUE); i++) {
            if (!queue.some(q => q.pageUrl === this.activityQueue[i].pageUrl))
                queue.push(this.activityQueue[i]);
        }
        this.activityQueue = queue;
        localStorage.setItem(this.ACTIVITY_TRACKER_KEY, JSON.stringify(this.activityQueue));
    }

    /**
     * Deletes the recent activity object from storage
     */
    public deleteRecentActivity() {
        localStorage.removeItem(this.ACTIVITY_TRACKER_KEY);
    }
}

export default new ActivityTracker();