import React from 'react';
import {Link} from 'react-router-dom';
import Button from '../../../components/button';
import SiteName from '../../../components/SiteName';

const postBody = `{
    "email": "YOUR_EMAIL_HERE",
    "password": "YOUR_PASSWORD_HERE"
}`;

const authHeader = 'Authorization: Bearer YOUR_JWT_HERE';

export const ExampleQueryBody = `{
    "version": 2,
    "baseQueryGraph": {"baseQuery": {
        "queryType": "dim",
        "tableNodes": [{
            "id": "hospital-directory",
            "dataset": "hospital-directory",
            "columns": [
                "torch_facility_id",
                "facility_name",
                "category",
                "city_name",
                "abbreviated_state",
                "beds",
                "net_patient_revenue"
            ]
        }]
    }},
    "pagination": {
        "page": 0,
        "pageSize": 10
    }
}`;

export default function APIDocQuickStart() {
    return <div>
        <h2>Quick Start</h2>
        <p>
            Here is a quick guide to get you up and running with the <SiteName /> API.
        </p>

        <h3>Authorization</h3>
        <p>
            In order to start pulling down data, you'll first need to authenticate with your <SiteName /> account. To
            do this, send a <code>POST</code> request to <code>https://api-prod.torchinsight.com/v2/user/authenticate</code> with
            a message body that looks something like this:
        </p>
        <pre>{postBody}</pre>

        <p>
            If you supplied a valid email and password, the API will return a JSON Web Token (JWT) which you will use to
            authorize calls to the API. Add this JWT as an <code>Authorization</code> header in your request, so it looks like this:
        </p>
        <pre>{authHeader}</pre>
        <p>
            <em>For more info on the authorization API, check <Link to="/docs/api/authorization">here</Link>.</em>
        </p>

        <h3>Your First Query</h3>
        <p>
            Now that you are authenticated, you're ready to run your first query. To do so, we'll send a <code>POST</code> request
            to <code>https://api-prod.torchinsight.com/v2/quantum/data/execute</code> with the following body:
        </p>
        <pre>{ExampleQueryBody}</pre>

        <p>
            This query will return some basic fields for 10 hospitals from the <SiteName /> database.
            Congratulations! You have now made your first successful request to the Torch API.
        </p>

        <hr/>
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/api/authorization">Authorization</Button>
        </p>
    </div>;
}