
import {Link} from 'react-router-dom';
import AuthWall from '../../components/auth-wall';
import Template from '../../components/template';
import {SiteFeature} from '../../lib/check-access';
import Breadcrumb from '../../components/breadcrumb';
import './canvas.scss';
import CenteredLayout from '../../components/layout/centered-layout';
import {ReactNode, useEffect, useState} from 'react';
import {canvas} from '../../lib/api';
import {CanvasSectionType, CanvasType} from './lib/types';
import Loading from '../../components/loading';

export function CanvasLink(props: {name: string, url?: string}) {
    return <Link to={props.url || '/profile/' + encodeURIComponent(props.name.toLowerCase())} className="hover:no-underline hover:text-medinsight-vibrant-blue-300 items-center">
        <div className='px-4 py-4 h-full flex flex-col gap-2 bg-gray-100 shadow rounded text-black font-semibold my-4 hover:bg-gray-200'>
            {props.name}
        </div>
    </Link>;
}

interface CanvasCardProps {
    title?: string;
    children: ReactNode;
    className?:string;
  }
  
export const CanvasCard = (props: CanvasCardProps) => {
    const {title, children, className} = props;
  
    return (
        <div className={`bg-white rounded-md shadow-lg ${className}`}>
            {title && <header className="bg-blue-500 text-white rounded-t-md">
                <div className="flex items-center justify-center">
                    <h1 className="text-xl mb-5">
                        {title}
                    </h1>
                </div>
            </header>}
            <div className="p-4">
                {children}
            </div>
        </div>
    );
};

const breadCrumbs = [{name: 'Home', url: '/'}, {name: 'Canvas', url: '/user/canvas'}];

export const CanvasLandingPage = () => {
    const [canvases, setCanvases] = useState<Array<CanvasType>>([]);
    const [sections, setSections] = useState<Array<CanvasSectionType>>([]);

    useEffect(() => {
        const controller = new AbortController();
        canvas.listByUser(controller.signal).then(canvases => {
            setCanvases(canvases);
        });
        canvas.listSections(controller.signal).then(sections => {
            setSections(sections);
        });
        return () => controller.abort();
    }, []);

    return (
        <AuthWall siteFeature={SiteFeature.Dashboards}>
            <Template showFooter>
                <div className='canvas'>
                    <div className='canvas-header'>
                        <Breadcrumb crumbs={breadCrumbs} />
                        <div className='pb-4'>
                            <h1 className='text-center'>Canvases</h1>
                            <h3 className='text-center font-light'>
                                Explore Custom and Curated Content
                            </h3>
                        </div>
                    </div>
                    <div className='container pb-16'>
                        <CenteredLayout size={7}>
                            <div className='mt-5'>
                                <div className='text-center my-5'>
                                    <h1>Curated Content</h1>
                                </div>
                                {!sections.length && <Loading />}
                                <div className='grid grid-cols-2 gap-4'>
                                    {!!sections.length &&
                                        sections.map((section, i, sections) => (
                                            <CanvasCard
                                                key={i}
                                                className={`h-full ${
                                                    i === sections.length - 1 && sections.length % 2
                                                        ? 'col-span-2'
                                                        : ''
                                                }`}
                                                title={section.title}
                                            >
                                                {section.links.map((l) => (
                                                    <CanvasLink
                                                        key={l.url}
                                                        name={l.title}
                                                        url={l.url} 
                                                    />
                                                ))}
                                            </CanvasCard>
                                        ))}
                                </div>
                            </div>
                            {!!canvases.length && (
                                <div className='text-center'>
                                    <h1>Your Canvases</h1>
                                </div>
                            )}
                            {!!canvases.length && (
                                <CanvasCard className='mt-5' title={'Custom Canvases'}>
                                    {canvases.map((canvas, i) => (
                                        <CanvasLink key={i} name={canvas.name || canvas.title || ''} url={`/canvas/${canvas.id}/0`} />
                                    ))}
                                </CanvasCard>
                            )}
                        </CenteredLayout>
                    </div>
                </div>
            </Template>
        </AuthWall>
    );
};