import {observer} from 'mobx-react';
import {useContext} from 'react';
import {CanvasInsertContext} from '../../../lib/context';
import Select from '../../../../../components/select';
import {InaccessibleViewType} from '../../../lib/types';

const inaccessibleViewTypeLabels: Record<InaccessibleViewType, string> = {
    [InaccessibleViewType.disableIC]: 'Disable (Grey-out) Insight Container',
    [InaccessibleViewType.hideIC]: 'Hide Insight Container',
    [InaccessibleViewType.hideVariables]: 'Hide only Un-Authorized Columns'
};

function ConfigInaccessible() {
    const insertStore = useContext(CanvasInsertContext);
    
    return <div className='flex flex-col space-y-8'>
        <div>
            <h2>Configure View When Variables are Inaccessible</h2>
            <p>Choose how this Insight Container should be displayed if a user does not have access to some of the variables in the query</p>
        </div>
        <div>
            <Select<InaccessibleViewType> data={[InaccessibleViewType.disableIC, InaccessibleViewType.hideIC, InaccessibleViewType.hideVariables]} value={insertStore.inaccessibleViewType || ''} onChange={(v) => insertStore.inaccessibleViewType = v} >
                {_ => _}
                {_ => inaccessibleViewTypeLabels[_]}
            </Select>
        </div>
    </div>;
}

export default observer(ConfigInaccessible);
