import Template from '../../components/template';
import AuthWall from '../../components/auth-wall';
import React, {useEffect, useMemo, useState} from 'react';
import CanvasStore from './lib/store';
import {CanvasContext} from './lib/context';
import Canvas from './components/canvas';
import {SiteFeature} from '../../lib/check-access';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {loadParameterValues} from './lib/parameters';
import handleError, {isAbortError} from '../../lib/error';
import ErrorMessage from '../../components/error-message';


export default function CanvasViewPage() {
    const {id, title, section} = useParams<{id?: string, title?: string, section?: string}>();
    const location = useLocation();
    const search = location.search;
    const paramValues = useMemo(() => loadParameterValues(search), [search, id, title]);
    const navigate = useNavigate();
    const [err, setErr] = useState('');

    const store = useMemo(() => {
        return new CanvasStore(Number(id), title, navigate, Number(section));
    }, [id, title, navigate, section]);

    useEffect(() => {
        const controller = new AbortController();

        const asyncEffect = async () => {
            const searchParams = new URLSearchParams(search);
            store.draftMode = searchParams.get('draft') === 'true' && store.canEdit;
            store.editing = searchParams.get('editing') === 'true' && store.canEdit;
            const versionId = searchParams.get('versionId');
            store.versionId = versionId ? Number(versionId) : undefined;
            if (!store.board) {
                try {
                    await store.load(paramValues, controller.signal);
                } catch (e) {
                    try {
                        if (!isAbortError(e as Error) && store.canEdit) {
                            searchParams.set('draft', 'true');
                            const newSearchString = searchParams.toString();
                            navigate(`${location.pathname}?${newSearchString}`);
                        }
                    } catch (e) {
                        handleError(e as Error, setErr);
                    }
                }
            } else {
                store.loadParameter(paramValues, controller.signal).catch(e => handleError(e, setErr));
            }
        };
    
        asyncEffect();
    
        return () => controller.abort();
    }, [paramValues, store]);

    return <AuthWall siteFeature={SiteFeature.Dashboards}>
        <Template showFooter>
            {err && <ErrorMessage />}
            <CanvasContext.Provider value={store}>
                <Canvas />
            </CanvasContext.Provider>
        </Template>
    </AuthWall>;
}
