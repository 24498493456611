import {Layers} from './types';
import {LayerPropsWithType} from './types';
import {useState, useEffect} from 'react';

export function convertShapeToLayer(shapes:any, id:string, type:Layers, paint:any): LayerPropsWithType {
    return {id, layerType: type, data: shapes, paint};
}

export function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}
