import React from 'react';
import {makeAutoObservable} from 'mobx';
import {StandardRenderChildProps} from '../renderers/standard-data-renderer';
import {isDataVariable} from './common';
import {dataRows} from '../../lib/data-types';
import Variable from '../../lib/metadata';
import {DataConfig} from '../types';

export class ChartStore {
    private _yAxisName: string[] = [];
    private _data: dataRows = [];
    private _xAxisName: string;
    _maxXLabelLength: number | undefined;
    rowsToShow: number;
    private _metadata: Variable[];
    dataConfig: DataConfig;
    valueMeta: Variable[] = [];
    title: string;
    showLatestYears: boolean;
    legendLabelOverrides: Record<string, string>;
    additionalToolTipVariables: Array<string> = [];
    idVariable:string;
    labelFontSize = 14;
    nodeSize = false;
    nodeSizeVariable:string;

    constructor({data, metadata, dataConfig}: StandardRenderChildProps, maxRows: number) {
        this._metadata = metadata;
        this.dataConfig = dataConfig;
        this.yAxisNames = dataConfig.options?.value || [metadata.find(isDataVariable)?.name];
        this._xAxisName = dataConfig.options?.id ||
            metadata.filter((v) => v.getTag('FieldRole')! in ['name', 'Name'])[0]?.name ||
            metadata[0]?.name;
        this._maxXLabelLength = dataConfig.options?.maxXLabelLength || undefined;
        this.rowsToShow = dataConfig.options?.rowsToShow || Math.min(maxRows, data.length);
        this.title = dataConfig.options?.title || '';
        this.showLatestYears = dataConfig.options?.showLatestYears || false;
        this.legendLabelOverrides = dataConfig.options?.legendLabelOverrides || {};
        this.additionalToolTipVariables = dataConfig.options?.additionalToolTipVariables || [];
        this.labelFontSize = dataConfig.options?.labelFontSize || this.labelFontSize;
        this.data = data;
        this.idVariable = dataConfig.options?.idVariable || '';
        this.nodeSizeVariable = dataConfig.options?.nodeSizeVariable || '';
        this.nodeSize = dataConfig.options?.nodeSize || false;
        makeAutoObservable(this);
    }

    buildOptions() {
        return {
            value: this.yAxisNames,
            id: this.xAxisName,
            maxXLabelLength: this.maxXLabelLength,
            rowsToShow: this.rowsToShow,
            title: this.title,
            showLatestYears: this.showLatestYears,
            legendLabelOverrides: this.legendLabelOverrides,
            additionalToolTipVariables: this.additionalToolTipVariables,
            idVariable: this.idVariable,
            labelFontSize: this.labelFontSize,
            nodeSize: this.nodeSize,
            nodeSizeVariable: this.nodeSizeVariable
        };
    }

    getLegendLabel(variable: Variable | undefined, defaultValue: string): string {
        if (!variable)
            return defaultValue;

        return this.legendLabelOverrides[variable.name] || defaultValue;
    }

    get metadata() {
        return this._metadata;
    }

    get yAxisNames():string[] {
        return this._yAxisName;
    }

    set yAxisNames(newName:string[] | string) {
        let nameArray:string[] = newName instanceof Array ? newName : [newName];
        this.valueMeta = this._metadata.filter((v) => nameArray.some(n => n === v.name));
        this._yAxisName = nameArray;
    }

    get xAxisName():string {
        return this._xAxisName;
    }

    set xAxisName(name: string) {
        this._xAxisName = name;
    }

    get isYearChart() {
        let xAxisVariable = this._metadata.find(v => this.xAxisName === v.name);
        if (!xAxisVariable)
            return false;
        return xAxisVariable.getTag('Time') === 'year' || xAxisVariable.getTag('Format') === 'year' || xAxisVariable.name === 'year_key';
    }

    get isYearMonthChart() {
        let xAxisVariable = this._metadata.find(v => this.xAxisName === v.name);
        if (!xAxisVariable)
            return false;
        return xAxisVariable.getTag('Time') === 'month' || xAxisVariable.getTag('Format') === 'month' || xAxisVariable.name === 'year_month_key';
    }

    get isXAxisYearVariable() {
        if (!this.isYearChart)
            return undefined;
        
        return this._metadata.find(v => this.xAxisName === v.name);
    }

    get maxXLabelLength() {
        return this._maxXLabelLength;
    }

    set maxXLabelLength(length: number | undefined) {
        this._maxXLabelLength = length;
    }

    get data() {
        return this._data;
    }

    set data(data: dataRows) {
        this._data = data;
    }

    get year(): string | null {
        const yearKeyVar = this.getYearKeyVariable();
        if (yearKeyVar && this.xAxisName !== 'year_key' && this._data.length)
            return yearKeyVar.getValue(this._data[0]) as string;
        return null;
    }

    getYearKeyVariable() {
        return this._metadata.find((v) => v.name === 'year_key');
    }

    getSankeyFormatter(column:string) {
        const variable = this._metadata.find((v)=>v.name === column);
        return variable?.formatter;
    }

}

const ChartContext = React.createContext<ChartStore>(new ChartStore({data: [], metadata: [], dataConfig: {queries: []}}, 0));
export default ChartContext;