import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import QueryDataContext from './context';
import fetchData, {fetchDataWithUnAuthorizedVariables, fetchMetadata} from '../../../lib/data';
import handleError from '../../../lib/error';
import Loading from '../../../components/loading';
import ErrorMessage from '../../../components/error-message';
import NoAccessMsg, {NoAccessErrMsg} from '../../../components/no-access-msg';
import {TransformCBs} from '../../../lib/data-types';



/**
 * The query data renderer is to be used for data rendering where there is only one data query to load, but it is done
 * in paged segments. It is built with query manipulation in mind so sorting, pagination, and filtering and built in.
 * Like the standard data renderer, it takes care of loading data, loading metadata, cancelling/cleanup, and error
 * handling. Loading display is handled until metadata is loaded. Data loading display and count loading display are not
 * handled.
 *
 * All state is managed within a MobX store that is provided via the React Context API. So this must be used with a
 * context provider.
 *
 * Example:
 * <code>
 *     let queryDataStore = useMemo(() => QueryDataStore.empty(), []);
 *
 *     return <QueryDataContext.Provider value={queryDataStore}>
 *         <QueryDataRenderer>
 *             <YourVisHere />
 *         </QueryDataRenderer>
 *     </QueryDataContext.Provider>;
 * </code>
 *
 * In the above example, the vis `YourVisHere` must be an observer if it at all access the store in the context.
 */
const QueryDataRenderer = observer((props: {children: JSX.Element, loadCB?: (promise: Promise<void>) => void, transforms?: TransformCBs}) => {
    const {loadCB, transforms} = props;
    const store = useContext(QueryDataContext);
    const [err, setErr] = useState<string>('');
    const [noAccess, setNoAccess] = useState(false);

    // Load metadata
    useEffect(() => {
        store.mdLoading = true;
        const graph = store.dataConfig.queries[0];
        const controller = new AbortController();
        const promise = fetchMetadata(graph, controller.signal)
            .then(async variables => {
                await store.initMetadata(variables, transforms);
                store.initSort();
                store.updateGraphFilters();
                store.mdLoading = false;
                store.updateData();
            })
            .catch(err => {
                if (err.message.startsWith(NoAccessErrMsg))
                    setNoAccess(true);
                else throw err;
            })
            .catch(err => handleError(err, setErr));
        loadCB && loadCB(promise);

        return () => controller.abort();
    }, [store, store.metadataCounter, loadCB, transforms]);

    // Load Data once the metadata is loaded
    useEffect(() => {
        store.loading = true;
        if (!store.metadata.length || store.mdLoading)
            return;

        const graph = store.getGH(true, true, true).build();
        const controller = new AbortController();
        const promise = fetchDataWithUnAuthorizedVariables(graph, controller.signal)
            .then(({data, unAuthorizedVariables})=> {
                store.data = data;
                store.unAuthorizedVariables = unAuthorizedVariables || [];
                store.loading = false;
            })
            .catch(err => {
                if (err.message.startsWith(NoAccessErrMsg))
                    setNoAccess(true);
                else throw err;
            })
            .catch(err => handleError(err, setErr));
        loadCB && loadCB(promise);

        return () => controller.abort();
    }, [loadCB, store, store.dataCounter]);

    // Load count
    useEffect(() => {
        store.countLoading = true;
        const graph = store.getGH(true).buildCount();
        const controller = new AbortController();
        if (store.dataConfig?.options?.overrideCount || store.dataConfig?.options?.countlessPagination) {
            store.count = store.dataConfig.options.overrideCount || undefined;
            store.countLoading = false;
        } else {
            const promise = fetchData(graph, controller.signal)
                .then(rows => {
                    store.count = Number(rows[0]['count']);
                    store.countLoading = false;
                })
                .catch(err => {
                    if (err.message.startsWith(NoAccessErrMsg))
                        setNoAccess(true);
                    else throw err;
                })
                .catch(err => handleError(err, setErr));
            loadCB && loadCB(promise);
        }
        return () => controller.abort();
    }, [store, store.countCounter, loadCB]);

    function undo() {
        setErr('');
        store.undo();
    }

    if (noAccess)
        return <NoAccessMsg />;
    if (err)
        return <ErrorMessage actionText={store.canUndo ? 'Undo Last Change' : ''} onClick={() => undo()} />;
    if (store.mdLoading && store.metadata.length === 0)
        return <div className="dpp-container flex justify-center items-center">
            <Loading/>
        </div>;
    if (store.unAuthorizedVariables.length) {
        if (store.dataConfig.options?.inaccessibleViewType === 'hideIC')
            return <></>;
        if (store.dataConfig.options?.inaccessibleViewType === 'disableIC')
            return <div style={{opacity: 0.5, pointerEvents: 'none'}}>
                {props.children}
            </div>;
    }

    return props.children;
});

export default QueryDataRenderer;