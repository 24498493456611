import React, {FormEvent, useState} from 'react';
import Modal from '../modal';
import Button from '../button';
import {QuantumGraph} from 'quantum-graph';
import {savedQuery as savedQueryApi} from '../../lib/api';
import {SavedQuery} from '../../lib/data-types';
import handleError from '../../lib/error';
import ErrorMessage from '../error-message';

type Props = {
    onClose: (id: number | null) => void,
    graph: QuantumGraph,
    reference?: string
}

async function saveNewQuery(name: string, graph: QuantumGraph, reference: string): Promise<number> {
    const savedQuery: SavedQuery = {
        name,
        queryJSON: JSON.stringify(graph.serialize())
    };
    if (reference)
        savedQuery.reference = reference;
    const result = await savedQueryApi.create(savedQuery);
    return result.id;
}

async function saveExistingQuery(id: number, graph: QuantumGraph): Promise<number> {
    const savedQuery: SavedQuery = {
        id,
        queryJSON: JSON.stringify(graph.serialize()),
    };
    await savedQueryApi.modify(savedQuery);
    return id;
}

export default function SaveModal(props: Props) {
    const {onClose, graph, reference} = props;
    const [name, setName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const referenceId = Number(reference);
    const [saveNew, setSaveNew] = useState(!referenceId);
    const [saving, setSaving] = useState(false);

    function saveQuery(event?: FormEvent) {
        event && event.preventDefault();
        if (saveNew && !name)
            return;

        setErrorMessage('');
        setSaving(true);
        const promise = saveNew ?
            saveNewQuery(name, graph, reference || '') :
            saveExistingQuery(referenceId, graph);

        promise.then(id => onClose(id))
            .catch(error => handleError(error, setErrorMessage));
    }

    return <Modal title={'Save Data'} onClose={() => onClose(null)}>
        {errorMessage && <ErrorMessage error="There was a problem saving your data." />}

        {saveNew ?
            <div>
                <form onSubmit={saveQuery} className={'flex flex-col space-y-5'}>
                    <p>We will save this configuration of the data to your account. Enter a name for the saved data:</p>
                    <div className="flex flex-col">
                        <label className="font-bold">Name</label>
                        <input type="text" value={name} onChange={event => setName(event.target.value)} required/>
                    </div>

                    <div className="modal-buttons">
                        <Button color="primary" disabled={!name || saving}>Save</Button>
                        <Button color="white" onClick={() => onClose(null)}>Cancel</Button>
                    </div>
                </form>
            </div> :
            <div>
                <p>Do you want to overwrite the saved data?</p>

                <div className="modal-buttons">
                    <Button color="primary" onClick={() => saveQuery()} disabled={saving}>Yes, Overwrite</Button>
                    <Button color="blue" onClick={() => setSaveNew(true)} disabled={saving}>No, Save New</Button>
                    <Button color="white" onClick={() => onClose(null)}>Cancel</Button>
                </div>
            </div>}
    </Modal>;
}