import {
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    Layer,
    Tooltip,
    Modal
} from '@carbon/react';
import React, {useEffect, useContext, useState, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {NetworkContext} from '../lib/context';
import ComplianceIndicator from './compliance-indicator';
import {Renew, Information} from '@carbon/icons-react';
import './compliance-score.scss';
import Spinner from '../../../components/spinner';

type Props = {
    compactView?: boolean
}

function ComplianceScore(props: Props) {
    const {compactView} = props;
    const networkOptimizationStore = useContext(NetworkContext);
    const [loading, setLoading] = useState<boolean>(true);
    const {complianceScores} = networkOptimizationStore;

    useEffect(() => {
        setLoading(networkOptimizationStore.complianceScores.length === 0);
    }, [networkOptimizationStore.complianceScores.length]);

    const {selectedScore, headers} = useMemo(() => {
        const selectedScore = complianceScores?.find((score: { name: string }) => score.name === networkOptimizationStore.specialtyFilter)
            || networkOptimizationStore.calculateGeneralComplianceScores();
        const headers = Object.keys(selectedScore.scores).map((key) => selectedScore.scores[key].name);
        return {selectedScore, headers};
    }, [networkOptimizationStore.complianceScores, networkOptimizationStore.specialtyFilter, networkOptimizationStore.loadingMapData]);

    if (loading || !complianceScores || Object.keys(complianceScores).length === 0) {
        return <p>{networkOptimizationStore.readyToLoadData() && <span>Loading compliance scores...</span>}</p>;
    }

    const getTooltipLabel = (header: string): string => {
        const isSpecialtySelected = !!networkOptimizationStore.specialtyFilter;
    
        const tooltipLabels: { [key: string]: string } = {
            'Time': 'The percentage of the provider roster for the selected specialty that meets the driving time standard for the patient population.',
            'Distance': 'The percentage of the provider roster for the selected specialty that meets the driving distance standard for the patient population.',
            '# of Providers': 'The number of providers in the selected specialty that are included in the current roster.',
            '# of Beds': 'The number of beds in the selected Hospital.',
            'Total Compliance': isSpecialtySelected
                ? '✓ indicates the current roster is compliant with the CMS adequacy guidelines. ✗ indicates the plan is not in compliance with the CMS adequacy guidelines. Add providers from the table below to meet compliance standards.'
                : 'The number of specialties in your roster that meet the time and distance standard divided by the number of specialties that are included in the CMS adequacy standard.'
        };
    
        return tooltipLabels[header] || 'No tooltip available'; 
    };

    function renderDefaultView() {
        return (
            <Layer className='h-full'>
                <div className="compliance-score bg-white h-full flex flex-col relative overflow-visible"> 
                    <h1 className="text-lg font-semibold py-4 pl-4 m-0">
                        {networkOptimizationStore.specialtyFilter
                            ? `Estimated Total Compliance - ${networkOptimizationStore.specialtyFilter}`
                            : 'Estimated Total Compliance'}
                    </h1>
        
                    <TableContainer>
                        <DataTable
                            rows={[selectedScore]}
                            headers={headers.map((header) => ({
                                key: header,
                                header: header
                            }))}
                        >
                            {({headers}) => (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => {
                                                const headerLabel = header.header as string; 
                                                return (
                                                    <TableHeader key={header.key}>
                                                        <div className="flex items-center justify-end gap-1"> 
                                                            <span>{headerLabel}</span>
                                                            <Tooltip label={getTooltipLabel(headerLabel)} align="top" autoAlign>
                                                                <button type="button" aria-label="Information"> 
                                                                    <Information size={14} className="hover:text-blue-500" /> 
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </TableHeader>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {selectedScore.scores.map((score: { name: string, value: number, compliant: boolean, cssClasses?: string }, index: number) => (
                                                <TableCell key={index} className={`font-bold ${score.cssClasses}`}>
                                                    <div className="flex justify-end items-center space-x-3">
                                                        <span>{score.value}</span>
                                                        {score.compliant !== null && <ComplianceIndicator passed={score.compliant} />}
                                                    </div>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            )}
                        </DataTable>
                    </TableContainer>
                </div>
            </Layer>
        );
    }
        
    
    function renderCompactView() {
        return (
            <div className="bg-white">
                <h1 className="text-lg font-semibold py-4 pl-4 m-0">
                    {networkOptimizationStore.specialtyFilter
                        ? `Estimated Total Compliance - ${networkOptimizationStore.specialtyFilter}`
                        : 'Estimated Total Compliance'}
                </h1>
                <div className="w-full border-b border-gray-300 flex justify-end">
                    <button
                        disabled={networkOptimizationStore.disableRecalculateRevision}
                        className={`primary ${networkOptimizationStore.disableRecalculateRevision ? 'disabled' : ''}`}
                        onClick={() => networkOptimizationStore.recalculateRevision(new AbortController())}>
                        <span className="mr-2">Re-Calculate</span>
                        <Renew />
                    </button>
                </div>
                <ul className="flex flex-col">
                    {selectedScore.scores.map((score: { name: string, value: number, compliant: boolean, cssClasses?: string }, index: number) => (
                        <li key={index} className={`flex justify-between items-center border-b border-gray-300 p-2 ${score.cssClasses}`}>
                            <div className="flex items-center">
                                <span>{score.name}</span>
                                <Tooltip label={getTooltipLabel(score.name)} align="top" autoAlign>
                                    <button className="sb-tooltip-trigger ml-2" type="button" aria-label="Information">
                                        <Information className="hover:text-blue-500" />
                                    </button>
                                </Tooltip>
                            </div>
                            <div className="w-1/4 flex justify-end space-x-3 items-center">
                                <span>{score.value}</span>
                                {score.compliant !== null && <ComplianceIndicator passed={score.compliant} />}
                            </div>
                        </li>
                    ))}
                </ul>
                <Modal open={networkOptimizationStore.loadingRevisionRecalculation} modalLabel="Processing your data" passiveModal preventCloseOnClickOutside>
                    <div className='w-full p-4 flex space-x-8 items-center'>
                        <Spinner />
                        <div>
                            <p>Thank you for your patience! Recalculating your network adequacy may take a few minutes. <br />
                                <strong>Please avoid refreshing your browser.</strong></p>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
    

    return compactView ? renderCompactView() : renderDefaultView();
}

export default observer(ComplianceScore);
