import React, {ReactNode} from 'react';
import {NavLink} from 'react-router-dom';

export type NavItem = {
    path: string,
    text: ReactNode,
    component?: React.ReactElement
}

type Props = {
    navItems: Array<NavItem>,
    children?: ReactNode,
}

export default function SideNavLayout(props: Props) {
    return <div className="flex-row md:flex">
        <div className="shadow w-full mb-4 px-2 py-2 md:w-64 md:mb-0 md:shadow-none md:px-4 md:py-4 flex-shrink-0">
            {props.navItems.map(navItem => <div key={navItem.path} className="py-1">
                {navItem.path.startsWith('//') ?
                    <a href={navItem.path}>{navItem.text}</a> :
                    <NavLink to={navItem.path} className={(navData) => navData.isActive ? 'text-gray-600 font-bold' : ''}>{navItem.text}</NavLink>}
            </div>)}
        </div>
        <div className="flex-grow py-1 md:py-3 px-2 md:px-4">
            {props.children}
        </div>
    </div>;
}