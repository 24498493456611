import React, {memo} from 'react';
import {Handle, Position} from 'reactflow';

let FacilityNode = memo(({data}: {data: any}) => {
    let [collapsed, setCollapsed] = React.useState(true);

    let facilitiesToShow = []; 
    
    if (data.facilities.length) 
        facilitiesToShow = collapsed ? data.facilities.slice(0, 5) : data.facilities;

    return (
        <>
            <Handle
                type="target"
                position={Position.Top}
            />
            <div className='bg-white rounded border-gray-800 border-2 p-2 relative transition'>
                <div className='text-center font-semibold text-sm' >
                    Facilities
                </div> 
                {facilitiesToShow.map((facility: any) => {
                    return <div className='rounded p-2 m-1 bg-medinsight-orange-500 text-sm text-center text-white' key={facility.id}>{facility.name}</div>;
                })}
                {data.facilities && data.facilities.length > 5 && <div className='text-center text-xs text-slate-700 cursor-pointer' onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? 'Show more +' : 'Show less -'}
                </div>}
                <div className='text-xs italic text-slate-700 text-center'>
                    {data.facilities && `${data.facilities.length} total ${data.facilities.length > 1 ? 'facilities' : 'facility'}`}
                </div>
            </div>
        </>
    );
});

export default FacilityNode;
