import React, {ComponentType, useEffect, useState} from 'react';
import {dataRows} from '../../lib/data-types';
import {fetchDataWithUnAuthorizedVariables, fetchMetadata} from '../../lib/data';
import handleError from '../../lib/error';
import Loading from '../../components/loading';
import {DataConfig} from '../types';
import Variable from '../../lib/metadata';
import ErrorMessage from '../../components/error-message';
import NoAccessMsg, {NoAccessErrMsg} from '../../components/no-access-msg';

export type StandardRenderChildProps = {
    dataConfig: DataConfig,
    data: dataRows,
    metadata: Array<Variable>,
    loadCB?: (promise: Promise<void>) => void
};

type Props = {
    dataConfig: DataConfig,
    children: ComponentType<StandardRenderChildProps>,
    loadCB?: (promise: Promise<void>) => void
};

/**
 * The standard data renderer is to be used for all data rendering where there is only one data query to load. It
 * takes care of loading data, loading metadata, cancelling/cleanup, loading display, and error handling.
 * @constructor
 */
export default function StandardDataRenderer(props: Props) {
    const {dataConfig, loadCB} = props;
    const [loading, setLoading] = useState(true);
    const [mdLoading, setMdLoading] = useState(true);
    const [data, setData] = useState<dataRows>([]);
    const [metadata, setMetadata] = useState<Array<Variable>>([]);
    const [err, setErr] = useState<string>('');
    const [noAccess, setNoAccess] = useState(false);
    const [inaccessibleVariables, setInaccessibleVariables] = useState<Array<string>>([]);

    const graph = dataConfig.queries[0];
    useEffect(() => {
        setLoading(true);
        setMdLoading(true);
        setData([]);
        setMetadata([]);

        // Load the data from the graph
        const dataController = new AbortController();
        const promiseData = fetchDataWithUnAuthorizedVariables(graph, dataController.signal)
            .then(({data, unAuthorizedVariables}) => {
                setData(data);
                setInaccessibleVariables(unAuthorizedVariables || []);
                setLoading(false);
            })
            .catch(err => {
                if (err.message.startsWith(NoAccessErrMsg))
                    setNoAccess(true);
                else throw err;
            })
            .catch(err => handleError(err, setErr));
        loadCB && loadCB(promiseData);

        // Load the metadata from the graph
        const metadataController = new AbortController();
        const promiseMetadata = fetchMetadata(graph, metadataController.signal)
            .then(variables => {
                setMetadata(variables);
                setMdLoading(false);
            })
            .catch(err => {
                if (err.message.startsWith(NoAccessErrMsg))
                    setNoAccess(true);
                else throw err;
            })
            .catch(err => handleError(err, setErr));

        loadCB && loadCB(promiseMetadata);
        return function cleanup() {
            dataController.abort();
            metadataController.abort();
        };
    }, [graph, loadCB]);

    if (noAccess)
        return <NoAccessMsg />;
    if (err)
        return <ErrorMessage />;
    if (loading || mdLoading)
        return <Loading />;
    if (inaccessibleVariables.length) {
        if (dataConfig.options?.inaccessibleViewType === 'hideIC')
            return <></>;
        if (dataConfig.options?.inaccessibleViewType === 'disableIC')
            return <div style={{opacity: 0.5, pointerEvents: 'none'}}>
                {React.createElement(props.children, {dataConfig, metadata, data})}
            </div>;
    }


    return React.createElement(props.children, {dataConfig, metadata, data});
}