import {QueryObj} from '../../lib/data-types';
import {deserialize, GraphVersions, JoinType, QuantumGraph, TestType} from 'quantum-graph';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import Variable, {loadDatasetMetadata} from '../../lib/metadata';
import {addTimeFilters, createTableNodes, getDefaultColumns} from '../../pages/tools/affiliation-explorer/query';
import {DataDefRepo} from '../../lib/user-data-defs';

async function createEntityAffiliationQuery(dataset: string, entityId: number | number[], idVar: Variable | string, dataDefRepo: DataDefRepo, controller: AbortController): Promise<QuantumGraph> {
    const toDataset = dataset.split(':')[1];
    const toVars: Array<Variable> = getDefaultColumns(await loadDatasetMetadata(toDataset, controller.signal));
    const query: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: QueryType.Dim,
                tableNodes: createTableNodes(toDataset, '-to', toVars)
            },
            joins: [{
                joinType: JoinType.Inner,
                node: {
                    baseQuery: {
                        queryType: QueryType.Affiliation,
                        tableNodes: [
                            {
                                id: 'affiliation',
                                dataset: dataDefRepo.datasetByName[dataset].name,
                                columns: [
                                    'type',
                                    'effective_begin',
                                    'effective_end'
                                ]
                            }
                        ]
                    }
                }
            }]
        },
        filters: [Array.isArray(entityId) ? {
            column: {
                nodeId: 'affiliation',
                column: typeof idVar === 'string' ? idVar : idVar.name
            },
            test: TestType.In,
            value: entityId
        } : {
            column: {
                nodeId: 'affiliation',
                column: typeof idVar === 'string' ? idVar : idVar.name
            },
            test: TestType.Eq,
            value: entityId
        }],
        sort: [{
            column: {
                nodeId: 'affiliation',
                column: 'type'
            },
            asc: true
        }]
    };

    addTimeFilters(query, [], toVars);

    return deserialize(query);
}

export {
    createEntityAffiliationQuery
};