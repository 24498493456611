import {observer} from 'mobx-react';
import {useContext, useMemo} from 'react';
import Button from '../../../../components/button';
import {CanvasInsertContext} from '../../lib/context';
import CanvasICList from './ic-configs';
import auth from '../../../../lib/auth';

function VisTypeSelector() {
    const insertStore = useContext(CanvasInsertContext);

    const icList = useMemo(() => {
        let list = CanvasICList.filter(ic => !ic.hide);
        if (!auth.hasRole('admin'))
            list = list.filter(ic => !ic.adminOnly);
        return list;
    }, []);

    return <div>
        What kind of content do you want to insert?
        <div className='grid grid-cols-6 gap-2 mt-2'>
            {icList.map((ic, i) => <Button key={i} color="light" onClick={() => insertStore.visConfig = ic}>
                {!ic.componentIcon && <i className={`${ic.icon} block text-8xl mt-1`} />}
                {ic.componentIcon && <div className='flex items-center justify-center mt-1'>{ic.componentIcon}</div>}
                <p>{ic.label}</p>
            </Button>)}
        </div>
    </div>;
}

export default observer(VisTypeSelector);
