import {useEffect, useRef, useState} from 'react';
import Variable from '../../lib/metadata';
import {DragDropContext, Droppable, Draggable} from '@hello-pangea/dnd';
import Toggle from '../toggle';
import {MAX_STICKY_HEADERS} from '../table';
import {DataConfig, getConfigOption} from '../../insight-container/types';
import {Move} from '@carbon/icons-react';

export function ColumnOrder({dataConfig, selection, updateColumnOrder, onRemove}: {dataConfig: DataConfig, selection: Array<Variable>, updateColumnOrder: (stickyColumns: Variable[], columnOrder: Variable[]) => void, onRemove: (v: Variable) => void }) {
    const stickyColumnsString = getConfigOption<Array<string>>(dataConfig, 'stickyColumns') || [];
    const [stickyColumns, setStickyColumns] = useState<Array<Variable>>(selection.filter(v => stickyColumnsString.find(s => s === v.name)));
    const [columnOrder, setColumnOrder] = useState<Array<Variable>>(selection.filter(v => !stickyColumnsString.find(s => s === v.name)));
    const scrollableDivRef = useRef(null);
    const [scroll, setScroll] = useState<boolean>(false);

    useEffect(() => {
        setScroll((stickyColumns.length + columnOrder.length) < selection.length);
        setStickyColumns(selection.filter(v => stickyColumnsString.find(s => s === v.name)));
        setColumnOrder(selection.filter(v => !stickyColumnsString.find(s => s === v.name)));
    }, [selection]);

    useEffect(() => {
        if (scrollableDivRef.current && scroll) {
            const current: any = scrollableDivRef.current;
            current.scrollTop = current.scrollHeight;
        }
    }, [columnOrder]);

    function setColumns(newStickyColumns: Variable[], newColumnOrder: Variable[]) {
        setStickyColumns(newStickyColumns);
        setColumnOrder(newColumnOrder);
        updateColumnOrder(newStickyColumns, newColumnOrder);
    }

    function removeVariable(v: Variable) {
        setColumns(stickyColumns.filter(c => c.name !== v.name), columnOrder.filter(c => c.name !== v.name));
        onRemove(v);
    }
    
    return <DragDropContext onDragEnd={(result) => {
        if (result.destination) {
            let removed:Variable;
            const newStickyColumns = Array.from(stickyColumns);
            const newColumnOrder = Array.from(columnOrder);

            if (result.source.droppableId === 'sticky-column-order') {
                removed = newStickyColumns.splice(result.source.index, 1)[0];
            } else {
                removed = newColumnOrder.splice(result.source.index, 1)[0];
            }

            if (result.destination.droppableId === 'sticky-column-order') {
                newStickyColumns.splice(result.destination.index, 0, removed);
            } else {
                newColumnOrder.splice(result.destination.index, 0, removed);
            }

            setScroll(false);
            setColumns(newStickyColumns, newColumnOrder);
        }
    }}>
        <div className='bg-background-gray-100 h-full overflow-y-scroll mb-4' ref={scrollableDivRef}>
            <div className='bg-background-gray-200 flex p-2 pl-4 items-center leading-tight text-xs'>
                <h4 className='m-0 w-1/6'>Move Column</h4>
                <h4 className='m-0 w-1/6'>Freeze Column</h4>
                <h4 className='m-0 flex-grow'>Column Name</h4>
            </div>
            <Droppable droppableId='sticky-column-order' isDropDisabled={stickyColumns.length >= MAX_STICKY_HEADERS}
                renderClone={(provided, snapshot, rubric) => <DraggedVariableCard provided={provided} variableName={stickyColumns[rubric.source.index].label} checked />}>
                {provided => <div ref={provided.innerRef} {...provided.droppableProps} className='divide-y border-b-4 border-b-gray-300'>
                    {stickyColumns.map((variable, i) => <VariableCard key={variable.getId()} variable={variable} i={i} checked onChange={() => setColumns(stickyColumns.filter(c => c.name !== variable.name), [variable].concat(columnOrder))} onRemove={removeVariable} />)}
                    {provided.placeholder}
                </div>}
            </Droppable>
            <Droppable droppableId='column-order'
                renderClone={(provided, snapshot, rubric) => <DraggedVariableCard provided={provided} variableName={columnOrder[rubric.source.index].label} />}>
                {provided => <div ref={provided.innerRef} {...provided.droppableProps} className='divide-y'>
                    {columnOrder.map((variable, i) => <VariableCard key={variable.getId()} variable={variable} i={i} disabled={stickyColumns.length >= MAX_STICKY_HEADERS} onChange={() => setColumns(stickyColumns.concat(variable), columnOrder.filter(c => c.name !== variable.name))} onRemove={removeVariable} />)}
                    {provided.placeholder}
                </div>}
            </Droppable>
        </div>
    </DragDropContext>;
}

type VariableCardProps = {
    variable: Variable;
    i: number;
    onChange: () => void;
    onRemove: (v: Variable) => void;
    disabled?: boolean;
    checked?: boolean;
}
function VariableCard(props: VariableCardProps) {
    const {variable, i, disabled, checked, onChange, onRemove} = props;

    return <Draggable draggableId={variable.getId()} index={i}>
        {provided => <div ref={provided.innerRef} className='p-2 px-4 flex items-center bg-background-gray-100' {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className='w-1/6' ><Move /></div>
            <div className='w-1/6 flex items-center'><Toggle disabled={disabled} checked={!!checked} onChange={onChange} size='sm' /></div>
            <div className='w-1/2 pl-1'>{variable.label}</div>
            <div className='flex-grow text-right' onClick={() => onRemove(variable)}><i className="icon-times" /></div>
        </div>}
    </Draggable>;
}

function DraggedVariableCard(props: {provided: any, variableName: string, checked?: boolean}) {
    const {provided, variableName, checked} = props;

    return <div ref={provided.innerRef} className='p-1 px-4 flex items-center bg-background-gray-100 !z-[9001]' {...provided.draggableProps} {...provided.dragHandleProps}>
        <div className='w-1/6'><Move /></div>
        <div className='w-1/6 flex items-center'><Toggle checked={!!checked} onChange={() => {}} size='sm' /></div>
        <div className='w-1/2 pl-1'>{variableName}</div>
        <div className='flex-grow text-right'><i className="icon-times" /></div>
    </div>;
}