import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Modal from '../modal';
import Loading from '../loading';
import {QuantumGraph} from 'quantum-graph';
import Variable from '../../lib/metadata';
import Library, {Section} from '../../lib/library';
import CategoryGroup from './category-group';
import LibraryNav from './library-nav';
import titleCase from '../../lib/title-case';
import './data-library.scss';
import CategoryList from './category-list';
import Accordion, {AccordionCategory} from '../accordion';
import V2GraphHandler from '../../lib/graph-tools/graph-handler/v2-graph-handler';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import {DataConfig} from '../../insight-container/types';
import {ColumnOrder} from './column-order';
import Button from '../button';
import handleError from '../../lib/error';
import {HeaderContext} from '../header/context';
import {TextInput} from '@carbon/react';
import {Search} from '@carbon/icons-react';

type UpdateStrategy = (graph: QuantumGraph, selection: Array<Variable>, change: Array<Variable>, currentSection?: Section) => [QuantumGraph, Array<Variable>];

export function basicMDUpdateStrategy(graph: QuantumGraph, selection: Array<Variable>, change: Array<Variable>): [QuantumGraph, Array<Variable>] {
    const metadata = selection.slice();
    for (const variable of change) {
        const i = metadata.indexOf(variable);
        if (i >= 0)
            metadata.splice(i, 1);
        else
            metadata.push(variable);
    }
    return [graph, metadata];
}

type Props = {
    graph: QuantumGraph,
    selection: Array<Variable>,
    disabledVariables?: Array<Variable>,
    library: Library,
    updateStrategy: UpdateStrategy,
    onClose: (graph: QuantumGraph, metadata: Array<Variable>, dataConfig?: any) => void,
    variableFilter?: (v: Variable) => boolean,
    subCatWhiteList?: Array<string>,
    subCatBlackList?: Array<string>,
    title?:string,
    dataConfig?: DataConfig,
}

export default function DataLibraryModal(props: Props) {
    const {library, onClose: onApply, updateStrategy, disabledVariables, variableFilter, subCatWhiteList, subCatBlackList, dataConfig = {queries: []}} = props;
    const [graph, setGraph] = useState(props.graph);
    const [selection, setSelection] = useState(props.selection);
    const [section, setSection] = useState(0);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentCategory, setCurrentCategory] = useState<{grain: number, category: number}>({grain: 0, category: 0});
    const [newDataConfig, setNewDataConfig] = useState(dataConfig);
    // eslint-disable-next-line
    const [error, setError] = useState('');
    const {useNewDesign} = useContext(HeaderContext);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        library.loadAllNames(controller.signal)
            .then(() => library.getSection(section).loadMetadata(controller.signal))
            .then(() => setLoading(false))
            .catch(err => handleError(err, setError));
        return () => controller.abort();
    }, [library, section]);

    const handleToggle = useCallback((v: Variable | Array<Variable>) => {
        const change = v instanceof Array ? v : [v];
        const [newGraph, newSelection] = updateStrategy(graph, selection, change, library.getSection(section));
        setGraph(newGraph);
        setSelection(newSelection);
    }, [graph, selection, updateStrategy, section]);

    const activeSection = library.getSection(section);
    const activeIndex = activeSection.index;
    const visibleIndex = useMemo(() => activeIndex.search(search).filter(variableFilter || (() => true)), [search, activeIndex, variableFilter]);

    function reset() {
        setGraph(props.graph);
        setSelection(props.selection);
        setNewDataConfig(dataConfig);
    }

    function getLabel(grain: string) {
        const dsInfo = activeSection.dsInfo[grain];
        return dsInfo?.label || titleCase(grain);
    }

    function getAccordionCategories(): Array<AccordionCategory> {
        const categories: Array<AccordionCategory> = [];
        visibleIndex.map((grain, index, i) => {
            const catChildren = <CategoryList
                dimGrainIndex={index}
                selection={selection}
                onToggleAllOff={toggledVars => handleToggle(selection.filter(v => !toggledVars.find(t => t.name === v.name) && !disabledVariables?.find(d => d.name === v.name)))}
                onClick={catIndex => setCurrentCategory({grain: i, category: catIndex})}
                catWhiteList={subCatWhiteList}
                catBlackList={subCatBlackList}/>;
            const category: AccordionCategory = {
                name: getLabel(grain),
                children: catChildren
            };
            categories.push(category);
            return null;
        });
        return categories;
    }

    const activeCategory = useMemo(() => {
        let activeCategory;
        if (search) {
            activeCategory = visibleIndex.map((grain, index, i) => <div key={i}>
                {index.map((category, variables, j) => <div key={j}>
                    <CategoryGroup variables={variables} disabledVariables={disabledVariables || []}
                        selection={selection} onToggle={handleToggle} label={getLabel(grain)}/>
                </div>)}
            </div>);
        } else {
            visibleIndex.map((grain, index, i) => {
                index.map((category, variables, j) => {
                    if (i === currentCategory.grain && j === currentCategory.category) {
                        activeCategory = <CategoryGroup variables={variables} disabledVariables={disabledVariables || []} selection={selection} onToggle={handleToggle} label={getLabel(grain)}/>;
                    }
                    return null;
                });
                return null;
            });
        }
        return activeCategory;
    }, [search, visibleIndex, currentCategory, disabledVariables, handleToggle, selection]);

    const title = props.title ? <span>Edit Table - <em>{props.title}</em></span> : 'Edit Table';

    if (loading) {
        return <Modal title={title} onClose={() => onApply(graph, selection)} hideCloseButton className='data-library-modal'>
            <div className="text-center">
                <Loading/>
                <br/>
                The data library is loading...
            </div>
        </Modal>;
    }

    function updateColumnOrder(stickyColumns: Variable[], columnOrder: Variable[]) {
        const newOrder = stickyColumns.concat(columnOrder);
        const newGraph = new V2GraphHandler(graph as V2QuantumGraph).setColumnOrder(newOrder).build();
        setGraph(newGraph);
        setNewDataConfig((oldDC) => ({...oldDC, options: {...oldDC.options, stickyColumns: stickyColumns.map(v => v.name)}}));
        setSelection(newOrder);
    }

    return (
        <Modal
            title={title}
            onClose={() => onApply(props.graph, props.selection, props.dataConfig?.options)}
            className='data-library-modal relative'
            hideCloseButton
            size='lg'
            primaryButtonText='Apply'
            primaryButtonOnClick={() => onApply(graph, selection, newDataConfig.options)}
            secondaryButtonText='Cancel'
            secondaryButtonOnClick={() => onApply(props.graph, props.selection, props.dataConfig?.options)}
        >
            <div className="md:grid md:grid-cols-3 divide-x">
                <div className="md:col-span-2">
                    <div id='data-library'>
                        <div id='data-list'>
                            <LibraryNav library={library} section={section} setSection={setSection} />
                            <h3 className='mb-4'>Select Columns</h3>
                            <div className={`w-full mb-4 ${useNewDesign ? 'flex' : 'relative'}`}>
                                {useNewDesign ? <div className='bg-background-gray-100 h-10 w-10 flex items-center justify-center border-b border-background-gray-500'><Search className='' /></div> : <i className='icon-search absolute top-1/2 left-3 transform -translate-y-1/2 mr-0.5'></i>}
                                {useNewDesign ? <TextInput id='data-library-search' labelText='Search Data Library' hideLabel placeholder='Search column names and descriptions' value={search} onChange={(e) => setSearch(e.target.value)} /> :
                                    <input
                                        className='w-full !pl-10 !border-y-0 !border-x-0 !border-b mt-0.5 mb-px !border-black !rounded-none focus:border-2 focus:border-blue-400 focus:outline-none focus:ring-0 focus:mt-0 focus:mb-0'
                                        type='search'
                                        placeholder='Search column names and descriptions'
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />}
                            </div>
                            <div className='md:grid md:grid-cols-3 md:gap-6 h-[48vh] pt-4 bg-background-gray-100'>
                                <div className='md:col-span-1 overflow-y-auto overflow-x-hidden'>
                                    <div className='p-2 font-bold text-black tracking-wider text-lg sticky top-0 border-t-2 border-t-gray-100 bg-background-gray-100'>
                                        <i className='icon-categories mr-2'>
                                            <span className='path1'/>
                                            <span className='path2'/>
                                            <span className='path3'/>
                                            <span className='path4'/>
                                            <span className='path5'/>
                                        </i>

                                        Categories
                                    </div>

                                    <Accordion categories={getAccordionCategories()} firstDrawerOpen />
                                </div>

                                <div className='mt-5 md:mt-0 md:col-span-2 overflow-y-auto'>
                                    {activeCategory}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='md:mt-0 md:col-span-1 pl-3' id='column-order'>
                    <h3 className='mb-0 mt-12'>Manage Column Order</h3>
                    <div className='text-xs font-light'>{selection.length} columns selected</div>
                    <ColumnOrder selection={selection} updateColumnOrder={updateColumnOrder} dataConfig={newDataConfig} onRemove={handleToggle} />
                </div>
            </div>
            {useNewDesign ? <Button color='link' className='!absolute bottom-1 right-1' onClick={() => reset()}><i className='icon-reset text-sm mr-1' /> Reset</Button> : <div className="modal-buttons flex justify-end">
                <Button color='link' onClick={() => reset()}><i className='icon-reset text-sm mr-1' /> Reset</Button>
                <div className='grow'></div>
                <Button color='light' onClick={() => onApply(props.graph, props.selection, props.dataConfig?.options)}>Cancel</Button>
                <Button color='blue' onClick={() => onApply(graph, selection, newDataConfig.options)}>Apply</Button>
            </div>}
        </Modal>);
}


