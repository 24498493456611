import {CrudAction, CrudApi, FieldType} from './types';
import {dataRow, dataRows} from '../../../lib/data-types';

export default class CrudActionHandler {

    activeItem: dataRow;
    items: dataRows;
    fields: Array<FieldType>;
    api: CrudApi;

    constructor(activeItem: dataRow, items: dataRows, fields: Array<FieldType>, api: CrudApi) {
        this.activeItem = activeItem;
        this.items = items;
        this.fields = fields;
        this.api = api;
    }

    handle(action: CrudAction, item?: dataRow): Promise<dataRows> {
        const itemId = this.activeItem['id'] as number;
        const idx = this.items.findIndex(item => item['id'] === itemId);
        const newItems = this.items.slice();

        if (item) {
            // Create the object to save, including only editable fields
            const obj: dataRow = {};
            this.fields.filter(f => f.type === 'boolean').forEach(f => item[f.name] = !!item[f.name]); // Make sure bools are set, not null
            this.fields.filter(f => !f.readonly).forEach(f => obj[f.name] = item[f.name]);

            if (action === CrudAction.Edit) {
                const editMethod = this.api.hasOwnProperty('modify') ? 'modify' : 'update';
                return this.api[editMethod]!(obj)
                    .then(() => {
                        // Update the list with the new item
                        newItems[idx] = item;
                        return newItems;
                    });
            } else if (action === CrudAction.Create) {
                return this.api.create(obj)
                    .then(update => {
                        item['id'] = update['id'];
                        newItems.push(item);
                        return newItems;
                    });
            }
        }
        if (action === CrudAction.Delete) {
            return this.api.delete(itemId)
                .then(() => {
                    // Remove active item from the full list
                    newItems.splice(idx, 1);
                    return newItems;
                });
        }

        return Promise.resolve(this.items);
    }
}
