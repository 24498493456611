function relativeDate(date: Date) {
    const diff = Math.round(((new Date() as any) - (new Date(date) as any)) / 1000);

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 30;
    const year = month * 12;

    if (diff < 30) {
        return 'just now';
    } else if (diff < minute) {
        return diff + ' seconds ago';
    } else if (diff < 2 * minute) {
        return 'a minute ago';
    } else if (diff < hour) {
        return Math.floor(diff / minute) + ' minutes ago';
    } else if (diff < day) {
        let value = Math.floor(diff / hour);
        return `${value} ${(value === 1) ? 'hour' : 'hours'} ago`;
    } else if (diff < day * 2) {
        return 'yesterday';
    } else if (diff < week) {
        return Math.floor(diff / day) + ' days ago';
    } else if (diff < month) {
        let value = Math.floor(diff / week);
        return `${value} ${(value === 1) ? 'week' : 'weeks'} ago`;
    } else if (diff < year) {
        let value = Math.floor(diff / month);
        return `${value} ${(value === 1) ? 'month' : 'months'} ago`;
    } else {
        let value = Math.floor(diff / year);
        return `${value} ${(value === 1) ? 'year' : 'years'} ago`;
    }
}

export default relativeDate;