import React, {useContext} from 'react';
import Variable from '../../lib/metadata';
import Modal from '../modal';
import './source-modal.scss';
import getDateCoverage from '../../lib/date-coverage';
import {HeaderContext} from '../header/context';

type Props = {
    variable: Variable | Variable[],
    onClose: () => void,
}

export function SourceInfo({variable}:{variable:Variable}) {
    const source = variable.getTag('Source');
    const sourceDescription = variable.getTag('SourceDescription');
    const lastUpdated = variable.getTag('LastUpdated');
    const dateCoverage = getDateCoverage(variable);
    const {useNewDesign} = useContext(HeaderContext);

    const SourceInfoView = useNewDesign ? NewSourceInfoView : DefaultSourceInfoView;
    return <SourceInfoView {...{variable, source, sourceDescription, dateCoverage, lastUpdated}} />;
}

function DefaultSourceInfoView({variable, source, sourceDescription, dateCoverage, lastUpdated}: {variable: Variable, source: string | undefined, sourceDescription: string | undefined, dateCoverage: string | null, lastUpdated: string | undefined}) {
    return <>
        <h2>{variable.label}</h2>
        {variable.description && <p className="text-gray-500 whitespace-pre-line">{variable.description}</p>}
        {source && <div className="mt-6">
            <label>Source</label>
            <p>{source}</p>
        </div>}
        {sourceDescription && <div className="mt-6">
            <label>Source Description</label>
            <p>{sourceDescription}</p>
        </div>}
        {dateCoverage && <div className="mt-6">
            <label>Data Date Coverage</label>
            <p>{dateCoverage}</p>
        </div>}
        {lastUpdated && <div className="mt-6">
            <label>Last Updated</label>
            <p>{new Date(lastUpdated).toLocaleDateString('en-US', {timeZone: 'utc'})}</p>
        </div>}
    </>;
}

function NewSourceInfoView({variable, source, sourceDescription, dateCoverage, lastUpdated}: {variable: Variable, source: string | undefined, sourceDescription: string | undefined, dateCoverage: string | null, lastUpdated: string | undefined}) {
    return <div className='ml-4 space-y-6'>
        <div>
            <label className='font-bold font-sans'>Field Name</label>
            <div className='mt-1'>{variable.label}</div>
        </div>
        <div>
            <label className='font-bold font-sans'>Description</label>
            <div className='mt-1'>{variable.description}</div>
        </div>
        {source && <div>
            <label className='font-bold font-sans'>Source</label>
            <div className='mt-1'>{source}</div>
        </div>}
        {sourceDescription && <div>
            <label className='font-bold font-sans'>Source Description</label>
            <div className='mt-1'>{sourceDescription}</div>
        </div>}
        {dateCoverage && <div>
            <label className='font-bold font-sans'>Data Date Coverage</label>
            <div className='mt-1'>{dateCoverage}</div>
        </div>}
        {lastUpdated && <div>
            <label className='font-bold font-sans'>Last Updated</label>
            <div className='mt-1'>{new Date(lastUpdated).toLocaleDateString('en-US', {timeZone: 'utc'})}</div>
        </div>}
    </div>;
}

export default function SourceModal(props: Props) {
    const {variable, onClose} = props;
    let varsToShow = Array.isArray(variable) ? variable : [variable];

    return <Modal title="Field Info" onClose={onClose} className="source-modal" primaryButtonText='Close' primaryButtonOnClick={onClose} >
        {varsToShow.map(v => <SourceInfo variable={v} key={v.getId()} />)}
    </Modal>;
}