
type classNameObject = {
    [className: string]: boolean | undefined,
};

export default function classNames(...values: Array<string | classNameObject | undefined>): string {
    let classes: Array<string> = [];
    for (const value of values) {
        if (!value)
            continue;
        if (typeof value === 'string')
            classes.push(value);
        else {
            const obj = value as classNameObject;
            classes = classes.concat(Object.keys(value).filter(c => obj[c]));
        }
    }
    return classes.join(' ');
}
