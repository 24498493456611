import Variable from '../metadata';
import {searchFilter, VariableFilter} from '../search-and-filter';


export type SubCategoryIndex = {
    [category: string]: Array<Variable>,
}

export class CategoryIndex {

    index: SubCategoryIndex;

    constructor() {
        this.index = {};
    }

    add(variable: Variable) {
        const subCategory = variable.getCategory();
        if (!this.index[subCategory])
            this.index[subCategory] = [];
        this.index[subCategory].push(variable);
    }

    getCategories(): Array<string> {
        return Object.keys(this.index)
            .sort((a, b) => {
                if (a === 'General') return -1;
                if (b === 'General') return 1;
                return a < b ? -1 : a > b ? 1 : 0;
            });
    }

    getVariables(): Array<Variable> {
        return Object.values(this.index)
            .reduce((a, b) => a.concat(b), []);
    }

    sort() {
        Object.keys(this.index).forEach(key => this.index[key].sort());
    }

    map<T>(f: (category: string, variables: Array<Variable>, i: number) => T): Array<T> {
        return this.getCategories().map((c, i) => {
            const variables = this.index[c];
            return f(c, variables, i);
        });
    }
}

export default class LibraryIndex {

    categoryIndex: Record<string, CategoryIndex>;
    dimGrainIndex: Record<string, CategoryIndex>;

    constructor(metadata: Array<Variable>) {
        this.categoryIndex = {};
        this.dimGrainIndex = {};

        for (const variable of metadata) {
            const category = variable.getTag('Category') || variable.dataset || 'General';
            if (!this.categoryIndex[category])
                this.categoryIndex[category] = new CategoryIndex();
            this.categoryIndex[category].add(variable);

            const dsGroup = variable.getDsGroup();
            if (!this.dimGrainIndex[dsGroup])
                this.dimGrainIndex[dsGroup] = new CategoryIndex();
            this.dimGrainIndex[dsGroup].add(variable);
        }
        Object.keys(this.categoryIndex).forEach(key => this.categoryIndex[key].sort());
        Object.keys(this.dimGrainIndex).forEach(key => this.dimGrainIndex[key].sort());
    }

    getGrains(): Array<string> {
        const grains = Object.keys(this.dimGrainIndex);
        grains.sort();
        return grains;
    }

    getCategories(): Array<string> {
        const categories = Object.keys(this.categoryIndex);
        categories.sort();
        return categories;
    }

    getVariables(): Array<Variable> {
        return Object.values(this.categoryIndex)
            .flatMap(i => i.getVariables());
    }

    map<T>(f: (grain: string, index: CategoryIndex, i: number) => T): Array<T> {
        return this.getCategories().map((g, i) => f(g, this.categoryIndex[g], i));
    }

    search(query: string): LibraryIndex {
        if (!query || query.length < 3)
            return this;
        const variables = this.getVariables().filter(searchFilter(query));
        return new LibraryIndex(variables);
    }

    filter(variableFilter: VariableFilter): LibraryIndex {
        const variables = this.getVariables().filter(variableFilter);
        return new LibraryIndex(variables);
    }

}