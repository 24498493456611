import {useMemo, useRef, useState} from 'react';
import SourceModal from '../../components/source-modal';
import StandardDataRenderer, {StandardRenderChildProps} from '../renderers/standard-data-renderer';
import {DataConfig} from '../types';
import Popup from '../../components/popup';


export function SingleValueVis(props: StandardRenderChildProps) {
    const {dataConfig, data, metadata} = props;
    const [sourceModalOpen, setSourceModalOpen] = useState(false);
    const [year, setYear] = useState(Math.max(...data.map((d) => d.year_key as number)));
    const divRef = useRef<HTMLDivElement>(null);
    const years = useMemo(() => data.map((d) => d.year_key as number).sort((a, b) => a - b), [data]);
    const valueMeta = useMemo(() => metadata.find((v) => v.name === dataConfig.options.variableName) || metadata[0], [metadata, dataConfig.options.variableName]);
    const yearMeta = useMemo(() => metadata.find((v) => v.name === 'year_key')!, [metadata]);
    const dataRow = useMemo(() => data.find(r => r.year_key === year) || data[0], [year, data]);
    const displayValue = (dataRow && valueMeta && (!!dataRow[valueMeta.name] || dataRow[valueMeta.name] === 0)) ? valueMeta.formatSimple(dataRow) : '—';

    return <div className='h-full flex flex-col' style={{minHeight: '200px', container: 'single-value'}}>
        <div className='bg-white flex flex-col justify-between items-center grow rounded-b'>
            {valueMeta.label && <div className='flex flex-col justify-end basis-1/4' >
                <h3 className={`m-0 pb-${valueMeta.formatSimple(dataRow).length > 16 ? '4' : '2'} text-center align-bottom`}>{valueMeta.label}<i className="icon-info align-super text-xs text-gray-500 cursor-pointer" onClick={() => setSourceModalOpen(true)} /></h3>
            </div>}
            {sourceModalOpen && <SourceModal variable={valueMeta} onClose={() => setSourceModalOpen(false)} />}
            {valueMeta && <div className='grow w-full flex justify-center flex-col min-h-fit'>
                <div className='w-full flex justify-center leading-none' style={{containerType: 'inline-size'}}>
                    <span className='font-bold text-center break-words' style={{fontSize: `min(${12 * (12 / Math.max(Math.min(valueMeta.formatSimple(dataRow).length, 16), 8))}cqw, 12cqh)`}} >{displayValue}</span>
                </div>
            </div>}
            <div className='bg-white rounded-b px-3 py-1 shadow-lg w-full'>
                <div className='flex flex-col items-center'>
                    <div className='text-xs text-gray-400 font-light italic text-center hover:text-gray-500 pb-1'>{dataConfig.options.extraText || '\u00A0'}</div>
                    {yearMeta && <div ref={divRef} className='text-l text-gray-500 font-semibold hover:font-bold hover:text-gray-700 cursor-pointer px-6'>{yearMeta.formatSimple(dataRow)}</div>}
                    <Popup matchWidth targetRef={divRef}>
                        {years.map((y) => <div key={y} className='text-l text-gray-500 font-semibold hover:font-bold hover:text-gray-700 cursor-pointer  text-center' onClick={() => setYear(y)}>{y}</div>)}
                    </Popup>
                </div>
            </div>
        </div>
    </div>;
};

export default function SingleValueRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void}) {
    return <StandardDataRenderer dataConfig={props.dataConfig} loadCB={props.loadCB}>
        {SingleValueVis}
    </StandardDataRenderer>;
}