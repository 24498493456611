export enum RowCountOperators {
    'equal' = '=',
    'gt' = '>',
    'gtequal' = '>=',
    'lt' = '<',
    'ltequal' = '<='
}

export function compareOperands(leftOperand: number, operator: RowCountOperators, rightOperand: number): boolean {
    if (operator === '=')
        return leftOperand === rightOperand;
    else if (operator === '>')
        return leftOperand > rightOperand;
    else if (operator === '>=')
        return leftOperand >= rightOperand;
    else if (operator === '<')
        return leftOperand < rightOperand;
    else if (operator === '<=')
        return leftOperand <= rightOperand;
    else
        throw new Error(`Unknown operator ${operator}`);
}