import {DataConfig} from '../../types';
import {DataDefRepo} from '../../../lib/user-data-defs';
import {makeAutoObservable} from 'mobx';
import createGraphHandler from '../../../lib/graph-tools/graph-handler/create';
import Variable from '../../../lib/metadata';
import {ColDef, FilterModel} from 'ag-grid-community';
import {dataRows} from '../../../lib/data-types';
import Library from '../../../lib/library';
import createLibrary from '../../../lib/library/create';
import {downloadGraph} from '../../../lib/download';
import IQueryData from '../query-data-renderer/query-data-interface';
import Filter from '../../../lib/filter';
import {TestType} from 'quantum-graph';


export default class AGGridDataStore implements IQueryData {
    dataConfig: DataConfig;
    dataDefRepo: DataDefRepo;
    dataPromise: Promise<dataRows> | null = null;

    private _dataLoading: boolean;
    private _pristine: boolean;
    private _mdLoading: boolean;
    private _mdCounter: number;
    private _dataCounter: number;
    private _columnDefinitions: Array<ColDef>;
    private _metadata: Array<Variable>;
    private _data: dataRows;
    private _blockSize: number;
    private _page: number;
    private _library: Library;
    private _filters: Array<Filter>;
    private _sorts: Array<[Variable, boolean]>;
    private _rowCount: number;

    constructor(dataConfig: DataConfig, datasetRepo: DataDefRepo, blockSize = 100) {
        this.dataConfig = dataConfig;
        this.dataDefRepo = datasetRepo;
        this._dataLoading = false;
        this._pristine = true;
        this._mdLoading = false;
        this._mdCounter = 0;
        this._dataCounter = 0;
        this._columnDefinitions = [];
        this._metadata = [];
        this._data = [];
        this._blockSize = blockSize;
        this._page = 0;
        this._library = createLibrary(dataConfig.queries[0], this.dataDefRepo, this.dataConfig.options?.connectingFacts);
        this._filters = [];
        this._sorts = [];
        this._rowCount = 0;
        makeAutoObservable(this);
    }

    private variableToColDefType(type: string): string | undefined {
        switch (type) {
        case 'decimal':
        case 'bigint':
        case 'numeric':
        case 'tinyint':
        case 'smallint':
        case 'int':
        case 'float':
            return 'numericColumn';
        case 'time':
        case 'datetime':
        case 'datetime2':
        case 'Date':
        case 'smalldatetime':
            return 'date';
        case 'bit_true_false':
        case 'bit_yes_no':
            return 'boolean';
        default:
            return undefined;
        }
    }

    private filterModelTypeToTestType(type: string): TestType {
        switch (type) {
        case 'lessThan':
            return TestType.Lt;
        case 'lessThanOrEqual':
            return TestType.LtEq;
        case 'equals':
            return TestType.Eq;
        case 'notEqual':
            return TestType.NEq;
        case 'greaterThanOrEqual':
            return TestType.GtEq;
        case 'greaterThan':
            return TestType.Gt;
        case 'inRange':
            return TestType.In;
        case 'notInRange':
            return TestType.NotIn;
        case 'contains':
            return TestType.Like;
        case 'isNull':
            return TestType.Null;
        case 'notIsNull':
            return TestType.NotNull;
        default:
            return TestType.Eq;
        }
    }

    static empty(): AGGridDataStore {
        const datasetRepo = {datasets: [], datasetByName: {}};
        return new AGGridDataStore({queries: []}, datasetRepo);
    }

    get loading() {
        return this._dataLoading;
    }

    set loading(value: boolean) {
        this._dataLoading = value;
    }

    get pristine() {
        return this._pristine;
    }

    set pristine(value: boolean) {
        this._pristine = value;
    }

    get mdLoading() {
        return this._mdLoading;
    }

    set mdLoading(value: boolean) {
        this._mdLoading = value;
    }

    get mdCounter() {
        return this._mdCounter;
    }

    set mdCounter(value: number) {
        this._mdCounter = value;
    }

    get dataCounter() {
        return this._dataCounter;
    }

    set dataCounter(value: number) {
        this._dataCounter = value;
    }

    get columnDefinitions() {
        return this._columnDefinitions;
    }

    get data() {
        return this._data;
    }

    set data(rows: dataRows) {
        this._data = rows;
    }

    get count(): number | undefined {
        return this._rowCount;
    }

    set count(value: number | undefined) {
        this._rowCount = value || 0;
    }

    get blockSize() {
        return this._blockSize;
    }

    set blockSize(value: number) {
        this._blockSize = value;
    }

    get page() {
        return this._page;
    }

    set page(value: number) {
        this._page = value;
    }

    get metadata() {
        return this._metadata;
    }

    set metadata(variables: Array<Variable>) {
        this.parseMetadata(variables);
    }

    get library() {
        return this._library;
    }

    set library(value: Library) {
        this._library = value;
    }

    get filters(): Array<Filter> {
        return this._filters;
    }

    set filters(filterModel: FilterModel) {
        this._filters = [];
        Object.keys(filterModel).forEach(key => {
            const test = this.filterModelTypeToTestType(filterModel[key].type);
            this._filters.push({
                variable: this.metadata.find(v => v.name === key)!,
                test,
                value: test === TestType.Like ? `%${filterModel[key].filter}'` : filterModel[key].filter
            });
        });
    }

    get sorts(): Array<[Variable, boolean]> {
        return this._sorts;
    }

    set sorts(value: Array<[Variable, boolean]>) {
        this._sorts = value;
    }

    updateData() {
        this._dataCounter++;
    }

    updateMetadata() {
        this._mdCounter++;
    }

    getGraph() {
        return this.dataConfig.queries[0];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getGH(filter = false, sort = false, paginate = false) {
        const gh = createGraphHandler(this.getGraph(), this.dataDefRepo);
        // gh.applyPagination(this._page, this._blockSize);
        return gh;
    }

    parseMetadata(variables: Array<Variable>) {
        this._metadata = variables;
        const stickyColumns = this.dataConfig.options.stickyColumns || [];
        this._columnDefinitions = [];
        variables.forEach(variable => {
            let colDef: ColDef = {
                headerName: variable.label,
                field: variable.name,
                tooltipComponentParams: {variable},
                headerTooltip: variable.description,
                type: this.variableToColDefType(variable.type)
            };
            if (stickyColumns.includes(variable.name))
                colDef.pinned = 'left';
            this._columnDefinitions.push(colDef);
        });
    }

    setRowWindow(start: number) {
        this._page = Math.floor(start / this._blockSize);
    }

    updateConfig(dataConfig: DataConfig) {
        let roughEquals: boolean;
        if (this.getGraph().getVersion() !== dataConfig.queries[0].getVersion()) {
            roughEquals = false;
        } else {
            roughEquals = this.getGH().roughEquals(dataConfig.queries[0]);
        }

        this.dataConfig = dataConfig;
        if (!roughEquals) {
            this.updateMetadata();
        }
        this.updateData();
    }

    download() {
        const graph = this.getGH().build();
        downloadGraph(graph, this.data.length, this.dataDefRepo);
    }

    getLocked(): Array<Variable> {
        return [];
    }
}