import AdminCrudTable from '../crud-table';
import {users} from '../../../lib/api';
import React, {useMemo, useRef} from 'react';
import {ActionObj} from '../crud-table/types';
import auth from '../../../lib/auth';


export default function UsersAdmin() {
    const iframe = useRef<HTMLIFrameElement>(null);

    const fields = useMemo(() => [
        {name: 'id', label: 'Id', show: true, type: 'numeric'},
        {name: 'name', label: 'Name', show: true, type: 'text'},
        {name: 'email', label: 'Email', show: true, type: 'text'},
        {name: 'active', label: 'Active', show: true, readonly: true, type: 'text'},
        {name: 'password', label: 'Password', show: false, type: 'password', note: 'Enter value to change password'},
        {name: 'status', label: 'Status', show: false, type: 'text'},
        {name: 'landing', label: 'Landing', show: false, type: 'text'},
        {name: 'userAgreement', label: 'User Agreement Version', show: false, type: 'numeric'},
        {name: 'throttleLimit', label: 'Throttle Limit', show: false, type: 'numeric'},
        {name: 'accessRoles', label: 'Access Roles', show: false, type: 'accessRoles'},
        {name: 'modules', label: 'Modules', show: false, type: 'modules'}
    ], []);

    const actions: ActionObj = useMemo(() => ({
        Proxy: async user => {
            const token = await auth.proxy(user['id'] as number);
            if (iframe.current && iframe.current.contentWindow)
                iframe.current.contentWindow.postMessage(token, window.location.protocol + '//' + process.env.REACT_APP_TI_DOMAIN);

            setTimeout(() => {
                window.location.href = '/';
            }, 150);
        }
    }), []);

    return <AdminCrudTable api={users} title="User" fields={fields} actions={actions} urlPath="/site-admin/users" notice={<p>All changes must have a support ticket created in the <a target="_blank" rel="noreferrer" href="https://torchinsight.atlassian.net/jira/software/projects/SOPS/boards/13">Service Ops</a> board.</p>}>
        <iframe ref={iframe} title="ti" src={'//' + process.env.REACT_APP_TI_DOMAIN + '/login'} frameBorder="0" style={{visibility: 'hidden', height: 0}} />
    </AdminCrudTable>;
}