import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {DataConfig} from '../../types';
import StandardDataRenderer, {StandardRenderChildProps} from '../../renderers/standard-data-renderer';
import {ResponsivePie} from '@nivo/pie';
import {getTextWidth, limitRowsForChart, PlotColors, PlotTheme} from '../common';
import ChartContext, {ChartStore} from '../context';
import {observer} from 'mobx-react-lite';
import ChartContainer from '../container';
import AdditionalToolTipVariables from '../additional-tooltip-variables';
import {animated} from '@react-spring/web';
import {dataRow} from '../../../lib/data-types';

const MAX_SLICES = 99;

export const PieChartVis = observer(() => {
    let {yAxisNames, rowsToShow, data, xAxisName, valueMeta, labelFontSize} = useContext(ChartContext);
    let yAxisName = yAxisNames[0];
    let rows: Array<dataRow & {rowIndex: number}> = useMemo(
        () =>
            data
                .filter((r) => !!r[yAxisName])
                .sort((a, b) => (b[yAxisName] as any) - ((a[yAxisName] as any) || 0))
                .slice(0, rowsToShow)
                .map((row, index) => ({...row, rowIndex: index})),
        [data, rowsToShow, yAxisName],
    );
    let valueName = yAxisName;
    let valueMetadata = valueMeta[0];
    let labelSkipAngle = useMemo(() => rows.reduce((p, c) => p + getTextWidth(valueMetadata.formatSimple(c)), 0) / (rows.length / 0.52), [rows]);
    let ref = useRef<HTMLDivElement>(null);
    let [containerWidth, setContainerWidth] = useState(800);
    let rowTotal = useMemo(() => rows.reduce((p, c) => p + parseFloat(c[yAxisName]!.toString()), 0), [rows]);
    let nonSkippedRowTotal = useMemo(() => rows.reduce((numberBelowSkipAngle, row) => (parseFloat(row[yAxisName]!.toString()) / rowTotal) * 360 >= labelSkipAngle ? numberBelowSkipAngle + parseFloat(row[yAxisName]!.toString()) : numberBelowSkipAngle, 0), [rows, labelSkipAngle]);

    useEffect(() => {
        if (!ref.current)
            return;

        const observer = new ResizeObserver(entries => {
            setContainerWidth(entries[0].contentRect.width);
        });
        observer.observe(ref.current);
        return () => {
            if (ref.current)
                observer.unobserve(ref.current);
        };
    }, [ref]);

    return <ChartContainer divRef={ref} maxRows={MAX_SLICES}>
        <ResponsivePie
            data={rows}
            value={valueName}
            id={xAxisName}
            margin={{top: 35, bottom: 35, left: nonSkippedRowTotal > rowTotal / 2 ? containerWidth / 4 : 35, right: nonSkippedRowTotal > rowTotal / 4 ? containerWidth / 4 : 35}}
            startAngle={0}
            endAngle={360}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={PlotColors}
            theme={PlotTheme}
            valueFormat={valueMetadata.formatter.formatSimple.bind(valueMetadata.formatter)}
            activeOuterRadiusOffset={8}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [['darker', 2.5]],
            }}
            arcLabelsSkipAngle={labelSkipAngle}
            arcLinkLabelsSkipAngle={labelSkipAngle}
            arcLinkLabelComponent={({label, style}) => {
                let lines = [];
                let labelWords = label.toString().split(' ');
                do {
                    let line = [];
                    while (getTextWidth(line.join(' ')) < 60 && labelWords.length > 0) {
                        line.push(labelWords.shift()!);
                    }
                    lines.push(line.join(' '));
                } while (getTextWidth(labelWords.join(' ')) > 60);
                let initialDy = `${.3 - ((Math.min(lines.length, 3) - 1) * .6)}em`;
                return (<animated.g>
                    <path d={style.path.get()} fill='none' stroke={style.textColor.get()} />
                    <text width={10} fontSize={labelFontSize} color={style.textColor.get()} textAnchor={style.textAnchor.get()} transform={style.textPosition.get()} dy={-(lines.length * 10)} >{
                        lines.map((line, index) => (
                            <tspan x={0} dy={index === 0 ? initialDy : '1.2em'} key={index}>{line}</tspan>
                        ))
                    }</text>
                </animated.g>
                );}}
            animate={true}
            tooltip={({datum}: any) => (
                <div className='max-w-xs bg-white border shadow-lg rounded'>
                    <div
                        className='px-3 pt-2 pb-1 rounded-t text-white font-semibold'
                        style={{backgroundColor: datum.color}}
                    >
                        {datum.id}
                    </div>
                    <div className='px-3 pb-3 pt-3 rounded-b'>
                        {datum.formattedValue}
                    </div>
                    <AdditionalToolTipVariables rowIndex={datum.data.rowIndex} />
                </div>
            )}
        />
    </ChartContainer>;
});

function PieChartContext(props: StandardRenderChildProps) {
    const chartContext = new ChartStore(props, MAX_SLICES);

    return <ChartContext.Provider value={chartContext}>
        <PieChartVis />
    </ChartContext.Provider>; 
}

export default function PieChartRenderer(props: {
    dataConfig: DataConfig
    loadCB?: (promise: Promise<void>) => void
}) {
    return (
        <StandardDataRenderer dataConfig={limitRowsForChart(props.dataConfig, MAX_SLICES)} loadCB={props.loadCB}>
            {PieChartContext}
        </StandardDataRenderer>
    );
}
