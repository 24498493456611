import {ReactNode, useContext, useEffect, useState} from 'react';
import {Link, Routes, Route, useLocation} from 'react-router-dom';
import {HeaderContext} from '../header/context';

type TabBarSection = {
    name: ReactNode,
    stub?: string,
    component: () => JSX.Element
}

type Props = {
    useRouter?: boolean,
    useContainer?: boolean,
    justification?: string,
    children: Array<TabBarSection>,
}

export default function TabBar(props: Props) {
    const [activeSection, setActiveSection] = useState(0);
    const location = useLocation();
    const {children: sections, useRouter, useContainer, justification} = props;
    const headerStore = useContext(HeaderContext);

    useEffect(() => {
        if(useRouter) {
            const activeStub = location.pathname.split('/').pop();
            
            if(!!activeStub && sections.some(s => s.stub === activeStub))
                setActiveSection(sections.findIndex(s => s.stub === activeStub));
        }
    }, [useRouter, location.pathname, sections]);

    const Section = sections[activeSection].component;

    function legacyTabBar() {
        return <>
            <nav className='bg-white shadow xl:h-16 xl:overflow-hidden'>
                <div className={`w-full h-full relative flex ${justification} flex-col xl:flex-row items-start xl:items-center`}>
                    {sections.map((section, i) =>
                        useRouter ? <Link key={i} to={section.stub || '/'} className={'w-full xl:w-auto h-full flex items-center hover:no-underline'} onClick={() => setActiveSection(i)}>
                            <div className={`w-full xl:w-auto text-black h-full py-2 px-4 cursor-pointer flex items-center border-b-2 ${i === activeSection ? 'font-bold border-blue-500' : 'border-gray-300'}`}>{section.name}</div>
                        </Link> :
                            <div key={i} className={'w-full xl:w-auto h-full py-2 px-4 cursor-pointer flex items-center border-b-2 ' + (i === activeSection ? 'font-bold border-blue-500 ' : 'border-gray-300')}
                                onClick={() => setActiveSection(i)}>
                                {section.name}
                            </div>)}
                </div>
            </nav>
            <div className={useContainer ? 'container' : ''}>
                {useRouter ? <Routes>
                    {sections.map((section, i) => {
                        const SectionToRender = section.component;

                        return <Route key={i} path={section.stub} element={<SectionToRender />} />;
                    })} </Routes> : <Section />}
            </div>
        </>;
    }

    function dppTabBar() {
        const sectionLinkClasses = `w-full xl:w-auto text-black h-full py-4 px-4 cursor-pointer flex items-center `;
        return <>
            <nav className='xl:overflow-hidden'>
                <div className={`relative flex ${justification} ${useContainer && 'container !p-0'} flex-col xl:flex-row items-start xl:items-center`}>
                    {sections.map((section, i) =>
                        useRouter ? <Link key={i} to={section.stub || '/'} className={'w-full xl:w-auto h-full flex items-center hover:no-underline'} onClick={() => setActiveSection(i)}>
                            <div className={`${sectionLinkClasses} ${(i !== activeSection && i + 1 !== activeSection) && i !== sections.length - 1 && ' border-r-2 '} ${i === activeSection ? 'font-bold border-medinsight-blue-500 border-t-2 bg-white' : 'border-background-gray-300 bg-background-gray-200 text-background-gray-700'}`}>{section.name}</div>
                        </Link> :
                            <div key={i} className={sectionLinkClasses + (i === activeSection ? 'font-bold border-medinsight-blue-500' : 'border-gray-300')}
                                onClick={() => setActiveSection(i)}>
                                {section.name}
                            </div>)}
                </div>
            </nav>
            <div className={useContainer ? 'container bg-white' : 'bg-white'}>
                {useRouter ? <Routes>
                    {sections.map((section, i) => {
                        const SectionToRender = section.component;

                        return <Route key={i} path={section.stub} element={<SectionToRender />} />;
                    })} </Routes> : <Section />}
            </div>
        </>;
    }

    return headerStore.useNewDesign ? dppTabBar() : legacyTabBar();
}