import {dataValue} from './data-types';

export enum LinkType {
    External,
    Canvas,
    CanvasDraft,
    Profile,
    OldDashboard,
    Visualization,
    Maps,
    Document,
    HCI
}

// For old dashboards, we had to use the label of the dataset as the query string parameter for the dashboard parameter.
// This is basically doing that conversion for us. We may need to add extra cases in as we find them, but this in only
// a stop-gap until content is converted over to canvases.
export function getParam(dataset: string): string {
    let param: string;
    if (dataset === 'health-system' || dataset === 'healthsystem')
        param = 'Health System';
    else if (dataset === 'physiciangroup')
        param = 'Physician Group';
    else if (dataset === 'aco')
        param = 'ACO';
    else if (dataset === 'hospital')
        param = 'Hospital';
    else
        param = dataset[0].toUpperCase() + dataset.slice(1);
    return encodeURIComponent(param);
}

export default function generateLink(type: LinkType, value: dataValue, dataset?: string): string {
    if (type === LinkType.External) {
        if (!value)
            return '';
        let url = String(value);
        if (!url.startsWith('http') && !url.startsWith('//'))
            url = 'http://' + url;
        return url;
    } else if (type === LinkType.Canvas)
        return `/canvas/${value}/0`;
    else if (type === LinkType.CanvasDraft)
        return `/canvas/${value}/0?draft=true`;
    else if (type === LinkType.Profile && dataset) {
        return `/canvas-redirect/${dataset}/${value}`;
    } else if (type === LinkType.OldDashboard) {
        const prefix = '//' + process.env.REACT_APP_TI_DOMAIN;
        if (dataset) {
            if (dataset === 'provider-group') dataset = 'physiciangroup';
            if (dataset === 'health-system') dataset = 'healthsystem';
            if (!value)
                return `${prefix}/dashboard/${dataset}`;
            return `${prefix}/dashboard/${dataset}?${getParam(dataset)}=${value}`;
        }
        return `${prefix}/dashboard/${value}`;
    } else if (type === LinkType.Visualization)
        return '//' + process.env.REACT_APP_TI_DOMAIN + `/data/vis/${value}`;
    else if (type === LinkType.Maps)
        return '//' + process.env.REACT_APP_TI_DOMAIN + `/map/saved/${value}`;
    else if (type === LinkType.Document)
        return `https://torchinsight-file-uploads.s3.us-west-2.amazonaws.com/documents/${value}`;
    else if (type === LinkType.HCI)
        return `/healthcare-index/${value}`;
    return '';
}