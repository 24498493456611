import {DataTable, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, Checkbox, DataTableSkeleton, TableToolbar, TableBatchActions, TableBatchAction, Loading} from '@carbon/react';
import {useContext, useEffect, useState} from 'react';
import './additional-providers-table.scss';
import {NetworkContext} from '../lib/context';
import {observer} from 'mobx-react';
import {Save} from '@carbon/icons-react';
import {PercentFormatter} from '../../../lib/formatter';

const headers = [
    {key: 'npi', header: 'NPI'},
    {key: 'full_name', header: 'Name'},
    {key: 'first_line_practice_location_address', header: 'Address'},
    {key: 'practice_location_address_city_name', header: 'City'},
    {key: 'practice_location_address_state_name', header: 'State'},
    {key: 'practice_location_address_postal_code', header: 'Zip'},
    // {key: 'final_mips_score', header: 'Resiliency Score'},
    {key: 'marginal_impact_time', header: 'Impact Time'},
    {key: 'marginal_impact_distance', header: 'Impact Distance'},
];

function UpdateRosterButton({onClick}: {onClick: () => Promise<void>}) {
    let [loading, setLoading] = useState(false);
    function LoadingIcon() {
        return <Loading withOverlay={false} small />;
    }
    return <TableBatchAction className={loading ? 'gap-2' : ''} iconDescription="Save" renderIcon={loading ? LoadingIcon : Save} onClick={() => {
        setLoading(true);
        onClick().then(() => setLoading(false));
    }}>
        Update Roster
    </TableBatchAction>;
}

function AdditionalProvidersTable() {
    const networkOptimizationStore = useContext(NetworkContext);
    const [selection, setSelection] = useState<string[]>([]);

    useEffect(() => {
        const controller = new AbortController();
        const loadData = async () => {
            await networkOptimizationStore.loadLocalProviders(controller);
            setSelection([]);
        };

        loadData();
        // return () => controller.abort();
    }, [networkOptimizationStore.specialtyFilter, networkOptimizationStore.appliedGeographyFilter, networkOptimizationStore.runId, networkOptimizationStore.revisionCount]);

    if (networkOptimizationStore.loadingLocalProviders)
        return <DataTableSkeleton headers={[{key: ' ', header: ' '}, ...headers]} rowCount={4} columnCount={headers.length + 1} compact showToolbar={false} />;

    return <div className='additional-providers-table h-full'>
        {networkOptimizationStore.localProviders.length === 0 && <p>No providers found</p>}
        {!!networkOptimizationStore.localProviders.length && <DataTable rows={networkOptimizationStore.localProviders} headers={headers} size='sm' stickyHeader>
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getTableContainerProps,
                getToolbarProps,
                getBatchActionProps,
            }) => <TableContainer title={<div className='font-semibold mt-[-1.25rem] mb-[-.75rem]'>Available Providers/Facilities{networkOptimizationStore.specialtyFilter && <> - {networkOptimizationStore.specialtyFilter}</>}</div>} {...getTableContainerProps()}>
                <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                    <TableBatchActions {...getBatchActionProps()} shouldShowBatchActions={!!selection.length} totalSelected={selection.length} onCancel={() => setSelection([])}>
                        <UpdateRosterButton onClick={async () => {
                            await networkOptimizationStore.updateRoster(selection, new AbortController());
                        }} />
                    </TableBatchActions>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="sample table">
                    <TableHead>
                        <TableRow>
                            <TableHeader className='select-col' key='select' >
                                &nbsp;
                            </TableHeader>
                            {headers.map((header, i) => {
                                let headerProps = getHeaderProps({header});
                                let headerOnClick = headerProps.onClick as any;
                                return <TableHeader {...headerProps} key={i} onClick={headerOnClick}>
                                    {header.header}
                                </TableHeader>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => {
                            const rowProps = getRowProps({row});
                            return <TableRow {...rowProps} key={rowProps.key} >
                                <TableCell className='select-col' key={`select-${row.id}`}>
                                    <Checkbox id={`check-${row.id}`} labelText='' checked={selection.includes(row.id)} onChange={(_, {checked}) => checked ? setSelection([...selection, row.id]) : setSelection(selection.filter(s => s !== row.id))} />
                                </TableCell>
                                {row.cells.map(cell => <TableCell key={cell.id}>{['marginal_impact_time', 'marginal_impact_distance'].some(h => cell.id.split(':')[1] === h) ? new PercentFormatter(.01, null).formatValue(cell.value) : cell.value}</TableCell>)}
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>}
        </DataTable>}
    </div>;
}

export default observer(AdditionalProvidersTable);