import {dataRow, dataValue} from '../../../lib/data-types';
import Modal from '../../../components/modal';
import React, {ReactNode, useState} from 'react';
import Button from '../../../components/button';
import {ActionObj, CrudAction, FieldType} from './types';
import Alert from '../../../components/alert';
import AccessRolesInput from './inputs/access-roles';
import EntityInput from './inputs/entity';
import {canvas, dashboards, modules, organizations, userDatasets, users} from '../../../lib/api';
import Toggle from '../../../components/toggle';
import ModulesAdmin from './inputs/modules';
import GrantTypeInput from './inputs/grantType';
import MultiEntityInput from './inputs/multi-entity';


type Props = {
    item: dataRow,
    fields: Array<FieldType>,
    name: string,
    onClose: (action: CrudAction, item?: dataRow) => void,
    actions?: ActionObj,
    notice?: ReactNode
}

function formatDate(value: dataValue) {
    if (value === null)
        return '';
    return String(value).substr(0, 10);
}

export default function ItemModal(props: Props) {
    const {name, onClose, fields, actions, notice} = props;
    const [item, setItem] = useState(props.item);

    function setValue<T>(field: FieldType, value: T) {
        const newItem = Object.assign({}, item, {[field.name]: value});
        setItem(newItem);
    }

    function handleChange(field: FieldType, v: string | boolean) {
        let value: dataValue = v;
        if (field.type === 'numeric')
            value = Number(value);
        setValue(field, value);
    }

    function confirmDelete() {
        if (window.confirm('Are you sure you want to delete this user?')) {
            onClose(CrudAction.Delete);
        }
    }

    const action: CrudAction = item['id'] ? CrudAction.Edit : CrudAction.Create;
    const title = action === CrudAction.Edit ? 'Edit ' + name : 'Create ' + name;

    return <Modal title={title} onClose={() => onClose(CrudAction.None)}>
        {notice && <Alert color="yellow" icon="icon-warning" border noDismiss>{notice}</Alert>}
        {actions && action === CrudAction.Edit && <Alert color="blue" icon="icon-info" border noDismiss>
            <div className="text-blue-500 uppercase font-bold text-sm tracking-wide mb-2">Actions</div>
            <div>
                {Object.keys(actions).map(action => <Button key={action} color="light" onClick={() => actions[action](props.item)}>
                    {action}
                </Button>)}
            </div>
        </Alert>}

        {fields.map((field, i) => {
            const value = !item[field.name] ? '' : String(item[field.name]);
            return <div key={i} className="my-2">
                <label className="font-bold">{field.label}</label>
                <div>
                    {field.type === 'text' && <input type="text" value={value} onChange={e => handleChange(field, e.target.value)} disabled={field.readonly || field.name === 'id'} />}
                    {field.type === 'boolean' && <Toggle checked={!!value} onChange={() => handleChange(field, !value)} disabled={field.readonly} />}
                    {field.type === 'numeric' && <input type="number" value={value} onChange={e => handleChange(field, e.target.value)} disabled={field.readonly || field.name === 'id'} />}
                    {field.type === 'date' && <input type="date" value={formatDate(value)} onChange={e => handleChange(field, e.target.value)} disabled={field.readonly} />}
                    {field.type === 'password' && <input type="password" value={value} onChange={e => handleChange(field, e.target.value)} />}
                    {field.type === 'accessRoles' && <AccessRolesInput accessRoles={item[field.name] as any} onChange={roles => setValue(field, roles)} />}
                    {field.type === 'modules' && <ModulesAdmin modules={item[field.name] as any || []} onChange={modules => setValue(field, modules)} />}
                    {field.type === 'user' && <EntityInput autocomplete value={item[field.name] as number} list={users.list} onChange={id => setValue(field, id)} additionalFields={['email']} sortField="name" />}
                    {field.type === 'multi-user' && <MultiEntityInput autocomplete values={item[field.name] as any || []} list={users.list} onChange={id => setValue(field, id)} additionalFields={['email']} sortField="name" />}
                    {field.type === 'org' && <EntityInput value={item[field.name] as number} list={organizations.list} onChange={id => setValue(field, id)} sortField="name" />}
                    {field.type === 'grantType' && <GrantTypeInput value={value} onChange={value => setValue(field, value)} />}
                    {field.type === 'dashboards' && <EntityInput value={item[field.name] as number} onChange={id => setValue(field, id)} list={dashboards.list} />}
                    {field.type === 'canvas' && <EntityInput value={item[field.name] as number} onChange={id => setValue(field, id)} list={canvas.list} />}
                    {field.type === 'userDatasets' && <EntityInput value={item[field.name] as number} onChange={id => setValue(field, id)} list={userDatasets.list} />}
                    {field.type === 'permissionModule' && <EntityInput value={item[field.name] as number} onChange={id => setValue(field, id)} list={modules.list} />}
                </div>
                {field.note && <div className="text-sm text-gray-400">{field.note}</div>}
            </div>;
        })}
        <hr/>
        <div>
            <Button color="blue" onClick={() => onClose(action, item)}>Save</Button>
            {action === CrudAction.Edit && <Button color="red" onClick={() => confirmDelete()}>Delete</Button>}
        </div>
    </Modal>;
}