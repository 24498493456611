import Variable from '../../lib/metadata';
import React from 'react';

type Props = {
    variable: Variable,
    onRemove?: (v: Variable) => void,
    onFilter?: (v: Variable) => void,
    onInfo?: (v: Variable) => void
};

export default function HeaderMenu(props: Props) {
    const {variable, onFilter, onInfo, onRemove} = props;
    const showInfo = !!onInfo && (!!variable.getTag('Source') || !!variable.description);
    if (!showInfo && !onFilter)
        return null;

    return <div className="header-menu" onClick={e => e.stopPropagation()}>
        {onFilter && <button data-testid="filter-button" onClick={() => onFilter && onFilter(variable)}>
            <i className="icon-filter mr-2" /> Filter
        </button>}
        {showInfo && <button data-testid="info-button" onClick={() => onInfo && onInfo(variable)}>
            <i className="icon-info mr-2" /> Information
        </button>}
        {onRemove && <button onClick={() => onRemove && onRemove(variable)}>
            <i className="icon-trash mr-2"/>
            Remove Column
        </button>}
    </div>;
}