import React from 'react';
import './error-message.scss';
import Button from '../button';

type Props = {
    error?: string,
    actionText?: string,
    onClick?: () => void,
}

export default function ErrorMessage(props: Props) {
    const {error, actionText, onClick} = props;
    const hasAction = !!actionText && !!onClick;

    return <div className="error-message">
        <div><i className="icon-error text-lg" /></div>
        {error || <div>
            <p>We ran into a problem loading your data.</p>
            <p>The issue has been reported and we'll fix it as soon as we can. Please try again later.</p>
        </div>}
        {hasAction && <p>
            <Button color="light" onClick={onClick}>{actionText}</Button>
        </p>}
    </div>;
}