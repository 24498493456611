import StandardDataRenderer, {StandardRenderChildProps} from '../renderers/standard-data-renderer';
import React, {useMemo, useState} from 'react';
import {DataTableVis} from '../data-table';
import {DataConfig} from '../types';
import {downloadCsv, downloadGraph} from '../../lib/download';
import {orderCRBMetadata} from './order';
import Modal from '../../components/modal';
import Button from '../../components/button';
import {addBreakdownPivotMetadata, addSummaryPivotMetadata} from './pivot-metadata';
import Alert from '../../components/alert';
import {getDefaultDataDefRepo} from '../../lib/data-defs';


function getDLType(reportType: string, regionType: string) {
    if (reportType === 'breakdown' || reportType === 'summary')
        return 'types of service';
    if (regionType === 'state')
        return 'states';
    if (regionType === 'msa')
        return 'MSAs/Divisions';
    return 'options';
}

function buildLink(options: any) {
    const {regionType, reportType, typeOfService, region, metricType} = options;
    let url = `/cr-benchmarks/${regionType}/${reportType}/${metricType}`;
    if (reportType === 'breakdown')
        url += '/' + typeOfService;
    if ((reportType === 'drg' || reportType === 'mdc') && region)
        url += '/' + region;
    return url;
}

export function CRBenchmarksVis(props: StandardRenderChildProps) {
    const {dataConfig, data} = props;
    const [downloadModal, setDownloadModal] = useState(false);

    const {measures, reportType, typeOfService} = dataConfig.options;
    const metadata = useMemo(() => {
        let md = props.metadata;
        if (reportType === 'summary')
            md = addSummaryPivotMetadata(props.metadata, measures);
        if (reportType === 'breakdown')
            md = addBreakdownPivotMetadata(props.metadata, measures, typeOfService, data);
        return orderCRBMetadata(md);
    }, [data, measures, props.metadata, reportType, typeOfService]);

    let customDownload: Array<[string, () => void] | null> | undefined;
    if (dataConfig.queries.length > 1) {
        customDownload = [['Download', () => setDownloadModal(true)]];
    }
    dataConfig.options.exploreLink = buildLink(dataConfig.options);

    return <div>
        <DataTableVis dataConfig={dataConfig} data={data} metadata={metadata} customDownload={customDownload} />
        {downloadModal && <Modal title="Download Data" onClose={() => setDownloadModal(false)}>
            <Alert border color="yellow" icon="icon-info" noDismiss>
                Note: Only the fields you have selected will be included in this file. To add more fields, close this dialog and use the "Add Data" button.
            </Alert>
            <p>Download the current selection of data.</p>
            <Button color="light" onClick={() => downloadCsv(data, metadata, dataConfig.options.name)}>
                <i className="icon-download" /> Download Current .csv
            </Button>
            <hr/>
            <p>Download the data expanded for all {getDLType(reportType, dataConfig.options.regionType)} and all Medicare payment types.</p>
            <Button color="light" onClick={() => downloadGraph(dataConfig.queries[1], -1, getDefaultDataDefRepo())}>
                <i className="icon-download" /> Download Expanded .csv
            </Button>
        </Modal>}
    </div>;
}

export default function CRBenchmarksRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void}) {
    return <StandardDataRenderer dataConfig={props.dataConfig} loadCB={props.loadCB}>
        {CRBenchmarksVis}
    </StandardDataRenderer>;
}