import FilterStepsStore from '../../../../components/filter-steps/libs/filter-steps-store';
import {QueryFilter, QueryObj} from '../../../../lib/data-types';
import {deserialize, GraphVersions, QuantumGraph, TestType} from 'quantum-graph';
import auth from '../../../../lib/auth';
import {PermissionModule} from '../../../../lib/check-module-access';
import quantumAccelerator from '../../../../lib/quantum-accelerator';
import {generateStandardFacilityFilters} from './institutional-steps/query';


export default class HPTFilterStepsStore extends FilterStepsStore {
    private readonly DEFAULT_VIEW = '';
    public _payers: Array<string>;
    public _lob: Array<string>;
    public _products: Array<string>;
    public _view: string;
    public _dataView: string;
    public _geoRestrictionModules: Array<PermissionModule>;
    public _useUATEnvironment: boolean;


    constructor(updateFacilities: boolean) {
        super(updateFacilities);
        this._payers = [];
        this._lob = [];
        this._products = [];
        this._view = this.DEFAULT_VIEW;
        this._dataView = this.defaultDataView();
        this._geoRestrictionModules = [];
        this._useUATEnvironment = false;
        this.removeUATSessionKey();
    }

    get payers() {
        return this._payers;
    }

    set payers(payers: Array<string>) {
        this._payers = payers;
    }

    get lob() {
        return this._lob;
    }

    set lob(lob: Array<string>) {
        this._lob = lob;
    }

    get products() {
        return this._products;
    }

    set products(products: Array<string>) {
        this._products = products;
    }

    get view() {
        return this._view;
    }

    set view(view: string) {
        this._view = view;
    }

    get dataView() {
        return this._dataView;
    }

    set dataView(dataView: string) {
        this._dataView = dataView;
        this._geoRestrictionModules = this.dataView === 'payer' ? [PermissionModule.PTPayer] : [PermissionModule.PTHospital];
    }

    get geoRestrictionModules() {
        return this._geoRestrictionModules;
    }

    set geoRestrictionModules(pms : Array<PermissionModule>) {
        this._geoRestrictionModules = pms;
    }

    get useUATEnvironment() {
        return this._useUATEnvironment;
    }

    set useUATEnvironment(value: boolean) {
        this._useUATEnvironment = value;
        quantumAccelerator.clearObjectStore();
        value ? this.setUATSessionKey() : this.removeUATSessionKey();
    }

    private defaultDataView() {
        if (auth.principal?.modules?.some(m => m.name === 'Pricing Transparency - Payer'))
            return 'payer';
        return 'hospital';
    }

    public clearAll() {
        super.clearAll();
        this.lob = [];
        this.payers = [];
        this.products = [];
        this.view = this.DEFAULT_VIEW;
    }

    public canConfigureTable(): boolean {
        return !!this.dataView && !!this.view;
    }

    protected createStateFilter(nodeId: string, filters: Array<QueryFilter>) {
        const stateIds = this.geographies.filter(g => g.type === 'state').map(g => g.name);
        if (stateIds.length > 0) {
            filters.push({
                type: 'basic',
                column: {
                    nodeId: nodeId,
                    column: 'state'
                },
                test: TestType.In,
                value: [...stateIds]
            });
        }
    }

    public createPayerFilter(): QueryFilter | null {
        if (this.payers.length > 0) {
            const filter: QueryFilter = {
                type: 'basic',
                column: {
                    nodeId: 'hpt-fact',
                    column: 'payer'
                },
                test: TestType.In,
                value: this._payers
            };

            return filter;
        }

        return null;
    }

    public createLOBFilter(): QueryFilter | null {
        if (this.lob.length > 0) {
            const filter: QueryFilter = {
                type: 'basic',
                column: {
                    nodeId: 'hpt-fact',
                    column: 'lob'
                },
                test: TestType.In,
                value: this._lob
            };

            return filter;
        }

        return null;
    }

    public createProductFilter(): QueryFilter | null {
        if (this.products.length > 0) {
            const filter: QueryFilter = {
                type: 'basic',
                column: {
                    nodeId: 'hpt-fact',
                    column: 'product'
                },
                test: TestType.In,
                value: this._products
            };

            return filter;
        }

        return null;
    }

    public createFacilitiesFilter(): QueryFilter | null {
        let filter: QueryFilter;
        if (this.facilityList.length > 0) {
            filter = {
                type: 'basic',
                column: {
                    nodeId: 'hospital',
                    column: 'facilityid'
                },
                test: TestType.In,
                value: this.facilityList.map(f => (f['facilityid'] as number))
            };

            return filter;
        }

        return null;
    }

    public getHPTFilters(): Array<QueryFilter> {
        const filters: Array<QueryFilter> = [];
        if (this.view === 'relativity')
            filters.push(this.createHospitalPayerFlagFilter()!);
        if (this.facilityList.length)
            filters.push(this.createFacilitiesFilter()!);
        if (this.payers.length)
            filters.push(this.createPayerFilter()!);
        if (this.lob.length)
            filters.push(this.createLOBFilter()!);
        if (this.products.length)
            filters.push(this.createProductFilter()!);

        return filters;
    }

    public getPayerDropdownQuery(variable: string): QuantumGraph {
        const obj: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'hospital',
                            dataset: 'pt-facility',
                            columns: []
                        },
                        {
                            id: 'hpt-fact',
                            dataset: this._dataView === 'payer' ? 'pt-servicelinecffactrecent-payer' : 'pt-servicelinecffactrecent-hospital',
                            columns: [variable]
                        }
                    ]
                },
                joins: []
            },
            sort: [
                {
                    column: {
                        nodeId: 'hpt-fact',
                        column: variable
                    },
                    asc: true
                }
            ],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'payer'
                    },
                    test: TestType.NotNull,
                    value: 1
                },
                this.createHospitalPayerFlagFilter()
            ],
            distinct: true
        };

        if (this.facilityList.length)
            obj.filters!.push(this.createFacilitiesFilter()!);

        return deserialize(obj);
    }

    protected generateFacilityListQuery(): QueryObj {
        let query: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'hospital',
                            dataset: 'pt-facility',
                            columns: [
                                'torch_facility_id',
                                'facilityid',
                                'facility_name'
                            ]
                        }
                    ]
                }
            },
            sort: [{
                column: {
                    nodeId: 'hospital',
                    column: 'facility_name'
                },
                asc: true
            }],
            filters: [
                ...generateStandardFacilityFilters(this.dataView),
                ...this.createFacilityTypeFilter(),
                ...this.createBedFilters(),
                ...this.createHealthSystemFilter()
            ],
            distinct: true
        };

        if (this.MAX_ENTITIES)
            query.pagination = {
                page: 0,
                pageSize: this.MAX_ENTITIES
            };

        if (this.geographies.length)
            query.filters!.push(this.createGeographyFilter('hospital')!);

        return query;
    }

    public getFacilityDropDownQuery(variable: string): QuantumGraph {
        const obj: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'hospital',
                            dataset: 'pt-facility',
                            columns: [variable]
                        }
                    ]
                },
                joins: []
            },
            sort: [
                {
                    column: {
                        nodeId: 'hospital',
                        column: variable
                    },
                    asc: true
                }
            ],
            filters: [
                ...generateStandardFacilityFilters(this.dataView),
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hospital',
                        column: variable
                    },
                    test: TestType.NotNull,
                    value: 1
                }
            ],
            distinct: true
        };

        if (this.geographies.length)
            obj.filters!.push(this.createGeographyFilter('hospital')!);

        return deserialize(obj);
    }

    public createHospitalPayerFlagFilter(): QueryFilter {
        return {
            type: 'basic',
            column: {
                nodeId: 'hpt-fact',
                column: 'payerflag'
            },
            test: TestType.Eq,
            value: this._dataView === 'payer'
        };
    }

    public createHealthSystemFilter(): Array<QueryFilter> {
        if (this.healthSystems.length > 0) {
            const typeFilter: QueryFilter = {
                type: 'basic',
                column: {
                    nodeId: 'hospital',
                    column: 'torch_health_system_name'
                },
                test: TestType.In,
                value: [...this._healthSystems]
            };

            return [typeFilter];
        }

        return [];
    }

    public removeUATSessionKey() {
        sessionStorage.removeItem('pt-uat');
    }

    public setUATSessionKey() {
        sessionStorage.setItem('pt-uat', 'true');
    }
}