import Modal from '../../../components/modal';
import React, {useContext, useState} from 'react';
import {observer} from 'mobx-react';
import {CanvasContext} from '../lib/context';
import Button from '../../../components/button';

function CvCopyModal() {
    const store = useContext(CanvasContext);
    const [name, setName] = useState('');

    return <Modal title="Make a Copy" onClose={() => store.toggleCopyModal()} hasButtons>
        <div>Enter a new name for the copy:</div>
        <input type="text" value={name} onChange={e => setName(e.target.value)} />

        <div className="modal-buttons">
            <Button color="orange" disabled={!name} onClick={() => store.saveCopy(name)}>
                Save
            </Button>
            <Button color="white" onClick={() => store.toggleCopyModal()}>
                Cancel
            </Button>
        </div>
    </Modal>;
}

export default observer(CvCopyModal);