import {useEffect, useMemo, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import AuthWall from '../../components/auth-wall';
import Template from '../../components/template';
import {organizations} from '../../lib/api';
import {Membership, Organization, User} from '../../lib/data-types';
import PageTitle from '../../components/page-title';
import Badge from '../../components/badge';
import Button from '../../components/button';
import handleError from '../../lib/error';
import auth from '../../lib/auth';
import MemberForm, {MemberFormData} from './member-form';
import SiteName from '../../components/SiteName';

export default function ManageMember() {
    const [user, setUser] = useState<User>();
    const [org, setOrg] = useState<Organization>();
    const [membership, setMembership] = useState<Membership>();
    const [resetPasswordSent, setResetPasswordSent] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate();
    const {id, userId} = useParams<{id: string, userId: string}>();

    const canSetModerator = useMemo(() => org && org.allowModerators && (auth.hasRole('admin') || (org && org.adminUsers!.some(admin => admin === auth.principal!.sub))), [org]);

    useEffect(() => {
        const controller = new AbortController();
        organizations.users.get(Number(id), Number(userId), controller.signal)
            .then(user => {
                setMembership({
                    orgId: id!,
                    userId: user.id,
                    isModerator: user.isModerator,
                    inviteCount: user.inviteCount || 1,
                    isInactive: user.isInactive
                });
                setUser(user);
            }).catch(e => handleError(e, setErrorMessage));
    }, [id, userId]);

    useEffect(() => {
        const controller = new AbortController();
        organizations.get(Number(id), controller.signal)
            .then(org => setOrg(org))
            .catch(e => handleError(e, setErrorMessage));
    }, [id]);

    function updateMemberFormData(data: MemberFormData) {
        const tempMembership: Membership = {...membership!};
        tempMembership.isModerator = data.moderator;
        tempMembership.isInactive = data.inactive;
        tempMembership.inviteCount = data.inviteCount;
        setMembership(tempMembership);
        organizations.users.update(tempMembership)
            .catch(e => handleError(e, setErrorMessage));

        handleOrgAdmin(data.orgAdmin);
    }

    function handleOrgAdmin(setOrgAdmin: boolean) {
        if (org && user) {
            const tempOrg = {...org};
            if (!setOrgAdmin && org.adminUsers!.includes(user.id!))
                tempOrg.adminUsers = tempOrg.adminUsers!.filter(admin => admin !== user.id);
            else if (setOrgAdmin && !org.adminUsers!.includes(user.id!))
                tempOrg.adminUsers!.push(user.id!);
            setOrg(tempOrg);
            organizations.modify(tempOrg)
                .catch(e => handleError(e, setErrorMessage));
        }

    }

    function passwordReset() {
        organizations.users.passwordReset(Number(id), Number(userId))
            .then(status => setResetPasswordSent(status.ok))
            .catch(e => handleError(e, setErrorMessage));
    }


    return <AuthWall>
        <Template showFooter>
            <PageTitle name={<div>View Membership</div>} />
            <div className="relative w-full flex items-center flex-col py-10 bg-gray-100" style={{minHeight: '65vh'}}>
                <div className="w-1/2 absolute">
                    <Button color={'white'} onClick={() => navigate(-1)}><i className="icon-arrow-left"/> Back</Button>
                </div>
                {org && <Badge color={'orange'} pill>{org.name}</Badge>}
                {errorMessage && <div className="w-1/2 text-red-500 mt-5">{errorMessage}</div>}
                {user && membership && <div className="w-1/2 mt-10 bg-white p-5 border shadow-xl">
                    <h1 className="m-0">Member Info</h1>
                    <div className="ml-3 mt-3 flex flex-col">
                        <ul>
                            <li>{user.name}</li>
                            <li>{user.email}</li>
                            {user.createdAt && <li>Joined <SiteName /> on {new Date(user.createdAt).toLocaleDateString()}</li>}
                        </ul>
                    </div>
                    {org && membership && <MemberForm onChange={data => updateMemberFormData(data)}
                        allowAdmin={true} allowModerator={canSetModerator || false}
                        isAdmin={org.adminUsers!.includes(user.id!)}
                        isModerator={membership.isModerator}
                        inviteCount={membership.inviteCount}
                        invitesSent={user.invitesSent!}
                        showInvites />}
                    <div className="flex flex-col space-y-2 mt-10">
                        <div>
                            <Button color={'orange'} onClick={passwordReset} disabled={resetPasswordSent}>Reset Password</Button>
                        </div>
                        {resetPasswordSent && <small className="text-green-500"><strong>Password reset email sent.</strong></small>}
                        <small>Resetting the password will send the user an email with a special link that will allow
                            them to change their password.</small>
                    </div>
                </div>}
            </div>
        </Template>
    </AuthWall>;
}