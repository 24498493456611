import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {CanvasContext} from '../lib/context';

type Props = {
    index: number,
    value: string,
}

const keyDelayTimeout = process.env.REACT_APP_API_BASE === 'test-api' ? 1 : 550;

export default function CvHeader(props: Props) {
    const {index, value} = props;
    const store = useContext(CanvasContext);
    const size = store.rows.length;
    const [tempValue, setTempValue] = useState<string>(value);
    const [changeTimeout, setChangeTimeout] = useState<any>(null);

    //Used for the debounce functionality, otherwise canvas reloads everything with every key press.
    useEffect(() => setTempValue(value), [value]);

    function updateValue(e: ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.value;
        setTempValue(newValue);

        if (changeTimeout)
            clearTimeout(changeTimeout);
        setChangeTimeout(setTimeout(() => store.updateHeader(index, newValue), keyDelayTimeout));
    }

    function renderHeader() {
        if (store.editing)
            return <div className="cv-header">
                <input type="text" value={tempValue} onChange={updateValue} />
                <div className="cv-button" onClick={() => store.removeItem(index, 0)}>
                    <i className="icon-times" />
                </div>
                {index > 0 && <div className="cv-button" onClick={() => store.moveRow(index, -1)}>
                    <i className="icon-arrow-up" />
                </div>}
                {index < size - 1 && <div className="cv-button" onClick={() => store.moveRow(index, 1)}>
                    <i className="icon-arrow-down" />
                </div>}
            </div>;

        return <h2 id={'#s' + index} className="m-0 p-0">{value}</h2>;
    }

    return <div className="w-full flex space-x-5 items-center mt-16">
        {renderHeader()}
        <div className="flex-grow flex items-center pt-2">
            <hr className="w-full border-gray-300 shadow"/>
        </div>
    </div>;
}