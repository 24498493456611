import {DashboardResult, EntityResult, RegionResult, ResultType, SearchResult, ToolResult} from './search-types';
import Card from '../../components/card';
import Badge from '../../components/badge';
import generateLink, {LinkType} from '../../lib/links';
import {entityKey, SearchFilterValues} from './search-common';
import React from 'react';
import {Dashboard, Map, MapIdentify, Tools} from '@carbon/icons-react';
import {CarbonIconType} from '@carbon/icons-react/lib/CarbonIcon';

export abstract class DataExtractor<T extends ResultType> {
    result: SearchResult<T>;
    constructor(result: SearchResult<T>) {
        this.result = result;
    }
    getName() {
        return this.result._source.name;
    }
    abstract getPrimary(): string;
    abstract getSecondary(): string;
    abstract getLinks(): Array<[string, string, string, string?, CarbonIconType?]>;
}

export class EntityDataExtractor extends DataExtractor<EntityResult> {
    getPrimary(): string {
        // const sfv = SearchFilterValues.find(sfv => sfv.value.includes(this.result._source.entityType));
        const sfv = SearchFilterValues.find(sfv => sfv.value.toLowerCase() === `${entityKey}${this.result._source.entityType}`.toLowerCase());
        if (sfv)
            return sfv.name;
        return '';
    }

    getSecondary(): string {
        let s = '';
        if (this.result._source.state) {
            s = this.result._source.state.toUpperCase();
            if (this.result._source.city)
                s = this.result._source.city.toLowerCase() + ', ' + s;
        }
        return s;
    }

    getLinks(): Array<[string, string, string, string?, CarbonIconType?]> {
        return [[
            generateLink(LinkType.Profile, this.result._source.torchId, this.result._source.entityType),
            'icon-dashboard',
            'View Canvas',
            'Canvas',
            Dashboard
        ]];
    }
}

export class ToolDataExtractor extends DataExtractor<ToolResult> {
    getPrimary(): string {
        return 'Tool';
    }
    getSecondary(): string {
        return '';
    }
    getLinks(): Array<[string, string, string, string?, CarbonIconType?]> {
        return [[
            `//${process.env.REACT_APP_TI_DOMAIN}${this.result._source.url}`,
            'icon-gear',
            'Access Tool',
            'Tool',
            Tools
        ]];
    }
}

export class RegionDataExtractor extends DataExtractor<RegionResult> {
    getPrimary(): string {
        return this.result._source.regionType.toLowerCase();
    }
    getSecondary(): string {
        return '';
    }
    getLinks(): Array<[string, string, string, string?, CarbonIconType?]> {
        return [[
            generateLink(LinkType.Profile, String(this.result._source.regionId), this.result._source.regionType),
            'icon-dashboard',
            'View Canvas',
            'Canvas',
            MapIdentify
        ], [
            `//${process.env.REACT_APP_TI_DOMAIN}/map?regionType=${encodeURIComponent(this.result._source.regionType)}&selection=${encodeURIComponent(this.result._source.regionId)}`,
            'icon-map',
            'View Map',
            'Map',
            Map
        ]];
    }
}

export class DashboardDataExtractor extends DataExtractor<DashboardResult> {
    getPrimary(): string {
        return 'Dashboard';
    }
    getSecondary(): string {
        return '';
    }
    getLinks(): Array<[string, string, string, string?, CarbonIconType?]> {
        return [[
            `//${process.env.REACT_APP_TI_DOMAIN}/dashboard/${this.result._source.id}`,
            'icon-dashboard',
            'View Dashboard',
            'Dashboard',
            Dashboard
        ]];
    }
}

export function extractData(searchResult: SearchResult<ResultType>): [DataExtractor<ResultType>, string, string] {
    function getDataExtractor(): DataExtractor<ResultType> {
        if (searchResult._type === 'entity')
            return new EntityDataExtractor(searchResult as SearchResult<EntityResult>);
        else if (searchResult._type === 'tool')
            return new ToolDataExtractor(searchResult as SearchResult<ToolResult>);
        else if (searchResult._type === 'region')
            return new RegionDataExtractor(searchResult as SearchResult<RegionResult>);
        else if (searchResult._type === 'dashboard')
            return new DashboardDataExtractor(searchResult as SearchResult<DashboardResult>);
        return null as any;
    }

    const de = getDataExtractor();
    const primary = de.getPrimary();
    const secondary = de.getSecondary();
    return [de, primary, secondary];
}

type Props = {
    searchResult: SearchResult<ResultType>,
}

export function SearchResultCard(props: Props) {
    const {searchResult} = props;
    const [de, primary, secondary] = extractData(searchResult);

    return <Card className="w-full shadow-md overflow-hidden h-full">
        <h4 className="text-lg mb-5">{de.getName()}</h4>
        {primary && <div>
            <Badge color='blue' pill className="capitalize first-line:text-sm">{primary}</Badge>
        </div>}
        {secondary && <div className="mt-2">
            <Badge color='light' pill className="capitalize text-sm">{secondary}</Badge>
        </div>}
        <div className="bg-gray-50 px-2 py-2 border-t border-gray-100" style={{margin: '1rem -1rem -1rem', marginTop: 'auto'}}>
            {de.getLinks().map(([url, icon, text], i) => <a className="button white small" key={i} href={url}>
                <i className={icon} /> {text}
            </a>)}
        </div>  
    </Card>;
}