
import {observer} from 'mobx-react';
import {useContext} from 'react';
import {CanvasInsertContext} from '../../../lib/context';
import createGraphHandler from '../../../../../lib/graph-tools/graph-handler/create';
import {getDefaultDataDefRepo} from '../../../../../lib/data-defs';
import InsightContainer from '../../../../../insight-container';
import Checkbox from '../../../../../components/checkbox';

const ConfigSankey = observer(() => {
    const insertStore = useContext(CanvasInsertContext);

    const gh = createGraphHandler(insertStore.queries[0],getDefaultDataDefRepo());
    const variables = gh.extractVariables();

    function setTitle(e:any) {
        insertStore.visTitle = e.target.value;
    }

    function handleSelectFrom(e:any) {
        insertStore.sankeyOptions.from = e.target.value;
    }

    function handleSelectTo(e:any) {
        insertStore.sankeyOptions.to = e.target.value;
    }

    function handleSelectCount(e:any) {
        insertStore.sankeyOptions.count = e.target.value;
    }

    function handleLabels() {
        insertStore.sankeyOptions.labels = !insertStore.sankeyOptions.labels;
    }

    function handleToggleLegend() {
        insertStore.sankeyOptions.showLegend = !insertStore.sankeyOptions.showLegend;
    }
    

    return(
        <div className='text-center'>
            <h2>Configure Sankey Chart</h2>
            <div className='flex justify-evenly mt-8'>
            
                <div className='flex flex-col justify-evenly'>
                    <div className='flex justify-between'>
                        <label className='pr-2' htmlFor='title'>Title</label>
                        <input onChange={setTitle} id='title'/>
                    </div>

                    <div className='flex justify-between'>
                        <label className='pr-2' htmlFor='from'>From</label>
                        <select onChange={handleSelectFrom} name='from' id='from'>
                            <option value=''>Select a variable</option>
                            {variables.filter(v => v.variable.startsWith('origin')).map((v)=>(
                                <option key={v.variable} value={v.variable}>
                                    {v.variable}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='flex justify-between'>
                        <label className='pr-2' htmlFor='to'>To</label>
                        <select onChange={handleSelectTo} name='to' id='to'>
                            <option value=''>Select a variable</option>
                            {variables.filter(v => v.variable.startsWith('dest')).map((v)=>(
                                <option key={v.variable} value={v.variable}>
                                    {v.variable}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='flex justify-between'>
                        <label className='pr-2' htmlFor='count'>Column Width Aggregator</label>
                        <select onChange={handleSelectCount} name='count' id='count'>
                            <option value=''>Select a variable</option>
                            {variables.filter(v => ['patients', 'episodes', 'origin_allowed', 'dest_allowed'].includes(v.variable)).map((v)=>(
                                <option key={v.variable} value={v.variable}>
                                    {v.variable}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className='pr-2' htmlFor='count'>Show Labels on Hover</label>
                        <Checkbox color='dark' checked={insertStore.sankeyOptions.labels} onChange={handleLabels}/>
                    </div>
                    <div>
                        <label className='pr-2' htmlFor='showLegend'>Show Legend</label>
                        <Checkbox 
                            color='dark' 
                            checked={insertStore.sankeyOptions.showLegend} 
                            onChange={handleToggleLegend}
                        />
                    </div>
                </div>
                <div style={{width: '800px'}}>
                    <InsightContainer type='sankey' dataConfig={insertStore.dataConfig}/>
                </div>
            </div>
        </div>
    );



});


export default ConfigSankey;