import React from 'react';
import EndpointDoc from '../api/endpoint';
import Button from '../../../components/button';

export default function CrmApiDataEndpoints() {
    return <div>
        <h2>Endpoints</h2>
        <p>
            These endpoints returns geographic data for a particular disease, subpopulation, measure, and year.
        </p>
        <p>
            Each of these endpoints paths must be prefixed with:
        </p>
        <pre>/api/data/crm/v/5</pre>

        <EndpointDoc method="GET" path="/:geoLevel/:disease/:subpopulation/:measure/:year"
            description="Returns CRM data for all regions at the specified geography level." />
        <EndpointDoc method="GET" path="/:geoLevel/:regionCode/:disease/:subpopulation/:measure/:year"
            description="Returns CRM data for a specific region." />

        <h2>Parameters</h2>
        <h3>geoLevel</h3>
        <p>
            The following are valid values for the <code>:geoLevel</code> parameter.
        </p>
        <ul className="list-disc list-inside">
            <li><code>national</code> - Data at a national level</li>
            <li><code>state</code> - State</li>
            <li><code>cd</code> - Congressional District</li>
            <li><code>county</code> - County</li>
        </ul>

        <h3>regionCode</h3>
        <p>The <code>:regionCode</code> parameter specifies the individual region. Use the following as a guide:</p>
        <ul className="list-disc list-inside">
            <li><code>national</code> - Use the value <code>0</code></li>
            <li><code>state</code> - Use the two-digit state FIPS code. You may exclude leading zeros.</li>
            <li><code>cd</code> - Use the two-digit state FIPS code followed by the congressional district number as a three-digit number. For states with only one congressional district, use the number <code>000</code>. You may exclude the state leading zeros.</li>
            <li><code>county</code> - Use the five-digit county FIPS code (two-digit state FIPS followed by three-digit county FIPS). You max exclude the state leading zeros.</li>
        </ul>

        <h3>disease</h3>
        <p>
            The following are valid single disease values for the <code>:disease</code> parameter. Note that some values
            have the option to add the <code>_all</code> suffix. These represent the disease both with and without a
            co-morbid disease. These values without the <code>_all</code> represent the disease without a co-morbid disease.
        </p>
        <ul className="list-disc list-inside">
            <li><code>t2d</code>, <code>t2d_all</code> - Type-2 Diabetes</li>
            <li><code>cvd</code>, <code>cvd_all</code> - Cardiovascular Disease</li>
            <li><code>ckd</code>, <code>ckd_all</code> - Kidney Disease (Stage 3 &amp; 4)</li>
            <li><code>ckd_ccw</code> - Kidney Disease (All)</li>
            <li><code>chf</code>, <code>chf_all</code> - Heart Failure</li>
            <li><code>shf</code> - Heart Failure with Reduced Ejection Fraction</li>
            <li><code>dhf</code> - Heart Failure with Preserved Ejection Fraction</li>
        </ul>

        <p>The following are valid options diseases with a co-morbid disease.</p>
        <ul className="list-disc list-inside">
            <li><code>t2d_cvd</code> - Type-2 Diabetes and Cardiovascular Disease</li>
            <li><code>t2d_ckd</code> - Type-2 Diabetes and Chronic Kidney Disease (Stages 3 &amp; 4)</li>
            <li><code>t2d_chf</code> - Type-2 Diabetes and Heart Failure</li>
            <li><code>chf_ckd</code> - Heart Failure and Chronic Kidney Disease (Stages 3 &amp; 4)</li>
        </ul>

        <h3>subpopulation</h3>
        <p>
            The following are valid values for the <code>:subpopulation</code> parameter.
        </p>
        <ul className="list-disc list-inside">
            <li><code>tot</code> - Total/Entire Medicare Population</li>
            <li><code>male</code></li>
            <li><code>female</code></li>
            <li><code>white</code></li>
            <li><code>black</code></li>
            <li><code>hispanic</code></li>
            <li><code>other</code></li>
        </ul>

        <h3>measure</h3>
        <p>
            The following are valid values for the <code>:measure</code> parameter, along with the unit type of the returned values. Some measures return more than one value.
        </p>
        <ul className="list-disc list-inside">
            <li><code>prev</code> - Prevalence. <code>[percent]</code></li>
            <li><code>cost</code> - All-Cause Cost. <code>[total dollars, pmpy dollars]</code></li>
            <li><code>hospCost</code> - Hospital Inpatient Cost. <code>[total dollars, pmpy dollars]</code></li>
            <li><code>pacCost</code> - Post-Acute Care Cost. <code>[total dollars, pmpy dollars]</code></li>
            <li><code>hospDays</code> - Hospital Days. <code>[total days, pmpy days]</code></li>
            <li><code>hospRate</code> - Hospitalization Rate. <code>[per 1000 individuals]</code></li>
            <li><code>hospEpisodes</code> - Hospital Episodes. <code>[number persons hospitalized, percent of population]</code></li>
            <li><code>mortRate</code> - Mortality Rate. <code>[percent]</code></li>
            <li><code>readRate</code> - Readmission Rate. <code>[percent]</code></li>
        </ul>

        <h3>year</h3>
        <p>
            The following are valid values for the <code>:year</code> parameter.
        </p>
        <ul className="list-disc list-inside">
            <li><code>2015</code></li>
            <li><code>2016</code></li>
            <li><code>2017</code></li>
            <li><code>2018</code></li>
            <li><code>2019</code></li>
        </ul>

        <hr/>
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/crm-api/pdf">PDF Endpoint</Button>
        </p>
    </div>;
}