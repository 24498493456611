import AuthWall from '../../../components/auth-wall';
import Tabs from '../components/tabs/tabs';
import SpecialtyFilterTable from '../components/specialty-filter-table';
import ComplianceScore from '../components/compliance-score';
import NetworkMap from '../components/network-map';
import GeographyFilters from '../components/geography-filter';
import './timeDistanceAccessPage.scss';
import AdditionalProvidersTable from '../components/additional-providers-table';
import {Layer} from '@carbon/react';
import ComplianceSummaryTable from '../components/summary';

function TimeDistanceAccessPage() {
    const SummaryView = () => (
        <div className="bg-background-gray-100 relative">
            <div className="time-distance-access-page grid grid-cols-1 grid-rows-1 gap-8 h-[calc(100vh-11rem+2px)] p-4 pl-12 mr-[calc(16rem+6rem)]">
                <div className="col-span-1 h-full">
                    <ComplianceSummaryTable />
                </div>
            </div>
            <div className='time-distance-access-page absolute right-0 top-0 h-[calc(100%)]'>
                <GeographyFilters />
            </div>
        </div>
    );

    const ResultsView = () => (
        <div className='bg-background-gray-100 relative'>
            <div className="time-distance-access-page grid grid-cols-5 grid-rows-5 gap-8 h-[calc(100vh-11rem+2px)] p-4 pl-12 mr-[calc(16rem+6rem)] ">
                <div className="row-span-5">
                    <SpecialtyFilterTable fullHeight />
                </div>
                <div className="col-span-4">
                    <ComplianceScore />
                </div>
                <div className="row-span-4 col-span-4">
                    <NetworkMap />
                </div>
            </div>
            <div className='time-distance-access-page absolute right-0 top-0 h-[calc(100%)]'>
                <GeographyFilters />
            </div>

        </div>
    );

    const ExploreView = () => (
        <div className='bg-background-gray-100 relative'>
            <div className="time-distance-access-page grid grid-cols-7 grid-rows-2 gap-8 h-[calc(100vh-11rem+2px)] p-4 pl-12 mr-[calc(16rem+6rem)] ">
                <div className="col-span-2">
                    <SpecialtyFilterTable />
                </div>
                <div className="col-span-2">
                    <ComplianceScore compactView />
                </div>
                <div className="col-span-3">
                    <NetworkMap />
                </div>
                <div className="col-span-7">
                    <Layer className='h-full'>
                        <AdditionalProvidersTable />
                    </Layer>
                </div>
            </div>
            <div className='time-distance-access-page absolute right-0 top-0 h-[calc(100%)]'>
                <GeographyFilters />
            </div>
        </div>
    );

    const tabs = [
        {
            label: 'Summary',
            content: <SummaryView />,
        },
        {
            label: 'Results',
            content: <ResultsView />,
        },
        {
            label: 'Explore Additional Providers',
            content: <ExploreView />,
        },
    ];

    return (
        <AuthWall>
            <div className="time-distance-access pt-5 bg-white">
                <h1 className="m-0 ml-12 mb-1 text-[28px] font-normal font-inherit">Time and Distance Access</h1>
                <div className="m-0 ml-12 mb-1 text-[14px] italic">Sample File</div>
                <Tabs tabs={tabs} />
            </div>
        </AuthWall>
    );
}

export default TimeDistanceAccessPage;


