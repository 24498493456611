import {useEffect, useState} from 'react';
import ContentSection, {MyContentSection} from './content-section';
import {savedQuery} from '../../../lib/api';
import {SavedQuery} from '../../../lib/data-types';
import {DataTable} from '@carbon/icons-react';
import handleError from '../../../lib/error';


export default function MySavedData() {
    const [loading, setLoading] = useState<boolean>(true);
    const [savedDataList, setSavedDataList] = useState<Array<MyContentSection>>([]);
    //eslint-disable-next-line
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const controller = new AbortController();
        savedQuery.list.forUser(controller.signal).then(savedData => {
            let savedDataContent = savedData.map((data: SavedQuery) => ({title: data.name, url: `/data/${data.id}`}));
            setSavedDataList(savedDataContent);
        }).catch(e => handleError(e, setError))
            .finally(() => setLoading(false));

        return () => controller.abort();
    }, []);

    return <ContentSection title="My Saved Data" content={savedDataList} contentIcon={<DataTable />} viewAllUrl="/user/saved-data" loading={loading} />;
}