import React, {useMemo} from 'react';
import AdminCrudTable from '../crud-table';
import {userDatasets} from '../../../lib/api';

export default function UserDatasetsEdi() {
    const fields = useMemo(() => [
        {name: 'id', label: 'Id', show: true, type: 'numeric'},
        {name: 'name', label: 'Name', show: true, type: 'text'},
        {name: 'uuid', label: 'UUID', show: true, type: 'text'},
        {name: 'userId', label: 'User', show: true, type: 'user'},
        {name: 'orgId', label: 'Org', show: true, type: 'org'},
        {name: 'viewGroupId', label: 'View Permission Group', show: false, type: 'permissionGroup'},
        {name: 'editGroupId', label: 'Edit Permission Group', show: false, type: 'permissionGroup'},
        {name: 'entity', label: 'Entity', show: false, type: 'boolean'},
        {name: 'pins', label: 'Includes Map Locations', show: false, type: 'boolean'},
        {name: 'pophealth', label: 'Population Health Table', show: false, type: 'boolean'},
        {name: 'tableName', label: 'Table Name', show: false, type: 'text'},
        {name: 'connectionId', label: 'Connection Id', show: false, type: 'numeric'}
    ], []);

    return <AdminCrudTable api={userDatasets} title={'User Datasets (EDI)'} urlPath={'/site-admin/user-datasets'} fields={fields}/>;
}