import React, {ReactNode} from 'react';
import Button from '../button';
import classNames from '../../lib/class-names';

type Props = {
    children: ReactNode
    onSubmit?: () => void
    submitText?: string
    className?: string
    disabled?: boolean
}

export default function Card(props: Props) {
    const classes = classNames('rounded bg-white shadow', props.className);
    return (
        <div data-testid='card-container' className={classes}>
            <div className='px-4 py-4 h-full flex flex-col gap-2'>{props.children}</div>
            {props.onSubmit && (
                <div className='bg-gray-50 py-3 px-3 text-right'>
                    <Button color='orange' onClick={props.onSubmit} disabled={props.disabled}>
                        {props.submitText || 'Submit'}
                    </Button>
                </div>
            )}
        </div>
    );
}
