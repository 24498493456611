import {Dispatch, SetStateAction} from 'react';
import auth from './auth';
import {trackError} from './analytics-provider';

export function isAbortError(error: Error) {
    const abortedKeywords = ['aborted', 'abort', 'cancel', 'cancelled', 'interrupted', 'interrupt', 'stop', 'stopped'];
    return abortedKeywords.some(keyword => error.message?.toLowerCase().includes(keyword));
}

export default function handleError(error: Error, setError: Dispatch<SetStateAction<string>> | ((s: string) => void) | null) {
    if (isAbortError(error)) {
        return;
    }

    if (process.env.REACT_APP_API_BASE !== 'test-api') {
        console.error(error.message || error);
        console.error(error.stack);
    }
    setError && setError('An error has occurred');

    //Track error in our analytics provider
    trackError(error);

    //Report error to New Relic Browser with custom attributes
    //Note that New Relic gets loaded on the global namespace and can't be handled through an import
    //@ts-ignore
    if (typeof NREUM !== 'undefined') {
        //@ts-ignore
        NREUM.noticeError(error, {
            userId: auth.principal?.sub || 'unknown'
        });
    }
}