import {Link} from 'react-router-dom';
import Modal from '../modal';
import React from 'react';

type Props = {
    relatedData: Array<{name: string, url: string}>
    onClose: () => void
}

export default function RelatedDataModal(props: Props) {
    const {relatedData, onClose} = props;

    return <Modal title="Explore Related Data" onClose={onClose}>
        <div className="flex p-5 space-x-12">
            <img className="w-32" src={'/src/pages/tools/hc-index/images/index.png' || '/assets/healthcare-index/index.png'} alt="Related Data" />
            <div>
                <p>Click a link to explore data related to this index.</p>
                <ul className="bg-gray-50 p-3 rounded shadow mt-5 grid grid-cols-4 gap-3">
                    {relatedData.map((rd, i) => <li key={i} className="flex justify-center">
                        <Link to={rd.url}>{rd.name}</Link>
                    </li>)}
                </ul>
            </div>
        </div>
    </Modal>;
}