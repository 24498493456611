import {CategoryIndex} from '../../lib/library/library-index';
import Badge from '../badge';
import Variable from '../../lib/metadata';


type Props = {
    dimGrainIndex: CategoryIndex,
    selection: Array<Variable>,
    onToggleAllOff: (selection: Array<Variable>) => void,
    onClick: (catIndex: number) => void,
    catWhiteList?: Array<string>,
    catBlackList?: Array<string>
}

export default function CategoryList(props: Props) {
    const {dimGrainIndex, selection, onToggleAllOff, onClick, catWhiteList, catBlackList} = props;

    function getEnabledVariablesCount(catVariables: Array<Variable>): number {
        let count = 0;
        for (const variable of catVariables) {
            if (selection.some(s => s.getId() === variable.getId())) {
                count++;
            }
        }

        return count;
    }

    function processCategory(category: string, variables: Array<Variable>, i: number) {
        if (catWhiteList?.length && !catWhiteList.includes(category))
            return null;
        if (catBlackList?.length && catBlackList.includes(category))
            return null;

        return renderCategory(category, variables, i);
    }

    function renderCategory(category: string, variables: Array<Variable>, i: number) {
        return <div key={i} className="ml-6 w-full flex justify-between py-2 px-5 hover:bg-gray-200"
            onClick={e => {e.stopPropagation(); onClick(i);}}>
            <div>{category}</div>
            {getEnabledVariablesCount(variables) > 0 && <Badge color="gray" pill onClose={() => onToggleAllOff(selection.filter(s => !variables.find(v => v.name === s.name)))}>
                <div className="px-1 text-lg leading-none">
                    {getEnabledVariablesCount(variables)}
                </div>
            </Badge>}
        </div>;
    }

    return <div>
        {dimGrainIndex.map((category, variables, i) => processCategory(category, variables, i))}
    </div>;
}