import {DatasetDefinition} from 'torch-data-defs';
import {datasets, datasetByName} from './data-defs';
import {users} from './api';
import {UserDataset} from './data-types';
import Variable, {batchLoadDatasetMetadata} from './metadata';
import Cache from './cache';

const integrationTagBlacklist = [
    'id',
    'name',
    'latitude',
    'longitude',
];

const integrationTagGeo: Record<string, string> = {
    cbsa: 'zip-geo:cbsa',
    cd: 'zip-geo:congressional_district',
    county: 'zip-geo:fips',
    hrr: 'zip-geo:hrr_num',
    state: 'zip-geo:state_fips',
    year: 'year::key',
};

function getGrain(variable: Variable): string | null {
    const integrationTag = variable.getTag('IntegrationTag');
    if (integrationTag) {
        if (integrationTagBlacklist.includes(integrationTag))
            return null;
        if (integrationTagGeo[integrationTag])
            return integrationTagGeo[integrationTag];
        const dataset = datasetByName[integrationTag];
        if (dataset) {
            if (variable.name.toLowerCase().endsWith('key'))
                return dataset.name + '::key';
            return dataset.name + '::id';
        }
    }
    return null;
}

export async function listUserDataDefs(signal: AbortSignal): Promise<Array<DatasetDefinition>> {
    const userDatasets: Array<UserDataset> = await users.datasets.list(signal);
    const dataDefs: Array<DatasetDefinition> = [];

    const allVariables = await batchLoadDatasetMetadata(userDatasets.map(ud => ud.uuid), signal);
    userDatasets.forEach((userDataset, i) => {
        const variables = allVariables[i];
        const grainList: Array<string> = [];

        const tableGrain: Array<string> = [];
        for (const variable of variables) {
            const grain = getGrain(variable);
            if (grain) {
                grainList.push(grain);
                tableGrain.push('!' + grain.split('::')[0]);
            }
        }

        dataDefs.push({
            name: userDataset.uuid,
            tableType: userDataset.entity ? 'dim' : 'fact',
            grain: grainList,
            tableGrain,
            foreignKeys: grainList,
        });
    });
    return dataDefs;
}

export type DataDefRepo = {
    datasets: Array<DatasetDefinition>,
    datasetByName: Record<string, DatasetDefinition>,
}

export default async function getDataDefRepo(signal: AbortSignal): Promise<DataDefRepo> {
    const key = 'data-def-repo';
    try {
        return Cache.get<DataDefRepo>(key);
    } catch (e) {
        if (e instanceof Cache.CacheMiss) {
            const userDataDefs = await listUserDataDefs(signal);
            const userDataDefByName: Record<string, DatasetDefinition> = {};
            userDataDefs.forEach(ds => userDataDefByName[ds.name] = ds);

            const dataDefRepo = {
                datasets: datasets.concat(userDataDefs),
                datasetByName: Object.assign({}, datasetByName, userDataDefByName),
            };
            Cache.set(key, dataDefRepo);
            return dataDefRepo;
        } else
            throw e;
    }
}