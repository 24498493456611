import GraphRectifier from './graph-rectifier';
import Variable from '../../../../metadata';
import {DataDefRepo} from '../../../../user-data-defs';
import {FilterNode, QuantumNode, TableInputNode} from 'quantum-graph/out/v1';
import {TestType} from 'quantum-graph';
import V1GraphHandler from '../../../graph-handler/v1-graph-handler';


export default class YearGrainRectifier extends GraphRectifier {

    hasYearGrain(topNode: QuantumNode, metadata: Array<Variable>, datasetRepo: DataDefRepo): boolean {
        const hasGrainSig = !!topNode.getSignature(datasetRepo).grains.find(g => !g.sourceName && g.name === 'year_key');
        if (hasGrainSig) {
            // Find if there is a non-year variable for a year-based dataset. The reason we do this is because if there
            // is only year_key added from a dataset, then we should say that actually *don't* have a year grain, and
            // should remove that variable.
            return !!metadata.filter(v => v.dataset && v.name !== 'year_key').find(v => {
                const dsDef = datasetRepo.datasetByName[v.dataset as string];
                return dsDef.grain!.includes('year::key');
            });
        }
        return false;
    }

    getYearFilter(gh: V1GraphHandler) {
        let yearFilter: FilterNode | null = null;
        gh.topNode.dfs((node: QuantumNode) => {
            if (yearFilter)
                return;
            if (node instanceof FilterNode && node.column.name.toLowerCase() === 'year_key')
                yearFilter = node;
        });
        return yearFilter;
    }

    getYearVariable(metadata: Array<Variable>) {
        return metadata.find(v => v.getTag('Time')?.toLowerCase() === 'year');
    }

    findYearVariable(topNode: QuantumNode, dataLibrary: Array<Array<Variable>>, datasetRepo: DataDefRepo): Variable {
        // First find the dataset that has the year grain
        let dataset = '';
        topNode.dfs((node: QuantumNode) => {
            if (dataset)
                return;
            if (node instanceof TableInputNode) {
                const dsDef = datasetRepo.datasetByName[node.dataset];
                if (dsDef.grain!.includes('year::key'))
                    dataset = node.dataset;
            }
        });
        if (!dataset)
            throw new Error('Cannot find a dataset in the graph that has a year grain');

        // Second, find that variable in the data library
        let yearVariable: Variable | null = null;
        dataLibrary.forEach(vars => vars.forEach(v => {
            if (v.dataset === dataset && v.getTag('Time')?.toLowerCase() === 'year')
                yearVariable = v;
        }));
        if (!yearVariable)
            throw new Error('Cannot find the year key in the data library for dataset: ' + dataset);
        return yearVariable;
    }

    getYearValue(variable: Variable) {
        const value = variable.getTag('LatestTime');
        if (!value)
            return new Date().getFullYear() - 1;
        return Number(value);
    }

    apply(gh: V1GraphHandler, metadata: Array<Variable>, dataLibrary: Array<Array<Variable>>, datasetRepo: DataDefRepo): void {
        // The questions we need to answer are: Do we need to add a year filter? Do we need to remove a year filter?
        const yearFilter = this.getYearFilter(gh);
        let yearVar = this.getYearVariable(metadata);

        if (this.hasYearGrain(gh.topNode, metadata, datasetRepo)) {
            if (!yearVar) { // Find the year_key variable in the data library and add it
                yearVar = this.findYearVariable(gh.topNode, dataLibrary, datasetRepo);
                metadata.push(yearVar);
            }
            if (!yearFilter) { // Add the year filter
                const filter = {
                    variable: yearVar,
                    test: TestType.Eq,
                    value: this.getYearValue(yearVar),
                };
                gh.addColumn(yearVar.dataset as string, yearVar.dataset as string, yearVar.name).applyFilters([filter]);
            }
        } else {
            if (yearVar) { // Remove the year_key variable
                const index = metadata.indexOf(yearVar);
                metadata.splice(index, 1);
                gh.removeColumn(yearVar.dataset as string, yearVar.name);
            }
            if (yearFilter) // Remove the year filter
                gh.removeNode(yearFilter, 1);
        }

    }
}