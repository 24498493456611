import {HCIndexSection} from '../types';
import {Link} from 'react-router-dom';
import React, {useMemo} from 'react';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

type itemData = {
    name: string,
    description: any,
    date: string,
    path: string,
};

type Props = {
    section: HCIndexSection,
}

export default function HCIndexTable(props: Props) {
    const {section} = props;
    const items: Array<itemData> = useMemo(() => {
        let items = section.entries.map(e => ({
            name: e.name,
            description: e.description || '',
            date: new Date(e.dateAdded).toLocaleDateString(),
            path: '/healthcare-index/' + e.shortName,
        }));
        items = items.concat(section.links.map(l => ({
            name: l.name,
            description: l.description,
            date: new Date(l.dateAdded).toLocaleDateString(),
            path: l.path,
        })));
        items.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        return items;
    }, [section.entries, section.links]);

    return <table className="w-full mt-5 mb-20">
        <thead>
            <tr>
                <th className="w-1/4 text-left">Dataset</th>
                <th className="w-3/5 text-left">Description</th>
                <th className="text-right">Date Added</th>
            </tr>
        </thead>
        <tbody>
            {items.map((item, i) =>
                <tr key={i} className={i % 2 === 0 ? 'bg-gray-300' : ''}>
                    <td className=""><Link to={item.path}>
                        {item.name}
                    </Link></td>
                    {item.description && <td>{documentToReactComponents(item.description)}</td>}
                    <td className="text-right">{item.date}</td>
                </tr>
            )}
        </tbody>
    </table>;
}