import Variable, {ValueType} from './metadata';
import {filterValue, TestType} from 'quantum-graph';
import {dataRows} from './data-types';

export default interface Filter {
    variable: Variable,
    test: TestType,
    value: filterValue | Array<filterValue>,
    // eslint-disable-next-line semi
}

export function applyFilter(data: dataRows, filter: Filter): dataRows {
    return data.filter(row => {
        let value = filter.variable.getValue(row);
        let filterValue = filter.value;
        if (filter.variable.getValueType() === ValueType.Number) {
            value = Number(value);
            filterValue = filterValue instanceof Array ? filterValue.map(v => Number(v)) : Number(filterValue);
        }

        if (filter.test === TestType.Lt)
            return (value === null || value === undefined) ? false : value < filterValue;
        else if (filter.test === TestType.LtEq)
            return (value === null || value === undefined) ? false : value <= filterValue;
        else if (filter.test === TestType.Eq)
            // eslint-disable-next-line eqeqeq
            return value == filterValue;
        else if (filter.test === TestType.NEq)
            // eslint-disable-next-line eqeqeq
            return value != filterValue;
        else if (filter.test === TestType.GtEq)
            return (value === null || value === undefined) ? false : value >= filterValue;
        else if (filter.test === TestType.Gt)
            return (value === null || value === undefined) ? false : value > filterValue;
        else if (filter.test === TestType.In)
            return (value === null || value === undefined) ? false : (filterValue as Array<filterValue>).includes(value);
        else if (filter.test === TestType.NotIn)
            return (value === null || value === undefined) ? false : !(filterValue as Array<filterValue>).includes(value);
        else if (filter.test === TestType.Like) {
            let search = String(filterValue).toLowerCase();
            search = search.substring(1, search.length - 1);
            return (value === null || value === undefined) ? false : String(value).toLowerCase().includes(search);
        } else if (filter.test === TestType.Null)
            return value === null || value === undefined;
        else if (filter.test === TestType.NotNull)
            return value !== null && value !== undefined;
        return false;
    });
}