import {observer} from 'mobx-react';
import {useContext, useMemo, useState} from 'react';
import {CanvasContext, CanvasInsertContext} from '../../../lib/context';
import EntityAutocomplete from '../../../../../components/entity-autocomplete';
import {TestType, deserialize} from 'quantum-graph';
import Checkbox from '../../../../../components/checkbox';
import NetworkRenderer from '../../../../../insight-container/network';

function ConfigOwnership() {
    const insertStore = useContext(CanvasInsertContext);
    const canvasStore = useContext(CanvasContext);
    const [healthSystem, setHealthSystem] = useState(canvasStore.parameterRow['torch_health_system_id'] ? canvasStore.parameterValue : 0);
    const [useParameter, setUseParameter] = useState(false);

    insertStore.query = useMemo(() => deserialize({
        'version': 2,
        'baseQueryGraph': {
            'baseQuery': {
                'queryType': 'dim',
                'tableNodes': [
                    {
                        'id': 'health-system',
                        'dataset': 'health-system',
                        'columns': [
                            'torch_health_system_id',
                        ]
                    }
                ]
            },
            'joins': []
        },
        'filters': [
            {
                'type': 'basic',
                'column': {
                    'nodeId': 'health-system',
                    'column': 'torch_health_system_id'
                },
                'test': '=',
                'value': healthSystem
            }
        ],
        'transforms': []
    }), [healthSystem]);

    function swapUseParameter() {
        if (useParameter) {
            insertStore.parameterConfigs = [];
            setUseParameter(false);
        } else {
            insertStore.addParameterConfig({
                parameterField: 'torch_health_system_id',
                dataModelField: 'torch_health_system_id',
                modelDataset: 'health-system',
                test: TestType.Eq
            });
            setHealthSystem(canvasStore.parameterRow['torch_health_system_id'] ? canvasStore.parameterValue : 0);
            setUseParameter(true);
        }
    }
    
    return <div className='flex flex-row gap-4'>
        <div className='flex flex-col gap-4'>
            <div className="flex flex-col space-y-2">
                <label>Select Health System</label>
                <EntityAutocomplete dataset='health-system' value={Number(healthSystem)} onChange={entity => setHealthSystem(entity.id)} disabled={useParameter} />
            </div>
            <div className="flex space-x-3 items-center cursor-pointer" onClick={() => (canvasStore && canvasStore.parameter) ? swapUseParameter() : null}>
                <Checkbox checked={useParameter} onChange={() => true} disabled={!canvasStore || !canvasStore.parameter} color="blue" />
                <label>Use Canvas parameter</label>
            </div>
        </div>
        <div className='w-auto grow'>
            <NetworkRenderer dataConfig={insertStore.dataConfig} />
        </div>
    </div>;
}

export default observer(ConfigOwnership);
