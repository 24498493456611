import Autocomplete from '../../components/autocomplete';
import React, {useState} from 'react';
import {queryCityState} from './search-common';
import {CityStateResult, SearchResult} from './search-types';

type Props = {
    onChange: (city: string, state: string) => void,
    className?: string
}

export default function CityStateSelect(props: Props) {
    const {onChange, className} = props;
    const [value, setValue] = useState('');
    const [cityStates, setCityStates] = useState<Array<SearchResult<CityStateResult>>>([]);

    async function getOptions(query: string) {
        if (!query) {
            onChange('', '');
            return [];
        }

        const controller = new AbortController();
        const list = await queryCityState(query, controller);
        setCityStates(list);
        return list.map((s: any) => s._type === 'states' ? s._source.name : `${s._source.name}, ${s._source.abbreviateState}`);
    }

    function handleChange(value: string) {
        if (value.includes(', ')) {
            const [city, state] = value.split(', ');
            onChange(city, state);
        } else {
            const source: CityStateResult = cityStates.find(cs => cs._source.name === value)!._source as CityStateResult;
            onChange('', source.abbreviateState);
        }
        setValue(value);
    }

    return <Autocomplete value={value} onChange={handleChange} getOptions={getOptions} className={className} />;
}