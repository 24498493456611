
// There is room here for different retention policies. They would each need to be tested with how they perform for
// clients.
//   FIFO: Basically we keep the N most recent cache items
//   Exp: Cache items have an expiration date at which time they are purged
//   Size: Oldest items are purged once a size threshold has been reached.

const MaxRetentions = 500;


interface ICache {
    set<T>(key: string, value: T): void,
    get<T>(key: string): T,
}

export class CacheMiss extends Error {}


const InMemoryCache = {
    keys: ((): Array<string> => [])(),
    store: ((): Record<string, any> => ({}))(),
    get<T>(key: string): T {
        if (this.store.hasOwnProperty(key))
            return this.store[key] as T;
        throw new CacheMiss();
    },
    set<T>(key: string, value: T) {
        // Retention policy: FIFO
        this.keys.push(key);
        if (this.keys.length > MaxRetentions) {
            const oldKey = String(this.keys.shift());
            delete this.store[oldKey];
        }

        this.store[key] = value;
    },
};

let storageEngine: Storage;
// storageEngine = localStorage;
storageEngine = sessionStorage;
const StorageCache = {
    getKeys(): Array<string> {
        return JSON.parse(storageEngine.getItem('cache-keys') || '[]');
    },
    queueKey(key: string) {
        const keys = this.getKeys();
        keys.push(key);
        storageEngine.setItem('cache-keys', JSON.stringify(keys));
    },
    dequeueKey(): string {
        const keys = this.getKeys();
        const key = String(keys.shift());
        storageEngine.setItem('cache-keys', JSON.stringify(keys));
        return key;
    },
    get<T>(key: string): T {
        key += '[ls]';
        const item = storageEngine.getItem(key);
        if (!item)
            throw new CacheMiss();
        return JSON.parse(item) as T;
    },
    set<T>(key: string, value: T) {
        key += '[ls]';

        // Retention policy: FIFO
        this.queueKey(key);
        if (this.getKeys().length > MaxRetentions) {
            const oldKey = this.dequeueKey();
            storageEngine.removeItem(oldKey);
        }

        storageEngine.setItem(key, JSON.stringify(value));
    }
};


let cacheBackend: ICache = StorageCache;
function setCacheBackend(backend: ICache) {
    cacheBackend = backend;
}

export {InMemoryCache, StorageCache};


const Cache = {
    set<T>(key: string, value: T) {
        cacheBackend.set(key, value);
    },
    get<T>(key: string): T {
        return cacheBackend.get(key);
    },
    setCacheBackend,
    CacheMiss,
    // setRetentionPolicy,
};

export default Cache;