import {search} from '../../lib/api';
import {CityStateResult, EntityResult, ResultType, SearchResult} from './search-types';

export const entityKey = 'entity-';

export const SearchFilterValues = [
    {name: 'Everything', value: 'all'},
    {name: 'All Data', value: 'entities'},
    {name: 'ACOs', value: entityKey + 'aco'},
    {name: 'Diabetes Prevention Program Sites', value: entityKey + 'dpp'},
    {name: 'Electronic Health Record Systems', value: entityKey + 'ehr'},
    {name: 'Facilities', value: entityKey + 'facility'},
    {name: 'Organizations', value: entityKey + 'org'},
    {name: 'Payer Subsidiaries', value: entityKey + 'payer'},
    {name: 'Payer Parent Companies', value: entityKey + 'payer-group'},
    {name: 'Provider Groups', value: entityKey + 'provider-group'},
    {name: 'Providers', value: entityKey + 'provider'},
    {name: 'Dashboards', value: 'dashboards'},
    {name: 'Tools', value: 'tools'},
    {name: 'Regions', value: 'regions'}
];

const entityTypeWeights: Record<string, number> = {
    hospital: 2,
    healthsystem: 3,
};

/**
 * Will query all endpoints based on a search filter and search query
 * @param searchFilter
 * @param searchQuery
 * @param state
 * @param city
 * @param controller
 * @returns Promise<Array<SearchResult>>
 */
export async function performQueries(searchFilter: string, searchQuery: string, state: string, city: string, controller: AbortController): Promise<Array<SearchResult<ResultType>>> {
    let rv;
    if (searchFilter === 'all') {
        const entities = await search.entities(searchQuery, state, city, controller.signal);
        const regions = await search.regions(searchQuery, controller.signal);
        const tools = await search.tools(searchQuery, controller.signal);
        const dashboards = await search.dashboards(searchQuery, controller.signal);
        rv = [...entities.results, ...regions.results, ...tools.results, ...dashboards.results];
    } else if (searchFilter === 'header-search') {
        const entities = await search.entities(searchQuery, state, city, controller.signal);
        const regions = await search.regions(searchQuery, controller.signal);
        const tools = await search.tools(searchQuery, controller.signal);
        rv = [...entities.results, ...regions.results, ...tools.results];
    } else if (searchFilter === 'entities') {
        const entities = await search.entities(searchQuery, state, city, controller.signal);
        rv = [...entities.results];
    } else if (searchFilter.includes(entityKey)) {
        const type = searchFilter.split(entityKey)[1];
        const entityByType = await search.entitiesByType(searchQuery, type, state, city, controller.signal);
        rv = [...entityByType.results];
    } else {
        const response = await search[searchFilter](searchQuery, controller.signal);
        rv = [...response.results];
    }

    return processQuery(rv);
}

/**
 * Sorts the results of a search query
 * @param searchResults
 * @returns Array<SearchResult>
 */
function processQuery(searchResults: Array<SearchResult<ResultType>>): Array<SearchResult<ResultType>> {
    if (searchResults) {
        // Weight some entities higher than others
        searchResults.forEach((r: SearchResult<ResultType>) => {
            if (r._source.hasOwnProperty('entityType')) {
                const source: EntityResult = r._source as EntityResult;
                r._score *= entityTypeWeights[source.entityType] || 1;
            }
        });
        searchResults = searchResults.sort((a, b) => b._score - a._score)
            .slice(0, 100);
    }

    return searchResults;
}

/**
 * Queries endpoints for city and states available to populate City or State auto complete
 * @param query
 * @param controller
 * @returns Promise<Array<SearchResult>>
 */
export async function queryCityState(query: string, controller: AbortController): Promise<Array<SearchResult<CityStateResult>>> {
    const states = await search.states(query, controller.signal);
    const cities = await search.cities(query, controller.signal);
    const sortedCities = cities.results.sort((a: any, b: any) => b._score = a._score).slice(0, 100);

    return [...states.results, ...sortedCities];
}