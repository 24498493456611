import Variable from '../../lib/metadata';

const maxOrder = 10;
const order: Record<string, number> = {
    state: 0,
    msa_division: 1,
    msa_division_name: 2,

    drg: 3,
    diagnosis_related_group_description: 4,
    major_diagnostic_category: 5,
    major_diagnostic_category_description: 6,
    mdc: 5,
    mdc_desc: 6,
    los: 7,
    admits: 8,
};

export function orderCRBMetadata(metadata: Array<Variable>): Array<Variable> {
    const newMd: Array<Variable> = Array(maxOrder).fill(null);
    for (const variable of metadata) {
        const index = order[variable.name];
        if (index !== undefined)
            newMd[index] = variable;
        else
            newMd.push(variable);
    }

    return newMd.filter(_ => _);
}