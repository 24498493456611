
type ConstraintMap = Record<string, Array<string>>;

const yearLobs: ConstraintMap = {
    2019: ['Medicare FFS'],
    2021: [
        'Commercial',
        'Medicaid',
        'Medicare FFS',
        'Medicaid Dual',
        'Medicare Advantage',
        'Medicare Dual',
    ],
};

const yearDataSource: ConstraintMap = {
    2019: ['VRDC'],
    2021: ['RAD'],
};

const lobDataSources: ConstraintMap = {
    'Commercial': ['RAD'],
    'Medicaid': ['RAD'],
    'Medicare FFS': ['RAD', 'VRDC'],
    'Medicaid Dual': ['RAD'],
    'Medicare Advantage': ['RAD'],
    'Medicare Dual': ['RAD'],
};

const dataSourceRegionTypes: ConstraintMap = {
    'VRDC': ['national', 'state', 'msa-division', 'county'],
    'RAD': ['national'],
};

const dataSourceServiceCategories: ConstraintMap = {
    'VRDC': ['Service Setting', 'Service Subcategory'],
    'RAD': ['Service Subcategory'],
};

const dataSourceHasDemoBreakout: Record<string, boolean> = {
    'VRDC': true,
    'RAD': false,
};

const focusLevelSrcs: ConstraintMap = {
    service: ['virt'],
};


function intersect(values1: Array<string>, values2: Array<string>): Array<string> {
    const intersection: Array<string> = [];
    for (const value of values1) {
        if (values2.includes(value))
            intersection.push(value);
    }
    return intersection;
}

function deriveFocusLevels(srcs: Array<string>) {
    const focusLevels = ['all'];
    for (const src of srcs) {
        if (src === 'cond')
            focusLevels.push('cond');
        else if (src === 'virt')
            focusLevels.push('service');
    }
    return focusLevels;
}

const CommonCoreConstraints = {
    lobs(year: string) {
        return yearLobs[year];
    },
    dataSources(year: string, lob: string) {
        return intersect(yearDataSource[year], lobDataSources[lob]);
    },
    regionTypes(dataSource: string) {
        return dataSourceRegionTypes[dataSource];
    },
    serviceCategories(dataSource: string) {
        return dataSourceServiceCategories[dataSource];
    },
    hasDemoBreakout(dataSource: string) {
        return dataSourceHasDemoBreakout[dataSource];
    },
    yearLobs,
    yearDataSource,
    lobDataSources,
    focusLevelSrcs,
    deriveFocusLevels,
    srcFromFocusLevel(focusLevel: string, srcs: Array<string>) {
        if (focusLevel === 'all')
            return srcs.includes('all') ? 'all' : '';
        else if (focusLevel === 'cond')
            return 'cond';
        else if (focusLevel === 'service')
            return focusLevelSrcs[focusLevel][0];
        return '';
    },
};

export default CommonCoreConstraints;