import {DataDefRepo} from '../../../user-data-defs';
import BaseQueryNode from 'quantum-graph/out/v2/base-query/base-query-graph';
import V2QuantumGraph from 'quantum-graph/out/v2/v2-graph';

export function pruneFacts(baseQueryNode: BaseQueryNode, dataDefRepo: DataDefRepo) {
    baseQueryNode.baseQuery.tableNodes = baseQueryNode.baseQuery.tableNodes.filter(node => {
        const dataDef = dataDefRepo.datasetByName[node.dataset];
        const isFact = dataDef.tableType === 'fact';
        const hasColumns = !!node.columns.length;
        return !isFact || hasColumns;
    });

    baseQueryNode.joins.forEach(join => pruneFacts(join.node, dataDefRepo));
}

export function pruneJoins(query: V2QuantumGraph) {
    query.baseQueryGraph.joins = query.baseQueryGraph.joins.filter(join => {
        const hasJoins = !!join.node.joins.length;
        const allEmpty = join.node.baseQuery.tableNodes.every(node => !node.columns.length);
        return hasJoins || !allEmpty;
    });
}

export function pruneFiltersSorts(query: V2QuantumGraph) {
    query.filters = query.filters.filter(f => f.validate(id => query.lookup(id)));
    query.sort = query.sort.filter(s => query.lookup(s.column.nodeId));
}