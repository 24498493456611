import {DatasetDefinition} from 'torch-data-defs';
import BaseQuery, {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import V2QuantumGraph from 'quantum-graph/out/v2/v2-graph';
import {DataDefRepo} from '../user-data-defs';
import {findFacts} from './join';
import {loadDatasetInfo} from '../metadata';
import Library, {Section} from './index';


export class V2Section extends Section {
    baseQuery: BaseQuery;
    affDataDef: DatasetDefinition | null;
    baseDataset: string;
    factDatasets: Array<string>;

    constructor(baseQuery: BaseQuery, dataDefRepo: DataDefRepo, connectingFacts?: Array<DatasetDefinition>) {
        super();
        this.baseQuery = baseQuery;

        // Check if we're looking at an affiliation query
        let rootNode = baseQuery.tableNodes[0];
        if (baseQuery.queryType === QueryType.Affiliation || baseQuery.queryType === QueryType.TiedAffiliationScaffold) {
            this.affDataDef = dataDefRepo.datasetByName[rootNode.dataset];
            rootNode = baseQuery.tableNodes[1];
        } else
            this.affDataDef = null;

        const dataDef = dataDefRepo.datasetByName[rootNode.dataset];
        this.baseDataset = dataDef.name;
        if (connectingFacts)
            this.factDatasets = connectingFacts.map(ds => ds.name);
        else
            this.factDatasets = findFacts(dataDef, dataDefRepo);

        if (baseQuery.queryType === QueryType.Affiliation ||
            baseQuery.queryType === QueryType.TiedAffiliationScaffold ||
            baseQuery.queryType === QueryType.AffiliationScaffold) {
            this.factDatasets = this.factDatasets.filter(ds => {
                let dataset = dataDefRepo.datasetByName[ds];
                return dataset.tableGrain.some(g => g === 'year_key');
            });
        }
    }

    async loadName(signal: AbortSignal) {
        this.dsInfo[this.baseDataset] = await loadDatasetInfo(this.baseDataset, signal);
        this.name = this.dsInfo[this.baseDataset].label || this.baseDataset;
    }

    protected getDatasets(): Array<string> {
        const datasets = [this.baseDataset].concat(this.factDatasets);
        if (this.affDataDef)
            datasets.push(this.affDataDef.name);
        return datasets;
    }

}

export default class V2Library extends Library {

    constructor(graph: V2QuantumGraph, dataDefRepo: DataDefRepo, connectingFacts?: Array<DatasetDefinition>) {
        super();
        this.sections.push(new V2Section(graph.baseQueryGraph.baseQuery, dataDefRepo, connectingFacts));
        for (const join of graph.baseQueryGraph.joins) {
            this.sections.push(new V2Section(join.node.baseQuery, dataDefRepo, connectingFacts));

            // Note that we don't currently support data libraries with nested joins
            if (join.node.joins.length)
                console.warn('Nested joins detected. Not currently supported');
        }
    }
}