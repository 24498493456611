import Variable from './metadata';


export default function getDateCoverage(variable: Variable): string | null {
    const start = variable.getTag('EarliestObservation');
    const end = variable.getTag('LatestObservation');
    if (!start || !end)
        return null;

    // Pull out the ultimate start and end dates from the observation ranges
    const startMatch = /^(\d{4}-\d{2}-\d{2}).*/.exec(start);
    const endMatch = /.*(\d{4}-\d{2}-\d{2})$/.exec(end);
    if (!startMatch || !endMatch)
        return null;

    const startDate = new Date(startMatch[1]).toLocaleDateString('en-Us', {timeZone: 'utc'});
    const endDate = new Date(endMatch[1]).toLocaleDateString('en-Us', {timeZone: 'utc'});
    return startDate + ' to ' + endDate;
}