import {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import {Organization} from '../../../lib/data-types';
import auth from '../../../lib/auth';
import Badge from '../../../components/badge';
import Button from '../../../components/button';
import {organizations, analytics} from '../../../lib/api';
import {HeaderContext} from '../../../components/header/context';
import {CheckmarkOutline, CloseOutline, Group, UserFilled} from '@carbon/icons-react';

type Props = {
    org: Organization,
    allowProxy: boolean
    current: boolean,
    onError: (error: Error) => void
}

export default function OrgOverview(props: Props) {
    const {org, allowProxy, current, onError} = props;
    const [isModerator, setIsModerator] = useState<boolean>(false);
    const [analyticsAccess, setAnalyticsAccess] = useState<boolean>(false);
    const headerStore = useContext(HeaderContext);

    useEffect(() => {
        const controller = new AbortController();
        organizations.users.get(org.id!, auth.principal!.sub, controller.signal).then(memberships => {
            setIsModerator(memberships.isModerator);
        }).catch(onError);
        analytics.org.checkAccess(org.id!, controller.signal).then(accessResult => {
            setAnalyticsAccess(accessResult.hasAccess);
        }).catch(onError);

        return () => controller.abort();
    }, [org, onError]);

    function proxyOrg() {
        auth.proxyOrg(org.id!).then(() => window.location.href = '/user/orgs');
    }

    function legacyOrgOverview() {
        return <div className="w-full bg-gray-300 rounded-2xl shadow p-5 flex items-center justify-around">
            <div className="flex space-x-5 items-center">
                <i className="icon-group text-4xl text-gray-500" />
                <div className={'text-xl'}>{org.name}</div>
                {current && <Badge color={'green'} pill>Current Proxy</Badge>}
                <Badge color={'light'} pill><i className="icon-user text-gray-500" /> {org.size} / {org.sizeLimit}</Badge>
                {org.adminUsers?.some(admin => admin === auth.principal!.sub) && <div>
                    <Link className="button light hover:no-underline" to={`/org/${org.id}/membership`}>Manage</Link>
                </div>}
                {analyticsAccess && <div>
                    <a className="button light hover:no-underline" href={`//${process.env.REACT_APP_TI_DOMAIN}/org/${org.id}/analytics`}>Analytics</a>
                </div>}
                {isModerator && <div>
                    <Link className="button light hover:no-underline" to={`/org/${org.id}/invite`}>Invite Others</Link>
                </div>}
                {allowProxy && <div>
                    <Button color={'light'} onClick={proxyOrg}>Proxy</Button>
                </div>}
            </div>
            <div className="flex flex-grow items-center justify-end">
                <div className="flex flex-col justify-center items-center space-y-3">
                    <div>
                        <Badge color={org.active ? 'green' : 'orange'} pill>{org.active ? 'Active' : 'Inactive'}</Badge>
                    </div>
                    <small>{org.active ? 'Expires' : 'Expired'} on {new Date(org.contractEnd!).toLocaleDateString()}</small>
                </div>
            </div>
        </div>;
    }

    function dppOrgOverview() {
        return <div className="w-full bg-background-gray-100 p-4 flex items-center gap-4">
            <div className="flex gap-3 items-center">
                <Group size={20} />
                <div className={'font-bold mr-6'}>{org.name}</div>
                {current && <Badge color={'green'} pill>Current Proxy</Badge>}
                <div className='rounded-full bg-background-gray-200 pl-2 pr-1 py-1 text-xs flex items-center gap-1'>
                    <div>{org.size} / {org.sizeLimit}</div> <UserFilled size={14} />
                </div>
                {org.adminUsers?.some(admin => admin === auth.principal!.sub) && <div className='rounded-full bg-background-gray-200 px-2 py-1 text-xs'>
                    <Link className="hover:no-underline text-background-gray-900" to={`/org/${org.id}/membership`}>Manage</Link>
                </div>}
                {analyticsAccess && <div className='rounded-full bg-background-gray-200 px-2 py-1 text-xs'>
                    <a className="hover:no-underline text-background-gray-900" href={`//${process.env.REACT_APP_TI_DOMAIN}/org/${org.id}/analytics`}>Analytics</a>
                </div>}
                {isModerator && <div className='rounded-full bg-background-gray-200 px-2 py-1 text-xs'>
                    <Link className="hover:no-underline text-background-gray-900" to={`/org/${org.id}/invite`}>Invite Others</Link>
                </div>}
                {allowProxy && <div className='rounded-full bg-background-gray-200 px-2 py-1 text-xs'>
                    <div onClick={proxyOrg}>Proxy</div>
                </div>}
            </div>
            <div className='grow' />
            <div className='text-xs'>{org.active ? 'Expires' : 'Expired'} {new Date(org.contractEnd!).toLocaleDateString()}</div>
            <div className={`rounded-full pl-2 pr-1 py-1 text-xs flex items-center font-semibold gap-1 ${org.active ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600 '}`}>
                {org.active ? <><div>Active</div> <CheckmarkOutline size={16} /></> : <><div>Inactive</div> <CloseOutline size={16} /></>}
            </div>
        </div>;
    }

    return headerStore.useNewDesign ? dppOrgOverview() : legacyOrgOverview();
}