import React, {useState} from 'react';
import Select from '../../../components/select';
import 'quantum-graph';
import Alert from '../../../components/alert';

type ApiDocNodeType = {
    type: string,
    ntype: string,
    label: string,
    description: string,
    inputs: number,
    attributes?: Array<{name: string, description: string}>,
    refs?: Array<string>,
};
const nodes: Array<ApiDocNodeType> = [
    {
        type: 'aggregate',
        ntype: 'Operation',
        label: 'Aggregate',
        description: 'Will apply aggregations over the defined columns.',
        inputs: 1,
        attributes: [
            {name: 'aggregations', description: 'A list of aggregation signatures'}
        ],
        refs: ['aggSig']
    },
    {
        type: 'count',
        ntype: 'Operation',
        label: 'Count',
        description: 'Instead of returning data, will return the number of data records.',
        inputs: 1,
    },
    {
        type: 'count-distinct',
        ntype: 'Operation',
        label: 'Count Distinct Values',
        description: 'Instead of returning the entire data, will return the number of unique values for the defined column.',
        inputs: 1,
        attributes: [
            {name: 'column', description: 'The column signature to show unique value count for.'},
        ],
        refs: ['colSig'],
    },
    {
        type: 'distinct',
        ntype: 'Operation',
        label: 'Distinct Values',
        description: 'Instead of returning the entire data, will return the unique values for the defined column.',
        inputs: 1,
        attributes: [
            {name: 'column', description: 'The column signature to show unique values for.'},
        ],
        refs: ['colSig'],
    },
    {
        type: 'filter',
        ntype: 'Operation',
        label: 'Filter',
        description: 'Applies a filter to a specific column to limit results',
        inputs: 1,
        attributes: [
            {name: 'column', description: 'The column signature to apply the filter on.'},
            {name: 'test', description: 'What type of filter operation to perform. Valid values are listed below.'},
            {name: 'value', description: 'The value or values to compare against when performing the filtering.'},
        ],
        refs: ['colSig', 'tests'],
    },
    {
        type: 'grainFilter',
        ntype: 'Operation',
        label: 'Grain Filter',
        description: 'Applies a filter to an entire grain to limit results',
        inputs: 1,
        attributes: [
            {name: 'grain', description: 'The grain signature to apply the filter on.'},
            {name: 'test', description: 'What type of filter operation to perform. Valid values are listed below.'},
            {name: 'value', description: 'The value or values to compare against when performing the filtering.'},
        ],
        refs: ['grainSig', 'tests'],
    },
    {
        type: 'grainSuppressor',
        ntype: 'Operation',
        label: 'Grain Suppressor',
        description: 'Removes a grain for all downstream nodes. Useful when wanting to fine-tune joining nodes together.',
        inputs: 1,
        attributes: [
            {name: 'grain', description: 'The grain signature to remove.'},
        ],
        refs: ['grainSig'],
    },
    {
        type: 'join',
        ntype: 'Operation',
        label: 'Join',
        description: 'Combines the data of two nodes or sub-trees together',
        inputs: 2,
        attributes: [
            {name: 'joinType', description: 'The type of joining operation. Valid values are listed below.'},
        ],
        refs: ['join'],
    },
    {
        type: 'page',
        ntype: 'Operation',
        label: 'Page',
        description: 'Applies pagination to limit the number of results',
        inputs: 1,
        attributes: [
            {name: 'pageSize', description: 'The number of records to include in each page.'},
            {name: 'page', description: 'Which page to return.'},
        ],
    },
    {
        type: 'sort',
        ntype: 'Operation',
        label: 'Sort',
        description: 'Sorts the data by the specified column',
        inputs: 1,
        attributes: [
            {name: 'column', description: 'The column signature to sort by.'},
            {name: 'asc', description: 'Boolean if the results should be sorted ascending.'},
        ],
        refs: ['colSig']
    },
    {
        type: 'table',
        ntype: 'Data Input',
        label: 'Table Input',
        description: 'Pulls the specified columns from the identified dataset.',
        inputs: 0,
        attributes: [
            {name: 'dataset', description: 'The dataset to pull data from'},
            {name: 'columns', description: 'A list of column names to pull'},
        ],
    },
];


export default function APIDocNodeTypes() {
    const [activeType, setActiveType] = useState('table');
    const nodeType = nodes.find(n => n.type === activeType) || nodes[0];

    return <div>
        <h2>v1 Query Node Reference</h2>

        <Alert color="yellow" icon="icon-warning" border noDismiss>
            Note: It is recommended that you should should use version <code>2</code> graphs as it provides an easier
            experience manually authoring graphs.
        </Alert>

        <p>
            Version <code>1</code> queries are structured in graph form, which each node of the graph representing
            either data input or some manipulation of the data. The most common nodes are:
        </p>
        <ul className="list-disc list-inside">
            <li>Get data - use a <code>table</code> node</li>
            <li>Connect data - use a <code>join</code> node</li>
            <li>Sort - use a <code>sort</code> node</li>
            <li>Filter - use a <code>filter</code> node</li>
        </ul>

        <p>
            Each node has three attributes:
        </p>
        <ul className="list-disc list-inside">
            <li><code>id</code> - A unique identifier for the node</li>
            <li><code>type</code> - The type of node</li>
            <li><code>incoming</code> - A list of other nodes that connect to this node</li>
        </ul>
        <p>
            Different nodes may have additional attributes. Select a node for details on its attributes.
        </p>
        <Select<ApiDocNodeType> value={activeType} data={nodes} onChange={setActiveType}>
            {n => n.type}
            {n => <span><code>{n.type}</code> - {n.ntype} Node</span>}
        </Select>
        <hr/>

        <div className="my-6 px-4 py-4 bg-gray-100 rounded">
            <h3>{nodeType.label} Node</h3>
            <p>Type name: <code>{nodeType.type}</code></p>
            <p>Number of incoming: <code>{nodeType.inputs}</code></p>
            <p>{nodeType.description}</p>

            {nodeType.attributes && <div>
                <p className="mt-4 font-bold text-blue-700">Additional Attributes</p>
                <ul className="list-disc list-inside">
                    {nodeType.attributes.map(a => <li key={a.name}>
                        <code>{a.name}</code> - {a.description}
                    </li>)}
                </ul>
            </div>}
        </div>

        {nodeType.refs && nodeType.refs.map(r => <div key={r}>
            {r === 'join' && <p>
                Valid join types are: <code>left</code>, <code>inner</code>, and <code>right</code>. For more info on
                what different join types do, <a href="https://en.wikipedia.org/wiki/Join_(SQL)">see here</a>.
            </p>}
            {r === 'tests' && [
                <p key={0}>The test type is a string. Valid test types are:</p>,
                <ul key={1} className="list-disc list-inside">
                    <li><code>&lt;</code> - Less than</li>
                    <li><code>&lt;=</code> - Less than or equal to</li>
                    <li><code>=</code> - Equal to</li>
                    <li><code>&gt;=</code> - Greater than or equal to</li>
                    <li><code>&gt;</code> - Greater than</li>
                    <li><code>&lt;&gt;</code> - Not equal to</li>
                    <li><code>in</code> - Data value is one of the values defined in <code>value</code></li>
                    <li><code>not in</code> - Data value is not one of the values defined in <code>value</code></li>
                    <li><code>like</code> - The text in <code>value</code> is a substring of the data value</li>
                    <li><code>is null</code> - The data value is <em className="text-gray-500">null</em></li>
                    <li><code>is not null</code> - The data value is not <em className="text-gray-500">null</em></li>
                </ul>
            ]}
            {r === 'aggSig' && [
                <p key={0}>An aggregate signature is a list containing the aggregate method and column signature:</p>,
                <pre key={1}>[Aggregate: string, ColumnNodeId: string, ColumnName: string]</pre>,
                <p key={2}>
                    Valid aggregate values are:
                </p>,
                <ul className="list-disc list-inside" key={3}>
                    <li><code>min</code></li>
                    <li><code>max</code></li>
                    <li><code>sum</code></li>
                    <li><code>avg</code></li>
                    <li><code>count</code></li>
                </ul>
            ]}
            {r === 'colSig' && [
                <p key={0}>A column signature is a list containing the id of the node that the column comes from and the column name.</p>,
                <pre key={1}>[ColumnNodeId: string, ColumnName: string]</pre>,
            ]}
            {r === 'grainSig' && [
                <p key={0}>A grain signature is a list containing the dataset name, the name of the grain, and (optionally) the name of the column if it differs from the grain name.</p>,
                <pre key={1}>[Dataset: string, GrainName: string, ColumnName: string]</pre>,
            ]}
        </div>)}

    </div>;
}