import React from 'react';
import './page-menu.scss';

type Props = {
    children: React.ReactNode,
};

export default function PageMenu(props: Props) {
    return <div className="page-menu">
        <div className="container">
            {props.children}
        </div>
    </div>;
}