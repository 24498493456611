import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useEffect} from 'react';


type Props = {
    editable: boolean
}
export default function RichTextEditable(props: Props) {
    const {editable} = props;
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (editor)
            editor.setEditable(!!editable);
    }, [editable, editor]);

    return null;
}