import {observer} from 'mobx-react';
import {TransformCBs} from '../../../lib/data-types';
import NoAccessMsg, {NoAccessErrMsg} from '../../../components/no-access-msg';
import ErrorMessage from '../../../components/error-message';
import Loading from '../../../components/loading';
import React, {useContext, useEffect, useState} from 'react';
import AGGridDataContext from './context';
import fetchData, {fetchMetadata} from '../../../lib/data';
import handleError from '../../../lib/error';


const AGGridDataRenderer = observer((props: {children: JSX.Element, loadCB?: (promise: Promise<void>) => void, transforms?: TransformCBs}) => {
    const store = useContext(AGGridDataContext);
    const [err, setErr] = useState<string>('');
    const [noAccess, setNoAccess] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        store.mdLoading = true;
        const graph = store.getGH().build();
        fetchMetadata(graph, controller.signal).then(async variables => {
            store.parseMetadata(variables);
            store.updateData();
        }).catch(err => {
            if (err.message.startsWith(NoAccessErrMsg))
                setNoAccess(true);
            else
                handleError(err, setErr);
        }).finally(() => store.mdLoading = false);

        return () => controller.abort();
    }, [store.mdCounter]);

    useEffect(() => {
        store.loading = true;
        if (!store.columnDefinitions.length || store.mdLoading)
            return;

        const graph = store.getGH().build();
        const controller = new AbortController();
        store.dataPromise = new Promise((resolve, reject) => {
            fetchData(graph, controller.signal).then(rows => {
                store.data = rows;
                resolve(rows);
            }).catch(err => {
                if (err.message.startsWith(NoAccessErrMsg))
                    setNoAccess(true);
                else
                    handleError(err, setErr);
                reject();
            }).finally(() => store.loading = false);
        });

        return () => controller.abort();
    }, [store.dataCounter]);

    if (noAccess)
        return <NoAccessMsg />;
    if (err)
        return <ErrorMessage />;
    if (store.loading || store.mdLoading)
        return <div className="flex justify-center items-center" style={{minHeight: 'calc(100vh - 95px)'}}>
            <Loading large/>
        </div>;

    return props.children;
});

export default AGGridDataRenderer;