import {GraphVersions, JoinType, QuantumGraph, TestType, deserialize} from 'quantum-graph';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import {addTimeFilters, createTableNodes, getDefaultColumns} from '../../../pages/tools/affiliation-explorer/query';
import Variable, {loadDatasetMetadata} from '../../../lib/metadata';
import {DataDefRepo} from '../../../lib/user-data-defs';
import {QueryObj} from '../../../lib/data-types';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';

export async function createAffiliationQuery(dataset: string, entityId: number | number[], idVar: string, dataDefRepo: DataDefRepo = getDefaultDataDefRepo(), controller: AbortController): Promise<QuantumGraph> {
    const [fromDataset, toDataset] = dataset.split(':');
    const toVars: Array<Variable> = getDefaultColumns(await loadDatasetMetadata(toDataset, controller.signal));
    const fromVars: Array<Variable> = getDefaultColumns(await loadDatasetMetadata(fromDataset, controller.signal));
    const query: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: QueryType.Dim,
                tableNodes: createTableNodes(toDataset, '-to', toVars)
            },
            joins: [{
                joinType: JoinType.Inner,
                node: {
                    baseQuery: {
                        queryType: QueryType.Affiliation,
                        tableNodes: [
                            {
                                id: 'affiliation',
                                dataset: dataDefRepo.datasetByName[dataset].name,
                                columns: [
                                    'type',
                                    'effective_begin',
                                    'effective_end'
                                ]
                            },
                            ...createTableNodes(fromDataset, '-from', fromVars)
                        ]
                    }
                }
            }]
        },
        filters: [Array.isArray(entityId) ? {
            column: {
                nodeId: 'affiliation',
                column: idVar
            },
            test: TestType.In,
            value: entityId
        } : {
            column: {
                nodeId: 'affiliation',
                column: idVar
            },
            test: TestType.Eq,
            value: entityId
        }],
        sort: [{
            column: {
                nodeId: 'affiliation',
                column: 'type'
            },
            asc: true
        }]
    };

    addTimeFilters(query, [], toVars);

    return deserialize(query);
}

export function getFacilityOwnershipQuery(healthSystemId: number): QuantumGraph {
    return V2QuantumGraph.deserialize({
        'version': 2,
        'baseQueryGraph': {
            'baseQuery': {
                'queryType': 'dim',
                'tableNodes': [
                    {
                        'id': 'facility-full-ownership-lineage',
                        'dataset': 'facility-full-ownership-lineage',
                        'columns': [
                            'reference_torch_health_system_id',
                            'reference_torch_health_system_name',
                            'torch_facility_id',
                            'facility_name',
                            'category',
                            'category_subtype',
                            'affiliation_type_1',
                            'torch_health_system_id_1',
                            'health_system_name_1',
                            'affiliation_type_2',
                            'torch_health_system_id_2',
                            'health_system_name_2',
                            'affiliation_type_3',
                            'torch_health_system_id_3',
                            'health_system_name_3',
                            'affiliation_type_4',
                            'torch_health_system_id_4',
                            'health_system_name_4',
                            'affiliation_type_5',
                            'torch_health_system_id_5',
                            'health_system_name_5',
                            'affiliation_type_6',
                            'torch_health_system_id_6',
                            'health_system_name_6',
                            'ultimate_owner_torch_health_system_id',
                            'ultimate_owner_health_system_name'
                        ]
                    }
                ],
                'scaffoldType': ''
            },
            'joins': []
        },
        'filters': [
            {
                'type': 'basic',
                'column': {
                    'nodeId': 'facility-full-ownership-lineage',
                    'column': 'reference_torch_health_system_id'
                },
                'test': '=',
                'value': healthSystemId.toString()
            }
        ]
    });
}
