import React, {ReactElement, useState, useContext} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import checkAccess, {SiteFeature} from '../../lib/check-access';
import SideNavLayout, {NavItem} from '../../components/layout/side-nav-layout';
import Template from '../../components/template';
import UsersAdmin from './components/users';
import OrganizationAdmin from './components/organizations';
import AccessRolesAdmin from './components/access-roles';
import AuthWall from '../../components/auth-wall';
import UserDatasetsEdi from './components/user-datasets-edi';
import PermissionModulesAdmin from './components/permission-modules';
import {ffEnv} from '../../lib/api';
import Button from '../../components/button';
import DatasetModulesAdmin from './components/dataset-modules';
import CanvasModulesAdmin from './components/canvas-modules';
import ContentAccessAdmin from './components/content-access';
import CanvasAdmin from './components/canvas';
import {observer} from 'mobx-react';
import {HeaderContext} from '../../components/header/context';
import Toggle from '../../components/toggle';

const navItems: NavItem[] = [
    {path: 'users/*', text: <span><i className="icon-user" /> Users</span>, component: <UsersAdmin />},
    {path: 'organizations/*', text: <span><i className="icon-group" /> Organizations</span>, component: <OrganizationAdmin />},
    {path: 'access-roles/*', text: <span><i className="icon-key" /> Access Roles</span>, component: <AccessRolesAdmin />},
    {path: 'canvases/*', text: <span><i className="icon-paint" /> Canvases</span>, component: <CanvasAdmin />},
    {path: 'user-datasets/*', text: <span><i className="icon-data" /> User Datasets (EDI)</span>, component: <UserDatasetsEdi />},
    {path: 'permission-modules/*', text: <span><i className="icon-gear" /> Permission Modules</span>, component: <PermissionModulesAdmin />},
    {path: 'dataset-modules/*', text: <span><i className="icon-database" /> Dataset Modules</span>, component: <DatasetModulesAdmin />},
    {path: 'canvas-modules/*', text: <span><i className="icon-dashboard" /> Canvas Modules</span>, component: <CanvasModulesAdmin />},
    {path: 'content-access/*', text: <span><i className="icon-check-circle" /> Content Access</span>, component: <ContentAccessAdmin />},
    {path: `//${process.env.REACT_APP_TI_DOMAIN}/admin/analytics`, text: <span><i className="icon-chart-pie" /> Analytics</span>},
];
function SiteAdminPage(): ReactElement {
    const [useFFEnv, setUseFFEnv] = useState<boolean>(ffEnv.useFFEnv());

    const redesignStore = useContext(HeaderContext);

    if (!checkAccess(SiteFeature.Admin))
        return <Navigate replace to='/'/>;

    return <AuthWall>
        <Template>
            <SideNavLayout navItems={navItems}>
                <Routes>
                    {navItems.map(navItem => {
                        if (!navItem.path.startsWith('//')) {
                            return <Route key={navItem.path} path={navItem.path} element={navItem.component} />;
                        }
                        return null;
                    })}
                </Routes>
            </SideNavLayout>
            <div className='flex items-center px-4'>
                <span className='px-2'>Use Site Redesign</span>
                <Toggle checked={redesignStore.useNewDesign} onChange={() => redesignStore.useNewDesign = !redesignStore.useNewDesign } />
            </div>
            <div className="fixed bottom-0 right-0 m-5 w-24">
                <Button className="w-full" color={'blue'} onClick={() => setUseFFEnv(ffEnv.toggleEnv())}> {useFFEnv ? 'FF Env' : 'RDS Env'}</Button>
            </div>
        </Template>
    </AuthWall>;
}

export default observer(SiteAdminPage);
