import {QuantumGraph} from 'quantum-graph';
import auth from './auth';
import {generateHash} from './hash';

type QuantumAcceleratorCache = {
    quantumHash: string,
    userId: number,
    timestamp: number,
    response: any
}

class QuantumAccelerator {
    private request: IDBOpenDBRequest | undefined;
    private readonly CACHE_TTL_HOURS = 24;
    private readonly OBJECT_STORE_NAME = 'quantum_cache';
    private readonly META_KEY = '::metadata';

    constructor() {}

    private getStore(): Promise<IDBObjectStore> {
        return new Promise((resolve, reject) => {
            this.request = indexedDB.open(this.OBJECT_STORE_NAME, 3);
            this.request.onupgradeneeded = (e: any) => {
                let db: IDBDatabase = e.target.result;
                db.createObjectStore(this.OBJECT_STORE_NAME, {keyPath: 'quantumHash'});
            };
            this.request.onsuccess = (e: any) => {
                let db: IDBDatabase = e.target.result;
                let store = db.transaction(this.OBJECT_STORE_NAME, 'readwrite').objectStore(this.OBJECT_STORE_NAME);
                resolve(store);
            };
            this.request.onerror = () => {
                reject('IndexedDB request error');
            };
        });
    }

    private async generateHash(queryString: string) {
        return await generateHash(queryString);
    }

    private async getFromCache(queryHash: string): Promise<any> {
        return new Promise((resolve) => {
            this.getStore().then(store => {
                let cachedQueryRequest = store.get(queryHash);
                cachedQueryRequest.onsuccess = () => {
                    let cachedQuery: QuantumAcceleratorCache = cachedQueryRequest.result;
                    if (cachedQuery && cachedQuery.userId === auth.principal?.sub) {
                        let queryTimestamp = new Date(cachedQuery.timestamp).getTime();
                        let currentTTLCacheExpDate = Date.now() - (this.CACHE_TTL_HOURS * 1000 * 60 * 60);
                        if (currentTTLCacheExpDate < queryTimestamp)
                            resolve(cachedQuery.response);
                        else
                            resolve(null);
                    } else
                        resolve(null);
                };
            });
        });
    }

    private addToCache(queryHash: string, response: any, ttlTimestamp?: number) {
        return new Promise((resolve) => {
            this.getStore().then(store => {
                const cachedQuery: QuantumAcceleratorCache = {
                    quantumHash: queryHash,
                    userId: auth.principal!.sub,
                    timestamp: ttlTimestamp || new Date().getTime(),
                    response
                };
                store.put(cachedQuery).onsuccess = () => resolve(cachedQuery);
            });
        });
    }

    public async getQuery(query: QuantumGraph): Promise<any> {
        const queryHash = await this.generateHash(JSON.stringify(query.serialize()));
        return this.getFromCache(queryHash);
    }

    public async getMetadata(query: QuantumGraph): Promise<any> {
        const metaHash = await this.generateHash(JSON.stringify(query.serialize()) + this.META_KEY);
        return this.getFromCache(metaHash);
    }

    public async addQuery(query: QuantumGraph, response: any, ttlTimestamp?: number) {
        const queryHash = await this.generateHash(JSON.stringify(query.serialize()));
        return this.addToCache(queryHash, response, ttlTimestamp);
    }

    public async addMetadata(query: QuantumGraph, response: any, ttlTimestamp?: number) {
        const metaHash = await this.generateHash(JSON.stringify(query.serialize()) + this.META_KEY);
        return this.addToCache(metaHash, response, ttlTimestamp);
    }

    public clearObjectStore() {
        this.getStore().then(store => {
            store.clear();
        });
    }

    public isIndexedDBSupported() {
        if (!('indexedDB' in window))
            return false;

        return true;
    }
}

export default new QuantumAccelerator();