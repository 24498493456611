import React from 'react';
import 'quantum-graph';
import {Link} from 'react-router-dom';

const V2Schema = `{
    "version": 2,
    "baseQueryGraph": BaseQueryNode,
    "filters": [Filter...],        // optional
    "sort": [Sort...],             // optional
    "pagination": Pagination,      // optional
    "count": boolean,              // optional
    "distinct": boolean,           // optional
    "transforms": [Transform...],  // optional
}`;

const BaseQueryNodeSchema = `{
    "baseQuery": BaseQuery,
    "joins": [Join...], // optional
}`;

const BaseQuerySchema = `{
    "queryType": QueryType,
    "tableNodes": [TableNode...],
    "scaffoldField": string, // optional
}`;

const JoinSchema = `{
    "node": BaseQueryNode,
    "joinType": JoinType,
    "joinNodeId": string, // optional
}`;

const TableNodeSchema = `{
    "id": string,
    "dataset": string,
    "columns": [string...],
    "joinOn": { // optional
    "local": string,
    "target": string,
}`;

const ColumnSignatureSchema = `{
    "nodeId": string,
    "column": string,
}`;

const FilterSchema = `{
    "column": ColumnSignature,
    "test": Test,
    "value": FilterValue
}`;

const SortSchema = `{
    "column": ColumnSignature,
    "asc": boolean,
}`;

const PaginationSchema = `{
    "page": number,
    "pageSize": number,
}`;

const ScaffoldExample = `{
    "queryType": "dim",
    "tableNodes": [{
    "id": "facility",
    "dataset": "facility",
    "columns": ["facility_name"]
}, {
    "id": "hospital-cost-report-total-revenue",
    "dataset": "hospital-cost-report-total-revenue",
    "columns": ["net_patient_revenue"]
}, {
    "id": "hospital-cost-report-calculated-metrics",
    "dataset": "hospital-cost-report-calculated-metrics",
    "columns": ["average_length_of_stay"]
}],
    "scaffoldField": "year_key"
}`;

const ScaffoldFilter = `{
    "column": {
    "nodeId": "facility_scaffold",
    "column": "year_key"
},
    "test": "=",
    "value": 2021
}`;

const AggregateSchema = `{
    "type": "aggregate",
    "idColumns": [ColumnSignature...],
    "aggregations": [ColumnAggregation...]
}`;

const ColumnAggregationSchema = `[
    ColumnSignature,
    Aggregation
]`;

const PivotSchema = `{
    "type": "pivot",
    "idColumns": [ColumnSignature...],
    "pivotColumn": ColumnSignature,
    "aggregations": [ColumnAggregation...]
}`;

export default function APIDocV2Guide() {

    return <div>
        <h2>v2 Query Authoring Guide</h2>

        <p>
            This guide explains how to create data queries, and the different features of our query engine.
        </p>

        <ol className="list-inside list-decimal">
            <li><a href="#overview">Overview</a></li>
            <li><a href="#structure">Structure</a></li>
            <li><a href="#table-nodes">Table Nodes</a></li>
            <li><a href="#filters">Filters</a></li>
            <li><a href="#sorting-and-pagination">Sorting and Pagination</a></li>
            <li><a href="#counting-and-distinct">Counting and Distinct</a></li>
            <li><a href="#scaffolds">Scaffolds</a></li>
            <li><a href="#transform-streams">Transform Streams</a></li>
        </ol>

        <h3 id="overview">Overview</h3>
        <p>
            The version <code>2</code> query is composed of three different things:
        </p>
        <ol className="list-decimal list-inside">
            <li>Graph of data sources (the <code>baseQueryGraph</code>)</li>
            <li>Adjustments to that data (sorting, filtering, etc.)</li>
            <li>Transformations to that data (aggregating, pivoting)</li>
        </ol>

        <p>
            The graph of data sources is based on years of experience building analytics tools on a data warehouse. We
            have found that datasets tend to group together, usually all anchored to one dataset. These groups then can be
            connected together for more advanced queries.
        </p>
        <p>
            As described in the <Link to="/docs/api/data-warehouse">Data Warehouse Structure</Link> section, we use a
            reverse star schema. What that means is each group of datasets (called a base query) is centered on a single
            dimension, with multiple facts anchored to it. Base queries are described below.
        </p>
        <p>
            You can also reference our <Link to="/docs/api/data-warehouse">Examples</Link> page to see examples of
            different types of queries.
        </p>

        <h3 id="structure">Structure</h3>
        <p>A query structure looks like this:</p>
        <pre>{V2Schema}</pre>

        <h4>Base Queries</h4>
        <p>
            A base query is a logical group of datasets, such as a dimension and facts. These base queries can be joined together to create a more complicated query.
        </p>
        <p>
            A <code>BaseQueryNode</code> type looks like:
        </p>
        <pre>{BaseQueryNodeSchema}</pre>

        <p>
            A <code>BaseQuery</code> type looks like:
        </p>
        <pre>{BaseQuerySchema}</pre>

        <p>
            A <code>QueryType</code> type is a string that is one of the following values:
        </p>
        <ul className="list-disc list-inside">
            <li><code>dim</code> - A standard dimension-based query</li>
            <li><code>scaffold</code> - A query utilizing a data scaffold</li>
            <li><code>aff</code> - An affiliation-based query</li>
        </ul>

        <p>You only provide the <code>scaffoldField</code> when creating a scaffold. See the section below on scaffolds.</p>

        <h4>Joins</h4>
        <p>A <code>Join</code> type looks like:</p>
        <pre>{JoinSchema}</pre>

        <p>
            A <code>JoinType</code> type is a string that is one the following values: <code>left</code>, <code>right</code>, or <code>inner</code>.
        </p>
        <p>
            By default, joins will join to the first dataset of a base query. To join to a different dataset, you can specify the <code>joinNodeId</code>.
        </p>

        <h3 id="table-nodes">Table Nodes</h3>
        <p>A <code>TableNode</code> type looks like:</p>
        <pre>{TableNodeSchema}</pre>

        <p>
            The first <code>TableNode</code> should be the anchor dataset, which is usually the dimension. Subsequent <code>TableNode</code> will join automatically to the anchor node.
        </p>

        <p>
            Also, other parts of a query may reference a <code>ColumnSignature</code>. This refers to a field within a dataset. It need not be listed within the <code>columns</code> field of a <code>TableNode</code>, but it does reference the <code>id</code>. A <code>ColumnSignature</code> type looks like:
        </p>
        <pre>{ColumnSignatureSchema}</pre>

        <h3 id="filters">Filters</h3>
        <p>The <code>Filter</code> type looks like:</p>
        <pre>{FilterSchema}</pre>

        <p>
            A <code>Test</code> type is a string that is one of the following values: <code>&lt;</code>, <code>&lt;=</code>, <code>=</code>, <code>&gt;=</code>, <code>&gt;</code>, <code>&lt;&gt;</code>, <code>in</code>, <code>not in</code>, <code>like</code>, <code>is null</code>, <code>is not null</code>.
        </p>
        <p>
            A <code>Value</code> type is a <code>number</code>, a <code>string</code>, a <code>boolean</code>, or an array of one of those types.
        </p>

        <h3 id="sorting-and-pagination">Sorting and Pagination</h3>
        <p>The <code>Sort</code> type looks like:</p>
        <pre>{SortSchema}</pre>

        <p>You can define multiple sorts. The query will sort by the first field first, then apply the second, and so on.</p>

        <p>The <code>Pagination</code> type looks like:</p>
        <pre>{PaginationSchema}</pre>
        <p>If not provided, then all rows will be returned.</p>

        <h3 id="counting-and-distinct">Counting and Distinct</h3>
        <p>To count the number of records and return that count instead of the data, you can set:</p>
        <pre>count: true</pre>

        <p>To return only unique values, you can set:</p>
        <pre>distinct: true</pre>

        <h3 id="scaffolds">Scaffolds</h3>
        <p>When joining to multiple time-based facts, it is useful to use a scaffold to return the data without excluding any rows for missing years.</p>
        <p>To use a scaffold, you need to specify the <code>scaffoldField</code> within the <code>BaseQuery</code>. An example:</p>
        <pre>{ScaffoldExample}</pre>

        <p>This will automatically build the scaffold, and include the <code>year_key</code> column in the data and the variable in the metadata.</p>
        <p>You can then filter based on the year using a regular filter. For the node id you specify the id of the first table node followed by <code>_scaffold</code>:</p>
        <pre>{ScaffoldFilter}</pre>

        <h3 id="transform-streams">Transform Streams</h3>
        <p>
            You can create a pipeline of streams to apply to the data before it is returned. These transform stream are useful for performing aggregate operations. We support the following transformations:
        </p>

        <ul className="list-disc list-inside">
            <li>Aggregation</li>
            <li>Pivot</li>
        </ul>

        <h4>Aggregation Stream</h4>
        <p>The aggregate <code>Transform</code> looks like:</p>

        <pre>{AggregateSchema}</pre>
        <p>The <code>idColumns</code> field defines the columns used to group aggregations by. For accurate results, you <span className="font-bold text-orange-500">must also</span> sort the data by the same <code>idColumns</code> as well.</p>
        <p>The <code>ColumnAggregation</code> type looks like:</p>
        <pre>{ColumnAggregationSchema}</pre>

        <p>The <code>Aggregation</code> type is a string that has one of the following values: <code>min</code>, <code>max</code>, <code>sum</code>, <code>avg</code>, or <code>count</code>.</p>

        <h4>Pivot Stream</h4>
        <p>The pivot <code>Transform</code> looks like:</p>
        <pre>{PivotSchema}</pre>

        <p>Like the aggregate stream, the <code>idColumns</code> defines a unique identifier to partition by. The values of the <code>pivotColumn</code> will be turned into columns. The values within the <code>aggregations</code> will be aggregated as they are combined into the new pivoted columns.</p>
    </div>;
}