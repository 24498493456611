import {observer} from 'mobx-react';
import {useContext} from 'react';
import FilterStepsContext from '../../../../../components/filter-steps/libs/context';
import Checkbox from '../../../../../components/checkbox';
import FilterStepMultiDropdown from '../../../../../components/filter-steps/common-steps/filter-step-multi-dropdown';

const ServiceGrainFilterStep = observer(() => {
    const hcgFilterStore = useContext(FilterStepsContext);

    const serviceYearDefault = (values: Array<string>) => {
        const years: Array<number> = values.map(v => parseInt(v));
        if (years.length)
            hcgFilterStore.serviceYear = [Math.max(...years)];
    };

    const serviceSettingDefault = (values: Array<string>) => {
        hcgFilterStore.serviceSetting = values;
    };

    return <div className="flex flex-col space-y-5">
        <div className="flex">
            <FilterStepMultiDropdown label="Service Year" filterStoreVariable="serviceYear" variable="year_key" customDefault={serviceYearDefault} getDropdownQuery={(v) => hcgFilterStore.getDropdownQuery(v)} resyncVariables={['geographies']} />
        </div>
        <div className="flex justify-between items-center">
            <FilterStepMultiDropdown label="Service Setting" filterStoreVariable="serviceSetting" variable="setting" customDefault={serviceSettingDefault} getDropdownQuery={(v) => hcgFilterStore.getDropdownQuery(v)} resyncVariables={['geographies']} />
            <FilterStepMultiDropdown label="HCG Line" filterStoreVariable="hcgLine" variable="mr_line" getDropdownQuery={(v) => hcgFilterStore.getDropdownQuery(v)} resyncVariables={['geographies']} />
            <HCGLIneDetailCheckbox />
        </div>
        <div className="flex">
            <FilterStepMultiDropdown label="Primary Diagnosis Category" filterStoreVariable="primaryDiagnosisCategory" variable="ccs_diag" getDropdownQuery={(v) => hcgFilterStore.getDropdownQuery(v)} resyncVariables={['geographies']} />
        </div>
    </div>;
});

const HCGLIneDetailCheckbox = observer(() => {
    const hcgFilterStore = useContext(FilterStepsContext);

    return <div className="w-80 flex flex-col justify-center pt-5">
        <div className="flex space-x-3">
            <Checkbox color="dark" checked={hcgFilterStore.includeHCGLineDetail} onChange={() => hcgFilterStore.includeHCGLineDetail = !hcgFilterStore.includeHCGLineDetail} disabled={hcgFilterStore.hcgLine.length === 1 && hcgFilterStore.hcgLine[0] === 'Total'}/>
            <label>Include HCG Line Detail</label>
        </div>
        <small>To include HCG Line Detail, select at least one non-Total HCG Line</small>
    </div>;
});



export default ServiceGrainFilterStep;