import React, {useMemo} from 'react';
import AdminCrudTable from '../crud-table';
import {modules} from '../../../lib/api';

export default function CanvasModulesAdmin() {
    const fields = useMemo(() => [
        {name: 'id', label: 'Id', show: true, type: 'numeric'},
        {name: 'moduleId', label: 'Module', show: true, type: 'permissionModule'},
        {name: 'canvasId', label: 'Canvas', show: true, type: 'canvas'},
    ], []);

    return <div>
        <AdminCrudTable api={modules.canvas} title="Canvas Module" urlPath="/site-admin/canvas-modules" fields={fields} />
    </div>;
}