import TorchDataDefinitions, {DatasetDefinition, DatasetSchema, legacyDatasetRepo} from 'torch-data-defs';
import auth from './auth';
import Cache from './cache';
import {DataDefRepo} from './user-data-defs';

const protocol = String(process.env.REACT_APP_API_BASE).startsWith('local.torch') ? 'http://' : 'https://';
const apiBase = protocol + process.env.REACT_APP_API_BASE;
let torchDataDefs = new TorchDataDefinitions(apiBase, auth.token!);

let datasets: Array<DatasetDefinition> = [];
let schemas: Array<DatasetSchema> = [];

let datasetByName: {[dataset: string]: DatasetDefinition} = {};
function addDataset(name: string, dataset: DatasetDefinition) {
    if (datasetByName[name])
        throw new Error('Duplicate dataset names: ' + name);
    datasetByName[name] = dataset;
}

const datasetsBySchema: {[schema: string]: Array<DatasetDefinition>} = {};
function addSchema(name: string, datasets: Array<DatasetDefinition>) {
    if (datasetsBySchema[name])
        throw new Error('Duplicate schema: ' + name);
    datasetsBySchema[name] = datasets;
}

async function initTorchDataDefs(): Promise<void> {
    torchDataDefs = new TorchDataDefinitions(apiBase, auth.token!);
    await initDatasets();
    await initSchemas();
}

async function initDatasets(): Promise<void> {
    const datasetCacheKey = 'datasets-from-cms';
    try {
        datasets = Cache.get<Array<DatasetDefinition>>(datasetCacheKey);
    } catch (e) {
        if (e instanceof Cache.CacheMiss) {
            datasets = await torchDataDefs.getDatasetLibrary();
            Cache.set(datasetCacheKey, datasets);
        }
    }

    datasets.forEach(dataset => {
        addDataset(dataset.name, dataset);
        if (dataset.altName)
            addDataset(dataset.altName, dataset);
    });
}

async function initSchemas(): Promise<void> {
    const schemaCacheKey = 'dataset-schemas-from-cms';
    try {
        schemas = Cache.get(schemaCacheKey);
    } catch (e) {
        if (e instanceof Cache.CacheMiss) {
            schemas = await torchDataDefs.getSchemas();
            Cache.set(schemaCacheKey, schemas);
        }
    }

    schemas.forEach(schema => addSchema(schema.schema, schema.datasets));
}

function resolveField(field: string, datasetName: string, datasetByName: {[k: string]: DatasetDefinition}): [string, string, string?] {
    return TorchDataDefinitions.resolveField(field, datasetName, datasetByName);
}

if (process.env.NODE_ENV === 'test') {
    datasets = legacyDatasetRepo.datasets;
    datasetByName = legacyDatasetRepo.datasetByName;
}

function getDefaultDataDefRepo(): DataDefRepo {
    return {
        datasets,
        datasetByName
    };
}

export {
    torchDataDefs,
    datasets,
    datasetByName,
    datasetsBySchema,
    resolveField,
    initTorchDataDefs,
    getDefaultDataDefRepo
};

