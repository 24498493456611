import React from 'react';
import Badge from '../badge';
import InsightContainerTypeLabels from '../../insight-container/labels';

type Props = {
    onChange: (section: string) => void,
    sections: Array<string>,
    activeSection: string,
}

function getLabel(section: string) {
    if (section === 'saved-query')
        return 'Saved Queries';
    return InsightContainerTypeLabels[section];
}

export default function SavedDataNav(props: Props) {
    const {onChange, sections, activeSection} = props;
    if (sections.length <= 1)
        return null;

    return <div>
        {sections.map(section => <Badge color={activeSection === section ? 'orange' : 'dark'} key={section} outlined={activeSection !== section} onClick={() => onChange(section)}>
            {getLabel(section)}
        </Badge>)}
    </div>;
}