import {makeAutoObservable} from 'mobx';
import {PointLayerOptions} from './types';
import {QuantumGraph} from 'quantum-graph';
import {QuantumQuery} from '../../../lib/data-types';
import {FeatureCollection, Geometry, BBox} from 'geojson';
import Variable from '../../../lib/metadata';
import {Formatter, StringFormatter} from '../../../lib/formatter';

export default class MapOptions {
    mapQueries: QuantumGraph[] = [];
    mapShape: 'cbsa' | 'state_fips';
    choroplethColumn: string;
    choroplethColumnLabel = '';
    highColor: string;
    lowColor: string;
    choroHover: boolean;
    pointOptions: PointLayerOptions[];
    popupOptions = {
        longitude: 0,
        latitude: 0,
    };
    choroMinMax: number[] = [0,100];
    choroFeatureCollection: FeatureCollection = {type: 'FeatureCollection', features: []};
    choroplethFormatter:Formatter = new StringFormatter;


    constructor(
        mapShape: 'cbsa' | 'state_fips' = 'cbsa',
        choroplethColumn = '',
        highColor = '',
        lowColor = '',
        choroHover = false,
        pointOptions: PointLayerOptions[] = [],
    ) {
        this.mapShape = mapShape;
        this.choroplethColumn = choroplethColumn;
        this.highColor = highColor;
        this.lowColor = lowColor;
        this.choroHover = choroHover;
        this.pointOptions = pointOptions;

        makeAutoObservable(this);
    }

    addMapQuery(mapQuery: QuantumGraph) {
        this.mapQueries.push(mapQuery);

        if (this.mapQueries.length > 1) {
            this.pointOptions.push({
                color: '#000000',
                hover: false,
            });
        }
    }

    removeMapQuery(index: number) {
        if (index >= 0 && index < this.mapQueries.length) {
            this.mapQueries.splice(index, 1);
            this.pointOptions.splice(index - 1, 1);
        }
    }

    onHover = (features: any) => {
        if (features && features.length) {
            const sortedFeatures = features.sort((a:any, b:any) => {
                if (a.source === 'choropleth' && b.source !== 'choropleth') {
                    return 1;
                }
                if (a.source !== 'choropleth' && b.source === 'choropleth') {
                    return -1;
                }
                return 0;
            });
            const feature = sortedFeatures![0];
            const layer = feature.source;
            if (layer === 'choropleth' && this.choroHover) {
                const name = feature.properties![this.choroplethColumn];
                const newPopupOptions = {
                    latitude: feature.properties!.INTPTLAT,
                    longitude: feature.properties!.INTPTLON,
                    name: this.choroplethFormatter.format(name),
                    closeButton: false,
                    closeOnClick: false,
                };
                this.popupOptions = newPopupOptions;
            } else if (layer.split('-')[0] === 'points') {
                const pointIndex = Number(layer.split('-')[1]);
                const pointOption = this.pointOptions[pointIndex];

                if (pointOption && pointOption.column && feature.properties![pointOption.column]) {
                    const name = feature.properties![pointOption.column];
                    const newPopupOptions = {
                        latitude: Number(feature.properties!.latitude),
                        longitude: Number(feature.properties!.longitude),
                        name: name,
                        closeButton: false,
                        closeOnClick: false,
                    };
                    this.popupOptions = newPopupOptions;
                }
            }
        }
    };

    get legendOptions() {
        return {
            choropleth: {
                highColor: this.highColor,
                lowColor: this.lowColor,
                column: this.choroplethColumnLabel,
                minMax: this.choroMinMax
            },
            points: this.pointOptions,
        };
    }

    minMaxFromMap(map: Map<number, number>): { min: number; max: number } {
        let min = Infinity;
        let max = -Infinity;

        const values = Array.from(map.values());
        for (const value of values) {
            min = Math.min(min, value);
            max = Math.max(max, value);
        }

        if (min === Infinity || max === -Infinity) {
            throw new Error('The map is empty');
        }
        return {min, max};
    }

    applyColumnToShapes(query: QuantumQuery, shapes: FeatureCollection, column: string) {
        const shapeKey = this.mapShape === 'state_fips' ? 'STATEFP' : 'CBSAFP';

        const dataMap = new Map();

        query.data.forEach((d) => {
            dataMap.set(Number(d[this.mapShape]), Number(d[column]));
        });

        const features = shapes.features
            .map((f) => {
                if (dataMap.has(Number(f.properties![shapeKey]))) {
                    const properties = {
                        ...f.properties,
                        [column]: dataMap.get(Number(f.properties![shapeKey])),
                    };
                    return {...f, properties: properties};
                }
            })
            .filter(
                (
                    f,
                ): f is {
                    properties: { [x: string]: any }
                    type: 'Feature'
                    geometry: Geometry
                    id?: string | number | undefined
                    bbox?: BBox | undefined
                } => f !== undefined,
            );

        const {min, max} = this.minMaxFromMap(dataMap);
        
        this.choroFeatureCollection = {
            type: 'FeatureCollection',
            features: features,
        },
        this.choroMinMax = [min, max];
    }
    
    generateLabels(queries:QuantumQuery[]) {
        const [choroplethQuery, ...pointsQueries] = queries;
        const choroVariable = choroplethQuery.metadata.find((v)=>v.name === this.choroplethColumn);
        this.choroplethColumnLabel = choroVariable!.label;


        pointsQueries.forEach((q,index)=>{
            const pointVariable = q.metadata.find((v)=>v.name === this.pointOptions[index].column);
            this.pointOptions[index].columnLabel = pointVariable?.label;
        });
    }

    setFormatter(metadata:Variable[]) {
        const choroVariable = metadata.find((v)=>v.name === this.choroplethColumn);
        if(choroVariable?.formatter) {
            this.choroplethFormatter = choroVariable?.formatter;
        } 
    }

    formatQueryData(queries:QuantumQuery[]) {
        const [choroplethQuery] = queries;
        this.setFormatter(choroplethQuery.metadata);
        this.generateLabels(queries);
    }

}
