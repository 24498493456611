import GradientBar from '../../components/gradient-bar';
import MapOptions from '../../pages/canvas/lib/mapOptions';
import {useEffect, useState, useRef} from 'react';
import {observer} from 'mobx-react';

type Props = {
  mapOptions: MapOptions;
};

const Legend = observer((props: Props) =>{
    const {highColor, lowColor, column, minMax} = props.mapOptions.legendOptions.choropleth;
    const [low, high] = minMax;
    const {points} = props.mapOptions.legendOptions;
    const [numIntervals, setNumIntervals] = useState(4);

    const legendRef = useRef(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const width = entry.contentRect.width;
                setNumIntervals(Math.max(1, Math.floor(width / 100)));
            }
        });

        if (legendRef.current) {
            resizeObserver.observe(legendRef.current);
        }

        return () => {
            if (legendRef.current) {
                resizeObserver.unobserve(legendRef.current);
            }
        };
    }, []);

    const intervals = Array.from(
        {length: numIntervals},
        (_, i) => (low + ((high - low) / numIntervals) * i).toFixed(0)
    );

    return (
        <div className="flex flex-col space-y-4 p-4 border-1 border-gray-300 rounded-md h-full" ref={legendRef}>
            <h1 className="text-lg font-bold">Legend</h1>
            <div>
                <h4 className="mb-2 text-sm">{column}</h4>
                <GradientBar startColor={lowColor} endColor={highColor} numIntervals={numIntervals} />
                <div className="flex justify-between mt-1 text-sm">
                    {intervals.map((val, index) => (
                        <div key={index}>{props.mapOptions.choroplethFormatter.format(val)}</div>
                    ))}
                    <div>{props.mapOptions.choroplethFormatter.format(high.toFixed(0))}</div>
                </div>
            </div>
            {points.length &&
        points.map((p, index) => (
            <div key={index} className="flex items-center space-x-2">
                <svg
                    fill="none"
                    width="25"
                    height="25"
                    viewBox="0 0 15 15"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-gray-500"
                >
                    <path
                        fill={String(p.color)}
                        d="M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703 C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z"
                    />
                </svg>
                <span className="text-sm">{p.columnLabel}</span>
            </div>
        ))}
        </div>
    );
});

export default Legend;