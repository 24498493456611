import PayerFilterStep from '../steps/payer-filter-step';
import DataSelectionFilterStep from './steps/data-selection-filter-step';
import GeographyFilterStep from '../../../../../components/filter-steps/common-steps/geography-filter-step';
import {FilterStep} from '../../../../../components/filter-steps/libs/types';
import ProviderGroupFilterStep from './steps/provider-group-filter-step';


const HPTProfessionalFilterStepsConfig: Array<FilterStep> = [
    {
        name: 'Data Selection',
        subText: 'Required: you must choose one for each group',
        required: true,
        step: DataSelectionFilterStep
    },
    {
        name: 'Region Information',
        subText: 'You can also select regions by clicking  on the map.',
        required: false,
        step: () => <GeographyFilterStep geographies={['CBSA', 'State']} subtext="Click regions on the map to include all hospitals within the region." yearKey="2021"/>
    },
    {
        name: 'Provider Group Information',
        subText: '',
        required: false,
        step: ProviderGroupFilterStep
    },
    {
        name: 'Payer Information',
        subText: '',
        required: false,
        step: PayerFilterStep
    }
];

export default HPTProfessionalFilterStepsConfig;