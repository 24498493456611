import Popup from '../popup';
import React, {ChangeEvent, useCallback, useContext, useEffect, useRef, useState} from 'react';
import handleError from '../../lib/error';
import classNames from '../../lib/class-names';
import {observer} from 'mobx-react';
import Spinner from '../spinner';
import Badge from '../badge';
import Checkbox from '../checkbox';
import {MenuContext} from '../popup/context';
import Fuse from 'fuse.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import './multi-select-autocomplete.scss';
import {FixedSizeList, FixedSizeList as List} from 'react-window';

const KEY_DELAY_TIMEOUT = process.env.REACT_APP_API_BASE === 'test-api' ? 1 : 550;
const MIN_SEARCH_LENGTH = 3;

type Props = {
    onChange: (value: Array<string>) => void,
    values: Array<string>
    getOptions: (value: string) => Promise<Array<string>>,
    placeholder?: string,
    disabled?: boolean,
    width?: number,
    popupWidth?: number,
    onSelectAll?: (value: Array<string>, selected: boolean) => void,
    customDefault?: (values: Array<string>) => void
};


const MultiSelectAutocomplete = observer((props: Props) => {
    const {onChange, getOptions, placeholder, disabled, width, popupWidth, values, onSelectAll, customDefault} = props;
    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState<Array<string>>([]);
    const [displayOptions, setDisplayOptions] = useState<Array<string>>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState('');
    const [changeTimeout, setChangeTimeout] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [lastScrollPosition, setLastScrollPosition] = useState(0);
    const menuStore = useContext(MenuContext);

    useEffect(() => {
        if (values) {
            if (values.length === 0) {
                setSearchValue('');
                setSelectAll(false);
                setDisplayOptions(options);
            } else if (values.length === 1) {
                setSearchValue(values[0]);
                setDisplayOptions(options);
            } else
                setSearchValue('');
        }
    }, [values, options]);

    const fetchOptions = useCallback((value: string) => {
        setError('');
        getOptions(value)
            .then(opts => {setOptions(opts); customDefault && customDefault(opts);})
            .then(() => setLoading(false))
            .catch(err => handleError(err, setError));
    }, [getOptions, customDefault]);

    useEffect(() => {
        if (!disabled) {
            setLoading(true);
            setOptions([]);
            fetchOptions('');
        }
    }, [disabled, fetchOptions]);

    useEffect(() => {
        if ((!searchValue && !menuStore.visible) || (!searchValue && displayOptions.length === 0))
            setDisplayOptions(options);
    }, [options, searchValue, menuStore.visible, displayOptions.length]);

    useEffect(() => {
        if (searchValue)
            setSelectAll(false);
    }, [searchValue]);

    function updateValue(e: ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.value;
        setSearchValue(newValue);

        if (changeTimeout)
            clearTimeout(changeTimeout);
        setChangeTimeout(setTimeout(() => searchEntities(newValue), KEY_DELAY_TIMEOUT));
    }

    function searchEntities(value: string) {
        if (value.length >= MIN_SEARCH_LENGTH) {
            let tempOptions = [...options];
            const fuse = new Fuse(tempOptions, {
                threshold: .5
            });
            tempOptions = fuse.search(value).map(entry => entry.item);
            setDisplayOptions(tempOptions);
        } else {
            setDisplayOptions(options);
        }
    }

    function handleSelectAll() {
        if (onSelectAll) {
            onSelectAll(displayOptions, !selectAll);
            setSelectAll(!selectAll);
        }
    }

    function handlePseudoElementClick() {
        if (inputRef.current && !menuStore.visible) {
            inputRef.current.focus();
        }
    }

    function handleOnChange(items: Array<string>, index: number) {
        if (items.length === 1)
            setSearchValue(items[0]);
        onChange(items);
        setLastScrollPosition(index);
    }

    return <div className="inline-block relative">
        <div className="relative hover:cursor-pointer multi-select-search-bar" style={{width: `${width}px`}}>
            {values.length > 0 && <div className="absolute top-2 left-0">
                <Badge color="dark" onClose={() => {onChange([]); setSelectAll(false); setSearchValue('');}} pill>{values.length}</Badge>
            </div>}
            <div className={`search-field ${menuStore.visible && 'open'}`} onClick={handlePseudoElementClick}>
                <input className={`${values.length > 0 ? 'pl-28' : ''} pr-10 w-full`} type="text" value={searchValue} onChange={updateValue}
                    onClick={e => e.stopPropagation()} placeholder={placeholder} disabled={disabled || loading} ref={inputRef} autoComplete="new-password"/>
            </div>
            {loading && <div className="absolute top-0 right-5 h-full pr-3 flex items-center">
                <Spinner />
            </div>}
        </div>
        {error && <p className="text-sm text-red-500">{error}</p>}
        <Popup targetRef={inputRef} showEvent="focus" customWidth={popupWidth} matchWidth={!popupWidth} noHideOnClick>
            <div>
                {onSelectAll && displayOptions.length > 1 && <div className={classNames('py-1', 'hover:cursor-pointer', 'pl-2')} onClick={handleSelectAll}>
                    <div className="relative flex items-center">
                        <Checkbox color="dark" checked={selectAll} onChange={() => true} />
                        <label className="ml-8">Select All</label>
                    </div>
                </div>}
                {displayOptions.length > 0 && <MenuItems 
                    lastScrollPosition={lastScrollPosition} 
                    displayOptions={displayOptions} 
                    onChange={handleOnChange} 
                    values={values} />}
                {displayOptions.length === 0 && <div className="py1 ml-8">
                    No results found
                </div>}
            </div>
        </Popup>
    </div>;
});

type MenuProps = {
    displayOptions: Array<string>,
    values: Array<string>,
    onChange: (values: Array<string>, index: number) => void,
    lastScrollPosition: number
}

const LIST_ITEM_HEIGHT = 32;

function MenuItems(props: MenuProps) {
    const {displayOptions, values, onChange, lastScrollPosition} = props;
    const listRef = React.createRef<FixedSizeList>();

    function toggleMultiSelect(value: string, i: number) {
        const tempList = [...values];
        const index = tempList.findIndex(entry => entry === value);
        if (index >= 0) {
            tempList.splice(index, 1);
        } else {
            tempList.push(value);
        }

        onChange(tempList, i);
    }

    const listHeight = Math.min(displayOptions.length * LIST_ITEM_HEIGHT, 240);

    const lastItemOffset = Math.max(0, displayOptions.length * LIST_ITEM_HEIGHT - listHeight);
    const maxOffset = Math.min(lastItemOffset, lastScrollPosition * LIST_ITEM_HEIGHT);
    const minOffset = Math.max(0, lastScrollPosition * LIST_ITEM_HEIGHT - listHeight + LIST_ITEM_HEIGHT);

    const middleOffset = Math.round(minOffset + (maxOffset - minOffset) / 2);
    let scrollOffset = 0;
    if (middleOffset < Math.ceil(listHeight / 2)) {
        scrollOffset = 0; // near the beginning
    } else if (middleOffset > lastItemOffset + Math.floor(listHeight / 2)) {
        scrollOffset = lastItemOffset; // near the end
    } else {
        scrollOffset = middleOffset;
    }

    return <AutoSizer disableHeight>
        {({width}: any) => (<List ref={listRef}
            initialScrollOffset={scrollOffset}
            itemData={displayOptions}
            innerElementType="ul"
            itemCount={displayOptions.length}
            height={listHeight}
            itemSize={LIST_ITEM_HEIGHT}
            width={width!}>
            {({data, index, style}) => {
                const value = data[index];
                return <li style={style} className="py-1" onClick={() => toggleMultiSelect(value, index)}>
                    <div className="relative flex items-center pl-2 hover:bg-gray-50 hover:cursor-pointer">
                        <Checkbox color="dark" checked={values.some(entry => entry === value)} onChange={() => true} />
                        <label className="ml-8">{value}</label>
                    </div>
                </li>;
            }}
        </List>)}
    </AutoSizer>;
}

export default MultiSelectAutocomplete;