import {DatasetDefinition} from 'torch-data-defs';
import {resolveField} from '../data-defs';
import {DataDefRepo} from '../user-data-defs';


function subset<T>(a1: Array<T>, a2: Array<T>): Array<T> {
    const result: Array<T> = [];
    for (const item of a1) {
        if (a2.includes(item))
            result.push(item);
    }
    return result;
}

type ForeignKeyData = {
    dataset: string,
    fromCol: string,
    toCol: string,
}

export function parseForeignKey(fk: string, dataDefRepo: DataDefRepo): ForeignKeyData {
    // Use case 1: dataset-name::key
    let match = /^([a-z0-9-]+)::(\w+)$/.exec(fk);
    if (match) {
        const dataset = match[1];
        const field = resolveField(fk, dataset, dataDefRepo.datasetByName)[1];
        return {dataset, fromCol: field, toCol: field};
    }

    // Use case 2: custom_id_field:dataset-name::id
    match = /^(\w+):([a-z0-9-]+)::(\w+)$/.exec(fk);
    if (match) {
        const dataset = match[2];
        const field = resolveField(dataset + '::' + match[3], dataset, dataDefRepo.datasetByName)[1];
        return {dataset, fromCol: match[1], toCol: field};
    }

    // Use case 3: custom_field:dataset-name:custom_other_field
    match = /^(\w+):([a-z0-9-]+):(\w+)$/.exec(fk);
    if (match) {
        return {dataset: match[2], fromCol: match[1], toCol: match[3]};
    }

    // Use case 4: dataset-name:custom_field
    match = /^([a-z0-9-]+):(\w+)$/.exec(fk);
    if (match) {
        return {dataset: match[1], fromCol: match[2], toCol: match[2]};
    }

    throw new Error('Invalid foreign key: ' + fk);
}

export function canJoinFact(dimDef: DatasetDefinition, factDef: DatasetDefinition, dataDefRepo: DataDefRepo): boolean {
    if (factDef.tableType !== 'fact')
        return false;
    if (dimDef.tableType === 'dim' || dimDef.tableType === 'composite') {
        const dimGrain = dimDef.tableGrain.filter(g => g[0] === '!');
        const factGrain = factDef.tableGrain.filter(g => g[0] === '!');
        const grainSubset = subset(dimGrain, factGrain);
        if (grainSubset.length)
            return true;

        // Otherwise, check the foreign keys
        let dimFks = dimDef.foreignKeys.map(fk => parseForeignKey(fk, dataDefRepo).dataset);
        let factFks = factDef.foreignKeys.map(fk => parseForeignKey(fk, dataDefRepo).dataset);
        // Don't allow connecting through zip geo
        dimFks = dimFks.filter(fk => fk !== 'zip-geo' && fk !== 'zip-geo-monthly');
        factFks = factFks.filter(fk => fk !== 'zip-geo' && fk !== 'zip-geo-monthly');
        const fkSubset = subset(dimFks, factFks);
        return !!fkSubset.length;
    }

    return false;
}

export function isUUID(dataset: string) {
    return dataset.match(/^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/);
}

export function findFacts(dataDef: DatasetDefinition, dataDefRepo: DataDefRepo): Array<string> {
    let allFacts: Array<string> = [];
    if (dataDef.connectingFacts) {
        for (const connectingFact of dataDef.connectingFacts) {
            let fact = connectingFact.name;
            if (canJoinFact(dataDef, connectingFact, dataDefRepo)) {
                allFacts.push(fact);
            }
        }

        // Discover EDI facts that connect
        const ediFacts = dataDefRepo.datasets
            .filter(factDef => factDef.tableType === 'fact' && isUUID(factDef.name) && canJoinFact(dataDef, factDef, dataDefRepo))
            .map(ds => ds.name);
        allFacts = allFacts.concat(ediFacts);
    } else {

        // Discovery
        allFacts = dataDefRepo.datasets
            .filter(factDef => factDef.tableType === 'fact' && canJoinFact(dataDef, factDef, dataDefRepo))
            .map(ds => ds.name);
    }

    return allFacts;
}