import {observer} from 'mobx-react';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {CanvasContext} from '../lib/context';
import Autocomplete from '../../../components/autocomplete';
import fetchData from '../../../lib/data';
import {deserialize, TestType} from 'quantum-graph';
import {dataRow, dataRows} from '../../../lib/data-types';
import createGraphHandler from '../../../lib/graph-tools/graph-handler/create';
import Fuse from 'fuse.js';
import {useNavigate, useLocation} from 'react-router-dom';
import Variable from '../../../lib/metadata';
import {StringFormatter} from '../../../lib/formatter';
import Select from '../../../components/select';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';
import {useDeepCompareEffect} from 'use-deep-compare';
import activityTracker from '../../../lib/activity-tracker';

const MIN_INPUT_LENGTH = 4;

function formatOptionLabel(pattern: string, row: dataRow) {
    return pattern.replace(/\{(\w+)}/g, (_match, p1) => row[p1] as string);
}

const CvParameterInput = observer(() => {
    const store = useContext(CanvasContext);
    const [parameterData, setParameterData] = useState<dataRows>([]);
    const [fullParameterData, setFullParameterData] = useState<dataRows>([]);
    const [parameterSearchValue, setParameterSearchValue] = useState<string>('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (store.parameterValue) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('id', store.parameterValue.toString());
            store.loading = true;
            store.loadPromises = [];
            navigate({search: searchParams.toString()});
        }
    }, [store.parameterValue]);

    useDeepCompareEffect(() => {
        if (store.parameter && Object.keys(store.parameterRow).length) {
            const value = store.parameter.displayPattern ? formatOptionLabel(store.parameter.displayPattern, store.parameterRow) : store.parameterRow[store.parameter.searchField] as string;
            setParameterSearchValue(value);
            activityTracker.setRecentActivity('Canvas', value, undefined, location.pathname + '?id=' + store.parameterValue.toString());
        }
    }, [store.parameterRow, store.parameter, store.parameter?.searchField, store.parameter?.displayPattern]);


    useEffect(() => {
        if (store.parameter && store.parameter.useFuzzySearch) {
            let gh = createGraphHandler(deserialize(store.parameter.query), getDefaultDataDefRepo());
            gh.removePagination();
            fetchData(gh.build(), new AbortController().signal).then((data) => {
                setFullParameterData(data);
            });
        }
    }, [store.parameter]);

    const getOptions = useCallback(async (input: string): Promise<Array<string>> => {
        if (!store.parameter || input.length < (store.parameter.minSearchChars || MIN_INPUT_LENGTH))
            return [];

        let data = [];

        if (store.parameter.useFuzzySearch) {
            const fuse = new Fuse(fullParameterData, {threshold: .3, keys: [store.parameter.searchField]});
            data = fuse.search(input).map(e => e.item);
        } else {
            let gh = createGraphHandler(deserialize(store.parameter.query), getDefaultDataDefRepo());
            gh.removePagination();
            gh.applyFilters([{
                variable: new Variable(store.parameter.searchField, '', '', '', {}, new StringFormatter(), gh.getDatasetFromVariable(store.parameter.searchField)),
                test: TestType.Like,
                value: `%${input}%`
            }]);
            data = await fetchData(gh.build(), new AbortController().signal);
        }

        setParameterData(data);
        return data.map(row => store.parameter?.displayPattern ? formatOptionLabel(store.parameter.displayPattern, row) : row[store.parameter!.searchField] as string);
    }, [parameterData, fullParameterData, store.parameter]);

    const onChange = (_name: string, index: number) => {
        let row = parameterData[index];
        if (row) {
            store.parameterValue = row[store.parameter!.idField] as string;
            store.parameterRow = row;
        }
    };

    return <div className="flex items-center space-x-3">
        {store.parameter && store.parameter.searchFields && store.parameter.searchFields.length > 1 && <Select
            data={store.parameter.searchFields}
            value={store.parameter.searchField}
            onChange={v => store.parameter!.searchField = v}
        >
            {_ => _.name}
            {_ => _.label}
        </Select>}
        
        <Autocomplete value={parameterSearchValue} onChange={onChange} getOptions={getOptions} icon={parameterSearchValue ? 'icon-down' : 'icon-search'} customKeyDelay={store.parameter?.customKeyDelay} placeholder="Select value"/>
    </div>;
});
export default CvParameterInput;