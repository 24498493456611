import React, {useEffect, useMemo, useState} from 'react';
import Template from '../../components/template';
import Breadcrumb from '../../components/breadcrumb';
import {ChevronDown, ChevronUp, Search} from '@carbon/icons-react';
import CityStateSelect from './city-state-select';
import ErrorMessage from '../../components/error-message';
import Pagination from '../../components/pagination';
import Loading from '../../components/loading';
import './search.scss';
import {SiteSearchAllResult} from './search-types';
import {canvas} from '../../lib/api';
import handleError from '../../lib/error';
import {CanvasSectionType} from '../canvas/lib/types';
import {DEFAULT_FILTERS, performSiteSearch, SearchFilter, SearchResultEntry} from './site-search-result-card';

const PAGE_SIZE = 10;
let queryTimeout: any;
function debounce(func: any, time: number) {
    clearTimeout(queryTimeout);
    queryTimeout = setTimeout(func, time);
}
export default function SiteSearchView() {
    const queryFromParam = useMemo(() => {
        return new URLSearchParams(window.location.search).get('q') || '';
    }, []);

    const [searchQuery, setSearchQuery] = useState<string>(queryFromParam);
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [searchFilters, setSearchFilters] = useState<Array<SearchFilter>>(DEFAULT_FILTERS);
    const [selectedFilter, setSelectedFilter] = useState<SearchFilter>(searchFilters[0]);
    const [searchResults, setSearchResults] = useState<Array<SiteSearchAllResult>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showAdvance, setShowAdvance] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        const controller = new AbortController();
        canvas.listSections(controller.signal).then(sections => {
            const canvases: Array<SearchFilter> = [...DEFAULT_FILTERS];
            sections.forEach((section: CanvasSectionType) => {
                section.links.forEach(c => canvases.push({title: c.title, url: c.url, endpoint: section.title}));
            });
            setSearchFilters(canvases);
        }).catch(err => handleError(err, setErrorMessage))
            .finally(() => setLoading(false));

        return () => controller.abort();
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        debounce(() => {
            if (searchQuery) {
                setErrorMessage('');
                setLoading(true);
                performSiteSearch(searchQuery, selectedFilter, controller)
                    .then(results => setSearchResults(results))
                    .catch(err => handleError(err, setErrorMessage))
                    .finally(() => setLoading(false));
            } else {
                setSearchResults([]);
            }
        }, 550);

        return () => controller.abort();
    }, [searchQuery, selectedFilter, city, state]);

    function setCityState(city: string, state: string) {
        setCity(city);
        setState(state);
    }

    function setSearchFilterFromTitle(title: string) {
        const filter = searchFilters.find(sf => sf.title === title);
        setSelectedFilter(filter!);
    }


    return <Template showFooter>
        <div className="bg-white search-page pb-16" style={{minHeight: 'calc(100vh - 49px - 3rem)'}}>
            <div className="w-full flex flex-col items-center justify-center pt-5 mb-6">
                <div className='self-start ml-12 mb-12' >
                    <Breadcrumb crumbs={[{name: 'Home', url: '/'}, {name: 'Search Results', url: '/search'}]} />
                </div>
                <div className="w-full p-2 sm:p-0 sm:w-2/3 relative flex">
                    <div className='flex-initial'>
                        <div className='text-gray-800 pr-2 bg-gray-100 border-gray-400 border-b border-r h-12 flex items-center'>
                            <select className="search-type text-l bg-gray-100 border-0 shadow-none rounded-none pl-4 focus:ring-0 hover:cursor-pointer" value={selectedFilter.title} onChange={e => setSearchFilterFromTitle(e.target.value)} aria-controls='popup-menu'>
                                {searchFilters.map((f, i) => <option key={i} value={f.title}>{f.title}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="flex-grow relative text-gray-800">
                        <div className="absolute left-0 top-0 p-4 text-gray-400">
                            <Search size={16} />
                        </div>
                        <input className="w-full h-12 text-l pl-10 bg-gray-100 shadow-none border-0 border-b border-gray-400 rounded-none focus:ring-0" type="search" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
                    </div>
                </div>
                <div className="w-full p-2 sm:p-0 sm:w-2/3 relative flex flex-col bg-gray-100 border-b border-gray-300">
                    <div className="ml-4 cursor-pointer flex flex-col sm:flex-row items-center mt-2 mb-2" onClick={() => setShowAdvance(!showAdvance)}>
                        <span className=" text-gray-800 flex items-center text-xs">{showAdvance ? 'Hide' : 'Show'} Advanced Filters {showAdvance ? <ChevronUp /> : <ChevronDown />} </span>
                    </div>
                    <div className='relative p-0 h-0 overflow-visible'>
                        <div className={`flex flex-col w-full lg:flex-row items-center space-y-3 lg:space-y-0 lg:space-x-8 ${!showAdvance ? 'opacity-0 h-0 p-0' : 'p-3' } overflow-hidden transition-all ease-out duration-100 absolute bg-gray-100 border-b border-gray-300`}>
                            <div className="flex flex-col grow">
                                <small className="ml-1 mb-1">City or State</small>
                                <CityStateSelect onChange={setCityState} className='shadow-none border-0 border-b border-gray-300 rounded-none' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {errorMessage && <ErrorMessage error={errorMessage} />}
            {!loading && <div className={showAdvance ? 'mt-32' : ''}>
                <div className="w-full mt-12 flex justify-center">
                    <div className="w-2/3 justify-start text-sm leading-tight">Showing <span className='font-bold'>{(page * PAGE_SIZE) + 1}-{Math.min(((page + 1) * PAGE_SIZE), searchResults.length)}</span> of <span className='font-bold'>{searchResults.length}</span> results for <span className='font-bold'>{searchQuery}</span></div>
                </div>
                <div className="w-full mt-4 flex flex-col items-center">
                    <ul className='w-2/3 divide-y gap-4 flex flex-col'>
                        {searchResults.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((result, index) => <li key={index} className="flex border-gray-200">
                            <SearchResultEntry searchResult={result} searchQuery={searchQuery} />
                        </li>)}
                        {searchResults.length === 0 && <li className="p-5 flex flex-col space-y-3 border bg-white">
                            <i className="icon-warning text-2xl" /> <div className="text-lg">Sorry, no results found for "{searchQuery}". Try refining your search by clicking Advanced.</div>
                        </li>}
                    </ul>
                    <div className='w-2/3'>
                        <Pagination className='mt-8 mb-8' page={page} setPage={setPage} pageSize={PAGE_SIZE} count={searchResults.length} />
                    </div>
                </div>
            </div>}
            {loading && searchQuery !== '' && <div className="w-full flex items-center justify-center mt-24">
                <Loading large />
            </div>}
        </div>
    </Template>;
}
