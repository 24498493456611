import Button from '../button';
import Select, {BasicSelectType} from '../select';

export type Props = {
    selectOptions: Array<BasicSelectType>,
    values: Record<string, string>,
    onChange: (entryValues: Record<string, string>) => void
}
export default function MultiEntryValue(props: Props) {
    const {selectOptions, values, onChange} = props;

    function handleSelectChange(oldValue: string, newValue: string) {
        if (oldValue === newValue) return;
        if (Object.keys(values).includes(newValue)) return;
        const newValues: Record<string, string> = {...values};
        delete newValues[oldValue];
        newValues[newValue] = selectOptions.find((opt) => opt.value === newValue)!.label;
        onChange(newValues);
    }

    function handleInputChange(key: string, value: string) {
        const newValues: Record<string, string> = {...values};
        newValues[key] = value;
        onChange(newValues);
    }

    function addEntry() {
        const nextIndex = selectOptions.findIndex((opt) => !Object.keys(values).includes(opt.value));
        if (nextIndex === -1) return;
        onChange({...values, [selectOptions[nextIndex].value]: selectOptions[nextIndex].label});
    }

    function removeEntry(key: string) {
        const newValues = {...values};
        delete newValues[key];
        onChange(newValues);
    }

    return <div className="w-full flex flex-col space-y-3">
        <ul className="flex flex-col space-y-2">
            {Object.keys(values).map((key, i) => <li key={i} className="flex space-x-3 items-center">
                <div className="flex space-x-1">
                    <Select<BasicSelectType> data={selectOptions} value={key} onChange={(opt) => handleSelectChange(key, opt)}>
                        {(t) => t.value}
                        {(t) => t.label}
                    </Select>
                    <input className="display-block" type="text" value={values[key]} onChange={(e) => handleInputChange(key, e.target.value)}/>
                </div>
                <Button color="red" onClick={() => removeEntry(key)}><i className="icon-trash" /></Button>
            </li>)}
        </ul>
        <div>
            <Button color="blue" onClick={addEntry}><i className="icon-plus" /> Add</Button>
        </div>
    </div>;
}