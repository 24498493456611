//import {useContext, useRef} from 'react';
import {Link} from 'react-router-dom';
import './header.scss';
import {Help, Notebook, UserAvatar} from '@carbon/icons-react';
import auth from '../../../../lib/auth';
import classNames from '../../../../lib/class-names';
import HeaderButton from './header-button';
import {NetworkContext} from '../../lib/context';
import {useContext, useEffect} from 'react';
import {observer} from 'mobx-react';

export type NavbarProps = {
    className?: string
}

const Header = observer((props: NavbarProps) => {
    const classes = classNames('header', props.className);
    const networkOptimizationStore = useContext(NetworkContext);

    useEffect(() => {
        if (networkOptimizationStore.navItems.length === 0) {
            networkOptimizationStore.loadHeaders();
        }
    }, []);
    
    return (
        <nav className={classes}>
            <div className='pl-4 h-12 w-full'>
                <div className='flex items-center h-12 w-full'>
                    <div className='flex-1 flex items-center'>
                        <div className='flex-shrink-0 flex items-center gap-4'>
                            <Link to='/network-optimization/my-files' className='hover:no-underline text-gray-300'>
                                <span className='hover:no-underline'>
                                    Milliman <span className='font-bold text-gray-100'>Network Adequacy</span>
                                </span>
                            </Link>
                            <div className='w-px bg-gray-300 h-6'></div>
                            <div className='text-gray-300'>{auth.principal?.orgs[0].name}</div>
                            <div className='w-px bg-gray-300 h-6'></div>
                            <nav className="header-nav flex gap-4">
                                {networkOptimizationStore.navItems.map((item, index) => (
                                    <span key={index}>
                                        <Link className='text-gray-300 text-sm hover:font-semibold' to={item.path}>
                                            {item.label}
                                        </Link>
                                    </span>
                                ))}
                            </nav>
                        </div>
                        <div className='block flex-grow' />
                    </div>
                    {auth.principal && (
                        <div className='absolute right-0 flex items-center h-full'>
                            <div className='h-full relative flex'>
                                <HeaderButton id="my-content" icon={<Notebook />} onClick={() => { alert('This is still being built'); }} tooltipName="My Content" />
                                <HeaderButton id="help-section" icon={<Help />} onClick={() => { alert('This is still being built'); }} tooltipName="Resources" />
                                <HeaderButton id="user-menu" icon={<UserAvatar />} onClick={() => { alert('This is still being built'); }} tooltipName="My Account" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
});

export default Header;