import React from 'react';
import './progressBar.scss';
import classNames from '../../lib/class-names';

type Props = {
    progress: number,
    color: string,
    border?: boolean,
    showPercentage?: boolean
}

export default function ProgressBar(props: Props) {
    const {progress, color, border, showPercentage} = props;

    return <div className={`${classNames('progress-bar', {[`border-${color}-600`]: border})} relative`}>
        <div className={`progress ${color}`} style={{width: `${progress}%`}}></div>
        {showPercentage && <div className={`absolute top-0 left-1/2 translate-x-1/2 ${progress > 52 ? 'text-white' : 'text-black'}`} style={{marginLeft: '-30px'}}>{Math.floor(progress)}%</div>}
    </div>;
}