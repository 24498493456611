import StandardDataRenderer, {StandardRenderChildProps} from '../renderers/standard-data-renderer';
import {DataConfig} from '../types';
import dot from 'dot';
import {useMemo, useState} from 'react';
import ErrorMessage from '../../components/error-message';

export function CustomText(props:StandardRenderChildProps) {
    let {data, dataConfig, metadata} = props;
    let [err, setErr] = useState<string>('');
    let compiledTemplate = dot.template(dataConfig.options.template || '', {...dot.templateSettings, varname: 'd, f'});

    let formattedData = useMemo(() => 
        data.map(row => metadata.reduce((prev, currMeta) => ({...prev, [currMeta.name]: currMeta.formatSimple(row)}), {}))
    , [data, metadata]);

    let text = useMemo(() => {
        try {
            return compiledTemplate(data, formattedData);
        } catch (e) {
            if (typeof e === 'string') {
                setErr(e.toUpperCase());
            } else if (e instanceof Error) {
                setErr(e.message);
            }

            return '';
        }
    }, [data, formattedData, dataConfig]);

    return <div>
        {err && <ErrorMessage error={err} />}
        {text}
    </div>;
}

export default function CustomTextRenderer(props: {
    dataConfig: DataConfig
    loadCB?: (promise: Promise<void>) => void
}) {
    return (
        <StandardDataRenderer dataConfig={props.dataConfig} loadCB={props.loadCB}>
            {CustomText}
        </StandardDataRenderer>
    );
}