import React, {useEffect, useState} from 'react';
import {hcIndex} from '../../lib/api';
import handleError from '../../lib/error';
import ErrorMessage from '../error-message';
import {HCIndexEntry, HCIndexSection} from '../../pages/tools/hc-index/types';
import Loading from '../loading';
import './hcindex-browser.scss';
import Button from '../button';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';


type Props = {
    onSelect: (index: HCIndexEntry) => void,
    pullBlindProd?: boolean
}

export default function HCIndexBrowser(props: Props) {
    const {onSelect, pullBlindProd} = props;
    const [sections, setSections] = useState<Array<HCIndexSection>>([]);
    const [err, setErr] = useState('');

    useEffect(() => {
        const controller = new AbortController();
        if (pullBlindProd) {
            hcIndex.getBlindProd(controller.signal)
                .then((sections: Array<HCIndexSection>) => setSections(sections))
                .catch(err => handleError(err, setErr));
        } else {
            hcIndex.sections(controller.signal)
                .then((sections: Array<HCIndexSection>) => {
                    setSections(sections.filter(s => s.entries.length));
                })
                .catch(err => handleError(err, setErr));
        }
        return () => controller.abort();
    }, []);

    return <div className="hcindex-browser">
        {err && <ErrorMessage error={err} />}
        {sections.length ?
            <div>
                {sections.map((section, i) => <div key={i} className="mt-24 first:mt-8">
                    <div className="flex mb-3">
                        <div className="ml-10" style={{width: 100}}>
                            {section.imageUrl && <img src={section.imageUrl.file.url} alt="" />}
                        </div>
                        <div className="ml-10">
                            <h2>{section.name}</h2>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                            </tr>
                        </thead>

                        <tbody>
                            {section.entries.map((entry, i) => <tr key={i}>
                                <td>
                                    <Button color="link" onClick={() => onSelect(entry)}>
                                        {entry.name}
                                    </Button>
                                </td>
                                <td>{entry.description && <div>{documentToReactComponents(entry.description)}</div>}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>)}
            </div> :
            <Loading small />}
    </div>;
}