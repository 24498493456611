import React, {useEffect, useState} from 'react';
import {accessRoles as accessRolesApi} from '../../../../lib/api';
import Button from '../../../../components/button';
import {AccessRole} from '../../../../lib/data-types';
import Loading from '../../../../components/loading';
import Toggle from '../../../../components/toggle';

const demo = [
    'queryData',
    'accessDashboard',
    'accessMap',
    'accessBenchmark',
    'accessDrg',
    'accessPatientFlow',
    'accessMetadata',
    'saveMap',
    'accessEntityDashboards',
    'accessInfluenceMap',
    'accessEntityExplorer',
    'standardTier',
    'canSearch',
    'canSaveDiscoveryTable',
    'saveMarket',
    'accessPopHealthMap',
    'listDashboards',
    'saveCodeReport',
    'accessPopHealthData',
    'accessGetStarted',
    'queryEOC'
];

const standard = [
    ...demo,
    'downloadData',
    'accessSavedQueries'
];

export const AccessRoleGroups = {
    hcindexTrial: [
        'accessHealthcareIndex',
        'queryQuantumEngine'
    ],
    demo: demo,
    standard: [
        ...demo,
        'downloadData'
    ],
    standardAPI: [
        ...standard,
        'apiAccess'
    ],
    premium: [
        ...standard,
        'premiumTier'
    ]
};

type Props = {
    accessRoles?: Array<string>,
    onChange: (roles: Array<string>) => void,
}

export default function AccessRolesInput(props: Props) {
    const {onChange} = props;
    const [accessRoleList, setAccessRoleList] = useState<Array<AccessRole>>([]);
    const accessRoles = props.accessRoles || [];

    useEffect(() => {
        const controller = new AbortController();
        accessRolesApi.list(controller.signal).then(accessRoleList => setAccessRoleList(accessRoleList));
        return () => controller.abort();
    }, []);

    function handleToggle(role: string) {
        const newAccessRoles = accessRoles.slice();
        const idx = accessRoles.indexOf(role);
        if (idx === -1) {
            newAccessRoles.push(role);
        } else {
            newAccessRoles.splice(idx, 1);
        }
        onChange(newAccessRoles);
    }

    return <div className="px-4 border-l">
        <p className="my-4 text-sm px-2 py-2 bg-yellow-200 rounded">
            To see what access roles map to which site features, refer to the <a href="https://gitlab.com/torch-insight/keen-suite/app-torchinsight/-/blob/master/src/lib/check-access.ts#L36" target="_blank" rel="noreferrer noopener">check-access.ts file</a>.
        </p>
        <label className="text-bold">Assign Access Role Group</label>
        <div>
            <Button color="light" onClick={() => onChange(AccessRoleGroups.demo)}>Demo</Button>
            <Button color="light" onClick={() => onChange(AccessRoleGroups.hcindexTrial)}>HCIndex Throttled Trial</Button>
            <Button color="light" onClick={() => onChange(AccessRoleGroups.standard)}>Standard</Button>
            <Button color="light" onClick={() => onChange(AccessRoleGroups.standardAPI)}>Standard w/ API</Button>
            <Button color="light" onClick={() => onChange(AccessRoleGroups.premium)}>Premium</Button>
        </div>

        <div className="mt-5">
            {!accessRoleList.length ?
                <Loading /> :
                <table>
                    <tbody>
                        {accessRoleList.map(ar => <tr key={ar.id}>
                            <td><Toggle checked={accessRoles.includes(ar.name)} onChange={() => handleToggle(ar.name)} /></td>
                            <td>{ar.name}</td>
                            <td className="text-gray-400">{ar.description}</td>
                        </tr>)}
                    </tbody>
                </table>}
        </div>
    </div>;
}