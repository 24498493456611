import React, {ReactNode} from 'react';
import './centered-layout.scss';

type Props = {
    children: ReactNode,
    size: number,
}

export default function CenteredLayout(props: Props) {
    let {size} = props;
    if (size < 3 || size > 9 || size % 2 === 0)
        size = 5;

    return <div className={`centered-layout-container md:cols-${size}`}>
        <div className={`centered-layout md:span-${size - 2}`}>
            {props.children}
        </div>
    </div>;
}