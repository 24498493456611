import Template from '../../components/template';
import React from 'react';

export default function AttributionPage() {
    return <Template>
        <div className="container">
            <h1>Attribution Notices</h1>
            <p>
                Icons by <a href="https://www.icofont.com">IcoFont</a>, licensed under the <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a> license.
            </p>
        </div>
    </Template>;
}