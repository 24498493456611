import './index.scss';
import Template from '../../components/template';
import auth from '../../lib/auth';
import TabBar from '../../components/tab-bar';
import AccountSettingsSection from './sections/account-settings';
import StatusSection from './sections/subscription-status';
import AuthWall from '../../components/auth-wall';
import UserDownloads from './sections/downloads';
import SavedDataPage from './sections/saved-data';
import React, {useContext} from 'react';
import CanvasPage from './sections/canvas';
import {HeaderContext} from '../../components/header/context';
import Breadcrumb from '../../components/breadcrumb';
import {CheckmarkOutline, Dashboard, DataTable, DocumentDownload, UserSettings} from '@carbon/icons-react';
import FavoritesPage from './sections/favorites';
import {StarFilled} from '@carbon/icons-react';


export default function UserPage() {

    const headerStore = useContext(HeaderContext);

    function legacyUserPage() {
        return <div id="user-page" className="pb-10">
            <div id="hero" className="bg-blue-gradient" >
                <div className="text-7xl bg-gray-100 text-blue-500 rounded-full p-8">
                    <i className="icon-user" />
                </div>
                <div className="mx-10">
                    <h1>{auth.principal?.name}</h1>
                    <p>{auth.principal?.email}</p>
                    <p>{auth.principal?.orgs.map(org => org.name).reduce((prev, curr, idx) => idx === 0 ? curr : `${prev}, ${curr}`, '')}</p>
                </div>
            </div>
            <TabBar useRouter useContainer justification="justify-center">
                {[
                    {name: 'Account Settings', component: AccountSettingsSection, stub: 'account'},
                    {name: 'Saved Data', component: SavedDataPage, stub: 'saved-data'},
                    {name: 'Canvas Boards', component: CanvasPage, stub: 'canvas'},
                    {name: 'File Downloads', component: UserDownloads, stub: 'user-downloads'},
                    {name: 'Subscription Status', component: StatusSection, stub: 'status'},
                ]}
            </TabBar>
        </div>;
    }

    function dppUserPage() {
        return <div className='dpp-container px-10 py-6 flex flex-col bg-background-gray-100'>
            <div className='flex'>
                <div className='flex-1'>
                    <Breadcrumb crumbs={[{name: 'Home', url: '/'}, {name: 'My Account', url: '/user/account'}]} />
                    <div className='py-4'>
                        <div className='text-4xl'>My Account</div>
                    </div>
                </div>
                <div className='flex-1'>
                    <div className='py-4'>
                        <div className="text-right">
                            <div className='text-2xl mb-2' >{auth.principal?.name}</div>
                            <div>{auth.principal?.email}</div>
                            <div>{auth.principal?.orgs.map(org => org.name).reduce((prev, curr, idx) => idx === 0 ? curr : `${prev}, ${curr}`, '')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <TabBar useRouter justification="justify-left">
                {[
                    {name: <div className='flex items-bottom gap-2 text-sm'><div>Account Settings</div> <UserSettings size={16}/></div>, component: AccountSettingsSection, stub: 'account'},
                    {name: <div className='flex items-bottom gap-2 text-sm'><div>Saved Data</div> <DataTable size={16}/></div>, component: SavedDataPage, stub: 'saved-data'},
                    {name: <div className='flex items-bottom gap-2 text-sm'><div>My Favorites</div> <StarFilled size={16}/></div>, component: FavoritesPage, stub: 'favorites'},
                    {name: <div className='flex items-bottom gap-2 text-sm'><div>Canvas Boards</div> <Dashboard size={16}/></div>, component: CanvasPage, stub: 'canvas'},
                    {name: <div className='flex items-bottom gap-2 text-sm'><div>File Downloads</div> <DocumentDownload size={16}/></div>, component: UserDownloads, stub: 'user-downloads'},
                    {name: <div className='flex items-bottom gap-2 text-sm'><div>Subscription Status</div> <CheckmarkOutline size={16}/></div>, component: StatusSection, stub: 'status'},
                ]}
            </TabBar>
        </div>;
    }

    return <AuthWall>
        <Template showFooter>
            {headerStore.useNewDesign ? dppUserPage() : legacyUserPage()}
        </Template>
    </AuthWall>;
}