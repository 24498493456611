import Template from '../../components/template';
import PageMenu from '../../components/page-menu';
import React, {useContext, useEffect, useState} from 'react';
import Button from '../../components/button';
import {users} from '../../lib/api';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import handleError from '../../lib/error';
import Loading from '../../components/loading';
import {HeaderContext} from '../../components/header/context';
import LoginTitle from './login-title';
import Input from '../../components/input';
import Alert from '../../components/alert';
import {CheckmarkFilled, ErrorFilled} from '@carbon/icons-react';

const PASSWORD_MIN_LENGTH = 8;
export default function ChangePasswordPage() {
    const [newPassword, setNewPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [valid, setValid] = useState<boolean>(true);
    const [changing, setChanging] = useState<boolean>(false);
    const [changed, setChanged] = useState<boolean>(false);
    const [validation, setValidation] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const headerStore = useContext(HeaderContext);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('email');
        const code = searchParams.get('code');
        if (!email || !code)
            setValid(false);
        else {
            users.passwordReset.check(email, code).then(res => {
                setEmail(email);
                setCode(code);
                setValid(res.valid);
            });
        }

    }, []);

    function changePassword() {
        setErrorMessage('');
        if (!newPassword && !repeatPassword) {
            setValidation(true);
            return;
        }

        if (newPassword !== repeatPassword) {
            setErrorMessage('Passwords do not match');
            return;

        }
        if (newPassword!.length < PASSWORD_MIN_LENGTH) {
            setErrorMessage('Password must be at least 8 characters long.');
            return;
        }

        setChanging(true);
        users.passwordReset.change(email, code, newPassword!)
            .then(() => setChanged(true))
            .catch(e => handleError(e, setErrorMessage))
            .finally(() => setChanging(false));
    }

    if (!valid)
        return <Navigate replace to="/login" />;

    function legacyChangePassword() {
        return <div>
            <PageMenu>
                <div className="center">
                    <h1>Change Your Password</h1>
                </div>
            </PageMenu>
            <div className="container flex flex-col items-center mt-16">
                <p>
                    Please enter your new password. It must be at least 8 characters long.
                </p>
                {changing && <div className="flex space-x-8 items-center"><span>Updating password...</span><Loading /></div>}
                {changed && <div className="text-green-500">Password successfully updated</div>}
                {changed && <Link to="/login">Login</Link>}
                {errorMessage && <div className="text-red-500">{errorMessage}</div>}
                <form className="w-1/3 flex flex-col space-y-8 mt-8" onClick={e => e.preventDefault()}>
                    <div className="flex flex-col space-y-3">
                        <label className="font-bold">New Password</label>
                        <input type="password" required value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                    </div>
                    <div className="flex flex-col space-y-3">
                        <label className="font-bold">Re-Enter Password</label>
                        <input type="password" required value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)} />
                    </div>
                    <div className="flex justify-end">
                        <Button disabled={!newPassword || !repeatPassword} color="blue" onClick={changePassword}>Change Password</Button>
                    </div>
                </form>
            </div>
        </div>;
    }

    function dppChangePassword() {
        return <div className="login-page">
            <div className="wrapper">
                <div className='w-full'>
                    <LoginTitle>
                        <span>Reset password</span>
                    </LoginTitle>
                    {errorMessage && <Alert color='red' icon={<ErrorFilled />}>{errorMessage}</Alert>}
                    {!changed && <div className='mt-8'>
                        <p>Please enter your new password.</p>
                        <div className='mt-4 text-gray-500 text-sm font-bold'>New Password</div>
                        <Input value={newPassword} onChange={e => setNewPassword(e.target.value)} tabIndex={1}
                            placeholder='New Password' validation={validation && !newPassword} type='password' />
                        <small>Password must contain at least 8 characters</small>
                        <div className='mt-4 text-gray-500 text-sm font-bold'>Confirm Password</div>
                        <Input value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)} tabIndex={2}
                            placeholder='Confirm Password' validation={validation && !repeatPassword}
                            type='password' />
                        <small>Passwords must match</small>
                        <Button color='milliman-blue' className='block w-full mt-8'
                            disabled={!newPassword || !repeatPassword}
                            loading={changing}
                            onClick={changePassword}>
                            <div className='w-full h-full flex space-x-3 justify-center items-center py-2'
                                tabIndex={3}>
                                <span className='block'>Reset Password</span>
                            </div>
                        </Button>
                        <div className='mt-16 flex justify-center'>
                            <Link className='text-medinsight-blue-300' to='/login'>Back to login</Link>
                        </div>
                    </div>}
                    {changed && <div>
                        <Alert color='green' icon={<CheckmarkFilled />} noDismiss>
                            <div>
                                <strong className='block'>Password reset successful</strong>
                                <p>You can now use your new password to log in to your account.</p>
                            </div>
                        </Alert>
                        <Button color='milliman-blue' className='block w-full mt-8' onClick={() => navigate('/login')}>
                            <div className='w-full h-full flex space-x-3 justify-center items-center py-2' tabIndex={1}>
                                <span className='block'>Log in</span>
                            </div>
                        </Button>
                    </div>}
                </div>
            </div>
        </div>;
    }

    return <Template showFooter={headerStore.useNewDesign}>
        {headerStore.useNewDesign ? dppChangePassword() : legacyChangePassword()}
    </Template>;
}