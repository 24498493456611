import {makeAutoObservable} from 'mobx';
import {
    CanvasParameter,
    CanvasSection,
    CanvasType, ConditionalRender,
    CvData,
    CvRowType,
    ParameterConfig,
    RowLayouts,
} from './types';
import {canvas} from '../../../lib/api';
import {QuantumGraph} from 'quantum-graph';
import handleError from '../../../lib/error';
import auth from '../../../lib/auth';
import createGraphHandler from '../../../lib/graph-tools/graph-handler/create';
import {dataRow} from '../../../lib/data-types';
import fetchData from '../../../lib/data';
import {createParameterQuery} from './parameters';
import {NavigateFunction} from 'react-router-dom';
import {getLastSection} from './util';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';
import {CanvasBoard} from './canvas-board';
import {generateHash} from '../../../lib/hash';
import {RowCountOperators} from './row-count-compare';

/**
 * The CanvasStore class represents the MobX store for the canvas page.
 */
export default class CanvasStore {

    static empty() {
        return new CanvasStore();
    }

    editing: boolean;
    draftMode: boolean;
    board?: CanvasBoard;
    pristineBoard?: CanvasBoard;
    loading: boolean;
    saving = false;
    merging = false;
    error = '';
    copyModal = false;
    rowLayoutModal = false;
    name = '';
    showVersionModal = false;
    versionId?: number;

    private _id: number;
    private _title?: string;
    private _loadPromises: Array<Promise<void>> = [];
    private _addRowIndex = -1;
    private _addItemIndex = -1;
    private _addConditionalRenderRow = false;
    private _addConditionalRenderCell = false;
    private _addConditionalRenderAlternativeIC = false;
    private _parameterValues: Array<string> = [];
    private _setupParameter: boolean;
    private _parameterRow: dataRow = {};
    private _navigate?: NavigateFunction;
    private _currentSectionIndex: number;
    private _pristine: boolean;
    private _canvasUserId?: number;
    private _canvasOrgId?: number;
    private _boardHash?: string;

    /**
     * Creates an instance of the CanvasStore class.
     * @constructor
     * @param {number} [id=0] - The ID of the canvas.
     * @param {string} [title] - The title of the canvas.
     * @param {NavigateFunction} [navigate] - The function to navigate to a different page.
     * @param {number} [currentSectionIndex=0] - The index of the current section.
     */
    constructor(id?: number, title?: string, navigate?: NavigateFunction, currentSectionIndex?: number) {
        this._id = id || 0;
        this._title = title;
        this._navigate = navigate;
        this.editing = false;
        this.draftMode = false;
        this.loading = true;
        this._pristine = true;
        this._setupParameter = false;
        this._currentSectionIndex = currentSectionIndex || 0;
        makeAutoObservable(this);
    }

    /**
     * Serializes the canvas class into a JSON object
     */
    serialize(): CanvasType {
        return {
            name: this.name,
            boardJSON: JSON.stringify(this.board?.serialize()),
            id: this._id,
            userId: this._canvasUserId,
            orgId: this._canvasOrgId,
            title: this._title,
        };
    }

    /**
     * Deserializes the canvas class from a JSON object
     * @param canvas
     */
    deserialize(canvas: CanvasType) {
        this.name = canvas.name || '';
        this._id = canvas.id!;
        this._canvasUserId = canvas.userId;
        this._canvasOrgId = canvas.orgId;
        this._title = canvas.title;
        this.board = CanvasBoard.deserialize(JSON.parse(canvas.boardJSON));
    }

    /**
     * Gets the Canvas Board JSON from the service.
     * @param signal
     * @returns Promise<CanvasType>
     */
    async getCanvasFromService(signal: AbortSignal) {
        let canvasObject: CanvasType;
        if (this.versionId && this.canEdit) {
            canvasObject = await canvas.getCanvasVersion(this.versionId, signal);
        } else if (this._title) {
            if (this.draftMode)
                canvasObject = await canvas.getByTitleDraft(this._title, signal);
            else
                canvasObject = await canvas.getByTitle(this._title, signal);
            this._id = canvasObject.id!;
        } else
            if (this.draftMode)
                canvasObject = await canvas.getDraft(this._id, signal);
            else
                canvasObject = await canvas.get(this._id, signal);

        return canvasObject;
    }

    /**
     * Loads the canvas with the given parameter values.
     * @param {Array<number>} paramValues - The parameter values to load the canvas with.
     * @param {AbortSignal} signal - The abort signal to cancel the loading if needed.
     * @returns {Promise<void>} - A promise that resolves when the canvas is loaded.
     */
    async load(paramValues: Array<string>, signal: AbortSignal): Promise<void> {
        let canvasObject = await this.getCanvasFromService(signal);
        this.board = CanvasBoard.deserialize(JSON.parse(canvasObject!.boardJSON));
        this.pristineBoard = CanvasBoard.deserialize(JSON.parse(canvasObject!.boardJSON));
        this._boardHash = await generateHash(JSON.stringify(this.board));
        this.name = canvasObject!.name || '';
        this._title = canvasObject!.title;
        this._canvasUserId = canvasObject!.userId;
        this._canvasOrgId = canvasObject!.orgId;

        if (this._currentSectionIndex >= this.board.sections.length) {
            this._currentSectionIndex -= 1;
        }
        await this.loadParameter(paramValues, signal);
        // Loading one of your boards that is empty will jump to editing
        if ((!this.getCurrentSection() || !this.getCurrentSection()?.rows.length) && this._canvasUserId === auth.principal?.sub) {
            this.editing = true;
        }
    }

    /**
     * Loads in the parameter values and the parameter row
     * @param paramValues
     * @param signal
     */
    async loadParameter(paramValues: Array<string>, signal: AbortSignal): Promise<void> {
        try {
            this._parameterValues = paramValues;
            await this.loadParameterRow(signal);
            // If there are no parameter values, add a promise to the list of promises being tracked for loading.
            if (!this._parameterValues.length)
                this.addPromise(Promise.resolve());
        } catch (e) {
            this.handleError(e as Error);
        }
    }


    /**
     * Loads the canvas from the board JSON and parameter values. Enables search mode on the canvas.
     * @param boardJSON
     * @param paramValues
     * @param signal
     */
    async loadBoardForSearch(boardJSON: string, paramValues:Array<string>, signal: AbortSignal): Promise<void> {
        try {
            this.loading = true;
            this.currentSectionIndex = 0;
            this._parameterValues = paramValues;
            await this.loadCanvasFromBoard(boardJSON, signal);
        } catch (e) {
            this.handleError(e as Error);
        } finally {
            this.loading = false;
        }
    }

    /**
     * Reloads the canvas from a provided boardJSON object.
     * @param boardJSON
     * @param signal
     */
    async loadCanvasFromBoard(boardJSON: string, signal: AbortSignal) {
        this.board = CanvasBoard.deserialize(JSON.parse(boardJSON));
        await this.loadParameterRow(signal);
    }

    /**
     * Handles an error by setting the error message and stopping loading if the error is due to unauthorized access.
     * @param {Error} error - The error to handle.
     * @returns {void}
     */
    handleError(error: Error): void {
        // if(isAbortError(error)) {
        //     return;
        // }
        let message = '';
        if (error.message.includes('not authorized')) {
            message = error.message;
            this.loading = false;
        }
        handleError(error, (e: string) => this.error = message || e);
    }

    /**
     * Loads the parameter row for the current canvas parameter.
     * @param {AbortSignal} [signal] - The abort signal to cancel the loading if needed.
     * @returns {Promise<void>} - A promise that resolves when the parameter row is loaded.
     */
    async loadParameterRow(signal?: AbortSignal): Promise<void> {
        if (!this.board || !this.board.parameter || !this._parameterValues[0])
            return;

        let data = await fetchData(createParameterQuery(this.board.parameter, this._parameterValues[0]), signal || new AbortController().signal);
        if (data && data.length)
            this.parameterRow = data[0];
    }

    /**
     * Adds a promise to the list of promises being tracked for loading.
     * @param {Promise<void>} promise - The promise to add to the list.
     * @returns {void}
     */
    addPromise(promise: Promise<void>) {
        this._loadPromises.push(promise);
    }

    // Actions

    /**
     * Toggles the editing state of the canvas by adding or removing the url parameter.
     * @returns {void}
     */
    toggleEdit(search: string): void {
        const searchParams = new URLSearchParams(search);
        if (this.editing)
            searchParams.delete('editing');
        else
            searchParams.set('editing', 'true');
        this._navigate && this._navigate({search: searchParams.toString()});
    }

    /**
     * Updates the text content of an item on the board at the specified row and item indices.
     * @param {number} rowIdx - The index of the row containing the item to update.
     * @param {number} itemIdx - The index of the item to update.
     * @param {'text' | 'rich-text'} type - The type of text content to update ('text' or 'rich-text').
     * @param {string} value - The new text content value.
     * @returns {void}
     */
    updateText(rowIdx: number, itemIdx: number, type: 'rich-text', value: string) {
        this.board!.updateText(this._currentSectionIndex, rowIdx, itemIdx, type, value);
        this._pristine = false;
    }

    /**
     * Updates the header text of a row on the board at the specified row index.
     * @param {number} rowIdx - The index of the row containing the header to update.
     * @param {string} value - The new header text value.
     * @returns {void}
     */
    updateHeader(rowIdx: number, value: string) {
        this.board!.updateHeader(this._currentSectionIndex, rowIdx, value);
        this._pristine = false;
    }

    /**
     * Removes an item from the board at the specified row and item indices.
     * @param rowIdx The index of the row containing the item to remove.
     * @param itemIdx The index of the item to remove.
     */
    removeItem(rowIdx: number, itemIdx: number) {
        let choice = window.confirm('Are you sure you want to remove this content?');
        if (choice) {
            this.board!.removeItem(this._currentSectionIndex, rowIdx, itemIdx);
            this._pristine = false;
        }
    }

    /**
     * Removes a row from the board at the specified index.
     * @param rowIdx The index of the row to remove.
     */
    removeRow(rowIdx: number) {
        let choice = window.confirm('Are you sure you want to remove this row?');
        if (choice) {
            this.board!.removeRow(this._currentSectionIndex, rowIdx);
            this._pristine = false;
        }
    }

    /**
     * Returns the size of the layout for the cell at the specified row and cell indices.
     * @param {number} rowIdx - The index of the row containing the cell.
     * @param {number} cellIdx - The index of the cell.
     * @returns {string} - The size of the layout for the cell.
     */
    getCellLayoutSize(rowIdx: number, cellIdx: number) {
        const row = this.board!.sections[this._currentSectionIndex].rows[rowIdx] as CvRowType;
        return row[cellIdx].size;
    }

    /**
     * Updates the data of a cell on the board at the specified row and cell indices.
     * @param {number} rowIdx - The index of the row containing the cell to update.
     * @param {number} cellIdx - The index of the cell to update.
     * @param {CvData} data - The new data for the cell.
     * @returns {void}
     */
    updateCell(rowIdx: number, cellIdx: number, data: CvData): void {
        this.board!.updateCell(this._currentSectionIndex, rowIdx, cellIdx, data);
        this._pristine = false;
    }

    /**
     * Adds a new header row to the board.
     * @returns {void}
     */
    addHeaderRow() {
        this.board!.addRow(this._currentSectionIndex, '');
        this._pristine = false;
    }

    /**
     * Adds a new content row to the board with the specified layout.
     * @param {RowLayouts} layout - The layout of the new row.
     * @returns {void}
     */
    addContentRow(layout: RowLayouts) {
        let row: any = [];
        layout.split('-').forEach(size => {
            row.push({
                type: 'empty',
                size
            });
        });
        this.board!.addRow(this._currentSectionIndex, row);
        this._pristine = false;
    }

    /**
     * Sets the row and item indices for the insert operation and opens the insert modal.
     * @param {number} rowIndex - The index of the row where the new item will be inserted.
     * @param {number} itemIndex - The index of the item where the new item will be inserted.
     * @returns {void}
     */
    startInsert(rowIndex: number, itemIndex: number): void {
        this._addRowIndex = rowIndex;
        this._addItemIndex = itemIndex;
    }

    /**
     * Sets the row and item indices for the insert operation and opens the insert modal. Will set the conditional render to the cell.
     * @param {number} rowIndex - The index of the row where the new item will be inserted.
     * @param {number} itemIndex - The index of the item where the new item will be inserted.
     * @returns {void}
     */
    startConditionalRenderCellInsert(rowIndex: number, itemIndex: number): void {
        this._addRowIndex = rowIndex;
        this._addItemIndex = itemIndex;
        this._addConditionalRenderCell = true;
    }

    /**
     * Sets the row and item indices for the insert operation and opens the insert modal. Will set the conditional render to the row.
     * @param {number} rowIndex - The index of the row where the new item will be inserted.
     * @returns {void}
     */
    startConditionalRenderRowInsert(rowIndex: number): void {
        this._addRowIndex = rowIndex;
        this._addItemIndex = -1;
        this._addConditionalRenderRow = true;
    }

    /**
     * Sets the row and item indices for the insert operation and opens the insert modal. Will set the alternative IC for the conditional render.
     * @param {number} rowIndex - The index of the row where the new item will be inserted.
     * @param {number} itemIndex - The index of the item where the new item will be inserted.
     * @returns {void}
     */
    startConditionalRenderAlternativeInsert(rowIndex: number, itemIndex: number): void {
        this._addRowIndex = rowIndex;
        this._addItemIndex = itemIndex;
        this._addConditionalRenderAlternativeIC = true;
    }

    /**
     * Closes the insert modal and resets the row and item indices for the insert operation.
     * Also resets the insert screen to 'type' and the setup parameter to false.
     * @returns {void}
     */
    closeInsert(): void {
        this._addRowIndex = -1;
        this._addItemIndex = -1;
        this._setupParameter = false;
        this._addConditionalRenderCell = false;
        this._addConditionalRenderRow = false;
        this._addConditionalRenderAlternativeIC = false;
    }

    /**
     * Returns the current cell being edited, or null if no cell is being edited.
     * @returns {CvData | null} - The current cell being edited, or null if no cell is being edited.
     */
    getCurrentCell() {
        if (this._addRowIndex === -1 || !this.board)
            return null;

        const row = this.board.sections[this._currentSectionIndex].rows[this._addRowIndex] as CvRowType;
        return row[this._addItemIndex];
    }


    /**
     * Returns the current section being shown, or null if no section is being shown.
     * @returns {CanvasSection | null} - The current section being shown, or null if no section is being shown.
     */
    getCurrentSection(): CanvasSection | null {
        if (this.board && this.board.sections?.length && this._currentSectionIndex < this.board.sections?.length)
            return this.board.sections[this._currentSectionIndex];
        return null;
    }

    /**
     * Returns the current rows conditional render or null if not set.
     * @param index
     */
    getRowConditionalRender(index: number): ConditionalRender | undefined {
        const section = this.board!.sections[this._currentSectionIndex];
        const conditionalRows = section.conditionalRenderRows || new Array(section.rows.length).fill(undefined);
        return conditionalRows[index];
    }

    /**
     * Adds a new text or rich-text cell to the board at the specified row and cell indices.
     * @param {string} type - The type of the new cell, either 'text' or 'rich-text'.
     * @returns {void}
     */
    addText(type: 'rich-text') {
        const row = this.board!.sections[this._currentSectionIndex].rows[this._addRowIndex] as CvRowType;
        const size = row[this._addItemIndex].size;
        const conditionalRender = row[this._addItemIndex].conditionalRender;
        row[this._addItemIndex] = {type, size, value: '', conditionalRender};
        this.closeInsert();
        this._pristine = false;
    }

    /**
     * Creates a new section in the board with a default title and no rows.
     * If the board exists, the new section is added to the board and saved.
     * The current section index is set to the newly created section.
     * @returns {Promise<void>}
     */
    async createSection() {
        if (this.board) {
            this.board.addSection();
            await this.save();
            this.currentSectionIndex = getLastSection(this.board);
        }
    }


    /**
     * Deletes the current section from the board, if there is more than one section.
     * Prompts the user for confirmation before deleting the section.
     * @returns {Promise<void>}
     */
    async deleteCurrentSection() {
        let deleteSection = window.confirm('Are you sure you want to delete this section');
        if (deleteSection) {
            if (this.board && this.board.sections && this.board.sections.length > 1) {
                this.board?.sections.splice(this._currentSectionIndex, 1);
                await this.save();
                this.currentSectionIndex = 0;
            }
        }
    }

    /**
     * Adds a new insight container cell to the board at the specified row and cell indices.
     * @param {string} type - The type of the new insight container, such as 'bar-chart' or 'line-chart'.
     * @param {Array<QuantumGraph>} queries - The array of QuantumGraph objects to be used as data sources for the insight container.
     * @param {Array<ParameterConfig>} parameterConfigs - The array of ParameterConfig objects to be used as configuration for the insight container.
     * @param {any} options - The options object to be used as configuration for the insight container.
     * @returns {void}
     */
    addInsightContainer(type: string, queries: Array<QuantumGraph>, parameterConfigs: Array<ParameterConfig> = [], options: any = []) {
        const row = this.board!.sections[this._currentSectionIndex].rows[this._addRowIndex] as CvRowType;
        const size = row[this._addItemIndex].size;
        const conditionalRender = row[this._addItemIndex].conditionalRender;
        const gh = queries.map((q) => createGraphHandler(q, getDefaultDataDefRepo()));
        const serialized = gh.map((h) => h.serialize());
        row[this._addItemIndex] = {type: 'data', size, id: 0, visType: type, parameterConfigs, options, queryObj: serialized, conditionalRender};
        this.closeInsert();
        this.pristine = false;
    }

    /**
     * Adds a conditional render to the specified cell.
     * @param queryObj
     * @param operator
     * @param rowCount
     * @param showLoading
     * @param parameterConfigs
     */
    setConditionalRenderCell(queryObj: object, operator: RowCountOperators, rowCount: number, showLoading: boolean, parameterConfigs: Array<ParameterConfig>) {
        const row = this.board!.sections[this._currentSectionIndex].rows[this._addRowIndex] as CvRowType;
        row[this._addItemIndex].conditionalRender = {
            queryObj,
            operator,
            rowCount,
            showLoading,
            parameterConfigs
        };
        this.closeInsert();
        this.pristine = false;
    }

    /**
     * Adds the alternative insight container for a conditional render cell.
     * @param type
     * @param queries
     * @param parameterConfigs
     * @param options
     */
    setConditionalRenderCellAlternativeIC(type: string, queries: Array<QuantumGraph>, parameterConfigs: Array<ParameterConfig> = [], options: any = []) {
        const row = this.board!.sections[this._currentSectionIndex].rows[this._addRowIndex] as CvRowType;
        const conditionalRender = row[this._addItemIndex].conditionalRender;
        if (conditionalRender) {
            const size = row[this._addItemIndex].size;
            const gh = queries.map((q) => createGraphHandler(q, getDefaultDataDefRepo()));
            const serialized = gh.map((h) => h.serialize());
            conditionalRender.alternateIC = {type: 'data', size, id: 0, visType: type, parameterConfigs, options, queryObj: serialized};
            this.pristine = false;
        }
        this.closeInsert();
    }

    /**
     * Adds a conditional render to the specified row.
     * @param queryObj
     * @param operator
     * @param rowCount
     * @param showLoading
     * @param parameterConfigs
     */
    setConditionalRenderRow(queryObj: object, operator: RowCountOperators, rowCount: number, showLoading: boolean, parameterConfigs: Array<ParameterConfig>) {
        const section = this.board!.sections[this._currentSectionIndex];
        section.conditionalRenderRows = section.conditionalRenderRows || new Array(section.rows.length).fill(undefined);
        section.conditionalRenderRows[this._addRowIndex] = {
            queryObj,
            operator,
            rowCount,
            showLoading,
            parameterConfigs
        };
        this.closeInsert();
        this.pristine = false;
    }

    /**
     * Removes the conditional render from the specified cell.
     */
    removeConditionalRenderCell() {
        const row = this.board!.sections[this._currentSectionIndex].rows[this._addRowIndex] as CvRowType;
        row[this._addItemIndex].conditionalRender = undefined;
        this.closeInsert();
        this.pristine = false;
    }

    /**
     * Removes the conditional render from the specified row.
     */

    removeConditionalRenderRow() {
        const section = this.board!.sections[this._currentSectionIndex];
        section.conditionalRenderRows = section.conditionalRenderRows || new Array(section.rows.length).fill(undefined);
        section.conditionalRenderRows[this._addRowIndex] = undefined;
        this.closeInsert();
        this.pristine = false;
    }

    /**
     * Removes the conditional render alternative IC from the specified cell.
     */
    removeConditionalRenderAlternativeIC() {
        const row = this.board!.sections[this._currentSectionIndex].rows[this._addRowIndex] as CvRowType;
        const conditionalRender = row[this._addItemIndex].conditionalRender;
        if (conditionalRender) {
            conditionalRender.alternateIC = undefined;
            this.pristine = false;
        }
        this.closeInsert();
    }

    /**
     * Moves a row within the current section of the board by the specified delta.
     * @param {number} index - The index of the row to move.
     * @param {number} delta - The number of positions to move the row. A positive delta moves the row down, and a negative delta moves the row up.
     * @returns {void}
     */
    moveRow(index: number, delta: number): void {
        this.board!.moveRow(this._currentSectionIndex, index, delta);
        this._pristine = false;
    }

    // Parameters

    /**
     * Adds a new parameter to the board.
     * @param {CanvasParameter} parameter - The parameter to add to the board.
     * @returns {Promise<void>}
     */
    async addParameter(parameter: CanvasParameter): Promise<void> {
        this.board!.parameter = parameter;
        await this.loadParameterRow().catch(this.handleError);
        this._pristine = false;
    }

    /**
     * Removes the parameter from the board.
     * @returns {void}
     */
    removeParameter(): void {
        this.board!.parameter = undefined;
        this._parameterRow = {};
        this._setupParameter = false;
        this._pristine = false;
    }

    /**
     * Saves the current state of the board to the database.
     * Updates the name and boardJSON properties of the current canvas object with the current board state.
     * @returns {Promise<void>}
     */
    async save(publish = false): Promise<void> {
        this.merging = false;
        const freshCanvas = await this.getCanvasFromService(new AbortController().signal);
        const freshBoardJSON = CanvasBoard.deserialize(JSON.parse(freshCanvas.boardJSON));
        const freshHash = await generateHash(JSON.stringify(freshBoardJSON));
        if (this._boardHash === freshHash) {
            this._boardHash = await generateHash(JSON.stringify(this.board));
            if (publish)
                await this.publishToServer();
            else
                await this.syncDraftToServer();
        }
        else
            this.merging = true;
    }

    /**
     * Publishes the current Canvas to the server.
     * This version will be what clients see when they load the Canvas.
     */
    async publish(): Promise<void> {
        if (window.confirm('Are you sure you want to publish this Canvas?'))
            await this.save(true);
    }

    /**
     * Syncs the current in memory Canvas BoardJSON to the server.
     */
    async syncDraftToServer() {
        this.saving = true;
        return canvas.update(this.serialize())
            .then(() => {
                this.saving = false;
                this.editing = false;
                this._pristine = true;
                this.merging = false;
            })
            .catch(e => this.handleError(e));
    }

    /**
     * Calls the Canvas publish API to create a new published version of the Canvas.
     */
    async publishToServer() {
        this.saving = true;
        canvas.publish(this.serialize())
            .then(() => {
                this.saving = false;
                this.editing = false;
                this._pristine = true;
                this.merging = false;
            })
            .catch(e => this.handleError(e));
    }

    /**
     * Saves a copy of the current canvas to the database with the specified name.
     * Updates the name, boardJSON, userId, and orgId properties of the new canvas object with the current board state and user information.
     * @param {string} name - The name to give to the new canvas.
     * @returns {Promise<void>}
     */
    async saveCopy(name: string): Promise<void> {
        this.saving = true;
        this.copyModal = false;
        const canvasObject = this.serialize();
        delete canvasObject.id;
        canvasObject.title = encodeURIComponent(name);
        canvasObject.name = name;
        canvasObject.userId = Number(auth.principal?.sub);
        canvasObject.orgId = undefined;
        canvas.create(canvasObject)
            .then(({id}) => {
                this._navigate && this._navigate('/canvas/' + id + '/0?draft=true&editing=true');
            })
            .catch(e => this.handleError(e));
    }

    /**
     * Applies the current version of the Canvas to the draft version.
     */
    async applyCanvasVersion() {
        if (window.confirm('Apply current version to this Canvas draft?')) {
            return await this.syncDraftToServer();
        }
    }

    toggleCopyModal() {
        this.copyModal = !this.copyModal;
    }

    toggleRowLayoutModal() {
        this.rowLayoutModal = !this.rowLayoutModal;
    }

    isAddConditionalRender() {
        return this.addConditionalRenderRow || this.addConditionalRenderCell;
    }

    get sections() {
        return this.board?.sections;
    }

    get rows() {
        const section = this.getCurrentSection();
        return section ? section.rows : [];
    }

    get insertModal() {
        return (this._addRowIndex !== -1 || this._setupParameter);
    }

    get canEdit() {
        return auth.principal?.sub === this._canvasUserId || auth.hasRole('admin');
    }

    get title() {
        return this._title;
    }

    get boardId() {
        return this._id;
    }

    get currentSectionIndex() {
        return this._currentSectionIndex;
    }

    set currentSectionIndex(i: number) {
        if (i < this.board!.sections.length) {
            this._currentSectionIndex = i;
        }
    }

    get sectionTitle() {
        let section = this.getCurrentSection();
        if (section)
            return section.title;
        return '';
    }

    set sectionTitle(title: string) {
        let section = this.getCurrentSection();
        if (section) {
            section.title = title;
            this._pristine = false;
        }
    }

    get pristine() {
        return this._pristine;
    }

    set pristine(pristine) {
        this._pristine = pristine;
    }

    get parameterRow() {
        return this._parameterRow;
    }

    set parameterRow(row: dataRow) {
        this._parameterRow = row;
    }

    get addRowIndex() {
        return this._addRowIndex;
    }

    get setupParameter() {
        return this._setupParameter;
    }

    set setupParameter(setup) {
        this._setupParameter = setup;
    }

    get addConditionalRenderCell() {
        return this._addConditionalRenderCell;
    }

    set addConditionalRenderCell(conditionalRender: boolean) {
        this._addConditionalRenderCell = conditionalRender;
    }

    get addConditionalRenderRow() {
        return this._addConditionalRenderRow;
    }

    set addConditionalRenderRow(conditionalRender: boolean) {
        this._addConditionalRenderRow = conditionalRender;
    }

    get addConditionalRenderAlternativeIC() {
        return this._addConditionalRenderAlternativeIC;
    }

    set addConditionalRenderAlternativeIC(conditionalRender: boolean) {
        this._addConditionalRenderAlternativeIC = conditionalRender;
    }

    get parameter() {
        return this.board?.parameter;
    }

    set parameter(p) {
        this.board!.parameter = p;
    }

    get parameterValue() {
        return this._parameterValues[0];
    }

    set parameterValue(v) {
        this._parameterValues = [v];
    }

    get loadPromises() {
        return this._loadPromises;
    }

    set loadPromises(promises) {
        this._loadPromises = promises;
    }
}

