import AuthWall from '../../../components/auth-wall';
import {SiteFeature} from '../../../lib/check-access';
import Template from '../../../components/template';
import React from 'react';
import OneTwoLayout from '../../../components/layout/one-two-layout';
import {NavLink, Route, Routes} from 'react-router-dom';
import Card from '../../../components/card';
import CrmApiOverview from './overview';
import CrmApiDataEndpoints from './data';
import CrmApiPdfEndpoints from './pdf';


export default function CrmApiDocumentation() {
    return <AuthWall siteFeature={SiteFeature.CrmApi} >
        <Template>
            <div className="container" id="api-doc-page">
                <h1 className="text-center mb-4">CRM API Documentation</h1>

                <OneTwoLayout>
                    <div>
                        <div className="my-4 text-small uppercase font-light text-gray-500">General</div>
                        <NavLink className={(navData) => navData.isActive ? 'nav-link is-active' : 'nav-link'} replace to={'/docs/crm-api/'}>Overview</NavLink>

                        <div className="my-4 text-small uppercase font-light text-gray-500">Cardio-Renal-Metabolic Endpoints</div>
                        <NavLink className={(navData) => navData.isActive ? 'nav-link is-active' : 'nav-link'} to={'/docs/crm-api/data'}>Data Endpoints</NavLink>
                        <NavLink className={(navData) => navData.isActive ? 'nav-link is-active' : 'nav-link'} to={'/docs/crm-api/pdf'}>PDF Endpoint</NavLink>
                    </div>
                    <Card>
                        <Routes>
                            <Route path="/" element={<CrmApiOverview />} />
                            <Route path="data" element={<CrmApiDataEndpoints />} />
                            <Route path="pdf" element={<CrmApiPdfEndpoints />} />
                        </Routes>
                    </Card>
                </OneTwoLayout>
            </div>
        </Template>
    </AuthWall>;
}