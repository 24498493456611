import React, {MutableRefObject, ReactNode} from 'react';
import './button.scss';
import classNames from '../../lib/class-names';
import {Link, To} from 'react-router-dom';

export type Props = {
    color: string,
    pill?: boolean,
    outlined?: boolean,
    disabled?: boolean,
    small?: boolean,
    loading?: boolean,
    children?: ReactNode,
    onClick?: () => void,
    className?: string,
    to?: string | To,
    state?: any,
};

type RefType = ((instance: HTMLButtonElement | null) => void)
    | MutableRefObject<HTMLButtonElement | null>
    | null;

const Button = React.forwardRef((props: Props, ref: RefType) => {
    const {color, pill, outlined, disabled, small, loading, className, to, state} = props;
    const classes = classNames('button', color, className, {pill, outlined, small, loading});
    if (to)
        return <Link className={classes} onClick={props.onClick} to={to} state={state}>{props.children}</Link>;
    return <button className={classes} onClick={props.onClick} disabled={disabled} ref={ref}>{props.children}</button>;
});

export default Button;