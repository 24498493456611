import logoBlue from '../../components/images/logo-blue.svg';
import React, {ReactNode} from 'react';
import SiteName from '../../components/SiteName';

type Props = {
    children?: ReactNode
}
export default function LoginTitle(props: Props) {

    return <div className="w-full">
        <div className='w-full flex justify-center text-2xl mt-16'>MedInsight</div>
        <div className='w-full flex justify-center items-center text-4xl py-3'>
            <img className='inline h-10 w-auto mr-6' src={logoBlue} alt='Torch Insight' />
            <SiteName />
        </div>
        <div className='text-xl mt-8'>{props.children}</div>
    </div>;
}