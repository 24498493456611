import {observer} from 'mobx-react';
import {useContext, useState, useMemo, Ref} from 'react';
import {useNavigate} from 'react-router-dom';
import Button from '../../components/button';
import DownloadButton from '../../components/download-button';
import ErrorMessage from '../../components/error-message';
import {downloadGraph} from '../../lib/download';
import {explore} from './common';
import ChartContext from './context';
import './charts.scss';
import SourceModal from '../../components/source-modal';
import QueryDataContext from '../renderers/query-data-renderer/context';
import YearModal from '../../components/year-modal';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import {getDefaultDataDefRepo} from '../../lib/data-defs';
import {CanvasContext} from '../../pages/canvas/lib/context';
import {getConfigOption} from '../types';
import Badge from '../../components/badge';

type ChartContainerProps = {
    maxRows: number,
    children: React.ReactNode,
    divRef?: Ref<HTMLDivElement>
}

const ChartContainer = observer(({maxRows, children, divRef}: ChartContainerProps) => {
    let chartContext = useContext(ChartContext);
    let queryContext = useContext(QueryDataContext);
    const canvasStore = useContext(CanvasContext);
    const parameter = canvasStore.board && canvasStore.board.parameter ? canvasStore.board.parameter : undefined;

    let [editModalOpen, setEditModalOpen] = useState(false);
    let [sourceModalOpen, setSourceModalOpen] = useState(false);
    let navigate = useNavigate();

    let valueMeta = useMemo(() => queryContext.metadata.find((v) => v.name === chartContext.yAxisNames[0]) || chartContext.valueMeta, [queryContext.metadata, chartContext.yAxisNames]);
    let title = chartContext.title;
    const showExplore = !getConfigOption<boolean>(queryContext.dataConfig, 'hideExplore');

    return (
        <div ref={divRef} className='h-full'>
            <div className="w-full flex justify-between">
                <div>{title && <h3>{title}</h3>}</div>
                {!!chartContext.getYearKeyVariable() && !chartContext.isYearChart && <div className="flex items-end hover:cursor-pointer"><Badge color="blue" pill onClick={() => setEditModalOpen(true)}>{queryContext.getYearFiltersValues()[0] || chartContext.year}</Badge></div>}
            </div>

            <div className='bg-white mt-3 px-3 py-3 border rounded-t text-gray-500 resizeContainer'>
                {(chartContext.rowsToShow <= maxRows || 
                    (!chartContext.xAxisName || !chartContext.yAxisNames.length)) && 
                    valueMeta && 
                    ((queryContext.data.length && queryContext.data.some(v => chartContext.yAxisNames.some(n => !!v[n]))) || (chartContext.data.length && chartContext.data.some(v => chartContext.yAxisNames.some(n => !!v[n])))) ? 
                    <>{children}</> : (
                        <ErrorMessage
                            error='Sorry, there is no data available for this chart.'
                        />
                    )}
                {(editModalOpen && !!chartContext.getYearKeyVariable()) && (
                    <YearModal
                        variable={chartContext.getYearKeyVariable()!}
                        onClose={(years, variable, testType) => {
                            setEditModalOpen(false);
                            queryContext.updateYear(years, testType, variable);
                        }}
                        query={queryContext.dataConfig.queries[0] as V2QuantumGraph}
                        dataDefRepo={queryContext.dataDefRepo || getDefaultDataDefRepo()}
                        onCancel={() => setEditModalOpen(false)}
                        singleYearOnly={!!chartContext.getYearKeyVariable() && !chartContext.isYearChart}
                    />
                )}
                {sourceModalOpen && <SourceModal variable={chartContext.valueMeta} onClose={() => setSourceModalOpen(false)} />}
            </div>
            <div className='flex bg-white px-3 py-3 border border-t-0 rounded-b shadow-lg text-gray-500'>
                <Button
                    color='light'
                    small
                    onClick={() => setSourceModalOpen(true)}
                >
                    <i className="icon-info mr-1" /> Information
                </Button>
                <div className='flex-grow' />
                {showExplore && <Button
                    color='light'
                    small
                    className='mr-2'
                    onClick={() => explore(navigate, queryContext.getGH(true, true, true).build() as V2QuantumGraph, queryContext.dataConfig, parameter)}
                >
                    Explore Data
                </Button>}
                {chartContext.isXAxisYearVariable && <i className='icon-edit mr-2 mt-1 hover:cursor-pointer' onClick={() => setEditModalOpen(true)} />}
                <DownloadButton
                    metadata={queryContext.metadata}
                    onDownload={() => downloadGraph(queryContext.dataConfig.queries[0], 0, getDefaultDataDefRepo())}
                    onGenerate={() => queryContext.getGH(true, true, true).build()}
                />
            </div>
        </div>
    );
});

export default ChartContainer;
