import React, {useEffect, useState} from 'react';
import Loading from '../loading';
import ErrorMessage from '../error-message';
import ReactMarkdown from 'react-markdown';
import fetchContent from './fetch-content';
import rehypeRaw from 'rehype-raw';
import transformUri from './transform-uri';


type Props = {
    file: string,
}

export default function MarkdownContent(props: Props) {
    const [loading, setLoading] = useState(true);
    const [md, setMd] = useState('');
    const [err, setErr] = useState('');

    useEffect(() => {
        const controller = new AbortController();
        fetchContent(props.file, controller.signal)
            .then(md => {
                setMd(md);
                setLoading(false);
            })
            .catch(err => setErr(err));
        return () => controller.abort();
    }, [props.file]);

    return <div>
        {err && <ErrorMessage error="There was a problem loading the content." />}
        {loading ?
            <div className="flex items-center">
                <Loading small />
                <div className="ml-5">Loading...</div>
            </div> :
            <div>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} transformLinkUri={transformUri}>{md}</ReactMarkdown>
            </div>}
    </div>;
}