import {useContext} from 'react';
import Badge from '../../../../components/badge';
import Button from '../../../../components/button';
import checkAccess, {SiteFeature} from '../../../../lib/check-access';
import {CanvasInsertContext} from '../../lib/context';
import {CvInsertQueryType} from '../../lib/types';

export default function QueryTypeSelector() {
    const insertStore = useContext(CanvasInsertContext);

    function nextScreen(type: CvInsertQueryType) {
        return async () => {
            insertStore.reset();
            insertStore.isAffiliationQuery = (type === CvInsertQueryType.affiliationBuilder);
            insertStore.queryType = type;
            insertStore.nextScreen();
        };
    }

    return <div>
        What kind of query do you want to insert?
        <div className='grid grid-cols-6 gap-2 mt-2'>
            <Button color="light" className='flex justify-between' onClick={nextScreen(CvInsertQueryType.hcindex)}>
                <i className="icon-notebook block text-8xl mt-1" /> 
                <span>Healthcare Index</span>
            </Button>
            <Button color="light" className='flex justify-between' onClick={nextScreen(CvInsertQueryType.blindHCIndex)}>
                <i className="icon-question block text-8xl mt-1" />
                <span>Blind Production Healthcare Index</span>
            </Button>
            <Button color="light" className='flex justify-between' onClick={nextScreen(CvInsertQueryType.affiliationBuilder)}>
                <i className="icon-group block text-8xl mt-1" />
                <span>Affiliation Builder</span>
            </Button>
            {checkAccess(SiteFeature.Admin) && <Button color="light" className='flex justify-between' onClick={nextScreen(CvInsertQueryType.query)}>
                <i className="icon-gear block text-8xl mt-1" /> 
                <span>Generic Query <Badge color="yellow" pill className="ml-3">Admin Only</Badge></span>
            </Button>}
            {checkAccess(SiteFeature.Admin) && <Button color="light" className='flex justify-between' onClick={nextScreen(CvInsertQueryType.customQuantumQuery)}>
                <i className="icon-warning block text-8xl mt-1" />
                <span>Custom Quantum Query <Badge color="yellow" pill className="ml-3">Admin Only</Badge></span>
            </Button>}
        </div>
    </div>;
}