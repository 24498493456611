import {Entity} from '../../lib/data-types';
import {PermissionModule} from '../../lib/check-module-access';
import {useEffect, useState} from 'react';
import BaseMap from '../base-map/base-map';
import MapSelectionController from './map-selection-controller';
import handleError from '../../lib/error';
import {useDeepCompareEffect} from 'use-deep-compare';
import {observer} from 'mobx-react';
import ErrorMessage from '../error-message';



type Props = {
    shape: string,
    values: Array<Entity>,
    onClick: (geoInfos: Array<Entity>) => void,
    year: string,
    renditionSize?: string,
    style?: any,
    mapStyle?: string,
    center?: { longitude: number, latitude: number },
    zoom?: number,
    fillColor?: string,
    selectionColor?: string,
    disabledColor?:string, 
    opacity?: number,
    onLoad?: (isLoading: boolean) => void
    allowedValues?: Array<Entity>
    geoRestrictionModules?: Array<PermissionModule>
}


const MapSelection = observer((props: Props)=> {
    const {shape, values, onClick, year, fillColor, style, mapStyle, selectionColor, disabledColor, opacity, renditionSize, onLoad, allowedValues, geoRestrictionModules} = props;
    const [mapSelection, setMapSelection] = useState<MapSelectionController>(new MapSelectionController(shape, year, renditionSize, fillColor, selectionColor, disabledColor, opacity, onClick, geoRestrictionModules));
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        onLoad && onLoad(loading);
    }, [loading, onLoad]);

    useDeepCompareEffect(()=>{
        if (values) {
            mapSelection.updateSelectedValues(values);
        }
    },[values, mapSelection, values.length]);


    useEffect(()=>{
        if (allowedValues)
            mapSelection.setAllowedValues(allowedValues);
    },[allowedValues]);

    useEffect(() => {
        let controller = new AbortController();
        const newMapSelection = new MapSelectionController(shape, year, renditionSize, fillColor, selectionColor, disabledColor, opacity, onClick, geoRestrictionModules, allowedValues, 'shapes');
        newMapSelection.init()
            .then(() => setMapSelection(newMapSelection))
            .catch(e => handleError(e, setErrorMessage))
            .finally(() => setLoading(false));

        return () => controller.abort();
    }, [shape, renditionSize, fillColor, selectionColor, opacity, geoRestrictionModules,allowedValues]);

    return <div>
        {mapSelection.layer && 
        <div className="w-full h-full relative"> 
            {errorMessage && <ErrorMessage error={errorMessage}/>}
            <BaseMap
                onClickCB={mapSelection.mapboxOnClickCb}
                layers={[mapSelection.layer]}
                popupOptions={mapSelection.popupOptions}
                onHoverCB={mapSelection.mouseMoveCB}
                mapStyle={mapStyle}
                style={style || {height: 400, width: 1216}}
            />
        </div>
        }
    </div>;
        


});

export default MapSelection;