import React, {useContext} from 'react';
import './toggle.scss';
import classNames from '../../lib/class-names';
import {HeaderContext} from '../header/context';
import {Toggle as CarbonToggle} from '@carbon/react';

type Props = {
    checked: boolean,
    onChange: () => void,
    color?: string,
    className?: string,
    disabled?: boolean,
    size?: 'sm' | 'md'
};

export default function Toggle(props: Props) {
    const {checked, disabled, onChange, color, className, size = 'md'} = props;
    const classes = classNames('toggle', color, className, {active: checked, disabled});
    const {useNewDesign} = useContext(HeaderContext);

    if (useNewDesign) {
        return <CarbonToggle id='toggle' className={className} disabled={disabled} toggled={checked} onToggle={onChange} hideLabel size={size} />;
    }
    
    return <label className={classes} data-testid="toggle-label">
        <input type="checkbox" className="toggle-knob" checked={checked} onChange={onChange} disabled={disabled} />
    </label>;
}