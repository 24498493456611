import {observer} from 'mobx-react';
import React, {useContext} from 'react';
import Button from '../../../components/button';
import {CanvasContext} from '../lib/context';
import CvParameterInput from './cv-parameter-input';

const CvParameters = observer(function CvParameters() {
    const store = useContext(CanvasContext);

    if (!store.board || (!store.editing && !store.board.parameter))
        return null;

    return <div>
        {store.board.parameter ?
            <div className="flex">
                <div className="ml-3">
                    <CvParameterInput />
                </div>
                {store.editing && <div className="ml-6 pt-2">
                    <Button color="link" small onClick={() => store.setupParameter = true}>
                        <i className="icon-edit" />
                    </Button>
                </div>}
            </div> :
            <em className="text-gray-500">No Parameters Defined</em>}
        {store.editing && !store.board.parameter && <div>
            <Button color="blue" onClick={() => store.setupParameter = true}>Add Parameter</Button>
        </div>}
    </div>;
});
export default CvParameters;