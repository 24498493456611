import V2QuantumGraph from 'quantum-graph/out/v2/v2-graph';
import {GraphVersions, JoinType, TestType} from 'quantum-graph';
import fetchData from '../../../lib/data';
import {QueryObj} from '../../../lib/data-types';
import {LATEST_YEAR} from './queries/datasets';

export default async function fetchRegions(regionType: string, signal: AbortSignal): Promise<Array<{name: string, value: string}>> {
    if (regionType === 'msa') {
        const query = buildMsaListQuery();
        const data = await fetchData(query, signal);
        return data.map(row => ({
            name: row['msa_division'] + ' - ' + row['msa_division_name'],
            value: String(row['msa_division']),
        }));
    } else {
        const query = buildStateListQuery();
        const data = await fetchData(query, signal);
        return data.map(row => ({
            name: String(row['state']),
            value: String(row['state_fips']),
        }));
    }
}

export function buildMsaListQuery() {
    const queryObj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: 'dim',
                tableNodes: [
                    {
                        id: 'pom',
                        dataset: 'percent-of-medicare-benchmarks-msa-division',
                        columns: [
                            'msa_division',
                            'msa_division_name',
                        ],
                    }
                ],
            },
        },
        distinct: true,
        sort: [{
            column: {nodeId: 'pom', column: 'msa_division_name'},
            asc: true,
        }],
        filters: [
            {
                column: {nodeId: 'pom', column: 'year_key'},
                test: TestType.Eq,
                value: LATEST_YEAR,
            }
        ],
    };
    return V2QuantumGraph.deserialize(queryObj);
}

export function buildStateListQuery() {
    const queryObj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: 'dim',
                tableNodes: [
                    {
                        id: 'pom',
                        dataset: 'percent-of-medicare-benchmarks-state-drg',
                        columns: [
                            'state_fips',
                        ],
                    }
                ],
            },
            joins: [{
                node: {
                    baseQuery: {
                        queryType: 'dim',
                        tableNodes: [{
                            id: 'state',
                            dataset: 'state',
                            columns: ['state'],
                            joinOn: {
                                local: 'state_fips',
                                target: 'state_fips',
                            }
                        }]
                    }
                },
                joinType: JoinType.Left,
                joinNodeId: 'pom',
            }]
        },
        distinct: true,
        sort: [{
            column: {nodeId: 'state', column: 'state'},
            asc: true,
        }],
    };
    return V2QuantumGraph.deserialize(queryObj);
}