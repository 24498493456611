import React, {useContext} from 'react';
import {CanvasContext} from '../lib/context';

type Props = {
    rowIndex: number,
    index: number,
}

export default function CvEmptyCell(props: Props) {
    const {rowIndex, index} = props;
    const store = useContext(CanvasContext);

    if (!store.editing)
        return null;

    return <div className="empty" onClick={() => store.startInsert(rowIndex, index)} />;
}