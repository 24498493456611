import OneOneOneLayout from './layout/one-one-one-layout';
import logoLight from './images/logo-full-light.svg';
import {Link} from 'react-router-dom';
import React from 'react';

export default function Footer() {
    return <div className="bg-gray-700 py-10 md:py-24">
        <div className="container">
            <OneOneOneLayout>
                <div className="flex justify-center mb-8 md:items-center md:h-full md:justify-left md:mb-0">
                    <img src={logoLight} alt="Torch Insight" style={{width: 250}} />
                </div>
                <div>
                    <div className="mb-3 font-bold text-gray-400">About</div>
                    <a className="block text-blue-200 hover:text-blue-400" href="https://torchinsight.com/legal/privacy-policy.html">Privacy Policy</a>
                    <a className="block text-blue-200 hover:text-blue-400" href="https://torchinsight.com/terms-of-use">Terms of Use</a>
                    <a className="block text-blue-200 hover:text-blue-400" href="https://medinsight.milliman.com">MedInsight</a>
                    <a className="block text-blue-200 hover:text-blue-400" href="https://www.milliman.com">Milliman</a>
                </div>
                <div>
                    <div className="mb-3 font-bold text-gray-400">More</div>
                    <a className="block text-blue-200 hover:text-blue-400" href="https://recruiting2.ultipro.com/MIL1017/JobBoard/f54234e9-dfde-b183-fd20-4fbdb19cba7a/?q=Medinsight&o=relevance">Careers</a>
                    <Link className="block text-blue-200 hover:text-blue-400" to="/docs/api">API Documentation</Link>
                    <Link className="block text-blue-200 hover:text-blue-400" to="/attribution">Attribution Notices</Link>
                    <a className="block text-blue-200 hover:text-blue-400" href="https://torchinsight.com/contact-us.html">Contact Us</a>
                </div>
            </OneOneOneLayout>
        </div>
    </div>;
}