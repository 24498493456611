import {CanvasBoardJSON, CanvasParameter, CanvasSection, CvData, CvRowType} from './types';
import {makeAutoObservable} from 'mobx';

export class CanvasBoard {
    sections: Array<CanvasSection> = [];
    parameter: CanvasParameter | undefined;

    private readonly defaultSectionTitle = 'Section title';

    constructor(sections: Array<CanvasSection>, parameter?: CanvasParameter) {
        this.sections = sections;
        this.parameter = this.buildParameterFromCanvasParameter(parameter);
        makeAutoObservable(this);
    }

    static deserialize(canvasBoardJSON: any) {
        return new CanvasBoard(canvasBoardJSON.sections, canvasBoardJSON.parameter);
    }

    serialize(): CanvasBoardJSON {
        return {
            sections: this.sections,
            parameter: this.parameter,
        };
    }

    buildParameterFromCanvasParameter(parameter?: CanvasParameter) {
        if (parameter) {
            if (!parameter.searchField && parameter.searchFields.length)
                parameter.searchField = parameter.searchFields[0].name;

            return parameter;
        }

        return undefined;
    }

    removeItem(sectionIdx: number, rowIdx: number, itemIdx: number) {
        const row = this.sections[sectionIdx].rows[rowIdx];

        if (typeof row === 'string') {
            this.sections[sectionIdx].rows.splice(rowIdx, 1);
        } else {
            let size = row[itemIdx].size;
            row[itemIdx] = {type: 'empty', size};
        }
    }

    removeRow(sectionIdx: number, rowIdx: number) {
        this.sections[sectionIdx].rows.splice(rowIdx, 1);
    }

    moveRow(sectionIdx: number, rowIdx: number, delta: number) {
        const newRows = this.sections[sectionIdx].rows.slice();
        const temp = newRows[rowIdx];
        newRows[rowIdx] = newRows[rowIdx + delta];
        newRows[rowIdx + delta] = temp;
        this.sections[sectionIdx].rows = newRows;
    }

    updateHeader(sectionIdx: number, rowIdx: number, value: string) {
        this.sections[sectionIdx].rows[rowIdx] = value;
    }

    updateText(sectionIdx: number, rowIdx: number, itemIdx: number, type: 'rich-text', value: string) {
        const row = this.sections[sectionIdx].rows[rowIdx] as CvRowType;
        const size = row[itemIdx].size;
        const conditionalRender = row[itemIdx].conditionalRender;
        row[itemIdx] = {
            type,
            size,
            value,
            conditionalRender
        };
    }

    updateCell(sectionIdx: number, rowIdx: number, cellIdx: number, data: CvData): void {
        const row = this.sections[sectionIdx].rows[rowIdx] as CvRowType;
        row[cellIdx] = data;
    }

    addRow(sectionIdx: number, content: string | CvRowType) {
        this.sections[sectionIdx].rows.push(content);
    }

    addSection() {
        let section: CanvasSection = {
            title: this.defaultSectionTitle,
            rows: []
        };
        this.sections.push(section);
    }

    moveSectionUp(sectionIdx: number) {
        if (sectionIdx > 0 && sectionIdx < this.sections.length) {
            const newSections = [...this.sections];
            [newSections[sectionIdx - 1], newSections[sectionIdx]] = [newSections[sectionIdx], newSections[sectionIdx - 1]];
            this.sections = newSections;
        }
    }
    
    moveSectionDown(sectionIdx: number) {
        if (sectionIdx >= 0 && sectionIdx < this.sections.length - 1) {
            const newSections = [...this.sections];
            [newSections[sectionIdx], newSections[sectionIdx + 1]] = [newSections[sectionIdx + 1], newSections[sectionIdx]];
            this.sections = newSections;
        }
    }
}