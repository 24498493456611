import {observer} from 'mobx-react';
import React, {useContext, useEffect, useRef} from 'react';
import {MenuContext} from './context';
import './popup.scss';


const PopupMenu = observer(() => {
    const store = useContext(MenuContext);
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (store.scroll >= 0 && popupRef.current) {
            const child = popupRef.current.children[store.scroll] as HTMLElement;
            if (child) {
                if (child.offsetTop < popupRef.current.scrollTop) // Scroll so child is at the top
                    popupRef.current.scrollTop = child.offsetTop;
                else if (child.offsetTop + child.offsetHeight > popupRef.current.scrollTop + popupRef.current.offsetHeight) // Scroll so child is at the bottom
                    popupRef.current.scrollTop = child.offsetTop - popupRef.current.offsetHeight + child.offsetHeight;
            }
        }
    }, [store.scroll]);

    useEffect(() => {
        const cancelClickHide = (e: any) => {
            if (popupRef.current && !(popupRef.current.contains(e.target) || (store.rect && document.elementFromPoint(store.rect.x, store.rect.y)?.contains(e.target)))) {
                if (store.cancelClickHide && store.visible)
                    store.hide();
            }
        };
        document.addEventListener('mousedown', cancelClickHide);

        return () => {
            document.removeEventListener('mousedown', cancelClickHide);
        };
    }, [popupRef, store]);

    if (!store.visible || !store.content || (store.content instanceof Array && !store.content.length))
        return null;

    const style: any = {[store.xAnchor]: store.x, [store.yAnchor]: store.y};
    if (store.width)
        style.width = store.width;
    if (!store.disableMaxHeight)
        style.maxHeight = '300px';
    if (store.transparentBackground) {
        style.backgroundColor = 'transparent';
        style.marginTop = 0;
        style.padding = 0;
        style.borderRadius = 0;
    }

    return <div className="popup" id="popup-menu" role="menu" aria-orientation="vertical" aria-labelledby={store.ariaLabelledBy} style={style} ref={popupRef}>
        {store.content}
    </div>;
});

export default PopupMenu;