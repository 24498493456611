import Variable from '../../lib/metadata';

export default class ProfileGroup {

    name: string;
    metadata: Array<Variable>;

    constructor(name: string, metadata: Array<Variable>) {
        this.name = name;
        this.metadata = metadata;
        this.metadata.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
    }

}