import Template from '../components/template';
import React, {useEffect, useState} from 'react';
import {insightContainer, savedQuery} from '../lib/api';
import {useParams} from 'react-router-dom';
import Loading from '../components/loading';
import handleError from '../lib/error';
import ErrorMessage from '../components/error-message';
import {DataConfig} from '../insight-container/types';
import InsightContainer from '../insight-container';
import {SavedQuery} from '../lib/data-types';
import {deserialize} from 'quantum-graph';
import AuthWall from '../components/auth-wall';


export default function InsightContainerViewPage() {
    const {id} = useParams<{id: string}>();
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [dataConfig, setDataConfig] = useState<DataConfig>({queries: []});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const controller = new AbortController();
        let options: any = {};
        insightContainer.get(Number(id), controller.signal).then(ic => {
            setType(ic.type);
            options = JSON.parse(ic.optionsJSON);
            return savedQuery.get(ic.savedQueryId, controller.signal);
        }).then((savedQuery: SavedQuery) => {
            const query = deserialize(JSON.parse(savedQuery.queryJSON));
            setName(savedQuery.name || '');
            setDataConfig({
                queries: [query],
                options,
            });
            setLoading(false);
        }).catch(err => handleError(err, setError));
    }, [id]);

    return <AuthWall>
        <Template>
            <div className="container">
                {error && <ErrorMessage error={error} />}
                {loading ?
                    <Loading /> :
                    <div style={{height: '1000px'}}>
                        <h1>{name}</h1>
                        <InsightContainer type={type} dataConfig={dataConfig} />
                    </div>}
            </div>
        </Template>
    </AuthWall>;
}