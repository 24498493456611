import {DataConfig, getConfigOption} from '../types';
import {observer} from 'mobx-react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {TransformCBs} from '../../lib/data-types';
import getDataDefRepo from '../../lib/user-data-defs';
import AGGridDataContext from '../renderers/ag-grid-data-renderer/context';
import AGGridDataStore from '../renderers/ag-grid-data-renderer/store';
import AGGridDataRenderer from '../renderers/ag-grid-data-renderer';
import CustomTooltip from './custom-tooltip';
import {QuantumGraph} from 'quantum-graph';
import TableHeader from '../../components/table-header';
import FilterBar from '../../components/filter-bar';
import {FilterChangedEvent, ModelUpdatedEvent, SortChangedEvent} from 'ag-grid-community';

type Props = {
    customTableControl?: JSX.Element,
    saveDataCB?: (query:QuantumGraph) => void
    titleOverride?: string
    height?: number
}

const AGGridTableVis = observer((props: Props) => {
    const {customTableControl, saveDataCB, titleOverride, height} = props;
    const store = useContext(AGGridDataContext);
    const gridRef = useRef<AgGridReact>(null);

    // const createDatasource: IServerSideDatasource = {
    //     getRows: (params: IServerSideGetRowsParams) => {
    //         store.setRowWindow(params.request.startRow || 0);
    //         store.updateData();
    //         store.dataPromise?.then((rows) => {
    //             params.success({
    //                 rowData: rows,
    //                 rowCount: store.page * store.blockSize
    //             });
    //         });
    //     }
    // };

    // useEffect(() => {
    //     return () => {
    //         if (gridRef.current && gridRef.current.api && !gridRef.current.api.isDestroyed())
    //             gridRef.current.api.destroy();
    //     };
    // }, [store.columnDefinitions, store.data]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 100,
        tooltipComponent: CustomTooltip
    }), []);

    const onFilterChanged = useCallback((params: FilterChangedEvent) => {
        if (params.api && !params.api.isDestroyed())
            store.filters = params.api.getFilterModel();
    }, []);

    const onSortChanged = useCallback((params: SortChangedEvent) => {
        if (params.api && !params.api.isDestroyed()) {
            const cols = params.api.getAllGridColumns();
            store.sorts = cols.filter(col => col.getSort()).map(col => {
                const variable = store.metadata.find(v => v.name === col.getColDef().field)!;
                return [variable, col.getSort() === 'asc'];
            });
        }
    }, []);

    const onModelUpdated = useCallback((params: ModelUpdatedEvent) => {
        if (params.api && !params.api.isDestroyed())
            store.count = params.api.getDisplayedRowCount();
    }, []);

    const hideTableHeader: boolean = getConfigOption<boolean>(store.dataConfig, 'hideTableHeader')!;
    const isCanvas: boolean = getConfigOption(store.dataConfig, 'isCanvas')!;
    const queryTitle: string = useMemo(() => props.titleOverride || getConfigOption(store.dataConfig, 'title') || '', [titleOverride, store.dataConfig]);
    const hideDownload: boolean = getConfigOption(store.dataConfig, 'hideDownload') || false;
    const hideSave: boolean = getConfigOption(store.dataConfig, 'hideSave') || false;

    return <div className="ag-theme-quartz" style={{height: height || 500}}>
        <FilterBar
            filters={store.filters}
            sorts={store.sorts}
            queryName={queryTitle}
        />
        {!hideTableHeader && (
            <TableHeader
                store={store}
                onlyShowDownload={isCanvas}
                hideDownload={hideDownload}
                hideSave={hideSave}
                saveDataCB={saveDataCB}
                title={queryTitle}
            >
                {customTableControl}
            </TableHeader>
        )}
        <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            columnDefs={store.columnDefinitions}
            rowData={store.data}
            tooltipShowDelay={500}
            tooltipInteraction
            animateRows
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            onModelUpdated={onModelUpdated}
        />
        <div className="w-full bg-white p-3 flex items-center border-t border-gray-300 rounded-md">
            <p>{Number(store.count).toLocaleString()} records</p>
        </div>
    </div>;
});

export {AGGridTableVis};

export function AgGridTableContext(props: {
    dataConfig: DataConfig,
    children: JSX.Element,
    loadCB?: (promise: Promise<void>) => void,
    transforms?: TransformCBs,
    pageSize?: number
}) {
    const {dataConfig, loadCB, transforms} = props;
    const [setup, setSetup] = useState(false);
    const [agGridDataStore, setStore] = useState<AGGridDataStore | null>(null);

    // Create the store the first time
    useEffect(() => {
        if (!setup) {
            const controller = new AbortController();
            getDataDefRepo(controller.signal).then(dataDefRepo => {
                setStore(new AGGridDataStore(dataConfig, dataDefRepo, dataConfig.options.blockSize));
                setSetup(true);
            });
            return () => controller.abort();
        }
    }, [setup, dataConfig]);

    // Update the store subsequent updates
    useEffect(() => {
        if (setup) {
            const isDiff = JSON.stringify(dataConfig) !== JSON.stringify(agGridDataStore?.dataConfig);
            if (isDiff)
                agGridDataStore?.updateConfig(dataConfig);
        }
    }, [setup, dataConfig, agGridDataStore]);

    if (!agGridDataStore || !setup)
        return null;

    return <AGGridDataContext.Provider value={agGridDataStore}>
        <AGGridDataRenderer loadCB={loadCB} transforms={transforms}>
            {props.children}
        </AGGridDataRenderer>
    </AGGridDataContext.Provider>;
}

export default function AgGridTableRenderer(props: {
    dataConfig: DataConfig,
    loadCB?: (promise: Promise<void>) => void
}) {
    return <AgGridTableContext dataConfig={props.dataConfig} loadCB={props.loadCB}>
        <AGGridTableVis />
    </AgGridTableContext>;
}