import {useEffect, useState} from 'react';
import ContentSection, {MyContentSection} from './content-section';
import {Star} from '@carbon/icons-react';
import {content} from '../../../lib/api';
import {MyFavorite} from '../../../lib/data-types';
import handleError from '../../../lib/error';

export default function MyFavorites() {
    const [loading, setLoading] = useState<boolean>(true);
    const [myFavorites, setMyFavorites] = useState<Array<MyContentSection>>([]);
    //eslint-disable-next-line
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        content.myFavorites.listTop(controller.signal).then(favoritesList => {
            setMyFavorites(favoritesList.map((favorite: MyFavorite) => ({title: favorite.pageName, url: favorite.url})));
        }).catch(e => handleError(e, setError)).finally(() => setLoading(false));

        return () => controller.abort();
    }, []);

    return <ContentSection title="My Favorites" content={myFavorites} contentIcon={<Star />} viewAllUrl={'/user/favorites'} loading={loading} />;
}