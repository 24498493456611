type Props = {
    name: string,
    subText: string,
    required: boolean,
    index: number
}

export default function StepLabel(props: Props) {
    const {name, subText, required, index} = props;

    return <div className="w-full flex space-x-2 py-1 items-center mb-5">
        <div className="flex space-x-5 p-1">
            <div className="font-bold">{required && <span>*</span>} Step {index + 1}: {name}</div>
            <div className="text-gray-500">{subText}</div>
        </div>
    </div>;
}