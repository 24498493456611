import {useEffect, useState} from 'react';
import InsightContainer from '../index';
import Variable from '../../lib/metadata';
import {createEntityAffiliationQuery} from './entity-query';
import {DataConfig} from '../types';
import Loading from '../../components/loading';
import {getDefaultDataDefRepo} from '../../lib/data-defs';

type Props = {
    dataset: string,
    entityId?: number,
    idVar?: Variable
}

export default function EntityAffiliationTable(props: Props) {
    const {dataset, entityId, idVar} = props;
    const [config, setConfig] = useState<DataConfig>();

    useEffect(() => {
        const controller = new AbortController();
        if (entityId && idVar) {
            createEntityAffiliationQuery(dataset, entityId, idVar, getDefaultDataDefRepo(), controller).then(graph => {
                const dataConfig: DataConfig = {
                    queries: [graph],
                    options: {
                        hideExplore: true
                    }
                };
                setConfig(dataConfig);
            });
        }

        return () => controller.abort();
    }, [dataset, entityId, idVar]);

    return <div className="mt-3">
        {!config && <Loading />}
        {config && <InsightContainer type="table" dataConfig={config} />}
    </div>;
}