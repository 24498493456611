import React, {useEffect, useState} from 'react';
import {useDeepCompareCallback} from 'use-deep-compare';
import Autocomplete from '../autocomplete';
import {affiliatedEntitySearch, entitySearch, getEntityName} from '../../lib/entity-search';
import Loading from '../loading';
import {QueryFilter} from '../../lib/data-types';

type Props = {
    dataset: string,
    placeholder?: string,
    value: number,
    onChange: (entity: {id: number, name: string}) => void,
    filters?: Array<QueryFilter>,
    affiliationFilter?: {dataset: string, value: string, type: string},
    getOptionsOnLoad?: boolean
    disabled?: boolean
    icon?: string
}


export default function EntityAutocomplete(props: Props) {
    const {dataset, value, placeholder, filters, affiliationFilter, getOptionsOnLoad, disabled, icon} = props;
    const [loading, setLoading] = useState(!!value);
    const [name, setName] = useState('');
    const [entities, setEntities] = useState<Array<{id: number, name: string}>>([]);

    useEffect(() => {
        if (!value && name) {
            setName('');
            return;
        } else if (!value || name)
            return;
        const controller = new AbortController();
        getEntityName(dataset, value, controller.signal).then(name => {
            setLoading(false);
            setName(name);
        });
        return () => controller.abort();
    }, [dataset, value, name]);

    const getOptions = useDeepCompareCallback(async (input: string): Promise<Array<string>> => {
        if (input.length < 3 && !getOptionsOnLoad)
            return [];
        let entities = await entitySearch(dataset, input, new AbortController().signal, filters);
        if (affiliationFilter) {
            const affDataset = `${affiliationFilter.dataset}:${dataset}`;
            const affiliatedEntities = await affiliatedEntitySearch(affDataset, affiliationFilter.value, formatAffiliationCol(`torch_${affiliationFilter.dataset}_id`), affiliationFilter.type, new AbortController().signal);
            entities = entities.filter(e => affiliatedEntities.some(ae => e.id.toString() === ae[formatAffiliationCol(`torch_${dataset}_id`)]!.toString()));
        }
        setEntities(entities);
        return entities.map(e => e.name);
    }, [dataset, affiliationFilter, filters, getOptionsOnLoad]);

    function formatAffiliationCol(col: string) {
        return col.replace('-', '_');
    }

    const onChange = (name: string, index: number) => {
        setName(name);
        props.onChange(entities[index]);
    };

    if (loading)
        return <Loading small />;
    return <Autocomplete value={name} onChange={onChange} getOptions={getOptions} placeholder={placeholder} getOptionsOnLoad={getOptionsOnLoad} disabled={disabled} icon={icon}/>;
}