import {useContext, useEffect, useState} from 'react';
import Button from '../../../components/button';
import {modules, organizations} from '../../../lib/api';
import auth from '../../../lib/auth';
import Loading from '../../../components/loading';
import handleError from '../../../lib/error';
import {Module, Organization} from '../../../lib/data-types';
import OrgOverview from '../orgs/org-overview';
import {HeaderContext} from '../../../components/header/context';
import {CheckmarkFilled} from '@carbon/icons-react';

export default function StatusSection() {
    const [moduleList, setModuleList] = useState<Array<Module>>([]);
    const [orgs, setOrgs] = useState<Array<Organization>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const headerStore = useContext(HeaderContext);

    useEffect(() => {
        if (auth.hasRole('admin')) {
            const controller = new AbortController();
            modules.list(controller.signal).then((m: Array<Module>) => {
                m = m.filter(mod => !mod.groupName);
                setModuleList(m);
            }).catch(error => handleError(error, setErrorMessage));
        }
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        organizations.listByUser(controller.signal).then(orgs => {
            if (auth.isProxied())
                orgs = orgs.filter((org: Organization) => org.id === auth.principal!.orgs[0].id);
            orgs.forEach((org: Organization) => {
                const start = new Date(org.contractStart!).getTime();
                const end = new Date(org.contractEnd!).getTime();
                const now = new Date().getTime();
                org.active = now >= start && now <= end;
            });
            setOrgs(orgs);
        }).catch(error => handleError(error, setErrorMessage))
            .finally(() => setLoading(false));

        return () => controller.abort();
    }, []);

    function onError(error: Error) {
        handleError(error, setErrorMessage);
    }

    function legacyStatusSection() {
        return <div className="container" style={{minHeight: '65vh'}}>
            <h1>Your Modules</h1>
            <div className="w-full my-5 flex flex-col space-y-5">
                <div className="w-full bg-gray-300 rounded-2xl shadow p-5 grid grid-cols-3 gap-2 items-center place-items-center justify-around">
                    {moduleList.map((m, i) => <div key={i} className="flex flex-row items-center rounded bg-gray-50 p-2 w-full shadow">
                        <i className={`text-gray-50 p-1 rounded-sm ${auth.principal?.modules!.map(m => m.id).includes(m.id) ? 'bg-green-500 icon-ui-check' : 'bg-red-500 icon-ui-close'}`} /> 
                        <div className="ml-2">{m.name}</div>
                    </div>)}
                </div>
            </div>
            <h1>Your Organizations</h1>
            {errorMessage && <div className={'text-red-500'}>{errorMessage}</div>}
            {auth.isProxied() && <Button color={'light'} onClick={auth.unProxy}>Return to Account</Button>}
            {loading ? <Loading /> : <div className="w-full my-5 flex flex-col space-y-5">
                {!orgs.length && <p>You are not a member of any organization.</p>}
                {orgs.map((org, i) => <OrgOverview key={i} org={org} allowProxy={orgs.length > 1} current={auth.isProxied()} onError={onError}/>)}
            </div>}
        </div>;
    }

    function dppStatusSection() {
        return <div className="p-8 flex flex-col gap-8" >
            <div className='text-xl'>Your Modules</div>
            <div className="w-full">
                <div className="w-full flex gap-2 justify-start">
                    {auth.principal?.modules!.map((m, i) => <div key={i} className="text-xs flex flex-row items-center rounded-full bg-background-gray-200 pl-2 pr-1 py-1">
                        <div className="mr-1">{m.name}</div>
                        <CheckmarkFilled size={16} className='text-green-500' />
                    </div>)}
                </div>
            </div>
            <div className="bg-background-gray-300 h-px "> </div>
            <div>
                <div className='text-xl'>Your Organizations</div>
                {errorMessage && <div className={'text-red-500'}>{errorMessage}</div>}
                {auth.isProxied() && <div className="bg-background-gray-200 px-2 py-1 inline-block cursor-pointer" onClick={auth.unProxy}>Return to Account</div>}
                {loading ? <Loading /> : <div className="w-full mt-8 flex flex-col gap-5">
                    {!orgs.length && <p>You are not a member of any organization.</p>}
                    {orgs.map((org, i) => <OrgOverview key={i} org={org} allowProxy={orgs.length > 1} current={auth.isProxied()} onError={onError}/>)}
                </div>}
            </div>
        </div>;
    }

    return headerStore.useNewDesign ? dppStatusSection() : legacyStatusSection();
}