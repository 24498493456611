import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import Template from '../../../components/template';
import {organizations} from '../../../lib/api';
import {Organization} from '../../../lib/data-types';
import OrgOverview from './org-overview';
import Loading from '../../../components/loading';
import Modal from '../../../components/modal';
import OrgMembership from '../../org/membership';
import auth from '../../../lib/auth';
import handleError from '../../../lib/error';
import Button from '../../../components/button';

export default function UserOrgsPage() {
    const [orgs, setOrgs] = useState<Array<Organization>>([]);
    const [paramOrg, setParamOrg] = useState<Organization>();
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();
        organizations.listByUser(controller.signal).then(orgs => {
            if (auth.isProxied())
                orgs = orgs.filter((org: Organization) => org.id === auth.principal!.orgs[0].id);
            orgs.forEach((org: Organization) => {
                const start = new Date(org.contractStart!).getTime();
                const end = new Date(org.contractEnd!).getTime();
                const now = new Date().getTime();
                org.active = now >= start && now <= end;
            });
            setParamOrg(orgs.find((o: Organization) => o.id === parseInt(id!)));
            setOrgs(orgs);
        }).catch(error => handleError(error, setErrorMessage))
            .finally(() => setLoading(false));

        return () => controller.abort();
    }, [id]);

    function closeOrgModal() {
        navigate('/user/orgs');
    }

    function onError(error: Error) {
        handleError(error, setErrorMessage);
    }

    return <Template showFooter>
        <div className="w-full flex items-center flex-col" style={{minHeight: '65vh'}}>
            <h1>Your Organizations</h1>
            {errorMessage && <div className={'text-red-500'}>{errorMessage}</div>}
            {auth.isProxied() && <Button color={'light'} onClick={auth.unProxy}>Return to Account</Button>}
            {loading ? <Loading /> : <div className="w-1/2 my-5 flex flex-col space-y-5">
                {!orgs.length && <p>You are not a member of any organization.</p>}
                {orgs.map((org, i) => <OrgOverview key={i} org={org} allowProxy={orgs.length > 1} current={auth.isProxied()} onError={onError}/>)}
            </div>}
        </div>
        {paramOrg && <Modal className="big" title={paramOrg.name} onClose={closeOrgModal}>
            <OrgMembership modal />
        </Modal>}
    </Template>;
}