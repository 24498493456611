import Template from '../../../../components/template';
import React, {useEffect, useMemo, useState} from 'react';
import indexLogo from '../images/index.png';
import '../hc-index.scss';
import AuthWall from '../../../../components/auth-wall';
import {SiteFeature} from '../../../../lib/check-access';
import {hcIndex} from '../../../../lib/api';
import handleError from '../../../../lib/error';
import ErrorMessage from '../../../../components/error-message';
import {HCIndexSection} from '../types';
import {searchSections} from './search';
import HCIndexNav from './nav';
import HealthcareIndexSection from './section';
import SiteName from '../../../../components/SiteName';

export default function HCIndexListPage() {
    const [sections, setSections] = useState<Array<HCIndexSection>>([]);
    const [selectedSection, setSelectedSection] = useState<HCIndexSection>();
    const [searchString, setSearchString] = useState<string>('');
    const [err, setErr] = useState('');

    useEffect(() => {
        const controller = new AbortController();
        hcIndex.sections(controller.signal)
            .then((sections: Array<HCIndexSection>) => sections.filter(s => s.entries.length || s.links.length))
            .then(setSections)
            .catch(err => handleError(err, setErr));

        return () => controller.abort();
    }, []);

    const visibleSections = useMemo(() => {
        let visibleSections = selectedSection ? [selectedSection] : sections;
        if (searchString.length >= 3)
            visibleSections = searchSections(searchString, visibleSections);
        return visibleSections;
    }, [selectedSection, searchString, sections]);

    return <AuthWall siteFeature={SiteFeature.HealthcareIndex}>
        <Template showFooter>
            <div id="hc-index-page">
                <div id="hero" className="bg-blue-gradient">
                    <div><img src={indexLogo} alt="Healthcare Index"/></div>
                    <div className="mx-10">
                        <h1>Healthcare Index</h1>
                        <p>
                            A curated collection of the best of
                            <br/>
                            <SiteName />'s data and affiliations.
                        </p>
                        <input type="text" placeholder="Search healthcare indices..." value={searchString}
                            onChange={(e) => setSearchString(e.target.value)} />
                    </div>
                </div>
                <HCIndexNav sections={sections} selectedSection={selectedSection} setSelectedSection={setSelectedSection} />

                {err && <ErrorMessage />}

                <div className="container mt-10">
                    {visibleSections.map((section, i) => <HealthcareIndexSection section={section} key={i} />)}
                </div>
            </div>
        </Template>
    </AuthWall>;
}

