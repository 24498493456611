import {deserialize, QuantumGraph} from 'quantum-graph';
import {useContext, useEffect, useMemo, useState} from 'react';
import HCIndexBrowser from '../../../../components/hcindex-browser';
import Loading from '../../../../components/loading';
import {CanvasInsertContext} from '../../lib/context';
import {CvInsertQueryType} from '../../lib/types';
import CvDatasetSelector from '../cv-dataset-selector';
import ModelVisData from './model-data';
import CustomQuantumQuery from './custom-quantum-query';
import {HCIndexEntry} from '../../../tools/hc-index/types';

export default function QuerySelector() {
    const insertStore = useContext(CanvasInsertContext);
    const [loading, setLoading] = useState(false);

    function insertHCIEntry(entry: HCIndexEntry) {
        setLoading(false);
        insertStore.hcIndexReference = entry.shortName;
        insertStore.connectingFacts = entry.connectingFacts;
        insertGraph(deserialize(JSON.parse(entry.queryJSON)));
    }

    function insertGraph(graph: QuantumGraph) {
        setLoading(false);
        insertStore.query = graph;
        insertStore.nextScreen();
    }

    useEffect(() => {
        insertStore.reset();
    }, []);

    const datasetComponents:Record<CvInsertQueryType, JSX.Element> = useMemo(() => ({
        [CvInsertQueryType.hcindex]: <HCIndexBrowser onSelect={insertHCIEntry} />,
        [CvInsertQueryType.blindHCIndex]: <HCIndexBrowser onSelect={insertHCIEntry} pullBlindProd />,
        [CvInsertQueryType.affiliationBuilder]: <ModelVisData />,
        [CvInsertQueryType.customQuantumQuery]: <CustomQuantumQuery />,
        [CvInsertQueryType.query]: <CvDatasetSelector setQuery={insertGraph} />
    }), []);

    let datasetScreen = useMemo(() => datasetComponents[insertStore.queryType || CvInsertQueryType.hcindex], [insertStore.queryType]);

    return <div className='h-auto overflow-auto mb-4 flex-auto'>
        {loading ? <Loading /> : datasetScreen}
    </div>;
}