import {observer} from 'mobx-react';
import Modal from '../../../components/modal';
import {useContext, useEffect, useState} from 'react';
import {CanvasContext} from '../lib/context';
import {canvas} from '../../../lib/api';
import Loading from '../../../components/loading';
import Button from '../../../components/button';
import {useLocation, useNavigate} from 'react-router-dom';


const VersionModal = observer(() => {
    const store = useContext(CanvasContext);
    const [canvasVersions, setCanvasVersions] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let controller = new AbortController();
        setLoading(true);
        canvas.getCanvasVersions(store.boardId, controller.signal)
            .then((versions) => setCanvasVersions(versions))
            .finally(() => setLoading(false));
        return () => controller.abort();
    }, [store.boardId]);

    async function viewVersion(version: any) {
        store.showVersionModal = false;
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('versionId', version.versionId);
        navigate({search: searchParams.toString()});
        await store.loadCanvasFromBoard(version.boardJSON, new AbortController().signal);
    }


    return <Modal title="Select a version" onClose={() => store.showVersionModal = false}>
        <div className="p-3 w-full">
            <p>Select a version to view from the list. Published versions are highlighted in green.
                Applying a version will only update the current draft. It will still need to be published.</p>
            {loading && <Loading/>}
            {!loading && <ul className="border shadow-xl rounded">
                <li className="p-2 bg-gray-200 flex">
                    <div className="w-1/4">Version ID</div>
                    <div className="w-1/4">Date Created</div>
                    <div className="w-1/4">Published</div>
                    <div className="w-1/4">Preview</div>
                </li>
                {canvasVersions.map((version, i) => <li key={i} className={`border p-2 ${version.published ? 'bg-green-100' : (i % 2 !== 0 ? 'bg-gray-100' : 'bg-white')} flex items-center`}>
                    <div className="w-1/4">{version.versionId}</div>
                    <div className="w-1/4">{new Date(version.versionDate).toUTCString()}</div>
                    <div className="w-1/4">{version.published.toString()}</div>
                    <div><Button color="blue" onClick={() => viewVersion(version)}>View</Button></div>
                </li>)}
            </ul>}
        </div>
    </Modal>;
});

export default VersionModal;