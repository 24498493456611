import React, {ReactNode} from 'react';

type Props = {
    children: [ReactNode, ReactNode, ReactNode],
};

export default function OneOneOneLayout(props: Props) {
    const [child1, child2, child3] = props.children;
    return <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
            {child1}
        </div>
        <div className="mt-5 md:mt-0 md:col-span-1">
            {child2}
        </div>
        <div className="mt-5 md:mt-0 md:col-span-1">
            {child3}
        </div>
    </div>;
}