import {deserialize, GraphVersions} from 'quantum-graph';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import {DataDefRepo} from '../../lib/user-data-defs';
import fetchData from '../../lib/data';
import {QueryObj} from '../../lib/data-types';

export async function loadYears(dataset: string, dataDefRepo: DataDefRepo, signal: AbortSignal): Promise<Array<number>> {
    const dsDef = dataDefRepo.datasetByName[dataset];
    if (!dsDef)
        return [];
    if (!dsDef.tableGrain.includes('year_key'))
        return [];

    const obj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: QueryType.Dim,
                tableNodes: [{
                    id: dataset,
                    dataset,
                    columns: ['year_key'],
                }],
            },
        },
        distinct: true
    };
    const yearQuery = deserialize(obj);
    const data = await fetchData(yearQuery, signal);
    return data.map(row => Number(row.year_key));
}

export async function loadAllYears(datasets: Array<string>, dataDefRepo: DataDefRepo, signal: AbortSignal): Promise<Array<number>> {
    const years = new Set<number>();
    for (const dataset of datasets) {
        const dsYears = await loadYears(dataset, dataDefRepo, signal);
        dsYears.forEach(y => years.add(y));
    }

    return Array.from(years).sort((a, b) => a - b);
}
