/* eslint-disable @typescript-eslint/no-unused-vars */
import Template from '../../components/template';
import AuthWall from '../../components/auth-wall';
import AdditionalProvidersTable from '../network-optimization/components/additional-providers-table';
import {Theme} from '@carbon/react';

// const queries = [
//     deserialize({
//         'version': 2,
//         'baseQueryGraph': {
//             'baseQuery': {
//                 'queryType': 'scaffold',
//                 'tableNodes': [
//                     {
//                         'id': 'facility',
//                         'dataset': 'facility',
//                         'columns': [
//                             'torch_facility_id',
//                             'facility_name',
//                             'category',
//                             'category_subtype',
//                             'city_name',
//                             'abbreviated_state',
//                             'ownership_type',
//                             'provider_number',
//                             'owner_torch_health_system_id',
//                             'cost_reporting_category',
//                             'milliman_hospital_type',
//                             'termination_or_expiration_date_key'
//                         ]
//                     },
//                     {
//                         'id': 'hospital-cost-report-beds',
//                         'dataset': 'hospital-cost-report-beds',
//                         'columns': [
//                             'beds'
//                         ]
//                     },
//                     {
//                         'id': 'hospital-cost-report-total-revenue',
//                         'dataset': 'hospital-cost-report-total-revenue',
//                         'columns': [
//                             'net_patient_revenue',
//                             'total_patient_revenue'
//                         ]
//                     },
//                     {
//                         'id': 'structural-measures',
//                         'dataset': 'structural-measures',
//                         'columns': [
//                             'hospital_star_rating'
//                         ]
//                     },
//                     {
//                         'id': 'value-based-purchasing',
//                         'dataset': 'value-based-purchasing',
//                         'columns': [
//                             'total_performance_score'
//                         ]
//                     },
//                     {
//                         'id': 'hospital-cost-report-calculated-metrics',
//                         'dataset': 'hospital-cost-report-calculated-metrics',
//                         'columns': [
//                             'bed_utilization',
//                             'average_length_of_stay',
//                             'average_daily_census',
//                             'percent_revenue_medicare',
//                             'percent_revenue_medicaid',
//                             'percent_revenue_commercial',
//                             'operating_margin'
//                         ]
//                     }
//                 ],
//                 'scaffoldField': 'year_key',
//                 'scaffoldType': ''
//             },
//             'joins': []
//         },
//         'sort': [
//             {
//                 'column': {
//                     'nodeId': 'facility',
//                     'column': 'torch_facility_id'
//                 },
//                 'asc': true
//             },
//             {
//                 'column': {
//                     'nodeId': 'facility_scaffold',
//                     'column': 'year_key'
//                 },
//                 'asc': false
//             }
//         ],
//         'filters': [
//             {
//                 'type': 'basic',
//                 'column': {
//                     'column': 'category',
//                     'nodeId': 'facility'
//                 },
//                 'test': '=',
//                 'value': 'Hospital'
//             },
//             {
//                 'type': 'basic',
//                 'column': {
//                     'column': 'cost_reporting_category',
//                     'nodeId': 'facility'
//                 },
//                 'test': '=',
//                 'value': 'Reported Recently'
//             },
//             {
//                 'type': 'basic',
//                 'column': {
//                     'column': 'termination_or_expiration_date_key',
//                     'nodeId': 'facility'
//                 },
//                 'test': 'is null',
//                 'value': ''
//             },
//             {
//                 'type': 'basic',
//                 'column': {
//                     'nodeId': 'facility',
//                     'column': 'facility_name'
//                 },
//                 'test': 'in',
//                 'value': [
//                     'Southeastern Regional Medical Center, Inc.',
//                     'Intermountain Hospital',
//                     'Ucsf Medical Center',
//                     'Harborview Medical Center'
//                 ]
//             },
//             {
//                 'type': 'basic',
//                 'column': {
//                     'nodeId': 'hospital-cost-report-calculated-metrics',
//                     'column': 'average_daily_census'
//                 },
//                 'test': 'is not null',
//                 'value': ''
//             }
//         ],
//         'transforms': [],
//         'columnOrder': []
//     }
    
    
    
//     )
// ];

export default function TestPage() {

    return <Template>
        <AuthWall>
            <div className='h-full w-full'>
                {/* <NetworkMap /> */}
                <Theme theme='white'>
                    <AdditionalProvidersTable />
                </Theme>
            </div>
        </AuthWall>
    </Template>;
}
