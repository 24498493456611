import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import * as H from 'history';
import Template from '../../components/template';
import auth from '../../lib/auth';


type Props = {
    location?: H.Location<{dest: string}>,
}

export default function AuthPage(props: Props) {
    const {location} = props;
    const [dest] = useState(location && location.state ? location.state.dest : '/');
    const [action, setAction] = useState('');

    useEffect(() => {
        const listener = (m: MessageEvent) => {
            if (m.origin.endsWith(String(process.env.REACT_APP_TI_DOMAIN))) {
                const token = m.data;
                if (!token)
                    setAction('failure');
                else {
                    auth.setToken(token);
                    setAction('success');
                }
            }
        };
        window.addEventListener('message', listener);

        return function cleanup() {
            window.removeEventListener('message', listener);
        };
    });


    if (action === 'failure')
        return <Navigate replace to="/login" state={dest} />;
    if (action === 'success')
        return <Navigate replace to={dest || '/'}/>;


    return <Template>
        <div className="container">
            <iframe src={'//' + process.env.REACT_APP_TI_DOMAIN + '/auth-transfer'} frameBorder="0" width={0} height={0} title="Torch Insight Auth Check" />
        </div>
    </Template>;
}