import React, {useContext, useEffect, useRef, useState} from 'react';
import './select-menu.scss';
import classNames from '../../lib/class-names';
import {originatesFromMenu} from '../popup/util';
import {Popover, PopoverContent} from '@carbon/react';
import {HeaderContext} from '../header/context';

type Props = {
    label: string,
    children: React.ReactNode | Array<React.ReactNode>
};

export default function SelectMenu(props: Props) {
    const {label} = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const {useNewDesign} = useContext(HeaderContext);

    useEffect(() => {
        const listener = (e: MouseEvent) => {
            const isFromMenu = originatesFromMenu(e.target as HTMLElement);
            const isFromButton = buttonRef.current === e.target;
            if (!isFromMenu && !isFromButton) {
                setMenuOpen(false);
            }
        };
        window.addEventListener('click', listener);
        return () => window.removeEventListener('click', listener);
    }, []);

    if (useNewDesign) {
        return <Popover className='!inline' open={menuOpen}>
            <div className='cds--select'>
                <div className='cds--select-input__wrapper'>
                    <button ref={buttonRef} className='cds--select-input items-center !flex' onClick={() => setMenuOpen(!menuOpen)} >
                        <div>{label}</div>
                    </button>
                    <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="currentColor" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" className="cds--select__arrow" xmlns="http://www.w3.org/2000/svg"><path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z"></path></svg>
                </div>
            </div>
            <PopoverContent onClick={e => e.stopPropagation()} >
                {props.children}
            </PopoverContent>
        </Popover>;
    }

    return <div className="relative inline-block">
        <div className="select-holder">
            <button className={classNames('select', {'is-darkened': menuOpen})} ref={buttonRef}
                onClick={() => setMenuOpen(!menuOpen)} aria-controls="popup-menu" aria-expanded={menuOpen ? 'true' : 'false'}>
                {label}
            </button>
        </div>

        {menuOpen && <div className="select-menu-popup" onClick={e => e.stopPropagation()}>
            {props.children}
        </div>}
    </div>;
}