import {analytics} from './api';
import handleError from './error';

export default function saveEvent(name: string, payload: any) {
    payload.platform = 'app-ti';
    const event = {
        payload,
        timeZone: new Date().getTimezoneOffset(),
    };

    analytics.save(name, event)
        .catch(err => handleError(err, null));
}