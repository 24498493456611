import {TestType} from 'quantum-graph';
import Variable from '../../../lib/metadata';
import {RowCountOperators} from './row-count-compare';
import {ReactElement} from 'react';

export type ParameterConfig = {
    parameterField: string,
    dataModelField: string,
    modelDataset: string,
    test: TestType
};

export type CanvasParameter = {
    query: object,
    idField: string,
    searchField: string,
    searchFields: Array<Variable>,
    useFuzzySearch: boolean
    minSearchChars?:number
    customKeyDelay?:number,
    displayPattern?:string
}

export type CanvasBoardJSON = {
    sections: Array<CanvasSection>,
    parameter?: CanvasParameter,
}

export type CanvasSection = {
    title: string
    rows: Array<CvRowType | string>
    conditionalRenderRows?: Array<ConditionalRender | undefined>
}

export type CvRowType = Array<CvRichText | CvData | CvEmpty>

export type ConditionalRender = {
    queryObj: any,
    operator: RowCountOperators,
    rowCount: number,
    showLoading: boolean,
    parameterConfigs?: Array<ParameterConfig>,
    alternateIC?: CvRichText | CvData
}

export type CvRichText = {
    type: 'rich-text',
    size: string,
    value: string,
    conditionalRender?: ConditionalRender
}

export type CvData = {
    type: 'data',
    size: string
    id: number,
    queryObj?: any,
    visType?: string,
    parameterConfigs?: Array<ParameterConfig>,
    options?: any,
    conditionalRender?: ConditionalRender
}

export type CvEmpty = {
    type: 'empty',
    size: string,
    conditionalRender?: ConditionalRender
}

export type CanvasType = {
    id?: number,
    name: string,
    title?: string,
    boardJSON: string,
    userId?: number,
    orgId?: number,
}

export type CanvasSectionType = {
    title: string,
    links: Array<CanvasLink>
    landingPage?:boolean;
}

export type CanvasLink = {
    url:string,
    title:string
}

export enum RowLayouts {
    xlg = 'xlg',
    md_md = 'md-md',
    sm_sm_sm = 'sm-sm-sm',
    xs_xs_xs_xs = 'xs-xs-xs-xs',
    lg_sm = 'lg-sm',
    sm_lg = 'sm-lg',
    lg_xs = 'lg-xs',
    xs_lg = 'xs-lg',
    md_xs_xs = 'md-xs-xs',
    xs_xs_md = 'xs-xs-md',
    xs_md_xs = 'xs-md-xs'
}




export enum CvInsertScreen {
    'type',
    'queryType',
    'querySelect',
    'model',
    'configVis',
    'configText',
    configOwnership,
    'mapQuerySelection',
    'columnConfig',
    'mapConfig',
    configSingleValue,
    'configSankey',
    'conditionalRender',
    'configInaccessible'
}




export enum CvInsertVisType {
    'text' = 'text',
    'richText' = 'richText',
    'query' = 'query',
    'pie' = 'pie',
    'plot' = 'plot',
    'bar' = 'bar',
    'entityAffiliations' = 'entity-affiliations',
    'profile' = 'profile',
    'parameter' = 'parameter',
    dataText = 'custom-text',
    ownership = 'network',
    'map' = 'map',
    singleValue = 'single-value',
    'sankey' = 'sankey',
    'conditionalRender' = 'conditional-render',
    'scatter' = 'scatter'
}

export enum CvInsertQueryType {
    'hcindex',
    'query',
    'affiliationBuilder',
    'customQuantumQuery',
    'blindHCIndex'
}

export type ICConfig = {
    type: CvInsertVisType;
    label: string;
    icon: string;
    screens: Array<CvInsertScreen>,
    adminOnly?: boolean,
    hide?: boolean,
    componentIcon?:ReactElement,
}

export type PointLayerOptions = {
    color:string;
    hover:boolean;
    column?:string;
    columnLabel?:string;
}

export type ChoroplethOptions = {
    highColor: string;
    lowColor: string;
    column: string;
    minMax: number[]
}

export type LegendOptions = {
    choropleth: ChoroplethOptions;
    points: PointLayerOptions[];
}

export enum InaccessibleViewType {
    'hideVariables' = 'hideVariables',
    'hideIC' = 'hideIC',
    'disableIC' = 'disableIC',
}