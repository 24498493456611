import React from 'react';

type Props = {
    dashboard: string | number,
    name: string,
    description: string,
};

export default function DashboardLink(props: Props) {
    return <a href={'//' + process.env.REACT_APP_TI_DOMAIN + '/dashboard/' + props.dashboard} className="hover:no-underline hover:text-blue-700 flex items-center mt-2">
        <div className="mr-4">
            <div className="w-5 h-5 rounded-full border-2 border-blue-400 border-blue" />
        </div>
        <div>
            <span className="block font-bold">{props.name}</span>
            <span className="block font-light">{props.description}</span>
        </div>
    </a>;
}