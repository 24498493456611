import {BasicSavedDataType, GroupedSavedData} from './types';
import {insightContainer, savedQuery} from '../../lib/api';


export const savedDataTypes = [
    'saved-query',
    'cr-benchmarks',
    'common-core',
    'profile',
    'bar',
    'plot',
];

function groupSavedData(savedDataList: Array<BasicSavedDataType>): GroupedSavedData {
    const grouped: GroupedSavedData = {};
    for (const savedData of savedDataList) {
        const dataType = savedData.type || 'saved-query';
        if (savedDataTypes.includes(dataType)) {
            if (!grouped[dataType])
                grouped[dataType] = [];
            grouped[dataType].push(savedData);

            // Remove the saved queries for included insight containers
            if (dataType !== 'saved-query' && grouped['saved-query']) {
                const queryIndex = grouped['saved-query'].findIndex(q => q.id === savedData.savedQueryId);
                if (queryIndex >= 0)
                    grouped['saved-query'].splice(queryIndex, 1);
            }
        }
    }
    return grouped;
}

export default async function loadSavedData(signal: AbortSignal): Promise<GroupedSavedData> {
    const queries: Array<BasicSavedDataType> = await savedQuery.list.forUser(signal);
    const containers: Array<BasicSavedDataType> = await insightContainer.list.forUser(signal);
    return groupSavedData(queries.concat(containers));
}