import  {
    MultiQueryRenderChildProps,
} from '../renderers/multi-query-data-renderer';
import {DataConfig} from '../types';
import BaseMap from '../../components/base-map/base-map';
import {QuantumQuery} from '../../lib/data-types';
import {FeatureCollection, Feature} from 'geojson';
import {useMemo, useState, useEffect, useCallback} from 'react';
import {Layers} from '../../components/base-map/layers/types';
import {useGeoData} from './map-libs/map-utils';
import Loading from '../../components/loading';
import {Expression, FillPaint} from 'mapbox-gl';
import createGraphHandler from '../../lib/graph-tools/graph-handler/create';
import MapOptions from '../../pages/canvas/lib/mapOptions';
import {observer} from 'mobx-react';
import Legend from './legend';
import {getDefaultDataDefRepo} from '../../lib/data-defs';
import SerialMultiQueryRenderer from '../renderers/serial-multi-query-data-renderer';

const NewMapVis = observer((props: MultiQueryRenderChildProps)=> {

    const {queries, dataConfig} = props;
    const mapOptionsObj = dataConfig.options.mapOptions;
    const [choroplethQuery, ...pointsQueries] = queries;   
    const [mapOptions, setMapOptions] = useState(new MapOptions(
        mapOptionsObj.mapShape,
        mapOptionsObj.choroplethColumn,
        mapOptionsObj.highColor,
        mapOptionsObj.lowColor,
        mapOptionsObj.choroHover,
        mapOptionsObj.pointOptions
    ));
    

    useEffect(()=>{
        setMapOptions(new MapOptions(
            mapOptionsObj.mapShape,
            mapOptionsObj.choroplethColumn,
            mapOptionsObj.highColor,
            mapOptionsObj.lowColor,
            mapOptionsObj.choroHover,
            mapOptionsObj.pointOptions
        ));
    },[mapOptionsObj]);

    const {choroplethColumn, highColor, lowColor, mapShape: shape, choroHover, pointOptions} = mapOptions;
    
    const shapes:any = useGeoData(shape, '2019', 'sm');


    useEffect(()=>{
        mapOptions.formatQueryData(queries);
    },[queries, mapOptions]);

    useEffect(() => {
        if (choroplethQuery && shapes) {
            mapOptions.applyColumnToShapes(choroplethQuery, shapes, choroplethColumn);
        }
    }, [choroplethQuery, shapes, choroplethColumn, shape]);


    function formatGeoData(query:QuantumQuery): FeatureCollection {
        const features:Feature[] = [];
        query.data.forEach((d)=>{
            features.push({
                type: 'Feature',
                geometry: {type: 'Point', coordinates: [Number(d.longitude), Number(d.latitude)]},
                properties: d,
                id: String(d.facility_name)
            });
        });

        const output:FeatureCollection = {
            type: 'FeatureCollection',
            features: features
        };
        output.features = features;
        return output;
    }

    const generatePointPaint = (hexColor: string): FillPaint => {
        return {
            'fill-color': hexColor,
            'fill-opacity': 0.6, // you can adjust this as needed
        };
    };

    const pointsLayers = useMemo(() => {
        const data = pointsQueries.map((q) => formatGeoData(q));
        return data.map((d, i) => {
            return {
                id: `points-${i}`,
                layerType: Layers.point,
                data: d,
                paint: generatePointPaint(pointOptions[i].color)
            };
        });
    }, [pointsQueries]);

    function generateChoroPaint(column: string, min:number, max:number) {
             
        const paint: { 'fill-color': Expression; 'fill-opacity': number } = {
            'fill-color': [
                'interpolate',
                ['linear'],
                ['get', column], 
                min,
                lowColor, 
                max,
                highColor 
            ] as Expression,
            'fill-opacity': 0.8
        };
        return paint;
    }

    const interactivePointLayers: string[] = useMemo(() => 
        pointOptions
            .filter((o) => o.hover)
            .map((o, i) => `points-${i}`),
    [pointOptions]
    );



    if(!mapOptions.choroFeatureCollection) {
        return <Loading/>;
    }
    
    return (
        <div className="flex bg-white mt-3 border rounded-t text-gray-500">
            <div className="w-3/4">
                <BaseMap
                    layers={[
                        ...pointsLayers,
                        {id: 'choropleth', layerType: Layers.choropleth, data: mapOptions.choroFeatureCollection , paint: generateChoroPaint(choroplethColumn,mapOptions.choroMinMax[0],mapOptions.choroMinMax[1])},
                    ]}
                    onHoverCB={mapOptions.onHover}
                    interactiveLayers={[...interactivePointLayers,'choropleth']}
                    dynamicBounds={true}
                    popupOptions={choroHover && mapOptions.popupOptions}
                    style={{width: '100%', height: '600px'}}
                />
            </div>
            <div className="w-1/4 bg-gray-100">
                <Legend mapOptions={mapOptions} />
            </div>
        </div>);
        


});


export default function NewMapRenderer(props: {
    dataConfig: DataConfig
    loadCB?: (promise: Promise<void>) => void
}) {

    const buildGraph = useCallback(function buildGraphWithoutPag(dataConfig:DataConfig) {
        const unpaginatedQueries = dataConfig.options.mapOptions.mapQueries.map((q:any)=>{
            return createGraphHandler(q, getDefaultDataDefRepo()).removePagination().build();
        });

        console.log(unpaginatedQueries);
        
        return {...dataConfig, queries: unpaginatedQueries};

    },[]);

    return (
        <SerialMultiQueryRenderer dataConfig={buildGraph(props.dataConfig)} loadCB={props.loadCB}>
            {NewMapVis}
        </SerialMultiQueryRenderer>
    );
}



