import Map, {LngLatBoundsLike, MapLayerMouseEvent, MapRef, NavigationControl, PointLike, Popup, ViewState} from 'react-map-gl';
import {useCallback, useRef} from 'react';
import mapboxConfig from '../../insight-container/map/map-libs/mapbox-config';
import {LayerPropsWithType} from './layers/types';
import LayerFinder from './layers';
import {bbox} from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';

type Props = {
    layers: LayerPropsWithType[]
    onHoverCB?: (features?:any) => void;
    onClickCB?: (event:MapLayerMouseEvent) => void;
    interactiveLayers?: string[]
    popupOptions?: any
    style?: any
    mapStyle?: string
    dynamicBounds?: boolean
    viewState?: Partial<ViewState> & {
        bounds?: LngLatBoundsLike;
        fitBoundsOptions?: {
            offset?: PointLike;
            minZoom?: number;
            maxZoom?: number;
            padding?: number;
        };
    }
}

export default function BaseMap(props:Props) {
    const {layers, onHoverCB, onClickCB, interactiveLayers, popupOptions, style, mapStyle, dynamicBounds = false, viewState} = props;
    const {name, disabledText, ...rest} = popupOptions ?? {};

    const mapRef = useRef<MapRef | null>(null);

    const mapRefCallback = useCallback((ref: MapRef | null) => {
        if (ref !== null) {
            //Set the actual ref we use elsewhere
            mapRef.current = ref;
            const map = ref;
    
            const loadImage = () => {
                if (!map.hasImage('map-pin')) {
                    //NOTE ref for adding local image instead
                    map.loadImage('/assets/map/map-pin.png', (error, image) => {
                        if (error || !image) throw error;
                        map.addImage('map-pin', image, {sdf: true});
                    });
                }
            };
    
            loadImage();
    
            //TODO need this?
            map.on('styleimagemissing', (e) => {
                const id = e.id; // id of the missing image
                console.log(id);
                loadImage();
            });
        }
    }, []);

    function onMapLoad() {
        if (!mapRef.current) return;
        mapRefCallback(mapRef.current);
        if (dynamicBounds) {
            const geojsonLayer = layers.find((l) => l.layerType === 'choropleth');
            if (geojsonLayer && geojsonLayer.data) {
                const bounds = bbox(geojsonLayer.data);
                const map = mapRef.current.getMap();
                map.fitBounds([[bounds[0], bounds[1]], [bounds[2], bounds[3]]]);
            }
        }
    }
    

    const defaultStyle = {width: 800, height: 600};

    const initialViewState = {
        longitude: mapboxConfig.defaultLongitude,
        latitude: mapboxConfig.defaultLatitude,
        zoom: mapboxConfig.defaultZoom,
        maxZoom: 6,
        minZoom: 2.2
    };

    return (
        <Map
            style={style || defaultStyle}
            mapStyle={mapStyle || mapboxConfig.defaultStyle}
            mapboxAccessToken={mapboxConfig.accessToken}
            onLoad={onMapLoad}
            ref={mapRef}
            onClick={onClickCB}
            interactiveLayerIds={interactiveLayers || [String(layers[0].id)]}
            onMouseMove={(e)=>{
                if (!mapRef.current) return;
                const features = mapRef.current.queryRenderedFeatures(e.point, {
                    layers: interactiveLayers || [String(layers[0].id)]
                });
                onHoverCB && onHoverCB(features);
            }}
            initialViewState={viewState || initialViewState}
            attributionControl={false}
        >
            {layers.map((l) => (
                <LayerFinder key={l.id} {...l} />
            ))}
            {popupOptions && (
                <Popup {...rest} closeButton={false} closeOnClick={false}>
                    <p className='text-sm'>{name}</p>
                    {disabledText && <p className='text-sm'>{disabledText}</p> }
                </Popup>
            )}
            <NavigationControl position='top-left'/>
        </Map>
    );
}
