import auth from './auth';

export enum SiteFeature {
    Search,
    Discovery,
    HealthcareIndex,
    MarketSelection,
    Dashboards,
    CreateDashboards,
    ClassicMap,
    PopHealthMap,
    HospitalDRGLookup,
    HospitalDRGReport,
    PatientFlow,
    MetadataGlossary,
    Contacts,
    EOC,
    SimilarityFinder,
    BenchmarkCompare,
    HSFootprint,
    Leakage,
    AffiliationsMap,
    COVID19,
    API,
    CrmApi,
    Admin,
    Download,
    AccessSavedQueries,
    AccessCRBenchmarks,
    AccessCommonCore,
    CreateVis,
    AccessHPT,
    CrosswalkBuilder,
    AccessHCG,
    ptEnv
}

// For one-to-one mapping of site features to auth roles, define that here
const featureAuthRoleMapping: Record<number, string> = {
    [SiteFeature.Search]:               'canSearch',
    [SiteFeature.Discovery]:            'accessEntityExplorer',
    [SiteFeature.HealthcareIndex]:      'accessHealthcareIndex',
    [SiteFeature.MarketSelection]:      'listDashboards',
    [SiteFeature.Dashboards]:           'listDashboards',
    [SiteFeature.CreateDashboards]:     'createDashboard',
    [SiteFeature.ClassicMap]:           'accessMap',
    [SiteFeature.HospitalDRGLookup]:    'accessDrg',
    [SiteFeature.HospitalDRGReport]:    'accessDrg',
    [SiteFeature.PatientFlow]:          'accessPatientFlow',
    [SiteFeature.MetadataGlossary]:     'accessMetadata',
    [SiteFeature.Contacts]:             'queryContacts',
    [SiteFeature.EOC]:                  'queryEOC',
    [SiteFeature.SimilarityFinder]:     'accessBenchmark',
    [SiteFeature.BenchmarkCompare]:     'accessBenchmark',
    [SiteFeature.HSFootprint]:          'accessInfluenceMap',
    [SiteFeature.Leakage]:              'accessLeakage',
    [SiteFeature.AffiliationsMap]:      'accessLeakage',
    [SiteFeature.COVID19]:              'listDashboards',
    [SiteFeature.API]:                  'apiAccess',
    [SiteFeature.CrmApi]:               'crmApiAccess',
    [SiteFeature.Admin]:                'admin',
    [SiteFeature.Download]:             'downloadData',
    [SiteFeature.AccessSavedQueries]:   'accessSavedQueries',
    [SiteFeature.AccessCRBenchmarks]:   'accessCRBenchmarks',
    [SiteFeature.AccessCommonCore]:     'accessCommonCore',
    [SiteFeature.CreateVis]:            'createVis',
    [SiteFeature.AccessHPT]:            'accessHPT',
    [SiteFeature.CrosswalkBuilder]:     'accessCrosswalkBuilder',
    [SiteFeature.AccessHCG]:            'accessHCG',
    [SiteFeature.ptEnv]:                'ptEnv'
};

export default function checkAccess(feature: SiteFeature): boolean {
    if (auth.isExpired())
        return true; // We'll let the auth wall catch it and redirect

    const authRole = featureAuthRoleMapping[feature];
    if (authRole)
        return auth.hasRole(authRole);
    else if (feature === SiteFeature.PopHealthMap)
        return auth.hasRole('accessMap') || auth.hasRole('accessPopHealthMap');

    return false;
}
