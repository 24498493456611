import {deserialize, GraphVersions, JoinType, TestType} from 'quantum-graph';
import {getAffiliationConfig} from 'torch-affiliation-defs';
import {QueryObj, TableNodeType} from '../../../lib/data-types';
import Variable from '../../../lib/metadata';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import {DataDefRepo} from '../../../lib/user-data-defs';
import groupBy from '../../../lib/group-by';
import {AFFILIATION_DS_OVERRIDES} from 'torch-data-defs';


const affiliationConfig = getAffiliationConfig();
function getAffiliatedDataset(ds: string) {
    return AFFILIATION_DS_OVERRIDES[ds] || ds;
}

export function getDefaultColumns(vars: Array<Variable>): Array<Variable> {
    return vars.filter(v => ['id', 'name'].includes(v.getTag('FieldRole') || ''));
}

export function createTableNodes(dataset: string, idSuffix: string, vars: Array<Variable>): Array<TableNodeType> {
    const nodes: Array<TableNodeType> = [];

    // Group vars by dataset
    const grouped = groupBy(vars, v => v.dataset || 'other');

    // Add a node for each dataset, make sure we add dim first
    nodes.push({id: dataset + idSuffix, dataset, columns: grouped[dataset] ? grouped[dataset].map(v => v.name) : []});
    delete grouped[dataset];
    Object.keys(grouped).forEach(dataset =>
        nodes.push({id: dataset + idSuffix, dataset, columns: grouped[dataset].map(v => v.name)})
    );

    return nodes;
}

export function addTimeFilters(query: QueryObj, fromVars: Array<Variable>, toVars: Array<Variable>) {
    // Add in time filters
    fromVars.filter(v => v.getTag('Time') === 'year' && v.getTag('LatestTime'))
        .forEach(v => {
            query.filters?.push({
                column: {nodeId: v.dataset + '-from', column: v.name},
                test: TestType.Eq,
                value: v.getTag('LatestTime')!,
            });
        });
    toVars.filter(v => v.getTag('Time') === 'year' && v.getTag('LatestTime'))
        .forEach(v => {
            query.filters?.push({
                column: {nodeId: v.dataset + '-to', column: v.name},
                test: TestType.Eq,
                value: v.getTag('LatestTime')!,
            });
        });
}

export default function buildAffiliationQuery(fromDataset: string, fromVars: Array<Variable>, toDataset: string, toVars: Array<Variable>, affType: string, dataDefRepo: DataDefRepo) {
    const sourceConfig = affiliationConfig.find(conf => conf.From === fromDataset && conf.To === toDataset && conf.Type === affType);
    const additionalColumns = sourceConfig?.Additional_Columns || [];
    const query: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: QueryType.Dim,
                tableNodes: createTableNodes(fromDataset, '-from', fromVars),
            },
            joins: [{
                joinType: JoinType.Left,
                node: {
                    baseQuery: {
                        queryType: QueryType.Affiliation,
                        tableNodes: [{
                            id: 'affiliation',
                            dataset: dataDefRepo.datasetByName[getAffiliatedDataset(fromDataset) + ':' + getAffiliatedDataset(toDataset)].name,
                            columns: [
                                'type',
                                'effective_begin',
                                'effective_end',
                                ...additionalColumns,
                            ]
                        }, ...createTableNodes(toDataset, '-to', toVars)]
                    }
                }
            }]
        },
        filters: [{
            column: {nodeId: 'affiliation', column: 'type'},
            test: TestType.Eq,
            value: affType
        }],
    };

    addTimeFilters(query, fromVars, toVars);

    return deserialize(query);
}
