import React, {useContext, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {NetworkContext} from '../lib/context';
import './geography-filter.scss';
import Select from '../../../components/select';
import Spinner from '../../../components/spinner';

const GeographyFilters: React.FC = observer(() => {
    const networkOptimizationStore = useContext(NetworkContext);
    const appliedStateFilterIndex = useMemo(() => networkOptimizationStore.availableGeographyFilters.findIndex(o => o.state.toLowerCase() === networkOptimizationStore.appliedGeographyFilter.state.toLowerCase()), [networkOptimizationStore.appliedGeographyFilter.state]);
    const countyData = useMemo(() => appliedStateFilterIndex >= 0 ? networkOptimizationStore.availableGeographyFilters[appliedStateFilterIndex].counties.map(c => `${c.county_name}-${c.county_ssa}`) : [], [appliedStateFilterIndex]);

    if (!networkOptimizationStore.availableGeographyFilters.length) {
        return <div className="w-full flex justify-center items-center p-6">
            <Spinner />
            <span className="ml-3">Loading...</span>
        </div>; 
    }

    return (
        <div className="geography-filters bg-white py-4 px-6 w-64 h-[calc(100%)] flex flex-col">
            <h1 className="text-lg my-4 font-semibold">Filters</h1>
            <div className="flex-grow">
                <div className="filter-group mb-4">
                    <label className="block text-gray-500 text-sm mb-1">State</label>
                    <Select data={networkOptimizationStore.availableGeographyFilters.map(o => o.state)}
                        value={networkOptimizationStore.appliedGeographyFilter.state}
                        onChange={(e) => networkOptimizationStore.setGeographyFilter('state', e)}>
                        {c => c}
                        {c => c || 'All'}
                    </Select>
                </div>
                <div className="filter-group mb-4">
                    <label className="block text-gray-500 text-sm mb-1">County</label>
                    <Select data={countyData}
                        value={networkOptimizationStore.appliedGeographyFilter.county}
                        onChange={(e) => networkOptimizationStore.setGeographyFilter('county', e)}>
                        {c => c}
                        {c => c || 'All'}
                    </Select>
                </div>
            </div>
        </div>
    );
});

export default GeographyFilters;