import {Layer, Source} from 'react-map-gl';
import {LayerProps} from './types';
import {useEffect} from 'react';
import {useMap} from 'react-map-gl';

export default function Choropleth(props:LayerProps) {
    const {data, paint, id} = props;
    const {current: map} = useMap();


    useEffect(()=>{
        if(map && data) {
            const source = map.getSource(String(id));
            if(source && source.type === 'geojson') {
                const geoJsonSource = source;
                geoJsonSource.setData(data);
            }
        } 
    },[data]);




    const defaultPaint =  {
        'fill-color': {
            property: 'ALAND',
            stops: [
                [82093, '#ff3c3c'],
                [3770388366, '#ff3c3c']
            ]
        },
        'fill-opacity': 0.75
    };
     

    return (
        <Source key={`${id}-layer`} id={String(id)} type="geojson" data={data}>
            <Layer
                key={`${id}-layer`}
                id={String(id)}
                type="fill"
                paint={paint || defaultPaint as any}
            />
        </Source>
    );
}