import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import {deserialize} from 'quantum-graph';
import Template from '../../../components/template';
import AuthWall from '../../../components/auth-wall';
import {QueryTableContext} from '../../../insight-container/query-table';
import DataExplorer from '../data-explorer/data-explorer';
import checkAccess, {SiteFeature} from '../../../lib/check-access';
import {hcIndex as hcIndexApi, savedQuery} from '../../../lib/api';
import {HCIndexEntry} from './types';
import {DataConfig} from '../../../insight-container/types';
import handleError from '../../../lib/error';
import activityTracker from '../../../lib/activity-tracker';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {HeaderContext} from '../../../components/header/context';
import {AgGridTableContext} from '../../../insight-container/ag-grid-table';

export default function HCIndexViewPage() {
    const {key, queryId} = useParams<{key: string, queryId: string}>();
    const [entry, setEntry] = useState<HCIndexEntry>();
    const [queryError, setQueryError] = useState('');
    const location = useLocation();
    const {useNewDesign} = useContext(HeaderContext);
    const agGrid = sessionStorage.getItem('agGrid');

    useEffect(() => {
        const dataController = new AbortController();
        hcIndexApi.getByShortName(key!, dataController.signal)
            .then((index: HCIndexEntry) => {
                if (queryId) {
                    savedQuery.get(parseInt(queryId), dataController.signal).then(q => {
                        index.queryJSON = q.queryJSON;
                        setEntry(index);
                    }).catch(err => handleError(err, setQueryError));
                } else {
                    if (location.state && location.state.query) {
                        index.queryJSON = location.state.query;
                    }   
                    setEntry(index);
                }
                activityTracker.setRecentActivity('Healthcare Index', index.name);
            })
            .catch(err => handleError(err, setQueryError));

        return function cleanup() {
            dataController.abort();
        };
    }, [key]);

    const dataConfig: DataConfig | null = useMemo(() => {
        if (!entry)
            return null;
        const entryOptions = entry.options && Object.entries(entry.options).length ? entry.options : {};
        const graph = deserialize(JSON.parse(entry.queryJSON));
        const filter = location.state ? location.state.filter : null;
        return {
            queries: [graph],
            options: Object.assign({
                hideExplore: true,
                colorColumns: entryOptions.hasOwnProperty('colorColumns') ? entryOptions.colorColumns : true,
                linkNewTab: true,
                excludeClaims: true,
                title: entry.name,
                connectingFacts: entry.connectingFacts,
                reference: key,
                filter: filter
            }, entryOptions),
        };
    }, [entry, key]);

    if (!checkAccess(SiteFeature.HealthcareIndex))
        return <Navigate replace to="/"/>;

    if (queryError)
        return <Navigate replace to="/healthcare-index"/>;
    
    const HCIPageView = agGrid ? AGGridHCIView : useNewDesign ? NewHCIView : DefaultHCIView;
    return <Template showFooter={useNewDesign}>
        <AuthWall>
            <HCIPageView dataConfig={dataConfig} entry={entry}/>
        </AuthWall>
    </Template>;
}

function DefaultHCIView({dataConfig, entry}: {dataConfig: DataConfig | null, entry: HCIndexEntry | undefined}) {
    return <div id="hc-index-view-page">
        {!!dataConfig && !!entry && <QueryTableContext dataConfig={dataConfig}>
            <DataExplorer back={true} title={entry.name}>
                <div className="container">
                    {entry.description && <div className="my-8">{documentToReactComponents(entry.description)}</div>}
                    {entry.documentationURL && <p className="my-8">
                        For details on the data sources and methodology, please refer to the <a href={entry.documentationURL} target="_blank" rel="noopener noreferrer">overview documentation</a>.
                    </p>}
                </div>
                <div className="container">
                    {entry.details?.map((detail, i) => <p key={i} className="my-9">{detail}</p>)}
                </div>
            </DataExplorer>
        </QueryTableContext>}
    </div>;
}

function NewHCIView({dataConfig, entry}: {dataConfig: DataConfig | null, entry: HCIndexEntry | undefined}) {
    return <div className="dpp-container">
        {!!dataConfig && !!entry && <QueryTableContext dataConfig={dataConfig}>
            <DataExplorer back={true} title={entry.name} />
        </QueryTableContext>}
    </div>;
}

function AGGridHCIView({dataConfig, entry}: {dataConfig: DataConfig | null, entry: HCIndexEntry | undefined}) {
    return <div>
        {!!dataConfig && !!entry && <AgGridTableContext dataConfig={dataConfig}>
            <DataExplorer back={true} title={entry.name} agGrid/>
        </AgGridTableContext>}
    </div>;
}
