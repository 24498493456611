import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {DataConfig} from '../../types';
import {ResponsiveBar} from '@nivo/bar';
import {formatData, getTextWidth, limitRowsForChart, PlotColors, PlotTheme} from '../common';
import ChartContext, {ChartStore} from '../context';
import {observer} from 'mobx-react';
import ChartContainer from '../container';
import QueryDataContext from '../../renderers/query-data-renderer/context';
import Loading from '../../../components/loading';
import {QueryTableContext} from '../../query-table';
import AdditionalToolTipVariables from '../additional-tooltip-variables';

const MAX_BARS = 99;

export const BarChartVis = observer(() => {
    const chartStore = useContext(ChartContext);
    let {yAxisNames, rowsToShow, xAxisName, valueMeta, showLatestYears, maxXLabelLength} =
        useContext(ChartContext);
    let {metadata, data} = useContext(QueryDataContext);
    let ref = useRef<HTMLDivElement>(null);
    let [containerWidth, setContainerWidth] = useState(800);
    const yVars = valueMeta;
    const yAxes = yAxisNames;
    const rows = useMemo(
        () => formatData(data, showLatestYears, rowsToShow, xAxisName, maxXLabelLength),
        [data, rowsToShow, showLatestYears, maxXLabelLength],
    );

    let maxXLegendLabelLength = rows.reduce((maxLength, row) => {
        let variable = metadata.find((m) => m.name === xAxisName);
        let formattedText = variable ? variable.formatSimple(row) : '';
        return Math.max(maxLength, getTextWidth(formattedText));
    }, 0);
    let maxXLabelLengthHorizontal = Math.cos(30 * (Math.PI / 180)) * maxXLegendLabelLength + 14;
    let maxXLabelLengthVertical = Math.sin(30 * (Math.PI / 180)) * maxXLegendLabelLength + 14;

    let maxYLabelLength = rows.reduce(
        (maxLength, row) =>
            Math.max(
                maxLength,
                yVars.length
                    ? getTextWidth(
                        yVars[0].formatter.formatSimple(
                            yAxes.reduce((total, v) => total + Number(row[v]), 0),
                        ),
                    )
                    : 0,
            ),
        0,
    );

    let showLegend = yVars.length > 1;
    let maxYVarLabelLength = yVars.reduce(
        (maxLength, v) => Math.max(maxLength, getTextWidth(v.label)),
        0,
    );

    let maxLegendsOnLine = Math.floor(containerWidth / (maxYVarLabelLength + 22));

    useEffect(() => {
        if (!ref.current) return;

        const observer = new ResizeObserver((entries) => {
            setContainerWidth(entries[0].contentRect.width);
        });
        observer.observe(ref.current);
        return () => {
            if (ref.current) observer.unobserve(ref.current);
        };
    }, [ref]);

    return (
        <ChartContainer divRef={ref} maxRows={MAX_BARS}>
            <ResponsiveBar
                data={rows}
                indexBy={xAxisName}
                keys={yAxes}
                margin={{
                    top: 15,
                    right: Math.max(maxXLabelLengthHorizontal - containerWidth / rowsToShow, 15),
                    bottom:
                        maxXLabelLengthVertical +
                        (showLegend ? 23 * Math.ceil(yVars.length / maxLegendsOnLine) : 0),
                    left: maxYLabelLength + (yVars.length === 1 ? 30 : 10),
                }}
                colors={PlotColors}
                padding={0.3}
                enableLabel={false}
                theme={PlotTheme}
                axisLeft={{
                    format: yVars.length ? (x) => yVars[0].formatter.formatSimple(x) : () => '',
                    legend: yVars.length === 1 ? yVars[0].label : '',
                    legendPosition: 'middle',
                    legendOffset: -(maxYLabelLength + 25),
                }}
                axisBottom={{tickRotation: 30}}
                tooltip={(d) => (
                    <div className='max-w-xs bg-white border shadow-lg rounded'>
                        <div
                            className='px-3 pt-2 pb-1 rounded-t text-white font-semibold'
                            style={{backgroundColor: d.color}}
                        >
                            {yVars.find((v) => v.name === d.id)!.label}
                        </div>
                        <div className="p-3">
                            {metadata.find((v) => v.name === d.id)?.formatter.formatSimple(d.value)}
                        </div>
                        {maxXLabelLength && (
                            <div className='p-3'>
                                {d.data.untrucatedXAxisName}
                            </div>
                        )}
                        <AdditionalToolTipVariables rowIndex={d.index} />
                    </div>
                )}
                legendLabel={(d) => yVars.find((v) => v.name === d.id)!.label}
                legends={
                    showLegend
                        ? new Array(Math.ceil(yAxes.length / maxLegendsOnLine))
                            .fill(1)
                            .map((_, i) => i)
                            .map((n) => ({
                                data: yAxes
                                    .slice(n * maxLegendsOnLine, (n + 1) * maxLegendsOnLine)
                                    .map((r, i) => ({
                                        label: chartStore.getLegendLabel(metadata.find(v => v.name === r), yVars.find((v) => v.name === r)!.label),
                                        id: r,
                                        color: PlotColors[
                                            (i + n * maxLegendsOnLine) % PlotColors.length
                                        ],
                                    })),
                                anchor: 'bottom',
                                dataFrom: 'keys',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: maxXLabelLengthVertical + 20 * (n + 1),
                                itemsSpacing: 10,
                                itemDirection: 'left-to-right',
                                itemWidth: maxYVarLabelLength + 12 + 10,
                                itemHeight: 20,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            }))
                        : []
                }
                groupMode='grouped'
            />
        </ChartContainer>
    );
});

const BarChartContext = observer(() => {
    const queryContext = useContext(QueryDataContext);
    const chartContext = useMemo(
        () =>
            new ChartStore(
                {
                    dataConfig: queryContext.dataConfig,
                    data: queryContext.data,
                    metadata: queryContext.metadata,
                },
                MAX_BARS,
            ),
        [queryContext.dataConfig, queryContext.data, queryContext.metadata],
    );

    if (queryContext.loading) return <Loading />;

    return (
        <ChartContext.Provider value={chartContext}>
            <BarChartVis />
        </ChartContext.Provider>
    );
});

export default function BarChartRenderer(props: {
    dataConfig: DataConfig
    loadCB?: (promise: Promise<void>) => void
}) {
    return (
        <QueryTableContext
            dataConfig={limitRowsForChart(props.dataConfig, MAX_BARS)}
            loadCB={props.loadCB}
        >
            <BarChartContext />
        </QueryTableContext>
    );
}
