
export const Years = [
    '2019',
    '2021',
];

export const LinesOfBusiness = [
    'Medicare FFS',
    'Medicaid',
    'Commercial',
    'Medicare Dual',
    'Medicaid Dual',
    'Medicare Advantage',
];

export const DataSources = ['VRDC', 'RAD'];

export const DataSourceLabels: Record<string, string> = {
    VRDC: 'CMS VRDC',
    RAD: 'Milliman Emerging Experience',
};

export const RegionTypes = [
    'national',
    'state',
    'county',
    'msa-division',
];

export const GeographyLabels: Record<string, string> = {
    national: 'National',
    state: 'State',
    county: 'County',
    'msa-division': 'MSA/Division',
};

export const ServiceCategories = [
    // 'Total',
    'Service Setting',
    'Service Subcategory',
];

export const RegionFields: Record<string, Array<string>> = {
    national: [],
    state: ['state'],
    county: ['county_name'],
    'msa-division': ['msa', 'msa_division_name'],
};

export const FocusLevelLabels: Record<string, string> = {
    'all': 'All Services',
    'cond': 'Focused Condition',
    'service': 'Focused Service',
};

export const FocusServiceLabels: Record<string, string> = {
    'virt': 'Virtual Care',
};
