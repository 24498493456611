import AuthWall from '../../components/auth-wall';
import {SiteFeature} from '../../lib/check-access';
import Template from '../../components/template';
import React, {useEffect, useMemo, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {deserialize, QuantumGraph} from 'quantum-graph';
import {insightContainer, savedQuery} from '../../lib/api';
import createGraphHandler from '../../lib/graph-tools/graph-handler/create';
import getDataDefRepo from '../../lib/user-data-defs';
import fetchData, {fetchMetadata} from '../../lib/data';
import Select from '../../components/select';
import InsightContainerTypeLabels from '../labels';
import Variable from '../../lib/metadata';
import ICChecks from './checks';
import InsightContainer from '../index';
import ErrorMessage from '../../components/error-message';
import Button from '../../components/button';
import Modal from '../../components/modal';
import handleError from '../../lib/error';


export default function VisBuilderPage() {
    const {id} = useParams<{id: string}>();
    const [query, setQuery] = useState<QuantumGraph>();
    const [count, setCount] = useState(0);
    const [metadata, setMetadata] = useState<Array<Variable>>([]);
    const [type, setType] = useState('');
    const [saveModal, setSaveModal] = useState(false);
    const [name, setName] = useState('');
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();
        let q: QuantumGraph;
        savedQuery.get(Number(id), controller.signal).then(r => {
            setName(r.name);
            q = deserialize(JSON.parse(r.queryJSON));
            setQuery(q);
            return getDataDefRepo(controller.signal);
        }).then(dataDefRepo => {
            // Load the count
            const gh = createGraphHandler(q, dataDefRepo);
            const countGraph = gh.buildCount();
            return fetchData(countGraph, controller.signal);
        }).then(rows => {
            setCount(Number(rows[0]['count']));
            return fetchMetadata(q, controller.signal);
        }).then(setMetadata)
            .catch(err => handleError(err, setError));
        return () => controller.abort();
    }, [id]);

    const supportedTypes = useMemo(() => {
        return Object.entries(InsightContainerTypeLabels).filter(([key]) => ICChecks[key](count, metadata, query));
    }, [count, metadata, query]);

    function saveVis() {
        setSaving(true);

        insightContainer.create({
            type,
            optionsJSON: JSON.stringify({}),
            savedQueryId: Number(id),
        }).then(ic => navigate('/ic/' + ic.id))
            .catch(err => handleError(err, setError));
    }

    return <AuthWall siteFeature={SiteFeature.CreateVis}>
        <Template>
            <div className="container">
                <h1>Visualization Builder</h1>

                <div className="flex">
                    <div className="w-1/4">
                        <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                            Query
                        </div>
                        <div>{name}</div>
                    </div>
                    <div className="w-1/4">
                        <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                            Count
                        </div>
                        <div>{count}</div>
                    </div>
                    <div className="w-1/4">
                        <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                            Visualization Type
                        </div>
                        {supportedTypes.length && <Select data={supportedTypes} value={type} onChange={setType}>
                            {_ => _[0]}
                            {_ => _[1]}
                        </Select>}
                    </div>
                </div>
                <div className="mt-4">
                    <Button color="green" disabled={!type} onClick={() => setSaveModal(true)}>
                        <i className="icon-save" /> Save Visualization
                    </Button>
                </div>
                <hr/>
                {error && <ErrorMessage error={error} />}
                {!supportedTypes.length && <ErrorMessage error="Sorry, there are no visualizations that support this data. Try changing the data and try again." />}
                {query && type && <InsightContainer type={type} dataConfig={{queries: [query], options: {}}} />}
                {query && saveModal && <Modal title="Save Visualization" onClose={() => setSaveModal(false)}>
                    <p>Confirm you want to save this visualization.</p>

                    <div className="modal-buttons">
                        <Button color="primary" disabled={saving} onClick={saveVis}>Save</Button>
                        <Button color="white" onClick={() => setSaveModal(false)}>Cancel</Button>
                    </div>
                </Modal>}
            </div>
        </Template>
    </AuthWall>;
}