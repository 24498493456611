import React from 'react';
import EndpointDoc from './endpoint';
import {Link} from 'react-router-dom';


export default function APIDocMetadata() {
    return <div>
        <h2>Retrieving Metadata</h2>

        <p>
            There are a couple ways to retrieve metadata. You can pull down all available metadata about a particular
            dataset or you can retrieve only the fields relevant to a particular query.
        </p>

        <p>
            To see all data points that are available, you can use the following endpoint:
        </p>
        <EndpointDoc method="get" path="/v2/quantum/metadata/:dataset"
            description={<div>
                <p>Returns metadata about all available fields for the provided dataset.</p>
                <p>Replace <code>:dataset</code> with the desired dataset name. See <Link to="/docs/api/datasets">Available Datasets</Link> for all options.</p>
            </div>}
            response="The metadata for the dataset will be returned in JSON format">
            Provide the query JSON in the request body.
        </EndpointDoc>

        <p>
            For metadata for a specific query, you can use the following endpoint.
        </p>
        <EndpointDoc method="post" path="/v2/quantum/metadata"
            description="Returns metadata for each of the columns in the query"
            response="The metadata for your query will be returned in JSON format">
        </EndpointDoc>
    </div>;
}