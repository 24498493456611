import {observer} from 'mobx-react';
import FilterStepMultiDropdown from '../../../../../components/filter-steps/common-steps/filter-step-multi-dropdown';
import {useContext} from 'react';
import FilterStepsContext from '../../../../../components/filter-steps/libs/context';

const PopulationFilterStep = observer(() => {
    const hcgFilterStore = useContext(FilterStepsContext);

    return <div className="flex justify-between">
        <FilterStepMultiDropdown label="Line of Business" filterStoreVariable="lob" variable="lob" getDropdownQuery={(v) => hcgFilterStore.getDropdownQuery(v)} resyncVariables={['geographies']}/>
        <FilterStepMultiDropdown label="Age" filterStoreVariable="age" variable="memberageband" getDropdownQuery={(v) => hcgFilterStore.getDropdownQuery(v)} resyncVariables={['geographies']}/>
        <FilterStepMultiDropdown label="Gender" filterStoreVariable="gender" variable="gender" getDropdownQuery={(v) => hcgFilterStore.getDropdownQuery(v)} resyncVariables={['geographies']}/>
    </div>;
});

export default PopulationFilterStep;