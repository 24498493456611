import React, {useContext} from 'react';
import {CanvasContext} from '../lib/context';
import {observer} from 'mobx-react';
import RichTextEditor from '../../../components/rich-text-editor';

type Props = {
    rowIndex: number,
    index: number,
    text: string
}

function CvRichTextContent(props: Props) {
    const store = useContext(CanvasContext);
    const {rowIndex, index, text} = props;

    return <RichTextEditor editable={store.editing} content={text || null} onUpdate={(editorState) => store.updateText(rowIndex, index, 'rich-text', JSON.stringify(editorState))} placeholder="Enter some text..."/>;
}

export default observer(CvRichTextContent);