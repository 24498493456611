import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ErrorMessage from '../error-message';
import Spinner from '../spinner';
import {dataRow, dataRows} from '../../lib/data-types';
import Select from '../select';
import handleError from '../../lib/error';
import Fuse from 'fuse.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList as List} from 'react-window';

type Props = {
    values: dataRows,
    onChange: (entities: dataRows) => void,
    onLoadEntities: () => Promise<dataRows>,
    displayName: string,
    displayColumn: string,
    searchColumns: Array<{name: string, value: string, threshold: number}>,
    additionalSubText?: string
    onManuallyAddedEntity?: (entities: dataRows) => void
    maxEntities?: number
}

const MIN_SEARCH_LENGTH = 3;
const LIST_ITEM_HEIGHT = 45;

export default function EntityList(props: Props) {
    const {values, onChange, displayName, onLoadEntities, displayColumn, searchColumns, additionalSubText, onManuallyAddedEntity, maxEntities} = props;
    const [foundSearch, setFoundSearch] = useState<string>('');
    const [addSearch, setAddSearch] = useState<string>('');
    const [addDisplayEntities, setAddDisplayEntities] = useState<dataRows>([]);
    const [entities, setEntities] = useState<dataRows>([]);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [addedEntityList, setAddedEntityList] = useState<dataRows>([]);
    const [addedSearchColumn, setAddedSearchColumn] = useState<string>(searchColumns[0].value);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const selectedRef = useRef<HTMLDivElement>(null);

    const fetchEntities = useCallback(() => {
        setLoading(true);
        onLoadEntities()
            .then(e => setEntities(e))
            .catch(e => handleError(e, setErrorMessage))
            .finally(() => setLoading(false));
    }, [onLoadEntities, displayColumn]);

    useEffect(() => {
        fetchEntities();
    }, [fetchEntities]);

    const searchEntities = useCallback((value: string) => {
        let tempEntities = entities.filter(e => !values.some(v => v[displayColumn] === e[displayColumn]));
        if (value.length >= MIN_SEARCH_LENGTH) {
            let searchCol = searchColumns.find(c => c.value === addedSearchColumn);
            let threshold = searchCol ? searchCol.threshold : .3;
            const fuse = new Fuse(tempEntities, {
                keys: [addedSearchColumn],
                threshold
            });
            tempEntities = fuse.search(value).map(entry => entry.item);
        }
        setAddDisplayEntities(tempEntities);
    }, [entities, values, addedSearchColumn, displayColumn]);

    useEffect(() => {
        setAddDisplayEntities(entities);
        // setAddDisplayEntities(entities.filter(e => !values.some(v => v[displayColumn] === e[displayColumn])));
    }, [entities, displayColumn, values]);

    useEffect(() => {
        if (addSearch)
            searchEntities(addSearch);
    }, [addedSearchColumn, addSearch, searchEntities]);

    const foundDisplayEntities = useMemo(() => {
        let foundEntities = values;
        if (foundSearch) {
            const fuse = new Fuse(values, {
                keys: [displayColumn],
                threshold: .3
            });
            foundEntities = fuse.search(foundSearch).map(entry => entry.item);
        }

        return foundEntities;
    }, [values, foundSearch, displayColumn]);

    function toggleValue(entity: dataRow) {
        let tempEntityList = [...values];
        let tempAddedEntityList = [...addedEntityList];
        const index = tempEntityList.findIndex(e => e[displayColumn] === entity[displayColumn]);
        if (index >= 0) {
            tempEntityList.splice(index, 1);
            tempAddedEntityList = tempAddedEntityList.filter(ae => ae[displayColumn] !== entity[displayColumn]);
        } else {
            tempEntityList = [entity, ...tempEntityList];
            tempAddedEntityList.push(entity);
        }

        if (maxEntities && tempEntityList.length > maxEntities) {
            tempEntityList = tempEntityList.slice(0, maxEntities);
        }

        setAddedEntityList(tempAddedEntityList);
        onManuallyAddedEntity && onManuallyAddedEntity(tempAddedEntityList);
        onChange(tempEntityList);
    }

    function entityAddedByList(entity: dataRow) {
        return addedEntityList.some(ae => ae[displayColumn] === entity[displayColumn]);
    }

    const listHeight = Math.min(addDisplayEntities.length * LIST_ITEM_HEIGHT, 800);

    return <div>
        {errorMessage && <ErrorMessage error={errorMessage}/>}
        <div className="flex items-center border-b relative">
            <div className="w-1/2 mb-3 flex flex-col">
                <div className="text-xl capitalize py-1">{displayName}:</div>
                <div>Your query returned: <strong>{values.length}</strong></div>
                {maxEntities && <small>Maximum of {maxEntities}</small>}
            </div>
            <div className="w-1/2">
                <div className="text-xl">All <span className="capitalize">{displayName}</span>:</div>
                <div>{additionalSubText}</div>
            </div>
        </div>
        <div className="flex space-x-3 justify-center mt-3">
            <div className="w-1/2 flex flex-col border-r-2">
                <div className="flex space-x-2 found-search">
                    <div className="relative flex-grow">
                        <input className="w-full shadow-none border-0 pl-10 bg-gray-100" type="search" value={foundSearch} onChange={e => setFoundSearch(e.target.value)}
                            placeholder="Search" data-testid="foundSearch"/>
                        <i className="icon-search absolute left-3 top-3" />
                    </div>
                    <div className="w-1/6 flex items-center justify-center">
                        <div className="text-blue-400 hover:cursor-pointer" onClick={() => {onChange([]); setAddedEntityList([]);}}>Clear all</div>
                    </div>
                </div>
                <div ref={selectedRef} className={`${expanded ? '' : 'overflow-x-hidden'} mt-3`} style={{height: expanded ? 'auto' : '550px'}}>
                    {foundDisplayEntities.length === 0 && <div className="w-full h-full flex justify-center items-center">
                        {foundSearch && <span>No results found.</span>}
                    </div>}
                    <AutoSizer disableHeight>
                        {({width}: any) => (<List
                            itemData={foundDisplayEntities}
                            innerElementType="ul"
                            className="w-full flex flex-wrap"
                            itemCount={foundDisplayEntities.length}
                            height={listHeight}
                            itemSize={LIST_ITEM_HEIGHT}
                            width={width!}>
                            {({data, index, style}) => {
                                const value = data[index];
                                return (<li style={style} className={`w-full py-2 pr-5 flex justify-between items-center border-b ${entityAddedByList(value) && 'bg-gray-50'} hover:bg-gray-200`}>
                                    <div className="pl-2">{value[displayColumn] as string}</div>
                                    <div className="flex space-x-2 items-center">
                                        {entityAddedByList(value) && <span>Added</span>}
                                        <div className="hover:cursor-pointer pt-1" onClick={() => toggleValue(value)}>
                                            <i className="icon-times" />
                                        </div>
                                    </div>
                                </li>);
                            }}
                        </List>)}
                    </AutoSizer>
                </div>
            </div>
            <div className="w-1/2 flex flex-col pr-3">
                <div className="flex w-full">
                    <div className="w-1/3">
                        <Select data={searchColumns} value={addedSearchColumn} onChange={setAddedSearchColumn} width={182}>
                            {_ => _.value}
                            {_ => _.name}
                        </Select>
                    </div>
                    <div className="flex-grow relative">
                        <input className="w-full shadow-none border-0 pl-10 bg-gray-100" type="search" value={addSearch} onChange={e => setAddSearch(e.target.value)}
                            placeholder="Search" data-testid="addedSearch"/>
                        <i className="icon-search absolute left-3 top-3" />
                        {loading && <div className="absolute top-0 right-5 h-full pr-3 flex items-center">
                            <Spinner />
                        </div>}
                    </div>
                </div>
                <div className="overflow-x-hidden mt-3" style={{height: (expanded && selectedRef.current) ? selectedRef.current.scrollHeight : '550px'}}>
                    {addDisplayEntities.length === 0 && <div className="w-full h-full flex justify-center items-center">
                        {addSearch && <span>No results found.</span>}
                    </div>}
                    <AutoSizer disableHeight>
                        {({width}: any) => (<List
                            itemData={addDisplayEntities}
                            innerElementType="ul"
                            className="w-full flex flex-wrap"
                            itemCount={addDisplayEntities.length}
                            height={listHeight}
                            itemSize={LIST_ITEM_HEIGHT}
                            width={width!}>
                            {({data, index, style}) => {
                                const value = data[index];
                                return (<li style={style} className="w-full py-2 pr-5 flex justify-between items-center border-b hover:cursor-pointer hover:bg-gray-200" onClick={() => toggleValue(value)}>
                                    <div className="pl-2">{value[displayColumn] as string}</div>
                                    <div className="pt-1">
                                        <i className="icon-plus" />
                                    </div>
                                </li>);
                            }}
                        </List>)}
                    </AutoSizer>
                </div>
            </div>
        </div>
        <div className="border-t pl-2 pt-2 flex justify-end">
            <div className="hover:cursor-pointer text-gray-500" onClick={() => setExpanded(!expanded)}><span className="text-lg mr-3">{expanded ? 'Collapse' : 'Expand'} Window</span> <i className="icon-expand" /></div>
        </div>
    </div>;
}