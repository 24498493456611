import {useContext,  useMemo} from 'react';
import {CanvasInsertContext} from '../../../lib/context';
import {CvInsertScreen} from '../../../lib/types';
import Alert from '../../../../../components/alert';
import Button from '../../../../../components/button';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import QueryListItem from '../../../../../components/quantum-list-item';
import {GraphVersions, QuantumGraph} from 'quantum-graph';
import {observer} from 'mobx-react';



export function generateTitle(graph: QuantumGraph): string {
    if (graph.getVersion() === GraphVersions.V1) {
        return 'V1 Quantum Graph';
    }
  
    if (graph.getVersion() === GraphVersions.V2) {
        const v2QuantumGraph = graph as V2QuantumGraph;
        const tableNodes = v2QuantumGraph.baseQueryGraph.baseQuery.tableNodes;
        const joins = v2QuantumGraph.baseQueryGraph.joins;
  
        if (tableNodes.length === 1) {
            return tableNodes[0].dataset;
        }
  
        if (tableNodes.length > 1 && joins.length > 0) {
            const mainDataset = tableNodes[0].dataset;
            const joinedDatasets = joins.map(join => join.node.baseQuery.tableNodes.map(tableNode => tableNode.dataset));
            const joinedDatasetNames = joinedDatasets.flat().join('-');
            return `${mainDataset}-${joinedDatasetNames}`;
        }
  
        if (tableNodes.length > 1) {
            const datasetNames = tableNodes.map(tableNode => tableNode.dataset).join('-');
            return datasetNames;
        }
    }
  
    return 'Unnamed-Graph';
}

const MapQuerySelector = observer(()=> {
    const insertStore = useContext(CanvasInsertContext);
    const onDelete = insertStore.removeQuery.bind(insertStore);

    const [choroplethQuery, ...pointsQueries] = useMemo(
        () =>
            insertStore.mapOptions.mapQueries && insertStore.mapOptions.mapQueries.length > 0
                ? insertStore.mapOptions.mapQueries
                : [],
        [insertStore.mapOptions.mapQueries],
    );

    return (
        <div className='px-3 mt-3'>
            <h2>Select Queries For The Map Visualization</h2>
            
            <h4>Choropleth Shape</h4>
            <select onChange={(e)=>insertStore.mapOptions.mapShape = e.target.value as 'cbsa' | 'state_fips'}>
                <option value='cbsa'>CBSA</option>
                <option value='state_fips'>State</option>
            </select>

            <h4>Choropleth Query</h4>
            <Button
                color={choroplethQuery ? 'red' : 'orange'}
                onClick={choroplethQuery ? ()=>onDelete(0) : () => insertStore.goToVisScreen(CvInsertScreen.queryType)}
            >
                {choroplethQuery ? 'Replace' : 'Add'}
            </Button>
            <div>{choroplethQuery && <QueryListItem query={choroplethQuery} name={generateTitle(choroplethQuery)} index={0}/>}</div>

            <h4>Points Queries</h4>
            {!choroplethQuery && (
                <Alert color='yellow' icon='icon-warning'>
                    Add a choropleth query before adding any points queries
                </Alert>
            )}
            <Button
                color='orange'
                disabled={!choroplethQuery}
                onClick={() => insertStore.goToVisScreen(CvInsertScreen.queryType)}
            >
                Add
            </Button>
            <div>
                {pointsQueries &&
                    pointsQueries.map((q:any, i:any) => <QueryListItem key={generateTitle(q) + String(i)} index={i} query={q} name={generateTitle(q)} onDelete={onDelete} />)}
            </div>
            <br>
            </br>
            {!choroplethQuery && (
                <Alert color='yellow' icon='icon-warning'>
                    Add at least a choropleth query before moving on to map config
                </Alert>
            )}
            <Button disabled={!choroplethQuery} onClick={() => insertStore.goToVisScreen(CvInsertScreen.columnConfig)} color='blue'>Save</Button>
        </div>
    );
});

export default MapQuerySelector;

