import Alert from '../../../components/alert';
import React, {useContext} from 'react';
import {CanvasContext} from '../lib/context';

type Props = {
    conditionMet: boolean,
    loading: boolean,
    type: string,
    hasAlternative?: boolean
}
export default function ConditionalRenderAlert(props: Props) {
    const store = useContext(CanvasContext);
    const {conditionMet, loading, type, hasAlternative} = props;

    return <div>
        {store.editing && !loading && <Alert color="blue" icon="icon-info" noDismiss>{conditionMet ? `This ${type} has a conditional render set.`
            : `Conditional render not met. This ${type} ${hasAlternative ? `is rendering the alternate insight container based on current settings.` : `will not show in preview or publish mode with the current settings.`}`}
        </Alert>}
    </div>;
}