import React from 'react';
import Variable from '../../lib/metadata';
import DataPoint from './data-point';
import Button from '../button';

type Props = {
    variables: Array<Variable>
    disabledVariables: Array<Variable>
    selection: Array<Variable>
    onToggle: (v: Array<Variable> | Variable) => void
    label?: string
}

export default function CategoryGroup(props: Props) {
    const {variables, selection, onToggle, disabledVariables, label} = props;
    const selectionMap = Object.fromEntries(selection.map((v) => [v.getId(), true]));

    function isDisabled(v: Variable): boolean {
        return !!disabledVariables.find((variable) => variable.getId() === v.getId());
    }

    function toggleAll() {
        const allSelected = variables
            .filter((v) => !isDisabled(v) && !v.locked)
            .every((v) => selectionMap[v.getId()]);
        const toToggle = variables.filter((v) => {
            if (isDisabled(v) || v.locked) return false;
            return allSelected || !selectionMap[v.getId()];
        });
        props.onToggle(toToggle);
    }

    return (
        <div className='w-full'>
            <div className='flex justify-between'>
                <div className='text-lg mb-2'>
                    <i className='icon-folder mr-3' />
                    {label} / <span className='font-bold'>{variables[0].getCategory()}</span>
                </div>
                <Button color='link' className='opacity-70 mr-4' onClick={toggleAll}>
                    Toggle All
                </Button>
            </div>
            <ul className='overflow-y-visible'>
                {variables.map((v, i) => (
                    <DataPoint
                        key={i}
                        variable={v}
                        selected={selectionMap[v.getId()]}
                        onToggle={() => onToggle(v)}
                        disabled={isDisabled(v)}
                        premium={v.locked}
                    />
                ))}
            </ul>
        </div>
    );
}
