import {observer} from 'mobx-react';
import Loading from '../../components/loading';
import {useContext} from 'react';
import FilterStepsContext from './libs/context';


const HPTLoading = observer(() => {
    const filterStepsStore = useContext(FilterStepsContext);

    return <div>
        {filterStepsStore.loading && <div className="absolute w-full h-full z-50 top-0 left-0 flex justify-center items-center">
            <Loading large />
        </div>}
    </div>;
});

export default HPTLoading;