import React from 'react';
import {DataConfig} from '../types';
import StandardDataRenderer, {StandardRenderChildProps} from '../renderers/standard-data-renderer';
import {DataTableVis} from '../data-table';

export function EOCVis(props: StandardRenderChildProps) {
    const {data, metadata, dataConfig, loadCB} = props;

    return <div className="eoc-vis">
        <DataTableVis data={data} metadata={metadata} dataConfig={dataConfig} loadCB={loadCB}/>
    </div>;
}

export default function EOCRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void}) {
    return <StandardDataRenderer dataConfig={props.dataConfig} loadCB={props.loadCB}>
        {EOCVis}
    </StandardDataRenderer>;
}