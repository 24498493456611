import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Loading from '../../../components/loading';
import {content} from '../../../lib/api';
import {TrashCan} from '@carbon/icons-react';
import {MyFavorite} from '../../../lib/data-types';
import handleError from '../../../lib/error';

export default function FavoritesPage() {
    const [favorites, setFavorites] = useState<Array<MyFavorite>>([]);
    const [loading, setLoading] = useState(true);
    const [hoveredRow, setHoveredRow] = useState<number | null>(null);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const controller = new AbortController();
        content.myFavorites.listAll(controller.signal).then(favoritesList => setFavorites(favoritesList))
            .catch(err => handleError(err, setError))
            .finally(() => setLoading(false));
        return () => controller.abort();
    }, []);


    async function deleteFavorites(myFavorite: MyFavorite) {
        if (window.confirm('Are you sure you want to delete?')) {
            await content.myFavorites.delete(myFavorite.id);
            setFavorites(favorites.filter(favorite => favorite.id !== myFavorite.id));
        }
    }

    function renderFavoritesPage() {
        return <div className="p-8">
            <div className='text-xl'>My Favorites</div>
            {error && <div className='text-red-500'>{error}</div>}

            {loading ?
                <Loading /> :
                <div>
                    {favorites.length ?
                        <div className="dpp-insight-table mt-5">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Favorites</th>
                                        <th className='w-4'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {favorites.map((myFavorites, i) => <tr onMouseOver={() => setHoveredRow(i)} onMouseLeave={() => setHoveredRow(null)} key={myFavorites.id}>
                                        <td><Link to={myFavorites.url}>
                                            {myFavorites.pageName || <em>Untitled</em>}
                                        </Link></td>
                                        <td>
                                            { i === hoveredRow && <TrashCan onClick={() => deleteFavorites(myFavorites)} size={16} /> }
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div> :
                        <div className={'w-full bg-white flex flex-col md:flex-row pt-5 items-center'}>
                            <div>
                                <p>You don't have any canvas boards created.</p>
                            </div>
                        </div>}
                </div>}
        </div>;
    }

    return renderFavoritesPage();
}