import {dataRow} from '../../../lib/data-types';

export default function searchItems<T extends dataRow>(items: Array<T>, query: string, fields: Array<string>): Array<T> {
    if (!query)
        return items;
    query = query.toLowerCase();
    return items.filter(item => {
        for (const field of fields) {
            const value = String(item[field]).toLowerCase();
            if (value.includes(query))
                return true;
        }
        return false;
    });
}