import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {DataConfig, getConfigOption} from '../types';
import QueryDataRenderer from '../renderers/query-data-renderer';
import QueryDataContext from '../renderers/query-data-renderer/context';
import QueryDataStore from '../renderers/query-data-renderer/store';
import Table from '../../components/table';
import Variable from '../../lib/metadata';
import Loading from '../../components/loading';
import Pagination from '../../components/pagination';
import VariableFilter from '../../components/variable-filter';
import Filter from '../../lib/filter';
import Button from '../../components/button';
import FilterBar from '../../components/filter-bar';
import './query-table.scss';
import SourceModal from '../../components/source-modal';
import DatasetLegend from '../../components/table/dataset-legend';
import getDataDefRepo from '../../lib/user-data-defs';
import YearModal from '../../components/year-modal';
import V2QuantumGraph from 'quantum-graph/out/v2/v2-graph';
import RelatedDataModal from '../../components/related-data-modal';
import Spinner from '../../components/spinner';
import TableHeader from '../../components/table-header';
import updateGraphSelection from '../../lib/graph-tools/selection';
import {TransformCBs} from '../../lib/data-types';
import {QuantumGraph, TestType} from 'quantum-graph';
import TimeFilterModal from '../../components/time-filter-modal';
import {To} from 'react-router-dom';
import {BasicFilter} from 'quantum-graph/out/v2/filter';
import createGraphHandler from '../../lib/graph-tools/graph-handler/create';
import {getDefaultDataDefRepo} from '../../lib/data-defs';
import V2GraphHandler from '../../lib/graph-tools/graph-handler/v2-graph-handler';
import {CanvasContext} from '../../pages/canvas/lib/context';
import {getFilterById} from '../charts/common';
import {trackEvent} from '../../lib/analytics-provider';
import {HeaderContext} from '../../components/header/context';
import CustomPagination from '../../components/custom-pagination/CustomPagination';

type Props = {
    customTableControl?: JSX.Element,
    saveDataCB?: (query:QuantumGraph) => void
    titleOverride?: string
}

const QueryTableVis = observer((props: Props) => {
    const {customTableControl, saveDataCB} = props;
    const store = useContext(QueryDataContext);
    const canvasStore = useContext(CanvasContext);
    const {useNewDesign} = useContext(HeaderContext);
    
    const relatedData: Array<{name: string, url: string}> | undefined = getConfigOption(store.dataConfig, 'relatedData');
    const hideTableHeader: boolean = getConfigOption<boolean>(store.dataConfig, 'hideTableHeader')!;
    const showExplore = !getConfigOption<boolean>(store.dataConfig, 'hideExplore');
    const lockFilters: boolean = getConfigOption<boolean>(store.dataConfig, 'lockFilters')!;
    const hiddenColumnNames: Array<string> = getConfigOption(store.dataConfig, 'hiddenColumns') || [];
    const hiddenColumns: Array<Variable> = store.metadata.filter(v => hiddenColumnNames.some(n => v.name === n));
    const exploreLink: string | To = getConfigOption(store.dataConfig, 'exploreLink') || {pathname: '/data/explore'};
    const exploreState = getConfigOption(store.dataConfig, 'exploreState') || {query: JSON.stringify(store.dataConfig.queries[0].serialize())};
    const isCanvas: boolean = getConfigOption(store.dataConfig, 'isCanvas')!;
    const queryTitle: string = useMemo(() => props.titleOverride || getConfigOption(store.dataConfig, 'title') || '', [props.titleOverride, store.dataConfig]);
    const hideDownload: boolean = getConfigOption(store.dataConfig, 'hideDownload') || false;
    const hideSave: boolean = getConfigOption(store.dataConfig, 'hideSave') || false;
    const stickyColumns: Array<string> = getConfigOption(store.dataConfig, 'stickyColumns') || [];
    const noRowMessage: string | undefined = getConfigOption(store.dataConfig, 'noRowMessage');
    const parameterFilters: string = getConfigOption(store.dataConfig, 'parameterFilters') || '';

    useEffect(() => {
        if (isCanvas && canvasStore) {
            const graph = store.dataConfig.queries[0];
            const gh = createGraphHandler(graph, getDefaultDataDefRepo());
            const query: any = graph.serialize();
            const filter = getFilterById(canvasStore.board?.parameter?.idField ?? '', query.filters);
            if (gh instanceof V2GraphHandler && filter) {
                const variable = gh.findVariable(filter.column, store.metadata);
                if (variable) {
                    const parameterFilter: Filter = {
                        variable,
                        test: filter.test,
                        value: filter.value,
                    };
                    const filterIndex = store.filters.findIndex(f => f.variable.name === parameterFilter.variable.name);
                    store.removeFilter(filterIndex);
                    store.dataConfig.options.lockFilters = true;
                    if (!store.graphFilters.some(f => f.variable === parameterFilter.variable && f.test === parameterFilter.test && f.value === parameterFilter.value)) {
                        store.graphFilters.push(parameterFilter); }
                }
            }
        }
    }, [canvasStore, store.dataConfig]);

    useEffect(()=> {
        if (parameterFilters) {
            handleSpecialFilter(parameterFilters);
        }
    },[store.dataConfig]);

    function setSort(v: Variable, removeSort = false) {
        store.applySort(v, removeSort);
        store.updateData();
    }

    function removeSort(v: Variable) {
        store.removeSort(v);
        store.updateData();
    }

    function setPage(page: number) {
        store.page = page;
        store.updateData();
        trackEvent('pagination-update', {
            url: window.location.href,
            page: store.page
        });
    }

    let removeVariable = useCallback((variable: Variable) => {
        const controller = new AbortController();
        store.loading = true;
        Promise.all([store.library.loadAllNames(controller.signal), store.library.loadAllMetadata(controller.signal)]).then(() => {
            const [newGraph, newSelection] = updateGraphSelection(store.getGraph(), store.metadata, [variable], store.library, store.dataDefRepo);
            store.metadata = newSelection;
            store.updateConfig({queries: [newGraph], options: store.dataConfig.options});
        }).finally(() => store.loading = false);
    }, [store.library, store.metadata, store.dataDefRepo, store.getGraph()]);

    function removeScaffoldFilter() {
        store.scaffoldField = undefined;
        store.scaffoldType = undefined;
        store.updateData();
    }

    function getScaffoldLabel() {
        let scaffoldField = store.scaffoldField;
        if (!scaffoldField) {
            const graph = store.getGraph();
            if (graph instanceof V2QuantumGraph)
                scaffoldField = graph.baseQueryGraph.baseQuery.scaffoldField;
        }
        const variable = store.metadata.find(v => v.name === scaffoldField);
        return variable ? variable.label : '';
    }

    let filters = store.getFilters();
    const sorts = store.sorts;

    function handleSpecialFilter(filter: string) {
        const graph = store.dataConfig.queries[0];
        const gh = createGraphHandler(graph, getDefaultDataDefRepo());
        const parameterFilter = BasicFilter.deserialize(JSON.parse(filter));

        if (parameterFilter && gh instanceof V2GraphHandler) {
            const variable = gh.findVariable(parameterFilter.column,store.metadata);

            if (variable) {
                const filter: Filter = {
                    variable: variable,
                    test: parameterFilter.test,
                    value: parameterFilter.value
                };
                if (!store.filters.some(f => f.variable === filter.variable && f.test === filter.test && f.value === filter.value)) {
                    store.addFilter(filter);
                }
            }

        }

    }

    const QueryTableView = useMemo(() => useNewDesign ? NewQueryTableView : DefaultQueryTableView, [store.loading, useNewDesign]);
    const viewProps: QueryTableViewProps = {
        filters,
        sorts,
        queryTitle,
        lockFilters,
        setPage,
        getScaffoldLabel,
        removeScaffoldFilter,
        setSort,
        removeSort,
        hideTableHeader,
        isCanvas,
        hideDownload,
        hideSave,
        saveDataCB,
        customTableControl,
        removeVariable,
        hiddenColumns,
        stickyColumns,
        noRowMessage,
        showExplore,
        exploreLink,
        exploreState,
        relatedData,
    };
    return <QueryTableView {...viewProps} />;
});

export {QueryTableVis};

type QueryTableViewProps = {
    filters: Filter[];
    sorts: [Variable, boolean][];
    queryTitle: string;
    lockFilters: boolean;
    setPage: (page: number) => void;
    getScaffoldLabel: () => string;
    removeScaffoldFilter: () => void;
    setSort: (v: Variable, removeSort?: boolean) => void;
    removeSort: (v: Variable) => void;
    hideTableHeader: boolean;
    isCanvas: boolean;
    hideDownload: boolean;
    hideSave: boolean;
    saveDataCB: ((query: QuantumGraph) => void) | undefined;
    customTableControl: JSX.Element | undefined;
    removeVariable: (variable: Variable) => void;
    hiddenColumns: Variable[];
    stickyColumns: string[];
    noRowMessage: string | undefined;
    showExplore: boolean;
    exploreLink: To;
    exploreState: any;
    relatedData: {
        name: string;
        url: string;
    }[] | undefined;
};

function DefaultQueryTableView(props: QueryTableViewProps) {
    const {
        filters, 
        sorts, 
        queryTitle, 
        lockFilters, 
        setPage,
        getScaffoldLabel, 
        removeScaffoldFilter, 
        setSort, 
        removeSort, 
        hideTableHeader, 
        isCanvas, 
        hideDownload, 
        hideSave, 
        saveDataCB, 
        customTableControl, 
        removeVariable,
        hiddenColumns,
        stickyColumns,
        noRowMessage, 
        showExplore,
        exploreLink,
        exploreState,
        relatedData,
    } = props;
    const store = useContext(QueryDataContext);
    const [filterModal, setFilterModal] = useState<{variable: Variable, filter: Filter | null} | null>(null);
    const [yearModal, setYearModal] = useState<Variable | null>(null);
    const [timeFilterModal, setTimeFilterModal] = useState<{variable: Variable, filter: {f: Filter, i: number} | null} | null>(null);
    const [infoModal, setInfoModal] = useState<Variable | null>(null);
    const [showRelatedDataModal, setShowRelatedDataModal] = useState<boolean>(false);

    function isYearKey(v: Variable) {
        const graph = store.getGraph();
        return graph instanceof V2QuantumGraph && v.name === 'year_key';
    }

    function isTimeVariable(v: Variable) {
        const graph = store.getGraph();
        const timeVariables = ['year_month_key'];
        return graph instanceof V2QuantumGraph && timeVariables.includes(v.name);
    }

    function columnFilter(v: Variable) {
        // Intercept filtering special columns
        if (isYearKey(v))
            setYearModal(v);
        else if (isTimeVariable(v))
            setTimeFilterModal({variable: v, filter: null});
        else
            setFilterModal({variable: v, filter: null});
    }

    function modifyFilter(filter: Filter) {
        const graph = store.getGraph();
        if (graph instanceof V2QuantumGraph) {
            setPage(0);
            if (isYearKey(filter.variable))
                setYearModal(filter.variable);
            else if (isTimeVariable(filter.variable))
                setTimeFilterModal({variable: filter.variable, filter: {f: filter, i: store.filters.findIndex(f => f === filter)}});
            else
                setFilterModal({variable: filter.variable, filter: filter});
        }
    }

    function setTimeFilters(filterData: Array<{time: string, v: Variable, testType: TestType}>) {
        setPage(0);
        if (store.scaffoldField === timeFilterModal?.variable.name)
            removeScaffoldFilter();
        if (timeFilterModal?.filter && timeFilterModal?.filter.i !== -1)
            store.removeFilter(timeFilterModal.filter.i);
        setTimeFilterModal(null);

        filterData.forEach(f => {
            if (f.testType === TestType.Latest || f.testType === TestType.Earliest) {
                let existingFilterIdx = store.filters.findIndex(f => f.variable.name === store.scaffoldField);
                if (existingFilterIdx)
                    store.removeFilter(existingFilterIdx);
                store.applyMinMaxTimeFilter(filterData[0].v, f.testType === TestType.Latest);
            } else {
                store.addFilter({
                    variable: f.v,
                    test: f.testType,
                    value: f.time
                });
            }
        });
    }

    function applyFilter(filter: Filter) {
        const existingFilterIndex = store.filters.findIndex(f => f === filterModal!.filter);
        if (existingFilterIndex >= 0)
            store.removeFilter(existingFilterIndex);
        setPage(0);
        store.addFilter(filter);
        setFilterModal(null);
    }

    return <div className='query-table-vis'>
        <div className={'table-holder' + (store.loading ? ' is-loading' : '')}>
            <FilterBar
                filters={filters}
                sorts={sorts}
                queryName={queryTitle}
                lockedFilters={lockFilters ? store.graphFilters : undefined}
                onRemove={(i) => {
                    setPage(0);
                    store.removeFilter(i);
                } }
                onModify={modifyFilter}
                scaffoldFilter={store.scaffoldType ? {field: getScaffoldLabel(), type: store.scaffoldType === 'max' ? TestType.Latest : TestType.Earliest} : undefined}
                onScaffoldRemove={removeScaffoldFilter}
                onSortToggle={(sort) => setSort(sort[0])}
                onSortRemove={(sort) => removeSort(sort[0])}
                onClearAll={store.filters.length ? () => store.clearAllNonGraphFilters() : undefined} />
            {!hideTableHeader && (
                <TableHeader store={store} onlyShowDownload={isCanvas} hideDownload={hideDownload} hideSave={hideSave} saveDataCB={saveDataCB} title={queryTitle}>{customTableControl}</TableHeader>
            )}
            <Table
                headers={store.metadata}
                data={store.data}
                sorts={store.sorts}
                onSort={(v) => setSort(v, true)}
                onRemove={removeVariable}
                onFilter={columnFilter}
                onInfo={setInfoModal}
                getColorIndex={(v) => store.getGroup(v)}
                hiddenColumns={hiddenColumns}
                stickyColumns={store.metadata.filter(v => stickyColumns.includes(v.name))}
                tableBottomMessage={(!store.loading && !store.countLoading && store.count === 0 && noRowMessage) ? noRowMessage : undefined} />
            {store.loading && <Loading />}
        </div>
        <div className='flex justify-between items-center bg-white p-3 border border-t-0 rounded-b shadow-lg text-gray-500'>
            {store.countLoading && (
                <div className='flex items-center'>
                    <Spinner />
                </div>
            )}
            <div className='w-1/4'>
                <Pagination
                    count={store.count}
                    page={store.page}
                    pageSize={store.pageSize}
                    setPage={setPage}
                    showCount />
            </div>
            <div className='w-1/2 h-full flex justify-center'>
                {store.colorColumns && <DatasetLegend datasets={store.getDatasetNames()} />}
            </div>
            <div className='flex-grow flex justify-end'>
                {showExplore && (<Button color='light' small to={exploreLink} state={exploreState} className='mr-2'>Explore Data</Button>)}
                {relatedData && (
                    <Button
                        color='light'
                        small
                        className='mr-2'
                        onClick={() => setShowRelatedDataModal(true)}
                    >
                        <i className='icon-chart-line' />
                        <span className='ml-2'>Explore Related Data</span>
                    </Button>
                )}
            </div>
        </div>
        {filterModal && (
            <VariableFilter
                config={filterModal}
                onApply={applyFilter}
                gh={store.getGH(true)}
                onCancel={() => setFilterModal(null)} />
        )}
        {infoModal && <SourceModal variable={infoModal} onClose={() => setInfoModal(null)} />}
        {yearModal && (
            <YearModal
                variable={yearModal}
                query={store.getGraph() as V2QuantumGraph}
                onCancel={() => setYearModal(null)}
                dataDefRepo={store.dataDefRepo}
                onClose={(year, variable, testType) => {
                    setPage(0);
                    setYearModal(null);
                    store.updateYear(year, testType, variable);
                } } />
        )}
        {showRelatedDataModal && relatedData && (
            <RelatedDataModal
                relatedData={relatedData}
                onClose={() => setShowRelatedDataModal(false)} />
        )}
        {timeFilterModal && (
            <TimeFilterModal
                filter={timeFilterModal.filter?.f}
                variable={timeFilterModal.variable}
                onCancel={() => setTimeFilterModal(null)}
                onClose={setTimeFilters} />
        )}
    </div>;
}

function NewQueryTableView(props: QueryTableViewProps) {
    const { 
        filters, 
        sorts, 
        queryTitle, 
        lockFilters, 
        setPage,  
        getScaffoldLabel, 
        removeScaffoldFilter, 
        setSort, 
        removeSort, 
        hideTableHeader, 
        isCanvas, 
        hideDownload, 
        hideSave, 
        saveDataCB, 
        customTableControl, 
        removeVariable, 
        hiddenColumns,
        stickyColumns,
        noRowMessage, 
        relatedData,
    } = props;
    const store = useContext(QueryDataContext);
    const [filterModal, setFilterModal] = useState<{variable: Variable, filter: Filter | null} | null>(null);
    const [yearModal, setYearModal] = useState<Variable | null>(null);
    const [timeFilterModal, setTimeFilterModal] = useState<{variable: Variable, filter: {f: Filter, i: number} | null} | null>(null);
    const [infoModal, setInfoModal] = useState<Variable | null>(null);
    const [showRelatedDataModal, setShowRelatedDataModal] = useState<boolean>(false);

    function isYearKey(v: Variable) {
        const graph = store.getGraph();
        return graph instanceof V2QuantumGraph && v.name === 'year_key';
    }

    function isTimeVariable(v: Variable) {
        const graph = store.getGraph();
        const timeVariables = ['year_month_key'];
        return graph instanceof V2QuantumGraph && timeVariables.includes(v.name);
    }

    function columnFilter(v: Variable) {
        // Intercept filtering special columns
        if (isYearKey(v))
            setYearModal(v);
        else if (isTimeVariable(v))
            setTimeFilterModal({variable: v, filter: null});
        else
            setFilterModal({variable: v, filter: null});
    }

    function modifyFilter(filter: Filter) {
        const graph = store.getGraph();
        if (graph instanceof V2QuantumGraph) {
            setPage(0);
            if (isYearKey(filter.variable))
                setYearModal(filter.variable);
            else if (isTimeVariable(filter.variable))
                setTimeFilterModal({variable: filter.variable, filter: {f: filter, i: store.filters.findIndex(f => f === filter)}});
            else
                setFilterModal({variable: filter.variable, filter: filter});
        }
    }

    function setTimeFilters(filterData: Array<{time: string, v: Variable, testType: TestType}>) {
        setPage(0);
        if (store.scaffoldField === timeFilterModal?.variable.name)
            removeScaffoldFilter();
        if (timeFilterModal?.filter && timeFilterModal?.filter.i !== -1)
            store.removeFilter(timeFilterModal.filter.i);
        setTimeFilterModal(null);

        filterData.forEach(f => {
            if (f.testType === TestType.Latest || f.testType === TestType.Earliest) {
                let existingFilterIdx = store.filters.findIndex(f => f.variable.name === store.scaffoldField);
                if (existingFilterIdx)
                    store.removeFilter(existingFilterIdx);
                store.applyMinMaxTimeFilter(filterData[0].v, f.testType === TestType.Latest);
            } else {
                store.addFilter({
                    variable: f.v,
                    test: f.testType,
                    value: f.time
                });
            }
        });
    }

    function applyFilter(filter: Filter) {
        const existingFilterIndex = store.filters.findIndex(f => f === filterModal!.filter);
        if (existingFilterIndex >= 0)
            store.removeFilter(existingFilterIndex);
        setPage(0);
        store.addFilter(filter);
        setFilterModal(null);
    }

    return <div className='query-table-vis'>
        <div className={'table-holder' + (store.loading ? ' is-loading' : '')}>
            <FilterBar
                filters={filters}
                sorts={sorts}
                queryName={queryTitle}
                lockedFilters={lockFilters ? store.graphFilters : undefined}
                onRemove={(i) => {
                    setPage(0);
                    store.removeFilter(i);
                } }
                onModify={modifyFilter}
                scaffoldFilter={store.scaffoldType ? {field: getScaffoldLabel(), type: store.scaffoldType === 'max' ? TestType.Latest : TestType.Earliest} : undefined}
                onScaffoldRemove={removeScaffoldFilter}
                onSortToggle={(sort) => setSort(sort[0])}
                onSortRemove={(sort) => removeSort(sort[0])}
            />
            {!hideTableHeader && (
                <TableHeader
                    store={store}
                    onlyShowDownload={isCanvas} 
                    hideDownload={hideDownload} 
                    hideSave={hideSave} 
                    saveDataCB={saveDataCB} 
                    title={queryTitle} 
                    onClearAll={store.filters.length ? () => store.clearAllNonGraphFilters() : undefined}
                >
                    {customTableControl}
                </TableHeader>
            )}
            <Table
                headers={store.metadata.filter(v => !store.unAuthorizedVariables.includes(v.name))}
                data={store.data}
                sorts={store.sorts}
                onSort={(v) => setSort(v, true)}
                onRemove={removeVariable}
                onFilter={columnFilter}
                onInfo={setInfoModal}
                getColorIndex={(v) => store.getGroup(v)}
                hiddenColumns={hiddenColumns}
                stickyColumns={store.metadata.filter(v => stickyColumns.includes(v.name))}
                tableBottomMessage={(!store.loading && !store.countLoading && store.count === 0 && noRowMessage) ? noRowMessage : undefined} />
            {store.loading && <Loading />}
        </div>
        <CustomPagination
            totalItems={store.count || 0}
            page={store.page || 1}
            pageSize={store.pageSize}
            onChange={({page, pageSize}) => {store.pageSize = pageSize;setPage(page - 1);}} 
        />
        {filterModal && (
            <VariableFilter
                config={filterModal}
                onApply={applyFilter}
                gh={store.getGH(true)}
                onCancel={() => setFilterModal(null)} />
        )}
        {infoModal && <SourceModal variable={infoModal} onClose={() => setInfoModal(null)} />}
        {yearModal && (
            <YearModal
                variable={yearModal}
                query={store.getGraph() as V2QuantumGraph}
                onCancel={() => setYearModal(null)}
                dataDefRepo={store.dataDefRepo}
                onClose={(year, variable, testType) => {
                    setPage(0);
                    setYearModal(null);
                    store.updateYear(year, testType, variable);
                } } />
        )}
        {showRelatedDataModal && relatedData && (
            <RelatedDataModal
                relatedData={relatedData}
                onClose={() => setShowRelatedDataModal(false)} />
        )}
        {timeFilterModal && (
            <TimeFilterModal
                filter={timeFilterModal.filter?.f}
                variable={timeFilterModal.variable}
                onCancel={() => setTimeFilterModal(null)}
                onClose={setTimeFilters} />
        )}
    </div>;
}

export function QueryTableContext(props: {dataConfig: DataConfig, children: JSX.Element, loadCB?: (promise: Promise<void>) => void, transforms?: TransformCBs, pageSize?: number}) {
    const {dataConfig, loadCB, transforms, pageSize} = props;
    const [setup, setSetup] = useState(false);
    const [queryDataStore, setStore] = useState<QueryDataStore | null>(null);

    // Create the store the first time
    useEffect(() => {
        if (!setup) {
            const controller = new AbortController();
            getDataDefRepo(controller.signal).then(dataDefRepo => {
                setStore(new QueryDataStore(dataConfig, dataDefRepo, [], 0, pageSize));
                setSetup(true);
            });
            return () => controller.abort();
        }
    }, [setup, dataConfig]);

    // Update the store subsequent updates
    useEffect(() => {
        if (setup) {
            const isDiff = JSON.stringify(dataConfig) !== JSON.stringify(queryDataStore?.dataConfig);
            if (isDiff)
                queryDataStore?.updateConfig(dataConfig);
        }
    }, [setup, dataConfig, queryDataStore]);

    if (!queryDataStore || !setup)
        return null;

    return <QueryDataContext.Provider value={queryDataStore}>
        <QueryDataRenderer loadCB={loadCB} transforms={transforms}>
            {props.children}
        </QueryDataRenderer>
    </QueryDataContext.Provider>;
}


export default function QueryTableRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void}) {
    return <QueryTableContext dataConfig={props.dataConfig} loadCB={props.loadCB}>
        <QueryTableVis />
    </QueryTableContext>;
}