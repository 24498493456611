import './radio-button-group.scss';
import classNames from '../../lib/class-names';

type Props = {
    values: Array<{name: string, value: string}>,
    value: string,
    onChange: (value: string) => void,
    className?: string,
    disabled?: boolean
}

export default function RadioButtonGroup(props: Props) {
    const {values, value, onChange, className, disabled} = props;

    function handleClick(value: string) {
        !disabled && onChange(value);
    }

    return <div className={classNames('radio-button-group', className)}>
        {values.map((v, i) => <div key={i} className={`${disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'} flex space-x-3 items-center px-1`} onClick={() => handleClick(v.value)}>
            <div className={`radio ${disabled && 'disabled-border'}`}>
                <div className={`${value === v.value ? 'selected' : ''} ${disabled ? 'disabled' : 'enabled'}`}></div>
            </div>
            <div className={disabled ? 'text-gray-300' : ''}>{v.name}</div>
        </div>)}
    </div>;
}