import {SiteSearchAllResult} from './search-types';
import {CarbonIconType} from '@carbon/icons-react/lib/CarbonIcon';
import generateLink, {LinkType} from '../../lib/links';
import {Hospital, MapIdentify, FolderShared, ToolKit} from '@carbon/icons-react';
import {siteSearch} from '../../lib/api';
import {SearchCanvas} from '../../lib/data-types';
import Badge from '../../components/badge';
import React from 'react';

export type SiteSearchData = {
    url: string,
    icon: CarbonIconType,
    dataset?: string,
    additionalInfo?: string
}

export type SearchFilter = {
    title: string,
    url: string,
    endpoint?: string
}

export function parseCanvasEntityData(canvasEntityData: SiteSearchAllResult): SiteSearchData {
    let additionalInfo = '';
    if (canvasEntityData.source.abbreviated_state) {
        additionalInfo = canvasEntityData.source.abbreviated_state.toUpperCase();
        if (canvasEntityData.source.city_name)
            additionalInfo = `${canvasEntityData.source.city_name.toLowerCase()}, ${additionalInfo}`;
    }

    return {
        url: generateLink(LinkType.Profile, canvasEntityData.id!.value, canvasEntityData.dataset),
        icon: Hospital,
        dataset: canvasEntityData.dataset,
        additionalInfo
    };
}

export function parseCanvasGeographyData(canvasGeographyData: SiteSearchAllResult): SiteSearchData {
    return {
        url: generateLink(LinkType.Profile, canvasGeographyData.id!.value, canvasGeographyData.dataset),
        icon: MapIdentify,
        dataset: canvasGeographyData.dataset
    };
}

export function parseHCIData(hciData: SiteSearchAllResult): SiteSearchData {
    return {
        url: generateLink(LinkType.HCI, hciData.key.value),
        icon: FolderShared
    };
}

export function parseToolData(toolData: SiteSearchAllResult): SiteSearchData {
    return {
        url: toolData.key.value,
        icon: ToolKit
    };
}

export function parseSiteSearchData(siteSearchData: SiteSearchAllResult): SiteSearchData | null {
    if (siteSearchData.entityType === 'canvas' && siteSearchData.index?.endpoint === 'entities_and_organizations')
        return parseCanvasEntityData(siteSearchData);
    else if (siteSearchData.entityType === 'canvas' && siteSearchData.index?.endpoint === 'geography')
        return parseCanvasGeographyData(siteSearchData);
    else if (siteSearchData.entityType === 'hci')
        return parseHCIData(siteSearchData);
    else if (siteSearchData.entityType === 'tool')
        return parseToolData(siteSearchData);

    return null;
}

export const DEFAULT_FILTERS = [
    {title: 'Everything', url: ''},
    {title: 'Datasets', url: ''},
    {title: 'Tools', url: ''}
];

export async function performSiteSearch(query: string, searchFilter: SearchFilter, controller: AbortController): Promise<Array<SiteSearchAllResult>> {
    if (!query)
        return [];

    if (searchFilter.title === DEFAULT_FILTERS[0].title) {
        return await searchAll(query, controller);
    } else if (searchFilter.title === DEFAULT_FILTERS[1].title) {
        return await searchDatasets(query, controller);
    } else if (searchFilter.title === DEFAULT_FILTERS[2].title) {
        return await searchTools(query, controller);
    } else {
        const searchCanvas: SearchCanvas = {
            q: query,
            canvasLink: {
                title: searchFilter.title,
                url: searchFilter.url
            },
            canvasSectionTitle: searchFilter.endpoint || '',
            numResults: 100
        };
        return await searchCanvasIndexes(searchCanvas, controller);
    }
}

export async function searchAll(query: string, controller: AbortController, maxRows?: number) {
    return await siteSearch.searchAll(query, controller.signal, maxRows);
}

export async function searchDatasets(query: string, controller: AbortController) {
    return await siteSearch.searchHCI(query, controller.signal);
}

export async function searchTools(query: string, controller: AbortController) {
    return await siteSearch.searchTool(query, controller.signal);
}

export async function searchCanvasIndexes(searchCanvas: SearchCanvas, controller: AbortController) {
    return await siteSearch.searchCanvas(searchCanvas, controller.signal);
}

type Props = {
    searchResult: SiteSearchAllResult,
}

export function SearchResultEntry(props: Props & {searchQuery: string}) {
    const {searchResult, searchQuery} = props;
    const siteSearchData = parseSiteSearchData(searchResult);
    const searchResultName = searchResult.searchField.value;
    if (!siteSearchData)
        return null;

    return <div className="w-full overflow-hidden h-full mt-4">
        <a className="text-xl mt-0 mb-2 text-medinsight-vibrant-blue-500 hover:underline" href={siteSearchData.url} dangerouslySetInnerHTML={{__html: boldSearchQuery(searchResultName, searchQuery)}}></a>
        <div className='flex items-baseline'>
            <siteSearchData.icon className='self-center mr-1.5 text-gray-500' size={14} />
            <div className="flex-none border-gray-400 border-r pr-3 text-gray-500 text-sm">{searchResultName}</div>
            {siteSearchData.dataset && <div className='flex-none whitespace-pre ml-1.5'>
                <Badge color='light' pill className="capitalize small">{siteSearchData.dataset}</Badge>
            </div>}
            {siteSearchData.additionalInfo && <div className='flex-none whitespace-pre'>
                <Badge color='light' pill className="capitalize small">{siteSearchData.additionalInfo}</Badge>
            </div>}
        </div>
    </div>;
}

function boldSearchQuery(text: string, searchQuery: string) {
    const regex = new RegExp(searchQuery.split('').reduce((s, c) => s.concat('\\s?', c), ''), 'gi');
    return text.replaceAll(regex, match => `<b>${match}</b>`);
}