import {Aggregation, filterValue, QuantumGraph, TestType} from 'quantum-graph';
import React, {useEffect, useState, Fragment} from 'react';
import {useLocation} from 'react-router-dom';
import Loading from '../../../components/loading';
import Select from '../../../components/select';
import fetchData from '../../../lib/data';
import {dataRows, dataRow} from '../../../lib/data-types';
import handleError from '../../../lib/error';
import AuthWall from '../../../components/auth-wall';
import Template from '../../../components/template';
import Badge from '../../../components/badge';
import OneOneLayout from '../../../components/layout/one-one-layout';
import QueryTableRenderer from '../../../insight-container/query-table';
import PageMenu from '../../../components/page-menu';
import Button from '../../../components/button';
import DRGSelect from '../../../components/drg-select';
import Modal from '../../../components/modal';
import './drg-tool.scss';
import {EntityHelper, TableInputHelper} from 'quantum-graph/out/v1';
import activityTracker from '../../../lib/activity-tracker';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';

export default function LookupDrgPage() {
    const location = useLocation();
    const [year, setYear] = useState('');
    const [years, setYears] = useState<string[]>();
    const [selectedDRGs, setSelectedDRGs] = useState(location.state?.codes || []);
    const [, setErr] = useState<string>('');
    const [searchOpen, setSearchOpen] = useState(false);

    useEffect(() => {
        const graph = new TableInputHelper('hospital-drg-volume-and-charges', [
            'year_key',
            'count_of_claims'
        ], getDefaultDataDefRepo())
            .buildHelper()
            .aggregate('count_of_claims', Aggregation.Sum)
            .build().toGraph();

        // Load the data from the graph
        const dataController = new AbortController();
        fetchData(graph, dataController.signal)
            .then(rows => {
                setYears(rows.map(row => row['year_key']!.toString()));
            })
            .catch(err => handleError(err, setErr));

        activityTracker.setRecentActivity('DRG', 'Hospital Table');

        return function cleanup() {
            dataController.abort();
        };
    }, []);

    const select = (code:dataRow | dataRows, add = true) => {
        if (add) {
            setSelectedDRGs(Array.from(new Set(selectedDRGs.concat(code))));
        } else {
            setSelectedDRGs(selectedDRGs.filter((s: any) => Array.isArray(code) ? !code.includes(s) : code['drg'] !== s['drg']));
        }
    };

    let drgDataGraph:QuantumGraph | null = null;

    if (!year && !!location?.state?.year)
        setYear(location?.state?.year);

    if (year) {
        drgDataGraph = new EntityHelper('hospital', ['torch_facility_id', 'name', 'state'], getDefaultDataDefRepo())
            .addFact('hospital-drg-volume-and-charges', [
                'count_of_claims',
                'mean_charge',
                'mean_payment',
                'drg',
                'year_key'
            ]).buildHelper()
            .filterGrain(null, 'year_key', TestType.Eq, year)
            .filter('drg', TestType.In, selectedDRGs.map((c: any) => c['drg'] as filterValue))
            .filter('count_of_claims', TestType.Gt, 0)
            .aggregate('count_of_claims', Aggregation.Sum)
            .aggregate(['mean_charge', 'mean_payment'], Aggregation.Average)
            .aggregate('drg', Aggregation.Count)
            .sort('count_of_claims', false)
            .build()
            .toGraph();
    }

    return <AuthWall>
        <Template>
            <div id="drg-table-view-page">
                <PageMenu>
                    {/* <Button color="link" onClick={() => navigate('/drg', location.state)}><i className="icon-left" /> Back</Button> */}
                    <div className="flex-grow" />

                    <div className="center">
                        <h1>Hospital Table</h1>
                    </div>
                </PageMenu>
                <div className="container">
                    <OneOneLayout>
                        <p className="my-12">
                            <div className="flex flex-auto flex-col items-end content-end">
                                {selectedDRGs.length ? <Fragment>
                                    <label className="label">Codes</label>
                                    <div className="flex flex-auto flex-row items-end content-end justify-end flex-wrap bg-gray-100 rounded overflow-y-auto p-1">
                                        {selectedDRGs.map((code: any, i: number) => <Badge key={i} className='my-1' pill color="blue">{code['drg']}</Badge>)}
                                    </div>
                                    <Button className="text-sm" color="orange" onClick={() => setSearchOpen(true)}>Edit Codes</Button>
                                </Fragment> : <Fragment>
                                    <label className="label">&nbsp;</label>
                                    <Button className="h-11" color="orange" onClick={() => setSearchOpen(true)}>Select Codes</Button>
                                </Fragment>}
                            </div>
                        </p> 
                        <p className="my-12">
                            <div className="field">
                                <label className="label">Year</label>
                                <div className="control">
                                    {years ? <Select data={years} value={year} onChange={(opt) => setYear(opt)}>
                                        {(y: string) => y}
                                        {(y, i) => <p key={i}>{y}</p>}
                                    </Select> : <Loading />}
                                </div>
                            </div>
                        </p>
                    </OneOneLayout>
                </div>
                <div className="mt-5 mx-10">
                    {drgDataGraph && <QueryTableRenderer dataConfig={{queries: [drgDataGraph!], options: {lockFilters: true, hiddenColumns: ['drg', 'year_key']}}} />}
                </div>
                {searchOpen && <Modal title="DRG Search" onClose={() => setSearchOpen(false)}>
                    <DRGSelect select={select} selected={selectedDRGs} />
                    <div className="modal-buttons">
                        <Button color="orange" onClick={() => setSearchOpen(false)}>Save</Button>
                    </div>
                </Modal>}
            </div>
        </Template>
    </AuthWall>;
}
