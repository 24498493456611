import HPTFilterStepsStore from '../../hpt-filter-steps-store';
import {computed, makeObservable, observable} from 'mobx';

export default class HPTInstitutionalFilterStepsStore extends HPTFilterStepsStore {

    constructor() {
        super(true);

        makeObservable(this, {
            _minNumBeds: observable,
            minNumBeds: computed,
            _maxNumBeds: observable,
            maxNumBeds: computed,
            _geographies: observable,
            geographies: computed,
            _geographyType: observable,
            geographyType: computed,
            _facilityList: observable,
            facilityList: computed,
            _facilityTypes: observable,
            facilityTypes: computed,
            _healthSystems: observable,
            healthSystems: computed,
            _loading: observable,
            loading: computed,
            _payers: observable,
            payers: computed,
            _lob: observable,
            lob: computed,
            _products: observable,
            products: computed,
            _view: observable,
            view: computed,
            _dataView: observable,
            dataView: computed,
            _geoRestrictionModules: observable,
            geoRestrictionModules: computed,
            _useUATEnvironment: observable,
            useUATEnvironment: computed
        });
    }

    static empty() {
        return new HPTInstitutionalFilterStepsStore();
    }
}