import React, {ReactNode, useContext, useState} from 'react';
import './alert.scss';
import classNames from '../../lib/class-names';
import {HeaderContext} from '../header/context';

type Props = {
    color: string,
    icon: string | ReactNode,
    children?: ReactNode,
    timed?: boolean,
    className?: string,
    border?: boolean,
    noDismiss?: boolean,
}

export default function Alert(props: Props) {
    const {color, icon, className, border, noDismiss} = props;
    const [closed, setClosed] = useState(false);
    const headerStore = useContext(HeaderContext);

    if (closed)
        return null;

    function legacyAlert() {
        return <div className={classNames('alert', className, {[color + '-border']: border})}>
            {typeof icon === 'string' && <div className={'alert-box ' + color}><i className={icon} /></div>}
            {typeof icon !== 'string' && <div className={'alert-box ' + color}>{icon}</div>}
            <div className="alert-content">{props.children}</div>
            {!noDismiss && <div className="close" onClick={() => setClosed(true)} role="button" tabIndex={0}><i className="icon-times" /></div>}
        </div>;
    }

    function dppAlert() {
        return <div className={classNames('alert', 'dpp', className, `${color}-border-dpp`)}>
            {typeof icon === 'string' && <div className={'alert-box-dpp ' + color}><i className={icon} /></div>}
            {typeof icon !== 'string' && <div className={'alert-box-dpp ' + color}>{icon}</div>}
            <div className='px-2 py-5 flex-grow'>{props.children}</div>
            {!noDismiss && <div className='close' onClick={() => setClosed(true)} role='button' tabIndex={0}><i
                className='icon-times' /></div>}
        </div>;
    }

    return headerStore.useNewDesign ? dppAlert() : legacyAlert();
}