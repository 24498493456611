import {deserialize, GraphVersions, TestType} from 'quantum-graph';
import fetchData from '../../../../lib/data';
import {QueryObj} from '../../../../lib/data-types';

export enum CCEnumField {
    MemberCategory = 'membercategory',
    ConditionCategory = 'condition_category',
    AgeBand = 'age_band',
    Gender = 'gender',
    MetricScope = 'src',
}

export async function listEnum(enumField: CCEnumField, regionType: string, dataSource: string, signal: AbortSignal): Promise<Array<string>> {
    const dataset = 'common-core-benchmarks-metrics-' + regionType;
    const queryObj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: 'dim',
                tableNodes: [
                    {
                        id: 'cc',
                        dataset,
                        columns: [enumField]
                    }
                ]
            }
        },
        distinct: true,
        filters: [{
            column: {nodeId: 'cc', column: 'data_source'},
            test: TestType.Eq,
            value: dataSource,
        }]
    };
    const query = deserialize(queryObj);
    const data = await fetchData(query, signal);
    return data.map(row => row[enumField] as string || '').sort((a, b) => {
        if (a === '') return -1;
        if (b === '') return 1;
        if (a === 'Total') return -1;
        if (b === 'Total') return 1;
        return a < b ? -1 : a > b ? 1 : 0;
    });
}