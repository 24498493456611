import {useEffect, useState} from 'react';
import PageTitle from '../../components/page-title';
import Button from '../../components/button';
import {users} from '../../lib/api';
import {useParams} from 'react-router-dom';
import ErrorMessage from '../../components/error-message';
import handleError from '../../lib/error';
import {useNavigate} from 'react-router';
import SiteName from '../../components/SiteName';


export default function JoinPage() {
    const {code} = useParams<{code: string}>();
    const [saving, setSaving] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [invalidCode, setInvalidCode] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (code) {
            setInvalidCode(false);
            users.join.checkCode(code).then(({valid}) => {
                if (!valid) {
                    setError('The link is either invalid or expired. Please contact your account admin.');
                    setInvalidCode(true);
                }
            });
        }
    }, [code]);

    function isStrongPassword() {
        return password.length >= 8;
    }

    function join() {
        setError('');
        if (isStrongPassword() && code) {
            setSaving(true);
            users.join.setPassword(code, password).then(() => {
                navigate('/login');
            }).catch(err => handleError(err, setError))
                .finally(() => setSaving(false));
        }
    }

    function onKeyUp(e: any) {
        if (e.key === 'Enter' || e.keyCode === 13)
            join();
    }

    return <div>
        <PageTitle name={<h1>Welcome to <SiteName /></h1>} />
        <div className="container mt-16 w-1/2 flex flex-col space-y-5">
            {error && <ErrorMessage error={error} />}
            <div className="flex flex-col space-y-3">
                <label><strong>Enter your password:</strong></label>
                <input type="password" value={password} onChange={e => setPassword(e.target.value)} disabled={invalidCode} onKeyUp={onKeyUp}/>
                <small>Password must be at least 8 characters long.</small>
            </div>
            <div>
                <p>
                    By clicking the Get Started, you agree to our <a href="https://torchinsight.com/legal/terms.html" target="_blank" rel="noopener noreferrer">Terms of Use</a>.
                </p>
            </div>
            <div>
                <Button color="blue" disabled={!isStrongPassword() || saving} onClick={join}>Get Started</Button>
            </div>
        </div>
    </div>;
}