import {ReactElement, cloneElement} from 'react';

type Props = {
    name: string,
    icon: ReactElement,
    selected: boolean;
    setter: any
}

export default function SelectorTab(props: Props) {
    const {icon, name, selected, setter} = props;

    const iconWithClass = cloneElement(icon, {
        className: '',
        size: '24'
    });

    const borderColor = selected ? 'border-milliman-blue-400' : 'border-gray-300';

    return (
        <div className={`flex flex-grow  px-10 py-2 border-b-2 ${borderColor} hover:cursor-pointer`} onClick={()=>setter(name)}>
            {iconWithClass}
            <span className='ml-4 text-lg'><strong>{name}</strong></span>
        </div>
    );
}