import ProfileGroup from './profile-group';
import Variable from '../../lib/metadata';
import {dataRow} from '../../lib/data-types';

export default class Profile {

    idVariable: Variable | undefined;
    nameVariable: Variable | undefined;
    groups: Array<ProfileGroup> = [];
    year_key: Variable | undefined;

    constructor(metadata: Array<Variable>) {
        const categories: Record<string, Array<Variable>> = {};
        for (const variable of metadata) {
            const role = variable.getTag('FieldRole');
            if (role === 'id') {
                this.idVariable = variable;
                continue;
            } else if (role === 'name') {
                this.nameVariable = variable;
                continue;
            } else if (variable.name === 'year_key') {
                this.year_key = variable;
                continue;
            } else if (variable.name.endsWith('_key') || variable.name.startsWith('torch_'))
                continue;
            const category = variable.getCategory();
            if (!categories[category])
                categories[category] = [];
            categories[category].push(variable);
        }

        for (const category of Object.keys(categories)) {
            this.groups.push(new ProfileGroup(category, categories[category]));
        }
        this.groups.sort((a, b) => {
            if (a.name === 'General')
                return -1;
            else if (b.name === 'General')
                return 1;
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });
    }

    get length() {
        return this.groups.length;
    }

    getName(row: dataRow) {
        return this.nameVariable ? row[this.nameVariable.name] : '';
    }

}