import {NavigateFunction, useNavigate} from 'react-router-dom';
import React, {ReactNode, useContext, useMemo} from 'react';
import {observer} from 'mobx-react';
import Variable from '../../../lib/metadata';
import Button from '../../../components/button';
import PageMenu from '../../../components/page-menu';
import {QueryTableVis} from '../../../insight-container/query-table';
import auth from '../../../lib/auth';
import Alert from '../../../components/alert';
import {HeaderContext} from '../../../components/header/context';
import Breadcrumb from '../../../components/breadcrumb';
import {Star, StarFilled} from '@carbon/icons-react';
import {useFavorite} from '../../../lib/favorites';
import {AGGridTableVis} from '../../../insight-container/ag-grid-table';

type Props = {
    back: boolean,
    title?: string,
    children?: ReactNode | Array<ReactNode>
    agGrid?: boolean
}

export function hideKeys(v: Variable) {
    const name = v.name.toLowerCase();
    return !(name === 'year_key' || name === 'year_month_key' || name.endsWith('_dim_key') || name.endsWith('_fact_key') || !v.getTag('Recommended'));
}

const DataExplorer = observer((props: Props) => {
    const {back, title, children, agGrid} = props;
    const navigate = useNavigate();
    const preContent = children && (children instanceof Array ? children[0] : children);
    const postContent = children && children instanceof Array && children.slice(1);
    const gridContent = agGrid ? <AGGridTableVis height={750} /> : <QueryTableVis />;
    const {useNewDesign} = useContext(HeaderContext);
    let DataExplorerView = useNewDesign ? NewDataExplorerView : DefaultDataExplorerView;
    return <DataExplorerView back={back} title={title} navigate={navigate} preContent={preContent} postContent={postContent} gridContent={gridContent}/>;
});

export default DataExplorer;

type DataExplorerViewProps = {
    back: boolean;
    navigate: NavigateFunction;
    title: string | undefined;
    preContent: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
    postContent: string | number | boolean | React.ReactNode[] | null | undefined;
    gridContent: ReactNode | Array<ReactNode>;
};

function DefaultDataExplorerView({back, navigate, preContent, title, postContent}: DataExplorerViewProps) {
    return <div>
        <PageMenu>
            {back && <Button color="link" onClick={() => navigate(-1)}><i className="icon-left" /> Back</Button>}
            <div className="flex-grow" />

            <div className="center">
                <h1>{title || 'Data Explorer'}</h1>
            </div>
        </PageMenu>

        {auth.principal?.throttleLimit && <div className="container mt-5">
            <Alert color="orange" icon="icon-warning">
                <strong>Warning</strong> You are using a throttled demo account. That means that all data queries will
                show adjusted and limited results. Specifically you cannot adjust pagination or apply certain numerical
                filters.
            </Alert>
        </div>}
        {preContent}
        <div className="mt-5 mx-10">
            <QueryTableVis />
        </div>
        {postContent}
    </div>;
}

function NewDataExplorerView({preContent, title, postContent, gridContent}: DataExplorerViewProps) {
    const [myFavorites, setFavorite] = useFavorite(title || 'Data Explorer');

    const isFavorite = useMemo(() => myFavorites.some(favorite => favorite.url === window.location.pathname), [myFavorites]);

    const FavoriteIcon = isFavorite ? StarFilled : Star;
    
    return <div className='data-content'>
        <Breadcrumb crumbs={[{name: 'Home', url: '/'}, {name: title || 'Data Explorer', url: '/'}]} />
        <div className='flex mt-6 mb-8 leading-10 gap-3'>
            <FavoriteIcon size={32} className='text-gray-800 hover:cursor-pointer' onClick={setFavorite} />
            <h1 className='text-gray-800 text-[32px] font-light m-0'>{title || 'Data Explorer'}</h1>
        </div>
        {auth.principal?.throttleLimit && <div className="container mt-5">
            <Alert color="orange" icon="icon-warning">
                <strong>Warning</strong> You are using a throttled demo account. That means that all data queries will
                show adjusted and limited results. Specifically you cannot adjust pagination or apply certain numerical
                filters.
            </Alert>
        </div>}
        {preContent}
        <div className="mt-5">
            {gridContent}
        </div>
        {postContent}
    </div>;
}
