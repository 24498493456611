import React, {useMemo, useState, useEffect} from 'react';
import AdminCrudTable from '../crud-table';
import {modules} from '../../../lib/api';
import {datasets} from '../../../lib/data-defs';

export default function DatasetModulesAdmin() {
    const [assignedDatasets, setAssignedDatasets] = useState<Array<string>>([]);
    const fields = useMemo(() => [
        {name: 'id', label: 'Id', show: true, type: 'numeric'},
        {name: 'moduleId', label: 'Module Id', show: true, type: 'permissionModule'},
        {name: 'dataset', label: 'Dataset', show: true, type: 'text'},
        {name: 'rowCriteria', label: 'Row Criteria', show: true, type: 'text'},
        {name: 'columns', label: 'Columns', show: true, type: 'text'}
    ], []);

    useEffect(() => {
        const controller = new AbortController();
        modules.datasets.list(controller.signal)
            .then(ds => setAssignedDatasets(ds.map((ds: Record<string, string>) => ds.dataset)));

        return () => controller.abort();
    }, []);

    function getUnAssignedDatasets() {
        return datasets.filter(ds => !assignedDatasets.includes(ds.name));
    }

    return <div>
        <AdminCrudTable api={modules.datasets} title={'Dataset Module'} urlPath={'/site-admin/datasets-modules'} fields={fields} />
        <h2 className="w-full text-center"><i className="icon-warning" /> Non-assigned datasets</h2>
        <ul className="grid grid-cols-5 mt-8 border p-5 bg-white">
            {getUnAssignedDatasets().map((ds, i) => <li key={i} className={'text-orange-500 p-1'}>
                {ds.name}
            </li>)}
        </ul>
    </div>;
}