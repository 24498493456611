import auth from './auth';
import {Module} from './data-types';

export enum PermissionModule {
    Core,
    PTPayer,
    PTHospital
}

const moduleNameMap: Record<number, string> = {
    [PermissionModule.Core]: 'core',
    [PermissionModule.PTPayer]: 'Pricing Transparency - Payer',
    [PermissionModule.PTHospital]: 'Pricing Transparency - Hospital'
};

export function checkModuleAccess(module: PermissionModule) {
    if (auth.isExpired())
        return true;

    const moduleName = moduleNameMap[module];
    if (moduleName)
        return auth.hasModule(moduleName);
}

export function getModule(module: PermissionModule): Module | undefined {
    if (checkModuleAccess(module))
        return auth.principal?.modules?.find(m => m.name === moduleNameMap[module]);
}