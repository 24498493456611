import {useEffect, useMemo, useState} from 'react';
import {useParams, useNavigate, Navigate, Link} from 'react-router-dom';
import AuthWall from '../../components/auth-wall';
import Template from '../../components/template';
import {Invitee, Membership, Organization} from '../../lib/data-types';
import {organizations} from '../../lib/api';
import PageTitle from '../../components/page-title';
import Button from '../../components/button';
import Badge from '../../components/badge';
import auth from '../../lib/auth';
import handleError from '../../lib/error';
import MemberForm, {MemberFormData} from './member-form';
import SiteName from '../../components/SiteName';


export default function InviteMember() {
    const [invitees, setInvitees] = useState<Array<Invitee>>([]);
    const [org, setOrg] = useState<Organization>();
    const [membership, setMembership] = useState<Membership>();
    const [loading, setLoading] = useState<boolean>(true);
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [memberFormData, setMemberFormData] = useState<MemberFormData>();
    const [inviteSent, setInviteSent] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const {orgId} = useParams<{orgId: string}>();
    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();
        const promises = [];

        promises.push(organizations.users.listInvitees(Number(orgId), controller.signal)
            .then(data => setInvitees(data.invitees)));

        promises.push(organizations.get(Number(orgId), controller.signal)
            .then(data => setOrg(data)));

        promises.push(organizations.users.get(Number(orgId), auth.principal!.sub, controller.signal)
            .then(user => {
                setMembership({
                    orgId: orgId!,
                    userId: user.id,
                    isModerator: user.isModerator,
                    inviteCount: user.inviteCount || 1,
                    isInactive: user.isInactive,
                    invitesSent: user.invitesSent
                });
            }).catch(e => handleError(e, setErrorMessage)));

        Promise.all(promises).finally(() => setLoading(false));

        return () => controller.abort();
    }, [orgId]);

    const canInvite = useMemo(() => (auth.hasRole('admin') ||
            (org && org.adminUsers!.some(admin => admin === auth.principal?.sub)) ||
            membership?.isModerator ||
            auth.hasRole('admin'))
    , [org, membership]);


    if (!canInvite && !loading)
        return <Navigate replace to="/" />;

    function sendInvitation() {
        const inviteForm = {
            name,
            email,
            isModerator: memberFormData?.moderator || false,
            inviteCount: memberFormData?.inviteCount || membership?.inviteCount
        };

        organizations.users.invite(Number(orgId), inviteForm)
            .then(res => {
                const tempInvitees = [...invitees];
                tempInvitees.push(res);
                setInvitees(tempInvitees);
                setInviteSent(true);
            })
            .catch(e => handleError(e, setErrorMessage));
    }

    function canSendInvitation() {
        return (membership && membership.invitesSent! >= membership.inviteCount) || inviteSent;
    }

    return <AuthWall>
        <Template showFooter>
            <PageTitle name={<div>Invite Member</div>} />
            <div className="relative w-full flex items-center flex-col py-10 bg-gray-100" style={{minHeight: '65vh'}}>
                <div className="w-1/2 absolute">
                    <Button color={'white'} onClick={() => navigate(-1)}><i className="icon-arrow-left"/> Back</Button>
                </div>
                {org && <Badge color={'orange'} pill>{org.name}</Badge>}
                {errorMessage && <div className="w-1/2 text-red-500 mt-5">{errorMessage}</div>}
                {invitees && membership && <div className="w-1/2 mt-10 bg-white p-5 border shadow-xl">
                    <h1>Invitees</h1>
                    <Badge color={'light'} pill>{membership.invitesSent} / {membership.inviteCount} invitations sent</Badge>
                    <table className="border-collapse table-auto w-full my-3">
                        <thead>
                            <tr>
                                <th className="border-b text-left">Name</th>
                                <th className="border-b text-left">Email</th>
                                <th className="border-b text-left">Status</th>
                                <th className="border-b text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invitees.map((invitee, i) => <tr key={i}>
                                <td className="py-2">{invitee.name}</td>
                                <td className="py-2">{invitee.email}</td>
                                <td className="py-2">{invitee.status}</td>
                                <td className="py-2">
                                    <Link to={`/org/${orgId}/membership/${invitee.id}`} className="button bg-gray-100 text-black hover:no-underline"><i className="icon-user" /> Manage Membership</Link>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                    <hr />
                    <h1>Invite Somebody</h1>
                    <div className="flex flex-col space-y-5">
                        <div className="flex flex-col space-y-1">
                            <label>Name</label>
                            <div>
                                <input type="text" value={name} onChange={e => setName(e.target.value)} />
                            </div>
                        </div>
                        <div className="flex flex-col space-y-1">
                            <label>Email</label>
                            <div>
                                <input type="text" value={email} onChange={e => setEmail(e.target.value)}/>
                            </div>
                        </div>
                        {org && membership && <MemberForm onChange={data => setMemberFormData(data)}
                            allowAdmin={false}
                            isAdmin={false}
                            allowModerator={org.allowModerators!}
                            isModerator={false}
                            inviteCount={0}
                            invitesSent={membership.invitesSent!} />}
                        <div>
                            <Button color={'orange'} onClick={sendInvitation} disabled={canSendInvitation()}>Invite</Button>
                            {inviteSent && <div className="text-green-500 mt-3">Invitation sent</div>}
                        </div>
                        <small>Invited users will receive an email containing a special link that will set up their <SiteName /> account.</small>
                    </div>
                </div>}
            </div>
        </Template>
    </AuthWall>;
}