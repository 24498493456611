import React, {ReactNode} from 'react';
import './badge.scss';
import classNames from '../../lib/class-names';

type Props = {
    color: string,
    pill?: boolean,
    outlined?: boolean,
    className?: string,
    children?: ReactNode,
    onClose?: () => void,
    onClick?: () => void,
};

export default function Badge(props: Props) {
    const {color, pill, outlined, className, onClose, onClick} = props;
    const classes = classNames('badge', color, {pill, outlined, 'cursor-pointer': !!onClick}, className);
    return <div data-testid="badge-parent" className={`${classes} inline-block`} onClick={onClick}>
        <div className="h-full flex items-center space-x-1">
            <div className="content flex-grow pl-1">
                {props.children}
            </div>
            {onClose && <div><button onClick={e => {e.stopPropagation(); onClose && onClose();}}><i className="icon-times" /></button></div>}
        </div>
    </div>;
}