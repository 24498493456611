import {observer} from 'mobx-react';
import {useContext} from 'react';
import {CanvasInsertContext} from '../../../lib/context';
import SingleValueRenderer from '../../../../../insight-container/single-value';
import Select from '../../../../../components/select';
import StandardDataRenderer, {StandardRenderChildProps} from '../../../../../insight-container/renderers/standard-data-renderer';

function ConfigSingleValueVis(props:StandardRenderChildProps) {
    const insertStore = useContext(CanvasInsertContext);

    return <div className='flex flex-row gap-4'>
        <div className='flex flex-col gap-4'>
            <div className="flex flex-col space-y-2">
                <label htmlFor="variable" className="text-sm font-medium text-gray-700">Variable</label>
                <Select data={props.metadata.map(v => v.name)} value={insertStore.variableName} onChange={e => insertStore.variableName = e}>
                    {(value:string) => value}
                    {(value:string) => <div>{value}</div>}
                </Select>
            </div>
            <div className="flex flex-col space-y-2">
                <label htmlFor="extratext" className="text-sm font-medium text-gray-700">Extra Text</label>
                <input type="text" name="extratext" id="extratext" 
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" 
                    value={insertStore.extraText} 
                    onChange={(e) => insertStore.extraText = e.target.value} />
            </div>
        </div>
        <div className='w-64'>
            <SingleValueRenderer dataConfig={insertStore.dataConfig} />
        </div>
    </div>;
}

function ConfigSingleValue() {
    const insertStore = useContext(CanvasInsertContext);
    
    return <StandardDataRenderer dataConfig={insertStore.dataConfig}>
        {ConfigSingleValueVis}
    </StandardDataRenderer>;
}

export default observer(ConfigSingleValue);
