import React from 'react';
import EndpointDoc from '../api/endpoint';

export default function CrmApiPdfEndpoints() {
    return <div>
        <h2>Endpoint</h2>
        <p>
            This endpoint generates a PDF containing charts comparing the CRM data for a particular region, disease group, and subpopulation.
        </p>
        <EndpointDoc method="GET" path="/api/pdf/v/5/:geoLevel/:regionCode/:diseaseGroup/:subpopulation/crm.pdf"
            description="Returns a generated PDFs for the specified CRM data." />

        <h2>Parameters</h2>
        <h3>geoLevel</h3>
        <p>
            The following are valid values for the <code>:geoLevel</code> parameter.
        </p>
        <ul className="list-disc list-inside">
            <li><code>state</code> - State</li>
            <li><code>cd</code> - Congressional District</li>
            <li><code>county</code> - County</li>
        </ul>

        <h3>regionCode</h3>
        <p>The <code>:regionCode</code> parameter specifies the individual region. Use the following as a guide:</p>
        <ul className="list-disc list-inside">
            <li><code>state</code> - Use the two-digit state FIPS code. You may exclude leading zeros.</li>
            <li><code>cd</code> - Use the two-digit state FIPS code followed by the congressional district number as a three-digit number. For states with only one congressional district, use the number <code>000</code>. You may exclude the state leading zeros.</li>
            <li><code>county</code> - Use the five-digit county FIPS code (two-digit state FIPS followed by three-digit county FIPS). You max exclude the state leading zeros.</li>
        </ul>

        <h3>diseaseGroup</h3>
        <p>
            The following are valid values for the <code>:diseaseGroup</code> parameter.
        </p>
        <ul className="list-disc list-inside">
            <li><code>t2d_all</code> - Type-2 Diabetes</li>
            <li><code>t2d_cvd</code> - Type-2 Diabetes, Cardiovascular Disease, and Type-2 Diabetes with Cardiovascular Disease</li>
            <li><code>t2d_ckd</code> - Type-2 Diabetes, Chronic Kidney Disease (Stages 3 &amp; 4), and Type-2 Diabetes with Chronic Kidney Disease (Stages 3 &amp; 4)</li>
            <li><code>t2d_chf</code> - Type-2 Diabetes, Heart Failure, Type-2 Diabetes with Heart Failure</li>
            <li><code>cvd_all</code> - Cardiovascular Disease</li>
            <li><code>ckd_all</code> - Kidney Disease (Stage 3 &amp; 4)</li>
            <li><code>chf_all</code> - Heart Failure</li>
            <li><code>chf_ckd</code> - Heart Failure, Chronic Kidney Disease (Stages 3 &amp; 4), and Heart Failure with Chronic Kidney Disease (Stages 3 &amp; 4)</li>
        </ul>

        <h3>subpopulation</h3>
        <p>
            The following are valid values for the <code>:subpopulation</code> parameter.
        </p>
        <ul className="list-disc list-inside">
            <li><code>tot</code> - Total/All</li>
            <li><code>male</code></li>
            <li><code>female</code></li>
            <li><code>white</code></li>
            <li><code>black</code></li>
            <li><code>hispanic</code></li>
            <li><code>other</code></li>
        </ul>
    </div>;
}