// src/pages/network-optimization/index.tsx
import React, {useContext, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Outlet, useLocation} from 'react-router-dom';
import {NetworkContext} from './lib/context';
import AuthWall from '../../components/auth-wall';
import Header from './components/header';
import {useNavigate} from 'react-router';
import {HeaderContext} from '../../components/header/context';

const NetworkOptimizationViewPage: React.FC = observer(() => {
    const networkOptimizationStore = useContext(NetworkContext);
    const headerStore = useContext(HeaderContext);
    const navigate = useNavigate();
    const location = useLocation();

    headerStore.useNewDesign = true;
    
    useEffect(() => {
        if (location.pathname === '/network-optimization' && !networkOptimizationStore.runId) {
            navigate('/network-optimization/my-files');
        }
    }, [navigate, location.pathname]);

    return (
        <AuthWall>
            <NetworkContext.Provider value={networkOptimizationStore}>
                <div className="network-optimization-page">
                    <header className="header">
                        <Header />
                    </header>
                    <Outlet />
                </div>
            </NetworkContext.Provider>
        </AuthWall>
    );
});
export default NetworkOptimizationViewPage;
