import {deserialize, GraphVersions, QuantumGraph, TestType} from 'quantum-graph';
import Variable from '../../../../lib/metadata';
import {RegionFields} from '../constants';
import {QueryObj} from '../../../../lib/data-types';

export default function buildCommonCoreQueryRAD(regionType: string, lob: string, variables: Array<Variable>): QuantumGraph {
    const dataset = 'common-core-benchmarks-metrics-' + regionType;

    const columns = [
        ...RegionFields[regionType],
        'setting',
        'subcategory',
        'line_of_business',
    ];
    for (const v of variables)
        if (!columns.includes(v.name))
            columns.push(v.name);

    const dim: Array<any> = [];
    if (regionType === 'county') {
        dim.push({
            id: 'state',
            dataset: 'state',
            columns: ['state'],
        });
    }

    const obj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: 'dim',
                tableNodes: [
                    ...dim,
                    {
                        id: 'cc',
                        dataset,
                        columns,
                    }
                ]
            }
        },
        filters: [
            {
                column: {nodeId: 'cc', column: 'data_source'},
                test: TestType.Eq,
                value: 'RAD',
            },
            {
                column: {nodeId: 'cc', column: 'line_of_business'},
                test: TestType.Eq,
                value: lob,
            },
        ],
    };

    return deserialize(obj);
}