import {QuantumGraph} from 'quantum-graph';
import {V1QuantumGraph} from 'quantum-graph/out/v1';
import Library from './index';
import V1Library from './v1-library';
import {DataDefRepo} from '../user-data-defs';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import V2Library from './v2-library';
import {DatasetDefinition} from 'torch-data-defs';

export default function createLibrary(graph: QuantumGraph, dataDefRepo: DataDefRepo, connectingFacts?: Array<DatasetDefinition>): Library {
    if (!graph)
        return new Library();
    if (graph instanceof V1QuantumGraph)
        return new V1Library(graph, dataDefRepo);
    else if (graph instanceof V2QuantumGraph)
        return new V2Library(graph, dataDefRepo, connectingFacts);
    throw new Error('Unsupported graph version: ' + graph.getVersion());
}