import React from 'react';

export const links = [
    {name: <div className="flex"><img className="h-5 mr-2" src="/assets/tool-discovery/torch-core-blue.png" alt="Core" />Core</div>, href: '#Core'},
    {name: <div className="flex"><img className="h-5 mr-2" src="/assets/tool-discovery/torch-target-blue.png" alt="Target" />Target</div>, href: '#Target'},
    {name: <div className="flex"><img className="h-5 mr-2" src="/assets/tool-discovery/torch-network-blue.png" alt="Network" />Network</div>, href: '#Network'}
];

export type ToolDiscovery = {
    name: string,
    img: string,
    description: string,
    buttonText?: string,
    buttonLink?: string,
    authRole?: string
}

export type ToolDiscoveryCategory = {
    name: string,
    img: string,
    titleText: string
    tools: Array<ToolDiscovery>
}

const toolDiscoveryData: Array<ToolDiscoveryCategory> = [
    {
        name: 'Core',
        img: '/assets/tool-discovery/torch-core.png',
        titleText: 'The Torch Insight essentials for understanding markets, tracking value-based care, and querying data.',
        tools: [
            {
                name: 'Population Health Map',
                img: '/assets/tool-discovery/globe.png',
                description: 'Visualize population demographic data transform over time, layered with health care organizations. Built for market-oriented strategists.',
                buttonText: 'View Map',
                buttonLink: `//${process.env.REACT_APP_TI_DOMAIN}/pophealth`,
                authRole: 'accessPopHealthMap'
            },
            {
                name: 'Saved Maps',
                img: '/assets/tool-discovery/map.png',
                description: 'Access your saved, custom maps with pre-built filters.'
            },
            {
                name: 'Dashboards and Custom Reports',
                img: '/assets/tool-discovery/dashboard.png',
                description: 'Prebuilt and custom reports on ACOs, health systems, MA expansion, physician groups and more. Built for executives in a hurry.',
                buttonText: 'Access Dashboards',
                buttonLink: `//${process.env.REACT_APP_TI_DOMAIN}/user/dashboards`,
                authRole: 'listDashboards'
            },
            {
                name: 'Data Glossary',
                img: '/assets/tool-discovery/database.png',
                description: 'Search for specific metrics, identify data sources and update frequency. Trust the data.',
                buttonText: 'Data Glossary',
                buttonLink: `//${process.env.REACT_APP_TI_DOMAIN}/glossary`,
                authRole: 'accessMetadata'
            },
            {
                name: 'Data Discovery',
                img: '/assets/tool-discovery/magnify.png',
                description: 'Build and export custom lists of ACOs, health systems, with custom metrics. Built for savvy analysts.',
                buttonText: 'Access Data',
                buttonLink: `//${process.env.REACT_APP_TI_DOMAIN}/discover`,
                authRole: 'accessEntityExplorer'
            }
        ]
    },
    {
        name: 'Target',
        img: '/assets/tool-discovery/torch-target.png',
        titleText: 'The Torch Insight suite of tools for expanding sales teams.',
        tools: [
            {
                name: 'Territory Builder',
                img: '/assets/tool-discovery/tools.png',
                description: 'Choose your specific territory and access a pre-built report on key stakeholders and population metrics.',
                buttonText: 'Start Building',
                buttonLink: `//${process.env.REACT_APP_TI_DOMAIN}/market-select`,
                authRole: 'listDashboards'
            },
            {
                name: 'Hospital DRG Targeter',
                img: '/assets/tool-discovery/ekg.png',
                description: 'Build a list of hospitals based on their volume of a specific DRG or ICD.',
                buttonText: 'Find Hospitals',
                buttonLink: `//${process.env.REACT_APP_TI_DOMAIN}/lookup`,
                authRole: 'accessDrg'
            },
            {
                name: 'Hospital DRG Report',
                img: '/assets/tool-discovery/stethoscope.png',
                description: 'Choose a DRG and pull a national report on hospitals and counties with the highest prevalence of that DRG.',
                buttonText: 'Build a Report',
                buttonLink: `//${process.env.REACT_APP_TI_DOMAIN}/lookup-report`,
                authRole: 'accessDrg'
            }
        ]
    },
    {
        name: 'Network',
        img: '/assets/tool-discovery/torch-network.png',
        titleText: 'Analytic tools for network and clinical care analysis. Be more efficient.',
        tools: [
            {
                name: 'EOC & Efficiency Ranker',
                img: '/assets/tool-discovery/eoc.png',
                description: 'Select an Episode of Care, a target market, and rank hospitals and providers by efficiency.',
                buttonText: 'Access Tool',
                buttonLink: `//${process.env.REACT_APP_TI_DOMAIN}/eoc`,
                authRole: 'queryEOC'
            },
            {
                name: 'Patient Flow',
                img: '/assets/tool-discovery/flow.png',
                description: 'Show the flow of patients post-discharge across all players in a market.',
                buttonText: 'Explore Patient Flow',
                buttonLink: `//${process.env.REACT_APP_TI_DOMAIN}/patient-flow`,
                authRole: 'accessPatientFlow'
            },
            {
                name: 'Commercial Reimbursement Benchmarks',
                img: '/assets/tool-discovery/magnify.png',
                description: 'The Commercial Reimbursement Benchmarks provide reference data on average Commercial Allowed amounts by region and service category.',
                buttonText: 'Access',
                buttonLink: '/cr-benchmarks',
                authRole: 'accessCRBenchmarks'
            },
        ]
    }
];

export default toolDiscoveryData;