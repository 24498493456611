import {observer} from 'mobx-react';
import FilterStepMultiDropdown from '../../../../../components/filter-steps/common-steps/filter-step-multi-dropdown';
import {useContext} from 'react';
import FilterStepsContext from '../../../../../components/filter-steps/libs/context';

const resyncVariables = ['facilityList', 'providerGroupList', 'dataView', 'useUATEnvironment'];

const PayerFilterStep = observer(() => {
    const filterStepsStore = useContext(FilterStepsContext);

    return <div className="pb-5">
        <p>Payer organizations are listed as reported on price transparency files published by individual facilities selected in the above configuration steps. Variation in payer naming conventions and contract reporting approaches at the facility level may result in duplication of payer organizations.</p>
        <div className="flex items-center justify-between pt-3">
            <FilterStepMultiDropdown label="Payer Organization" filterStoreVariable="payers" variable="payer" placeholder="Select Payer Organization" popupWidth={1200} getDropdownQuery={(v) => filterStepsStore.getPayerDropdownQuery(v)} resyncVariables={resyncVariables}/>
            <FilterStepMultiDropdown label="Line of Business" filterStoreVariable="lob" variable="lob" placeholder="Select Line of Business" getDropdownQuery={(v) => filterStepsStore.getPayerDropdownQuery(v)} resyncVariables={resyncVariables} />
            <FilterStepMultiDropdown label="Product" filterStoreVariable="products" variable="product" placeholder="Select Product" getDropdownQuery={(v) => filterStepsStore.getPayerDropdownQuery(v)} resyncVariables={resyncVariables} />
        </div>
    </div>;
});

export default PayerFilterStep;