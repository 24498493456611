import React, {useMemo} from 'react';
import {Module} from '../../../../../lib/data-types';
import Toggle from '../../../../../components/toggle';

type Props = {
    modules: Array<Module>,
    allModules: Array<Module>,
    toggle: (module: Module) => void,
}

export default function StandardModulesAdmin(props: Props) {
    const {modules, toggle} = props;

    const moduleList = useMemo(() => {
        const modules = props.allModules.filter(m => !m.groupName);
        modules.sort((a, b) => a.id! - b.id!);
        return modules;
    }, [props.allModules]);

    const toggled = useMemo(() => {
        const toggled: Record<string, boolean> = {};
        for (const module of moduleList)
            toggled[module.id!] = modules.some(m => m.id === module.id);
        return toggled;
    }, [moduleList, modules]);

    return <div>
        <div className="text-blue-500 my-2">Standard Modules</div>
        <div className="px-4 border-l">
            <table>
                <tbody>
                    {moduleList.map(mod => <tr key={mod.id}>
                        <td><Toggle checked={toggled[mod.id!]} onChange={() => toggle(mod)} /></td>
                        <td>[{mod.id}]</td>
                        <td className="text-gray-400">{mod.name}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </div>;
}