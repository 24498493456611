import {DataSources, LinesOfBusiness, RegionTypes, ServiceCategories, Years} from './constants';

type CommonCoreParams = {
    year?: string,
    lob?: string,
    dataSource?: string,
    regionType?: string,
    memberCategory?: string,
    conditionCategory?: string,
    serviceCategory?: string,
    src?: string,
    focusLevel?: string,
    demoBreakout?: boolean,
}

export function buildParamKey(year: string, lob: string, dataSource: string, regionType: string, memberCategory: string, conditionCategory: string, serviceCategory: string, src: string, focusLevel: string, demoBreakout: boolean): string {
    return [
        Years.indexOf(year),
        LinesOfBusiness.indexOf(lob),
        DataSources.indexOf(dataSource),
        RegionTypes.indexOf(regionType),
        ServiceCategories.indexOf(serviceCategory),
    ].join(',') + `:${memberCategory || '_'}:${conditionCategory || '_'}:${src || '_'}:${focusLevel || '_'}:${demoBreakout ? 'breakout' : 'total'}`;
}

export function extractParamKey(key: string): CommonCoreParams {
    if (!key)
        return {};
    const [indexString, memberCategory, conditionCategory, src, focusLevel, breakout] = key.split(':');
    const [yearI, lobI, dsI, regionI, serviceCatI] = indexString.split(',').map(i => Number(i));

    return {
        year: Years[yearI],
        lob: LinesOfBusiness[lobI],
        dataSource: DataSources[dsI],
        regionType: RegionTypes[regionI],
        memberCategory: memberCategory === '_' ? '' : memberCategory,
        conditionCategory: conditionCategory === '_' ? '' : conditionCategory,
        serviceCategory: ServiceCategories[serviceCatI],
        src: src === '_' ? '' : src,
        focusLevel: focusLevel === '_' ? '' : focusLevel,
        demoBreakout: breakout === 'breakout',
    };
}

