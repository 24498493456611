import {QuantumGraph} from 'quantum-graph';
import Variable from '../../metadata';
import {V1QuantumGraph} from 'quantum-graph/out/v1';
import updateV1GraphSelection from './v1/v1-selection';
import {DataDefRepo} from '../../user-data-defs';
import Library, {Section} from '../../library';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import updateV2GraphSelection from './v2/v2-selection';
import {V2Section} from '../../library/v2-library';


export default function updateGraphSelection(graph: QuantumGraph, metadata: Array<Variable>, change: Array<Variable>, library: Library, dataDefRepo: DataDefRepo, currentSection?: Section): [QuantumGraph, Array<Variable>] {
    if (graph instanceof V1QuantumGraph) {
        return updateV1GraphSelection(graph, metadata, change, library, dataDefRepo);
    } else if (graph instanceof V2QuantumGraph) {
        return updateV2GraphSelection(graph, metadata, change, library, dataDefRepo, currentSection as V2Section);
    }
    throw new Error('Unsupported version');
}