import {observer} from 'mobx-react';
import {useContext} from 'react';
import {CanvasContext} from '../lib/context';
import Button from '../../../components/button';
import auth from '../../../lib/auth';

type Props = {
    pulse?: boolean,
    disable?: boolean,
    icon?: string,
    text: string,
    color: string,
    onClick: () => void,
}
const AuthoringButton = observer((props: Props) => {
    const {pulse, disable, icon, text, color, onClick} = props;
    const store = useContext(CanvasContext);

    return <div className="relative p-1">
        {pulse && <div className="red-dot pulse"></div>}
        <Button className="w-full" color={color} disabled={store.saving || disable} onClick={onClick}>
            {icon && <i className={icon + ' mr-1'}/>} {text}
        </Button>
    </div>;
});

const AuthoringPanel = observer(() => {
    const store = useContext(CanvasContext);

    return <div className="fixed bottom-12 right-5 z-50 flex flex-col align-center space-y-3 bg-gray-500 bg-opacity-30 p-3 rounded-lg shadow">
        {auth.principal!.accessRoles.includes('publishCanvas') && <AuthoringButton text="Publish" icon="icon-paint" color="green" disable={store.loading} onClick={() => store.publish()} />}
        <AuthoringButton text="Save Draft" icon="icon-save" color="blue" pulse={!store.pristine} disable={store.loading || store.pristine} onClick={() => store.save()} />
        <AuthoringButton text={store.editing ? 'Preview' : 'Edit'} icon={store.editing ? 'icon-chart-bar' : 'icon-edit'} color="blue" onClick={() => store.toggleEdit(location.search)}/>
        <AuthoringButton text="Copy" icon="icon-notebook" color="blue" onClick={() => store.toggleCopyModal()} />
        <AuthoringButton text="Versions" icon="icon-chart-tree" color="blue" onClick={() => store.showVersionModal = true} />
    </div>;
});

export {
    AuthoringPanel,
    AuthoringButton,
};