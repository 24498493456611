import {dataRows, dataRow} from '../../lib/data-types';

export type DRGCode = {
    drg: number;
    diagnosis_related_group_description: string;
    MCC?: boolean;
    CC?: boolean;
}

export type MDCCode = {
    mdc: number;
    major_diagnostic_category_description: string;
    drgCodes: DRGCode[];
}

export type DRGDataFilter = [(mdc: MDCCode) => boolean, (mdc: MDCCode) => MDCCode]
export type DRGDataFilters = Record<string, DRGDataFilter>

export default class DRGData {
    mdcCodes: MDCCode[];
    drgCodes: DRGCode[];

    constructor(data:dataRows) {
        const createDRG = (r: dataRow) => ({
            drg: r['drg'], 
            diagnosis_related_group_description: r['diagnosis_related_group_description'],
            MCC: !!r['diagnosis_related_group_description']?.toString().match(/(W MCC)|(W CC\/MCC)/g),
            CC: !!r['diagnosis_related_group_description']?.toString().match(/(W CC)|(W CC\/MCC)/g)
        } as DRGCode);
        this.drgCodes = data.map(createDRG);
        
        const mdcs = Array.from(new Set(data.map(r => r['major_diagnostic_category_description'])));
        this.mdcCodes = mdcs.map((c,i) => ({
            mdc: i, 
            major_diagnostic_category_description: c, 
            drgCodes: data.filter(r => r['major_diagnostic_category'] === i)
                .map(createDRG)
        } as MDCCode));
    }

    applyFilters(filters:DRGDataFilters) : MDCCode[] {
        let filteredCodes = this.mdcCodes;

        Object.keys(filters).forEach(filterName => {
            const [mdcFilter, drgFilter] = filters[filterName];
            filteredCodes = filteredCodes.filter(mdcFilter).map(drgFilter);
        });

        return filteredCodes;
    }
}