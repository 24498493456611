import {Edge, Node, Position} from 'reactflow';
import dagre from 'dagre';
import FacilityNode from './facility-node';
import {PlotTheme, getTextWidth} from '../charts/common';
import {dataRows} from '../../lib/data-types';
import SystemNode from './system-node';

const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = 'TB', defaultNodeWidth = 400, defaultNodeHeight = 66) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({rankdir: direction, ranker: 'tight-tree', ranksep: 50, nodesep: 10});

    nodes.forEach((node) => {
        const width =  node.width || (typeof node.style?.width === 'number' && node.style.width) || defaultNodeWidth;
        const height = node.height || (getTextWidth(node.data.label) > (width - 20) ? defaultNodeHeight + 21 : defaultNodeHeight);
        dagreGraph.setNode(node.id, {width: width, height: height});
    });
  
    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });
  
    dagre.layout(dagreGraph);
  
    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? Position.Left : Position.Top;
        node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
  
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
            x: nodeWithPosition.x - nodeWithPosition.width / 2,
            y: nodeWithPosition.y - nodeWithPosition.height / 2,
        };
  
        return node;
    });
  
    return {nodes, edges};
};

const edgeStyle = {
    style: {
        strokeWidth: 4,
        stroke: 'rgb(31 41 55)',
    },
    type: 'smoothstep',
};

function createSystemNodes(id: string, name: string, position: {x: number, y: number}, facilities: any[] = []): Node {
    const systemNode: Node = {
        id: id,
        position: position,
        width: 400,
        data: {
            label: name,
            facilities: facilities,
        },
        style: {
            fontSize: PlotTheme.fontSize,
            fontFamily: PlotTheme.fontFamily,
            width: 400,
        },
        type: 'system'
    };

    return systemNode;
}

/**
 * Converts flat data into a network graph
 * @param rows
 * 
 */
function convertToNetwork(rows: dataRows) {
    const links: Edge[] = [];
    const systemNodeMap = new Map<string, Node>();

    for (const row of rows) {
        const ultimateOwner = row['ultimate_owner_torch_health_system_id']!.toString();
        const ultimateNode = systemNodeMap.get(ultimateOwner);

        if (!ultimateNode) {
            systemNodeMap.set(ultimateOwner, createSystemNodes(
                ultimateOwner, 
                row['ultimate_owner_health_system_name']!.toString(), 
                {x: 25, y: 0}
            ));
        }

        for (let i = 0; i < 7; i++) {
            const target = row[i === 0 ? 'torch_facility_id' : 'torch_health_system_id_' + i]!.toString();
            const source = row['torch_health_system_id_' + (i + 1)]?.toString();

            if (!source)
                break;

            const sourceNode = systemNodeMap.get(source);

            if (!sourceNode) {
                systemNodeMap.set(source, createSystemNodes(
                    source, 
                    row['health_system_name_' + (i + 1)]!.toString(), 
                    {x: (systemNodeMap.size * 250) + 25, y: (i + 1) * 150}, 
                    i === 0 ? [{name: row['facility_name'], id: row['torch_facility_id']}] : []
                ));
            } else { //if (i === 0) {
                sourceNode.data.facilities.push({name: row['facility_name'], id: row['torch_facility_id']});
            }

            if (i !== 0 && !links.some(l => l.source === source && l.target === target)) {
                links.push({
                    id: 'e' + source + target,
                    source: source,
                    target: target,
                    ...edgeStyle
                });
            }
        }
    }
    return {
        nodes: Array.from(systemNodeMap.values()).flat(),
        links,
    };
}

function addAffiliationDataToNetwork(affData: {[key: string]: dataRows}, nodes: Node[], links: Edge[]): Node[] {
    let newNodes = nodes.slice();
    for (const node of newNodes) {
        let parents = findParents(node, links, nodes);
        for (const key of Object.keys(affData)) {
            if (key === 'facilities')
                continue;
            const affRows = affData[key];
            const affRow = affRows.filter(r => r['torch_health_system_id']?.toString() === node.id);

            if (affRow) {
                node.data[key] = node.data[key] ? node.data[key].concat(affRow) : affRow;
                parents.forEach(p => {
                    let n = newNodes.find(n => n.id === p.id);
                    if (n)
                        n.data[key] = n.data[key] ? n.data[key].concat(affRow) : affRow;
                });
            }
        }
    }
    return newNodes;
}

function findParents(node: Node, links: Edge[], allNodes: Node[]): Node[] {
    let parents: Node[] = [];
    for (const link of links) {
        if (link.target === node.id) {
            const parent = allNodes.find(n => n.id === link.source);
            if (parent) {
                parents.push(parent);
                const p = findParents(parent, links, allNodes);
                parents = parents.concat(p); 
            }
            
        }
    }
    return parents;
}

const nodeTypes = {facility: FacilityNode, system: SystemNode};

export {
    getLayoutedElements,
    convertToNetwork,
    nodeTypes,
    addAffiliationDataToNetwork,
};