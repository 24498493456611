import BaseMap from '../../../components/base-map/base-map';
import {LayerPropsWithType, Layers} from '../../../components/base-map/layers/types';
import {useContext, useEffect, useMemo, useState} from 'react';
import {FeatureCollection} from 'geojson';
import mapboxConfig from '../../../insight-container/map/map-libs/mapbox-config';
import {NetworkContext} from '../lib/context';
import {CirclePaint} from 'mapbox-gl';
import {observer} from 'mobx-react';
import bbox from '@turf/bbox';
import {BBox2d} from '@turf/helpers/dist/js/lib/geojson';

const INVALID_COLOR = 'rgba(230, 57, 70, 0.6)';
const VALID_COLOR = 'rgba(67, 170, 139, 0.6)';
const NULL_COLOR = 'rgba(79, 93, 117, 0.6)';
const PROPERTY_TO_MAP = 'adequacy';

const DEFAULT_LAYER_STYLE: CirclePaint = {
    'circle-radius': .25, 
    'circle-color': ['match', ['get', PROPERTY_TO_MAP], '1', VALID_COLOR, '0', INVALID_COLOR, NULL_COLOR ] //['interpolate',['linear'],['get', PROPERTY_TO_MAP],0,INVALID_COLOR,1,VALID_COLOR]
};

function createLayers(patientData: FeatureCollection, providerData: FeatureCollection): LayerPropsWithType[] {
    return [{
        id: `layer-1`,
        data: patientData,
        layerType: Layers.circle,
        paint: DEFAULT_LAYER_STYLE,
        cluster: false
    },
    {
        id: `layer-2`,
        data: providerData,
        layerType: Layers.point,
        paint: {'fill-color': 'rgba(255, 238, 147, 1)'},
        cluster: false
    }];
}

function NetworkMap() {
    const [layers, setLayers] = useState<LayerPropsWithType[]>();
    const networkStore = useContext(NetworkContext);

    useEffect(() => {
        const controller = new AbortController();
        networkStore.loadMapGeoJson(controller).then(() => {
            if (!networkStore.patientGeoJson || !networkStore.providerGeoJson)
                return;
            setLayers(createLayers(networkStore.patientGeoJson, networkStore.providerGeoJson));
        });

        // return () => controller.abort();
    }, [networkStore.appliedGeographyFilter, networkStore.specialtyFilter, networkStore.runId, networkStore.revisionCount]);

    const bounds = useMemo(() => bbox(networkStore.providerGeoJson), [networkStore.providerGeoJson]);

    return !networkStore.loadingMapData && layers?.length ?
        <BaseMap style={{width: '100%', height: '100%'}}
            layers={layers}
            viewState={{bounds: bounds as BBox2d, fitBoundsOptions: {padding: 20}}}
            mapStyle={mapboxConfig.darkStyle} />
        : <h2>{networkStore.readyToLoadData() && <span>Loading Map Data...</span>}</h2>;
}

export default observer(NetworkMap);