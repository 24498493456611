import React from 'react';
import Button from '../../../components/button';


export default function MillimanInsights() {
    return <div>
        <h2>Milliman Insights</h2>
        <p>
            Our data for Milliman Insights is stored on a separate external database. If you wish to use the API to download Milliman Insights data send a <code>POST</code> request to the following endpoint:
        </p>
        <code>https://api-ptsynapse.torchinsight.com/v2/quantum/data/execute</code>
        <p>
            This endpoint uses the same headers and post body that our normal API. It only contains Milliman Insights data, for all other data please use our normal endpoint.
        </p>
        <code>https://api-prod.torchinsight.com/v2/quantum/data/execute</code>
        <hr/>
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/api/data-warehouse">Data Warehouse Structure</Button>
        </p>
    </div>;
}