import React, {useCallback, useState} from 'react';
import {useDeepCompareEffect} from 'use-deep-compare';
import {affiliatedEntitySearch, entitySearch} from '../../lib/entity-search';
import {Entity, QueryFilter} from '../../lib/data-types';
import MultiSelectAutocomplete from '../multi-select-autocomplete';
import {getCBSARestrictions} from '../../lib/geo-restrictions';
import {TestType} from 'quantum-graph';

const MIN_SEARCH_LENGTH = 3;

type MultiEntity = Entity & {dataset: string};

type Props = {
    datasets: Array<string>,
    onChange: (entities: Array<MultiEntity>) => void
    values: Array<MultiEntity>,
    placeholder?: string,
    filters?: Array<QueryFilter>,
    affiliationFilter?: {dataset: string, value: string, type: string},
    disabled?: boolean
    width?: number
}

export default function MultiEntityAutocomplete(props: Props) {
    const {datasets, values, placeholder, filters, affiliationFilter, disabled, width} = props;
    const [baseEntities, setBaseEntities] = useState<Array<MultiEntity>>([]);

    async function checkForGeoRestrictions(dataset: string, queryFilters: Array<QueryFilter>) {
        if (dataset === 'cbsa') {
            let restrictions = await getCBSARestrictions();
            if (restrictions.length) {
                queryFilters.push({
                    type: 'basic',
                    column: {
                        nodeId: dataset,
                        column: 'cbsa'
                    },
                    test: TestType.In,
                    value: restrictions
                });
            }
        }
    }

    useDeepCompareEffect(() => {
        const entityPromises: Array<Promise<Array<MultiEntity>>> = [];
        for (const dataset of datasets) {
            let queryFilters: Array<QueryFilter> = filters || [];
            entityPromises.push(checkForGeoRestrictions(dataset, queryFilters).then(async () => {
                let e = await entitySearch(dataset, '', new AbortController().signal, queryFilters);
                if (affiliationFilter) {
                    const affDataset = `${affiliationFilter.dataset}:${dataset}`;
                    const affiliatedEntities = await affiliatedEntitySearch(affDataset, affiliationFilter.value, formatAffiliationCol(`torch_${affiliationFilter.dataset}_id`), affiliationFilter.type, new AbortController().signal);
                    e = e.filter(e => affiliatedEntities.some(ae => e.id.toString() === ae[formatAffiliationCol(`torch_${dataset}_id`)]!.toString()));
                }
                return e.sort((a, b) => a.name.localeCompare(b.name)).map(e => ({...e, dataset}));
            }));
        }
        Promise.all(entityPromises).then(entities => setBaseEntities(entities.flat()));
    }, [datasets, filters, affiliationFilter]);

    const getOptions = useCallback(async (input: string): Promise<Array<string>> => {
        const entities = baseEntities.map(e => e.name);
        if (input.length < MIN_SEARCH_LENGTH)
            return entities;
        return entities.filter(e => e.toLowerCase().includes(input.toLowerCase()));
    }, [baseEntities]);

    function formatAffiliationCol(col: string) {
        return col.replace('-', '_');
    }

    const onChange = (names: Array<string>) => {
        const filteredEntities = baseEntities.filter(e => names.some(n => e.name === n));
        props.onChange(filteredEntities);
    };

    return <MultiSelectAutocomplete onChange={onChange} getOptions={getOptions} placeholder={placeholder} disabled={disabled} width={width} values={values.map(v => v.name)}/>;
}