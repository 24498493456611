import SideNavSection from './section';
import {FolderShared, Home, ToolKit, Hospital, MapIdentify, DataTable} from '@carbon/icons-react';
import {useEffect, useMemo, useState} from 'react';
import SideNavLink from './link';
import Cache from '../../lib/cache';
import {canvas, hcIndex} from '../../lib/api';
import {useLocation, useNavigate} from 'react-router-dom';
import './side-nav.scss';
import handleError from '../../lib/error';
import {HCIndexEntry, HCIndexSection} from '../../pages/tools/hc-index/types';
import {CanvasSectionType} from '../../pages/canvas/lib/types';
import {CacheMiss} from '../../lib/cache';

export const IconMap = new Map([
    ['Entities and Organizations', <Hospital key='1'/>],
    ['Geography', <MapIdentify key='2'/>],
]);

export const getIcon = (title: string) => {
    return IconMap.get(title) || DefaultIcon;
};

const DefaultIcon = <FolderShared />;

export default function SideNav() {
    const [hcIndices, setHcIndices] = useState<HCIndexSection[]>([]);
    const [canvasSections, setCanvasSections] = useState<CanvasSectionType[]>([]);
    const [openSection, setOpenSection] = useState<string | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [err, setErr] = useState('');

    const toggleSection = (sectionName: string) => {
        setOpenSection(openSection === sectionName ? null : sectionName);
    };

    const flattenedHCIndices:HCIndexEntry[] = useMemo(()=>{
        const output:any = [];
        hcIndices.forEach((section:any)=>{
            section.entries.forEach((entry:any)=>output.push(entry));
        });
        return output;
    },[hcIndices]);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const controller = new AbortController();
        const cacheKey = 'canvasSections';

        try {
            const cachedData = Cache.get(cacheKey);
            if (cachedData) {
                setCanvasSections(cachedData as CanvasSectionType[]);
            } 
        } catch (error) {
            if (error instanceof CacheMiss) {
                canvas
                    .listSections(controller.signal)
                    .then((apiSections) => {
                        setCanvasSections(apiSections);
                        Cache.set(cacheKey, apiSections);
                    })
                    .catch((err) => handleError(err, setErr));
            } else {
                handleError(error as Error, setErr);
            }
        }

        return () => controller.abort();
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const cacheKey = 'hcIndices';
        try {
            const cachedData = Cache.get(cacheKey);
            if (cachedData) {
                setHcIndices(cachedData as HCIndexSection[]);
            } 
            
        } catch (error) {
            if (error instanceof CacheMiss) {
                hcIndex
                    .sections(controller.signal)
                    .then((sections: Array<HCIndexSection>) =>
                        sections.filter((s) => s.entries.length || s.links.length),
                    )
                    .then(setHcIndices)
                    .catch((err) => handleError(err, setErr));
            } else {
                handleError(error as Error, setErr);
            }
        }

        return () => controller.abort();
    }, []);


    return (
        <div className='side-nav' onMouseLeave={() => setOpenSection(null)}>
            {err && <p>{err}</p>}
            <SideNavLink name='Home' icon={<Home />} link='/' />
            {canvasSections.map((section) => (
                <SideNavSection
                    key={section.title}
                    name={section.title}
                    icon={getIcon(section.title)}
                    isOpen={openSection === section.title}
                    toggle={toggleSection}
                >
                    {section.links.map((link) => (
                        <div onClick={() => navigate(link.url)} key={link.title} className={link.url === location.pathname ? 'highlight' : ''}>
                            {link.title}
                        </div>
                    ))}
                </SideNavSection>
            ))}
            <SideNavSection
                key='hcIndex'
                name='Build a Dataset'
                icon={<DataTable />}
                isOpen={openSection === 'Build a Dataset'}
                toggle={toggleSection}
            >
                {flattenedHCIndices.map((index)=>(
                    <div key={index.name} onClick={()=>navigate(`/healthcare-index/${index.shortName}`)} className={`/healthcare-index/${index.shortName}` === location.pathname ? 'highlight' : ''}>
                        {index.name}
                    </div>
                ))}
            </SideNavSection>
            <SideNavLink name='Tools' icon={<ToolKit />} link='/tools' />
        </div>
    );
}
