import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Select, {BasicSelectType} from '../../../components/select';
import Variable, {loadDatasetMetadata} from '../../../lib/metadata';
import {affiliationDisplayNames, findConnType, findSecondAffiliations} from '../crosswalk-builder/query';
import buildAffiliationQuery, {getDefaultColumns} from './query';
import {QuantumGraph} from 'quantum-graph';
import {getAffiliationMatches} from 'torch-affiliation-defs';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';
import {HeaderContext} from '../../../components/header/context';


type Props = {
    onSelect: (graph: QuantumGraph, affDetails: {from: string, to: string, type: string}) => void,
    from?: string,
    to?: string,
    type?: string
}

const affiliationMatches = getAffiliationMatches();

export default function AffiliationExplorer(props: Props) {
    const {onSelect, from, to, type} = props;
    const [fromDataset, setFromDataset] = useState<string>(from || '');
    const [toDataset, setToDataset] = useState<string>(to || '');
    const [affType, setAffType] = useState<string>(type || '');
    const [fromSelection, setFromSelection] = useState<Array<Variable>>([]);
    const [toSelection, setToSelection] = useState<Array<Variable>>([]);
    const {useNewDesign} = useContext(HeaderContext);

    const fromData = useMemo(() => {
        return affiliationMatches.map(type => ({
            label: affiliationDisplayNames[type.name],
            value: type.name
        }));
    }, []);

    const toData = useMemo(() => {
        if (!fromDataset)
            return [];
        return findSecondAffiliations(fromDataset);
    }, [fromDataset]);

    const connTypeData = useMemo(() => {
        if (!fromDataset || !toDataset)
            return [];
        return findConnType(fromDataset, toDataset);
    }, [fromDataset, toDataset]);

    useEffect(() => {
        const controller = new AbortController();
        if (fromDataset) {
            loadDatasetMetadata(fromDataset, controller.signal).then(vars => {
                setFromSelection(getDefaultColumns(vars));
            });
        }
        return () => controller.abort();
    }, [fromDataset]);

    useEffect(() => {
        const controller = new AbortController();
        if (toDataset) {
            loadDatasetMetadata(toDataset, controller.signal).then(vars => {
                setToSelection(getDefaultColumns(vars));
            });
        }
        return () => controller.abort();
    }, [toDataset]);

    useEffect(() => {
        if (fromDataset && fromSelection.length && toDataset && toSelection.length && affType) {
            const query = buildAffiliationQuery(fromDataset, fromSelection, toDataset, toSelection, affType, getDefaultDataDefRepo());
            onSelect(query, {from: fromDataset, to: toDataset, type: affType});
        }
    }, [fromDataset, fromSelection, toDataset, toSelection, affType]);

    const setPrimary = useCallback((ds: string) => {
        setAffType('');
        setToDataset('');
        setFromDataset(ds);
    }, []);

    const setSecondary = useCallback((ds: string) => {
        setAffType('');
        setToDataset(ds);
    }, []);

    return <div>
        <div className={`flex ${useNewDesign ? 'gap-2' : ''}`}>
            <div className="w-1/4">
                <div className={`font-semibold uppercase text-sm tracking-wide ${useNewDesign ? '' : 'text-blue-500'} mt-2`}>
                    From
                </div>
                <Select<BasicSelectType> data={fromData} value={fromDataset} onChange={setPrimary}>
                    {_ => _.value}
                    {_ => _.label}
                </Select>
            </div>
            <div className="w-1/4">
                <div className={`font-semibold uppercase text-sm tracking-wide ${useNewDesign ? '' : 'text-blue-500'} mt-2`}>
                    To
                </div>
                <Select<BasicSelectType> data={toData} value={toDataset} onChange={setSecondary} disabled={!fromDataset}>
                    {_ => _.value}
                    {_ => _.label}
                </Select>
            </div>
            <div className="w-1/4">
                <div className={`font-semibold uppercase text-sm tracking-wide ${useNewDesign ? '' : 'text-blue-500'} mt-2`}>
                    Connection Type
                </div>
                <Select<BasicSelectType> data={connTypeData} value={affType} onChange={setAffType} disabled={!toDataset}>
                    {_ => _.value}
                    {_ => _.label}
                </Select>
            </div>
        </div>
    </div>;
}