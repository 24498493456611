import {makeAutoObservable} from 'mobx';
import {deserialize, QuantumGraph} from 'quantum-graph';
import {ChartStore} from '../../../insight-container/charts/context';
import Filter from '../../../lib/filter';
import {StringFormatter} from '../../../lib/formatter';
import createGraphHandler from '../../../lib/graph-tools/graph-handler/create';
import Variable from '../../../lib/metadata';
import CanvasStore from './store';
import {
    ConditionalRender as ConditionalRenderType,
    CvData,
    CvInsertQueryType,
    CvInsertScreen,
    CvInsertVisType,
    ICConfig,
    InaccessibleViewType,
    ParameterConfig,
} from './types';
import {ConditionalRender, Parameter} from '../components/insert-modal/ic-configs';
import mapOptions from './mapOptions';
import {isMapConfig, validateQuery} from './util';
import {DatasetDefinition} from 'torch-data-defs';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';
import {RowCountOperators} from './row-count-compare';


/**
 * The `CanvasInsertStore` class represents the store for the insert modal in the canvas page.
 */
export default class CanvasInsertStore {
    private _visConfig: ICConfig | undefined;
    private _hcIndexReference: string | undefined;
    private _connectingFacts: Array<DatasetDefinition> | undefined;
    private _visTitle = '';
    private _stickyColumns: Array<string> = [];
    private _hiddenColumns: Array<string> = [];
    private _noRowMessage: string | undefined;
    private _prevOptions: any = {};
    private _queryCount = 0;
    private _inaccessibleViewType: InaccessibleViewType | undefined;
    screen: CvInsertScreen;
    visScreenIndex = 0;
    queryType: CvInsertQueryType | undefined;
    queries: QuantumGraph[] = [];
    canvasStore: CanvasStore | undefined;
    chartContext: ChartStore | undefined;
    isAffiliationQuery = false;
    datasetColorsEnabled = true;
    parameterConfigs: Array<ParameterConfig> = [];
    parameterIdField = '';
    parameterSearchField = '';
    private _parameterSearchFields: Array<Variable> = [];
    parameterFuzzySearch = false;
    parameterMinSearchChars: number | undefined;
    parameterKeyDelay: number | undefined;
    parameterDisplayTemplate = '';
    conditionalRenderOptions: ConditionalRenderType = {
        queryObj: {},
        operator: RowCountOperators.equal,
        rowCount: 0,
        showLoading: false
    };
    templateText = '';
    mapOptions: mapOptions;
    checked = false;
    variableName = '';
    extraText = '';
    sankeyOptions = {
        labels: true,
        showLegend: true,
        from: '',
        to: '',
        count: ''
    };
    
    static empty() {
        return new CanvasInsertStore();
    }

    /**
     * Creates a new instance of the CanvasInsertStore class.
     * @constructor
     * @param {CanvasStore} canvasStore - The canvas store to associate with this instance.
     */
    constructor(canvasStore?: CanvasStore) {
        this.canvasStore = canvasStore;
        this.screen = CvInsertScreen.type;
        this.mapOptions = new mapOptions();
        if (canvasStore) {
            this.parameterIdField = canvasStore.parameter?.idField || '';
            this.parameterSearchFields = canvasStore.parameter?.searchFields || [];
            this._prevOptions = canvasStore.getCurrentCell()?.type === 'data' ? (canvasStore.getCurrentCell() as CvData).options : {};
        }
        if (this.canvasStore?.setupParameter) {
            this._visConfig = Parameter;
            this.screen = Parameter.screens[0];
        }
        if (this.canvasStore?.isAddConditionalRender()) {
            this._visConfig = ConditionalRender;
            this.screen = ConditionalRender.screens[0];
        }
        makeAutoObservable(this);
    }

    serializeOptions(options: any) {
        this.visTitle = options.title;
        this.stickyColumns = options.stickyColumns || [];
        this.hiddenColumns = options.hiddenColumns || [];
        this.templateText = options.template;
        this._noRowMessage = options.noRowMessage;
    }

    /**
     * Resets the query, affiliation query flag, and parameter configurations to their default values.
     * @returns void
     */
    reset() {
        this.queries = [];
        this.isAffiliationQuery = false;
        this.parameterConfigs = [];
    }

    /**
     * Goes to the previous screen in the insert modal.
     * If the previous screen is a query selector, decrements the index by 2, otherwise decrements by 1.
     * @returns void
     */
    prevScreen() {
        let decrement = this.isQuerySelectorNext(-1) ? 2 : 1;
        this.goToVisScreenByIndex(this.visScreenIndex - decrement);
    }

    /**
     * Goes to the next screen in the insert modal.
     * If the next screen is a query selector, increments the index by 2, otherwise increments by 1.
     * @returns void
     */
    nextScreen() {
        let increment = this.isQuerySelectorNext(1) ? 2 : 1;
        this.goToVisScreenByIndex(this.visScreenIndex + increment);
    }

    /**
     * Sets the current screen to the screen at the specified index in the current visualization configuration's screens array.
     * If the index is out of bounds or the current visualization configuration is a parameter, sets the screen to the default type screen.
     * @param {number} index - The index of the screen to set as the current screen.
     * @returns {void}
     */
    goToVisScreenByIndex(index: number) {
        if (this.visConfig && index >= 0 && index < this.visConfig.screens.length) {
            this.visScreenIndex = index;
            this.screen = this.visConfig.screens[index];
        } else if (this.visConfig?.type !== CvInsertVisType.parameter)
            this.screen = CvInsertScreen.type;
    }

    /**
     * Sets the current screen to the specified screen in the current visualization configuration's screens array.
     * If the specified screen is not found, does nothing.
     * @param {CvInsertScreen} screen - The screen to set as the current screen.
     * @returns {void}
     */
    goToVisScreen(screen: CvInsertScreen) {
        let inx = this.visConfig?.screens.indexOf(screen);
        if (inx)
            this.goToVisScreenByIndex(inx);
    }

    /**
     * Saves the current query and parameter configurations to the canvas store as an insight container or a parameter,
     * depending on the current visualization configuration. Closes the insert screen afterwards.
     * @returns void
     */
    applyInsert() {
        if (!this.query || !this.visConfig || !this.canvasStore) {
            return;
        }

        if (this.canvasStore.addConditionalRenderAlternativeIC) {
            this.canvasStore.setConditionalRenderCellAlternativeIC(
                this.visConfig.type,
                this.queries,
                this.parameterConfigs,
                {...this.dataConfig.options, ...this.chartContext?.buildOptions()}
            );
        } else if (this.visConfig.type === CvInsertVisType.parameter)
            this.canvasStore?.addParameter({
                query: this.query.serialize(),
                idField: this.parameterIdField,
                searchField: this.parameterSearchField || this.parameterSearchFields[0].name,
                searchFields: this.parameterSearchFields,
                useFuzzySearch: this.parameterFuzzySearch,
                minSearchChars: this.parameterMinSearchChars,
                customKeyDelay: this.parameterKeyDelay,
                displayPattern: this.parameterDisplayTemplate
            });
        else
            this.canvasStore.addInsightContainer(
                this.visConfig.type,
                this.queries, 
                this.parameterConfigs, 
                {...this.dataConfig.options, ...this.chartContext?.buildOptions()}
            );

        this.canvasStore.closeInsert();
    }

    /**
     * Applies a filter to the given query based on the provided parameter configuration.
     * The filter is created using the parameter field and value from the canvas store.
     * @param query The query to apply the filter to.
     * @param config The parameter configuration to use for creating the filter.
     * @returns void
     */
    applyCanvasParameterFilter(query: QuantumGraph, config: ParameterConfig) {
        if (!this.canvasStore || !this.canvasStore.parameter) {
            return;
        }
        const gh = createGraphHandler(query, getDefaultDataDefRepo());
        const filter: Filter = {
            variable: new Variable(config.dataModelField, '', '', '', {}, new StringFormatter(), config.modelDataset),
            test: config.test,
            value: this.canvasStore.parameterRow[config.parameterField] as string
        };
        this.query = gh.applyFilters([filter]).build();
    }

    /**
     * Removes the parameter configuration at the specified index from the canvas store and the current query.
     * @param index The index of the parameter configuration to remove.
     * @returns void
     */
    removeParameter(index: number) {
        if (!this.canvasStore || !this.canvasStore.parameter || !this.query) {
            return;
        }
        let config = this.parameterConfigs[index];
        const gh = createGraphHandler(deserialize(this.query.serialize()), getDefaultDataDefRepo());
        const filter: Filter = {
            variable: new Variable(config.dataModelField, '', '', '', {}, new StringFormatter(), config.modelDataset),
            test: config.test,
            value: ''
        };
        gh.removeFilter(filter, true);
        this.query = gh.build();
        this.parameterConfigs.splice(index, 1);
    }

    /**
     * Adds a new parameter configuration to the list of parameter configurations if it does not already exist.
     * @param config The parameter configuration to add.
     * @returns A boolean indicating whether the parameter configuration was added successfully.
     */
    addParameterConfig(config: ParameterConfig): boolean {
        if (!this.parameterConfigs.some(pc => pc.parameterField === config.parameterField && pc.test === config.test && pc.modelDataset === config.modelDataset && pc.dataModelField === config.dataModelField)) {
            this.parameterConfigs.push(config);
            return true;
        }
        return false;
    }

    addConditionalRenderConfig() {
        if (this.canvasStore?.addConditionalRenderRow)
            this.addConditionalRenderRowConfig();
        else if (this.canvasStore?.addConditionalRenderCell)
            this.addConditionalRenderCellConfig();
    }

    removeConditionalRenderConfig() {
        if (this.canvasStore?.addConditionalRenderRow)
            this.canvasStore?.removeConditionalRenderRow();
        else if (this.canvasStore?.addConditionalRenderCell)
            this.canvasStore?.removeConditionalRenderCell();
    }

    /**
     * Adds the current conditional render configuration to the current cell of the Canvas store.
     */
    addConditionalRenderCellConfig() {
        this.canvasStore?.setConditionalRenderCell(this.query.serialize(), this.conditionalRenderOptions.operator, this.conditionalRenderOptions.rowCount, this.conditionalRenderOptions.showLoading, this.parameterConfigs);
    }

    /**
     * Adds the current conditional render configuration to the current row of the Canvas store.
     */
    addConditionalRenderRowConfig() {
        this.canvasStore?.setConditionalRenderRow(this.query.serialize(), this.conditionalRenderOptions.operator, this.conditionalRenderOptions.rowCount, this.conditionalRenderOptions.showLoading, this.parameterConfigs);
    }


    private isQuerySelectorNext(increment: number) {
        return this.isAffiliationQuery && this.visConfig?.screens[this.visScreenIndex + increment] === CvInsertScreen.querySelect;
    }

    /**
     * Adds a new query to the list of queries for the current visualization configuration.
     * If the current visualization configuration is a map configuration, validates the query and adds it to the map options.
     * @param {QuantumGraph} query - The query to add.
     * @throws {Error} If the query is not valid for the current map configuration.
     * @returns {void}
     */
    addQuery(query: QuantumGraph) {
        this.queries.push(query);

        if (isMapConfig(this.visConfig) && this.mapOptions) {
            if(this.mapOptions.mapQueries.length === 0) {
                if(!validateQuery(query,[this.mapOptions.mapShape])) {
                    throw new Error('Your choropleth query must contain the column ' + this.mapOptions.mapShape);
                }
            }

            if (this.mapOptions.mapQueries.length > 0) {
                if(!validateQuery(query,['longitude', 'latitude'])) {
                    throw new Error("Your points query must contain the columns 'longitude' and 'latitude'");
                }
            }
            this.mapOptions.addMapQuery(query);
            this.goToVisScreenByIndex(0);
        }
    }

    /**
     * Removes the query at the specified index from the list of queries for the current visualization configuration.
     * If the current visualization configuration is a map configuration, removes the query from the map options as well.
     * If the removed query was the first query in the map options, navigates to the next screen in the insert flow.
     * @param index The index of the query to remove.
     * @returns void
     */
    removeQuery(index: number) {
        this.queries.splice(index, 1);

        if (isMapConfig(this.visConfig) && this.mapOptions) {
            this.mapOptions.removeMapQuery(index);
            if (index === 0) {
                this.goToVisScreenByIndex(1);
            }
        }
    }

    /**
     * Returns the data configuration object for the current visualization.
     * If there is no query or canvas store, returns an empty object.
     * @returns An object containing the queries and options for the current visualization.
     */
    get dataConfig() {
        if (!this.query || !this.canvasStore)
            return {queries: [], options: {}};

        const options = Object.assign({}, this._prevOptions, {
            title: this.visTitle,
            colorColumns: this.isAffiliationQuery && this.datasetColorsEnabled,
            template: this.templateText,
            variableName: this.variableName,
            extraText: this.extraText,
            stickyColumns: this.stickyColumns,
            mapOptions: this.mapOptions,
            connectingFacts: this.connectingFacts,
            queryCount: this.queryCount,
            noRowMessage: this.noRowMessage,
            sankeyOptions: this.sankeyOptions,
            inaccessibleViewType: this._inaccessibleViewType
        });
        if (this.hcIndexReference)
            options.exploreLink = `/healthcare-index/${this.hcIndexReference}`;
        if (this.hiddenColumns)
            options.hiddenColumns = this.hiddenColumns;

        return {
            queries: this.queries,
            options,
        };
    }

    /**
     * Gets the visualization configuration for the current visualization.
     * @returns The visualization configuration for the current visualization.
     */
    get visConfig() {
        return this._visConfig;
    }

    /**
     * Sets the visualization configuration for the current visualization.
     * If the visualization type is text or rich text, adds a new text element to the canvas store.
     * If the visualization type is not recognized, navigates to the first screen in the insert flow.
     * @param config The visualization configuration to set.
     * @returns void
     */
    set visConfig(config) {
        this._visConfig = config;
        if (this.visConfig?.type === CvInsertVisType.richText)
            this.canvasStore?.addText('rich-text');
        else
            this.goToVisScreenByIndex(0);
    }

    /**
     * Gets or sets the title of the current visualization configuration.
     * @returns The title of the current visualization configuration.
     */
    get visTitle() {
        return this._visTitle;
    }

    /**
     * Sets the title of the current visualization configuration.
     * @param title The title to set.
     * @returns void
     */
    set visTitle(title) {
        this._visTitle = title;
    }

    /**
     * Gets the sticky columns for the current visualization configuration.
     * @returns An array of column names that should be sticky in the table view of the visualization.
     */
    get stickyColumns() {
        return this._stickyColumns;
    }

    /**
     * Sets the sticky columns for the current visualization configuration.
     * @param cols An array of column names that should be sticky in the table view of the visualization.
     * @returns void
     */
    set stickyColumns(cols) {
        this._stickyColumns = cols;
    }

    /**
     * Returns the hidden columns for when views on Canvas, but available on download and explore data
     * @returns Array<string>
     */
    get hiddenColumns() {
        return this._hiddenColumns;
    }

    /**
     * Sets the hidden columns for when views on Canvas, but available on download and explore data
     * @param cols Array<string>
     */
    set hiddenColumns(cols: Array<string>) {
        this._hiddenColumns = cols;
    }

    /**
     * Gets the first query in the list of queries for the current visualization configuration.
     * If there are no queries, returns undefined.
     * @returns The first query in the list of queries for the current visualization configuration.
     */
    get query() {
        return this.queries?.[0];
    }         

    /**
     * Sets the first query in the list of queries for the current visualization configuration.
     * @param q The query to set as the first query in the list of queries.
     * @returns void
     */
    set query(q: QuantumGraph) {
        this.queries[0] = q;
        this._queryCount++;
    }

    /**
     * Returns the referring healthcare index used to model the query
     * @returns string
     */
    get hcIndexReference() {
        return this._hcIndexReference;
    }

    /**
     * Sets the referring healthcare index used to model the query
     * @param ref string | undefined
     */
    set hcIndexReference(ref: string | undefined) {
        this._hcIndexReference = ref;
    }

    /**
     * Returns a list of the connecting facts defined in the index.
     */
    get connectingFacts() {
        return this._connectingFacts;
    }

    /**
     * Sets the list of connecting facts defined in the index.
     * @param facts Array<DatasetDefinition> | undefined
     */
    set connectingFacts(facts: Array<DatasetDefinition> | undefined) {
        this._connectingFacts = facts;
    }

    /**
     * Sets the parameter search fields for the current visualization configuration.
     * @param fields The parameter search fields to set.
     * @returns void
     */
    set parameterSearchFields(fields: Variable[]) {
        this._parameterSearchFields = fields;
        
        if (!this.parameterDisplayTemplate) {
            this.parameterDisplayTemplate = fields.map(f => f.name).reduce((acc, cur) => acc + ` {${cur}}`, '');
        }
    }

    /**
     * Gets the parameter search fields for the current visualization configuration.
     * @returns The parameter search fields for the current visualization configuration.
     */
    get parameterSearchFields() {
        return this._parameterSearchFields;
    }

    /**
     * This is a hack to get the dataConfig object to update when the query object is updated. MobX can't detect updates to an array.
     * @returns The number of times the query object has been updated.
     */
    get queryCount() {
        return this._queryCount;
    }

    /**
     * This is a hack to get the dataConfig object to update when the query object is updated. MobX can't detect updates to an array.
     * @param num
     */
    set queryCount(num: number) {
        this._queryCount = num;
    }

    /**
     * Gets the no row message for the current visualization configuration.
     */
    get noRowMessage() {
        return this._noRowMessage;
    }

    /**
     * Sets the no row message for the current visualization configuration.
     * @param message
     */
    set noRowMessage(message: string | undefined) {
        this._noRowMessage = message;
    }

    set inaccessibleViewType(viewType: string | undefined) {
        if(!viewType || Object.values<string>(InaccessibleViewType).includes(viewType)) {
            this._inaccessibleViewType = viewType as InaccessibleViewType;
        }
    }

    get inaccessibleViewType() {
        return this._inaccessibleViewType?.toString();
    }
}

