import {observer} from 'mobx-react';
import Modal from '../../../components/modal';
import {useContext} from 'react';
import {CanvasContext} from '../lib/context';
import Button from '../../../components/button';
import {useNavigate} from 'react-router';


const MergingModal = observer(() => {
    const store = useContext(CanvasContext);
    const navigate = useNavigate();

    return <Modal title="Canvas Updated Externally" onClose={() => store.merging = false}>
        <div className="w-full h-full p-3 mt-1">
            <p>This Canvas has been updated externally by another user. This causes a conflict between your version and the version saved on the cloud. Overriding will cause the recent external changes to be lost.</p>
            <div className="mt-8">
                <span>Select an option below to continue:</span>
                <div className="flex space-x-5 mt-3">
                    <Button color="blue" onClick={() => store.merging = false}>Cancel</Button>
                    <Button color="light" onClick={() => navigate(0)}>Discard Your Changes</Button>
                    <Button color="light" onClick={() => store.syncDraftToServer()}>Override External Changes</Button>
                </div>
                <div className="mt-2">
                    <small>Selection cannot be undone.</small>
                </div>
            </div>
        </div>
    </Modal>;
});

export default MergingModal;