import {dataRows} from './data-types';
import Variable from './metadata';
import saveEvent from './analytics';
import {QuantumGraph} from 'quantum-graph';
import getDateCoverage from './date-coverage';
import {DataDefRepo} from './user-data-defs';
import {V1QuantumGraph} from 'quantum-graph/out/v1';
import {V2QuantumGraph} from 'quantum-graph/out/v2';

const DomUrl = window.URL || window.webkitURL;

export function csvEscape(value: any) {
    if (value === null || value === undefined)
        value = '';
    else
        value = value + '';
    if (value.match(/[,"\n]/))
        return '"' + value.replace(/"/g, '""') + '"';
    return value;
}

export function toCsv(data: Array<Array<string>>): string {
    return data.map(row => row.map(v => csvEscape(v)).join(',')).join('\n');
}

export function downloadCsv(data: dataRows, metadata: Array<Variable>, name?: string) {
    const rows: Array<Array<string>> = data.map(row => metadata.map(v => String(v.getValue(row))));
    rows.unshift(metadata.map(v => csvEscape(v.label)));
    const csv = toCsv(rows);

    if (!name)
        name = Math.random().toString(16).substr(2);
    const filename = `Torch-Insight-Data_${name}.csv`;
    download(csv, filename, 'application/csv');
}

export function generateCodebook(metadata: Array<Variable>) {
    let data = [['Field', 'Description', 'Source', 'Source Description', 'Date Range', 'Last Updated']];
    data = data.concat(metadata.map(v => [
        v.label || v.name,
        v.description || '',
        v.getTag('Source') || '',
        v.getTag('SourceDescription') || '',
        getDateCoverage(v) || '',
        v.getTag('LastUpdated') ?
            new Date(v.getTag('LastUpdated') as string).toLocaleDateString('en-US', {timeZone: 'utc'}) :
            '',
    ]));
    return toCsv(data);
}

export function downloadCodebook(metadata: Array<Variable>, name?: string) {
    const csv = generateCodebook(metadata);

    if (!name)
        name = Math.random().toString(16).substr(2);
    const filename = `Torch-Insight-Codebook_${name}.csv`;
    download(csv, filename, 'application/csv');
}

export default function download(data: string | Blob, name: string, type: string) {
    let blob: Blob;
    if (data instanceof Blob)
        blob = data;
    else
        blob = new Blob([data], {type});

    saveEvent('download', {
        path: window.location.pathname,
        size: blob.size,
        name,
        type,
    });

    const url = DomUrl.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function downloadGraph(graph: QuantumGraph, count: number, datasetRepo: DataDefRepo) {
    let signature: any = {};
    if (graph instanceof V1QuantumGraph) {
        signature = JSON.parse(JSON.stringify(graph.getSignature(datasetRepo)));
        signature.columns = signature.columns.map((c: any) => c.name);
    } else if (graph instanceof V2QuantumGraph) {
        graph.forEachTable(node => signature[node.dataset] = node.columns);
    }
    saveEvent('download-graph', {
        path: window.location.pathname,
        count,
        signature,
    });

    const id = Math.random().toString(16).substring(2);
    localStorage['download:' + id] = JSON.stringify(graph.serialize());
    window.open('/file-download/' + id, '_blank');
}