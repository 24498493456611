import {deserialize, GraphVersions, QuantumGraph, TestType} from 'quantum-graph';
import Variable from '../../../../lib/metadata';
import {RegionFields} from '../constants';
import {CCEnumField} from './enum-field';
import {QueryObj} from '../../../../lib/data-types';

export default function buildCommonCoreQueryVRDC(regionType: string, memberCategory: string, conditionCategory: string, serviceCategory: string, src: string, demoBreakout: boolean, variables: Array<Variable>): QuantumGraph {
    const dataset = 'common-core-benchmarks-metrics-' + regionType;

    const columns = [
        ...RegionFields[regionType],
    ];
    if (serviceCategory === 'Service Setting') {
        columns.push('setting');
    } else if (serviceCategory === 'Service Subcategory') {
        columns.push('setting');
        columns.push('subcategory');
    }
    if (demoBreakout) {
        columns.push(CCEnumField.AgeBand);
        columns.push(CCEnumField.Gender);
    }
    for (const v of variables)
        if (!columns.includes(v.name))
            columns.push(v.name);

    const dim: Array<any> = [];
    if (regionType === 'county') {
        dim.push({
            id: 'county',
            dataset: 'county',
            columns: ['state'],
        });
    }

    const obj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: 'dim',
                tableNodes: [
                    ...dim,
                    {
                        id: 'cc',
                        dataset,
                        columns,
                    }
                ]
            }
        },
        sort: [
            ...dim.flatMap(d => d.columns.map((column: string) => ({
                column: {nodeId: d.id, column},
                asc: true,
            }))),
            ...RegionFields[regionType].map(column => ({
                column: {nodeId: 'cc', column},
                asc: true,
            })),
        ],
        filters: [
            {
                column: {nodeId: 'cc', column: 'data_source'},
                test: TestType.Eq,
                value: 'VRDC',
            }, {
                column: {nodeId: 'cc', column: CCEnumField.MemberCategory},
                test: memberCategory ? TestType.Eq : TestType.Null,
                value: memberCategory,
            }, {
                column: {nodeId: 'cc', column: CCEnumField.ConditionCategory},
                test: conditionCategory ? TestType.Eq : TestType.Null,
                value: conditionCategory,
            }, {
                column: {nodeId: 'cc', column: CCEnumField.AgeBand},
                test: demoBreakout ? TestType.NEq : TestType.Eq,
                value: 'Total',
            }, {
                column: {nodeId: 'cc', column: CCEnumField.Gender},
                test: demoBreakout ? TestType.NEq : TestType.Eq,
                value: 'Total',
            }, {
                column: {nodeId: 'cc', column: CCEnumField.MetricScope},
                test: TestType.Eq,
                value: src,
            }
        ],
    };

    // Filters based on service category selection
    if (serviceCategory === 'Total') {
        obj.filters!.push({
            column: {nodeId: 'cc', column: 'setting'},
            test: TestType.Eq,
            value: 'Total',
        });
    } else if (serviceCategory === 'Service Setting') {
        obj.filters!.push({
            column: {nodeId: 'cc', column: 'setting'},
            test: TestType.NotNull,
            value: '',
        });
        obj.filters!.push({
            column: {nodeId: 'cc', column: 'subcategory'},
            test: TestType.Eq,
            value: 'Total',
        });
    } else if (serviceCategory === 'Service Subcategory') {
        obj.filters!.push({
            column: {nodeId: 'cc', column: 'setting'},
            test: TestType.NotNull,
            value: '',
        });
        obj.filters!.push({
            column: {nodeId: 'cc', column: 'subcategory'},
            test: TestType.NEq,
            value: 'Total',
        });
    }

    return deserialize(obj);
}