import {makeAutoObservable, runInAction} from 'mobx';
import {networkOptimization} from '../../../lib/api';
import {GeographyFilterOptions,
    FetchGeoJsonResponse,
    FetchComplianceResponse,
    FetchProvidersResponse,
    FetchRunIdResponse,
    AppliedGeographyFilter} from './types';

export default class NetworkOptimizationStore {

    static empty() {
        return new NetworkOptimizationStore();
    }

    // Store properties
    patientGeoJson: FetchGeoJsonResponse | null = null;
    providerGeoJson: FetchGeoJsonResponse | null = null;
    appliedGeographyFilter: AppliedGeographyFilter = {
        state: '',
        county: ''
    };
    availableGeographyFilters: Array<GeographyFilterOptions> = [];
    _specialtyFilter = '';
    specialties: string[] = [];
    loadingSpecialties = false;
    loadingMapData = false;
    loadingLocalProviders = false;
    loadingRevisionRecalculation = false;
    disableRecalculateRevision = true;
    complianceScores: FetchComplianceResponse = [];
    localProviders: FetchProvidersResponse = [];
    runId: FetchRunIdResponse = null;
    error: string | null = null;
    navItems: {label: string; path: string}[] = [];
    revisionCount = 0;

    constructor() {
        this.loadInitialData();  
        this.loadHeaders();
        makeAutoObservable(this);
    }

    // Load initial data for the store
    async loadInitialData() {
        try {
            const [geographyResponse, runId] = await Promise.all([
                networkOptimization.fetchGeographyFilters() as Promise<Array<GeographyFilterOptions>>,
                networkOptimization.fetchRunId() as Promise<FetchRunIdResponse>
            ]);
            runInAction(() => {
                this.availableGeographyFilters = geographyResponse;
                this.runId = runId;
            });
        } catch (error) {
            this.error = this.extractErrorMessage(error);
        }
    }
    
    //fetchNavheaders
    async loadHeaders() {
        try {
            const response = await networkOptimization.fetchNavItems();
            runInAction(() => {
                this.navItems = response;
            });
        } catch (error) {
            console.error('Failed to fetch navigation items:', error);
        }
    }

    // Fetch and update the map data
    async loadMapGeoJson(controller: AbortController) {
        if (!this.readyToLoadData() || this.loadingMapData)
            return;
        try {
            runInAction(() => {
                this.loadingMapData = true;
            });
            let filters: Record<string, string> = {
                ...this.geographyFilters
            };
            if (this.specialtyFilter)
                filters.specialty = this.specialtyFilterCode;
            const patientServiceCall: any = this.specialtyFilter ? networkOptimization.fetchPatientGeoJson : networkOptimization.fetchPatientPopulationGeoJson;
            const [patientData, providerData] = await Promise.all([
                patientServiceCall(filters, this.runId!, !!this.revisionCount, controller.signal),
                networkOptimization.fetchProviderGeoJson(filters, this.runId!, !!this.revisionCount, controller.signal)
            ]) as [FetchGeoJsonResponse, FetchGeoJsonResponse];

            runInAction(() => {
                this.patientGeoJson = patientData;
                this.providerGeoJson = providerData;
            });
        } catch (error) {
            this.error = this.extractErrorMessage(error);
            console.error('Failed to fetch map data:', error);
        } finally {
            runInAction(() => {
                this.loadingMapData = false;
            });
        }
    }

    // Fetch and update the local providers list
    async loadLocalProviders(controller: AbortController) {
        if (!this.readyToLoadData())
            return;
        try {
            this.loadingLocalProviders = true;
            let filters: Record<string, string> = {
                ...this.geographyFilters
            };
            if (this.specialtyFilter)
                filters.specialty = this.specialtyFilterCode;
            let providers = await networkOptimization.fetchProviders(filters, this.runId!, !!this.revisionCount, controller.signal) as FetchProvidersResponse;
            this.localProviders = providers.map((provider: any) => ({...provider, id: provider.npi}));
        } catch (error) {
            this.error = this.extractErrorMessage(error);
        } finally {
            this.loadingLocalProviders = false;
        }
    }

    async loadSpecialtiesData(controller: AbortController) {
        if (!this.readyToLoadData() || this.loadingSpecialties)
            return;
        try {
            runInAction(() => {
                this.loadingSpecialties = true;
            });
            const filters = {
                ...this.geographyFilters
            };

            const specialtyReport = await networkOptimization.fetchSpecialtyReport(filters, this.runId!, !!this.revisionCount, controller.signal);
            runInAction(() => {
                this.specialties = Array.from(new Set(specialtyReport.map((item: any) => item.specialty_code)));
                this.complianceScores = specialtyReport.map((item: any) => ({
                    name: item.specialty_name,
                    specialtyCode: item.specialty_code,
                    compliance: item.final_adequacy_flag === '1',
                    scores: [
                        {name: 'Time', value: this.formatPercentage(item.population_met_time), compliant: item.time_adequacy_flag === '1'},
                        {name: 'Distance', value: this.formatPercentage(item.population_met_distance), compliant: item.distance_adequacy_flag === '1'},
                        {name: `# of ${item.specialty_code === '040' ? 'Beds' : 'Providers'}`, value: item.total_adequate_units, compliant: item.min_docs_flag === '1'},
                        {name: 'Total Compliance', value: null, compliant: item.final_adequacy_flag === '1', cssClasses: item.final_adequacy_flag === '1' ? 'bg-green-300 font-bold' : 'bg-red-300 font-bold'}
                    ]
                }));
            });
        } catch (error) {
            this.error = this.extractErrorMessage(error);
            console.error('Failed to fetch specialties and compliance scores:', error);
        } finally {
            this.loadingSpecialties = false;
        }
    }

    async updateRoster(selectedProviders: string[], controller: AbortController) {
        if (!this.runId || !this.specialtyFilterCode)
            return;
        try {
            await networkOptimization.updateProviderRoster(this.runId, this.specialtyFilterCode, selectedProviders, controller.signal);
            this.disableRecalculateRevision = false;
        } catch (error) {
            this.error = this.extractErrorMessage(error);
        }
    }

    async recalculateRevision(controller: AbortController) {
        if (!this.runId || !this.specialtyFilterCode)
            return;
        try {
            this.disableRecalculateRevision = true;
            this.loadingRevisionRecalculation = true;
            await networkOptimization.recalculateRevision(this.runId, this.specialtyFilterCode, this.geographyFilters.county, controller.signal);
            this.revisionCount++;
        } catch (error) {
            this.error = this.extractErrorMessage(error);
        } finally {
            this.loadingRevisionRecalculation = false;
        }
    }

    calculateGeneralComplianceScores() {
        if (this.readyToLoadData() && !this.loadingMapData && this.complianceScores.length > 0 && this.providerGeoJson?.features?.length > 0)
            return {
                scores: [
                    {name: '# of Providers', value: this.providerGeoJson.features.length, compliant: null},
                    {name: 'Total Compliance', value: `${this.complianceScores.filter(cs => cs.compliance).length} / ${this.complianceScores.length}`, compliant: null}
                ]
            };
        return {scores: []};
    }

    // Setters for applied filters
    setGeographyFilter(filterName: 'state' | 'county', value: string) {
        if (filterName === 'state') {
            this.appliedGeographyFilter.state = value;
        } else if (filterName === 'county') {
            this.appliedGeographyFilter.county = value;
        }
        this.appliedGeographyFilter = {...this.appliedGeographyFilter};
        this.specialtyFilter = '';
    }

    get geographyFilters(): { state: string; county: string; } | Record<string, never> {
        let state = this.availableGeographyFilters.find(o => o.state === this.appliedGeographyFilter.state);
        let county = state?.counties.find(o => `${o.county_name}-${o.county_ssa}` === this.appliedGeographyFilter.county);
        return {state: state?.code || '', county: county?.county_ssa || ''};
    }

    get specialtyFilterCode() {
        return this.complianceScores.find(o => o.name === this.specialtyFilter)?.specialtyCode || null;
    }

    get specialtyFilter() {
        return this._specialtyFilter;
    }

    set specialtyFilter(value: string) {
        this._specialtyFilter = value;
    }

    // Fetch and set the Run ID
    async fetchRunId() {
        try {
            const runId = await networkOptimization.fetchRunId() as FetchRunIdResponse;
            this.runId = runId;
        } catch (error) {
            this.error = this.extractErrorMessage(error);
        }
    }

    readyToLoadData() {
        return this.appliedGeographyFilter.state && this.appliedGeographyFilter.county && this.runId;
    }

    private formatPercentage(value: number) {
        return `${(value * 100).toFixed(2)}%`;
    }

    // Extract error message from the error object
    private extractErrorMessage(error: any): string {
        if (error instanceof Error) {
            return error.message;
        }
        return 'An unexpected error occurred';
    }
}

