import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {DataConfig} from '../../types';
import {DatumValue, ResponsiveLine} from '@nivo/line';
import {dataRows} from '../../../lib/data-types';
import {
    formatData,
    formatXAxisByType,
    generateAxisBottomConfig,
    getTextWidth,
    limitRowsForChart,
    PlotColors,
    PlotTheme,
} from '../common';
import Variable from '../../../lib/metadata';
import ChartContainer from '../container';
import ChartContext, {ChartStore} from '../context';
import {observer} from 'mobx-react-lite';
import {QueryTableContext} from '../../query-table';
import QueryDataContext from '../../renderers/query-data-renderer/context';
import Loading from '../../../components/loading';
import AdditionalToolTipVariables from '../additional-tooltip-variables';

const MAX_LINES = 99;

export function dataToPlot(
    data: dataRows,
    xAxis: string,
    yVars: Array<Variable>,
    isYearChart = false,
    idVariable?: string,
): Array<{ id: string; data: Array<{ x: DatumValue; y: DatumValue, rowIndex: number }> }> {
    return idVariable ? Array.from(new Set(data.map(row => row[idVariable]))).map(id => ({
        id: id as string,
        data: data
            .filter(row => row[idVariable] === id)
            .map((row, index) => ({x: formatXAxisByType(xAxis, row), y: row[yVars[0].name] as DatumValue, rowIndex: index}))
            .sort((a, b) => isYearChart ? Number(a.x) - Number(b.x) : -1),
    })) : yVars.map((yVar) => ({
        id: yVar.label,
        data: data
            .map((row, index) => ({x: formatXAxisByType(xAxis, row), y: row[yVar.name] as DatumValue, rowIndex: index}))
            .sort((a, b) => isYearChart ? Number(a.x) - Number(b.x) : -1),
    }));
}

export const PlotChartVis = observer(() => {
    let {yAxisNames, rowsToShow, xAxisName, valueMeta, showLatestYears, isYearChart, isYearMonthChart, maxXLabelLength, idVariable} = useContext(ChartContext);
    const chartStore = useContext(ChartContext);
    let {data, metadata, loading} = useContext(QueryDataContext);
    let ref = useRef<HTMLDivElement>(null);
    let [containerWidth, setContainerWidth] = useState(800);
    const yVars = valueMeta;
    const xVar = metadata.find((m) => m.name === xAxisName)!;
    const idVar = idVariable ? metadata.find((m) => m.name === idVariable)! : undefined;
    const rows = useMemo(
        () => dataToPlot(
            formatData(data, showLatestYears, rowsToShow, xAxisName, maxXLabelLength, idVariable),
            xAxisName,
            yVars,
            isYearChart,
            idVariable
        )
        ,[data, rowsToShow, xAxisName, yAxisNames, showLatestYears, idVariable]);
    let maxXLegendLabelLength = data
        .slice(0, rowsToShow)
        .reduce((maxLength, row) => Math.max(maxLength, getTextWidth(xVar.formatSimple(row))), 0);
    let maxYLabelLength = rows.reduce(
        (maxLength, line, i) =>
            Math.max(
                maxLength,
                yVars.length
                    ? line.data.reduce(
                        (max, point) =>
                            Math.max(max, getTextWidth(yVars[idVar ? 0 : i].formatter.formatSimple(point.y))),
                        0,
                    )
                    : 0
            ),
        0,
    );

    let showLegend = yVars.length > 1 || idVar;
    let maxYVarLabelLength = idVar ? rows.reduce((maxLength, line) => Math.max(maxLength, getTextWidth(line.id)) , 0) : yVars.reduce(
        (maxLength, v) => Math.max(maxLength, getTextWidth(v.label)),
        0,
    );

    let maxLegendsOnLine = Math.floor(containerWidth / (maxYVarLabelLength + 25));

    useEffect(() => {
        if (!ref.current)
            return;

        const observer = new ResizeObserver(entries => {
            setContainerWidth(entries[0].contentRect.width);
        });
        observer.observe(ref.current);
        return () => {
            if (ref.current)
                observer.unobserve(ref.current);
        };
    }, [ref]);

    if (loading)
        return <Loading />;

    return (
        <ChartContainer divRef={ref} maxRows={MAX_LINES}>
            <ResponsiveLine
                data={rows}
                margin={{
                    top: 15,
                    right: maxXLegendLabelLength + 5,
                    bottom: maxXLegendLabelLength + 5 + (showLegend ? (20 * Math.ceil(rows.length / maxLegendsOnLine)) : 0),
                    left: maxYLabelLength + (yVars.length === 1 ? 30 : 10),
                }}
                theme={PlotTheme}
                colors={PlotColors}
                axisLeft={{
                    format: yVars.length ? (x) => yVars[0].formatter.formatSimple(x) : () => '',
                    legend: yVars.length === 1 ? yVars[0].label : '',
                    legendPosition: 'middle',
                    legendOffset: -(maxYLabelLength + 25),
                }}
                axisBottom={generateAxisBottomConfig(isYearChart, isYearMonthChart)}
                xScale={isYearChart || isYearMonthChart ? {
                    type: 'time',
                    format: 'native',
                    min: new Date(rows.reduce((min, r) => Math.min(min, ...r.data.map(d => (d.x as Date).getFullYear())), 9999), 11, 30),
                    max: new Date(rows.reduce((max, r) => Math.max(max, ...r.data.map(d => (d.x as Date).getFullYear())), 0), 12, 31)
                } : {
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                }}
                yScale={{type: 'linear', min: Math.min(0, ...rows.flatMap(r => r.data.map(d => Number(d.y)))), max: Math.max(0, ...rows.flatMap(r => r.data.map(d => Number(d.y))))}}
                useMesh={true}
                tooltip={(d: any) => (
                    <div className='max-w-xs bg-white border shadow-lg rounded'>
                        <div
                            className='px-3 pt-2 pb-1 rounded-t text-white font-semibold'
                            style={{backgroundColor: d.point.serieColor}}
                        >
                            {d.point.serieId}
                        </div>
                        <div className='px-3 pb-3 pt-3 rounded-b'>
                            {yVars[0].formatter.formatSimple(d.point.data.yFormatted)}
                        </div>
                        <AdditionalToolTipVariables rowIndex={d.point.data.rowIndex} />
                    </div>
                )}
                legends={
                    showLegend
                        ? (new Array(Math.ceil(rows.length / maxLegendsOnLine))).fill(1).map((_, i) => i).map(n => ({
                            data: rows.slice(n * maxLegendsOnLine, (n * maxLegendsOnLine) + maxLegendsOnLine).map((r, i) => ({label: chartStore.getLegendLabel(idVar ? idVar : yVars.find(v => v.label === r.id), r.id), id: r.id, color: PlotColors[i + (n * maxLegendsOnLine) % PlotColors.length]})),
                            anchor: 'bottom',
                            direction: 'row',
                            justify: false,
                            translateX: 0,
                            translateY: maxXLegendLabelLength + (20 * (n + 1)),
                            itemsSpacing: 10,
                            itemDirection: 'left-to-right',
                            itemWidth: maxYVarLabelLength + 12 + 10,
                            itemHeight: 12,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        }))
                        : []
                }
            />
        </ChartContainer>
    );
});

const PlotChartContext = observer(() => {
    const queryContext = useContext(QueryDataContext);
    const chartContext = useMemo(() => new ChartStore({dataConfig: queryContext.dataConfig, data: queryContext.data, metadata: queryContext.metadata}, MAX_LINES), [queryContext.dataConfig, queryContext.data, queryContext.metadata]);

    if (queryContext.loading)
        return <Loading />;

    return (
        <ChartContext.Provider value={chartContext}>
            <PlotChartVis />
        </ChartContext.Provider>
    );
});

export default function PlotChartRenderer(props: {
    dataConfig: DataConfig
    loadCB?: (promise: Promise<void>) => void
}) {
    return (
        <QueryTableContext
            dataConfig={limitRowsForChart(props.dataConfig, 1000)}
            loadCB={props.loadCB}
            pageSize={1000}
        >
            <PlotChartContext />
        </QueryTableContext>
    );
}
