import React, {useMemo} from 'react';
import Modal from '../../../../components/modal';
import InsightContainer from '../../../../insight-container';
import {DataConfig} from '../../../../insight-container/types';
import {deserialize} from 'quantum-graph';

type Props = {
    query: string,
    onClose: () => void,
}

export default function ExampleQueryExecutor(props: Props) {
    const {query, onClose} = props;
    const dataConfig: DataConfig = useMemo(() => {
        return {
            queries: [deserialize(JSON.parse(query))],
            options: {
                hideExplore: true,
            },
        };
    }, [query]);

    return <Modal title="Execute Query" onClose={onClose}>
        <InsightContainer type="table" dataConfig={dataConfig} />
    </Modal>;
}