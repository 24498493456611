import {ReactNode, RefObject, useContext, useEffect, useMemo, useState} from 'react';
import {MenuContext} from './context';
import {observer} from 'mobx-react';

type Props = {
    ariaLabelledBy?: string,
    matchWidth?: boolean,
    customWidth?: number,
    disableMaxHeight?: boolean,
    transparentBackground?: boolean,
    targetRef: RefObject<HTMLButtonElement | HTMLInputElement | HTMLDivElement>,
    children: ReactNode,
    scrollIndex?: number,
    showEvent?: string,
    hideEvent?: string,
    noHideOnClick?: boolean
};

const Popup = observer((props: Props) => {
    const {children, ariaLabelledBy, targetRef, matchWidth, customWidth, disableMaxHeight, transparentBackground, scrollIndex, showEvent, noHideOnClick} = props;
    const menuStore = useContext(MenuContext);
    const [addedListeners, setAddedListeners] = useState<{[key: string]: boolean}>({});
    const id = useMemo(() => Math.random().toString(16).substring(2), []);

    const onEvent = (e: Event | CustomEvent) => {
        e.stopPropagation();

        const noHide = e instanceof CustomEvent && e.detail && e.detail.noHide;
        const hide = e instanceof CustomEvent && e.detail && e.detail.hide;
        if ((menuStore.activeId === id && !noHide) || hide) {
            menuStore.hide();
        } else if (targetRef.current) {
            const rect = targetRef.current.getBoundingClientRect();
            menuStore.show(id, rect, children, !!matchWidth, customWidth, disableMaxHeight, transparentBackground, ariaLabelledBy);
            menuStore.cancelClickHide = noHideOnClick || false;
        }
    };

    useEffect(() => {
        let event = showEvent || 'click';
        if (targetRef.current && !addedListeners[event]) {
            targetRef.current.addEventListener(event, onEvent);
            const events = {...addedListeners};
            events[event] = true;
            setAddedListeners(events);
        }
    }, [targetRef, showEvent]);

    useEffect(() => {
        if (menuStore.activeId === id)
            menuStore.updateContent(children);
    }, [children, id, menuStore, menuStore.activeId]);

    useEffect(() => {
        if (typeof scrollIndex === 'number')
            menuStore.scroll = scrollIndex;
    }, [menuStore, scrollIndex]);

    return null;
});

export default Popup;