import {useContext, useMemo} from 'react';
import './pagination.scss';
import Badge from '../badge';
import classNames from '../../lib/class-names';
import {HeaderContext} from '../header/context';
import {CaretLeft, CaretRight} from '@carbon/icons-react';

export function buildPagination(count: number, pageSize: number, page: number): Array<number> {
    const numPages = Math.ceil(count / pageSize);
    page = Math.max(1, Math.min(numPages - 2, page));
    let pages = [0, page - 1, page, page + 1, numPages - 1].filter(i => i >= 0 && i < numPages);
    const pagination = [0];

    pages = Array.from(new Set(pages)).sort((a,b) => a - b);
    let min = -1;
    for (let i = 1; i < pages.length; i++) {
        if (pages[i] - pages[i - 1] > 1)
            pagination.push(min--);
        pagination.push(pages[i]);
    }
    return pagination;
}

type Props = {
    page: number,
    pageSize: number,
    setPage: (page: number) => void,
    count?: number,
    showCount?: boolean,
    className?: string
};

export default function Pagination(props: Props) {
    const {count, page, pageSize, setPage, showCount, className} = props;
    const calculatedCount = useMemo(() => count ? count : 0, [count]);
    const headerStore = useContext(HeaderContext);

    const pageNumbers = buildPagination(calculatedCount, pageSize, page);
    const formattedCount = new Intl.NumberFormat('en-US').format(calculatedCount) + ' Record' + (calculatedCount === 1 ? '' : 's');
    if (pageNumbers.length === 1 && !!count)
        return showCount ? <Badge color="light">{formattedCount}</Badge> : null;
    const lastPage = pageNumbers[pageNumbers.length - 1];

    function hasCount() {
        return count !== undefined && count !== null;
    }

    const PaginationView =  headerStore.useNewDesign ? NewPaginationView : DefaultPaginationView;

    return <PaginationView className={className} showCount={showCount} hasCount={hasCount} formattedCount={formattedCount} page={page} setPage={setPage} count={count} pageNumbers={pageNumbers} lastPage={lastPage} />;
}

type PaginationViewProps = {
    className?: string;
    showCount: boolean | undefined;
    hasCount: () => boolean;
    formattedCount: string;
    page: number;
    setPage: (page: number) => void;
    count: number | undefined;
    pageNumbers: number[];
    lastPage: number;
};

function DefaultPaginationView({className, showCount, hasCount, formattedCount, page, setPage, count, pageNumbers, lastPage}: PaginationViewProps) {
    return <div className={classNames('pagination', className)}>
        {showCount && hasCount() && <Badge color="light" className="mr-10">{formattedCount}</Badge>}
        <div className="pt-2">
            <button disabled={page === 0} onClick={() => setPage(0)}><i className="icon-double-left" /></button>
            <button disabled={page === 0} onClick={() => setPage(page - 1)}><i className="icon-left" /></button>
            {count && pageNumbers.map(pageNum => pageNum < 0 ?
                <span key={pageNum}>&hellip;</span> :
                <button key={pageNum} className={pageNum === page ? 'active' : ''} onClick={() => setPage(pageNum)}>
                    {pageNum + 1}
                </button>)}
            {!hasCount() && <button className="active cursor-default">{page + 1}</button>}
            <button disabled={hasCount() && page === lastPage} onClick={() => setPage(page + 1)}><i className="icon-right" /></button>
            {hasCount() && <button disabled={page === lastPage} onClick={() => setPage(lastPage)}><i className="icon-double-right" /></button>}
        </div>
    </div>;
}

function NewPaginationView({className, showCount, hasCount, formattedCount, page, setPage, count, pageNumbers, lastPage}: PaginationViewProps) {
    return <div className={classNames('pagination', 'new', className)}>
        {showCount && hasCount() && <Badge color="light" className="mr-10">{formattedCount}</Badge>}
        <div className="pt-2 flex flex-row">
            {(!hasCount() || hasCount() && !!count) && <button className='flex justify-center items-center' disabled={page === 0} onClick={() => setPage(page - 1)}><CaretLeft size={16} /> </button>}
            {!!count && pageNumbers.map(pageNum => pageNum < 0 ?
                <span className='elipses' key={pageNum}>&hellip;</span> :
                <button key={pageNum} className={pageNum === page ? 'active' : ''} onClick={() => setPage(pageNum)}>
                    <span>{pageNum + 1}</span>
                </button>)}
            {!hasCount() && <button className="active cursor-default"><span>{page + 1}</span></button>}
            {(!hasCount() || hasCount() && !!count) && <button className='flex justify-center items-center' disabled={hasCount() && page === lastPage} onClick={() => setPage(page + 1)}><CaretRight size={16} /> </button>}
        </div>
    </div>;
}
