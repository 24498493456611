import {useMemo, useState} from 'react';
import {DataConfig} from '../types';
import Button from '../../components/button';
import Modal from '../../components/modal';
import auth from '../../lib/auth';
import Spinner from '../../components/spinner';

export function AsyncDownloadVis(props: {dataConfig: DataConfig}) {
    const {dataConfig} = props;
    const [showModal, setShowModal] = useState<boolean>(false);
    const defaultFileName = useMemo(() => dataConfig.options.defaultFileName + '_' + new Date().toLocaleDateString().replace(/\//g, '-') + '.csv', [dataConfig.options.defaultFileName]);
    const [fileName, setFileName] = useState<string>(defaultFileName);
    const [submitting, setSubmitting] = useState<boolean>(false);

    function submitDownload() {
        setSubmitting(true);
        setTimeout(() => {
            setSubmitting(false);
            setShowModal(false);
        }, 3000);
    }

    return <div>
        <Button color="blue" onClick={() => setShowModal(true)}><i className="icon-download" /> {dataConfig.options.downloadText || 'Download'}</Button>
        {showModal && <Modal title={dataConfig.options.downloadText || 'Download'} onClose={() => setShowModal(false)}>
            <div className="w-full h-full">
                <div className="flex justify-between space-x-5">
                    <img src="/assets/tool-discovery/tools.png" className="h-24" alt="Download data"/>
                    <p>
                        Your download will take time to put together. We will send you an email at <a className="text-orange-500" href={`mailto: ${auth.principal?.email}`}>{auth.principal?.email}</a> once complete.
                    </p>
                </div>
                <div className="flex flex-col space-y-1 mt-5">
                    <label>File Name</label>
                    <div className="w-2/3 flex space-x-5 items-center">
                        <input className="w-3/4" type="text" value={fileName} onChange={e => setFileName(e.target.value)} />
                        {submitting && <Spinner />}
                    </div>

                </div>

                <div className="absolute bottom-5 w-full flex space-x-5 justify-end pr-8">
                    <Button color="light" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button color="blue" onClick={submitDownload}>Submit</Button>
                </div>
            </div>
        </Modal>}
    </div>;
}

export default function AsyncDownloadRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void}) {
    return <AsyncDownloadVis dataConfig={props.dataConfig} />;
}