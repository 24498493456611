import AuthWall from '../../../components/auth-wall';
import Template from '../../../components/template';
import {useParams, useNavigate} from 'react-router-dom';
import PageMenu from '../../../components/page-menu';
import {SiteFeature} from '../../../lib/check-access';
import AffiliationExplorer from './view';
import activityTracker from '../../../lib/activity-tracker';
import {QuantumGraph} from 'quantum-graph';
import InsightContainer from '../../../insight-container';
import SaveModal from '../../../components/save-modal';
import {getQueryStoreGraph} from '../../../insight-container/renderers/query-data-renderer/store';
import DataLibraryModal, {basicMDUpdateStrategy} from '../../../components/data-library-modal';
import {hideKeys} from '../data-explorer/data-explorer';
import {useCallback, useContext, useMemo, useState} from 'react';
import {DataConfig} from '../../../insight-container/types';
import Library from '../../../lib/library';
import Variable from '../../../lib/metadata';
import updateAffiliationSelection from './selection';
import createLibrary from '../../../lib/library/create';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';
import {HeaderContext} from '../../../components/header/context';
import {useFavorite} from '../../../lib/favorites';
import {StarFilled, Star} from '@carbon/icons-react';
import Breadcrumb from '../../../components/breadcrumb';

type AffiliationExplorerParams = {
    from?: string,
    to?: string,
    type?: string,
}

export default function AffiliationExplorerPage() {
    const [config, setConfig] = useState<DataConfig>();
    const [showSaveQueryModal, setShowSaveQueryModal] = useState<boolean>(false);
    const [addData, setAddData] = useState(false);
    const [library, setLibrary] = useState<Library>();
    const [affConfig, setAffConfig] = useState<{from: string, to: string, type: string}>();
    const [fromSelection, setFromSelection] = useState<Array<Variable>>([]);
    const [toSelection, setToSelection] = useState<Array<Variable>>([]);
    const params = useParams<AffiliationExplorerParams>();
    const navigate = useNavigate();
    const {useNewDesign} = useContext(HeaderContext);

    function onSelect(graph: QuantumGraph, affDetails: {from: string, to: string, type: string}) {
        if (!affDetails.from || !affDetails.to || !affDetails.type)
            return;

        const url = `/affiliations/${affDetails.from}/${affDetails.to}/${affDetails.type}`;
        navigate(url);
        activityTracker.setRecentActivity('Affiliation Explorer');

        let dataConfig: DataConfig = {
            queries: [graph],
            options: {
                colorColumns: false,
            }
        };
        setConfig(dataConfig);
        setAffConfig(affDetails);
        const library = createLibrary(graph, getDefaultDataDefRepo());
        setLibrary(library);
    }

    const updateSelection = useCallback((graph: QuantumGraph, metadata: Array<Variable>) => {
        if (!affConfig)
            return;
        const [newFrom, newTo] = updateAffiliationSelection(metadata, affConfig.from, affConfig.to, library!, getDefaultDataDefRepo());
        setFromSelection(newFrom);
        setToSelection(newTo);
        setAddData(false);
    }, [affConfig, library]);

    const AffiliationExplorerView = useNewDesign ? NewAffiliationExplorerView : DefaultAffiliationExplorerView;

    return <AuthWall siteFeature={SiteFeature.CrosswalkBuilder}>
        <Template>
            <AffiliationExplorerView params={params} onSelect={onSelect} config={config} />
            {showSaveQueryModal && config && <SaveModal onClose={() => setShowSaveQueryModal(false)}
                graph={getQueryStoreGraph() || config.queries[0]}
                reference='Affiliation Explorer'/>}
            {addData && config && <DataLibraryModal graph={config.queries[0]} selection={fromSelection.concat(toSelection)}
                library={library!} updateStrategy={basicMDUpdateStrategy}
                variableFilter={hideKeys} onClose={updateSelection} />}
        </Template> 
    </AuthWall>;
}

interface AffiliationExplorerViewProps {
    params: AffiliationExplorerParams,
    onSelect: (graph: QuantumGraph, affDetails: {
        from: string;
        to: string;
        type: string;
    }) => void,
    config?: DataConfig
}

function DefaultAffiliationExplorerView({params, onSelect, config}: AffiliationExplorerViewProps) {
    return <>
        <PageMenu>
            <div className="center">
                <h1>Affiliation Explorer</h1>
            </div>
        </PageMenu>
        <div className="w-full px-8 mt-10">
            <AffiliationExplorer from={params.from} to={params.to} type={params.type} onSelect={onSelect} />
            <hr/>
            {config && <InsightContainer type="query" dataConfig={config} />}
        </div>
    </>;
}

function NewAffiliationExplorerView({params, onSelect, config}: AffiliationExplorerViewProps) {
    const [myFavorites, setFavorite] = useFavorite('Affiliation Explorer');

    const isFavorite = useMemo(() => myFavorites.some(favorite => favorite.url === window.location.pathname), [myFavorites, window.location.pathname]);

    const FavoriteIcon = isFavorite ? StarFilled : Star;

    return <div className='px-10 py-8 bg-background-gray-100'>
        <Breadcrumb crumbs={[{name: 'Home', url: '/'}, {name: 'Affiliation Explorer', url: '/affiliations'}]} />
        <div className='flex mt-6 mb-8 leading-10 gap-3'>
            <FavoriteIcon size={32} className='text-gray-800 hover:cursor-pointer' onClick={setFavorite} />
            <h1 className='text-gray-800 text-[32px] font-light m-0'>Affiliation Explorer</h1>
        </div>
        <div className="mt-5">
            <AffiliationExplorer from={params.from} to={params.to} type={params.type} onSelect={onSelect} />
        </div>
        <div className="mt-5">
            {config && <InsightContainer type="query" dataConfig={config} />}
        </div>
    </div>;
}