import LibraryIndex from './library-index';
import Variable, {batchLoadDatasetInfo, batchLoadDatasetMetadata, DatasetInfo} from '../metadata';
import {datasetPermissions} from '../api';

export abstract class Section {

    name: string;
    index: LibraryIndex;
    dsInfo: Record<string, DatasetInfo>;

    protected constructor() {
        this.name = '';
        this.index = new LibraryIndex([]);
        this.dsInfo = {};
    }

    abstract loadName(signal: AbortSignal): Promise<void>

    protected abstract getDatasets(): Array<string>;

    async loadMetadata(signal: AbortSignal) {
        if (this.index.getGrains().length) // Already loaded
            return;

        const datasets = this.getDatasets();

        // Get disabled datasets
        const test: {valid: Array<boolean | object>} = await datasetPermissions.validateAll(datasets, signal);
        const disabled: Record<string, boolean> = {};
        test.valid.forEach((valid, i) => {
            if (!valid)
                disabled[datasets[i]] = true;
        });

        const allVariables = await batchLoadDatasetMetadata(datasets, signal);
        const allDsInfo = await batchLoadDatasetInfo(datasets, signal);
        let variables: Array<Variable> = [];
        const seenVars = new Set<string>();
        datasets.forEach((dataset, i) => {
            const dsVars = allVariables[i];

            // Lock variables whose datasets are forbidden
            if (disabled[dataset])
                dsVars.forEach(v => v.locked = true);

            // Remove duplicate variables (such as from a dim and composite)
            // Exception: year keys
            const filteredVars: Array<Variable> = [];
            for (const variable of dsVars) {
                const key = (variable.getTag('dataset') || variable.dataset) + ':' + (variable.getTag('srcName') || variable.name);
                if (variable.name !== 'year_key' && seenVars.has(key))
                    continue;
                filteredVars.push(variable);
                seenVars.add(key);
            }

            variables = variables.concat(filteredVars);
            this.dsInfo[dataset] = allDsInfo[i];
        });

        this.index = new LibraryIndex(variables);
    }
}

export default class Library {

    protected sections: Array<Section>;

    constructor() {
        this.sections = [];
    }

    async loadAllNames(signal: AbortSignal) {
        for (const section of this.sections)
            await section.loadName(signal);
    }

    async loadAllMetadata(signal: AbortSignal) {
        for (const section of this.sections)
            await section.loadMetadata(signal);
    }

    getSections() {
        return this.sections;
    }

    getSection(i: number) {
        return this.sections[i];
    }

    map<T>(f: (s: Section, i: number) => T): Array<T> {
        return this.sections.map(f);
    }

}