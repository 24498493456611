import Modal from '../modal';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import Button from '../button';
import V2QuantumGraph from 'quantum-graph/out/v2/v2-graph';
import {BasicFilter} from 'quantum-graph/out/v2/filter';
import Select, {BasicSelectType} from '../select';
import {TestType} from 'quantum-graph';
import Variable from '../../lib/metadata';
import V2GraphHandler from '../../lib/graph-tools/graph-handler/v2-graph-handler';
import {loadAllYears} from './load-years';
import {DataDefRepo} from '../../lib/user-data-defs';
import Spinner from '../spinner';
import {HeaderContext} from '../header/context';

type Props = {
    query: V2QuantumGraph,
    dataDefRepo: DataDefRepo,
    variable: Variable,
    onCancel: () => void,
    onClose: (years: Array<number>, v: Variable, testType?: TestType) => void,
    singleYearOnly?: boolean
}

type YearFilterProps = Props & {
    year: string,
    setYear: (year: string) => void,
    type: string,
    setType: (type: string) => void,
    startYear: number,
    setStartYear: (year: number) => void,
    endYear: number,
    setEndYear: (year: number) => void,
    singleYearOnly?: boolean
}

export enum ViewType {
    Single = 'single',
    Range = 'range',
    Greater = 'greater',
    Lesser = 'lesser',
    Latest = 'latest',
    Earliest = 'earliest'
}

export const ViewOptions: Array<BasicSelectType> = [
    {value: ViewType.Single, label: 'Single Year'},
    {value: ViewType.Range, label: 'Range of Years'},
    {value: ViewType.Greater, label: 'After Year'},
    {value: ViewType.Lesser, label: 'Before Year'},
    {value: ViewType.Latest, label: 'Latest Year'},
    {value: ViewType.Earliest, label: 'Earliest Year'},
];

export function YearFilter(props: YearFilterProps) {
    const {query, variable, dataDefRepo, year, setYear, type, setType, startYear, setStartYear, endYear, setEndYear, singleYearOnly} = props;

    const [years, setYears] = useState<number[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const gh = new V2GraphHandler(query);
        const datasets = gh.getDatasets();
        const controller = new AbortController();
        loadAllYears(datasets, dataDefRepo, controller.signal).then(years => {
            setYears(years);
            if (!years.includes(startYear))
                setStartYear(years[0]);
            if (!years.includes(endYear))
                setEndYear(years[years.length - 1]);
            setLoading(false);
        });
        return () => controller.abort();
    }, [query, dataDefRepo]);
        
    function updateType(type: string) {
        setType(type);
        if ([ViewType.Single, ViewType.Greater, ViewType.Lesser].some(viewType => type === viewType))
            setYear(variable.getTag('LatestTime') || '');
    }
        
        
    function transformYearsForSelect() {
        return years.map(year => ({
            label: year.toString(),
            value: year.toString()
        }));
    }
        
    function getThroughYears() {
        return transformYearsForSelect().filter(year => parseInt(year.value) > startYear);
    }

    function getViewOptions() {
        return ViewOptions.filter(view => !singleYearOnly || view.value === ViewType.Single);
    }

    return (
        <>
            <label className="font-bold block">
                        Time View
            </label>

            <Select<BasicSelectType> data={getViewOptions()} value={type} onChange={updateType}>
                {_ => _.value}
                {_ => _.label}
            </Select>

            {[ViewType.Single, ViewType.Greater, ViewType.Lesser].some(viewType => type === viewType) && <div className="mt-5">
                <label className="font-bold block">
                    Year
                </label>
                {loading ? <Spinner /> :
                    <Select<BasicSelectType> data={transformYearsForSelect()} value={year} onChange={value => setYear(value)}>
                        {_ => _.value}
                        {_ => _.label}
                    </Select>
                }
            </div>}

            {type === ViewType.Range && <div className="mt-5 flex space-x-5">
                <div>
                    <label className="font-bold block">
                        From
                    </label>
                    {loading ? <Spinner /> :
                        <Select<BasicSelectType> data={transformYearsForSelect()} value={startYear.toString()} onChange={value => setStartYear(parseInt(value))}>
                            {_ => _.value}
                            {_ => _.label}
                        </Select>
                    }
                </div>
                <div className="mt-5 flex items-center">
                    -
                </div>
                <div>
                    <label className="font-bold block">
                        Through
                    </label>
                    {loading ? <Spinner /> :
                        <Select<BasicSelectType> data={getThroughYears()} value={endYear.toString()} onChange={value => setEndYear(parseInt(value))}>
                            {_ => _.value}
                            {_ => _.label}
                        </Select>
                    }
                </div>
            </div>}
        </>
    );
}

export default function YearModal(props: Props) {
    const {query, variable, onCancel, onClose, singleYearOnly} = props;

    const filter = useMemo(() => query.filters.find(f => f instanceof BasicFilter && f.column.column === 'year_key') as BasicFilter, [query]);
    const [type, setType] = useState<string>((filter?.test === TestType.Eq || singleYearOnly) ? ViewType.Single : ViewType.Range);
    const [year, setYear] = useState<string>(filter ? String(filter.value) : variable.getTag('LatestTime') || '');
    const [startYear, setStartYear] = useState<number>(0);
    const [endYear, setEndYear] = useState<number>(0);
    const {useNewDesign} = useContext(HeaderContext);
    
    function handleClose() {
        if (type === ViewType.Single)
            onClose([Number(year)], variable);
        else if (type === ViewType.Greater)
            onClose([Number(year)], variable, TestType.GtEq);
        else if (type === ViewType.Lesser)
            onClose([Number(year)], variable, TestType.LtEq);
        else if (type === ViewType.Latest)
            onClose([], variable, TestType.Latest,);
        else if (type === ViewType.Earliest)
            onClose([], variable, TestType.Earliest);
        else
            onClose([startYear, endYear], variable);
    }

    return <Modal title="Edit Year" onClose={onCancel} hasButtons primaryButtonText='Apply' primaryButtonOnClick={handleClose} secondaryButtonText='Cancel' secondaryButtonOnClick={onCancel}>
        <YearFilter {...props} year={year} setYear={setYear} type={type} setType={setType} startYear={startYear} setStartYear={setStartYear} endYear={endYear} setEndYear={setEndYear} singleYearOnly={singleYearOnly} />
        {!useNewDesign && <div className="modal-buttons">
            <Button color="primary" disabled={type === ViewType.Single && !year} onClick={handleClose}>Save</Button>
            <Button color="white" onClick={onCancel}>Cancel</Button>
        </div>}
    </Modal>;
}