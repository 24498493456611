import {useNavigate} from 'react-router-dom';
import Alert from '../alert';
import React, {useMemo} from 'react';
import activityTracker from '../../lib/activity-tracker';


export default function RecentActivity() {
    const navigate = useNavigate();

    const recentActivity = useMemo(() => {
        return activityTracker.activityQueue[0] ?? null;
    }, []);

    function navigateRecentActivity() {
        if (recentActivity) {
            if (recentActivity.customQuery) {
                navigate('/data/explore', {state: {
                    query: recentActivity.customQuery,
                    title: recentActivity.pageName
                }});
            } else if (recentActivity.pageUrl.includes(window.location.host)) {
                const hostIndex = recentActivity.pageUrl.indexOf(window.location.host) + window.location.host.length;
                const urlPath = recentActivity.pageUrl.substring(hostIndex, recentActivity.pageUrl.length);
                navigate(urlPath);
            } else {
                window.location.href = recentActivity.pageUrl;
            }
        }
    }

    return <div>
        {recentActivity && <div className="w-full md:w-1/2 mx-auto relative">
            <div className="absolute left-0 w-full px-2" style={{top: 30}}>
                <Alert color="green" icon="icon-info">
                    <p>Pick up where you left off: <button data-testid="activity-button" className="ml-2 cursor-pointer text-blue-300 hover:underline" onClick={navigateRecentActivity}>{recentActivity.toolName} {recentActivity.pageName && `-> ${recentActivity.pageName}`} ({new Date(recentActivity.timestamp).toLocaleDateString()})</button></p>
                </Alert>
            </div>
        </div>}
    </div>;
}