import {DataConfig} from '../../../../insight-container/types';
import {QuantumGraph} from 'quantum-graph';
import Variable from '../../../../lib/metadata';
import buildCommonCoreConfigRAD from './rad';
import buildCommonCoreQueryVRDC from './vrdc';

export default function buildCommonCoreConfig(regionType: string, dataSource: string, memberCategory: string, conditionCategory: string, serviceCategory: string, demoBreakout: boolean, lob: string, src: string, variables: Array<Variable>): DataConfig {
    let query: QuantumGraph;
    if (dataSource === 'RAD')
        query = buildCommonCoreConfigRAD(regionType, lob, variables);
    else if (dataSource === 'VRDC')
        query = buildCommonCoreQueryVRDC(regionType, memberCategory, conditionCategory, serviceCategory, src, demoBreakout, variables);
    else
        throw new Error('Unsupported data source: ' + dataSource);


    return {
        queries: [query],
        options: {
            hideExplore: true,
            exploreLink: window.location.pathname,
        },
    };
}