import {useContext, useState} from 'react';
import {observer} from 'mobx-react';
import {CanvasInsertContext} from '../../lib/context';
import {deserialize, GraphVersions} from 'quantum-graph';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import {QueryObj} from '../../../../lib/data-types';
import Button from '../../../../components/button';
import createGraphHandler from '../../../../lib/graph-tools/graph-handler/create';
import {templates as quantumTemplates} from './custom-quantum-templates';
import {getDefaultDataDefRepo} from '../../../../lib/data-defs';

const DEFAULT_QUANTUM_QUERY: QueryObj = {
    version: GraphVersions.V2,
    baseQueryGraph: {
        baseQuery: {
            queryType: QueryType.Dim,
            tableNodes: [
                {
                    id: 'facility',
                    dataset: 'facility',
                    columns: [
                        'torch_facility_id',
                        'facility_name'
                    ]
                }
            ]
        }
    },
    filters: [],
    sort: []
};

const TEMPLATES = new Map(quantumTemplates);

const CustomQuantumQuery = observer(() => {
    const insertStore = useContext(CanvasInsertContext);
    const [quantumQuery, setQuantumQuery] = useState<string>(getQuery());

    function getQuery(): string {
        if (insertStore.query)
            return JSON.stringify(createGraphHandler(insertStore.query, getDefaultDataDefRepo()).serialize() as QueryObj);

        return JSON.stringify(DEFAULT_QUANTUM_QUERY);
    }

    function saveQuery() {
        insertStore.query = deserialize(JSON.parse(quantumQuery));
        insertStore.nextScreen();
    }

    return (
        <div className='h-full'>
            <h2>Custom Quantum Query</h2>
            <p className='inline-block'>Use the editor below to create and adjust the custom quantum query.</p>
            <select className='inline-block mb-2 ml-10'
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setQuantumQuery(JSON.stringify(TEMPLATES.get(event.target.value)));
                }}
                defaultValue=""
            >
                <option disabled selected hidden value="">
                    Choose a template
                </option>
                {Array.from(TEMPLATES.entries()).map(([key]) => (
                    <option key={key} value={key}>
                        {key}
                    </option>
                ))}
            </select>
            <div className='json-editor'>
                <textarea value={quantumQuery} onChange={e => setQuantumQuery(e.target.value)}/>
            </div>
            <div className='w-full flex justify-end items-center mt-10'>
                <Button color='blue' onClick={saveQuery}>
                    Save <i className='icon-save' />
                </Button>
            </div>
        </div>
    );
});

export default CustomQuantumQuery;