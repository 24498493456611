import {DataTable, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow} from '@carbon/react';
import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {NetworkContext} from '../lib/context';
import ComplianceIndicator from './compliance-indicator';

const ComplianceSummaryTable = observer(() => {
    const networkOptimizationStore = useContext(NetworkContext);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const controller = new AbortController();
        const loadData = async () => {
            setLoading(true);
            await networkOptimizationStore.loadSpecialtiesData(controller);
            setLoading(false);
        };

        loadData();
        return () => controller.abort();
    }, [networkOptimizationStore.appliedGeographyFilter, networkOptimizationStore.runId]);

    const {complianceScores} = networkOptimizationStore;

    if (!networkOptimizationStore.readyToLoadData()) {
        return null; 
    }

    if (loading) {
        return <p>Loading...</p>; 
    }

    const rows = complianceScores.length > 0 ? complianceScores.map((score, index) => ({
        id: index.toString(),
        cells: [
            {id: 'specialty', value: score.name},
            {id: 'passFail', value: <ComplianceIndicator passed={score.compliance} showText />},
            {id: 'numProviders', value: score.scores[2]?.value || '-'},
            {id: 'accessTime', value: score.scores[0]?.value || 0},
            {id: 'accessDistance', value: score.scores[1]?.value || 0},
            {id: 'complianceScore', value: 90},
        ],
    })) : [];

    const headers = [
        {key: 'specialty', header: 'Specialty'},
        {key: 'passFail', header: 'Pass/Fail'},
        {key: 'numProviders', header: '# of Providers'},
        {key: 'accessTime', header: '# with Access (Time)'},
        {key: 'accessDistance', header: '# with Access (Distance)'},
        {key: 'complianceScore', header: 'Compliance Target'},
    ];

    const getCellClassName = (cellId: string, passed: any) => {
        if (['numProviders', 'accessTime', 'accessDistance', 'complianceScore'].includes(cellId)) {
            return passed ? 'bg-green-200' : 'bg-yellow-200';
        }
        return '';
    };

    return (
        <div className="compliance-summary-page bg-white p-4 w-full">
            <h3 className="text-xl font-bold mb-4">Compliance Summary</h3>
            <TableContainer className="max-h-[70vh] overflow-x-auto">
                <DataTable rows={rows} headers={headers}>
                    {({headers}) => (
                        <Table className="min-w-full">
                            <TableHead>
                                <TableRow className="sticky table top-0 bg-white z-10">
                                    {headers.map(header => (
                                        <TableHeader
                                            key={header.key}
                                            className={`bg-white z-20 border-b border-gray-300 text-left p-2 ${
                                                header.key === 'specialty' ? 'w-[40%]' : 'w-[12%]'
                                            }`} 
                                        >
                                            {header.header}
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className="block max-h-[60vh] overflow-y-auto">
                                {rows.map(row => (
                                    <TableRow key={row.id} className="table w-full">
                                        {row.cells.map((cell, index) => (
                                            <TableCell
                                                key={cell.id}
                                                className={`p-2 ${getCellClassName(cell.id, row.cells[1].value.props.passed)}`}
                                                style={{width: index === 0 ? '40%' : '12%'}} 
                                            >
                                                {cell.value}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </DataTable>
            </TableContainer>
        </div>
    );
    
});

export default ComplianceSummaryTable;
