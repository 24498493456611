import React from 'react';
import Button from '../../../components/button';
import EndpointDoc from './endpoint';


export default function APIDocPerformQuery() {
    return <div>
        <h2>Performing Data Queries</h2>
        <p>
            Once you have built your query, you can send it to the following endpoint for execution:
        </p>
        <EndpointDoc method="post" path="/v2/quantum/data/execute"
            description="Executes data queries against the Torch Insight data warehouse."
            response="Your data will returned in JSON format, using the compressed data format.">
            Provide the query JSON in the request body.
        </EndpointDoc>
        <p>
            Note: All queries are validated before execution, checking both user access permissions and structural
            correctness of the query.
        </p>

        <hr/>
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/api/data-formats">Supported Data Formats</Button>
        </p>
    </div>;
}