import Library, {Section} from '../../../lib/library';
import Variable, {filterMetadataByPermissions, loadDatasetInfo} from '../../../lib/metadata';
import LibraryIndex from '../../../lib/library/library-index';

export class CRBSection extends Section {
    dataset: string;
    metadata: Array<Variable>;

    constructor(dataset: string, metadata: Array<Variable>) {
        super();
        this.dataset = dataset;
        this.name = 'Commercial Reimbursement Benchmarks';
        this.metadata = metadata;
    }

    async loadMetadata(signal: AbortSignal): Promise<void> {
        // Metadata is loaded previously. Load the dataset info
        this.dsInfo[this.dataset] = await loadDatasetInfo(this.dataset, signal);

        // We do need to load the dataset module to see what columns we need to remove
        const metadata = await filterMetadataByPermissions(this.metadata);
        this.index = new LibraryIndex(metadata);
    }

    protected getDatasets(): Array<string> {
        return [this.dataset];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async loadName(signal: AbortSignal) {}
}

export class CRBLibrary extends Library {

    constructor(dataset: string, metadata: Array<Variable>) {
        super();
        metadata = metadata.filter(v => v.getTag('Role'));
        this.sections = [new CRBSection(dataset, metadata)];
    }
}
