import React, {FormEvent, useContext, useRef, useState} from 'react';
import Template from '../../components/template';
import Card from '../../components/card';
import CenteredLayout from '../../components/layout/centered-layout';
import logo from '../../components/images/logo-full.svg';
import Button from '../../components/button';
import auth from '../../lib/auth';
import handleError from '../../lib/error';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import saveEvent from '../../lib/analytics';
import {HeaderContext} from '../../components/header/context';
import {ErrorFilled, Login} from '@carbon/icons-react';
import Alert from '../../components/alert';
import Input from '../../components/input';
import LoginTitle from './login-title';

export default function LogInPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const iframe = useRef<HTMLIFrameElement>(null);
    const [validation, setValidation] = useState<boolean>(false);
    const headerStore = useContext(HeaderContext);

    function login(e: FormEvent | void) {
        e && e.preventDefault();
        if (!email || !password) {
            setValidation(true);
            return;
        }

        setError('');
        setLoading(true);
        auth.login(email, password)
            .then(token => {
                if (iframe.current && iframe.current.contentWindow)
                    iframe.current.contentWindow.postMessage(token, window.location.protocol + '//' + process.env.REACT_APP_TI_DOMAIN);

                // Give a bit of time for the iframe to receive and process the message
                setTimeout(() => {
                    // For users who have only purchased HPT, take them directly to the tool
                    if (auth.principal?.modules?.length === 1 && auth.principal.modules[0].name.includes('Pricing')) {
                        navigate('/hpt/medicare-relativity-score');
                    } else {
                        const dest = location.state ? location.state.dest : '/';
                        navigate(dest);
                    }
                }, 150);
            })
            .catch(err => {
                if (err instanceof auth.InvalidAuthError) {
                    saveEvent('loginError', {email});
                    setError('Invalid username/password');
                } else
                    handleError(err, setError);
                setLoading(false);
            });
    }

    function legacyLogin() {
        return <div className='container mt-16 md:mt-32'>
            <CenteredLayout size={3}>
                <Card>
                    <div className='text-center mt-8 mb-16'>
                        <img src={logo} alt='Torch Insight' className='w-64 inline-block' />
                    </div>

                    <form onSubmit={login}>
                        <div className='mt-4 text-gray-500 text-sm font-bold'>Email</div>
                        <input type='text' required placeholder='Enter Email' className='block mt-1 w-full'
                            value={email} onChange={e => setEmail(e.target.value)} />
                        <div className='mt-4 text-gray-500 text-sm font-bold'>Password</div>
                        <input type='password' required placeholder='Enter Password' className='block mt-1 w-full'
                            value={password} onChange={e => setPassword(e.target.value)} />

                        <Button color='primary' className='block w-full mt-8' disabled={loading} loading={loading}>
                            Log In
                        </Button>
                    </form>
                    {error && <div className='text-red-500 mt-1'>{error}</div>}
                    <div className='mt-8'>
                        <Link to='/reset-password'>Forgot Password?</Link>
                    </div>
                    <iframe ref={iframe} title='ti' src={'//' + process.env.REACT_APP_TI_DOMAIN + '/login'}
                        frameBorder='0' style={{visibility: 'hidden', height: 0}} />
                </Card>
            </CenteredLayout>
        </div>;
    }

    function dppLogin() {
        return <div className='login-page'>
            <div className="wrapper">
                <div className="w-full">
                    <LoginTitle>
                        <span>Log in</span>
                    </LoginTitle>
                    {error && <Alert color='red' icon={<ErrorFilled />}><strong>Error: </strong>Invalid username or password</Alert>}
                    <div className='mt-8'>
                        <form onSubmit={login}>
                            <div className='mt-4 text-gray-500 text-sm font-bold'>Email</div>
                            <Input value={email} onChange={e => setEmail(e.target.value)} tabIndex={1} placeholder='Email' validation={validation && !email} />
                            <div className='mt-4 flex justify-between items-center'>
                                <div className='text-gray-500 text-sm font-bold'>Password</div>
                                <Link className="text-medinsight-blue-300" to='/reset-password' tabIndex={4}>Forgot Password?</Link>
                            </div>
                            <Input value={password} onChange={e => setPassword(e.target.value)} tabIndex={2} placeholder='Password' type='password' validation={validation && !password} />
                            <Button color='milliman-blue' className='block w-full mt-8' disabled={loading} loading={loading}>
                                <div className='w-full h-full flex space-x-3 justify-center items-center py-2' tabIndex={3}>
                                    <span className='block'>Log in</span>
                                    <Login />
                                </div>
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
    }

    return <Template showFooter={headerStore.useNewDesign}>
        {headerStore.useNewDesign ? dppLogin() : legacyLogin()}
    </Template>;
}