import React, {ReactNode, useState} from 'react';
import Select from '../select';
import Badge from '../badge';
import Button from '../button';

export type Props<T> = {
    data: Array<T>,
    value: Array<string>,
    onChange: (value: Array<string>) => void,
    children: [
        (item: T, i: number) => string,
        (item: T, i: number) => ReactNode,
    ],
    searchable?: boolean,
    disabled?: boolean,
    optionDisabled?: (item: T, i: number) => boolean,
}

export default function MultiSelect<T>(props: Props<T>) {
    const {data, value, onChange} = props;
    const [getValue, getLabel] = props.children;
    const [current, setCurrent] = useState('');
    const optionDisabled = props.optionDisabled || (() => false);

    function add() {
        const newValue = value.slice();
        newValue.push(current);
        onChange(newValue);
        setCurrent('');
    }

    function remove(i: number) {
        const newValue = value.slice();
        newValue.splice(i, 1);
        onChange(newValue);
    }

    return <div>
        <Select data={data} value={current} onChange={setCurrent} optionDisabled={optionDisabled}>
            {getValue}
            {getLabel}
        </Select>
        <Button color="blue ml-2" disabled={!current} onClick={add}>Add</Button>
        <div className="mt-3">
            {!value.length && <em className="text-gray-500">None Selected</em>}
            {value.map((item, i) => <Badge color="light" key={i} onClose={() => remove(i)}>
                {getLabel(data.find((d, i) => getValue(d, i) === item)!, i)}
            </Badge>)}
        </div>
    </div>;
}
