import React, {useState} from 'react';

export const MaxHeaderLength = 35;

type Props = {
  value: string,
}

export default function HeaderName(props: Props) {
    const {value} = props;
    const [showAll, setShowAll] = useState(false);
    const isLong = value.length > MaxHeaderLength;

    const displayValue = isLong && !showAll ? `${value.substr(0, MaxHeaderLength - 3)}...` : value;

    return (
        <div
            className="header-name"
            onMouseEnter={() => setShowAll(true)}
            onMouseLeave={() => setShowAll(false)}
        >
            {displayValue}
        </div>
    );
}