import {useEffect, useMemo} from 'react';
import ReactFlow, {Background, Controls, useNodesState, useEdgesState, ReactFlowProvider, useNodesInitialized, useStore, useReactFlow} from 'reactflow';
import 'reactflow/dist/style.css';
import {DataConfig} from '../types';
import OwnershipDataRenderer, {OwnershipRenderChildProps} from '../renderers/ownership-data-renderer';
import {addAffiliationDataToNetwork, convertToNetwork, getLayoutedElements, nodeTypes} from './util';

export const NetworkChart = (props: OwnershipRenderChildProps) => {
    const data = useMemo(() => {
        const net = convertToNetwork(props.data.facilities);
        const nodesWithData = addAffiliationDataToNetwork(props.data, net.nodes, net.links);
        return {
            nodes: nodesWithData,
            links: net.links
        };
    }, [props.data]);
    const [nodes, setNodes] = useNodesState(data.nodes);
    const [edges, setEdges] = useEdgesState(data.links);
    const nodeInternals = useStore((state) => state.nodeInternals);
    const instance = useReactFlow();

    const nodesInitialized = useNodesInitialized();

    useEffect(() => {
        if (nodesInitialized) {
            const {nodes: newNodes, edges: newEdges} = getLayoutedElements(Array.from(nodeInternals.values()), edges, 'LR');
            setNodes(newNodes);
            setEdges(newEdges);
            instance.fitView();
        }
    }, [nodesInitialized]);

    useEffect(() => {
        const zoom = instance.getZoom();
        if (zoom < .8) {
            instance.fitView();
        }
    }, [nodes]);

    return (
        <div style={{height: 800}}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                minZoom={.01}
                fitView
                contentEditable={false}
            >
                <Background/>
                <Controls/>
            </ReactFlow>
        </div>
    );
};

export default function NetworkRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void}) {
    return <ReactFlowProvider>
        <OwnershipDataRenderer dataConfig={props.dataConfig} loadCB={props.loadCB}>
            {NetworkChart}
        </OwnershipDataRenderer>
    </ReactFlowProvider>;
}