import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {EditorState, SerializedEditorState, SerializedLexicalNode} from 'lexical';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {HeadingNode, QuoteNode} from '@lexical/rich-text';
import {TableCellNode, TableNode, TableRowNode} from '@lexical/table';
import {ListItemNode, ListNode} from '@lexical/list';
import {AutoLinkNode, LinkNode} from '@lexical/link';
import classNames from '../../lib/class-names';
import RichTextEditable from './plugins/rich-text-editable';
import ToolbarPlugin from './plugins/toolbar-plugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {TablePlugin} from '@lexical/react/LexicalTablePlugin';
import theme from './theme';
import './rich-text-editor.scss';

type Props = {
    content: string | null,
    onUpdate?: (editorState: SerializedEditorState<SerializedLexicalNode>) => void,
    editable?: boolean,
    placeholder?: string
}
export default function RichTextEditor(props: Props) {
    const {content, onUpdate, editable, placeholder} = props;

    function onError(error: Error) {
        console.error(error);
    }

    function onChange(editorState: EditorState) {
        onUpdate && onUpdate(editorState.toJSON());
    }

    const initialConfig = {
        namespace: 'torch',
        editable,
        editorState: content || undefined,
        theme,
        onError,
        // Any custom nodes go here
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode
        ]
    };

    return <div className={classNames('rich-text-editor', {'editing': editable})}>
        <LexicalComposer initialConfig={initialConfig}>
            <div className="editor-container">
                {editable && <ToolbarPlugin />}
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable />}
                        placeholder={<div className="editor-placeholder">{placeholder}</div>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <OnChangePlugin onChange={onChange} />
                    <HistoryPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    <TablePlugin />
                    <RichTextEditable editable={!!editable}/>
                </div>
            </div>
        </LexicalComposer>
    </div>;
}