import {Source, useMap, Layer} from 'react-map-gl';
import {isFillPaint, LayerProps} from './types';
import {useState, useEffect} from 'react';
import {useDebounce} from './layer-utils';

export default function Points(props: LayerProps) {
    const {data, id, paint} = props;
    const color = isFillPaint(paint) ? paint?.['fill-color'] : undefined;

    const {current: map} = useMap();
    const [zoom, setZoom] = useState(map ? map.getZoom() : 0);

    const debouncedZoom = useDebounce(zoom, 250);

    useEffect(() => {
        if (map) {
            const handleZoom = () => {
                setZoom(map.getZoom());
            };

            map.on('zoom', handleZoom);

            return () => {
                map.off('zoom', handleZoom);
            };
        }
    }, [map]);

    // Replace zoom with debouncedZoom
    const size = debouncedZoom * .025;

    return (
        <Source id={String(id)} key={id} type='geojson' data={data}>
            <Layer
                id={String(id)}
                type='symbol'
                layout={{'icon-image': 'map-pin', 'icon-size': size, 'icon-allow-overlap': true, 'icon-ignore-placement': true}}
                paint={{'icon-color': color}}
            />
        </Source>
    );
}
