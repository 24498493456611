import  {useContext, useMemo} from 'react';
import {CanvasContext} from '../lib/context';
import InsightContainer from '../../../insight-container';
import {observer} from 'mobx-react';
import {applyParameters} from '../lib/parameters';
import {deserialize} from 'quantum-graph';
import {ParameterConfig} from '../lib/types';
import {DataConfig} from '../../../insight-container/types';

type Props = {
    queryObj?: any,
    parameterConfigs: Array<ParameterConfig>,
    visType: string,
    options: any,
}

function CvInlineContent(props: Props) {
    const {queryObj, visType, parameterConfigs, options} = props;
    const store = useContext(CanvasContext);

    // Apply overrides to data
    const dataConfig = useMemo(() => {
        if (!queryObj)
            return {queries: []};

        let config: DataConfig = {
            queries: Array.isArray(queryObj) ? queryObj.map((q) => deserialize(q)) : [deserialize(queryObj)],
            options: {linkNewTab: true, isCanvas: true, hideExplore: !options.exploreLink, ...options},
        };

        return applyParameters(config, parameterConfigs, store.parameterRow);
    }, [queryObj, parameterConfigs, options, store.parameterRow]);


    if (!queryObj)
        return null;
    if (visType === 'map')
        return <div style={{height: '800px'}}>
            <InsightContainer type={visType} dataConfig={dataConfig} loadCB={p => store.addPromise(p)} />
        </div>;

    return <InsightContainer type={visType} dataConfig={dataConfig} loadCB={p => store.addPromise(p)} />;
}

export default observer(CvInlineContent);