import {QuantumGraph} from 'quantum-graph';
import GraphHandler from './index';
import {V1QuantumGraph} from 'quantum-graph/out/v1';
import V1GraphHandler from './v1-graph-handler';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import V2GraphHandler from './v2-graph-handler';
import {DataDefRepo} from '../../user-data-defs';

export default function createGraphHandler(graph: QuantumGraph, dataDefRepo: DataDefRepo): GraphHandler<QuantumGraph> {
    if (graph instanceof V1QuantumGraph)
        return new V1GraphHandler(graph, dataDefRepo);
    else if (graph instanceof V2QuantumGraph)
        return new V2GraphHandler(graph);
    throw new Error('Unsupported graph version: ' + graph.getVersion());
}