import Popup from '../popup';
import React, {ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import handleError from '../../lib/error';
import {observer} from 'mobx-react';
import {MenuContext} from '../popup/context';
import generateKeyHandler from '../popup/key-events';
import Spinner from '../spinner';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList, FixedSizeList as List} from 'react-window';
import classNames from '../../lib/class-names';
import {HeaderContext} from '../header/context';
import {ComboBox} from '@carbon/react';
import './autocomplete.scss';


type Props = {
    onChange: (value: string, index: number) => void,
    value?: string,
    allowFreeform?: boolean,
    getOptions: (value: string) => Promise<Array<string>>,
    placeholder?: string,
    getOptionsOnLoad?: boolean
    disabled?: boolean
    width?: number
    icon?: string
    customKeyDelay?: number,
    className?: string
};

const LIST_ITEM_HEIGHT = 45;

const Autocomplete = observer((props: Props) => {
    const {onChange, allowFreeform, getOptions, placeholder, getOptionsOnLoad, disabled, width, icon, customKeyDelay, className} = props;
    const keyDelayTimeout = process.env.REACT_APP_API_BASE === 'test-api' ? 1 : customKeyDelay || 550;
    const menuStore = useContext(MenuContext);
    const [value, setValue] = useState(props.value || '');
    const [options, setOptions] = useState<Array<string>>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState('');
    const [changeTimeout, setChangeTimeout] = useState<any>(null);
    const [focusIndex, setFocus] = useState(-1);
    const [loading, setLoading] = useState(false);
    const {useNewDesign} = useContext(HeaderContext);

    useEffect(() => setValue(props.value || ''), [props.value]);

    const fetchOptions = useCallback((value: string) => {
        getOptions(value)
            .then(opts => {
                setOptions(opts);
                if (inputRef.current)
                    inputRef.current.focus();
            })
            .catch(err => handleError(err, setError))
            .finally(() => setLoading(false));
    }, [getOptions]);

    useEffect(() => {
        if (getOptionsOnLoad && !disabled && !value) {
            setLoading(true);
            setOptions([]);
            fetchOptions('');
        }
    }, [getOptionsOnLoad, disabled, fetchOptions, value]);

    function updateValue(e: ChangeEvent<HTMLInputElement> | string) {
        const newValue = typeof e === 'string' ? e : e.target.value;
        if (allowFreeform)
            onChange(newValue, -1);
        setValue(newValue);

        setLoading(true);
        if (changeTimeout)
            clearTimeout(changeTimeout);
        setChangeTimeout(setTimeout(() => fetchOptions(newValue), keyDelayTimeout));
    }

    function handleClick(v: string, i: number) {
        setValue(v);
        onChange(v, i);
        menuStore.hide();
    }

    function handleBlur(e: any) {
        if (getOptionsOnLoad && e.relatedTarget?.className !== 'menu-item') {
            if (value && !allowFreeform && !options.some(o => o === value)) {
                setValue(props.value || '');
                if (getOptionsOnLoad)
                    fetchOptions('');
            }
        }
    }

    const handleKey = generateKeyHandler(menuStore, focusIndex, options.length, setFocus,
        i => handleClick(options[i], i), () => {}, null, () => {});

    if (useNewDesign) {
        return <div className="inline-block relative">
            <ComboBox id='autocomplete' 
                items={options.slice(0, 3)} 
                onChange={e => e.selectedItem && handleClick(e.selectedItem, options.indexOf(e.selectedItem))} 
                onInputChange={updateValue} 
                selectedItem={value} 
                placeholder={placeholder} 
                disabled={disabled} 
            />
            {loading && <div className="absolute top-0 right-[-36px] h-full pr-3 flex items-center">
                <Spinner />
            </div>}
        </div>;
    }

    return <div className="inline-block relative" onBlur={handleBlur}>
        <input style={{width: `${width}px`}} className={classNames(icon && '!pr-11', className)} type={allowFreeform ? 'search' : 'text'} ref={inputRef} value={value} onChange={updateValue}
            onClick={e => e.stopPropagation()} onKeyDown={handleKey} placeholder={placeholder} disabled={disabled}/>
        {icon && !loading && <div className="absolute top-0 right-5 h-full pre-3 flex items-center">
            <i className={icon} />
        </div>}
        {loading && <div className="absolute top-0 right-3 h-full pr-3 flex items-center">
            <Spinner />
        </div>}
        {error && <p className="text-sm text-red-500">{error}</p>}
        <Popup targetRef={inputRef} showEvent="focus" matchWidth noHideOnClick>
            {value && options.length > 0 && <MenuItems options={options} onChange={handleClick} focusIndex={focusIndex} />}
            {value && !options.length && !loading && <div className='menu-item'>
                Nothing to Show
            </div>}
        </Popup>
    </div>;
});

type MenuProps = {
    options: Array<string>,
    onChange: (value: string, index: number) => void,
    focusIndex: number
}
function MenuItems(props: MenuProps) {
    const {options, onChange, focusIndex} = props;
    const listRef = React.createRef<FixedSizeList>();
    const listHeight = useMemo(() => Math.min(options.length * LIST_ITEM_HEIGHT, 240), [options]);
    
    return <AutoSizer disableHeight>
        {({width}: any) => (<List ref={listRef}
            itemData={options}
            innerElementType="ul"
            itemCount={options.length}
            height={listHeight}
            itemSize={LIST_ITEM_HEIGHT}
            width={width!}>
            {({data, index, style}) => {
                const value = data[index];
                return <li style={style}>
                    <button onClick={() => onChange(value, index)} className={classNames('menu-item', {'is-focus':  index === focusIndex})}>
                        {value}
                    </button>
                </li>;
            }}
        </List>)}
    </AutoSizer>;
}

export default Autocomplete;