import {StandardRenderChildProps} from '../../renderers/standard-data-renderer';
import StandardDataRenderer from '../../renderers/standard-data-renderer';
import {DataConfig} from '../../types';
import {ResponsiveSankey} from '@nivo/sankey';
import {dataRows} from '../../../lib/data-types';
import {useContext, useMemo} from 'react';
import {observer} from 'mobx-react';
import ChartContext, {ChartStore} from '../context';
import ChartContainer from '../container';
import {PlotColors, PlotTheme} from '../common';

type SankeyNode = {
    id: string
}

type SankeyLink = {
    source: string
    target: string
    value: number
}

type SankeyData = {
    nodes: SankeyNode[]
    links: SankeyLink[]
}


export const createSankeyData = (
    data: dataRows,
    from: string,
    to: string,
    countby: string,
): SankeyData => {
    const sankeyData: SankeyData = {nodes: [], links: []};
    const fromAggregator: Record<string, number> = {};
    const toSet: Set<string> = new Set();

    data.forEach((row) => {
        const fromValue = row[from] !== null ? row[from]!.toString() : 'no data';
        const toValue = row[to] !== null ? row[to]!.toString() : 'no data';
        const countValue = row[countby] !== null ? Number(row[countby]) : 0;

        if (fromValue !== toValue) {
            fromAggregator[fromValue] = (fromAggregator[fromValue] || 0) + countValue;
            toSet.add(toValue);
        }
    });


    Object.keys(fromAggregator).forEach((key) => sankeyData.nodes.push({id: key}));
    toSet.forEach((key) => sankeyData.nodes.push({id: key.toString()}));

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [source, value] of Object.entries(fromAggregator)) {
        toSet.forEach((target) => {
            const linkValue = data.reduce((acc, row) => {
                const fromValue = row[from] !== null ? row[from]!.toString() : 'no data';
                const toValue = row[to] !== null ? row[to]!.toString() : 'no data';
                const countValue = row[countby] !== null ? Number(row[countby]) : 0;

                return fromValue === source && toValue === target ? acc + countValue : acc;
            }, 0);

            if (linkValue > 0) {
                sankeyData.links.push({source, target: target.toString(), value: linkValue});
            }
        });
    }

    return sankeyData;
};


export const SankeyVis = observer(() => {
    const chartContext = useContext(ChartContext);
    let {data, dataConfig} = chartContext;



    const {from, to, count, labels, showLegend} = dataConfig.options.sankeyOptions;
    const formatter = chartContext.getSankeyFormatter(count);

    const sankeyData = useMemo(() => {
        if (from && to && count && data) {
            return createSankeyData(data, from, to, count);
        }
    }, [data, from, to, count]);

    return (
        <div style={{height: '600px', width: '100%'}}>
            {sankeyData && (
                <ChartContainer maxRows={10000}>
                    <ResponsiveSankey
                        data={sankeyData}
                        isInteractive={labels}
                        valueFormat={formatter?.formatSimple.bind(formatter)}
                        colors={PlotColors}
                        theme={PlotTheme as any}
                        enableLinkGradient
                        margin={showLegend ? {bottom: 40} : {}}
                        legends={showLegend ? [
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                translateX: 0,
                                translateY: 35,
                                itemWidth: 120,
                                itemHeight: 14,
                                itemDirection: 'right-to-left',
                                itemsSpacing: 2,
                                itemTextColor: '#999',
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                symbolSize: 12,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ] : []}
                    />
                </ChartContainer>
            )}
        </div>
    );
});


function SankeyContext(props: StandardRenderChildProps) {
    const chartContext = new ChartStore(props, 10000);
    return (
        <ChartContext.Provider value={chartContext}>
            <SankeyVis />
        </ChartContext.Provider>
    );
}

export default function SankeyRenderer(props: {
    dataConfig: DataConfig
    loadCB?: (promise: Promise<void>) => void
}) {
    return (
        <StandardDataRenderer dataConfig={props.dataConfig} loadCB={props.loadCB}>
            {SankeyContext}
        </StandardDataRenderer>
    );
}
