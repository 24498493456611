import {useContext, useRef} from 'react';
import logoBlue from '../images/logo-blue.svg';
import {Link, useNavigate} from 'react-router-dom';
import classNames from '../../lib/class-names';
import HeaderButton from './header-button';
import ProfileMenu from './profile-menu';
import {HeaderContext} from './context';
import './header.scss';
import {Help, Notebook, UserAvatar} from '@carbon/icons-react';
import HeaderSearch from './header-search';
import auth from '../../lib/auth';
import SiteName from '../SiteName';

export type NavbarProps = {
    className?: string
}

export default function Header(props: NavbarProps) {
    const navMenuRef = useRef<HTMLButtonElement>(null);
    const classes = classNames('header', props.className);
    const headerStore = useContext(HeaderContext);
    const navigate = useNavigate();

    return (
        <nav className={classes}>
            <div className='pl-4 h-12 w-full'>
                <div className='flex items-center h-12 w-full'>
                    <div className='flex-1 flex items-center'>
                        <div className='flex-shrink-0 flex items-center'>
                            <Link to='/' className='hover:no-underline text-gray-300'>
                                <img
                                    className='inline h-8 w-auto mr-6'
                                    src={logoBlue}
                                    alt='Torch Insight'
                                />
                                <span className='hover:no-underline'>
                                    Milliman&nbsp;<span className='font-bold text-gray-100'><SiteName /></span>
                                </span>
                            </Link>
                        </div>
                        <div className='block flex-grow' />
                    </div>
                    {auth.principal && <div className='absolute right-0 flex items-center h-full'>
                        <HeaderSearch />
                        <div className='h-full relative flex'>
                            <HeaderButton id="my-content" icon={<Notebook/>} onClick={() => {headerStore.showMyContent = !headerStore.showMyContent; headerStore.setShowMyContentPreference(headerStore.showMyContent);}} highlight={headerStore.showMyContent} tooltipName="My Content" />
                            <HeaderButton id="help-section" icon={<Help/>} onClick={() => navigate('/docs/api/')} tooltipName="Resources"/>
                            <HeaderButton id="user-menu" icon={<UserAvatar/>} popupRef={navMenuRef} tooltipName="My Account" />
                        </div>
                    </div>}
                </div>
            </div>
            <ProfileMenu popupRef={navMenuRef} />
        </nav>
    );
}
