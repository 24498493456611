import auth from './auth';
import {Module, QueryObj} from './data-types';
import {deserialize, GraphVersions, TestType} from 'quantum-graph';
import fetchData from './data';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import {getModule, PermissionModule} from './check-module-access';

const geographyColumn: {[key: string]: string} = {
    cbsa: 'cbsa',
    state: 'state_fips'
};

async function getCBSARestrictions(permissionModules?: Array<PermissionModule>): Promise<Array<number>> {
    return await getGeoRestrictions('cbsa', 'cbsa_dim_key', 'cbsa', permissionModules);
}

async function getStateRestrictions(permissionModules?: Array<PermissionModule>): Promise<Array<number>> {
    return await getGeoRestrictions('state_fips', 'state_dim_key', 'state', permissionModules);
}

async function getGeoRestrictions(key1: string, key2: string, geography: string, permissionModules?: Array<PermissionModule>) {
    let modules: Array<Module> = [];
    permissionModules?.forEach(pm => modules.push(getModule(pm)!));
    modules = modules.filter(_ => _);
    if (!modules.length)
        modules = auth.principal?.modules || [];

    let geoRestrictedValues: Array<number> = [];
    for (let module of modules) {
        let restrictions = module.geoRestrictions.filter(geoRestriction => geoRestriction.variable === key1).flatMap(_ => _.keys);
        if (!restrictions.length) {
            restrictions = module.geoRestrictions.filter(geoRestriction => geoRestriction.variable === key2).flatMap(_ => _.keys);
            if (restrictions.length)
                restrictions = await convertGeoRestrictionDimKeyToId(restrictions, geography);
        }
        if (restrictions.length)
            geoRestrictedValues = [...geoRestrictedValues, ...restrictions];
    }

    return geoRestrictedValues;
}

async function convertGeoRestrictionDimKeyToId(restrictions: Array<number>, geography: string) {
    let query: QueryObj = generateGeoQuantumQuery(geography);
    query.filters = [
        {
            type: 'basic',
            column: {
                nodeId: geography,
                column: `${geography}_dim_key`
            },
            test: TestType.In,
            value: restrictions
        }
    ];
    const geoFields = geographyColumn[geography];
    return (await fetchData(deserialize(query), new AbortController().signal)).map(data => data[geoFields] as number);
}

function generateGeoQuantumQuery(geography: string): QueryObj {
    return {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: QueryType.Dim,
                tableNodes: [
                    {
                        id: geography,
                        dataset: geography,
                        columns: [geographyColumn[geography]]
                    }
                ]
            }
        }
    };
}

export {
    getCBSARestrictions,
    getStateRestrictions
};