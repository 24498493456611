import {Checkmark, Close} from '@carbon/icons-react';
import React from 'react';

type Props = {
    passed: boolean;
    showText?: boolean;
}

export default function ComplianceIndicator(props: Props) {
    const {passed, showText} = props;

    return <div className="flex space-x-3">
        <div className={`w-5 h-5 text-white rounded-full flex justify-center items-center ${passed ? 'bg-green-600' : 'bg-red-600'}`}>
            {passed ? <Checkmark /> : <Close />}
        </div>
        <span className="text-grey-500">{showText && (passed ? 'Pass' : 'Fail')}</span>
    </div>;
}