import {observer} from 'mobx-react';
import {useContext} from 'react';
import {CanvasInsertContext} from '../../../lib/context';
import {RowCountOperators} from '../../../lib/row-count-compare';
import Checkbox from '../../../../../components/checkbox';

const MAX_ROW_COUNT = 20;
const ConfigConditionalRender = observer(() => {
    const insertStore = useContext(CanvasInsertContext);

    function handleRowCountChange(e:any) {
        let value = Number(e.target.value);
        insertStore.conditionalRenderOptions.rowCount = value > MAX_ROW_COUNT ? MAX_ROW_COUNT : value;
    }

    return <div className="w-full mt-8 p-8">
        <p>This cell / row will only render based on the row count condition you set below. A maximum of {MAX_ROW_COUNT} rows from the data model can be used.</p>
        <div className="flex space-x-16 items-end mt-8">
            <strong className="text-xl">Row Count</strong>
            <div className="flex flex-col space-y-1">
                <label>Condition</label>
                <select value={insertStore.conditionalRenderOptions.operator} onChange={(e) => insertStore.conditionalRenderOptions.operator = e.target.value as RowCountOperators}>
                    <option value={RowCountOperators.equal}>Equal</option>
                    <option value={RowCountOperators.gtequal}>&gt;=</option>
                    <option value={RowCountOperators.gt}>&gt;</option>
                    <option value={RowCountOperators.ltequal}>&lt;=</option>
                    <option value={RowCountOperators.lt}>&lt;</option>
                </select>
            </div>
            <div className="flex flex-col space-y-1">
                <label>Number of rows</label>
                <input type="number" value={insertStore.conditionalRenderOptions.rowCount} onChange={handleRowCountChange} max={MAX_ROW_COUNT}/>
            </div>
        </div>
        <div className="mt-8 flex space-x-3">
            <label>Show loading icon</label>
            <Checkbox color="blue" checked={insertStore.conditionalRenderOptions.showLoading} onChange={() => insertStore.conditionalRenderOptions.showLoading = !insertStore.conditionalRenderOptions.showLoading} />
        </div>
        <small>Configure if you want a loading graphic to show when pulling the conditional data model from the DB.</small>
    </div>;
});

export default ConfigConditionalRender;