import Button from '../../../components/button';
import React from 'react';
import SiteName from '../../../components/SiteName';

export default function CrmApiOverview() {
    return <div>
        <h2>Overview</h2>
        <p>
            We appreciate you taking the time to learn the <SiteName /> CRM API. The following points should help you get
            started.
        </p>
        <h3>Domain</h3>
        <p>
            All requests are to made to <code>https://open.torchinsight.com</code>. The usage of <strong>https</strong> is
            required; <strong>http</strong> is not supported.
        </p>
        <h3>JSON</h3>
        <p>
            For many of the endpoints, you send JSON in the request payload. In these cases the body must be valid JSON
            and the content type must be set to <code>application/json</code>.
        </p>
        <hr/>
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/crm-api/data">Data Endpoints</Button>
        </p>
    </div>;
}