import DownloadButton from '../download-button';
import Button from '../button';
import {useCallback, useContext, useMemo, useState} from 'react';
import {QuantumGraph} from 'quantum-graph';
import {getConfigOption} from '../../insight-container/types';
import DataLibraryModal from '../data-library-modal';
import {hideKeys} from '../../pages/tools/data-explorer/data-explorer';
import SaveModal from '../save-modal';
import Variable from '../../lib/metadata';
import updateGraphSelection from '../../lib/graph-tools/selection';
import {observer} from 'mobx-react';
import {CanvasContext} from '../../pages/canvas/lib/context';
import {Section} from '../../lib/library';
import {HeaderContext} from '../header/context';
import {DataTable, TableToolbar, TableToolbarContent, TableContainer} from '@carbon/react';
import './table-header.scss';
import {Edit, Reset, Save} from '@carbon/icons-react';
import IQueryData from '../../insight-container/renderers/query-data-renderer/query-data-interface';

type Props = {
    store: IQueryData,
    children?: JSX.Element,
    onlyShowDownload?: boolean,
    hideDownload?: boolean,
    hideSave?: boolean,
    saveDataCB?: (query:QuantumGraph) => void,
    title?:string,
    onClearAll?: () => void
}

const TableHeader = observer((props: Props) => {
    const {store, children, onlyShowDownload, hideSave, hideDownload, saveDataCB, onClearAll} = props;
    const [libVisible, setLibVisible] = useState<boolean>(false);
    const [showSaveQueryModal, setShowSaveQueryModal] = useState<boolean>(false);
    const {useNewDesign} = useContext(HeaderContext);
    const canvasStore = useContext(CanvasContext);
    const relatedApis: Array<{name: string, graph: QuantumGraph}> | undefined = getConfigOption(store.dataConfig, 'relatedAPIS');
    const reference: string = getConfigOption(store.dataConfig, 'reference') || '';

    const updateStrategy = useCallback((graph: QuantumGraph, selection: Array<Variable>, change: Array<Variable>, currentSection?: Section) => {
        return updateGraphSelection(graph, selection, change, store.library, store.dataDefRepo, currentSection);
    }, [store.library, store.dataDefRepo]);

    function updateGraph(newGraph: QuantumGraph, metadata: Array<Variable>, options: any) {
        store.updateConfig({queries: [newGraph], options});
        store.metadata = metadata;
        setLibVisible(false);
    }

    const TableHeaderView = useMemo(() => useNewDesign ? NewTableHeaderView : DefaultTableHeaderView, [store.loading, useNewDesign]);
    const tableHeaderProps: TableHeaderViewProps = {
        store,
        children,
        setLibVisible,
        hideDownload,
        relatedApis,
        hideSave,
        saveDataCB,
        setShowSaveQueryModal,
        onlyShowDownload: onlyShowDownload || false,
        onClearAll
    };
    return <div>
        <TableHeaderView {...tableHeaderProps} />
        {libVisible && (
            <DataLibraryModal
                graph={store.getGraph()}
                selection={store.metadata}
                library={store.library}
                updateStrategy={updateStrategy}
                onClose={updateGraph}
                disabledVariables={store.getLocked()}
                variableFilter={!canvasStore.editing ? hideKeys : (v) => !!v.getTag('Recommended') || v.name.toLowerCase().includes('year_key')}
                subCatWhiteList={store.dataConfig.options?.subCategoryWhiteList}
                subCatBlackList={store.dataConfig.options?.subCategoryBlackList}
                title={props.title}
                dataConfig={store.dataConfig} />
        )}
        {showSaveQueryModal && (
            <SaveModal
                onClose={(id) => {
                    setShowSaveQueryModal(false);
                    store.pristine = !!id;
                } }
                graph={store.getGH(true, true, true).build()}
                reference={reference} />
        )}
    </div>;
});

export default TableHeader;

type TableHeaderViewProps = {
    store: IQueryData;
    children: JSX.Element | undefined;
    setLibVisible: React.Dispatch<React.SetStateAction<boolean>>;
    hideDownload: boolean | undefined;
    relatedApis: {
        name: string;
        graph: QuantumGraph;
    }[] | undefined;
    hideSave: boolean | undefined;
    saveDataCB: ((query: QuantumGraph) => void) | undefined;
    setShowSaveQueryModal: React.Dispatch<React.SetStateAction<boolean>>;
    onlyShowDownload: boolean;
    onClearAll?: () => void;
};

function DefaultTableHeaderView(props: TableHeaderViewProps) {
    let {store, children, setLibVisible, hideDownload, relatedApis, hideSave, saveDataCB, setShowSaveQueryModal, onlyShowDownload} = props;
    
    if (onlyShowDownload)
        return <div className="w-full flex space-x-5 justify-end items-center bg-white">
            <div className="pr-5">
                <DownloadButton metadata={store.metadata} onDownload={() => store.download()} disabled={store.count === 0} onGenerate={() => store.getGH(true, true).build()} relatedAPIS={relatedApis}/>
            </div>
        </div>;

    return <div className='w-full flex space-x-5 justify-end items-center bg-white'>
        {children}
        <i
            className={`icon-edit hover:cursor-pointer ${!store.metadata.length && 'text-gray-200'}`}
            onClick={() => {
                if (store.metadata.length) {
                    setLibVisible(true);
                }

            } } />
        {!hideDownload && <DownloadButton
            metadata={store.metadata}
            onDownload={() => store.download()}
            disabled={store.count === 0}
            onGenerate={() => store.getGH(true, true).build()}
            relatedAPIS={relatedApis} />}
        {!hideSave && <Button
            color='blue'
            onClick={() => saveDataCB
                ? saveDataCB(store.getGH(true, true, true).build())
                : setShowSaveQueryModal(true)}
        >
                Save <i className='icon-save ml-5 text-xs' />
        </Button>}
    </div>;
}

function NewTableHeaderView(props: TableHeaderViewProps) {
    let {store, children, setLibVisible, hideDownload, relatedApis, hideSave, saveDataCB, setShowSaveQueryModal, onlyShowDownload, onClearAll} = props;
    
    if (onlyShowDownload)
        return <div className="w-full flex space-x-5 justify-end items-center bg-white">
            <div className="pr-5">
                <DownloadButton metadata={store.metadata} onDownload={() => store.download()} disabled={store.count === 0} onGenerate={() => store.getGH(true, true).build()} relatedAPIS={relatedApis}/>
            </div>
        </div>;

    return <DataTable rows={store.data.map((r, i) => ({id: i.toString(), ...r}))} headers={store.metadata.map(h => ({key: h.name, header: h.label}))}>
        {({
            getToolbarProps,
            getTableContainerProps
        }) =>
            <TableContainer {...getTableContainerProps()} style={{paddingBlockStart: 0}} >
                <TableToolbar {...getToolbarProps()} size='sm'>
                    <TableToolbarContent>
                        {children}
                        {onClearAll && <div className='cds--overflow-menu-options__option justify-self-end !px-2 hover:cursor-pointer' style={{inlineSize: 'initial', blockSize: 'initial'}}>
                            <Reset size={16} className={` hover:cursor-pointer ${!store.metadata.length && 'text-gray-200'}`}
                                onClick={onClearAll} />
                        </div>}
                        <div className='cds--overflow-menu-options__option justify-self-end !px-2 hover:cursor-pointer' style={{inlineSize: 'initial', blockSize: 'initial'}}>
                            <Edit size={16} className={`${!store.metadata.length && 'text-gray-200'}`}
                                onClick={() => {
                                    if (store.metadata.length) {
                                        setLibVisible(true);
                                    }}} />
                        </div>
                        {!hideDownload && <div className='cds--overflow-menu-options__option justify-self-end !px-2 hover:cursor-pointer' style={{inlineSize: 'initial', blockSize: 'initial'}} >
                            <DownloadButton
                                metadata={store.metadata}
                                onDownload={() => store.download()}
                                disabled={store.count === 0}
                                onGenerate={() => store.getGH(true, true).build()}
                                relatedAPIS={relatedApis} />
                        </div>}
                        {!hideSave && <div className='cds--overflow-menu-options__option justify-self-end !px-2 hover:cursor-pointer' style={{inlineSize: 'initial', blockSize: 'initial'}}>
                            <Save size={16} className={`${!store.metadata.length && 'text-gray-200'}`}
                                onClick={() => saveDataCB
                                    ? saveDataCB(store.getGH(true, true, true).build())
                                    : setShowSaveQueryModal(true)} />
                        </div>}
                    </TableToolbarContent>
                </TableToolbar>
            </TableContainer>}
    </DataTable>;
}
