
const InsightContainerTypeLabels: Record<string, string> = {
    'eoc-2': 'Episodes of Care',
    'table': 'Data Table',
    'query': 'Data Query',
    'cr-benchmarks': 'Commercial Reimbursement Benchmarks',
    'common-core': 'Common Core Benchmarks',
    'bar': 'Bar Chart',
    'plot': 'Line Plot',
    'pie': 'Pie Chart',
    'profile': 'Profile',
    'map': 'Map',
    'entity-affiliations': 'Entity Affiliations',
    'test': 'Test',
};

export default InsightContainerTypeLabels;