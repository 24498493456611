import React, {SyntheticEvent, useContext, useState} from 'react';
import Button from '../../../components/button';
import {users} from '../../../lib/api';
import auth from '../../../lib/auth';
import Loading from '../../../components/loading';
import handleError from '../../../lib/error';
import {HeaderContext} from '../../../components/header/context';
import Input from '../../../components/input';

const MIN_PASSWORD_LENGTH = 8;

export default function AccountSettingsSection() {
    const [name, setName] = useState<string>(auth.principal!.name);
    const [email, setEmail] = useState<string>(auth.principal!.email);
    const [password1, setPassword1] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');
    const [infoErrorMessage, setInfoErrorMessage] = useState<string>('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const headerStore = useContext(HeaderContext);

    function saveInfo(event: SyntheticEvent) {
        event.preventDefault();
        setInfoErrorMessage('');
        setLoading(true);
        users.modify({
            id: auth.principal!.sub,
            name,
            email
        }).then(auth.refresh)
            .catch(error => handleError(error, setInfoErrorMessage))
            .finally(() => setLoading(false));
    }

    function savePassword(event: SyntheticEvent) {
        event.preventDefault();
        setPasswordErrorMessage('');
        setLoading(true);
        users.modify({
            id: auth.principal!.sub,
            password: password1
        }).catch(error => handleError(error, setPasswordErrorMessage))
            .finally(() => setLoading(false));
    }

    function infoDisabled() {
        return !name || !email || (name === auth.principal!.name && email === auth.principal!.email);
    }

    function passwordDisabled() {
        return password1.length < MIN_PASSWORD_LENGTH || password1 !== password2;
    }

    function legacyAccountSettingsSection() {
        return <div className="container" style={{minHeight: '65vh'}}>
            {loading && <Loading />}
            <div className="w-4/5">
                <div className="flex flex-col">
                    <h1>Your Account</h1>
                    <h2>User Info</h2>
                    {infoErrorMessage && <div className="text-red-500">{infoErrorMessage}</div>}
                    <form className="flex flex-col space-y-3" onSubmit={saveInfo}>
                        <div className="flex flex-col">
                            <label className="font-bold">Name</label>
                            <input type="text" value={name} onChange={e => setName(e.target.value)}/>
                        </div>
                        <div className="flex flex-col">
                            <label className="font-bold">Email</label>
                            <input type="text" value={email} onChange={e => setEmail(e.target.value)}/>
                        </div>
                        <div>
                            <Button color={'orange'} disabled={infoDisabled()}><i className="icon-save"/> Save</Button>
                        </div>
                    </form>
                </div>
                <div className="bg-gray-300 h-px my-10"> </div>
                <div className="flex flex-col">
                    <h2>Password</h2>
                    {passwordErrorMessage && <div className="text-red-500">{passwordErrorMessage}</div>}
                    <p>Use the following form to change your password:</p>
                    <form className="flex flex-col space-y-3" onSubmit={savePassword}>
                        <div className="flex flex-col">
                            <label className="font-bold">New Password</label>
                            <input type="password" placeholder="New Password" value={password1} onChange={e => setPassword1(e.target.value)}/>
                        </div>
                        <div className="flex flex-col">
                            <label className="font-bold">Repeat Password</label>
                            <input type="password" placeholder="Repeat Password" value={password2} onChange={e => setPassword2(e.target.value)}/>
                            <div className="text-sm mt-3">Passwords must match and have at least {MIN_PASSWORD_LENGTH} characters</div>
                        </div>
                        <div>
                            <Button color={'orange'} disabled={passwordDisabled()}><i className="icon-edit" /> Change</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>;
    }

    function dppAccountSettingsSection() {
        return <div className='p-8'>
            {loading && <Loading />}
            <div className="flex items-stretch justify-items-stretch gap-24">
                <div className="flex flex-col gap-8 grow">
                    <div className='text-xl'>Edit Profile</div>
                    {infoErrorMessage && <div className="text-red-500">{infoErrorMessage}</div>}
                    <form className="flex flex-col gap-6" onSubmit={saveInfo}>
                        <div className="flex flex-col">
                            <label className="text-sm font-semibold text-background-gray-600">Name</label>
                            <Input value={name} onChange={e => setName(e.target.value)}/>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm font-semibold text-background-gray-600">Email</label>
                            <Input value={email} onChange={e => setEmail(e.target.value)}/>
                        </div>
                        <div className='mt-4'>
                            <Button color='bg-milliman-blue-500' className='!rounded-none !py-2 text-sm text-white disabled:bg-background-gray-600 disabled:text-black' disabled={infoDisabled()}>Save</Button>
                        </div>
                    </form>
                </div>
                <div className="bg-background-gray-300 w-px "> </div>
                <div className="flex flex-col gap-8 grow">
                    <div className='text-xl'>Change Password</div>
                    {passwordErrorMessage && <div className="text-red-500">{passwordErrorMessage}</div>}
                    <form className="flex flex-col gap-6" onSubmit={savePassword}>
                        <div className="flex flex-col">
                            <label className="text-sm font-semibold text-background-gray-600">New Password</label>
                            <Input value={password1} onChange={e => setPassword1(e.target.value)} type='password'/>
                            <label className="text-xs mt-1 text-background-gray-500">Passwords must have at least {MIN_PASSWORD_LENGTH} characters</label>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm font-semibold text-background-gray-600">Repeat Password</label>
                            <Input value={password2} onChange={e => setPassword2(e.target.value)} type='password'/>
                            <div className="text-xs mt-1 text-background-gray-500">Passwords must match</div>
                        </div>
                        <div className='mt-4'>
                            <Button color='bg-milliman-blue-500' className='!rounded-none !py-2 text-sm text-white disabled:bg-background-gray-600 disabled:text-black' disabled={passwordDisabled()}>Change Password</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>;
    }

    return headerStore.useNewDesign ? dppAccountSettingsSection() : legacyAccountSettingsSection();
}