import {observer} from 'mobx-react';
import Button from '../../components/button';
import {useContext} from 'react';
import FilterStepsContext from './libs/context';
import {useNavigate} from 'react-router-dom';

type Props = {
    onSubmit: () => void
}

const ControlButtons = observer((props: Props) => {
    const {onSubmit} = props;
    const filterStepsStore = useContext(FilterStepsContext);
    const navigate = useNavigate();

    return <div className="w-full flex mt-5">
        <Button color="dark" onClick={() => navigate(-1)}>Cancel</Button>
        <Button color="blue" disabled={!filterStepsStore.canConfigureTable()} onClick={onSubmit}>Configure Table</Button>
    </div>;
});

export default ControlButtons;