import Variable from '../../lib/metadata';
import React, {useContext, useRef, useState} from 'react';
import Popup from '../popup';
import {downloadCodebook} from '../../lib/download';
import {QuantumGraph} from 'quantum-graph';
import Modal from '../modal';
import checkAccess, {SiteFeature} from '../../lib/check-access';
import {Link} from 'react-router-dom';
import Alert from '../alert';
import {Download} from '@carbon/icons-react';
import {HeaderContext} from '../header/context';

type Props = {
    metadata: Array<Variable>,
    onDownload: () => void,
    onGenerate?: () => QuantumGraph,
    relatedAPIS?: Array<{name: string, graph: QuantumGraph}>
    disabled?: boolean,
};

export default function DownloadButton(props: Props) {
    const {metadata, onDownload, onGenerate, relatedAPIS, disabled} = props;
    const btnRef = useRef<HTMLDivElement>(null);
    const [apiGraph, setGraph] = useState<QuantumGraph | null>(null);
    const [showRelatedApis, setShowRelatedApis] = useState<boolean>(false);
    const {useNewDesign} = useContext(HeaderContext);

    if (!checkAccess(SiteFeature.Download))
        return null;

    let iconElement = useNewDesign ? <Download className={disabled ? 'text-gray-300' : 'hover:cursor-pointer'} /> : <i className={`icon-download ${disabled ? 'text-gray-300' : 'hover:cursor-pointer'}`} />;

    return <div className="inline-block">
        {!disabled && <div data-testid="download-icon" ref={btnRef}>
            {iconElement}
        </div>}
        {disabled && <div data-testid="download-icon">
            {iconElement}
        </div>}

        <Popup targetRef={btnRef}>
            <div className="menu-item cursor-pointer" onClick={onDownload}>Download .csv</div>
            <div className="menu-item cursor-pointer" onClick={() => downloadCodebook(metadata)}>Download Codebook</div>
            {onGenerate && checkAccess(SiteFeature.API) && <div className="menu-item cursor-pointer" onClick={() => setGraph(onGenerate ? onGenerate() : null)}>Generate API Query</div>}
            {relatedAPIS && checkAccess(SiteFeature.API) && <div className="menu-item cursor-pointer" onClick={() => setShowRelatedApis(true)}>Related API Queries</div>}
        </Popup>

        {apiGraph && <Modal title="API Query" onClose={() => setGraph(null)}>
            <Alert color="blue" icon="icon-info" border>
                For more information on using our API, please refer to our <Link to="/docs/api">API Documentation</Link>
            </Alert>
            <p>Use the following query when querying the API:</p>
            <pre>{JSON.stringify(apiGraph.serialize(), null, 4)}</pre>
        </Modal>}

        {relatedAPIS && showRelatedApis && <Modal title="Related API Queries" onClose={() => setShowRelatedApis(false)}>
            <Alert color="blue" icon="icon-info" border>
                For more information on using our API, please refer to our <Link to="/docs/api">API Documentation</Link>
            </Alert>
            <div>
                {relatedAPIS.map((api, i) => <div key={i} className="mt-5">
                    <p>Use the following query as a starting point when querying {api.name}:</p>
                    <pre>{JSON.stringify(api.graph, null, 4)}</pre>
                </div>)}
            </div>
        </Modal>}
    </div>;
}