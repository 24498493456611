
/**
 * Checks to see if the event occurred within the popup menu
 * @param el HTMLElement
 */
export function originatesFromMenu(el: HTMLElement | null) {
    while (el && el !== window.document.body) {
        if (el.getAttribute('id') === 'popup-menu')
            return true;
        el = el.parentElement;
    }
    return false;
}