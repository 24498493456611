import {useState, useEffect, useMemo} from 'react';
import {content} from './api';
import auth from './auth';
import {MyFavorite} from './data-types';
import handleError from './error';
import {useLocation} from 'react-router-dom';

export function useFavorite(pageName: string) {
    const [myFavorites, setMyFavorites] = useState<MyFavorite[]>([]);
    //eslint-disable-next-line
    const [error, setError] = useState<string>('');
    const location = useLocation();
    const url = useMemo(() => location.pathname + location.search, [location.pathname, location.search]);
    
    useEffect(() => {
        const controller = new AbortController();
        content.myFavorites.listAll(controller.signal).then(favoritesList => {
            setMyFavorites(favoritesList);
        }).catch(err => handleError(err, setError));

        return () => controller.abort();
    }, []);

    function setFavorite() {
        const favorite = myFavorites.find(favorite => favorite.url === url);
        if (favorite) {
            content.myFavorites.delete(favorite.id).then(() => {
                setMyFavorites(myFavorites.filter(favorite => favorite.url !== url));
            });
        } else {
            const newFavorite = {id: Math.max(...myFavorites.map(f => f.id), 1), userId: auth.principal!.sub, url, pageName, dateSaved: new Date()};
            content.myFavorites.create(newFavorite).then((id: number) => {
                setMyFavorites([...myFavorites, {...newFavorite, id: id || newFavorite.id} as MyFavorite]);
            });
        }
    }

    return [myFavorites, setFavorite] as const;
}