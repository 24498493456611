import Variable from '../../../lib/metadata';
import Library from '../../../lib/library';
import groupBy from '../../../lib/group-by';
import {DataDefRepo} from '../../../lib/user-data-defs';

function updateYears(vars: Array<Variable>, library: Library, dataDefRepo: DataDefRepo): Array<Variable> {
    const byDataset = groupBy(vars, v => v.dataset || '');
    const metadata = library.getSections().flatMap(s => s.index.getVariables());
    Object.keys(byDataset).forEach(dataset => {
        const hasYearGrain = dataDefRepo.datasetByName[dataset].tableGrain.includes('year_key');
        const yearVar = metadata.find(v => v.dataset === dataset && v.getTag('Time') === 'year');
        if (hasYearGrain && yearVar)
            byDataset[dataset].push(yearVar);
    });
    return Object.values(byDataset).reduce((a, b) => a.concat(b), []);
}

export default function updateAffiliationSelection(metadata: Array<Variable>, fromDataset: string, toDataset: string, library: Library, dataDefRepo: DataDefRepo): [Array<Variable>, Array<Variable>] {
    let newFrom: Array<Variable> = [], newTo: Array<Variable> = [];
    for (const variable of metadata) {
        if (variable.getTag('Time') === 'year') // Don't add years here. We'll add it in later if there's a fact
            continue;

        const dsGroup = variable.getDsGroup();
        if (dsGroup === fromDataset)
            newFrom.push(variable);
        else if (dsGroup === toDataset)
            newTo.push(variable);
    }

    newFrom = updateYears(newFrom, library, dataDefRepo);
    newTo = updateYears(newTo, library, dataDefRepo);

    return [newFrom, newTo];

}
