import {deserialize, QuantumGraph} from 'quantum-graph';
import {savedQuery} from '../../../lib/api';
import {SavedQuery} from '../../../lib/data-types';

export interface DataExplorerState {
    query?: object | string,
    back?: boolean,
    title?: string,
}

export type QueryInfo = {
    graph: QuantumGraph,
    title: string,
}

export function loadFromPushState(state: DataExplorerState | undefined): QueryInfo | null {
    if (!state)
        return null;
    if (!state.query)
        return null;
    const query = typeof state.query === 'string' ? JSON.parse(state.query) : state.query;
    return {
        graph: deserialize(query),
        title: state.title || '',
    };
}

export function loadFromSession(): QueryInfo | null {
    const query = sessionStorage.getItem('query');
    if (query) {
        return {
            graph: deserialize(JSON.parse(query)),
            title: '',
        };
    }
    return null;
}

export async function loadFromId(id: number, signal: AbortSignal): Promise<QueryInfo | null> {
    if (!id)
        return null;
    const sQuery: SavedQuery = await savedQuery.get(id, signal);
    return {
        graph: deserialize(JSON.parse(sQuery.queryJSON)),
        title: sQuery.name || '',
    };
}

export default async function loadQueryInfo(state: DataExplorerState, id: string, signal: AbortSignal): Promise<QueryInfo | null> {
    let query = loadFromPushState(state);
    if (!query)
        query = loadFromSession();
    if (!query)
        query = await loadFromId(Number(id), signal);
    return query;
}