import {ReactElement} from 'react';

type Props = {
    name: ReactElement,
    sticky?: boolean,
    links?: Array<{name: ReactElement, href: string}>
}
export default function PageTitle(props: Props) {
    const {name, sticky, links} = props;

    return <div data-testid="title-parent" className={`${sticky && 'sticky top-0'} z-30`}>
        <div className="w-full flex justify-center items-center text-white font-light bg-blue-gradient py-3">
            <h1 className="m-0">{name}</h1>
        </div>
        {links && <nav className="w-full bg-white shadow">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex flex-col md:flex-row items-center justify-around md:h-12">
                    {links.map((link, index) => <a key={index} href={link.href} className="ml-5 text-gray-500 cursor-pointer hover:no-underline">{link.name}</a>)}
                </div>
            </div>
        </nav>}
    </div>;
}