import {FormEvent, useContext, useEffect, useMemo, useState} from 'react';
import Template from '../../components/template';
import Button from '../../components/button';
import OneTwoLayout from '../../components/layout/one-two-layout';
import logoWhite from '../../components/images/logo-white.svg';
import indexImg from '../tools/hc-index/images/index.png';
import OneOneLayout from '../../components/layout/one-one-layout';
import iconTools from './assets/icon-tools.png';
import iconData from './assets/icon-data.png';
import './landing.scss';
import './landing-new.scss';
import checkAccess, {SiteFeature} from '../../lib/check-access';
import AuthWall from '../../components/auth-wall';
import Footer from '../../components/footer';
import RecentActivity from '../../components/recent-activity';
import CanvasSection from './canvas-section';
import DashboardSection from './dashboard-section';
import {observer} from 'mobx-react';
import {HeaderContext} from '../../components/header/context';
import SiteFooter from '../../components/footer-new';
import auth from '../../lib/auth';
import Selector from './components/selector';
import Tool from './components/tool';
import {Bullhorn, Catalog, InformationSquare} from '@carbon/icons-react';
import ToolSection from './components/tool-section';
import SiteName from '../../components/SiteName';

const scrollHeightTrigger = 300;
const useCanvas = true;

function LandingPage() {
    const [search, setSearch] = useState('');
    const {useNewDesign} = useContext(HeaderContext);
    const toolAccess = useMemo(() => {
        return (
            checkAccess(SiteFeature.PopHealthMap) ||
            checkAccess(SiteFeature.HospitalDRGLookup) ||
            checkAccess(SiteFeature.PatientFlow) ||
            checkAccess(SiteFeature.MarketSelection)
        );
    }, []);

    // Handle scrolling and setting the color of the navbar
    const [navClasses, setNavClasses] = useState('');
    useEffect(() => {
        const scrollListener = () => {
            setNavClasses(window.scrollY >= scrollHeightTrigger ? 'bg-blue-500 bg-opacity-80' : '');
        };
        window.addEventListener('scroll', scrollListener);
        return () => window.removeEventListener('scroll', scrollListener);
    }, []);

    function doSearch(e: FormEvent) {
        e.preventDefault();
        if (!search) return;
        window.location.assign('/search?q=' + encodeURIComponent(search));
    }

    const LandingPageView = useNewDesign ? NewLandingPage : DefaultLandingPage;

    return (
        <AuthWall>
            <LandingPageView
                useNewDesign={useNewDesign}
                navClasses={navClasses}
                doSearch={doSearch}
                search={search}
                setSearch={setSearch}
                toolAccess={toolAccess}
            />
        </AuthWall>
    );
}

type LandingPageProps = {
    useNewDesign: boolean
    navClasses: string
    doSearch(e: FormEvent): void
    search: string
    toolAccess: boolean
    setSearch: React.Dispatch<React.SetStateAction<string>>
}

function DefaultLandingPage(props: LandingPageProps) {
    const {useNewDesign, doSearch, navClasses, search, toolAccess, setSearch} = props;

    return (
        <Template float light className={!useNewDesign ? navClasses : ''}>
            <div id='landing-page'>
                <div id='hero' className='bg-blue-gradient'>
                    <img src={logoWhite} alt='Torch Insight' />
                    <h1 className='text-white font-light text-3xl'>Smart on Data</h1>
                    {checkAccess(SiteFeature.Search) && (
                        <div className='pt-6'>
                            <form onSubmit={doSearch}>
                                <input
                                    type='text'
                                    placeholder='Search...'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </form>
                        </div>
                    )}
                    <RecentActivity />
                </div>
                {(toolAccess || checkAccess(SiteFeature.Discovery)) && (
                    <div className='bg-white pb-10 pt-20'>
                        {' '}
                        {/* Change `pb-10 pt-20` to `py-10` when we remove the alert */}
                        <div className='container'>
                            <OneOneLayout>
                                {toolAccess && (
                                    <div>
                                        <h2 className='text-green-500 flex uppercase items-center'>
                                            <img
                                                src={iconTools}
                                                alt='Tools'
                                                className='h-10 mr-2'
                                            />
                                            Tools
                                        </h2>
                                        <p>
                                            Navigate health care data with{' '}
                                            <a
                                                href={
                                                    '//' +
                                                    process.env.REACT_APP_TI_DOMAIN +
                                                    '/tools'
                                                }
                                            >
                                                our visualization tools
                                            </a>
                                            . Some of our most popular tools include:
                                        </p>
                                        {checkAccess(SiteFeature.PopHealthMap) && (
                                            <div className='mt-3'>
                                                <a
                                                    href={
                                                        '//' +
                                                        process.env.REACT_APP_TI_DOMAIN +
                                                        '/pophealth'
                                                    }
                                                    className='block md:inline-block relative rounded shadow px-3 py-2 focus:ring-4 bg-gray-100 text-green-500 hover:bg-gray-50 active:bg-gray-200 focus:ring-blue-500 focus:ring-opacity-50 transition'
                                                >
                                                    Population Health Map
                                                </a>
                                            </div>
                                        )}
                                        {checkAccess(SiteFeature.EOC) && (
                                            <div className='mt-3'>
                                                <a
                                                    href={
                                                        '//' +
                                                        process.env.REACT_APP_TI_DOMAIN +
                                                        '/eoc'
                                                    }
                                                    className='block md:inline-block relative rounded shadow px-3 py-2 focus:ring-4 bg-gray-100 text-green-500 hover:bg-gray-50 active:bg-gray-200 focus:ring-blue-500 focus:ring-opacity-50 transition'
                                                >
                                                    Episodes of Care
                                                </a>
                                            </div>
                                        )}
                                        {checkAccess(SiteFeature.PatientFlow) && (
                                            <div className='mt-3'>
                                                <a
                                                    href={
                                                        '//' +
                                                        process.env.REACT_APP_TI_DOMAIN +
                                                        '/patient-flow'
                                                    }
                                                    className='block md:inline-block relative rounded shadow px-3 py-2 focus:ring-4 bg-gray-100 text-green-500 hover:bg-gray-50 active:bg-gray-200 focus:ring-blue-500 focus:ring-opacity-50 transition'
                                                >
                                                    Patient Flow
                                                </a>
                                            </div>
                                        )}
                                        {checkAccess(SiteFeature.MarketSelection) && (
                                            <div className='mt-3'>
                                                <a
                                                    href={
                                                        '//' +
                                                        process.env.REACT_APP_TI_DOMAIN +
                                                        '/market-select'
                                                    }
                                                    className='block md:inline-block relative rounded shadow px-3 py-2 focus:ring-4 bg-gray-100 text-green-500 hover:bg-gray-50 active:bg-gray-200 focus:ring-blue-500 focus:ring-opacity-50 transition'
                                                >
                                                    Territory Builder
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {checkAccess(SiteFeature.Discovery) && (
                                    <div>
                                        <h2 className='text-orange-500 flex uppercase items-center'>
                                            <img
                                                src={iconData}
                                                alt='Data Access'
                                                className='h-10 mr-2'
                                            />
                                            Data Access
                                        </h2>
                                        <p>
                                            Dive deep into the data. With our Data Discovery suite,
                                            you can explore, refine, and export the data you need.
                                        </p>
                                        <p>
                                            Within Data Discovery, you can navigate entities, their
                                            affiliations, and additional supplemental data.
                                        </p>
                                        <p className='text-center'>
                                            <Button to='/healthcare-index' color='orange'>
                                                Healthcare Index
                                            </Button>
                                        </p>
                                    </div>
                                )}
                            </OneOneLayout>
                        </div>
                    </div>
                )}
                {useCanvas ? <CanvasSection /> : <DashboardSection />}
                {checkAccess(SiteFeature.HealthcareIndex) && (
                    <div className='py-10'>
                        <div className='container'>
                            <OneTwoLayout>
                                <div className='landing-image-section'>
                                    <img src={indexImg} alt='Healthcare Index' />
                                </div>
                                <div>
                                    <h2>Healthcare Index</h2>
                                    <p>
                                        A curated collection of the best of <SiteName />'s data and
                                        affiliations.
                                    </p>
                                    <Button color='primary' to='/healthcare-index'>
                                        Access
                                    </Button>
                                </div>
                            </OneTwoLayout>
                        </div>
                    </div>
                )}
                {useNewDesign ? <SiteFooter /> : <Footer />}
            </div>
        </Template>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function NewLandingPage(props: LandingPageProps) {
    return (
        <Template float light>
            <div className='dpp-container w-4/5 px-8 py-4 flex flex-col h-full'>
                <div className='my-4'>
                    <div className='text-2xl'>
                        Hello, <strong>{auth.principal?.name}</strong>
                    </div>
                    {auth.principal?.orgs[0] && (
                        <div>
                            <strong>{auth.principal?.orgs[0].name}</strong>
                        </div>
                    )}
                </div>
                <div className='landing-section'>
                    <div>Get Started</div>
                    <Selector />
                </div>
                <div className='landing-section'>
                    <div>Tools</div>
                    <div className='flex space-x-8'>
                        <ToolSection />
                    </div>
                </div>
                <div className='landing-section'>
                    <div>Resources</div>
                    <div className='flex space-x-8'>
                        <Tool
                            name='Data Dictionary'
                            description={<span>Look up information on any data source or variable we have available.</span>}
                            link={`https://${process.env.REACT_APP_TI_DOMAIN}/glossary`}
                            icon={<Catalog />}
                        />
                        <Tool
                            name='Learning Center'
                            description={<span>Learn how to use our tools and APIs.</span>}
                            link='/'
                            icon={<InformationSquare />}
                        />
                        <Tool
                            name='News Center'
                            description={<span>Learn about the latest news and updates happening at <SiteName /></span>}
                            link='/'
                            icon={<Bullhorn />}
                        />
                    </div>
                </div>
                <div className='end flex-grow'>
                    <div> About <SiteName /></div>
                    <div className='text-sm text-gray-500 pb-2'>
                        We provide access to our cloud-based database and key analytic tools for strategists thirsting for intelligence, and for marketing teams hungry to expand. We specialize in understanding ACOs, value-based care, and how markets are transforming. Built with 21st century technology, we bring together consulting expertise with cloud technology, to turn healthcaredata into actionable insights. Learn more about our solutions below.
                    </div>
                    <a className="text-medinsight-blue-300" href={`https://${process.env.REACT_APP_TI_DOMAIN}/data.html`}>Learn More</a>
                </div>
            </div>
            <SiteFooter/>
        </Template>
    );
}

export default observer(LandingPage);
