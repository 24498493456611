import {dataRow, dataRows} from '../../../lib/data-types';
import {LinkType} from '../../../lib/links';

export type CrudApi = {
    list: (signal: AbortSignal) => Promise<dataRows>,
    modify?: (item: dataRow) => Promise<dataRow>,
    update: (item: dataRow) => Promise<dataRow>,
    delete: (id: number) => Promise<void>,
    create: (item: dataRow) => Promise<dataRow>,
}

export type FieldType = {
    name: string,
    label: string,
    show: boolean,
    readonly?: boolean,
    type: string,
    note?: string,
    linkType?: LinkType,
}

export enum CrudAction {
    Create,
    Edit,
    Delete,
    None,
}

export type ActionObj = Record<string, (item: dataRow) => void>;