import {ReactElement, useState} from 'react';

export type AccordionCategory = {
    name: string
    children: ReactElement
}

type Props = {
    categories: Array<AccordionCategory>
    firstDrawerOpen?: boolean
}

export default function Accordion(props: Props) {
    const {categories, firstDrawerOpen} = props;
    const [currentCatIndex, setCurrentCatIndex] = useState<number>(firstDrawerOpen ? 0 : -1);

    return (
        <div className='w-full flex flex-col'>
            {categories.map((category, i) => (
                <div
                    key={i}
                    className='w-full flex flex-col items-center border-b hover:cursor-pointer'
                    onClick={(e) => {
                        e.stopPropagation();
                        setCurrentCatIndex(i === currentCatIndex ? -1 : i);
                    }}
                >
                    <div
                        className={`w-full flex items-center justify-start p-3 ${
                            i === currentCatIndex ? 'bg-gray-200' : ''
                        }`}
                    >
                        <i className={`mr-2 ${i === currentCatIndex ? 'icon-up' : 'icon-down'}`} />
                        {category.name}
                    </div>
                    {i === currentCatIndex && (
                        <div className='w-full'>
                            {currentCatIndex >= 0 && categories[currentCatIndex].children}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
