import React from 'react';
import classNames from '../../lib/class-names';
import Library from '../../lib/library';

type Props = {
    library: Library,
    section: number,
    setSection: (i: number) => void,
};

export default function LibraryNav(props: Props) {
    const {library, section, setSection} = props;

    return <div id="dataset-nav">
        {library.map((s, i) => <div key={i} onClick={() => setSection(i)}
            className={classNames('dataset', {'is-active': i === section})}>
            {s.name}
        </div>)}
    </div>;
}