import {observer} from 'mobx-react';
import {useContext} from 'react';
import ChartContext from './context';

type Props = {
    rowIndex: number
}

const AdditionalToolTipVariables = observer((props: Props) => {
    let chartContext = useContext(ChartContext);
    const {rowIndex} = props;

    return <div>
        {chartContext.additionalToolTipVariables.map((vName, i) => {
            const variable = chartContext.metadata.find(v => v.name === vName);
            if (!variable)
                return null;
            let value = variable.format(chartContext.data[rowIndex]) as string;
            if (!value)
                value = 'null';
            return <div key={i} className="p-3">
                <strong>{variable.label}:</strong> {value}
            </div>;
        })}
    </div>;
});

export default AdditionalToolTipVariables;