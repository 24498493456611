import {useEffect, useState} from 'react';
import {DataConfig} from '../types';
import MultiQueryDataRenderer, {MultiQueryRenderChildProps} from '../renderers/multi-query-data-renderer';
import {dataRows, TransformCBs} from '../../lib/data-types';
import Variable from '../../lib/metadata';
import {DataTableVis} from '../data-table';
import CompareTableWithVariable from './compare-table-with-variable';
import CompareTable from './compare-table';
import PivotedCompareTable from './pivoted-compare-table';
import BasicCompareTable from './basic-compare-table';

export function CompareTableVis(props: MultiQueryRenderChildProps) {
    const {queries, dataConfig} = props;
    const [compareData, setCompareData] = useState<dataRows>([]);
    const [compareMetadata, setCompareMetadata] = useState<Array<Variable>>([]);
    dataConfig.options.hideExplore = true;

    useEffect(() => {
        let compareTable: CompareTable;
        if (dataConfig.options.compareVariable) {
            compareTable = new CompareTableWithVariable(queries, dataConfig.options.compareVariable, dataConfig.options.asPercentage);
        } else if (dataConfig.options.pivoted) {
            compareTable = new PivotedCompareTable(queries, dataConfig.options.asPercentage);
        } else {
            compareTable = new BasicCompareTable(queries, dataConfig.options.asPercentage);
        }
        setCompareData(compareTable.compareData);
        setCompareMetadata(compareTable.compareMetadata);
    }, [queries, dataConfig.options.compareVariable, dataConfig.options.pivoted, dataConfig.options.asPercentage]);

    return <DataTableVis dataConfig={dataConfig} data={compareData} metadata={compareMetadata}/>;
}

export default function CompareTableRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void, transforms?: TransformCBs}) {
    return <MultiQueryDataRenderer dataConfig={props.dataConfig} transforms={props.transforms}>
        {CompareTableVis}
    </MultiQueryDataRenderer>;
}