import smartlookClient from 'smartlook-client';
import auth from './auth';


/**
 * Calls the init function on the analytics provider SDK
 * @param key
 */
function initAnalyticsProvider(key: string | undefined) {
    if (key && !smartlookClient.initialized()) {
        smartlookClient.init(key);
    }
}

/**
 * Identifies the user if they are logged in an not proxied to the analytics provider SDK
 */
function identifyVisitor() {
    if (auth.principal && !auth.isProxied()) {
        smartlookClient.identify(auth.principal.sub, {
            name: auth.principal.name,
            email: auth.principal.email,
            defaultOrg: auth.principal.orgs[0].name
        });
    }
}

/**
 * Used to track a custom event and report it to the analytics provider SDK
 * @param eventType
 * @param payload
 */
function trackEvent(eventType: string, payload: Record<string, string | number>) {
    smartlookClient.track(eventType, payload);
}

/**
 * Used to add a custom property to every event that is tracked, including custom events
 * @param property
 */
function trackEventProperty(property: Record<string, string | number>) {
    smartlookClient.properties(property);
}

/**
 * Used to report a navigation event if page URL has not changed.
 * Only use this in that case or a double event will be reported
 * @param path
 */
function reportNavigation(path: string) {
    smartlookClient.navigation(path);
}

/**
 * Gets analytics data from provider. Takes a callback as a parameter
 * @param cb
 */
function getAnalyticsData(cb: () => void) {
    smartlookClient.getData(cb);
}

/**
 * Used to anonymize the current user
 */
function anonymizeUser() {
    smartlookClient.anonymize();
}

/**
 * Used to disable analytics tracking
 */
function disableTracking() {
    smartlookClient.disable();
}

/**
 * Used to pause a session recording
 */
function pauseRecording() {
    smartlookClient.pause();
}

/**
 * Used to resume a paused session recording
 */
function resumeRecording() {
    smartlookClient.resume();
}

/**
 * Used to report a JS or customer error
 * @param error
 */
function trackError(error: Error) {
    smartlookClient.error(error);
}

export {
    initAnalyticsProvider,
    identifyVisitor,
    trackEvent,
    trackEventProperty,
    reportNavigation,
    getAnalyticsData,
    anonymizeUser,
    disableTracking,
    pauseRecording,
    resumeRecording,
    trackError
};