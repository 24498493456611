import {CvInsertScreen, CvInsertVisType, ICConfig} from '../../lib/types';
import {ChartScatter} from '@carbon/icons-react';


const defaultVisScreens = [
    CvInsertScreen.queryType,
    CvInsertScreen.querySelect,
    CvInsertScreen.model,
    CvInsertScreen.configVis
];


export const ConditionalRender: ICConfig = {
    type: CvInsertVisType.conditionalRender,
    label: 'Conditional Render',
    icon: '',
    screens: [
        CvInsertScreen.queryType,
        CvInsertScreen.querySelect,
        CvInsertScreen.model,
        CvInsertScreen.conditionalRender
    ],
    hide: true
};

export const Parameter: ICConfig = {
    type: CvInsertVisType.parameter,
    label: 'Canvas Parameter',
    icon: '',
    screens: defaultVisScreens,
    adminOnly: true,
    hide: true
};

const RichText: ICConfig = {
    type: CvInsertVisType.richText,
    label: 'Rich Text',
    icon: 'icon-file-text',
    adminOnly: true,
    screens: []
};

const Query: ICConfig = {
    type: CvInsertVisType.query,
    label: 'Table',
    icon: 'icon-table',
    screens: [...defaultVisScreens, CvInsertScreen.configInaccessible]
};

const BarChart: ICConfig = {
    type: CvInsertVisType.bar,
    label: 'Bar Chart',
    icon: 'icon-chart-bar',
    screens: [...defaultVisScreens, CvInsertScreen.configInaccessible]
};

const LineChart: ICConfig = {
    type: CvInsertVisType.plot,
    label: 'Line Chart',
    icon: 'icon-chart-line',
    screens: [...defaultVisScreens, CvInsertScreen.configInaccessible]
};

const PieChart: ICConfig = {
    type: CvInsertVisType.pie,
    label: 'Pie Chart',
    icon: 'icon-chart-pie',
    screens: [...defaultVisScreens, CvInsertScreen.configInaccessible]
};

const EntityAffiliation: ICConfig = {
    type: CvInsertVisType.entityAffiliations,
    label: 'Entity Affiliation',
    icon: 'icon-group',
    screens: [
        CvInsertScreen.queryType,
        CvInsertScreen.querySelect,
        CvInsertScreen.model, 
        CvInsertScreen.configInaccessible
    ],
    adminOnly: true
};

const Profile: ICConfig = {
    type: CvInsertVisType.profile,
    label: 'Profile',
    icon: 'icon-user',
    screens: [
        CvInsertScreen.queryType,
        CvInsertScreen.querySelect,
        CvInsertScreen.model, 
        CvInsertScreen.configInaccessible
    ]
};

const DataText: ICConfig = {
    type: CvInsertVisType.dataText,
    label: 'Data Text',
    icon: 'icon-file-text',
    screens: [
        CvInsertScreen.queryType,
        CvInsertScreen.querySelect,
        CvInsertScreen.model,
        CvInsertScreen.configText, 
        CvInsertScreen.configInaccessible
    ]
};

const OwnershipGraph: ICConfig = {
    type: CvInsertVisType.ownership,
    label: 'Health System Ownership Graph',
    icon: 'icon-user',
    screens: [
        CvInsertScreen.configOwnership
    ],
};

const Map: ICConfig = {
    type: CvInsertVisType.map,
    label: 'Map',
    icon: 'icon-map',
    screens: [
        CvInsertScreen.mapQuerySelection,
        CvInsertScreen.queryType,
        CvInsertScreen.querySelect,
        CvInsertScreen.model,
        CvInsertScreen.mapQuerySelection,
        CvInsertScreen.columnConfig,
        CvInsertScreen.mapConfig, 
        CvInsertScreen.configInaccessible
    ]

};

const SingleValue: ICConfig = {
    type: CvInsertVisType.singleValue,
    label: 'Single Value',
    icon: 'icon-file-text',
    screens: [
        CvInsertScreen.queryType,
        CvInsertScreen.querySelect,
        CvInsertScreen.model,
        CvInsertScreen.configSingleValue, 
        CvInsertScreen.configInaccessible
    ]
};

const Sankey: ICConfig = {
    type: CvInsertVisType.sankey,
    label: 'Sankey',
    icon: 'icon-chart-flow',
    screens: [
        CvInsertScreen.queryType,
        CvInsertScreen.querySelect,
        CvInsertScreen.model,
        CvInsertScreen.configSankey, 
        CvInsertScreen.configInaccessible
    ]
};

const Scatter: ICConfig = {
    type: CvInsertVisType.scatter,
    label: 'Scatter',
    icon: '',
    screens: [...defaultVisScreens, CvInsertScreen.configInaccessible],
    componentIcon: <ChartScatter size={96}/>
};


const CanvasICList = [
    ConditionalRender,
    Parameter,
    RichText,
    Query,
    BarChart,
    LineChart,
    PieChart,
    EntityAffiliation,
    Profile,
    DataText,
    OwnershipGraph,
    Map,
    SingleValue,
    Sankey,
    Scatter
];

export default CanvasICList;