import {useContext} from 'react';
import {CanvasInsertContext} from '../../lib/context';
import InsightContainer from '../../../../insight-container';
import {observer} from 'mobx-react';

const MapEditor = observer(() =>{

    const insertStore = useContext(CanvasInsertContext);

    return <InsightContainer type='map' dataConfig={insertStore.dataConfig}/>;


});

export default MapEditor;