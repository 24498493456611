import React, {ReactNode, useContext, useMemo, useRef} from 'react';
import './tooltip.scss';
import classNames from '../../lib/class-names';
import {HeaderContext} from '../header/context';
import {Tooltip as CarbonTooltip, PopoverAlignment} from '@carbon/react';

type Props = {
    children: ReactNode,
    content: ReactNode,
    position?: string,
    block?: boolean,
    wide?: boolean,
    narrow?: boolean,
    className?: string
    alignment?: PopoverAlignment
};

const EDGE_OFFSET = 10;
export default function Tooltip(props: Props) {
    const {useNewDesign} = useContext(HeaderContext);
    const classes = classNames('tooltip', {block: props.block}, props.className);
    const popupRef = useRef(null);
    let contentClasses = classNames('tooltip-content', {wide: props.wide, narrow: props.narrow});
    if (props.position)
        contentClasses += ' placement-' + props.position;

    const edgeOffset = useMemo(() => {
        let offset = 0;
        if (popupRef && popupRef.current && props.position === 'below') {
            const element: HTMLDivElement = popupRef.current;
            const rect = element.getBoundingClientRect();
            if ((rect.right + EDGE_OFFSET) >= window.innerWidth)
                offset = ((rect.right - window.innerWidth) * -1) || -EDGE_OFFSET;
        }

        return offset;
    }, [popupRef.current]);

    if (useNewDesign) {
        return <CarbonTooltip className={props.className} align={props.alignment || 'bottom'} description={props.content}>
            <button className="sb-tooltip-trigger" type="button">
                {props.children}
            </button>
        </CarbonTooltip>;
    }

    return <div className={classes}>
        {props.children}
        <div style={{left: edgeOffset ? edgeOffset : ''}} className={contentClasses} ref={popupRef}>
            {props.content}
        </div>
    </div>;
}

export function ConditionalTooltip(props: Props & { condition: boolean }) {
    return props.condition ? <Tooltip {...props} /> : <>{props.children}</>;
}