import React, {useState} from 'react';
import EndpointDoc from './endpoint';
import Button from '../../../components/button';
import auth from '../../../lib/auth';
import SiteName from '../../../components/SiteName';


export default function APIDocAuthorization() {
    const [copyMsg, setCopyMsg] = useState(false);

    function copyToken() {
        navigator.clipboard.writeText(auth.token || '');
        setCopyMsg(true);
    }

    return <div>
        <h2>Authorization</h2>
        <p>
            All calls to the <SiteName /> API must be authorized. You must first authenticate using your <SiteName />
            account and then include your access token with every call.
        </p>

        <h3>Getting the Access Token</h3>
        <p>
            Begin by providing your <SiteName /> email and password to the following endpoint. It will return your
            access token. Access tokens are valid for 24 hours.
        </p>

        <EndpointDoc method="post" path="/v2/user/authenticate"
            description="Authenticates using the provided credentials and returns an access token."
            response="A JWT (JSON Web Token) is returned. This token will be unique with every call.">
            Provide your credentials in the <code>POST</code> body as a <code>JSON</code> object with the following
            properties
            <ul className="list-disc list-inside">
                <li><code>email</code></li>
                <li><code>password</code></li>
            </ul>
        </EndpointDoc>

        <h3>Authorizing API Calls</h3>
        <p>
            Once you have your token, you can then make API calls. Simply set the <code>Authorization</code> header to be: <code>Bearer &lt;token&gt;</code>
        </p>
        <pre>Authorization: Bearer your-token-here</pre>

        <h3>Your Current Token</h3>
        <p>
            Your current token (generated by logging into <SiteName /> today) is:
        </p>
        <pre>{auth.token}</pre>
        <p>
            <Button color="light" onClick={copyToken}>Copy Token</Button>
            {copyMsg && <span className="ml-3 text-orange-400 text-sm">Token copied to clipboard</span>}
        </p>
        <p className="text-sm text-gray-400">
            This token is valid until:
            <span className="ml-3 text-gray-500 font-medium">
                {new Date(auth.principal!.exp * 1000).toLocaleString()}
            </span>
        </p>

        <hr/>
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/api/headers">Required Headers</Button>
        </p>
    </div>;
}