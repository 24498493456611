import React, {ReactNode} from 'react';
import {dataRow, dataValue} from './data-types';
import generateLink, {LinkType} from './links';
import {Link} from 'react-router-dom';
import Variable from './metadata';
import Button from '../components/button';


export abstract class Formatter {
    nullMsg: string | null;

    constructor(nullMsg: string | null = null) {
        this.nullMsg = nullMsg;
    }

    abstract formatValue(value: dataValue, row: dataRow): ReactNode;

    abstract formatSimple(value: dataValue): string;

    format(value: dataValue, row?: dataRow): ReactNode {
        if (value === null || value === undefined)
            return <em className="text-gray-400">{this.nullMsg || 'null'}</em>;
        if (row)
            return this.formatValue(value, row);
        else
            return this.formatSimple(value);
    }
}

export class NumberFormatter extends Formatter {

    protected numDigits: number;
    numFormat: Intl.NumberFormat;

    constructor(precision: number, nullMsg: string | null = null) {
        super(nullMsg);
        this.numDigits = precision.toString().replace(/[^.]*\.?/, '').length;
        this.numFormat = new Intl.NumberFormat('en-US', {
            maximumFractionDigits: this.numDigits,
            minimumFractionDigits: this.numDigits,
        });
    }

    formatValue(value: dataValue): string {
        if (isNaN(Number(value))) // Handle cases when a non-number is labeled as a number
            return String(value);
        return this.numFormat.format(Number(value));
    }

    formatSimple(value: dataValue): string {
        return this.formatValue(value);
    }
}

export class MoneyFormatter extends NumberFormatter {

    constructor(precision: number, nullMsg: string | null) {
        super(precision, nullMsg);
        this.numFormat = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: this.numDigits,
            maximumFractionDigits: this.numDigits,
            style: 'currency',
            currency: 'USD',
        });
    }
}

export class PercentFormatter extends NumberFormatter {

    constructor(precision: number, nullMsg: string | null) {
        super(precision, nullMsg);
        this.numFormat = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: this.numDigits,
            maximumFractionDigits: this.numDigits,
            style: 'percent',
        });
    }
}

export class DateTimeFormatter extends Formatter {

    private includeTime: boolean;

    constructor(includeTime: boolean, nullMsg: string | null = null) {
        super(nullMsg);
        this.includeTime = includeTime;
    }

    formatValue(value: dataValue): string {
        if (typeof value !== 'number' && typeof value !== 'string')
            return String(value);
        const date = new Date(value);
        let dateString = date.toLocaleDateString('en-US', {timeZone: 'utc'});
        if (dateString === 'Invalid Date')
            return String(value);
        if (this.includeTime)
            dateString += ' ' + date.toLocaleTimeString('en-US', {timeZone: 'utc'});
        return dateString;
    }

    formatSimple(value: dataValue): string {
        return this.formatValue(value);
    }

}

export class StringFormatter extends Formatter {
    formatValue(value: dataValue): string {
        return String(value);
    }

    formatSimple(value: dataValue): string {
        return this.formatValue(value);
    }
}

export class LinkFormatter extends Formatter {

    linkType: LinkType;
    valueVar?: Variable;
    newTab: boolean;

    constructor(linkType: LinkType, valueVar?: Variable, newTab?: boolean) {
        super();
        this.linkType = linkType;
        this.valueVar = valueVar;
        this.newTab = newTab || false;
    }

    formatValue(value: dataValue, row: dataRow): React.ReactNode {
        let url = '';
        const displayValue = String(value);
        if (this.valueVar) {
            const otherValue = this.valueVar.getValue(row);
            let dataset = this.valueVar.getTag('dataset') || this.valueVar.dataset || '';
            if (this.linkType === LinkType.OldDashboard && this.valueVar.getTag('dashboardDataset'))
                dataset = this.valueVar.getTag('dashboardDataset')!;
            else
                url = generateLink(this.linkType, otherValue, dataset);
        } else {
            url = generateLink(this.linkType, value);
        }

        if (this.linkType === LinkType.External)
            return <a href={url} onClick={event => event.stopPropagation()} target="_blank" rel="noreferrer noopener">{displayValue}</a>;
        else if (this.linkType === LinkType.OldDashboard)
            return <a href={url} onClick={event => event.stopPropagation()} target={this.newTab ? '_blank' : ''} rel="noreferrer noopener">{displayValue}</a>;
        else
            return <Link to={url} onClick={event => event.stopPropagation()} target={this.newTab ? '_blank' : ''} rel="noreferrer noopener">{displayValue}</Link>;
    }

    formatSimple(value: dataValue): string {
        return String(value);
    }
}

export type ActionType = {
    label: string,
    color: string,
    icon: string,
    onClick: (row: dataRow) => void
}

export class ActionFormatter extends Formatter {
    private actions: ActionType[];
    private readonly className: string;

    constructor(actions: ActionType[], className?: string) {
        super();
        this.actions = actions;
        this.className = className || '';
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formatSimple(value: dataValue): string {
        return '';
    }

    formatValue(value: dataValue, row: dataRow): React.ReactNode {
        return <div className={this.className}>
            {this.actions.map(action => <Button key={action.label} color={action.color} onClick={() => action.onClick(row)}><i className={action.icon}/> {action.label}</Button>)}
        </div>;
    }

}

export class CanvasLinkFormatter extends LinkFormatter {

    constructor(valueVar: Variable, newTab?: boolean) {
        super(LinkType.Profile, valueVar, newTab);
    }

    formatValue(value: dataValue, row: dataRow): React.ReactNode {
        const displayValue = String(value);
        const dataset = this.valueVar!.getTag('dataset') || this.valueVar!.dataset || '';
        const valueId = this.valueVar!.getValue(row);

        return <Link to={`/canvas-redirect/${dataset}/${valueId}`} onClick={event => event.stopPropagation()} target={this.newTab ? '_blank' : ''} rel="noreferrer noopener">{displayValue}</Link>;
    }
}

export default function getFormatter(format: string, precision = 0, nullMsg: string | null = null): Formatter {
    if (!format)
        return new StringFormatter(nullMsg);
    format = format.toLowerCase();
    if (format === 'number' || format === 'fte')
        return new NumberFormatter(precision, nullMsg);
    else if (format === 'percent')
        return new PercentFormatter(precision, nullMsg);
    else if (format === 'date')
        return new DateTimeFormatter(false, nullMsg);
    else if (format === 'datetime')
        return new DateTimeFormatter(true, nullMsg);
    else if (format === 'money' || format === 'currency')
        return new MoneyFormatter(precision, nullMsg);
    else if (format === 'url')
        return new LinkFormatter(LinkType.External);
    return new StringFormatter(nullMsg);
}