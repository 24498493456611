import {Link} from 'react-router-dom';
import relativeDate from '../../../lib/relative-date';
import Loading from '../../loading';
import {ReactElement} from 'react';

export type MyContentSection = {title: string, url: string, timestamp?: string};

type Props = {
    title: string,
    content: Array<MyContentSection>,
    viewAllUrl?: string,
    contentIcon?: ReactElement,
    loading?: boolean
}

const MAX_CONTENT = 5;
export default function ContentSection(props: Props) {
    const {title, viewAllUrl, contentIcon, content, loading} = props;

    if (loading)
        return <Loading small />;

    return <div>
        <div className="flex justify-between items-center">
            <h4>{title}</h4>
            {viewAllUrl && <Link className="mt-4 text-medinsight-blue-300" to={viewAllUrl}>View All</Link>}
        </div>
        <ul className="pl-5 flex flex-col space-y-1 mt-2">
            {content.slice(0, MAX_CONTENT).map((item, index) => <li key={index} className="flex flex-col">
                <div className="flex space-x-3">
                    {contentIcon ? <span className="pt-1">{contentIcon}</span> : <span className="text-blue-400">-</span>}
                    <Link className="underline text-medinsight-blue-300 truncate" to={item.url}>{item.title}</Link>
                </div>
                {item.timestamp && <small className="pl-5 text-gray-400">Viewed {relativeDate(new Date(item.timestamp))}</small>}
            </li>)}
        </ul>
    </div>;
}