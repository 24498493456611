import Autocomplete from '../autocomplete';
import React from 'react';
import {QuantumGraph, TestType} from 'quantum-graph';
import fetchData from '../../lib/data';
import Filter from '../../lib/filter';
import Variable from '../../lib/metadata';
import GraphHandler from '../../lib/graph-tools/graph-handler';
import createLibrary from '../../lib/library/create';
import {getDefaultDataDefRepo} from '../../lib/data-defs';

type Props = {
    value: string,
    onChange: (v: string) => void,
    variable: Variable,
    gh: GraphHandler<QuantumGraph>,
};

export default function FilterAutocomplete(props: Props) {
    const {value, onChange, gh, variable} = props;

    async function getOptions(v: string) {
        const prevFilters = gh.getFilters([variable], createLibrary(gh.build(), getDefaultDataDefRepo())).filter(f => f.test === TestType.Like);
        prevFilters.forEach(f => gh.removeFilter(f));
        let newGraph = gh.removePagination().buildDistinct(variable);
        if (v) {
            const filter: Filter = {
                variable,
                test: TestType.Like,
                value: v + '%',
            };
            newGraph = gh.applyFilters([filter]).removePagination().buildDistinct(variable);
        }

        
        const data = await fetchData(newGraph, new AbortController().signal);
        
        const uniqueData = [];
        const seen = new Set();

        for (const row of data) {
            const firstPropertyKey = Object.keys(row)[0]; 
            const firstPropertyValue = row[firstPropertyKey]; 

            if (!seen.has(firstPropertyValue)) {
                seen.add(firstPropertyValue); 
                uniqueData.push(row); 
            }
        }

        return uniqueData.map(row => String(variable.getValue(row)));
    }

    return <Autocomplete onChange={onChange} getOptions={getOptions} value={value} allowFreeform getOptionsOnLoad />;
}