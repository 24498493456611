import Variable, {loadDatasetInfo} from '../metadata';
import {V1QuantumGraph} from 'quantum-graph/out/v1';
import {DataDefRepo} from '../user-data-defs';
import {findFacts} from './join';
import Library, {Section} from './index';
import V1GraphHandler from '../graph-tools/graph-handler/v1-graph-handler';

export function determineBaseDatasets(graph: V1QuantumGraph, dataDefRepo: DataDefRepo) {
    let datasets = new V1GraphHandler(graph, dataDefRepo).getDatasets();
    if (datasets.length > 1) {
        // Filter to only dims and composite. We'll only do this if there are more than one dataset meaning there must be a join
        datasets = datasets.filter(dataset => {
            const datasetDef = dataDefRepo.datasetByName[dataset];
            return datasetDef.tableType === 'dim' || datasetDef.tableType === 'composite';
        });
    }

    // Composite datasets: Expand out by grain if they don't have defined facts
    for (const dataset of datasets.slice()) {
        const datasetDef = dataDefRepo.datasetByName[dataset];
        const hasFacts = !!datasetDef.facts && !!datasetDef.facts.length;
        if (datasetDef.tableType === 'composite' && !hasFacts) {
            const newDatasets = datasetDef.tableGrain
                .filter(g => g[0] === '!')
                .map(g => g.substr(1));
            datasets = datasets.concat(newDatasets);
        }
    }

    // De-dupe and sort
    datasets = Array.from(new Set(datasets));
    datasets = datasets.slice(0, 1).concat(datasets.slice(1).sort());

    return datasets;
}

export class V1Section extends Section {

    datasets: Array<string>;

    constructor(baseDataset: string, dataDefRepo: DataDefRepo) {
        super();
        const dataDef = dataDefRepo.datasetByName[baseDataset];
        const facts = findFacts(dataDef, dataDefRepo);
        this.datasets = [baseDataset, ...facts];

        // If the base is a composite with facts, then add in the grain dims too
        if (dataDef.tableType === 'composite' && !!dataDef.facts && !!dataDef.facts.length) {
            const dimGrains = dataDef.tableGrain.filter(g => g[0] === '!').map(g => g.substr(1));
            for (const dim of dimGrains) {
                if (!this.datasets.includes(dim))
                    this.datasets.push(dim);
            }
        }
    }

    async loadName(signal: AbortSignal) {
        const dataset = this.datasets[0];
        this.dsInfo[dataset] = await loadDatasetInfo(dataset, signal);
        this.name = this.dsInfo[dataset].label || dataset;
    }

    protected getDatasets(): Array<string> {
        return this.datasets;
    }

}

// Note: V1Library does not support affiliations with v1 queries
export default class V1Library extends Library {

    constructor(graph: V1QuantumGraph, dataDefRepo: DataDefRepo) {
        super();
        this.sections = determineBaseDatasets(graph, dataDefRepo)
            .map(ds => new V1Section(ds, dataDefRepo));
    }

    toDataLibrary(): Array<Array<Variable>> {
        const categories: Record<string, Array<Variable>> = {};
        for (const section of this.sections) {
            for (const variable of section.index.getVariables()) {
                const category = variable.getCategory();
                if (!categories[category])
                    categories[category] = [];
                categories[category].push(variable);
            }
        }

        return Object.values(categories);
    }
}