import {observer} from 'mobx-react';
import Checkbox from '../../../../components/checkbox';
import {useContext, useMemo, useState} from 'react';
import {CanvasContext, CanvasInsertContext} from '../../lib/context';
import Select from '../../../../components/select';
import createGraphHandler from '../../../../lib/graph-tools/graph-handler/create';
import Button from '../../../../components/button';
import {textTests} from '../../../../components/variable-filter';
import {TestType} from 'quantum-graph';
import QueryDataContext from '../../../../insight-container/renderers/query-data-renderer/context';
import {ParameterConfig} from '../../lib/types';
import Badge from '../../../../components/badge';
import {getDefaultDataDefRepo} from '../../../../lib/data-defs';

const delimiter = '::';

const ParameterDataModel = observer(() => {
    const insertStore = useContext(CanvasInsertContext);
    const canvasStore = useContext(CanvasContext);
    const queryDataStore = useContext(QueryDataContext);
    const [applyParameter, setApplyParameter] = useState<boolean>(false);
    const [parameterVariable, setParameterVariable] = useState<string>('');
    const [test, setTest] = useState<string>(TestType.Eq);
    const [dataModelVariable, setDataModelVariable] = useState<string>('');
    const [showConfiguredParams, setShowConfiguredParams] = useState<boolean>(false);

    const parameterVariables = useMemo(() => {
        return Object.keys(canvasStore.parameterRow).map(prop => ({
            label: prop,
            value: prop
        }));
    }, [canvasStore.parameterRow]);

    const dataModelVariables = useMemo(() => {
        if (!queryDataStore.getGraph())
            return [];

        const gh = createGraphHandler(queryDataStore.getGraph(), getDefaultDataDefRepo());
        const variables = gh.extractVariables();
        return variables.map(v => ({
            label: `${v.variable}${delimiter}${v.dataset}`,
            value: `${v.variable}${delimiter}${v.dataset}`
        }));
    }, [queryDataStore.getGraph()]);

    function addParameter() {
        const dataModel = dataModelVariable.split(delimiter);
        const typedTest: TestType = test as TestType;
        const config: ParameterConfig = {
            parameterField: parameterVariable,
            dataModelField: dataModel[0],
            modelDataset: dataModel[1],
            test: typedTest
        };
        let added = insertStore.addParameterConfig(config);
        if (added)
            insertStore.applyCanvasParameterFilter(queryDataStore.getGraph(), config);
    }

    return <div>
        <div className="flex space-x-5">
            <div className="flex space-x-3 items-center cursor-pointer" onClick={() => setApplyParameter(!applyParameter)}>
                <Checkbox checked={applyParameter} onChange={() => true} color="blue" />
                <label>Add Canvas parameter</label>
            </div>
            {!!insertStore.parameterConfigs.length && <div className="flex space-x-5">
                <div className="p-2">
                    <Button color="light" onClick={() => setShowConfiguredParams(!showConfiguredParams)}>{showConfiguredParams ? 'Hide' : 'Show'} Configured Parameters</Button>
                </div>
                {showConfiguredParams && <ul className="p-2 border shadow rounded grid grid-cols-3 gap-2">
                    {insertStore.parameterConfigs.map((pc, i) => <li key={i}><Badge color="blue" pill onClose={() => insertStore.removeParameter(i)}>{pc.parameterField} :: {pc.test} :: {pc.dataModelField}</Badge></li>)}
                </ul>}
            </div>}
        </div>
        {applyParameter && <div className="flex space-x-3 items-end mt-3">
            <div className="flex flex-col space-y-2">
                <label>Choose variable from parameter</label>
                <Select data={parameterVariables} value={parameterVariable} onChange={setParameterVariable}>
                    {_ => _.value}
                    {_ => _.label}
                </Select>
            </div>
            <div className="flex flex-col space-y-2">
                <label>Test</label>
                <Select data={textTests} value={test} onChange={setTest}>
                    {_ => _.value}
                    {_ => _.label}
                </Select>
            </div>
            <div className="flex flex-col space-y-2">
                <label>Choose variable from data model</label>
                <Select data={dataModelVariables} value={dataModelVariable} onChange={setDataModelVariable}>
                    {_ => _.value}
                    {_ => _.label}
                </Select>
            </div>
            <Button color="blue" onClick={addParameter}><i className="icon-plus" /> Add</Button>
        </div>}
    </div>;
});

export default ParameterDataModel;