import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import handleError from '../../../lib/error';
import ErrorMessage from '../../../components/error-message';
import Loading from '../../../components/loading';
import Button from '../../../components/button';
import Table from '../../../components/table';
import {dataRow, dataRows} from '../../../lib/data-types';
import Variable from '../../../lib/metadata';
import searchItems from './search';
import Pagination from '../../../components/pagination';
import sortData from '../../../lib/sort';
import {downloadCsv} from '../../../lib/download';
import {ActionObj, CrudAction, CrudApi, FieldType} from './types';
import ItemModal from './item-modal';
import CrudActionHandler from './crud-action-handler';
import {useNavigate, useLocation} from 'react-router-dom';
import {generateVariables} from './generate-variables';

const PageSize = 20;

function getId(pathname: string): number | undefined {
    const match = pathname.match(/^.*\/(\d+)$/);
    if (match) {
        return Number(match[1]);
    }
}

type Props = {
    api: CrudApi,
    title: string,
    urlPath: string
    fields: Array<FieldType>,
    actions?: ActionObj,
    notice?: ReactNode,
    children?: ReactNode,
}

export default function AdminCrudTable(props: Props) {
    const {api, title, fields, notice, actions, urlPath} = props;
    const [items, setItems] = useState<dataRows>([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(0);
    const [sortAsc, setSortAsc] = useState(true);
    const [err, setErr] = useState('');
    const [activeItem, setActiveItem] = useState<dataRow | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const id = useMemo(() => getId(location.pathname), [location.pathname]);

    // Create a list of variables to use w/ the table
    const variables = useMemo(() => generateVariables(fields), [fields]);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        api.list(controller.signal).then(items => {
            setItems(items);
            setLoading(false);
        }).catch(err => handleError(err, setErr));
    }, [api]);

    // We can set the active item by setting the id in the path.
    useEffect(() => {
        if (!id) {
            setActiveItem(null);
            return;
        }
        const activeItem = items.find(item => item['id'] === id);
        setActiveItem(activeItem || null);
    }, [items, id]);

    // Update the visible results depending on the search query, the sort, and the pagination
    const [results, count] = useMemo(() => {
        if (!items.length)
            return [[], 0];

        const searchFields = fields.filter(f => f.show).map(f => f.name);
        let results = searchItems(items, search, searchFields);
        const count = results.length;
        results = sortData(results, [[variables[sort], sortAsc]]);
        return [
            results.slice(page * PageSize, (page + 1) * PageSize),
            count,
        ];
    }, [fields, items, page, search, sort, sortAsc, variables]);

    function updateSearch(newSearch: string) {
        setPage(0);
        setSearch(newSearch);
    }

    function handleSort(v: Variable) {
        const newSort = variables.indexOf(v);
        if (newSort === sort)
            setSortAsc(!sortAsc);
        else {
            setSortAsc(true);
            setSort(newSort);
        }
    }

    function handleClose(action: CrudAction, item?: dataRow) {
        if (!activeItem)
            return;

        new CrudActionHandler(activeItem, items, fields, api)
            .handle(action, item)
            .then(items => {
                setItems(items);
                if (id)
                    navigate(urlPath);
                else
                    setActiveItem(null);
            })
            .catch(err => handleError(err, setErr));
    }

    return <div>
        <h1 className="text-center">{title} Administration</h1>
        {err && <ErrorMessage error={err} />}
        {loading ?
            <div className="text-center"><Loading /></div> :
            <div>
                <div className="flex items-center mb-5">
                    <div>
                        <input type="text" className="rounded-full" placeholder="Search..." value={search} onChange={e => updateSearch(e.target.value)} />
                    </div>
                    <div className="flex-grow" />
                    <div>
                        <Button color="green" onClick={() => setActiveItem({})}>
                            <i className="icon-plus" /> New {title}
                        </Button>
                        <Button color="white" onClick={() => downloadCsv(items, variables, title + 'List')}>
                            <i className="icon-download" /> Download
                        </Button>
                    </div>
                </div>

                <Table headers={variables} data={results} sorts={[[variables[sort], sortAsc]]} onSort={handleSort}
                    onRowClick={row => navigate(urlPath + '/' + row['id'])} />
                <Pagination count={count} page={page} pageSize={PageSize} setPage={setPage} />
            </div>}

        {activeItem && <ItemModal item={activeItem} onClose={handleClose} fields={fields} name={title} actions={actions} notice={notice} />}

        {props.children}
    </div>;
}