import React, {ComponentType, useEffect, useState} from 'react';
import fetchData, {fetchMetadata} from '../../lib/data';
import handleError from '../../lib/error';
import Loading from '../../components/loading';
import {DataConfig} from '../types';
import ErrorMessage from '../../components/error-message';
import NoAccessMsg, {NoAccessErrMsg} from '../../components/no-access-msg';
import {QuantumQuery} from '../../lib/data-types';
import {QuantumGraph} from 'quantum-graph';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import {MultiQueryRenderChildProps} from './multi-query-data-renderer';

type Props = {
    dataConfig: DataConfig
    children: ComponentType<MultiQueryRenderChildProps>
    loadCB?: (promise: Promise<void>) => void
}

/**
 * The standard data renderer is to be used for all data rendering where there is only one data query to load. It
 * takes care of loading data, loading metadata, cancelling/cleanup, loading display, and error handling.
 * @constructor
 */
export default function SerialMultiQueryRenderer(props: Props) {
    const {dataConfig, loadCB} = props;
    const [loading, setLoading] = useState(true);
    const [mdLoading, setMdLoading] = useState(true);
    const [queries, setQueries] = useState<Array<QuantumQuery>>([]);
    const [err, setErr] = useState<string>('');
    const [noAccess, setNoAccess] = useState(false);

    async function executeQuantumQuery(
        graph: QuantumGraph,
        index: number,
        controller: AbortController,
    ): Promise<QuantumQuery> {
        const quantumQuery: QuantumQuery = {
            graph: graph as V2QuantumGraph,
            data: [],
            metadata: [],
            index,
        };

        try {
            quantumQuery.data = await fetchData(graph, controller.signal);
        } catch (err: any) {
            if (err.message.startsWith(NoAccessErrMsg)) setNoAccess(true);
            else handleError(err, setErr);
            throw err;
        }

        try {
            quantumQuery.metadata = await fetchMetadata(graph, controller.signal);
        } catch (err: any) {
            if (err.message.startsWith(NoAccessErrMsg)) setNoAccess(true);
            else handleError(err, setErr);
            throw err;
        }

        return quantumQuery;
    }

    const graphs = dataConfig.queries;

    useEffect(() => {
        setLoading(true);
        setMdLoading(true);
        const controller = new AbortController();

        async function loadQueries() {
            try {
                const queries = await Promise.all(
                    graphs.map((graph, i) => executeQuantumQuery(graph, i, controller)),
                );
                setQueries(queries);
            } catch (error) {
                console.error('Error loading queries:', error);
            }
            setLoading(false);
            setMdLoading(false);
        }

        loadQueries();

        return () => {
            controller.abort();
        };
    }, [graphs, loadCB]);

    if (noAccess) return <NoAccessMsg />;
    if (err) return <ErrorMessage />;
    if (loading || mdLoading) return <Loading />;

    return React.createElement(props.children, {dataConfig, queries, loadCB});
}
