import {useMemo, useState} from 'react';
import Select from '../../../components/select';
import Button from '../../../components/button';
import {loadDatasetMetadata} from '../../../lib/metadata';
import {deserialize, GraphVersions, QuantumGraph} from 'quantum-graph';
import {QueryObj} from '../../../lib/data-types';
import handleError from '../../../lib/error';
import ErrorMessage from '../../../components/error-message';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';

async function buildQuery(dataset: string): Promise<QueryObj> {
    const controller = new AbortController();
    const metadata = await loadDatasetMetadata(dataset, controller.signal);

    const idField = metadata.find(v => v.getTag('FieldRole') === 'id');
    const nameField = metadata.find(v => v.getTag('FieldRole') === 'name');
    let columns: Array<string> = [];
    if (idField && nameField)
        columns = [idField.name, nameField.name];
    else {
        columns = metadata.filter(v => v.getTag('Recommended') && v !== idField && v !== nameField)
            .sort((a, b) => {
                const ar = Number(a.getTag('Recommended'));
                const br = Number(b.getTag('Recommended'));
                return ar - br;
            })
            .slice(0, 5)
            .map(v => v.name);
    }

    if (!columns.length) {
        throw new Error('missing-metadata');
    }

    return {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: 'dim',
                tableNodes: [
                    {
                        id: dataset,
                        dataset,
                        columns,
                    }
                ]
            }
        }
    };
}

export default function CvDatasetSelector({setQuery}: {setQuery: (query:QuantumGraph) => void}) {
    const [dataset, setDataset] = useState<string>('');
    const [adding, setAdding] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const datasets = useMemo(() => {
        const names = getDefaultDataDefRepo().datasets.filter(ds => ds.tableType === 'dim' || ds.tableType === 'composite').map(ds => ds.name);
        return names.sort();
    }, []);

    async function addQuery() {
        setErrorMessage('');
        setAdding(true);
        try {
            const queryObj = await buildQuery(dataset);
            setQuery(deserialize(queryObj));
        } catch (e: any) {
            if (e.message === 'missing-metadata') {
                setErrorMessage(`Missing metadata for ${dataset}. Make sure variables have a 'Recommended' tag.`);
            } else
                handleError(e, setErrorMessage);
        }
    }

    return <div className="flex flex-col space-y-5 px-2">
        <div>
            <div className="font-bold">Dimension Dataset</div>
            <Select data={datasets} value={dataset} onChange={setDataset} disabled={adding} searchable>
                {_ => _}
                {_ => _}
            </Select>
        </div>
        <div>
            <Button color="primary" className="ml-2" onClick={addQuery} disabled={adding || !dataset}>
                Select
            </Button>
        </div>
        {errorMessage && <ErrorMessage error={errorMessage}></ErrorMessage>}
    </div>;
}
