import Template from '../../../components/template';
import AuthWall from '../../../components/auth-wall';
import {SiteFeature} from '../../../lib/check-access';
import React, {useContext} from 'react';
import PageMenu from '../../../components/page-menu';
import Button from '../../../components/button';
import {useNavigate} from 'react-router-dom';
import MarkdownContent from '../../../components/markdown-content';
import {HeaderContext} from '../../../components/header/context';
import Breadcrumb from '../../../components/breadcrumb';


export default function CRBOverviewPage() {
    const navigate = useNavigate();
    const {useNewDesign} = useContext(HeaderContext);

    return <Template>
        <AuthWall siteFeature={SiteFeature.AccessCRBenchmarks}>
            <div className={useNewDesign ? 'px-10 py-8 bg-background-gray-100' : 'pb-8'}>
                {!useNewDesign ? <PageMenu>
                    <Button color="link" onClick={() => navigate(-1)}><i className="icon-left" /> Back</Button><div className="flex-grow" />
                    <div className="flex space-x-3">
                        {/*<Button color="orange" onClick={() => setLibVisible(true)} disabled={!metadata.length}><i className="icon-plus" /> Add Data</Button>*/}
                    </div>

                    <div className="center">
                        <h1>Commercial Reimbursement Benchmarks</h1>
                    </div>
                </PageMenu> : <Breadcrumb crumbs={[{name: 'Home', url: '/'}, {name: 'Commercial Reimbursement Benchmarks', url: '/cr-benchmarks'}, {name: 'Overview', url: '/cr-benchmarks/overview'}]} />}

                <div className={useNewDesign ? 'mt-5' : 'mt-10 container'}>
                    <MarkdownContent file="crb/overview.md" />
                </div>
            </div>
        </AuthWall>
    </Template>;
}