import {observer} from 'mobx-react';
import {useCallback, useContext} from 'react';
import FilterStepsContext from '../../../../../../components/filter-steps/libs/context';
import {QueryObj} from '../../../../../../lib/data-types';
import {deserialize, GraphVersions, TestType} from 'quantum-graph';
import fetchData from '../../../../../../lib/data';
import HPTLoading from '../../../../../../components/filter-steps/loading';
import EntityList from '../../../../../../components/entity-list';
import FilterStepMultiDropdown from '../../../../../../components/filter-steps/common-steps/filter-step-multi-dropdown';

const HPTProviderGroupListSection = observer(() => {
    const filterStepsStore = useContext(FilterStepsContext);

    const searchColumns = [
        {name: 'Organization Name', value: 'organization_legal_name', threshold: .3},
        {name: 'Group PAC Id', value: 'group_pac_id_or_placeholder', threshold: 0.0},
    ];

    const fetchProviderGroups = useCallback(() => {
        let query: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'provider-group',
                            dataset: 'pt-providergroup',
                            columns: [
                                'torch_provider_group_id',
                                'organization_legal_name',
                                'group_pac_id_or_placeholder'
                            ]
                        },
                        {
                            id: 'hpt-fact',
                            dataset: 'pt-servicelinecffactrecent-payer-professional',
                            columns: []
                        }
                    ]
                }
            },
            sort: [{
                column: {
                    nodeId: 'provider-group',
                    column: 'organization_legal_name'
                },
                asc: true
            }],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'torchprovidergroupid'
                    },
                    test: TestType.NEq,
                    value: -1
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'payer'
                    },
                    test: 'is not null',
                    value: 1
                }
            ],
            distinct: true
        };

        return fetchData(deserialize(query), new AbortController().signal);
    }, [filterStepsStore.useUATEnvironment]);

    return <div className='relative'>
        <div className='mt-5 relative'>
            <HPTLoading />
            <EntityList values={filterStepsStore.providerGroupList}
                onChange={pg => filterStepsStore.providerGroupList = pg}
                onLoadEntities={fetchProviderGroups}
                displayName='Provider Groups'
                displayColumn='organization_legal_name'
                searchColumns={searchColumns}
                additionalSubText='Add additional provider groups outside your search to the query.'
                maxEntities={filterStepsStore.MAX_ENTITIES}/>
        </div>
    </div>;
});

const ProviderGroupFilterStep = observer(() => {
    const filterStepsStore = useContext(FilterStepsContext);
    return <div className="z-40">
        <div className="w-full flex justify-between">
            <div className="w-1/3">
                <FilterStepMultiDropdown label="Primary Group Specialty Type"
                    filterStoreVariable="providerGroupType"
                    variable="primary_csspec_desc"
                    getDropdownQuery={(v) => filterStepsStore.getProviderGroupDropdownQuery(v)}
                    placeholder='Type or Choose Provider Group Type'
                    popupWidth={410}
                    resyncVariables={['geographies', 'useUATEnvironment']}/>
            </div>
            <div className="w-1/3 flex flex-col">
                <label>Full-Time Employee (FTE)</label>
                <div className='flex space-x-3 items-center'>
                    <input style={{width: '100px', minWidth: '0'}} type='number' placeholder='Minimum'
                        value={filterStepsStore.minNumFTEs}
                        onChange={e => filterStepsStore.minNumFTEs = Number(e.target.value)} />
                    <div>to</div>
                    <input style={{width: '100px', minWidth: '0'}} type='number' placeholder='Maximum'
                        value={filterStepsStore.maxNumFTEs}
                        onChange={e => filterStepsStore.maxNumFTEs = Number(e.target.value)} />
                </div>
            </div>
        </div>
        <HPTProviderGroupListSection />

    </div>;
});

export default ProviderGroupFilterStep;

