
const POMDatasets = {
    Msa: 'percent-of-medicare-benchmarks-msa-division',
    MsaCategory: 'percent-of-medicare-benchmarks-msa-division-category',
    MsaDrg: 'percent-of-medicare-benchmarks-msa-division-drg',
    MsaMdc: 'percent-of-medicare-benchmarks-msa-division-mdc',
    State: 'percent-of-medicare-benchmarks-state',
    StateCategory: 'percent-of-medicare-benchmarks-state-category',
    StateDrg: 'percent-of-medicare-benchmarks-state-drg',
    StateMdc: 'percent-of-medicare-benchmarks-state-mdc',
};

const LATEST_YEAR = 2024;
export {POMDatasets, LATEST_YEAR};