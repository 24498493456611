import {useContext} from 'react';
import Modal from '../../../../components/modal';
import {observer} from 'mobx-react';
import {CanvasContext} from '../../lib/context';
import {RowLayouts} from '../../lib/types';

function RowLayoutModal() {
    const store = useContext(CanvasContext);

    function addContentRow(layout: RowLayouts) {
        store.addContentRow(layout);
        store.toggleRowLayoutModal();
    }

    return <Modal title="Select Row Layout" onClose={() => store.toggleRowLayoutModal()}>
        <div>
            <ul>
                {Object.values(RowLayouts).map((layout, index) => <li key={index} className="rounded-lg p-2 cv-row hover:cursor-pointer hover:bg-medinsight-vibrant-blue-100" onClick={() => addContentRow(layout)}>
                    {layout.split('-').map((size, index) => <div key={index} className={`${size} h-32 w-auto border-dashed border-4 rounded-lg bg-gray-200 flex justify-center items-center bg-opacity-30`}>
                        <h2>{size}</h2>
                    </div>)}
                </li>)}
            </ul>
        </div>
    </Modal>;
}

export default observer(RowLayoutModal);