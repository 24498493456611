import React from 'react';
import Button from '../../../components/button';
import genQuery from './images/generate-query.png';
import {Link} from 'react-router-dom';
import SiteName from '../../../components/SiteName';

export default function APIDocQueryConstruction() {
    return <div>
        <h2>Query Construction</h2>

        <p>
            Using queries, you can extract data from the <SiteName /> data warehouse. Queries are JSON objects
            describing what data should be pulled along with any manipulation or presentation of that data. Our data
            queries support most common database operations.
        </p>

        <h3>Two Versions of Queries</h3>

        <p>
            There are two versions of query objects that you can use to retrieve data: versions <code>1</code> and <code>2</code>.
            It is recommended that you use version <code>2</code> as it can do everything version <code>1</code> can, but with
            additional functionality and an easier format.
        </p>
        <p>
            If you are manually authoring a query, please refer to the reference pages:
        </p>
        <ul className="list-disc list-inside">
            <li><Link to="/docs/api/v1-reference">v1 Query Reference</Link></li>
            <li><Link to="/docs/api/v2-guide">v2 Authoring Guide</Link></li>
        </ul>

        <h3>Building a Query in <SiteName /></h3>
        <p>
            The easiest way to build a query is within the <SiteName /> website. Using our tools, build out the data
            you want to pull, including filters. Once your data is constructed to your satisfaction, click
            the <strong>Download</strong> button at the bottom right corner of the table. Then select <strong>Generate API Query</strong>. This
            will then show you the JSON query to use.
        </p>
        <div className="text-center my-6">
            <img src={genQuery} alt="Generate API Query" className="inline-block" />
        </div>


        <hr/>
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/api/perform-query">Performing Data Queries</Button>
        </p>
    </div>;
}