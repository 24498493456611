import Variable from '../../metadata';
import {QuantumGraph, TestType} from 'quantum-graph';
import Filter from '../../filter';
import Library from '../../library';

export type Override = {
    field: string,
    test: TestType,
    value: number | string | Array<number | string>,
};

export default abstract class GraphHandler<GraphType extends QuantumGraph> {

    protected graph: GraphType;

    constructor(graph: GraphType) {
        this.graph = graph;
    }

    abstract roughEquals(graph: GraphType): boolean;
    abstract getDatasets(): Array<string>;
    abstract getSort(metadata: Array<Variable>): Array<[Variable, boolean]>;
    abstract applySort(sorts: Array<[Variable, boolean]>): this;
    abstract getFilters(metadata: Array<Variable>, library: Library): Array<Filter>;
    abstract applyFilters(filters: Array<Filter>): this;
    abstract removeFilter(filter: Filter, ignoreValue?: boolean): this;
    abstract applyPagination(page: number, pageSize: number): this;
    abstract removePagination(): this;
    abstract assembleVariables(metadata: Array<Variable>): Array<{connectingDim: string, variable: Variable}>;
    abstract applyOverride(override: Override): this;

    abstract build(): QuantumGraph;
    abstract buildCount(): QuantumGraph;
    abstract buildDistinct(variable: Variable): QuantumGraph;
    abstract buildCountDistinct(variable: Variable): QuantumGraph;
    abstract getDatasetFromVariable(variable: string): string;
    abstract extractVariables(): Array<{dataset: string, variable: string}>;
    abstract applyScaffoldField(field: string): void;
    abstract applyScaffoldType(type: string | undefined): void;
    abstract getScaffoldType(): string | undefined;

    serialize() {
        return this.build().serialize();
    }

}