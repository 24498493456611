import {GraphVersions, JoinType, TestType} from 'quantum-graph';
import {LATEST_YEAR, POMDatasets} from './datasets';
import {QueryObj} from '../../../../lib/data-types';

export function buildDrgQuery(regionType: string, metricType: string, region: string, fields: Array<string>): QueryObj {
    const dataset = regionType === 'msa' ? POMDatasets.MsaDrg : POMDatasets.StateDrg;
    const regionField = regionType === 'msa' ? 'msa_division' : 'state_fips';

    return {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: 'dim',
                tableNodes: [
                    {
                        id: 'drg',
                        dataset: 'drg',
                        columns: [
                            'drg',
                            'diagnosis_related_group_description',
                            'major_diagnostic_category',
                            'major_diagnostic_category_description',
                        ],
                    }, {
                        id: 'pom',
                        dataset,
                        columns: [...(regionType === 'msa' ? ['msa_division', 'msa_division_name'] : []), ...fields],
                    }
                ],
            },
            joins: regionType === 'msa' ? [] : [{
                node: {
                    baseQuery: {
                        queryType: 'dim',
                        tableNodes: [{id: 'state', dataset: 'state', columns: ['state']}]
                    }
                },
                joinType: JoinType.Left,
                joinNodeId: 'pom',
            }],
        },
        sort: [{
            column: {nodeId: 'drg', column: 'drg'},
            asc: true,
        }],
        filters: [
            {
                column: {nodeId: 'pom', column: 'metric_type'},
                test: TestType.Eq,
                value: metricType,
            },
            {
                column: {nodeId: 'pom', column: 'year_key'},
                test: TestType.Eq,
                value: LATEST_YEAR,
            },
            {
                column: {nodeId: 'pom', column: regionField},
                test: TestType.Eq,
                value: region,
            },
            {
                column: {nodeId: 'pom', column: 'under_age_65_pop'},
                test: TestType.NotNull,
                value: 0,
            },
        ],
    };
}
