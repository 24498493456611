import {useCallback, useContext} from 'react';
import {observer} from 'mobx-react';
import {deserialize, GraphVersions, TestType} from 'quantum-graph';
import FilterStepsContext from '../../../../../../components/filter-steps/libs/context';
import {QueryObj} from '../../../../../../lib/data-types';
import fetchData from '../../../../../../lib/data';
import HPTLoading from '../../../../../../components/filter-steps/loading';
import EntityList from '../../../../../../components/entity-list';
import FilterStepMultiDropdown from '../../../../../../components/filter-steps/common-steps/filter-step-multi-dropdown';
import {generateStandardFacilityFilters} from '../query';


const HptFacilityFilterStep = observer(() => {

    return <div className='flex flex-col space-y-12'>
        <FilterSection />
        <HPTFacilityListSection />
    </div>;
});

const HPTFacilityListSection = observer(() => {
    const filterStepsStore = useContext(FilterStepsContext);

    const searchColumns = [
        {name: 'Facility', value: 'facility_name', threshold: .3},
        {name: 'Health System', value: 'owner_health_system_name', threshold: .3},
        {name: 'CCN', value: 'provider_number', threshold: 0.0},
    ];

    const fetchFacilities = useCallback(() => {
        const query: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'hospital',
                            dataset: 'pt-facility',
                            columns: [
                                'torch_facility_id',
                                'facilityid',
                                'facility_name',
                                'torch_health_system_name',
                                'provider_number',
                            ],
                        }
                    ],
                },
            },
            sort: [
                {
                    column: {
                        nodeId: 'hospital',
                        column: 'facility_name',
                    },
                    asc: true,
                },
            ],
            filters: generateStandardFacilityFilters(filterStepsStore.dataView),
            distinct: true,
        };

        if (filterStepsStore.dataView === 'payer')
            query.filters!.push({
                type: 'basic',
                column: {
                    nodeId: 'hospital',
                    column: 'torch_facility_id',
                },
                test: TestType.NEq,
                value: -1,
            });

        return fetchData(deserialize(query), new AbortController().signal);
    }, [filterStepsStore.dataView, filterStepsStore.useUATEnvironment]);

    return <div className='relative'>
        <div className='mt-5 relative'>
            <HPTLoading />
            <EntityList values={filterStepsStore.facilityList}
                onChange={facs => filterStepsStore.facilityList = facs}
                onManuallyAddedEntity={facs => filterStepsStore.manuallyAddedFacilities = facs}
                onLoadEntities={fetchFacilities}
                displayName='facilities'
                displayColumn='facility_name'
                searchColumns={searchColumns}
                additionalSubText='Add additional facilities outside your search to the query.'
                maxEntities={filterStepsStore.MAX_ENTITIES}/>
        </div>
    </div>;
});

const FilterSection = observer(() => {
    const filterStepsStore = useContext(FilterStepsContext);

    return <div className='w-full flex justify-between z-40'>
        <FilterStepMultiDropdown label='Facility Type' filterStoreVariable='facilityTypes' 
            variable='milliman_facility_type'
            getDropdownQuery={(v) => filterStepsStore.getFacilityDropDownQuery(v)}
            placeholder='Type or Choose Facility Type' popupWidth={400}
            resyncVariables={['geographies', 'dataView', 'useUATEnvironment']} />
        <div className='flex flex-col'>
            <label>Number of Beds</label>
            <div className='flex space-x-3 items-center'>
                <input style={{width: '75px', minWidth: '0'}} type='number' placeholder='Minimum'
                    value={filterStepsStore.minNumBeds}
                    onChange={e => filterStepsStore.minNumBeds = Number(e.target.value)} />
                <div>to</div>
                <input style={{width: '75px', minWidth: '0'}} type='number' placeholder='Maximum'
                    value={filterStepsStore.maxNumBeds}
                    onChange={e => filterStepsStore.maxNumBeds = Number(e.target.value)} />
            </div>
        </div>
        <FilterStepMultiDropdown label='Health System' filterStoreVariable='healthSystems'
            variable='torch_health_system_name' placeholder='Type or Choose Health System'
            popupWidth={400} getDropdownQuery={(v) => filterStepsStore.getFacilityDropDownQuery(v)}
            resyncVariables={['geographies', 'dataView', 'useUATEnvironment']} />
    </div>;
});

export default HptFacilityFilterStep;