import React, {useMemo} from 'react';
import AdminCrudTable from '../crud-table';
import {LinkType} from '../../../lib/links';
import {canvas} from '../../../lib/api';

export default function CanvasAdmin() {
    const fields = useMemo(() => [
        {name: 'id', label: 'Id', show: true, type: 'numeric'},
        {name: 'title', label: 'Title', show: true, type: 'text', linkType: LinkType.CanvasDraft},
        {name: 'userId', label: 'Owner', show: false, type: 'user'},
        {name: 'notes', label: 'Notes', show: true, type: 'text'},
        {name: 'orgId', label: 'Organization', show: false, type: 'org'}
    ], []);

    return <AdminCrudTable api={canvas} title={'Canvas'} urlPath={'/site-admin/canvases'} fields={fields} />;
}