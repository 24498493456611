import {FilterStep} from '../../../../components/filter-steps/libs/types';
import GeographyFilterStep from '../../../../components/filter-steps/common-steps/geography-filter-step';
import PopulationFilterStep from './steps/population-filter-step';
import ServiceGrainFilterStep from './steps/service-grain-filter-step';
import {useContext, useEffect, useState} from 'react';
import {Entity} from '../../../../lib/data-types';
import {observer} from 'mobx-react';
import FilterStepsContext from '../../../../components/filter-steps/libs/context';
import {hcgAllowedGeographiesFilter} from '../queries';
import fetchData from '../../../../lib/data';
import Loading from '../../../../components/loading';

const yearKey = '2019';

const HCGGeographyFilterStep = observer(() => {
    const hcgFilterStore = useContext(FilterStepsContext);
    const [allowedValues, setAllowedValues] = useState<Array<Entity> | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [cbsaData, setCBSAData] = useState<Array<Entity>>([]);

    useEffect(() => {
        let controller = new AbortController();
        if (hcgFilterStore.geographyType === 'cbsa' && !allowedValues?.length) {
            if (!cbsaData.length) {
                setLoading(true);
                let query = hcgAllowedGeographiesFilter(yearKey);
                fetchData(query, controller.signal).then(data => {
                    let cbsaEntities = data.map(row => ({
                        id: row['cbsa'] as number,
                        key: -1,
                        name: ''
                    }));
                    setAllowedValues(cbsaEntities);
                    setCBSAData(cbsaEntities);
                }).finally(() => setLoading(false));
            } else
                setAllowedValues(cbsaData);
        } else {
            setAllowedValues(undefined);
        }

        return () => controller.abort();
    }, [hcgFilterStore.geographyType]);

    return <div className="relative">
        <GeographyFilterStep geographies={['National', 'CBSA', 'State']}
            subtext="Click regions on the map to limit data to the selected regions."
            allowedValues={allowedValues}
            yearKey={yearKey}/>
        {loading && <div className="absolute top-0 left-0 z-40 w-full h-full bg-opacity-70 bg-white flex justify-center items-center"><Loading large /></div>}
    </div>;
});


const HCGFilterStepsConfig: Array<FilterStep> = [
    {
        name: 'Select Geography',
        subText: 'You can also select regions by clicking on the map.',
        required: true,
        step: HCGGeographyFilterStep
    },
    {
        name: 'Select Population',
        subText: '',
        required: false,
        step: PopulationFilterStep
    },
    {
        name: 'Select Service Details',
        subText: '',
        required: false,
        step: ServiceGrainFilterStep
    }
];

export default HCGFilterStepsConfig;