import {ReactElement, ReactNode, cloneElement} from 'react';
import './side-nav.scss';

type Props = {
    name:string,
    icon: ReactElement,
    children: ReactNode,
    isOpen:boolean,
    toggle:(sectionName:string)=>void
}

export default function SideNavSection(props:Props) {
    const {icon, name, children, isOpen, toggle} = props;

    const iconWithClass = cloneElement(icon, {
        className: 'sidebar-icon',
    });

    return(
        <div className='sidebar-section' onClick={()=>toggle(name)}>
            {iconWithClass}
            <span className="sidebar-title">{name}</span>  
            <span className={`toggle-icon ${isOpen ? 'icon-up' : 'icon-down'}`} />      
            <div className={`sidebar-children ${isOpen ? 'open' : 'closed'}`}>
                {children}
            </div>
        </div>
    );
}