import {observer} from 'mobx-react';
import {useContext, useState, useMemo, useEffect} from 'react';
import {CanvasContext} from '../lib/context';
import {searchBoard} from '../lib/search';
import {useParams, useLocation} from 'react-router-dom';
import {loadParameterValues} from '../lib/parameters';
import {CanvasBoardJSON} from '../lib/types';
import handleError from '../../../lib/error';
import ErrorMessage from '../../../components/error-message';
import Spinner from '../../../components/spinner';


const MIN_SEARCH_VALUE_LENGTH = 2;
function CvSearchBar() {
    const store = useContext(CanvasContext);
    const {id, title} = useParams<{ id?: string; title?: string; section?: string }>();
    const search = useLocation().search;
    const paramValues = useMemo(() => loadParameterValues(search), [search, id, title]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [err, setErr] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [timeoutValue, setTimeoutValue] = useState<any>(null); // Add timeout state
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (timeoutValue)
            clearTimeout(timeoutValue);
        if (searchValue.length > MIN_SEARCH_VALUE_LENGTH) {
            setLoading(true);
            setTimeoutValue(setTimeout(() => generateSearchResults(), 1000));
        } else if (!searchValue && !store.loading)
            clearSearchResults();
    }, [searchValue]);

    async function generateSearchResults() {
        const controller = new AbortController();
        const searchResults: CanvasBoardJSON | null = searchBoard(searchValue, store.pristineBoard);
        if (searchResults) {
            store
                .loadBoardForSearch(JSON.stringify(searchResults), paramValues, controller.signal)
                .catch((e) => handleError(e, setErr));
        }
        setLoading(false);
    }

    async function clearSearchResults() {
        const controller = new AbortController();
        await store.load(paramValues, controller.signal).catch((e) => handleError(e, setErr));
        setLoading(false);
    }

    return (
        <div className="w-full flex space-x-3 items-center">
            <div className="w-full inline-block relative border-blue-500 border-b-2">
                <i className="icon-search absolute top-0 left-3 h-full pre-3 flex items-center"></i>
                <input
                    type="search"
                    className="!pl-11 !border-0 !bg-gray-100 w-full"
                    placeholder="Search this canvas"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                {loading && <div className="absolute top-3 right-10">
                    <Spinner />
                </div>}
            </div>
            {err && <ErrorMessage error={err}/>}
        </div>
    );
}

export default observer(CvSearchBar);

