import Select from '../../../../components/select';

const grantTypeFields = [
    {name: 'Read', value: '1'},
    {name: 'Write', value: '2'}
];

type Props = {
    value: string | null,
    onChange: (value: string) => void
}

export default function GrantTypeInput(props: Props) {
    const {value, onChange} = props;

    return <Select data={grantTypeFields} value={value || '1'} onChange={onChange}>
        {_ => _.value}
        {_ => _.name}
    </Select>;
}