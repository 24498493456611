import React, {useContext, useEffect, useMemo, useState} from 'react';
import Loading from '../loading';
import {BasicSavedDataType, GroupedSavedData} from './types';
import SavedDataNav from './saved-data-nav';
import Button from '../button';
import loadSavedData, {savedDataTypes} from './load';
import handleError from '../../lib/error';
import ErrorMessage from '../error-message';
import {insightContainer, savedQuery} from '../../lib/api';
import checkAccess, {SiteFeature} from '../../lib/check-access';
import {HeaderContext} from '../header/context';
import {TrashCan} from '@carbon/icons-react';

type Props = {
    allowDelete?: boolean,
    allowCreateVis?: boolean,
    onSelect: (type: 'query' | 'container', id: number, reference?: string) => void,
}

export default function SavedDataBrowser(props: Props) {
    const {allowDelete, onSelect} = props;
    const [savedData, setSavedData] = useState<GroupedSavedData>({});
    const [activeSection, setActiveSection] = useState('saved-query');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [hoveredRow, setHoveredRow] = useState<number | null>(null);
    const headerStore = useContext(HeaderContext);

    useEffect(() => {
        const controller = new AbortController();
        loadSavedData(controller.signal).then(savedData => {
            setSavedData(savedData);
            setLoading(false);
        }).catch(err => handleError(err, setError));
        return () => controller.abort();
    }, []);

    const sections = useMemo(() => {
        return savedDataTypes.filter(type => !!savedData[type]);
    }, [savedData]);

    function select(saved: BasicSavedDataType) {
        onSelect(saved.type ? 'container' : 'query', saved.id, saved.reference);
    }

    function deleteSaved(saved: BasicSavedDataType) {
        if (window.confirm('Are you sure you want to delete?')) {
            if (saved.type)
                insightContainer.delete(saved.id).catch(err => handleError(err, setError));
            else
                savedQuery.delete(saved.id).catch(err => handleError(err, setError));

            const newSaved = Object.assign({}, savedData);
            const idx = newSaved[activeSection].findIndex(item => item.id === saved.id);
            newSaved[activeSection].splice(idx, 1);
            setSavedData(newSaved);
        }
    }

    const allowCreateVis = props.allowCreateVis && checkAccess(SiteFeature.CreateVis);
    const showActions = allowDelete || allowCreateVis;

    function legacySavedDataBrowser() {
        return <div>
            {error && <ErrorMessage error={error} />}
            {loading ?
                <Loading /> :
                <div>
                    <SavedDataNav onChange={setActiveSection} sections={sections} activeSection={activeSection} />
                    {savedData[activeSection] ?
                        <div className="insight-table mt-5">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Saved Data</th>
                                        <th>Date Created</th>
                                        {showActions && <th>Actions</th>}
                                    </tr>
                                </thead>

                                <tbody>
                                    {savedData[activeSection].map(saved => <tr key={saved.id}>
                                        <td><Button color="link" onClick={() => select(saved)}>{saved.name}</Button></td>
                                        <td>{saved.dateCreated && new Date(saved.dateCreated).toLocaleDateString()}</td>
                                        {showActions && <td>
                                            {allowDelete && <Button color="red" onClick={() => deleteSaved(saved)}><i className="icon-trash" /></Button>}
                                            {allowCreateVis && <Button color="green" to={`/vis-builder/${saved.id}`}><i className="icon-chart-bar" /></Button>}
                                        </td>}
                                    </tr>)}
                                </tbody>
                            </table>
                        </div> :
                        <div>You don't have any saved data</div>}
                </div>}
        </div>;
    }

    function dppSavedDataBrowser() {

        return <div>
            {error && <ErrorMessage error={error} />}
            {loading ?
                <Loading /> :
                <div>
                    <SavedDataNav onChange={setActiveSection} sections={sections} activeSection={activeSection} />
                    {savedData[activeSection] ?
                        <div className="dpp-insight-table mt-5">
                            <table className='table-auto'>
                                <thead>
                                    <tr>
                                        <th className='w-2/3'>Saved Data</th>
                                        <th>Date Created</th>
                                        {showActions && <th className='w-8'></th>}
                                    </tr>
                                </thead>

                                <tbody>
                                    {savedData[activeSection].map((saved, i) => <tr onMouseOver={() => setHoveredRow(i)} onMouseLeave={() => setHoveredRow(null)} key={saved.id}>
                                        <td onClick={() => select(saved)} ><Button color="link" className='!p-0' >{saved.name}</Button></td>
                                        <td>{saved.dateCreated && new Date(saved.dateCreated).toLocaleDateString()}</td>
                                        {showActions && <td>
                                            {allowDelete && i === hoveredRow && <TrashCan onClick={() => deleteSaved(saved)} size={16} />}
                                        </td>}
                                    </tr>)}
                                </tbody>
                            </table>
                        </div> :
                        <div>You don't have any saved data</div>}
                </div>}
        </div>;
    }

    return headerStore.useNewDesign ? dppSavedDataBrowser() : legacySavedDataBrowser();
}