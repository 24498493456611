import {observer} from 'mobx-react';
import {useContext, useEffect, useMemo, useState} from 'react';
import MapSelection from '../../map-selection';
import Select from '../../../components/select';
import MultiEntityAutocomplete from '../../../components/multi-entity-autocomplete';
import FilterStepsContext from '../libs/context';
import {Entity} from '../../../lib/data-types';

type Props = {
    geographies: Array<string>,
    yearKey: string,
    subtext?: string,
    allowedValues?: Array<Entity>
}

const GeographyFilterStep = observer((props: Props) => {
    const {geographies, subtext, allowedValues, yearKey} = props;
    const filterStepsStore = useContext(FilterStepsContext);
    const [mapLoading, setMapLoading] = useState<boolean>(true);
    const geographyOptions = useMemo(() => {
        return geographies.map(geo => ({
            name: geo,
            value: geo.toLowerCase()
        }));
    }, [geographies]);

    useEffect(() => {
        if (!filterStepsStore.geographyType)
            filterStepsStore.geographyType = geographies[0];
    }, [geographies, filterStepsStore]);

    const orderedGeographies = useMemo(() => {
        if (!filterStepsStore.geographyType)
            return [];
        return [
            filterStepsStore.geographyType,
            ...geographies.map(geo => geo.toLowerCase()).filter(geo => geo !== 'national' && geo !== filterStepsStore.geographyType)
        ];
    }, [geographies, filterStepsStore.geographyType]);

    return <div className="w-full pb-5">
        <div className="flex space-x-12 my-5">
            <div className="flex flex-col space-y-1">
                <label>Geography</label>
                <Select data={geographyOptions} value={filterStepsStore.geographyType} onChange={geoType => filterStepsStore.geographyType = geoType} disabled={mapLoading}>
                    {_ => _.value}
                    {_ => _.name}
                </Select>
            </div>
            <div className="w-1/2 flex flex-col space-y-1">
                <label>Selected Regions</label>
                <MultiEntityAutocomplete datasets={orderedGeographies} values={filterStepsStore.geographies.map((g: {name: string, id: number, key: number, type: string}) => ({id: g.id, name: g.name, key: g.key, dataset: g.type}))}
                    onChange={geoInfos => filterStepsStore.geographies = geoInfos.map(g => ({id: g.id, name: g.name, key: g.key, type: g.dataset}))}
                    width={550}
                    placeholder="Choose the regions you want to include"
                    disabled={filterStepsStore.geographyType === 'national'}/>
            </div>
        </div>
        {filterStepsStore.geographyType && <div className="map-selection overflow-hidden">
            <MapSelection shape={filterStepsStore.geographyType}
                values={filterStepsStore.geographies}
                onClick={geoInfos => filterStepsStore.geographies = geoInfos.map(g => ({...g, type: filterStepsStore.geographyType}))} zoom={3.2}
                year={yearKey}
                onLoad={setMapLoading}
                allowedValues={allowedValues}
                geoRestrictionModules={filterStepsStore.geoRestrictionModules}/>
            {subtext && <small>{subtext}</small>}
        </div>}
    </div>;
});

export default GeographyFilterStep;