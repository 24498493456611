import {observer} from 'mobx-react';
import {useContext} from 'react';
import FilterStepsContext from './libs/context';


const ClearAll = observer(() => {
    const filterStepsStore = useContext(FilterStepsContext);

    return <div className="absolute top-0 right-5 text-blue-500 flex space-x-3 items-center hover:cursor-pointer" onClick={() => filterStepsStore.clearAll()}>
        <i className="icon-reset" />
        <span>Reset All Filters</span>
    </div>;
});

export default ClearAll;