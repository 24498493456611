import {observer} from 'mobx-react';
import {useContext, useEffect, useState} from 'react';
import {CanvasInsertContext} from '../lib/context';
import Select from '../../../components/select';
import Checkbox from '../../../components/checkbox';
import MultiSelectAutocomplete from '../../../components/multi-select-autocomplete';
import {fetchMetadata} from '../../../lib/data';
import Variable from '../../../lib/metadata';


function CvParameterEdit() {
    const insertStore = useContext(CanvasInsertContext);
    const [variableList, setVariableList] = useState<Array<{label: string, value: Variable}>>([]);

    useEffect(() => {
        let controller = new AbortController();
        if (insertStore.query) {
            fetchMetadata(insertStore.query, controller.signal).then(vars => {
                setVariableList(vars.map(v => ({label: v.label, value: v})));
                insertStore.parameterIdField = insertStore.parameterIdField || vars[0].name;
                insertStore.parameterSearchFields = insertStore.parameterSearchFields || vars[0];
            });
        }

        return () => controller.abort();
    }, [insertStore.query]);

    if (!insertStore.query)
        return null;

    return <div className="w-full flex space-x-32">
        <div className="w-1/3 flex flex-col space-y-8">
            <div className="flex flex-col space-y-3">
                <label>Id Variable</label>
                <div>
                    <Select data={variableList} value={insertStore.parameterIdField} onChange={v => insertStore.parameterIdField = v}>
                        {_ => _.value.name}
                        {_ => _.label}
                    </Select>
                </div>
            </div>
            <div className="flex flex-col space-y-3">
                <label>Search Variable</label>
                <MultiSelectAutocomplete values={insertStore.parameterSearchFields.map(v => v.label)} onChange={sf => insertStore.parameterSearchFields = sf.map(v => variableList.find(vl => vl.value.label === v)!.value)} getOptions={async () => variableList.map(v => v.label)} />
            </div>
        </div>
        <div className="w-1/3 flex flex-col space-y-8">
            <div className="flex flex-col space-y-3">
                <label>Minimum Characters Before Searching</label>
                <input type='number' defaultValue={4} onChange={(e)=>{
                    insertStore.parameterMinSearchChars = Number(e.target.value);
                }}/>
            </div>
            <div className="flex flex-col space-y-3">
                <label>Debounce Timing</label>
                <small style={{width: '300px'}}>Amount of time between an input changing and the search field sending a query (in milliseconds).</small>
                <input type='number' defaultValue={550} onChange={(e)=>{
                    insertStore.parameterKeyDelay = Number(e.target.value);
                }}/>
            </div>
            <div className="flex flex-col space-y-3">
                <label>Parameter Display Template</label>
                <small style={{width: '300px'}}>Defines how the rows are displayed in the parameter search</small>
                <input type='text' value={insertStore.parameterDisplayTemplate} onChange={(e)=>{
                    insertStore.parameterDisplayTemplate = e.target.value;
                }}/>
            </div>
            <div className="flex flex-col">
                <div className="flex space-x-3">
                    <Checkbox color="blue" checked={insertStore.parameterFuzzySearch} onChange={() => insertStore.parameterFuzzySearch = !insertStore.parameterFuzzySearch} />
                    <label>Use Fuzzy Search</label>
                </div>
                <small>Avoid with large datasets</small>
            </div>
        </div>
    </div>;
}

export default observer(CvParameterEdit);
