import React, {useState} from 'react';
import Button from '../../../../components/button';
import ExampleQueryExecutor from './example-query-executor';

export default function QueryExample(props: {query: string, children: React.ReactNode}) {
    const {query, children} = props;
    const [run, setRun] = useState(false);
    return <div>
        {children}
        <pre>{query}</pre>
        <div>
            <Button color="blue" small onClick={() => setRun(true)}>Run Query in Browser</Button>
        </div>
        {run && <ExampleQueryExecutor query={query} onClose={() => setRun(false)} />}
    </div>;
}