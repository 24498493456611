import {useContext, useEffect, useMemo} from 'react';
import {CanvasContext, CanvasInsertContext} from '../../lib/context';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import {observer} from 'mobx-react';
import VisTypeSelector from './vis-type-select';
import QueryTypeSelector from './query-type-selector';
import QuerySelector from './query-selector';
import CanvasInsertStore from '../../lib/insert-store';
import {CvData, CvInsertScreen} from '../../lib/types';
import ModelVisData from './model-data';
import ConfigVis from './config-screens/config-vis';
import {deserialize} from 'quantum-graph';
import CanvasICList from './ic-configs';
import ConfigText from './config-screens/config-text';
import ConfigOwnership from './config-screens/config-ownership';
import MapQuerySelector from './config-screens/map-query-selection-screen';
import ColumnConfig from './config-screens/column-config';
import MapEditor from './map-editor';
import ConfigSingleValue from './config-screens/config-single-value';
import Steps from './steps';
import ConfigSankey from './config-screens/config-sankey';
import CanvasStore from '../../lib/store';
import ConfigConditionalRender from './config-screens/config-conditional-render';
import ConfigInaccessible from './config-screens/config-inaccessible';
import {HeaderContext} from '../../../../components/header/context';
import {Button as CarbonButton} from '@carbon/react';
import {ArrowLeft, ArrowRight, TrashCan} from '@carbon/icons-react';

const screens:Record<CvInsertScreen, JSX.Element> = {
    [CvInsertScreen.type]: <VisTypeSelector />,
    [CvInsertScreen.queryType]: <QueryTypeSelector />,
    [CvInsertScreen.querySelect]: <QuerySelector />,
    [CvInsertScreen.model]: <ModelVisData />,
    [CvInsertScreen.configVis]: <ConfigVis />,
    [CvInsertScreen.configText]: <ConfigText />,
    [CvInsertScreen.configOwnership]: <ConfigOwnership />,
    [CvInsertScreen.mapQuerySelection]: <MapQuerySelector />,
    [CvInsertScreen.columnConfig]: <ColumnConfig/>,
    [CvInsertScreen.mapConfig]: <MapEditor/>,
    [CvInsertScreen.configSingleValue]: <ConfigSingleValue />,
    [CvInsertScreen.configSankey]: <ConfigSankey/>,
    [CvInsertScreen.conditionalRender]: <ConfigConditionalRender />,
    [CvInsertScreen.configInaccessible]: <ConfigInaccessible />
};

function setupEditParameterScreen(insertStore: CanvasInsertStore, canvasStore: CanvasStore) {
    if (canvasStore.parameter) {
        insertStore.query = deserialize(canvasStore.parameter.query);
        insertStore.parameterIdField = canvasStore.parameter.idField;
        insertStore.parameterSearchField = canvasStore.parameter.searchField;
        insertStore.parameterFuzzySearch = canvasStore.parameter.useFuzzySearch;
        insertStore.goToVisScreen(CvInsertScreen.model);
    }
}

function setupEditConditionalRenderScreen(insertStore: CanvasInsertStore, canvasStore: CanvasStore) {
    const currentCell = canvasStore.getCurrentCell();
    const currentRowConditionalRender = canvasStore.getRowConditionalRender(canvasStore.addRowIndex);
    if (canvasStore.addConditionalRenderCell && currentCell && currentCell.conditionalRender) {
        insertStore.query = deserialize(currentCell.conditionalRender.queryObj);
        insertStore.conditionalRenderOptions = currentCell.conditionalRender;
        insertStore.goToVisScreen(CvInsertScreen.model);
    } else if (canvasStore.addConditionalRenderRow && currentRowConditionalRender) {
        insertStore.query = deserialize(currentRowConditionalRender.queryObj);
        insertStore.conditionalRenderOptions = currentRowConditionalRender;
        insertStore.goToVisScreen(CvInsertScreen.model);
    }
}

function setupEditConditionalRenderAlternativeIC(insertStore: CanvasInsertStore, canvasStore: CanvasStore) {
    const currentCell = canvasStore.getCurrentCell();
    if (currentCell && currentCell.conditionalRender?.alternateIC && currentCell.conditionalRender?.alternateIC.type === 'data') {
        setupDefaultICEdit(currentCell.conditionalRender.alternateIC, insertStore);
        insertStore.goToVisScreen(CvInsertScreen.model);
    }
}

function setupEditScreen(insertStore: CanvasInsertStore, canvasStore: CanvasStore) {
    const currentCell = canvasStore.getCurrentCell();
    if (currentCell && currentCell.type === 'data') {
        let currentDataCell = currentCell as CvData;
        setupDefaultICEdit(currentDataCell, insertStore);
        insertStore.goToVisScreen(CvInsertScreen.model);
    }
}

function setupDefaultICEdit(currentCell: CvData, insertStore: CanvasInsertStore) {
    if (currentCell.queryObj) {
        let query = currentCell.queryObj;
        if (Array.isArray(currentCell.queryObj))
            query = currentCell.queryObj[0];
        insertStore.query = deserialize(query);
        insertStore.visConfig = CanvasICList.find(ic => ic.type === currentCell.visType);
        insertStore.parameterConfigs = currentCell.parameterConfigs || [];
        if (currentCell.queryObj.baseQueryGraph && currentCell.queryObj.baseQueryGraph.joins.length)
            insertStore.isAffiliationQuery = true;
        insertStore.serializeOptions(currentCell.options);
    }
}

const InsertModal = observer(() => {
    const canvasStore = useContext(CanvasContext);
    const insertStore = useContext(CanvasInsertContext);
    const {useNewDesign} = useContext(HeaderContext);

    useEffect(() => {
        if (canvasStore.setupParameter && canvasStore.parameter)
            setupEditParameterScreen(insertStore, canvasStore);
        else if (canvasStore.addConditionalRenderCell || canvasStore.addConditionalRenderRow)
            setupEditConditionalRenderScreen(insertStore, canvasStore);
        else if (canvasStore.addConditionalRenderAlternativeIC)
            setupEditConditionalRenderAlternativeIC(insertStore, canvasStore);
        else
            setupEditScreen(insertStore, canvasStore);
    }, [canvasStore.addRowIndex]);

    let screenComponent = useMemo(() => screens[insertStore.screen], [insertStore.screen]);
    let configScreens = [CvInsertScreen.configVis, CvInsertScreen.configText, CvInsertScreen.configOwnership, CvInsertScreen.configSingleValue, CvInsertScreen.mapConfig, CvInsertScreen.configSankey];
    let isOnLastScreen = insertStore.visConfig?.screens.indexOf(insertStore.screen) === (insertStore.visConfig?.screens.length || 0) - 1;

    return <Modal 
        noCloseOnClickOutside 
        noCloseOnEsc 
        hasButtons 
        title={canvasStore.setupParameter ? 'Setup Canvas Parameter' : `Insert Content`} 
        onClose={() => canvasStore.closeInsert()} 
        className='large insert-modal flex flex-col' 
        headerClassName='scrollable-modal-header flex-initial' 
    >
        <div className='flex-initial'>
            <Steps />
        </div>
        <div className='flex-auto'>
            {screenComponent}
        </div>
        {useNewDesign && <div className='flex mt-8 justify-end mb-[-2rem] gap-4'> 
            {insertStore.screen > 0 && <CarbonButton size='sm' kind='secondary' onClick={() => insertStore.prevScreen()}>
                <ArrowLeft className='mr-2' /> Back
            </CarbonButton>}
            {configScreens.includes(insertStore.screen) && <CarbonButton size='sm' kind='primary' onClick={() => insertStore.nextScreen()}>
                <ArrowRight className='mr-2' /> {'Save Configuration'}
            </CarbonButton>}
            {isOnLastScreen && <CarbonButton size='sm' kind='primary' onClick={() => insertStore.applyInsert()}>
                <ArrowRight className='mr-2' /> {canvasStore.setupParameter ? 'Apply Parameter' : 'Add to Canvas'}
            </CarbonButton>}
            {canvasStore.setupParameter && insertStore.screen === CvInsertScreen.queryType && <CarbonButton size='sm' kind='danger' onClick={() => canvasStore.removeParameter()}>
                <TrashCan className='mr-2' /> Remove Canvas Parameter
            </CarbonButton>}
            {canvasStore.isAddConditionalRender() && insertStore.screen === CvInsertScreen.conditionalRender && <CarbonButton size='sm' kind='primary' onClick={() => insertStore.addConditionalRenderConfig()}>
                <ArrowRight className='mr-2' /> Add Conditional Render
            </CarbonButton>}
            {canvasStore.isAddConditionalRender() && insertStore.screen === CvInsertScreen.conditionalRender && <CarbonButton size='sm' kind='danger' onClick={() => insertStore.removeConditionalRenderConfig()}>
                <TrashCan className='mr-2' /> Remove Conditional Render
            </CarbonButton>}
            {canvasStore.addConditionalRenderAlternativeIC && insertStore.screen === CvInsertScreen.conditionalRender && <CarbonButton size='sm' kind='danger' onClick={() => canvasStore.removeConditionalRenderAlternativeIC()}>
                <TrashCan className='mr-2' /> Remove Conditional Render Alternative IC
            </CarbonButton>}
        </div>}
        {!useNewDesign && <div className="mb-no-absolute flex-initial">
            {insertStore.screen > 0 && <Button color="light" onClick={() => insertStore.prevScreen()} className="mb-2">
                <i className="icon-arrow-left" /> Back
            </Button>}
            {configScreens.includes(insertStore.screen) && <Button color="green" onClick={() => insertStore.applyInsert()} className="mb-2">
                <i className="icon-arrow-right" /> {canvasStore.setupParameter ? 'Apply Parameter' : 'Add to Canvas'}
            </Button>}
            {canvasStore.setupParameter && insertStore.screen === CvInsertScreen.queryType && <Button color="red" onClick={() => canvasStore.removeParameter()} className="mb-2">
                <i className="icon-trash" /> Remove Canvas Parameter
            </Button>}
            {canvasStore.isAddConditionalRender() && insertStore.screen === CvInsertScreen.conditionalRender && <Button color="green" className="mb-2" onClick={() => insertStore.addConditionalRenderConfig()}>
                <i className="icon-arrow-right" /> Add Conditional Render
            </Button>}
            {canvasStore.isAddConditionalRender() && insertStore.screen === CvInsertScreen.conditionalRender && <Button color="red" className="mb-2" onClick={() => insertStore.removeConditionalRenderConfig()}>
                <i className="icon-trash" /> Remove Conditional Render
            </Button>}
            {canvasStore.addConditionalRenderAlternativeIC && insertStore.screen === CvInsertScreen.conditionalRender && <Button color="red" className="mb-2" onClick={() => canvasStore.removeConditionalRenderAlternativeIC()}>
                <i className="icon-trash" /> Remove Conditional Render Alternative IC
            </Button>}
        </div>}
    </Modal>;
});

function CvInsertModal() {
    const canvasStore = useContext(CanvasContext);
    const store = useMemo(() => new CanvasInsertStore(canvasStore), []);

    return <CanvasInsertContext.Provider value={store}>
        <InsertModal />
    </CanvasInsertContext.Provider>;
}

export default observer(CvInsertModal);