import React, {useContext} from 'react';
import {CanvasContext} from '../lib/context';
import {observer} from 'mobx-react';
import {ConditionalRender, CvRowType} from '../lib/types';
import useConditionalRender from '../lib/useConditionalRender';
import Loading from '../../../components/loading';
import ConditionalRenderAlert from './conditional-render-alert';
import CvCellRenderer from './cv-cell-renderer';

type Props = {
    row: CvRowType,
    index: number,
    conditionalRender?: ConditionalRender | undefined
}

function CvRow(props: Props) {
    const {row, index, conditionalRender} = props;
    const store = useContext(CanvasContext);
    const size = store.rows.length;
    const [conditionMet, loading] = useConditionalRender(conditionalRender?.parameterConfigs, conditionalRender, store.parameterRow, store.handleError);

    if (!store.editing && row.every(c => !c))
        return null;

    if (loading)
        return <Loading />;

    if (!conditionMet && !loading && !store.editing)
        return null;

    let rowClasses = 'cv-row my-16';
    if (row.length > 1) {
        rowClasses += ' flex multi';
    }

    return <div className="relative">
        <div className={rowClasses}>
            {row.map((c, i) => <CvCellRenderer key={i} cell={c} rowIndex={index} cellIndex={i} />)}
        </div>
        {!!conditionalRender && <ConditionalRenderAlert conditionMet={conditionMet} loading={loading} type={'canvas row'} />}
        {store.editing && <div className="cv-row-controls">
            <div className="cv-side-button" onClick={() => store.startConditionalRenderRowInsert(index)}>
                <i className="icon-question" />
            </div>
            <div className="cv-side-button" onClick={() => store.removeRow(index)}>
                <i className="icon-trash" />
            </div>
            {index > 0 && <div className="cv-side-button" onClick={() => store.moveRow(index, -1)}>
                <i className="icon-arrow-up" />
            </div>}
            {index < size - 1 && <div className="cv-side-button" onClick={() => store.moveRow(index, 1)}>
                <i className="icon-arrow-down" />
            </div>}
        </div>}
    </div>;
}

export default observer(CvRow);