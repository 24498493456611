import {ReactElement, cloneElement} from 'react';
import './side-nav.scss';
import {useLocation, useNavigate} from 'react-router-dom';

type Props = {
    name:string,
    icon: ReactElement,
    link: string
}

export default function SideNavLink(props:Props) {
    const {icon, name, link} = props;
    const navigate = useNavigate();
    const location = useLocation();

    const iconWithClass = cloneElement(icon, {
        className: 'sidebar-icon',
    });

    return(
        <div className={`sidebar-section ${location.pathname === link ? 'highlight' : ''}`} onClick={()=>navigate(link)}>
            {iconWithClass}
            <span className="sidebar-title">{name}</span>  
        </div>
    );
}