import {useCallback, useContext} from 'react';
import {CanvasInsertContext} from '../../lib/context';
import MultiSelectAutocomplete from '../../../../components/multi-select-autocomplete';
import createGraphHandler from '../../../../lib/graph-tools/graph-handler/create';
import {observer} from 'mobx-react';
import Toggle from '../../../../components/toggle';
import {getDefaultDataDefRepo} from '../../../../lib/data-defs';

function CvQueryEditor() {
    const insertStore = useContext(CanvasInsertContext);
    const getVarList = useCallback(
        async () =>
            createGraphHandler(insertStore.query!, getDefaultDataDefRepo())
                .extractVariables()
                .map((v) => v.variable),
        [insertStore.query],
    );

    if (!insertStore.query) return null;

    return (
        <div className='w-full flex flex-col space-y-5'>
            <div className='w-full flex flex-col space-y-3'>
                <label htmlFor='title'>Give your visualization a title</label>
                <input
                    className='w-1/2'
                    name='title'
                    value={insertStore.visTitle}
                    onChange={(e) => (insertStore.visTitle = e.target.value)}
                />
            </div>
            <div className='w-full flex flex-col space-y-3'>
                <label>Sticky columns</label>
                <div className='w-1/2'>
                    <MultiSelectAutocomplete
                        onChange={(cols) => (insertStore.stickyColumns = cols)}
                        values={insertStore.stickyColumns}
                        getOptions={getVarList}
                        width={800}
                    />
                </div>
            </div>
            <div className='w-full flex flex-col space-y-3'>
                <label>Hidden columns</label>
                <div className='w-1/2'>
                    <MultiSelectAutocomplete
                        onChange={(cols) => (insertStore.hiddenColumns = cols)}
                        values={insertStore.hiddenColumns}
                        getOptions={getVarList}
                        width={800}
                    />
                </div>
                {insertStore.isAffiliationQuery && (
                    <div className='w-full flex flex-col space-y-3'>
                        <label>Enable Dataset Colors</label>
                        <div className='w-1/2'>
                            <Toggle
                                checked={insertStore.datasetColorsEnabled}
                                onChange={() =>
                                    (insertStore.datasetColorsEnabled =
                                        !insertStore.datasetColorsEnabled)
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className='w-1/2 flex flex-col space-y-3'>
                <label>No Rows Found Message</label>
                <textarea value={insertStore.noRowMessage} onChange={(e) => insertStore.noRowMessage = e.target.value} />
            </div>
        </div>
    );
}

export default observer(CvQueryEditor);
