import FilterStepsStore from '../../../../components/filter-steps/libs/filter-steps-store';
import {computed, makeObservable, observable} from 'mobx';
import {FilterGroup, QueryFilter, QueryObj} from '../../../../lib/data-types';
import {deserialize, GraphVersions, QuantumGraph, TestType} from 'quantum-graph';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';


export default class HCGFilterStepsStore extends FilterStepsStore {
    public _lob: Array<string>;
    public _age: Array<string>;
    public _gender: Array<string>;
    public _serviceSetting: Array<string>;
    public _hcgLine: Array<string>;
    public _serviceYear: Array<string>;
    public _includeHCGLineDetail: boolean;
    public _primaryDiagnosisCategory: Array<string>;
    private readonly COMMERCIAL = 'COM';
    private readonly TOTAL = 'Total';

    constructor() {
        super(false);
        this._lob = [this.COMMERCIAL];
        this._age = [this.TOTAL];
        this._gender = [this.TOTAL];
        this._serviceSetting = [];
        this._hcgLine = [this.TOTAL];
        this._serviceYear = [];
        this._includeHCGLineDetail = false;
        this._primaryDiagnosisCategory = [this.TOTAL];

        makeObservable(this, {
            _geographies: observable,
            geographies: computed,
            _geographyType: observable,
            geographyType: computed,
            _lob: observable,
            lob: computed,
            _age: observable,
            age: computed,
            _gender: observable,
            gender: computed,
            _hcgLine: observable,
            hcgLine: computed,
            _serviceYear: observable,
            serviceYear: computed,
            _serviceSetting: observable,
            serviceSetting: computed,
            _includeHCGLineDetail: observable,
            includeHCGLineDetail: computed,
            _primaryDiagnosisCategory: observable,
            primaryDiagnosisCategory: computed
        });
    }

    static empty() {
        return new HCGFilterStepsStore();
    }

    canConfigureTable(): boolean {
        return this._geographyType === 'national' || !!this._geographies.length;
    }

    clearAll() {
        super.clearAll();
        this._lob = [];
        this._age = [];
        this._gender = [];
        this._serviceSetting = [];
        this._hcgLine = [];
        this._serviceYear = [];
        this._includeHCGLineDetail = false;
        this._primaryDiagnosisCategory = [];
    }

    getHCGFilters(): Array<QueryFilter | FilterGroup> {
        const filters: Array<QueryFilter | FilterGroup> = [];
        if (this.geographyType !== 'national')
            filters.push(this.createGeographyFilter('geo')!);
        filters.push(this.createLOBFilter()!);
        filters.push(this.createAgeFilter()!);
        filters.push(this.createGenderFilter()!);
        filters.push(this.createServiceSettingFilter()!);
        filters.push(this.createServiceYearFilter()!);
        filters.push(this.createHCGLineFilter()!);
        filters.push(this.createHCGLineDetFilter()!);
        filters.push(this.createPrimaryDiagnosisCategoryFilter()!);

        return filters.filter(_=>_);
    }

    get lob(): Array<string> {
        return this._lob;
    }

    set lob(values: Array<string>) {
        this._lob = values;
    }

    get age(): Array<string> {
        return this._age;
    }

    set age(values: Array<string>) {
        this._age = values;
    }

    get gender(): Array<string> {
        return this._gender;
    }

    set gender(values: Array<string>) {
        this._gender = values;
    }

    get serviceSetting(): Array<string> {
        return this._serviceSetting;
    }

    set serviceSetting(values: Array<string>) {
        this._serviceSetting = values;
    }

    get hcgLine(): Array<string> {
        return this._hcgLine;
    }

    set hcgLine(values: Array<string>) {
        this._hcgLine = values;
    }

    get serviceYear(): Array<string> {
        return this._serviceYear;
    }

    set serviceYear(values: Array<string>) {
        this._serviceYear = values;
    }

    get includeHCGLineDetail(): boolean {
        return this._includeHCGLineDetail;
    }

    set includeHCGLineDetail(include: boolean) {
        this._includeHCGLineDetail = include;
    }

    get primaryDiagnosisCategory(): Array<string> {
        return this._primaryDiagnosisCategory;
    }

    set primaryDiagnosisCategory(values: Array<string>) {
        this._primaryDiagnosisCategory = values;
    }

    isDropdownDisabled() {
        if (this._geographyType === 'national')
            return false;
        return !this._geographies.length;
    }

    getDropdownQuery(variable: string): QuantumGraph {
        const obj: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: QueryType.Dim,
                    tableNodes: [
                        {
                            id: 'geo',
                            dataset: this._geographyType === 'national' ? 'country' : this._geographyType,
                            columns: []
                        },
                        {
                            id: 'hcg',
                            dataset: 'hcg-metrics-' + this.resolveGeoType(),
                            columns: [
                                variable
                            ]
                        }
                    ]
                }
            },
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hcg',
                        column: 'release_year'
                    },
                    test: TestType.Eq,
                    value: '2022'
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hcg',
                        column: 'version'
                    },
                    test: TestType.Eq,
                    value: 1
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hcg',
                        column: variable
                    },
                    test: TestType.NotNull,
                    value: 1
                }

            ],
            distinct: true
        };

        if (this.geographies.length)
            obj.filters!.push(this.createGeographyFilter('geo')!);

        return deserialize(obj);
    }

    private resolveGeoType() {
        if (this._geographyType === 'cbsa')
            return 'msa';

        return this._geographyType;
    }

    private createLOBFilter(): QueryFilter | null {
        if (this._lob.length) {
            return {
                type: 'basic',
                column: {
                    nodeId: 'hcg',
                    column: 'lob'
                },
                test: TestType.In,
                value: this._lob
            };
        }

        return null;
    }

    private createAgeFilter(): QueryFilter | null {
        if (this._age.length) {
            return {
                type: 'basic',
                column: {
                    nodeId: 'hcg',
                    column: 'memberageband'
                },
                test: TestType.In,
                value: this._age
            };
        }

        return null;
    }

    private createGenderFilter(): QueryFilter | null {
        if (this._gender.length) {
            return {
                type: 'basic',
                column: {
                    nodeId: 'hcg',
                    column: 'gender'
                },
                test: TestType.In,
                value: this._gender
            };
        }

        return null;
    }

    private createServiceSettingFilter(): QueryFilter | null {
        if (this._serviceSetting.length) {
            return {
                type: 'basic',
                column: {
                    nodeId: 'hcg',
                    column: 'setting'
                },
                test: TestType.In,
                value: this._serviceSetting
            };
        }

        return null;
    }

    private createServiceYearFilter(): QueryFilter | null {
        if (this._serviceYear.length) {
            return {
                type: 'basic',
                column: {
                    nodeId: 'hcg',
                    column: 'year_key'
                },
                test: TestType.In,
                value: this._serviceYear
            };
        }

        return null;
    }

    private createHCGLineFilter(): QueryFilter | null {
        if (this._hcgLine.length) {
            return {
                type: 'basic',
                column: {
                    nodeId: 'hcg',
                    column: 'mr_line'
                },
                test: TestType.In,
                value: this._hcgLine
            };
        }

        return null;
    }

    private createHCGLineDetFilter(): QueryFilter | null {
        return {
            type: 'basic',
            column: {
                nodeId: 'hcg',
                column: 'mr_line_det'
            },
            test: this._includeHCGLineDetail ? TestType.NEq : TestType.Eq,
            value: 'Total'
        };
    }

    private createPrimaryDiagnosisCategoryFilter(): QueryFilter | null {
        if (this._primaryDiagnosisCategory.length) {
            return {
                type: 'basic',
                column: {
                    nodeId: 'hcg',
                    column: 'ccs_diag'
                },
                test: TestType.In,
                value: this._primaryDiagnosisCategory
            };
        }

        return null;
    }
}