import {useMemo} from 'react';
import AdminCrudTable from '../crud-table';
import {modules} from '../../../lib/api';

export default function PermissionModulesAdmin() {
    const fields = useMemo(() => [
        {name: 'id', label: 'Id', show: true, type: 'numeric'},
        {name: 'name', label: 'Name', show: true, type: 'text'},
        {name: 'groupName', label: 'Group Name', show: true, type: 'text'}
    ], []);

    return <AdminCrudTable api={modules} title={'Permission Modules'} urlPath={'/site-admin/permission-modules'} fields={fields} />;
}