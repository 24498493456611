import {observer} from 'mobx-react';
import {CvInsertQueryType, CvInsertScreen} from '../../lib/types';
import {useContext} from 'react';
import {CanvasInsertContext} from '../../lib/context';


const stepTitles: Record<CvInsertScreen, string> = {
    [CvInsertScreen.type]: 'Visualization Type',
    [CvInsertScreen.queryType]: 'Query Type',
    [CvInsertScreen.querySelect]: 'Query Selection',
    [CvInsertScreen.model]: 'Data Model',
    [CvInsertScreen.configVis]: 'Configuration',
    [CvInsertScreen.configText]: 'Configuration',
    [CvInsertScreen.configOwnership]: 'Configuration',
    [CvInsertScreen.mapQuerySelection]: 'Map Query Selection',
    [CvInsertScreen.columnConfig]: 'Column Configuration',
    [CvInsertScreen.mapConfig]: 'Configuration',
    [CvInsertScreen.configSingleValue]: 'Configuration',
    [CvInsertScreen.configSankey]: 'Configuration',
    [CvInsertScreen.conditionalRender]: 'Conditional Render',
    [CvInsertScreen.configInaccessible]: 'Inaccessible Variable Behavior'
    // [CvInsertScreen.configScatter]: 'Configuration'
};

const Step = observer(({screen, active, done}: {screen: CvInsertScreen, active: boolean, done: boolean}) => {
    const insertStore = useContext(CanvasInsertContext);

    let content = done ? <i className={'icon-checkmark text-green-500'} /> : <></>;
    if (screen === CvInsertScreen.type)
        content = <>{insertStore.visConfig?.label}</>;
    else if (screen === CvInsertScreen['queryType'] && insertStore.queryType)
        content = <>{CvInsertQueryType[insertStore.queryType]}</>;
    else if (screen === CvInsertScreen['querySelect'] && insertStore.hcIndexReference)
        content = <>{insertStore.hcIndexReference}</>;

    return <div className="step text-center" key={screen}>
        <div className={`font-semibold ${active ? 'text-orange-500' : done ? 'text-gray-500' : 'text-gray-300' }`}>{stepTitles[screen]}</div>
        <div className="step-content">{content}</div>
    </div>;
});

const Steps = observer(() => {
    const insertStore = useContext(CanvasInsertContext);

    if (!insertStore.visConfig)
        return <></>;
    
    return <div className='flex flex-row justify-around mb-5'>{[CvInsertScreen.type, ...insertStore.visConfig.screens].map((screen, i, screens) => 
        <Step screen={screen} active={insertStore.screen === screen} done={screens.indexOf(insertStore.screen) > i} key={screen} />)}
    </div>;
});

export default Steps;