import {HCIndexEntry, HCIndexSection} from '../types';
import Badge from '../../../../components/badge';
import {Link} from 'react-router-dom';
import CenteredLayout from '../../../../components/layout/centered-layout';
import React, {useMemo} from 'react';
import HCIndexTable from './table';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

type Props = {
    section: HCIndexSection,
}

export default function HealthcareIndexSection(props: Props) {
    const {section} = props;
    const featured: HCIndexEntry | undefined = useMemo(() =>
        section.entries.find(e => e.featured),
    [section.entries]);

    return <CenteredLayout size={9}>
        <div className='md:grid md:grid-cols-4 md:gap-6'>
            <div className="md:col-span-4">
                <div className="text-center">
                    <h1>{section.name}</h1>
                </div>
            </div>
        </div>
        <div className='md:grid md:grid-cols-4 md:gap-6 mt-5 '>
            <div className="md:col-span-1">
                <div className="text-center">
                    {section.imageUrl && <img src={section.imageUrl.file.url} alt={section.name} />}
                    {section.comingSoon && <Badge color="orange" pill>Coming Soon!</Badge>}
                </div>
            </div>
            <div className="md:mt-0 md:col-span-3">
                {featured && <div className="featured bg-white shadow rounded">
                    <Badge color="orange">Featured</Badge>
                    <Link to={'/healthcare-index/' + featured.shortName} className={section.comingSoon ? 'opacity-50 pointer-events-none' : ''}>
                        {featured.name}
                    </Link>
                    {featured.description && <div>{documentToReactComponents(featured.description)}</div>}
                </div>}
            </div>
        </div>

        <HCIndexTable section={section} />
    </CenteredLayout>;
}