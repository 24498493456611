import {CustomTooltipProps} from 'ag-grid-react';
import {SourceInfo} from '../../components/source-modal';
import Variable from '../../lib/metadata';


export default (props: CustomTooltipProps & {variable: Variable}) => {
    const {variable} = props;
    if (!variable)
        return null;
    return <div className="p-3 bg-white border border-gray-300 rounded-md">
        <SourceInfo variable={variable} />
    </div>;
};