import {useContext, useEffect, useState} from 'react';
import {Download} from '../../../lib/data-types';
import {download as downloadAPI} from '../../../lib/api';
import handleError from '../../../lib/error';
import auth from '../../../lib/auth';
import Button from '../../../components/button';
import ErrorMessage from '../../../components/error-message';
import Loading from '../../../components/loading';
import {HeaderContext} from '../../../components/header/context';

export default function UserDownloads() {
    const [downloads, setDownloads] = useState<Array<Download>>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const headerStore = useContext(HeaderContext);

    useEffect(() => {
        const controller = new AbortController();
        downloadAPI.user.list(controller.signal)
            .then(dl => setDownloads(dl))
            .catch(error => handleError(error, setErrorMessage))
            .finally(() => setLoading(false));
    }, []);

    function getDatasetName(query: any) {
        // We need to support both TIQL and JSON
        if (!query)
            return 'Other';
        let dataset;
        try {
            dataset = JSON.parse(query).datasets[0].name;
        } catch (e) {
            dataset = query.replace(/ /g, '').split(/\b/)[1];
        }

        return dataset;
    }

    function getDownloadUrl(id: number): string {
        let url = 'https://' + process.env.REACT_APP_API_BASE + '/v2/stream/download/' + id;
        url += '?token=' + auth.token;
        return url;
    }

    function legacyUserDownloads() {
        return <div className="w-full">
            {errorMessage && <ErrorMessage error={errorMessage} />}
            <h1>Your File Downloads</h1>
            {loading ?
                <Loading /> :
                downloads.length ?
                    <div className="insight-table mt-5">
                        <table>
                            <thead>
                                <tr>
                                    <th>Dataset</th>
                                    <th>Status</th>
                                    <th>Expires</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {downloads.map((dl, index) => <tr key={index}>
                                    <td className="capitalize">{getDatasetName(dl.query)}</td>
                                    <td className="capitalize">{dl.status}</td>
                                    <td>{new Date(dl.expiration).toDateString()}</td>
                                    <td> {dl.status === 'done' && <a href={getDownloadUrl(dl.id)} target="_blank" rel="noopener noreferrer" className="button bg-orange-500 text-white p-3 hover:no-underline">
                                        <i className="icon-download" />
                                    </a>}
                                    {dl.status !== 'done' && <Button color="orange" disabled><i className="icon-download" /> </Button>}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div> :
                    <div className={'w-full bg-white flex flex-col md:flex-row p-5 items-center'}>
                        <div className={'h-full mr-5 text-orange-500'}>
                            <i className={'icon-info text-3xl'} />
                        </div>
                        <div>
                            <p>Looks like you don't have any downloads.</p>
                        </div>
                    </div>}
        </div>;
    }

    function dppUserDownloads() {
        return <div className="p-8">
            {errorMessage && <ErrorMessage error={errorMessage} />}
            <div className='text-xl'>My File Downloads</div>
            {loading ?
                <Loading /> :
                downloads.length ?
                    <div className="dpp-insight-table mt-5">
                        <table>
                            <thead>
                                <tr>
                                    <th>Dataset</th>
                                    <th>Status</th>
                                    <th>Expiration Date</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {downloads.map((dl, index) => <tr key={index}>
                                    <td className="capitalize">{getDatasetName(dl.query)}</td>
                                    <td className="capitalize">{dl.status}</td>
                                    <td>{new Date(dl.expiration).toDateString()}</td>
                                    <td> {dl.status === 'done' && <a href={getDownloadUrl(dl.id)} target="_blank" rel="noopener noreferrer" className="button bg-orange-500 text-white p-3 hover:no-underline">
                                        <i className="icon-download" />
                                    </a>}
                                    {dl.status !== 'done' && <Button color="orange" disabled><i className="icon-download" /> </Button>}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div> :
                    <div className={'w-full bg-white flex flex-col md:flex-row pt-5 items-center'}>
                        <div>
                            <p>Looks like you don't have any downloads.</p>
                        </div>
                    </div>}
        </div>;
    }

    return headerStore.useNewDesign ? dppUserDownloads() : legacyUserDownloads();
}