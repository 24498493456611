import {Link} from 'react-router-dom';

type Props = {
    crumbs: Array<{name: string, url: string}>
}
export default function Breadcrumb(props: Props) {
    const {crumbs} = props;
    return <div className="flex space-x-2 items-center">
        {crumbs.map((crumb, i) => {
            if (i === crumbs.length - 1)
                return <div key={i} className="cursor-pointer">
                    {crumb.name}
                </div>;
            return <div key={i}>
                <Link to={crumb.url}>{crumb.name}</Link> {i < crumbs.length - 1 && <span className="ml-1">/</span>}
            </div>;
        })}
    </div>;
}