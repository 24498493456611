import {useEffect, useMemo, useRef, useState} from 'react';
import {Search} from '@carbon/icons-react';
import Popup from '../popup';
import {Link} from 'react-router-dom';
import {SiteSearchAllResult} from '../../pages/search/search-types';
import Spinner from '../spinner';
import Tooltip from '../tooltip';
import {parseSiteSearchData, searchAll} from '../../pages/search/site-search-result-card';
import {trackEvent} from '../../lib/analytics-provider';

const MINIMUM_SEARCH_LENGTH = 3;
const MAX_DEFAULT_RESULTS = 4;
const MAX_SEARCH_RESULTS = MAX_DEFAULT_RESULTS * 4;
type SearchResultType = {[key: string]: Array<SiteSearchAllResult>};
type SearchEntity = {type: string, title: string, endpoint?: string, maxScore: number};
export default function HeaderSearch() {
    const [expandSearch, setExpandSearch] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [searchResults, setSearchResults] = useState<SearchResultType>({});
    const [searchEntityScoreOrder, setSearchEntityScoreOrder] = useState<Array<SearchEntity>>([]);
    const searchMenuRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchTimeout, setSearchTimeout] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (inputRef.current && expandSearch)
            inputRef.current.focus();

        if (!expandSearch)
            setSearchText('');
    }, [expandSearch, inputRef]);

    useEffect(() => {
        let controller = new AbortController();
        if (searchText.length >= MINIMUM_SEARCH_LENGTH) {
            setLoading(true);
            clearTimeout(searchTimeout);
            setSearchTimeout(setTimeout(() => {
                searchAll(searchText, controller, MAX_SEARCH_RESULTS)
                    .then((results: Array<SiteSearchAllResult>) => {
                        setSearchResults(processResults(results));
                        processSearchEntities(results);
                        showSearchResultPopup();
                        trackEvent('header-site-search', {
                            query: searchText
                        });
                    }).finally(() => setLoading(false));
            }, 700));
        } else {
            setSearchResults({});
            hideSearchResultPopup();
        }

        return () => controller.abort();
    }, [searchText]);

    function processSearchEntities(searchResults: Array<SiteSearchAllResult>) {
        const searchOrder: Array<SearchEntity> = [];
        searchOrder.push({type: 'canvas', title: 'Entities and Organizations', endpoint: 'entities_and_organizations', maxScore: calculateEntityMaxScore(searchResults.filter(sr => sr.entityType === 'canvas' && sr.index?.endpoint === 'entities_and_organizations'))});
        searchOrder.push({type: 'canvas', title: 'Geography', endpoint: 'geography', maxScore: calculateEntityMaxScore(searchResults.filter(sr => sr.entityType === 'canvas' && sr.index?.endpoint === 'geography'))});
        searchOrder.push({type: 'hci', title: 'Dataset', maxScore: calculateEntityMaxScore(searchResults.filter(sr => sr.entityType === 'hci'))});
        searchOrder.push({type: 'tool',title: 'Tools', maxScore: calculateEntityMaxScore(searchResults.filter(sr => sr.entityType === 'tool'))});
        setSearchEntityScoreOrder(searchOrder.sort((a, b) => b.maxScore - a.maxScore));
    }

    function calculateEntityMaxScore(searchResults: Array<SiteSearchAllResult>) {
        const scores = searchResults.map(sr => sr.score);
        return Math.max(...scores);
    }

    function processResults(results: Array<SiteSearchAllResult>): SearchResultType {
        const entityTypes: SearchResultType = {};
        results.forEach(result => {
            if (!entityTypes[result.entityType])
                entityTypes[result.entityType] = [];
            entityTypes[result.entityType].push(result);
        });
        return entityTypes;
    }

    function showSearchResultPopup() {
        if (searchMenuRef.current) {
            searchMenuRef.current.dispatchEvent(new CustomEvent('search-results', {detail: {noHide: true}}));
        }
    }

    const hideSearchResultPopup = () => {
        if (searchMenuRef.current) {
            searchMenuRef.current.dispatchEvent(new CustomEvent('search-results', {detail: {hide: true}}));
        }
    };

    return <div className={`header-search ${expandSearch ? 'border-2 bg-gray-500 bg-opacity-20' : 'hover:bg-gray-500 hover:bg-opacity-20'}`} style={{width: expandSearch ? '500px' : ''}} ref={searchMenuRef}>
        {!expandSearch && <Tooltip className="h-full w-full px-3 py-4" position="below" narrow content={<span className='w-full flex justify-center'>Search</span>}>
            <button className="flex text-xl text-white items-center justify-center" onClick={() => setExpandSearch(!expandSearch)}>
                <Search className="icon" />
            </button>
        </Tooltip>}
        {expandSearch && <button className="p-5 flex text-xl text-white items-center justify-center" onClick={() => setExpandSearch(!expandSearch)}>
            <Search className="icon" />
        </button>}
        {expandSearch && <div className="flex items-center w-full relative">
            <input type="search" ref={inputRef} value={searchText} onChange={(e) => setSearchText(e.target.value)} onFocus={showSearchResultPopup} />
            {loading && <div className="absolute top-4 right-12"><Spinner /></div>}
        </div>}

        {searchMenuRef && expandSearch && <Popup ariaLabelledBy="search-menu" targetRef={searchMenuRef} customWidth={500} showEvent="search-results" disableMaxHeight transparentBackground noHideOnClick>
            {searchText.length >= MINIMUM_SEARCH_LENGTH && !loading ? <div className="bg-milliman-slate-500 w-full h-full text-white">
                <div className="p-5" >
                    {Object.keys(searchResults).length === 0 && !loading && <p>We could not find a match for "{searchText}". Please try another search.</p>}
                    <ul className="flex flex-col space-y-3" onClick={hideSearchResultPopup}>
                        {searchEntityScoreOrder.map(searchEntity => <>{searchResults[searchEntity.type] &&
                            <SearchResultItem searchResults={searchResults[searchEntity.type]} title={searchEntity.title} endpoint={searchEntity.endpoint} />}</>)}
                    </ul>
                </div>
                <hr className="border-gray-400" />
                <div className="px-5 pb-3">
                    <Link className="text-medinsight-blue-300" to={`/search?q=${searchText}`}>View all results</Link>
                </div>
            </div> : null}
        </Popup>}
    </div>;
}


type SearchResultItemProps = {
    searchResults: Array<SiteSearchAllResult>,
    title: string,
    endpoint?: string
}
function SearchResultItem(props: SearchResultItemProps) {
    const {searchResults, title, endpoint} = props;
    const filteredSearchResults = useMemo(() => endpoint ? searchResults.filter(sr => sr.index?.endpoint === endpoint) : searchResults, []);

    if (!filteredSearchResults.length)
        return null;

    return <li>
        <div>{title}</div>
        <hr className="border-gray-400 my-2" />
        {filteredSearchResults.slice(0, MAX_DEFAULT_RESULTS).map((result, index) => <div key={index}>
            <SearchResultLink searchResult={result} />
        </div>)}
    </li>;
}

type SearchResultLinkProps = {
    searchResult: SiteSearchAllResult
}
function SearchResultLink(props: SearchResultLinkProps) {
    const {searchResult} = props;
    const siteSearchData = parseSiteSearchData(searchResult);

    if (!siteSearchData)
        return null;

    function trackResult() {
        trackEvent('header-site-search-result-clicked', {
            resultValue: searchResult.searchField.value
        });
    }

    return <Link onClick={trackResult} className="text-white flex space-x-3 items-center px-2 py-1" to={siteSearchData.url}>
        <siteSearchData.icon />
        <div className="text-lg">{searchResult.searchField.value}</div>
    </Link>;
}