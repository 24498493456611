import React, {useState} from 'react';
import EntityInput from './entity';
import Button from '../../../../components/button';
import {dataRows} from '../../../../lib/data-types';

type Props = {
    values: Array<number | null>,
    onChange: (id: Array<number | null>) => void,
    additionalFields?: Array<string>,
    list: (signal: AbortSignal) => Promise<dataRows>,
    autocomplete?: boolean,
    sortField?: string,
}

export default function MultiEntityInput(props: Props) {
    const {values, onChange, list, autocomplete, sortField, additionalFields} = props;
    const [items, setItems] = useState<Array<number | null>>(values);

    function setValue(id: number | null, index: number) {
        const tempItems = [...items];
        tempItems[index] = id;
        setItems(tempItems);
        onChange(tempItems);
    }

    function addEntity() {
        const tempItems = [...items];
        tempItems.push(null);
        setItems(tempItems);
    }

    function removeEntity(index: number) {
        const tempItems = [...items];
        tempItems.splice(index, 1);
        setItems(tempItems);
        onChange(tempItems);
    }

    return <ul>
        {items.map((value, index) => <li key={index} className="flex space-x-3 my-1">
            <Button color='red' onClick={() => removeEntity(index)}><i className="icon-minus" /></Button>
            <EntityInput autocomplete={autocomplete} value={value} list={list} onChange={(id: number | null) => setValue(id, index)} additionalFields={additionalFields} sortField={sortField} />
        </li>)}
        <Button className='mt-2' color='light' onClick={addEntity}><i className="icon-plus" /></Button>
    </ul>;
}