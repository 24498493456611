import React, {ComponentType, useEffect, useState} from 'react';
import {dataRows} from '../../../lib/data-types';
import fetchData, {fetchMetadata} from '../../../lib/data';
import handleError from '../../../lib/error';
import Loading from '../../../components/loading';
import {DataConfig} from '../../types';
import Variable from '../../../lib/metadata';
import ErrorMessage from '../../../components/error-message';
import NoAccessMsg, {NoAccessErrMsg} from '../../../components/no-access-msg';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import {getAffiliationConfig} from 'torch-affiliation-defs';
import {getFacilityOwnershipQuery, createAffiliationQuery} from './util';
import {QuantumGraph} from 'quantum-graph';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';

const affiliationConfig = getAffiliationConfig();

export type OwnershipRenderChildProps = {
    dataConfig: DataConfig,
    data: {
        facilities: dataRows
        [key: string]: dataRows,
    }
    metadata: Array<Variable>,
    loadCB?: (promise: Promise<void>) => void
};

type Props = {
    dataConfig: DataConfig,
    children: ComponentType<OwnershipRenderChildProps>,
    loadCB?: (promise: Promise<void>) => void
};

export default function OwnershipDataRenderer(props: Props) {
    const {dataConfig, loadCB} = props;
    const [loading, setLoading] = useState(true);
    const [mdLoading, setMdLoading] = useState(true);
    const [data, setData] = useState<{facilities: dataRows, [datasetName: string]: dataRows}>({facilities: []});
    const [metadata, setMetadata] = useState<Array<Variable>>([]);
    const [err, setErr] = useState<string>('');
    const [noAccess, setNoAccess] = useState(false);

    const graph = dataConfig.queries[0] as V2QuantumGraph;
    const queryDataset = getDefaultDataDefRepo().datasetByName[graph.baseQueryGraph.baseQuery.tableNodes[0].dataset];
    const idToLoad = queryDataset && queryDataset.idField || 'torch_health_system_id';

    useEffect(() => {
        setLoading(true);
        setMdLoading(true);
        setErr('');
        setData({facilities: []});
        setMetadata([]);

        // Load the data from the graph
        const dataController = new AbortController();
        const promiseData = fetchData(graph, dataController.signal)
            .then(rows => {
                if (rows.length === 0) 
                    throw new Error('No data returned');
                const id = rows[0][idToLoad];
                return fetchData(getFacilityOwnershipQuery(Number(id)), dataController.signal);
            })
            .then(getAllAffiliationData(setData, dataController))
            .then(rows => {
                rows.forEach(row => {
                    setData((curr) => ({...curr, ...row}));
                });
                setLoading(false);
            })
            .catch(err => {
                if (err.message.startsWith(NoAccessErrMsg))
                    setNoAccess(true);
                else throw err;
            })
            .catch(err => handleError(err, setErr));
        loadCB && loadCB(promiseData);

        // Load the metadata from the graph
        const metadataController = new AbortController();
        const promiseMetadata = fetchMetadata(graph, metadataController.signal)
            .then(variables => {
                setMetadata(variables);
                setMdLoading(false);
            })
            .catch(err => {
                if (err.message.startsWith(NoAccessErrMsg))
                    setNoAccess(true);
                else throw err;
            })
            .catch(err => handleError(err, setErr));

        loadCB && loadCB(promiseMetadata);
        return function cleanup() {
            dataController.abort();
            metadataController.abort();
        };
    }, [loadCB, dataConfig]);

    if (noAccess)
        return <NoAccessMsg />;
    if (err)
        return <ErrorMessage />;
    if (loading || mdLoading)
        return <Loading />;

    return React.createElement(props.children, {dataConfig, metadata, data});
}

function getAllAffiliationData(setData: React.Dispatch<React.SetStateAction<{facilities: dataRows, [datasetName: string]: dataRows}>>, dataController: AbortController): ((value: dataRows) => {[datasetName: string]: dataRows}[] | PromiseLike<{[datasetName: string]: dataRows}[]>) | null | undefined {

    const fetchDataWithName = async (query: QuantumGraph, name: string) => {
        const rows = await fetchData(query, dataController.signal);
        return {[name]: rows};
    };

    return rows => {
        setData((curr) => ({...curr, facilities: rows}));
        let systemIds = rows.flatMap(row => [
            row['torch_health_system_id_1'],
            row['torch_health_system_id_2'],
            row['torch_health_system_id_3'],
            row['torch_health_system_id_4'],
            row['torch_health_system_id_5'],
            row['torch_health_system_id_6'],
            row['ultimate_owner_torch_health_system_id']
        ])
            .filter((id, i, arr) => id !== null && id !== undefined && arr.indexOf(id) === i)
            .map(id => Number(id));

        let promises: Promise<{[datasetName: string]: dataRows}>[] = [];
        affiliationConfig
            .filter((c, i, arr) => arr.findIndex(a => a.From === c.From && a.To === c.To) === i)
            .forEach((config) => {
                if ((config.From !== 'health-system' && config.To !== 'health-system') || config.To === 'clinic' || config.To === 'facility' || config.To === 'affiliated-health-system')
                    return;

                promises.push(
                    createAffiliationQuery(config.From + ':' + config.To, systemIds, 'torch_health_system_id', getDefaultDataDefRepo(), dataController)
                        .then(query => fetchDataWithName(query, config.From + ':' + config.To)));
            });

        return Promise.all(promises);
    };
}

