import {observer} from 'mobx-react';
import {ChangeEvent, useContext} from 'react';
import {CanvasContext} from '../lib/context';
import {ChevronLeft, ChevronRight} from '@carbon/icons-react';

function SectionTabs() {
    const store = useContext(CanvasContext);

    function canEditSectionName(sectionIndex: number) {
        return store.editing && store.currentSectionIndex === sectionIndex;
    }

    async function changeSection(i: number) {
        if (store.editing && !store.pristine) {
            if (window.confirm("Save section's unsaved changes?")) await store.save();
        }
        store.currentSectionIndex = i;
    }

    function editSectionName(e: ChangeEvent<HTMLInputElement>) {
        e.stopPropagation();
        store.sectionTitle = e.target.value;
    }

    return (
        <div className='w-full flex'>
            {store.sections &&
                store.sections.map((section, i) => (
                    <div
                        key={i}
                        className={`py-2 px-4 cursor-pointer ${
                            store.currentSectionIndex === i
                                ? 'border-blue-500 font-bold'
                                : 'border-gray-300'
                        } border-b-2 flex items-center`}
                    >
                        {canEditSectionName(i) && (
                            <div className='flex items-center'>
                                {store.currentSectionIndex > 0 && (
                                    <ChevronLeft
                                        onClick={async () => {
                                            store.board?.moveSectionUp(store.currentSectionIndex);
                                            await store.save();
                                            store.currentSectionIndex -= 1;
                                        }}
                                    />
                                )}
                                <input
                                    type='text'
                                    className='mx-2'
                                    value={section.title}
                                    onChange={editSectionName}
                                    style={{width: '175px', minWidth: 0}}
                                />
                                {store.currentSectionIndex < store.board!.sections.length - 1 && (
                                    <ChevronRight
                                        onClick={async () => {
                                            store.board?.moveSectionDown(store.currentSectionIndex);
                                            await store.save();
                                            store.currentSectionIndex += 1;
                                        }}
                                    />
                                )}
                            </div>
                        )}
                        {!canEditSectionName(i) && (
                            <span onClick={() => changeSection(i)}>{section.title}</span>
                        )}
                    </div>
                ))}
        </div>
    );
}

export default observer(SectionTabs);
