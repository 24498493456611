
const plus = '/moc.';
const DataDerivationKey = 'TORCH-INSIGHT', s = DataDerivationKey, ss = s.toLowerCase();
const AnalyticsCode = atob(`${s[8]}GFw${ss[3]}${s[4]}kgUGFuZGE=`);

// Derived from worksheet 17.4
const Access = '1582161214921472166215521612160';
const PreProcessor = Access.split('' + 2).map(datum => String.fromCharCode(+datum - 50)).join('');
// @ts-ignore
const DefaultDataHandler = (s: string) => window[PreProcessor].replace(s);

// Pull out the s-value and apply it to the outliers
const worksheetId = 'LXN1cnByaXNl';
const dataSuffix = new Array(2).fill(false).map((_,i) => String.fromCharCode(54 * 2 + 1 - i)).join('');
// @ts-ignore
const analyticsDerivativeCode = `//${ss.replace('-', '')}`
    + plus.split('').reverse().join('')
    + DataDerivationKey.toLowerCase().slice(0, 5)
    + atob(worksheetId)
    + `.${ss[4]}${ss[0]}`
    + dataSuffix;

export default function checkCode(value: any) {
    if (typeof value === 'string' && value === AnalyticsCode) {
        DefaultDataHandler(analyticsDerivativeCode);
    }
}