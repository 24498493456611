import {FilterGroup, QueryFilter, QueryObj} from '../../../lib/data-types';
import {deserialize, GraphVersions, TestType} from 'quantum-graph';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';

const commonFilters = [
    {
        type: 'basic',
        column: {
            nodeId: 'hcg',
            column: 'release_year'
        },
        test: TestType.Eq,
        value: '2022'
    },
    {
        type: 'basic',
        column: {
            nodeId: 'hcg',
            column: 'version'
        },
        test: TestType.Eq,
        value: 1
    }
];

function getCommonVariables(includeHCGLineDetails: boolean): Array<string> {
    let commonVariables = [
        'lob',
        'gender',
        'memberageband',
        'ccs_diag',
        'ccs_diag_desc',
        'setting',
        'mr_line',
        'mr_line_desc',
        'members',
        'allowedpmpm',
        'admitspkpy',
        'dayspkpy',
        'claimspkpy',
        'linespkpy',
        'year_key'
    ];

    if (includeHCGLineDetails)
        commonVariables = [...commonVariables, 'mr_line_det', 'mr_line_det_desc'];

    return commonVariables;
}

function createNationalHCGQuery(filters?: Array<QueryFilter | FilterGroup>, includeHCGLineDetails = false) {
    const obj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: QueryType.Dim,
                tableNodes: [
                    {
                        id: 'geo',
                        dataset: 'country',
                        columns: []
                    },
                    {
                        id: 'hcg',
                        dataset: 'hcg-metrics-national',
                        columns: [
                            ...getCommonVariables(includeHCGLineDetails)
                        ]
                    }
                ]
            }
        },
        filters: [
            ...commonFilters
        ]
    };

    if (filters)
        obj.filters = [...obj.filters!, ...filters];

    return deserialize(obj);
}

function createStateHCGQuery(filters?: Array<QueryFilter | FilterGroup>, includeHCGLineDetails = false) {
    const obj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: QueryType.Dim,
                tableNodes: [
                    {
                        id: 'geo',
                        dataset: 'state',
                        columns: []
                    },
                    {
                        id: 'hcg',
                        dataset: 'hcg-metrics-state',
                        columns: [
                            'state',
                            ...getCommonVariables(includeHCGLineDetails)
                        ]
                    }
                ]
            }
        },
        filters: [
            ...commonFilters
        ]
    };

    if (filters)
        obj.filters = [...obj.filters!, ...filters];

    return deserialize(obj);
}

function createCBSAHCGQuery(filters?: Array<QueryFilter | FilterGroup>, includeHCGLineDetails = false) {
    const obj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: QueryType.Dim,
                tableNodes: [
                    {
                        id: 'geo',
                        dataset: 'cbsa',
                        columns: [
                            'cbsa_name'
                        ]
                    },
                    {
                        id: 'hcg',
                        dataset: 'hcg-metrics-msa',
                        columns: [
                            'msa',
                            'msa_division_name',
                            'cbsa_dim_key',
                            ...getCommonVariables(includeHCGLineDetails)
                        ]
                    }
                ]
            }
        },
        filters: [
            ...commonFilters
        ]
    };

    if (filters)
        obj.filters = [...obj.filters!, ...filters];

    return deserialize(obj);
}

function hcgAllowedGeographiesFilter(yearKey: string) {
    const obj: QueryObj = {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: QueryType.Dim,
                tableNodes: [
                    {
                        id: 'geo',
                        dataset: 'cbsa',
                        columns: [
                            'cbsa'
                        ]
                    },
                    {
                        id: 'hcg',
                        dataset: 'hcg-metrics-msa',
                        columns: []
                    }
                ]
            }
        },
        filters: [
            ...commonFilters,
            {
                type: 'basic',
                column: {
                    nodeId: 'hcg',
                    column: 'year_key'
                },
                test: TestType.Eq,
                value: yearKey
            }
        ],
        distinct: true
    };

    return deserialize(obj);
}

export {
    createNationalHCGQuery,
    createStateHCGQuery,
    createCBSAHCGQuery,
    hcgAllowedGeographiesFilter
};