import AuthWall from '../../../components/auth-wall';
import Template from '../../../components/template';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Select, {BasicSelectType} from '../../../components/select';
import {getAffiliationMatches} from 'torch-affiliation-defs';
import Button from '../../../components/button';
import PageMenu from '../../../components/page-menu';
import {findConnType, findSecondAffiliations, createAffiliationQuery, affiliationDisplayNames} from './query';
import {DataConfig} from '../../../insight-container/types';
import InsightContainer from '../../../insight-container';
import SaveModal from '../../../components/save-modal';
import {useNavigate, useParams} from 'react-router-dom';
import activityTracker from '../../../lib/activity-tracker';
import {SiteFeature} from '../../../lib/check-access';

const affiliationMatches = getAffiliationMatches();

type CrosswalkBuilderParams = {
    from?: string,
    to?: string,
    type?: string,
}

export default function CrosswalkBuilderPage() {
    const params = useParams<CrosswalkBuilderParams>();
    const [fromDataSource, setFromDataSource] = useState<string>(params.from || '');
    const [toDataSource, setToDataSource] = useState<string>(params.to || '');
    const [connType, setConnType] = useState<string>(params.type || '');
    const [showSaveQueryModal, setShowSaveQueryModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const fromData = useMemo(() => {
        return affiliationMatches.map(type => ({
            label: affiliationDisplayNames[type.name],
            value: type.name
        }));
    }, []);

    const toData = useMemo(() => {
        if (!fromDataSource)
            return [];
        return findSecondAffiliations(fromDataSource);
    }, [fromDataSource]);


    const connTypeData = useMemo(() => {
        if (!fromDataSource || !toDataSource)
            return [];
        return findConnType(fromDataSource, toDataSource);
    }, [fromDataSource, toDataSource]);

    const config: DataConfig | null = useMemo(() => {
        if (!fromDataSource || !toDataSource || !connType)
            return null;
        const query = createAffiliationQuery(fromDataSource, toDataSource, connType);
        if (query)
            return {queries: [query]};
        return null;
    }, [fromDataSource, toDataSource, connType]);

    const setPrimary = useCallback((ds: string) => {
        setConnType('');
        setToDataSource('');
        setFromDataSource(ds);
    }, []);

    const setSecondary = useCallback((ds: string) => {
        setConnType('');
        setToDataSource(ds);
    }, []);

    useEffect(() => {
        if (!fromDataSource || !toDataSource || !connType)
            return;
        const url = `/crosswalk-builder/${fromDataSource}/${toDataSource}/${connType}`;
        navigate(url);
        activityTracker.setRecentActivity('Crosswalk Builder');
    }, [fromDataSource, toDataSource, connType]);

    return <AuthWall siteFeature={SiteFeature.CrosswalkBuilder}>
        <Template>
            <PageMenu>
                <div className="flex-grow" />
                <div className="flex space-x-3">
                    <Button color="white" onClick={() => setShowSaveQueryModal(true)}><i className="icon-save" /> Save Data</Button>
                </div>
                <div className="center">
                    <h1>Crosswalk Builder</h1>
                </div>
            </PageMenu>
            <div className="container mt-10">
                <div className="flex">
                    <div className="w-1/4">
                        <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                            From
                        </div>
                        <Select<BasicSelectType> data={fromData} value={fromDataSource} onChange={setPrimary}>
                            {_ => _.value}
                            {_ => _.label}
                        </Select>
                    </div>
                    <div className="w-1/4">
                        <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                            To
                        </div>
                        <Select<BasicSelectType> data={toData} value={toDataSource} onChange={setSecondary} disabled={!fromDataSource}>
                            {_ => _.value}
                            {_ => _.label}
                        </Select>
                    </div>
                    <div className="w-1/4">
                        <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                            Connection Type
                        </div>
                        <Select<BasicSelectType> data={connTypeData} value={connType} onChange={setConnType} disabled={!toDataSource}>
                            {_ => _.value}
                            {_ => _.label}
                        </Select>
                    </div>
                </div>

                <hr/>

                {config && <InsightContainer type="query" dataConfig={config} />}
                {showSaveQueryModal && config && <SaveModal onClose={() => setShowSaveQueryModal(false)} graph={config.queries[0]} reference='crosswalk builder'/>}
            </div>
        </Template>
    </AuthWall>;
}