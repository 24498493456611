import {createContext, ReactNode} from 'react';
import {makeAutoObservable} from 'mobx';

export class MenuStore {

    static empty() {
        return new MenuStore([], false);
    }

    private _x: number;
    private _y: number;
    private _xAnchor: string;
    private _yAnchor: string;
    private _width: number;
    private _content: ReactNode;
    private _visible: boolean;
    private _ariaLabelledBy?: string;
    private _activeId: string;
    private _scroll: number;
    private _cancelClickHide: boolean;
    private _rect?: DOMRect;
    private _disableMaxHeight: boolean;
    private _transparentBackground: boolean;


    constructor(content: Array<ReactNode>, visible: boolean, ariaLabelledBy?: string) {
        this._x = 0;
        this._y = 0;
        this._xAnchor = 'left';
        this._yAnchor = 'top';
        this._width = 0;
        this._content = content;
        this._visible = visible;
        this._ariaLabelledBy = ariaLabelledBy;
        this._activeId = '';
        this._scroll = -1;
        this._cancelClickHide = false;
        this._disableMaxHeight = false;
        this._transparentBackground = false;
        makeAutoObservable(this);
    }

    setup() {
        const clickListener = () => {
            if (this.visible && !this._cancelClickHide)
                this.hide();
        };
        const keyListener = (e: KeyboardEvent) => {
            if (this.visible && e.key === 'Escape')
                this.hide();
        };

        window.addEventListener('click', clickListener);
        window.addEventListener('keydown', keyListener);

        return this;
    }

    get x(): number {
        return this._x;
    }

    get y(): number {
        return this._y;
    }

    get xAnchor(): string {
        return this._xAnchor;
    }

    get yAnchor(): string {
        return this._yAnchor;
    }

    get width(): number {
        return this._width;
    }

    get content(): ReactNode {
        return this._content;
    }

    get visible(): boolean {
        return this._visible;
    }

    get ariaLabelledBy(): string | undefined {
        return this._ariaLabelledBy;
    }

    get activeId(): string {
        return this._activeId;
    }

    get scroll(): number {
        return this._scroll;
    }

    set scroll(value: number) {
        this._scroll = value;
    }

    get cancelClickHide() {
        return this._cancelClickHide;
    }

    set cancelClickHide(cancel: boolean) {
        this._cancelClickHide = cancel;
    }

    get rect(): DOMRect | undefined {
        return this._rect;
    }

    get disableMaxHeight(): boolean {
        return this._disableMaxHeight;
    }

    set disableMaxHeight(value: boolean) {
        this._disableMaxHeight = value;
    }

    get transparentBackground(): boolean {
        return this._transparentBackground;
    }
    set transparentBackground(value: boolean) {
        this._transparentBackground = value;
    }

    show(id: string, rect: DOMRect, content: ReactNode, matchWidth: boolean, customWidth?: number, disableMaxHeight?: boolean, transparentBackground?: boolean, ariaLabelledBy?: string) {
        this._content = content;
        this._ariaLabelledBy = ariaLabelledBy;
        this._width = matchWidth ? rect.width : customWidth || 0;
        this._disableMaxHeight = disableMaxHeight || false;
        this._transparentBackground = transparentBackground || false;
        this._xAnchor = (rect.x + (rect.width / 2)) < (window.innerWidth / 2) ? 'left' : 'right';
        this._yAnchor = (rect.y + (rect.height / 2)) < (window.innerHeight / 2) ? 'top' : 'bottom';
        this._x = this._xAnchor === 'left' ? rect.left + window.scrollX : window.innerWidth - rect.right - window.scrollX;
        this._y = this._yAnchor === 'top' ? rect.bottom + window.scrollY : window.innerHeight - rect.top - window.scrollY;
        this._activeId = id;
        this._visible = true;
        this._rect = rect;
    }

    hide() {
        this._activeId = '';
        this._visible = false;
    }

    updateContent(content: ReactNode) {
        this._content = content;
    }

}

const MenuContext = createContext(MenuStore.empty());
export {MenuContext};

