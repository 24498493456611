import {Checkbox, ContentSwitcher, DataTable, Pagination, Table, TableBody, TableCell, TableContainer, Switch, TableHead, TableHeader, TableRow, DataTableSkeleton} from '@carbon/react';
import React, {useEffect, useMemo, useState} from 'react';
import {networkOptimization} from '../../../lib/api';

interface Provider {
    id: string;
    name: string;
    location: string;
    specialty: string;
    provider_facility_type: string;
}

const headers = [
    {key: 'countyssa', header: 'SSA State/County Code'},
    {key: 'csspec_code', header: 'Specialty Code'},
    {key: 'npi', header: 'National Provider Identifier (NPI) Number'},
    {key: 'full_name', header: 'Name of Physician or Mid-Level Practitioner'},
    {key: 'first_line_practice_location_address', header: 'Street Address'},
    {key: 'practice_location_address_city_name', header: 'City'},
    {key: 'state', header: 'State'},
    {key: 'practice_location_address_postal_code', header: 'ZIP Code'},
];

const PAGE_SIZE = 16;

const pluralize = (word: string, count: number) => count === 1 ? word : word === 'Facility' ? `Facilities` : `${word}s`;

const RosterTable = ({rosterNumber}: {rosterNumber: number}) => {
    const [selection, setSelection] = useState<string[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [viewType, setViewType] = useState<string>('Provider');

    useEffect(() => {
        setLoading(true);
        networkOptimization.fetchProviderReport({}, rosterNumber, (new AbortController()).signal).then((providers) => {
            setProviders(providers.map((p: any, i: number) => ({...p, id: `${p.npi}${i}`})));
            setSelection(providers.map((p: any, i: number) => `${p.npi}${i}`));
            setLoading(false);
        });
    }, [rosterNumber]);

    const rows = useMemo(() => providers.filter(r => r.provider_facility_type === viewType), [viewType, providers]);

    if (loading) 
        return <DataTableSkeleton headers={headers} rowCount={PAGE_SIZE - 4} columnCount={headers.length} compact showToolbar={false} />;

    return <DataTable rows={rows} headers={headers} isSortable size='sm'>
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps
        }) => <TableContainer title={<div className='mt-[-1.25rem] mb-[-.75rem]'>Roster</div>} 
            description={<>
                <div className='mb-4'>Select providers/facilities to include in this roster</div>
                <ContentSwitcher className='mb-4 !justify-normal' onChange={({name}) => setViewType(name!.toString()) } selectedIndex={0} size={'sm'}>
                    <Switch name='Provider' text='Provider Roster' />
                    <Switch name='Facility' text='Facility Roster' />
                </ContentSwitcher>
            </>} {...getTableContainerProps()} className='overflow-none'>
            
            <Table {...getTableProps()} className='overflow-hidden'>
                <TableHead>
                    <TableRow>
                        <TableHeader key='select' >
                            <Checkbox id='select-all' labelText='' indeterminate={rows.some(r => selection.includes(r.id)) && !rows.every(r => selection.includes(r.id))} checked={rows.every(r => selection.includes(r.id))} onChange={(_, {checked}) => checked ? setSelection(rows.map(r => r.id)) : setSelection([])} />
                        </TableHeader>
                        {headers.map((header, i) => {
                            let headerProps = getHeaderProps({header});
                            let headerOnClick = headerProps.onClick as any;
                            return <TableHeader {...headerProps} key={i} onClick={headerOnClick}>
                                {header.header}
                            </TableHeader>;
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map(row => {
                        const rowProps = getRowProps({row});
                        return <TableRow {...rowProps} key={row.id} >
                            <TableCell key={`select-${row.id}`}>
                                <Checkbox id={`check-${row.id}`} labelText='' checked={selection.includes(row.id)} onChange={(_, {checked}) => checked ? setSelection([...selection, row.id]) : setSelection(selection.filter(s => s !== row.id))} />
                            </TableCell>
                            {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                        </TableRow>;
                    })}
                </TableBody>
            </Table>
            <Pagination 
                totalItems={rows.length} 
                pageSize={PAGE_SIZE} 
                pageSizes={[PAGE_SIZE]} 
                pageSizeInputDisabled 
                page={page + 1} 
                isLastPage={page === Math.floor(rows.length / PAGE_SIZE)} 
                onChange={({page: newPage}) => setPage(newPage - 1)}
                itemsPerPageText={`${pluralize(viewType, PAGE_SIZE)} per page:`}
                itemRangeText={(min, max, total) => `${min}-${max} of ${total} ${pluralize(viewType, total).toLocaleLowerCase()}`} />
        </TableContainer>}
    </DataTable>;
    
};

export default RosterTable;