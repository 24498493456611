import {observer} from 'mobx-react';
import RadioButtonGroup from '../../../../../../components/radio-button-group';
import {useContext} from 'react';
import FilterStepsContext from '../../../../../../components/filter-steps/libs/context';
import checkAccess, {SiteFeature} from '../../../../../../lib/check-access';

const viewValues = [
    {name: 'Pricing Relativities', value: 'relativity'},
    {name: 'Code Level Detail', value: 'detail'}
];

const envValues = [
    {name: 'Production', value: 'production'},
    {name: 'UAT', value: 'uat'}
];

const DataSelectionFilterStep = observer(() => {
    const filterStepsStore = useContext(FilterStepsContext);

    return <div className="pb-5">
        <div className="w-1/2 flex justify-between">
            <div>
                <label>Dataset Type</label>
                <RadioButtonGroup className="flex flex-col space-y-1 mt-1" values={viewValues} value={filterStepsStore.view} onChange={view => filterStepsStore.view = view} />
            </div>
            {checkAccess(SiteFeature.ptEnv) && <div>
                <label>Environment</label>
                <RadioButtonGroup className="flex flex-col space-y-1 mt-1" values={envValues} value={filterStepsStore.useUATEnvironment ? 'uat' : 'production'} onChange={env => filterStepsStore.useUATEnvironment = env === 'uat'} />
            </div>}
        </div>
    </div>;
});

export default DataSelectionFilterStep;