import Modal from '../../../components/modal';
import React, {useContext, useEffect, useState} from 'react';
import Select from '../../../components/select';
import {DataSourceLabels, Years} from './constants';
import Button from '../../../components/button';
import CommonCoreConstraints from './constraints';
import {HeaderContext} from '../../../components/header/context';


type Props = {
    year: string,
    lob: string,
    dataSource: string,
    onCancel: () => void,
    onClose: (year: string, lob: string, dataSource: string) => void,
}

export default function CommonCoreStartModal(props: Props) {
    const {onClose, onCancel} = props;
    const [year, setYear] = useState(props.year);
    const [lob, setLob] = useState(props.lob);
    const [lobs, setLobs] = useState<Array<string>>([]);
    const [dataSource, setDataSource] = useState(props.dataSource);
    const [dataSources, setDataSources] = useState<Array<string>>([]);
    const {useNewDesign} = useContext(HeaderContext);

    // Update lob based on year
    useEffect(() => {
        const lobs = CommonCoreConstraints.lobs(year);
        setLobs(lobs);
        setLob(lob => {
            if (!lobs.includes(lob))
                return lobs[0];
            return lob;
        });
    }, [year]);

    // Update data source based on year + lob
    useEffect(() => {
        const dataSources = CommonCoreConstraints.dataSources(year, lob);
        setDataSources(dataSources);
        setDataSource(ds => {
            if (!dataSources.includes(ds))
                return dataSources[0] || '';
            return ds;
        });
    }, [year, lob]);

    return <Modal title="Common Core Benchmarks - Setup" onClose={onCancel} primaryButtonText='Save' secondaryButtonText='Cancel' primaryButtonOnClick={() => onClose(year, lob, dataSource)} secondaryButtonOnClick={onCancel} hasButtons>
        <label className="font-bold block">Year</label>
        <Select<string> data={Years} value={year} onChange={setYear}>
            {_ => _}
            {_ => _}
        </Select>

        <label className="font-bold block mt-5">Line of Business</label>
        <Select<string> data={lobs} value={lob} onChange={setLob}>
            {_ => _}
            {_ => _}
        </Select>

        {dataSources.length > 1 ?
            <div className="mt-5">
                <label className="font-bold block">Data Source</label>
                <Select<string> data={dataSources} value={dataSource} onChange={setDataSource}>
                    {_ => _}
                    {_ => DataSourceLabels[_]}
                </Select>
            </div> :
            dataSources.length ?
                <div className="mt-5">
                    Based on your selections, you will be pulling data from the <span className="text-orange-500">{DataSourceLabels[dataSource]}</span> data source.
                </div> :
                <div className="mt-5">Sorry, we don't currently have data for your selection. Please modify your choices.</div>}


        {!useNewDesign && <div className="modal-buttons">
            <Button color="blue" onClick={() => onClose(year, lob, dataSource)} disabled={!dataSources.length}>Save</Button>
            <Button color="light" onClick={onCancel}>Cancel</Button>
        </div>}
    </Modal>;
}