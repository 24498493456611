import HPTFilterStepsStore from '../hpt-filter-steps-store';
import {computed, makeObservable, observable} from 'mobx';
import {dataRows, QueryFilter, QueryObj} from '../../../../../lib/data-types';
import {deserialize, GraphVersions, QuantumGraph, TestType} from 'quantum-graph';
import fetchData from '../../../../../lib/data';

export default class HPTProfessionalFilterStepsStore extends HPTFilterStepsStore {
    public _providerGroupList: dataRows;
    public _providerGroupType: Array<string>;
    public _minNumFTEs: number;
    public _maxNumFTEs: number;
    private readonly DEFAULT_MIN_NUM_FTES = 0;
    private readonly DEFAULT_MAX_NUM_FTES = 99999;

    constructor() {
        super(false);
        this._dataView = 'payer';
        this._providerGroupList = [];
        this._providerGroupType = [];
        this._minNumFTEs = this.DEFAULT_MIN_NUM_FTES;
        this._maxNumFTEs = this.DEFAULT_MAX_NUM_FTES;
        this._controller = new AbortController();

        makeObservable(this, {
            _minNumBeds: observable,
            minNumBeds: computed,
            _maxNumBeds: observable,
            maxNumBeds: computed,
            _geographies: observable,
            geographies: computed,
            _geographyType: observable,
            geographyType: computed,
            _facilityList: observable,
            facilityList: computed,
            _facilityTypes: observable,
            facilityTypes: computed,
            _healthSystems: observable,
            healthSystems: computed,
            _loading: observable,
            loading: computed,
            _payers: observable,
            payers: computed,
            _lob: observable,
            lob: computed,
            _products: observable,
            products: computed,
            _view: observable,
            view: computed,
            _dataView: observable,
            dataView: computed,
            _geoRestrictionModules: observable,
            geoRestrictionModules: computed,
            _useUATEnvironment: observable,
            useUATEnvironment: computed,
            _providerGroupList: observable,
            providerGroupList: computed,
            _providerGroupType: observable,
            providerGroupType: computed,
            _minNumFTEs: observable,
            minNumFTEs: computed,
            _maxNumFTEs: observable,
            maxNumFTEs: computed
        });
    }

    static empty() {
        return new HPTProfessionalFilterStepsStore();
    }

    get geographies() {
        return this._geographies;
    }

    set geographies(geographies: Array<{name: string, id: number, key: number, type: string}>) {
        this._geographies = geographies;
        this.updateProviderGroupList();
    }

    get providerGroupList() {
        return this._providerGroupList;
    }

    set providerGroupList(pgList: dataRows) {
        this._providerGroupList = pgList;
    }

    get providerGroupType() {
        return this._providerGroupType;
    }

    set providerGroupType(value: Array<string>) {
        this._providerGroupType = value;
        this.updateProviderGroupList();
    }

    public getHPTFilters(): Array<QueryFilter> {
        const filters: Array<QueryFilter> = [];
        if (this.view === 'relativity')
            filters.push(this.createHospitalPayerFlagFilter()!);
        if (this.providerGroupList.length)
            filters.push(this.createProviderGroupFilter()!);
        if (this.payers.length)
            filters.push(this.createPayerFilter()!);
        if (this.lob.length)
            filters.push(this.createLOBFilter()!);
        if (this.products.length)
            filters.push(this.createProductFilter()!);

        return filters;
    }

    get minNumFTEs() {
        return this._minNumFTEs;
    }

    set minNumFTEs(ftes: number) {
        this._minNumFTEs = ftes;
        this.updateProviderGroupList();
    }

    get maxNumFTEs() {
        return this._maxNumFTEs;
    }

    set maxNumFTEs(ftes: number) {
        this._maxNumFTEs = ftes;
        this.updateProviderGroupList();
    }

    public clearAll() {
        super.clearAll();
        this._providerGroupList = [];
        this._minNumFTEs = this.DEFAULT_MIN_NUM_FTES;
        this._maxNumBeds = this.DEFAULT_MAX_NUM_FTES;
    }

    public getProviderGroupDropdownQuery(variable: string): QuantumGraph {
        const obj: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'provider-group',
                            dataset: 'pt-providergroup',
                            columns: [variable]
                        },
                        {
                            id: 'hpt-fact',
                            dataset: 'pt-servicelinecffactrecent-payer-professional',
                            columns: []
                        }
                    ]
                },
                joins: []
            },
            sort: [
                {
                    column: {
                        nodeId: 'provider-group',
                        column: variable
                    },
                    asc: true
                }
            ],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'payer'
                    },
                    test: TestType.NotNull,
                    value: 1
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'provider-group',
                        column: variable
                    },
                    test: TestType.NotNull,
                    value: 1
                },
                this.createHospitalPayerFlagFilter()
            ],
            distinct: true
        };

        if (this.geographies.length)
            obj.filters!.push(this.createGeographyFilter('provider-group')!);

        return deserialize(obj);
    }

    public getPayerDropdownQuery(variable: string): QuantumGraph {
        const obj: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'provider-group',
                            dataset: 'pt-providergroup',
                            columns: []
                        },
                        {
                            id: 'hpt-fact',
                            dataset: 'pt-servicelinecffactrecent-payer-professional',
                            columns: [variable]
                        }
                    ]
                },
                joins: []
            },
            sort: [
                {
                    column: {
                        nodeId: 'hpt-fact',
                        column: variable
                    },
                    asc: true
                }
            ],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'torchprovidergroupid'
                    },
                    test: TestType.NEq,
                    value: -1
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'payer'
                    },
                    test: 'is not null',
                    value: 1
                }
            ],
            distinct: true
        };

        if (this.providerGroupList.length)
            obj.filters!.push(this.createProviderGroupFilter()!);

        return deserialize(obj);
    }

    public createProviderGroupFilter(): QueryFilter | null {
        let filter: QueryFilter;
        if (this.providerGroupList.length > 0) {
            filter = {
                type: 'basic',
                column: {
                    nodeId: 'provider-group',
                    column: 'torch_provider_group_id'
                },
                test: TestType.In,
                value: this.providerGroupList.map(f => (f['torch_provider_group_id'] as number))
            };

            return filter;
        }

        return null;
    }

    public createProviderGroupTypeFilter(): Array<QueryFilter> {
        if (this.providerGroupType.length > 0) {
            const filter = {
                type: 'basic',
                column: {
                    nodeId: 'provider-group',
                    column: 'primary_csspec_desc'
                },
                test: TestType.In,
                value: this.providerGroupType
            };

            return [filter];
        }

        return [];
    }

    protected createStateFilter(nodeId: string, filters: Array<QueryFilter>) {
        const stateIds = this.geographies.filter(g => g.type === 'state').map(g => g.name);
        if (stateIds.length > 0) {
            filters.push({
                type: 'basic',
                column: {
                    nodeId: nodeId,
                    column: 'state'
                },
                test: TestType.In,
                value: [...stateIds]
            });
        }
    }

    private createFTESizeFilter(): Array<QueryFilter> {
        const filters = [];
        if (this._minNumFTEs > this.DEFAULT_MIN_NUM_FTES) {
            const minFTESizeFilter: QueryFilter = {
                type: 'basic',
                column: {
                    nodeId: 'provider-group',
                    column: 'group_size'
                },
                test: TestType.GtEq,
                value: this._minNumFTEs
            };
            filters.push(minFTESizeFilter);
        }

        if (this._maxNumFTEs < this.DEFAULT_MAX_NUM_FTES) {
            const maxBedFilter: QueryFilter = {
                type: 'basic',
                column: {
                    nodeId: 'provider-group',
                    column: 'group_size'
                },
                test: TestType.LtEq,
                value: this._maxNumFTEs
            };
            filters.push(maxBedFilter);
        }

        return filters;
    }

    private updateProviderGroupList() {
        if (this._changeTimeout)
            clearTimeout(this._changeTimeout);
        this._changeTimeout = setTimeout(this.performProviderGroupListUpdate, 1000);
    }

    private generateProviderGroupListQuery() {
        let query: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'provider-group',
                            dataset: 'pt-providergroup',
                            columns: [
                                'torch_provider_group_id',
                                'organization_legal_name',
                                'group_pac_id_or_placeholder'
                            ]
                        },
                        {
                            id: 'hpt-fact',
                            dataset: 'pt-servicelinecffactrecent-payer-professional',
                            columns: []
                        }
                    ]
                }
            },
            sort: [{
                column: {
                    nodeId: 'provider-group',
                    column: 'organization_legal_name'
                },
                asc: true
            }],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'torchprovidergroupid'
                    },
                    test: TestType.NEq,
                    value: -1
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'payer'
                    },
                    test: 'is not null',
                    value: 1
                },
                ...this.createProviderGroupTypeFilter(),
                ...this.createFTESizeFilter()
            ],
            distinct: true
        };

        if (this.MAX_ENTITIES)
            query.pagination = {
                page: 0,
                pageSize: this.MAX_ENTITIES
            };

        if (this.geographies.length)
            query.filters!.push(this.createGeographyFilter('provider-group')!);

        return query;
    }

    private performProviderGroupListUpdate = async () => {
        if (this.loading) {
            this._controller.abort();
            this._controller = new AbortController();
        }
        this.loading = true;
        if (!this._geographies.length &&
            !this._providerGroupType.length &&
            this._minNumFTEs === this.DEFAULT_MIN_NUM_FTES &&
            this._maxNumFTEs === this.DEFAULT_MAX_NUM_FTES &&
            !this._providerGroupList.length) {
            this.providerGroupList = [];
        } else {
            const query: QueryObj = this.generateProviderGroupListQuery();
            this.providerGroupList = await fetchData(deserialize(query), this._controller.signal);
        }
        this.loading = false;
    };
}