import {FieldType} from './types';
import Variable from '../../../lib/metadata';
import {DateTimeFormatter, Formatter, LinkFormatter, StringFormatter} from '../../../lib/formatter';

export function generateVariables(fields: Array<FieldType>): Array<Variable> {
    const sf = new StringFormatter();
    let idVariable: Variable | null = null;
    return fields
        .filter(f => f.show)
        .map(f => {
            let formatter: Formatter = sf;
            if (idVariable && f.linkType)
                formatter = new LinkFormatter(f.linkType, idVariable);
            if (f.type === 'date')
                formatter = new DateTimeFormatter(false);

            const variable = new Variable(f.name, f.label, undefined, f.type, {}, formatter);
            if (f.name === 'id')
                idVariable = variable;
            return variable;
        });
}