import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';

type Props = {
    to: string | object,
    children: ReactNode,
};

export default function MenuItem(props: Props) {
    return <Link className="menu-item" role="menuitem" to={props.to}>{props.children}</Link>;
}