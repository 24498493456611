import Button from '../../../components/button';
import React from 'react';
import SiteName from '../../../components/SiteName';


export default function APIDocHeaders() {

    return <div>
        <h2>Required Headers</h2>
        <p>
            When making any type of request to a <SiteName /> API you must pass the following http headers in the request:
        </p>
        <ol className="font-bold">
            <li>{'{Authorization: Bearer your-auth-token}'}</li>
            <li>{'{Stream-Type: chunked}'}</li>
        </ol>
        <p>
            The Authorization header is required so the service can determine you have sufficient rights to download the requested dataset.
        </p>
        <p>
            The Stream-Type header indicates to the service to stream the response in chunks to the client. This is necessary in order to download large datasets.
        </p>
        <p>* This can also be set as a query parameter</p>
        <pre>{'https://api-prod.torchinsight.com/v2/quantum/data/execute?stream-type=chunked'}</pre>
        <hr />
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/api/compressed-data">Compressed Data</Button>
        </p>
    </div>;
}