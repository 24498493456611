import React, {useContext} from 'react';
import './loading.scss';
import {HeaderContext} from '../header/context';

type Props = {
    small?: boolean,
    large?: boolean
};

export default function Loading(props: Props) {
    const headerStore = useContext(HeaderContext);

    let size = [123, 183];
    if (!props.large)
        size = size.map(s => s / 2);
    if (props.small)
        size = size.map(s => s / 2);

    return <div className="loading">
        <svg className={headerStore.useNewDesign ? 'blue' : 'primary'} width={size[0] + 'px'} height={size[1] + 'px'} viewBox="0 0 123 183" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g transform="translate(0.895000, 0.737000)">
                <polygon className="bar-1" points="-3.55271368e-15 119.426 30.498 119.426 30.498 81.292 -3.55271368e-15 81.292" />
                <polygon className="bar-2" points="45.395 181.25 75.893 181.25 75.893 54.055 45.395 54.055" />
                <polygon className="bar-3" points="90.791 119.426 121.29 119.426 121.29 -2.84217094e-14 90.791 -2.84217094e-14" />
            </g>
        </svg>
    </div>;
}