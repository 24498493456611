import {deserialize, GraphVersions, TestType} from 'quantum-graph';
import {getAffiliationMatches, getAffiliationConfig} from 'torch-affiliation-defs';
import {resolveField, datasetByName} from '../../../lib/data-defs';
import {QueryObj} from '../../../lib/data-types';

const affiliationMatches = getAffiliationMatches();
const affiliationConfig = getAffiliationConfig();

const affiliationDisplayNames: Record<string, string> = {
    'aco': 'Accountable Care Organizations',
    'ehr': 'EHR System',
    'payment-arrangement': 'Payment Arrangement',
    'payer-group': 'Payer Group',
    'facility': 'Facility',
    'affiliated-facility': 'Affiliated Facility',
    'payer': 'Payer',
    'affiliated-payer': 'Affiliated Payer',
    'org': 'Health System',
    'affiliated-org': 'Affiliated Health System',
    'clinic': 'Clinic',
    'provider': 'Provider',
    'provider-group': 'Provider Group', 
    'county': 'County',
    'cbsa': 'Core Based Statistical Area',
    'state': 'State',
    'congressional-district': 'Congressional District',
    'zcta': 'Zip Code Tabulation Area',
    'npi': 'NPI'
};

function findConnType(from: string, to: string,) {
    const affConfig = affiliationConfig.filter(conf => (conf.To === to && conf.From === from || conf.To === from && conf.From === to));
    if (affConfig.length > 0) {
        return affConfig.map((conf: any) => ({
            label: conf.Type,
            value: conf.Type
        }));
    }

    return [];
}

function findSecondAffiliations(affiliationName: string) {
    const indirectMatches = affiliationMatches.filter((af) => af.affiliations.has(affiliationName)).map((af)=>({
        label: affiliationDisplayNames[af.name],
        value: af.name
    }));

    const directMatches = Array.from(affiliationMatches.find(match => match.name === affiliationName)!.affiliations).map((aff: string) => ({
        label: affiliationDisplayNames[aff],
        value: aff
    }));

    return [...indirectMatches, ...directMatches];
}

function createAffiliationQuery(fromSource: string, toSource: string, connectionType: string) {
    const affiliationSource = `${fromSource}:${toSource}`;
    const dataSource = datasetByName[affiliationSource];
    const sourceConfig = affiliationConfig.find(conf => conf.From === fromSource && conf.To === toSource && conf.Type === connectionType);
    if (dataSource && sourceConfig) {
        const idFields = dataSource.foreignKeys.map(fk => {
            const dataset = fk.includes(fromSource) ? fromSource : toSource;
            const resolution = resolveField(fk, dataset, datasetByName);
            if (resolution[0])
                return resolution[1];
            return fk.split(':')[0];
        });

        const typeFields = sourceConfig.Additional_Columns;
        const graph: QueryObj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: affiliationSource,
                            dataset: dataSource.name,
                            columns: [
                                ...idFields,
                                'type',
                                'effective_begin',
                                'effective_end',
                                ...typeFields
                            ]
                        }
                    ]
                }
            },
            filters: [
                {
                    column: {nodeId: affiliationSource, column: 'type'},
                    test: TestType.Eq,
                    value: connectionType
                }
            ]
        };
        return deserialize(graph);
    }

    return null;
}

export {
    findConnType,
    findSecondAffiliations,
    createAffiliationQuery,
    affiliationDisplayNames
};