import {useNavigate, useLocation} from 'react-router-dom';
import Button from '../../../components/button';
import CvRow from './cv-row';
import React, {useContext, useEffect, useMemo} from 'react';
import {CanvasContext} from '../lib/context';
import {observer} from 'mobx-react';
import Loading from '../../../components/loading';
import CvHeader from './cv-header';
import Alert from '../../../components/alert';
import CvInsertModal from './insert-modal/cv-insert-modal';
import ErrorMessage from '../../../components/error-message';
import CvCopyModal from './cv-copy-modal';
import CvParameters from './cv-parameters';
import RowLayoutModal from './inline-editor/row-layout-modal';
import SectionTabs from './section-tabs';
import {AuthoringPanel} from './authoring-panel';
import Breadcrumb from '../../../components/breadcrumb';
import CvSearchBar from './cv-search-bar';
import MergingModal from './merging-modal';
import SectionsPanel from './sections-panel';
import '../canvas.scss';
import VersionModal from './version-modal';
import ApplyVersion from './apply-version';
import {Star, StarFilled} from '@carbon/icons-react';
import {useFavorite} from '../../../lib/favorites';

function Canvas() {
    const store = useContext(CanvasContext);
    const navigate = useNavigate();
    const location = useLocation();
    const breadCrumbs = [{name: 'Home', url: '/'}, {name: 'Canvas', url: '/canvas'}, {name: store.name, url: location.pathname}];
    const [myFavorites, setFavorite] = useFavorite(store.parameter ? `${store.name} - ${store.parameterRow[store.parameter.searchField]}` : store.name);
    const favoriteURL = useMemo(() => location.pathname + location.search, [location.pathname, location.search]);
    const isFavorite = useMemo(() => myFavorites.some(favorite => favorite.url === favoriteURL), [myFavorites, favoriteURL]);
    const FavoriteIcon = isFavorite ? StarFilled : Star;

    useEffect(() => {
        const newURL = `/canvas/${store.boardId}/${store.currentSectionIndex}${location.search}`;
        if (store.loading) {
            return;
        } else {
            store.loading = true;
            store.loadPromises = [];
            navigate(newURL);
        }
    }, [store.currentSectionIndex, store.boardId]);

    useEffect(() => {
        if (store.loading && store.loadPromises.length) {
            Promise.allSettled(store.loadPromises).then((promises) => {
                if (store.loadPromises.length === promises.length)
                    store.loading = false;
            });
        }
    }, [store.loading, store.loadPromises.length]);

    const showSearch = useMemo(() => !store.parameter || (store.parameter && store.parameterValue), [store.parameter, store.parameterValue, store.loading]);

    return <div className="canvas">
        <div className="canvas-header">
            <Breadcrumb crumbs={breadCrumbs} />
            <div className="flex items-center justify-between">
                <div className="flex space-x-3 items-center">
                    {store.editing ?
                        <div>
                            <h1>
                                <input type='text' value={store.name} onChange={e => {
                                    store.name = e.target.value;
                                }} placeholder='Enter Canvas title...' />
                            </h1>
                        </div>
                        : <div className="flex space-x-3 items-center">
                            <FavoriteIcon size={32} className='text-gray-800 hover:cursor-pointer' onClick={setFavorite} />
                            <h1 className="m-0">{store.name}</h1>
                        </div>}
                    <CvParameters />
                </div>
                <div className="flex-grow"></div>
                {showSearch && <div className="mt-1 w-1/3 h-full flex justify-end items-center">
                    <CvSearchBar/>
                </div>}
            </div>
            <SectionTabs />
        </div>
        <div className="canvas-body">
            {store.error && <ErrorMessage error={store.error} />}
            {store.parameter && !store.parameterValue && !store.loading && <div className="w-full h-1/2 flex justify-center items-center">
                <h3 className="mt-16">Select a {store.name.toLowerCase()} using the dropdown menu above to populate data for this dashboard</h3>
            </div>}
            {(!store.parameter || (store.parameter && store.parameterValue && store.parameterRow)) && <div className='cv-container'>
                {store.rows.map(((row, i) =>
                    typeof row === 'string' ?
                        <CvHeader key={i} index={i} value={row} /> :
                        <CvRow key={i} row={row} index={i} conditionalRender={store.getRowConditionalRender(i)} />
                ))}
                {store.editing && <div>
                    <Alert color='blue' icon='icon-plus' noDismiss>
                        <span className='text-blue-600 mr-3'>
                        Add:
                        </span>
                        <Button color='light' onClick={() => store.addHeaderRow()}>
                      Header
                        </Button>
                        <Button color='light' onClick={() => store.toggleRowLayoutModal()}>
                      Content Row
                        </Button>
                    </Alert>
                </div>}
            </div>}
            {store.loading && !store.draftMode && <div className='loading-overlay'>
                <div className='text-gray-400 text-sm mb-5'>Loading greatness...</div>
                <Loading />
            </div>}

            {store.insertModal && <CvInsertModal />}
            {store.copyModal && <CvCopyModal />}
            {store.rowLayoutModal && <RowLayoutModal />}
            {!store.versionId && <div>
                {store.draftMode && store.canEdit && store.editing && <SectionsPanel />}
                {store.draftMode && store.canEdit && <AuthoringPanel />}
            </div>}
            {store.versionId && <ApplyVersion />}
            {store.merging && <MergingModal />}
            {store.showVersionModal && <VersionModal />}
        </div>
    </div>;
}

export default observer(Canvas);