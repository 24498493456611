import {QueryFilter, QueryObj} from '../../../../../lib/data-types';
import {deserialize, GraphVersions, TestType} from 'quantum-graph';

const createHPTQuery = function (view: string, reportedBy: string, filters?: Array<QueryFilter>) {
    let obj: QueryObj;
    
    if (view === 'relativity') {
        obj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'provider-group',
                            dataset: 'pt-providergroup',
                            columns: [
                                'cbsa_name',
                                'state',
                                'organization_legal_name',
                                'group_pac_id_or_placeholder'
                            ]
                        },
                        {
                            id: 'hpt-fact',
                            dataset: 'pt-servicelinecffactrecent-payer-professional',
                            columns: [
                                'plan_name',
                                'payer',
                                'lob',
                                'product',
                                'hpt_hcg_2_service_description',
                                'percent_medicare',
                                'pct_allowed_included',
                                'pct_rvus_included'
                            ]
                        }
                    ]
                },
                joins: []
            },
            sort: [
                {
                    column: {
                        nodeId: 'provider-group',
                        column: 'organization_legal_name'
                    },
                    asc: true
                }
            ],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'percent_medicare'
                    },
                    test: TestType.NotNull,
                    value: '1'
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'torchprovidergroupid'
                    },
                    test: TestType.NEq,
                    value: -1
                }
            ]
        };
    } else {
        obj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'provider-group',
                            dataset: 'pt-providergroup',
                            columns: [
                                'cbsa_name',
                                'state',
                                'organization_legal_name',
                                'group_pac_id_or_placeholder'
                            ]
                        },
                        {
                            id: 'hpt-fact',
                            dataset: 'pt-codedetailfactpayerprofessional',
                            columns: [
                                'payer',
                                'lob',
                                'product',
                                'planname',
                                'amount',
                                'ratemethodology',
                                'code',
                                'codetype',
                                'hcg',
                                'u_rate',
                                'rvufinalrvus',
                                'includeflag',
                                'percentrate',
                                'exclusionreason'
                            ]
                        }
                    ]
                },
            },
            sort: [
                {
                    column: {
                        nodeId: 'provider-group',
                        column: 'organization_legal_name'
                    },
                    asc: true
                }
            ],
            filters: [],
        };
    }

    obj.filters!.push({
        type: 'basic',
        column: {
            nodeId: 'hpt-fact',
            column: 'payer'
        },
        test: 'is not null',
        value: 1
    });

    if (filters)
        obj.filters = [...obj.filters!, ...filters];

    return deserialize(obj);
};

export {
    createHPTQuery
};