import {V1QuantumGraph} from 'quantum-graph/out/v1';
import Variable from '../../../metadata';
import {DatasetDefinition} from 'torch-data-defs';
import {resolveField} from '../../../data-defs';
import {deserialize, QuantumGraph} from 'quantum-graph';
import {DataDefRepo} from '../../../user-data-defs';
import CompositeRectifier from './graph-rectifiers/composite-rectifier';
import YearGrainRectifier from './graph-rectifiers/year-grain-rectifier';
import EmptyNodeRectifier from './graph-rectifiers/empty-node-rectifier';
import Library from '../../../library';
import V1Library from '../../../library/v1-library';
import V1GraphHandler from '../../graph-handler/v1-graph-handler';

const rectifier = new CompositeRectifier([
    new YearGrainRectifier(),
    new EmptyNodeRectifier(),
]);

export function getGrainField(grain: string, ds: DatasetDefinition, datasetRepo: DataDefRepo) {
    return resolveField(grain, ds.name, datasetRepo.datasetByName)[1];
}

export function getGrain(ds: DatasetDefinition, datasetRepo: DataDefRepo) {
    const grain = ds.grain || [];
    return grain.map((g: string) => getGrainField(g, ds, datasetRepo));
}

export function canConnect(ds1: DatasetDefinition, ds2: DatasetDefinition, datasetRepo: DataDefRepo): boolean {
    for (const grain of getGrain(ds1, datasetRepo)) {
        // Don't allow joining on year alone
        if (grain === 'year_key' || grain === 'year_month_key')
            continue;
        if (getGrain(ds2, datasetRepo).includes(grain))
            return true;
    }
    return false;
}

export function getCompositeDataDef(datasets: Array<string>, datasetRepo: DataDefRepo) {
    return datasets.map(ds => datasetRepo.datasetByName[ds]).find(dsDef => dsDef.tableType === 'composite');
}

export function findMiddleDim(compositeDataDef: DatasetDefinition, otherDataDef: DatasetDefinition, datasetRepo: DataDefRepo): DatasetDefinition | undefined {
    return datasetRepo.datasets.find(dsDef => {
        if (dsDef.tableType !== 'dim')
            return false;
        return canConnect(compositeDataDef, dsDef, datasetRepo) && canConnect(dsDef, otherDataDef, datasetRepo);
    });
}

export default function updateV1GraphSelection(graph: V1QuantumGraph, metadata: Array<Variable>, change: Array<Variable>, library: Library, dataDefRepo: DataDefRepo): [QuantumGraph, Array<Variable>] {
    const newMd = metadata.slice();
    const clone = deserialize(graph.serialize()) as V1QuantumGraph;
    const gh = new V1GraphHandler(clone, dataDefRepo);

    // Group variables by dataset
    const varsByDs: Record<string, Array<Variable>> = {};
    change.forEach(v => {
        const ds = v.dataset || 'none';
        if (!varsByDs[ds])
            varsByDs[ds] = [];
        varsByDs[ds].push(v);
    });

    // Add/remove variables for one dataset at a time, then apply the rectifier. This is important because some
    // rectifiers, such as the EmptyNodeRectifier, depend on only having one TableInputNode modified at a time
    for (const dsVars of Object.values(varsByDs)) {
        for (const variable of dsVars) {
            const index = newMd.findIndex(v => v.getId() === variable.getId());
            if (index >= 0) {
                newMd.splice(index, 1);
                gh.removeColumn(variable.dataset as string, variable.name);
                const filters = gh.getFilters(metadata);
                for (const filter of filters) {
                    if (filter.variable.name === variable.name)
                        gh.removeFilter(filter);
                }
            } else {
                const usedDatasets = gh.getDatasets();
                const datasets = library.map(s => s.index.getGrains()).reduce((a, b) => a.concat(b), []);
                const compositeDataDef = getCompositeDataDef(datasets, dataDefRepo);
                const varDataDef = dataDefRepo.datasetByName[variable.dataset as string];
                let baseDataset = varDataDef.tableGrain.find(g => g[0] === '!')!.substr(1);

                /*
                Three use cases:
                1. Not composite - just join and don't worry about it
                2. Composite - Can join directly to the composite
                3. Composite - Needs to add in the dim in order to join to the composite
                 */
                if (compositeDataDef) {
                    if (canConnect(compositeDataDef, varDataDef, dataDefRepo))
                        baseDataset = compositeDataDef.name;
                    else {
                        const dimDataDef = findMiddleDim(compositeDataDef, varDataDef, dataDefRepo);
                        if (dimDataDef) {
                            const resolved = resolveField('::key', dimDataDef.name, dataDefRepo.datasetByName);
                            gh.addColumn(compositeDataDef.name, dimDataDef.name, resolved[2] || resolved[1]);
                        } else
                            continue; // don't add to query
                    }
                } else if (!usedDatasets.includes(baseDataset)) {
                    // eg joining a fact to a hcindex pseudo dim
                    const dim = usedDatasets.find(ds => dataDefRepo.datasetByName[ds].tableType === 'dim');
                    if (!dim)
                        continue;
                    baseDataset = dim;
                }

                newMd.push(variable);
                gh.addColumn(baseDataset, variable.dataset as string, variable.name);
            }
        }

        const dataLibrary = (library as V1Library).toDataLibrary();
        rectifier.apply(gh, newMd, dataLibrary, dataDefRepo);
    }

    const newGraph = gh.build();
    return [newGraph, newMd];
}