import {useContext} from 'react';
import {CanvasContext} from '../lib/context';
import {observer} from 'mobx-react';
import {AuthoringButton} from './authoring-panel';


function SectionsPanel() {
    const store = useContext(CanvasContext);

    return <div className="fixed top-16 right-5 flex space-x-3 align-center bg-gray-500 bg-opacity-30 p-3 rounded-lg shadow">
        <AuthoringButton text="Add Section" icon="icon-plus" color="light" onClick={() => store.createSection()} />
        <AuthoringButton text="Remove Section" icon="icon-trash" color="red" onClick={() => store.deleteCurrentSection()} />
    </div>;
}

export default observer(SectionsPanel);