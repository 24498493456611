import EOCRenderer from '../eoc';
import {DataConfig} from '../types';
import DataTableRenderer from '../data-table';
import {ComponentType} from 'react';
import QueryTableRenderer from '../query-table';
import CRBenchmarksRenderer from '../cr-benchmarks';
import TestRenderer from '../test';
import BarChartRenderer from '../charts/bar-chart';
import PlotChartRenderer from '../charts/plot-chart';
import ProfileRenderer from '../profile';
import CompareTableRenderer from '../compare-table';
import {TransformCBs} from '../../lib/data-types';
import AsyncDownloadRenderer from '../async-download';
import EntityAffiliationsRenderer from '../entity-affiliations';
import PieChartRenderer from '../charts/pie-chart';
import NewMapRenderer from '../map/index';
import CustomTextRenderer from '../custom-text';
import NetworkRenderer from '../network';
import SingleValueRenderer from '../single-value';
import SankeyRenderer from '../charts/sankey';
import ScatterPlotRenderer from '../charts/scatter-plot';
import AgGridTableRenderer from '../ag-grid-table';

type rendererProps = {
    dataConfig: DataConfig,
    loadCB?: (promise: Promise<void>) => void,
    transforms?: TransformCBs
}

export default function findRenderer(type: string): ComponentType<rendererProps> {
    if (type === 'eoc-2')
        return EOCRenderer;
    else if (type === 'table')
        return DataTableRenderer;
    else if (type === 'query' || type === 'common-core')
        return QueryTableRenderer;
    else if (type === 'ag-grid')
        return AgGridTableRenderer;
    else if (type === 'compare-table')
        return CompareTableRenderer;
    else if (type === 'cr-benchmarks')
        return CRBenchmarksRenderer;
    else if (type === 'bar')
        return BarChartRenderer;
    else if (type === 'plot')
        return PlotChartRenderer;
    else if (type === 'profile')
        return ProfileRenderer;
    else if (type === 'map')
        return NewMapRenderer;
    else if (type === 'async-download')
        return AsyncDownloadRenderer;
    else if (type === 'entity-affiliations')
        return EntityAffiliationsRenderer;
    else if (type === 'pie')
        return PieChartRenderer;
    else if (type === 'test')
        return TestRenderer;
    else if (type === 'custom-text')
        return CustomTextRenderer;
    else if (type === 'network')
        return NetworkRenderer;
    else if (type === 'single-value')
        return SingleValueRenderer;
    else if (type === 'sankey')
        return SankeyRenderer;
    else if (type === 'scatter')
        return ScatterPlotRenderer;
    throw new Error('Unknown insight container type: ' + type);
}