import GraphHandler, {Override} from './index';
import {
    ColumnSignature, CountDistinctNode, CountNode, DistinctNode,
    FilterNode,
    JoinNode, PageNode,
    QuantumNode,
    SortNode,
    TableInputNode,
    V1QuantumGraph
} from 'quantum-graph/out/v1';
import {JoinType, QuantumGraph} from 'quantum-graph';
import Variable from '../../metadata';
import {DataDefRepo} from '../../user-data-defs';
import Filter from '../../filter';

export default class V1GraphHandler extends GraphHandler<V1QuantumGraph> {

    topNode: QuantumNode;
    datasetIdMap: Record<string, string> = {};
    dataDefRepo: DataDefRepo;

    constructor(graph: V1QuantumGraph, dataDefRepo: DataDefRepo) {
        super(graph);
        this.dataDefRepo = dataDefRepo;
        this.topNode = graph.getIncoming(0);

        // Build a map of dataset to source node id
        this.topNode.dfs(node => {
            if (node instanceof TableInputNode) {
                this.datasetIdMap[node.dataset] = node.id;
            }
        });
    }

    roughEquals(graph: V1QuantumGraph): boolean {
        return this.graph.getSignature(this.dataDefRepo).isEqual(graph.getSignature(this.dataDefRepo));
    }

    applyFilters(filters: Array<Filter>): this {
        // TODO: Update existing filter/dont add duplicate
        const existingFilters: Record<string, boolean> = {};
        this.topNode.dfs((node: QuantumNode) => {
            if (node instanceof FilterNode) {
                const key = `${node.column.sourceNodeId}.${node.column.name} ${node.test} ${JSON.stringify(node.value)}`;
                existingFilters[key] = true;
            }
        });

        for (const filter of filters) {
            const column = this.lookupColumn(filter.variable);
            const key = `${column.sourceNodeId}.${column.name} ${filter.test} ${JSON.stringify(filter.value)}`;
            if (existingFilters[key]) {
                console.log('Filter exists in graph already. Not adding.');
            } else {
                const node = new FilterNode(column, filter.test, filter.value);
                this.topNode.connect(node);
                this.topNode = node;
            }
        }

        return this;
    }

    applyPagination(page: number, pageSize: number): this {
        const pageNode = this.getPageNode();

        if (pageNode) {
            pageNode.page = page;
            pageNode.pageSize = pageSize;
        } else {
            const pageNode = new PageNode(pageSize, page);
            this.topNode.connect(pageNode);
            this.topNode = pageNode;
        }

        return this;
    }

    removePagination(): this {
        const pageNode = this.getPageNode();
        if (pageNode)
            this.removeNode(pageNode, 1);

        return this;
    }

    applySort(sorts: Array<[Variable, boolean]>): this {
        const [sort, asc] = sorts[0];
        const column = this.lookupColumn(sort);

        // Look for an existing sort node in the graph
        let sortNode = this.getSortNode();
        if (sortNode) {
            sortNode.column = column;
            sortNode.asc = asc;
        } else {
            sortNode = new SortNode(column, asc);
            const pageNode = this.getPageNode();
            if (pageNode) {
                // Insert the sort node before the page node
                pageNode.getIncoming(0).connect(sortNode);
                sortNode.connect(pageNode);
            } else {
                this.topNode.connect(sortNode);
                this.topNode = sortNode;
            }
        }

        return this;
    }

    getFilters(metadata: Array<Variable>): Array<Filter> {
        const nodeMap = this.buildNodeMap();
        function findVariable(column: ColumnSignature): Variable | undefined {
            const node = nodeMap[column.sourceNodeId];
            if (node && node instanceof TableInputNode) {
                const ds = node.dataset;
                return metadata.find(v => v.dataset === ds && v.name === column.name);
            }
        }

        const filters: Array<Filter> = [];
        this.graph.dfs((node: QuantumNode) => {
            if (node instanceof FilterNode) {
                const variable = findVariable(node.column);
                if (variable) {
                    filters.push({
                        variable,
                        test: node.test,
                        value: node.value,
                    });
                }
            }
        });
        return filters;
    }

    getSort(metadata: Array<Variable>): Array<[Variable, boolean]> {
        const sortNode = this.getSortNode();
        if (sortNode) {
            const [sortVar] = this.matchVariables([sortNode.column], metadata);
            if (sortVar)
                return [[sortVar, sortNode.asc]];
        }
        return [[metadata[0], true]];
    }

    removeFilter(filter: Filter, ignoreValue?: boolean): this {
        const filterNode = this.findFilter(filter, ignoreValue);
        if (filterNode)
            this.removeNode(filterNode, 1);
        return this;
    }

    assembleVariables(metadata: Array<Variable>): Array<{connectingDim: string, variable: Variable}> {
        const nodeMap = this.buildNodeMap();
        const columns = this.graph.getSignature(this.dataDefRepo).columns;
        const newMetadata = columns.map(column => {
            const sourceNode = nodeMap[column.sourceNodeId];
            let dataset = '';
            if (sourceNode instanceof TableInputNode)
                dataset = sourceNode.dataset;
            if (dataset)
                return metadata.find(v => v.name === column.name && v.dataset === dataset);
            else
                return metadata.find(v => v.name === column.name);
        }).filter(_ => _) as Array<Variable>;
        metadata.forEach(v => {
            if (!newMetadata.includes(v))
                newMetadata.push(v);
        });
        return newMetadata.map(v => ({connectingDim: 'other', variable: v}));
    }

    build(): QuantumGraph {
        return this.topNode.toGraph();
    }

    buildCount(): QuantumGraph {
        this.removeNonNecessaryNodes();
        const countNode = new CountNode();
        this.topNode.connect(countNode);
        return countNode.toGraph();
    }

    buildCountDistinct(variable: Variable): QuantumGraph {
        this.removeNonNecessaryNodes();
        const column = this.lookupColumn(variable);
        const countDistinctNode = new CountDistinctNode(column);
        this.topNode.connect(countDistinctNode);
        return countDistinctNode.toGraph();
    }

    buildDistinct(variable: Variable): QuantumGraph {
        this.removeNonNecessaryNodes();
        const column = this.lookupColumn(variable);
        const distinctNode = new DistinctNode([column]);
        this.topNode.connect(distinctNode);
        return distinctNode.toGraph();
    }


    // Carryovers from the old GraphAnalyzer below
    // ----

    buildNodeMap(): Record<string, QuantumNode> {
        const nodeMap: Record<string, QuantumNode> = {};
        this.graph.dfs((node: QuantumNode) => {
            nodeMap[node.id] = node;
        });
        return nodeMap;
    }

    findFilter(filter: Filter, ignoreValue?: boolean): FilterNode | null {
        const nodeMap = this.buildNodeMap();
        let filterNode: FilterNode | null = null;

        this.graph.dfs((node: QuantumNode) => {
            if (filterNode)
                return;
            if (node instanceof FilterNode) {
                // Check that the filter variable matches the column
                const sourceNode = nodeMap[node.column.sourceNodeId];
                let varMatch = false;
                if (sourceNode instanceof TableInputNode) {
                    varMatch = sourceNode.dataset === filter.variable.dataset && node.column.name === filter.variable.name;
                }

                const testMatch = node.test === filter.test;
                const valueMatch = JSON.stringify(node.value) === JSON.stringify(filter.value);

                if (varMatch && testMatch && (valueMatch || ignoreValue))
                    filterNode = node;
            }
        });
        return filterNode;
    }

    getDatasets(): Array<string> {
        const datasets: Array<string> = [];
        this.graph.dfs((node: QuantumNode) => {
            if (node instanceof TableInputNode) {
                datasets.push(node.dataset);
            }
        });
        return Array.from(new Set(datasets));
    }

    matchVariables(columns: Array<ColumnSignature>, metadata: Array<Variable>): Array<Variable | undefined> {
        const nodeMap = this.buildNodeMap();
        return columns.map(column => {
            const sourceNode = nodeMap[column.sourceNodeId];
            let dataset = '';
            if (sourceNode instanceof TableInputNode)
                dataset = sourceNode.dataset;
            if (dataset)
                return metadata.find(v => v.name === column.name && v.dataset === dataset);
            else
                return metadata.find(v => v.name === column.name);
        });
    }

    // Carryovers from the old GraphManipulator below
    // --------------------------------------------------

    removeNonNecessaryNodes() {
        const sortNode = this.getSortNode();
        if (sortNode)
            this.removeNode(sortNode, 1);
        const pageNode = this.getPageNode();
        if (pageNode)
            this.removeNode(pageNode, 1);
    }

    findTableNode(dataset: string): TableInputNode | null {
        let tableNode: TableInputNode | null = null;
        this.topNode.dfs((node: QuantumNode) => {
            if (tableNode)
                return;
            if (node instanceof TableInputNode && node.dataset === dataset)
                tableNode = node;
        });
        return tableNode;
    }

    findParent(childNode: QuantumNode): [QuantumNode | null, number] {
        let parent: QuantumNode | null = null;
        let port = 0;

        this.topNode.dfs((node: QuantumNode) => {
            if (parent)
                return;

            for (let i = 0; node.getIncoming(i); i++) {
                const inNode = node.getIncoming(i);
                if (inNode === childNode) {
                    parent = node;
                    port = i;
                }
            }
        });
        return [parent, port];
    }

    addColumn(baseDataset: string, dataset: string, columnName: string) {
        const baseNode = this.findTableNode(baseDataset);
        if (!baseNode) {
            console.error('Cannot find node for dataset: ' + baseDataset);
            return this;
        }
        const [baseParent, port] = this.findParent(baseNode);
        if (!baseParent && this.topNode !== baseNode) {
            console.error('Cannot find parent node for dataset: ' + baseDataset);
            return this;
        }
        const node = this.findTableNode(dataset);
        if (!node) {
            // Add a new node
            const join = new JoinNode(JoinType.Left);
            baseNode.connect(join, 0);
            const tableNode = new TableInputNode(dataset, [columnName]);
            tableNode.connect(join, 1);

            // Connect the join node into the tree
            if (!baseParent)
                this.topNode = join;
            else
                join.connect(baseParent, port);
            return this;
        }

        if (node.columns.includes(columnName)) {
            console.warn(`Column already added: ${dataset} - ${columnName}`);
            return this;
        }

        node.columns.push(columnName);
        return this;
    }

    removeColumn(dataset: string, columnName: string) {
        const node = this.findTableNode(dataset);
        if (!node) {
            console.warn('Cannot find node for dataset: ' + dataset);
            return this;
        }

        const index = node.columns.indexOf(columnName);
        if (index === -1) {
            console.warn(`Column not in node: ${dataset} - ${columnName}`);
            return this;
        }
        node.columns.splice(index, 1);
        return this;
    }

    removeNode(node: QuantumNode, numPorts: number) {
        const [parent, port] = this.findParent(node);
        if (!parent && this.topNode !== node) {
            console.error('Cannot remove node that does not exist');
            return this;
        }
        if (!parent)
            this.topNode = node.getIncoming(0);
        else {
            for (let i = 0; i < numPorts; i++)
                node.getIncoming(i).connect(parent, port);
        }
        return this;
    }

    lookupColumn(variable: Variable): ColumnSignature {
        const columns = this.topNode.getSignature(this.dataDefRepo).columns;
        if (variable.dataset) {
            const column = columns.find(c => c.name === variable.name && c.sourceNodeId === this.datasetIdMap[String(variable.dataset)]);
            if (column)
                return column;
        }

        return this.topNode.lookupColumn(variable.name, this.dataDefRepo);
    }

    getSortNode(): SortNode | null {
        let sortNode: SortNode | null = null;
        this.topNode.dfs(node => {
            if (sortNode)
                return;
            if (node instanceof SortNode)
                sortNode = node;
        });

        return sortNode;
    }

    getPageNode(): PageNode | null {
        let pageNode: PageNode | null = null;
        this.topNode.dfs(node => {
            if (pageNode)
                return;
            if (node instanceof PageNode)
                pageNode = node;
        });

        return pageNode;
    }

    applyOverride(override: Override): this {
        this.graph.dfs(node => {
            if (node instanceof FilterNode && node.column.name === override.field) {
                node.test = override.test;
                node.value = override.value;
            }
        });
        return this;
    }

    getDatasetFromVariable(variable: string): string {
        let dataset = '';
        this.graph.dfs(node => {
            if (node.lookupColumn(variable, this.dataDefRepo))
                dataset = node.name;
        });

        return dataset;
    }

    /**
     * @deprecated
     * Deprecated function please use V2 graph.
     */
    extractVariables(): Array<{dataset: string, variable: string}> {
        return [];
    }

    // eslint-disable-next-line
    applyScaffoldType(type: string | undefined) {}
    getScaffoldType(): string | undefined {
        return undefined;
    }
    // eslint-disable-next-line
    applyScaffoldField(field: string) {}
}