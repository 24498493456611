import {ReactEventHandler, ReactNode, useContext, useEffect} from 'react';
import './modal.scss';
import {observer} from 'mobx-react';
import {MenuContext} from '../popup/context';
import classNames from '../../lib/class-names';
import {HeaderContext} from '../header/context';
import {Modal as CarbonModal} from '@carbon/react';

type Props = {
    children: ReactNode,
    title: string | JSX.Element,
    onClose?: () => void,
    hasButtons?: boolean,
    className?: string,
    headerClassName?: string,
    noCloseOnEsc?: boolean,
    noCloseOnClickOutside?: boolean,
    hideCloseButton?: boolean,
}

type NewModalProps = {
    primaryButtonText?: string,
    secondaryButtonText?: string,
    primaryButtonOnClick?: ReactEventHandler<HTMLElement>,
    secondaryButtonOnClick?: ReactEventHandler<HTMLElement>,
    size?: 'sm' | 'md' | 'lg' | 'xs',
}

/**
 * Modal component. You can put this anywhere and it will show a full-screen modal. Only display one at a time. If you
 * want to include buttons at the bottom of the modal, then include within the children contents:
 *
 * ```
 * <div className="modal-buttons">
 *     <Button ... />
 *     <Button ... />
 * </div>
 * ```
 *
 * Also, set the hasButtons property on the modal to ensure proper spacing and padding of contents.
 */
const Modal = observer((props: Props & NewModalProps) => {
    // Because the modal captures all click events, closing menus won't work. So we need to handle that within this component
    const menuStore = useContext(MenuContext);
    const {useNewDesign} = useContext(HeaderContext);

    // "freeze" the window and keep it from scrolling when there is a modal
    // Also, if you press esc it should close the modal
    useEffect(() => {
        const keyListener = (e: KeyboardEvent) => {
            if (props.onClose) {
                if (e.key === 'Escape') {
                    props.onClose();
                }
            }
        };
        !props.noCloseOnEsc && window.addEventListener('keydown', keyListener);

        return () => {
            !props.noCloseOnEsc && window.removeEventListener('keydown', keyListener);
        };
    }, [props]);

    function closeAll() {
        if (menuStore.visible)
            menuStore.hide();
        props.onClose && props.onClose();
    }

    // `e` is of type MouseEvent, but I couldn't get the subtypes working correctly, so I reverted to any
    function handleClick(e: any) {
        e.stopPropagation();
        if (menuStore.visible)
            menuStore.hide();
    }

    
    const modalClasses = classNames('modal', props.className, {'has-buttons': props.hasButtons});
    const ModalView = useNewDesign ? NewModalView : DefaultModalView;

    return <ModalView modalProps={props} closeAll={closeAll} modalClasses={modalClasses} handleClick={handleClick} />;
});

export default Modal;

function DefaultModalView({modalProps, closeAll, modalClasses, handleClick}: {modalProps: Props, closeAll: () => void, modalClasses: string, handleClick: (e: any) => void}) {
    return <div className="modal-overlay" onClick={!modalProps.noCloseOnClickOutside ? closeAll : undefined}>
        <div className="modal-bg" />
        <div className={modalClasses} onClick={handleClick}>
            <div id='scroll-stop' />
            <div className={modalProps.headerClassName || 'modal-header'}>
                {modalProps.title}
                {modalProps.onClose && !modalProps.hideCloseButton && <button onClick={() => modalProps.onClose && modalProps.onClose()}><i className="icon-times" /></button>}
            </div>
            {modalProps.children}
        </div>
    </div>;
}

function NewModalView({modalProps, closeAll}: {modalProps: Props & NewModalProps, closeAll: () => void, modalClasses: string, handleClick: (e: any) => void}) {
    return <CarbonModal open={true} 
        onRequestClose={closeAll} 
        modalHeading={modalProps.title} 
        primaryButtonText={modalProps.primaryButtonText || 'OK'} 
        onRequestSubmit={modalProps.primaryButtonOnClick} 
        secondaryButtonText={modalProps.secondaryButtonText} 
        onSecondarySubmit={modalProps.secondaryButtonOnClick} 
        passiveModal={!modalProps.primaryButtonText} 
        size={modalProps.size || 'md'}
    >
        {modalProps.children}
    </CarbonModal>;
}
