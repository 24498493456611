import Template from '../../components/template';
import React, {useRef, useState} from 'react';
import Badge from '../../components/badge';
import Alert from '../../components/alert';
import Button from '../../components/button';
import Pagination from '../../components/pagination';
import Tooltip from '../../components/tooltip';
import Select, {BasicSelectType} from '../../components/select';
import Card from '../../components/card';
import Popup from '../../components/popup';
import MenuItem from '../../components/popup/menu-item';
import OneTwoLayout from '../../components/layout/one-two-layout';
import Table from '../../components/table';
import Variable from '../../lib/metadata';
import {StringFormatter} from '../../lib/formatter';
import Modal from '../../components/modal';
import SaveModal from '../../components/save-modal';
import Loading from '../../components/loading';
import CenteredLayout from '../../components/layout/centered-layout';
import Toggle from '../../components/toggle';
import ErrorMessage from '../../components/error-message';
import FilterBar from '../../components/filter-bar';
import Filter from '../../lib/filter';
import {TestType} from 'quantum-graph';
import OneOneLayout from '../../components/layout/one-one-layout';
import OneOneOneLayout from '../../components/layout/one-one-one-layout';
import PageMenu from '../../components/page-menu';
import SourceModal from '../../components/source-modal';
import Autocomplete from '../../components/autocomplete';
import Spinner from '../../components/spinner';
import ProgressBar from '../../components/progress-bar';
import {V1QuantumGraph} from 'quantum-graph/out/v1';
import PageTitle from '../../components/page-title';
import MapSelection from '../../components/map-selection';
import mapboxConfig from '../../insight-container/map/map-libs/mapbox-config';

const PROGRESS_DEFAULT_VALUE = 60;

const selectContents: Array<BasicSelectType> = new Array(20).fill(true).map((_, i) => ({
    label: 'Thing ' + i,
    value: String(i),
}));

const exampleVariable = new Variable('foo', 'Example Variable', 'An example of a variable that is used within Torch Insight', 'int', {
    Source: 'The name of the source goes here',
    SourceDescription: 'This source is really great because it is never updated ever.',
    LastUpdated: '2019-12-31',
    EarliestObservation: '2008-04-25 - 2008-09-25',
    LatestObservation: '2021-01-01 - 2021-01-14',
}, new StringFormatter(), 'hospital');

const exampleFilter: Filter = {
    variable: exampleVariable,
    test: TestType.LtEq,
    value: 123,
};

function getOptions(input: string): Promise<Array<string>> {
    console.log('Get options');

    return new Promise(resolve => {
        setTimeout(() => {
            resolve([
                'aaa',
                'bbb',
                'ccc',
                'ddd',
                'eee',
                'fff',
                'ggg',
                'hhh',
            ].map(v => input + ' ' + v));
        }, 200);
    });
}

const demoProgressBar = (event: React.MouseEvent<HTMLDivElement>, progress = 0, setProgress: React.Dispatch<React.SetStateAction<number>>) => {
    const intervalTime = setInterval(() => {
        if (progress <= 100) {
            progress++;
            setProgress(progress);
        } else {
            clearInterval(intervalTime);
            setProgress(PROGRESS_DEFAULT_VALUE);
        }
    }, 100);
};

const pageTitleLinks = [
    {name: <span>Link 1</span>, href: '#link1'},
    {name: <span>Link 2</span>, href: '#link2'},
    {name: <span>Link 3</span>, href: '#link3'},
];

export default function UILibraryPage() {
    const [acValue1, setAcValue1] = useState('');
    const [acValue2, setAcValue2] = useState('');
    const [popupVisible, setPopup] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectValue, setSelectValue] = useState('');
    const popupButtonRef = useRef<HTMLButtonElement>(null);
    const [toggle1, setToggle1] = useState(true);
    const [toggle2, setToggle2] = useState(false);
    const [sourceModal, setSourceModal] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const [progress, setProgress] = useState(PROGRESS_DEFAULT_VALUE);
    const [shape, setShape] = useState<string>('state');

    return <Template>
        <div className="container bg-white pt-5 pb-16 mt-1 mb-16 shadow">
            <h1>UI Library</h1>

            This is in the <code>container</code> class.

            <h2>Icons</h2>
            <p>Use <code>icon-</code></p>
            <Tooltip content="icon-arrow-down"><i className="icon-arrow-down" /></Tooltip>
            <Tooltip content="icon-arrow-left"><i className="icon-arrow-left" /></Tooltip>
            <Tooltip content="icon-arrow-right"><i className="icon-arrow-right" /></Tooltip>
            <Tooltip content="icon-arrow-up"><i className="icon-arrow-up" /></Tooltip>
            <Tooltip content="icon-chart-bar"><i className="icon-chart-bar" /></Tooltip>
            <Tooltip content="icon-chart-flow"><i className="icon-chart-flow" /></Tooltip>
            <Tooltip content="icon-chart-line"><i className="icon-chart-line" /></Tooltip>
            <Tooltip content="icon-chart-pie"><i className="icon-chart-pie" /></Tooltip>
            <Tooltip content="icon-chart-tree"><i className="icon-chart-tree" /></Tooltip>
            <Tooltip content="icon-check-circle"><i className="icon-check-circle" /></Tooltip>
            <Tooltip content="icon-dashboard"><i className="icon-dashboard" /></Tooltip>
            <Tooltip content="icon-data"><i className="icon-data" /></Tooltip>
            <Tooltip content="icon-database"><i className="icon-database" /></Tooltip>
            <Tooltip content="icon-double-left"><i className="icon-double-left" /></Tooltip>
            <Tooltip content="icon-double-right"><i className="icon-double-right" /></Tooltip>
            <Tooltip content="icon-down"><i className="icon-down" /></Tooltip>
            <Tooltip content="icon-download"><i className="icon-download" /></Tooltip>
            <Tooltip content="icon-edit"><i className="icon-edit" /></Tooltip>
            <Tooltip content="icon-error"><i className="icon-error" /></Tooltip>
            <Tooltip content="icon-file-excel"><i className="icon-file-excel" /></Tooltip>
            <Tooltip content="icon-file-pdf"><i className="icon-file-pdf" /></Tooltip>
            <Tooltip content="icon-file-spreadsheet"><i className="icon-file-spreadsheet" /></Tooltip>
            <Tooltip content="icon-filter"><i className="icon-filter" /></Tooltip>
            <Tooltip content="icon-gear"><i className="icon-gear" /></Tooltip>
            <Tooltip content="icon-group"><i className="icon-group" /></Tooltip>
            <Tooltip content="icon-info"><i className="icon-info" /></Tooltip>
            <Tooltip content="icon-key"><i className="icon-key" /></Tooltip>
            <Tooltip content="icon-left"><i className="icon-left" /></Tooltip>
            <Tooltip content="icon-map"><i className="icon-map" /></Tooltip>
            <Tooltip content="icon-minus"><i className="icon-minus" /></Tooltip>
            <Tooltip content="icon-plus"><i className="icon-plus" /></Tooltip>
            <Tooltip content="icon-question"><i className="icon-question" /></Tooltip>
            <Tooltip content="icon-right"><i className="icon-right" /></Tooltip>
            <Tooltip content="icon-save"><i className="icon-save" /></Tooltip>
            <Tooltip content="icon-search"><i className="icon-search" /></Tooltip>
            <Tooltip content="icon-times"><i className="icon-times" /></Tooltip>
            <Tooltip content="icon-times-circle"><i className="icon-times-circle" /></Tooltip>
            <Tooltip content="icon-trash"><i className="icon-trash" /></Tooltip>
            <Tooltip content="icon-up"><i className="icon-up" /></Tooltip>
            <Tooltip content="icon-user"><i className="icon-user" /></Tooltip>
            <Tooltip content="icon-warning"><i className="icon-warning" /></Tooltip>

            <h2>Alert</h2>
            <Alert color="primary" icon="icon-info" >This an example alert.</Alert>
            <Alert color="yellow" icon="icon-warning"><strong>Hey There!</strong> Put anything you want in here.</Alert>
            <Alert color="green" icon="icon-info" >This an example alert.</Alert>
            <Alert color="blue" icon="icon-info" >This an example alert.</Alert>
            <Alert color="red" icon="icon-info" >This an example alert.</Alert>
            <p>
                You can also add a slight colored border around the alert to help it stand out on white.
            </p>
            <Alert border color="primary" icon="icon-info" >This an example alert.</Alert>
            <Alert border color="yellow" icon="icon-warning"><strong>Hey There!</strong> Put anything you want in here.</Alert>
            <Alert border color="green" icon="icon-info" >This an example alert.</Alert>
            <Alert border color="blue" icon="icon-info" >This an example alert.</Alert>
            <Alert border color="red" icon="icon-info" >This an example alert.</Alert>

            <h2>Autocomplete</h2>
            <div>
                <Autocomplete onChange={setAcValue1} getOptions={getOptions} />
                <span className="text-gray-500 ml-5">Autocomplete value: {acValue1 || <em>null</em>}</span>
            </div>
            <p>You can use <code>allowFreeform</code> to control if freeform entry is allowed, or only dropdown options is permitted. Example below:</p>
            <div>
                <Autocomplete onChange={setAcValue2} getOptions={getOptions} allowFreeform />
                <span className="text-gray-500 ml-5">Autocomplete value: {acValue2 || <em>null</em>}</span>
            </div>

            <h2>Badge</h2>
            <Badge color="primary">Hello</Badge>
            <Badge color="yellow" pill>World</Badge>
            <Badge color="green" outlined>World</Badge>
            <Badge color="blue" pill outlined>World</Badge>
            <Badge color="red">World</Badge>
            <Badge color="dark">World</Badge>
            <Badge color="light">World</Badge>

            <p>By providing <code>onClose</code>, badges can become removable.</p>
            <Badge color="primary" onClose={() => console.log('close')}>Example 1</Badge>
            <Badge color="dark" onClose={() => console.log('close')} pill>Example 2</Badge>

            <h2>Button</h2>
            <div className="mt-1">
                <Button color="primary" onClick={() => console.log('hi')}>Button 1</Button>
                <Button color="yellow" onClick={() => console.log('hi')}>Button 2</Button>
                <Button color="green" onClick={() => console.log('hi')}>Button 3</Button>
                <Button color="blue" onClick={() => console.log('hi')}>Button 4</Button>
                <Button color="red" onClick={() => console.log('hi')}>Button 5</Button>
                <Button color="dark" onClick={() => console.log('hi')}>Button 6</Button>
                <Button color="light" onClick={() => console.log('hi')}>Button 7</Button>
                <Button color="white" onClick={() => console.log('hi')}>Button 8</Button>
                <Button color="link" onClick={() => console.log('hi')}>Button 9</Button>
            </div>
            <div className="mt-1">
                <Button pill color="primary" onClick={() => console.log('hi')}>Button 1</Button>
                <Button pill color="yellow" onClick={() => console.log('hi')}>Button 2</Button>
                <Button pill color="green" onClick={() => console.log('hi')}>Button 3</Button>
                <Button pill color="blue" onClick={() => console.log('hi')}>Button 4</Button>
                <Button pill color="red" onClick={() => console.log('hi')}>Button 5</Button>
                <Button pill color="dark" onClick={() => console.log('hi')}>Button 6</Button>
                <Button pill color="light" onClick={() => console.log('hi')}>Button 7</Button>
                <Button pill color="white" onClick={() => console.log('hi')}>Button 8</Button>
            </div>
            <div className="mt-1">
                <Button outlined color="primary" onClick={() => console.log('hi')}>Button 1</Button>
                <Button outlined color="yellow" onClick={() => console.log('hi')}>Button 2</Button>
                <Button outlined color="green" onClick={() => console.log('hi')}>Button 3</Button>
                <Button outlined color="blue" onClick={() => console.log('hi')}>Button 4</Button>
                <Button outlined pill color="red" onClick={() => console.log('hi')}>Button 5</Button>
                <Button outlined pill color="dark" onClick={() => console.log('hi')}>Button 6</Button>
            </div>
            <p className="my-2">You can also add <code>loading</code> to any button.</p>
            <div>
                <Button color="primary" onClick={() => null} loading disabled>Hello world</Button>
                <Button color="yellow" onClick={() => null} loading disabled>Hello world</Button>
                <Button color="green" onClick={() => null} loading disabled>Hello world</Button>
                <Button color="blue" onClick={() => null} loading disabled>Hello world</Button>
                <Button color="red" onClick={() => null} loading disabled>Hello world</Button>
                <Button color="dark" onClick={() => null} loading>Hello world</Button>
                <Button color="light" onClick={() => null} loading>Hello world</Button>
                <Button color="white" onClick={() => null} loading>Hello world</Button>
                <Button color="link" onClick={() => null} loading>Hello world</Button>
            </div>

            <h2>Progress Bar</h2>
            <div>progress: number (0 - 100)</div>
            <div>color: orange, yellow, green, blue, red, light, dark</div>
            <div>border: boolean</div>
            <div style={{height: '15px', marginBottom: '10px'}} onClick={(event) => demoProgressBar(event, progress, setProgress)}>
                <ProgressBar progress={progress} color={'orange'} border />
            </div>
            <div style={{height: '15px', marginBottom: '10px'}} onClick={(event) => demoProgressBar(event, progress, setProgress)}>
                <ProgressBar progress={progress} color={'yellow'} border/>
            </div>
            <div style={{height: '15px', marginBottom: '10px'}} onClick={(event) => demoProgressBar(event, progress, setProgress)}>
                <ProgressBar progress={progress} color={'green'} border/>
            </div>
            <div style={{height: '15px', marginBottom: '10px'}} onClick={(event) => demoProgressBar(event, progress, setProgress)}>
                <ProgressBar progress={progress} color={'blue'} border/>
            </div>
            <div style={{height: '15px', marginBottom: '10px'}} onClick={(event) => demoProgressBar(event, progress, setProgress)}>
                <ProgressBar progress={progress} color={'red'} border/>
            </div>
            <div style={{height: '15px', marginBottom: '10px'}} onClick={(event) => demoProgressBar(event, progress, setProgress)}>
                <ProgressBar progress={progress} color={'light'} border/>
            </div>
            <div style={{height: '15px', marginBottom: '10px'}} onClick={(event) => demoProgressBar(event, progress, setProgress)}>
                <ProgressBar progress={progress} color={'dark'} border/>
            </div>

            <h2>Card</h2>
            <div className="px-4 py-4 bg-gray-100">
                <div className="w-1/2">
                    <Card>
                        Put anything you want within a <code>Card</code> component.
                    </Card>
                    <div className="my-4" />
                    <Card onSubmit={() => console.log('Hi')} submitText="Log hi">
                        You can also add a submit button to a card.
                    </Card>
                </div>
            </div>

            <h2>Data Library</h2>
            <pre>{'<DataLibrary />'}</pre>

            <h2>Error Message</h2>
            <ErrorMessage error="This is an error message" />

            <h2>Filter Bar</h2>
            <FilterBar queryName="Filter Bar Name" filters={[exampleFilter]} sorts={[]} onRemove={() => true} />

            <h2>Input</h2>
            <div><input type="text"/></div>
            <div className="mt-2">
                <input type="text" disabled />
            </div>

            <h2>Layout: One-One</h2>
            <p>
                For a layout with two columns (1/2 and 1/2), use the <code>OneOneLayout</code> component. It takes two
                children: the left content and the right content
            </p>
            <div className="px-4 py-4 bg-gray-100">
                <OneOneLayout>
                    <p>The left content goes here</p>
                    <Card>
                        <p>The right content goes here</p>
                        <p>You can put anything you want here, like a card in this example.</p>
                    </Card>
                </OneOneLayout>
            </div>

            <h2>Layout: One-One-One</h2>
            <p>
                For a layout with two columns (1/3, 1/3, and 1/3), use the <code>OneOneOneLayout</code> component. It takes two
                children: the left content and the right content
            </p>
            <div className="px-4 py-4 bg-gray-100">
                <OneOneOneLayout>
                    <p>The left content goes here</p>
                    <p>The middle content goes here</p>
                    <Card>
                        <p>The right content goes here</p>
                        <p>You can put anything you want here, like a card in this example.</p>
                    </Card>
                </OneOneOneLayout>
            </div>

            <h2>Layout: One-Two</h2>
            <p>
                For a layout with two columns (1/3 and 2/3), use the <code>OneTwoLayout</code> component. It takes two
                children: the left content and the right content
            </p>
            <div className="px-4 py-4 bg-gray-100">
                <OneTwoLayout>
                    <p>The left content goes here</p>
                    <Card>
                        <p>The right content goes here</p>
                        <p>You can put anything you want here, like a card in this example.</p>
                    </Card>
                </OneTwoLayout>
            </div>

            <h2>Layout: Centered</h2>
            <p>
                Places content in the middle of a grid, taking up the center n-2 columns.
            </p>
            <div className="px-4 py-4 bg-gray-100">
                <CenteredLayout size={5}>
                    <Card>
                        This is a centered layout with size 5. This card takes up 3/5 of the columns.
                    </Card>
                </CenteredLayout>
            </div>

            <h2>Loading</h2>
            <div className="flex items-center">
                <Loading large />
                <div className="w-32" />
                <Loading />
                <div className="w-32" />
                <Loading small />
            </div>

            <h2>Modal</h2>
            <div>
                <Button color="light" onClick={() => setModal(!modal)}>Toggle Modal</Button>
            </div>
            {modal && <Modal title="Example Modal" onClose={() => setModal(false)}>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium id ipsam magnam maiores nulla provident unde. Cumque hic ipsa iure nam numquam provident quisquam ullam. Culpa expedita molestiae soluta!</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci alias at, debitis eveniet fugit maiores minus molestias necessitatibus quaerat quasi qui quo recusandae rem sapiente tempora velit vero voluptas!</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi blanditiis eaque, earum enim exercitationem id illo iure labore magnam, minima molestias neque nesciunt perferendis provident, quibusdam quod repellat similique suscipit.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi corporis dolor dolore in incidunt obcaecati, tempore. Adipisci beatae, consequatur, fugiat ipsum magni necessitatibus officia optio possimus reiciendis sequi temporibus vel.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium id ipsam magnam maiores nulla provident unde. Cumque hic ipsa iure nam numquam provident quisquam ullam. Culpa expedita molestiae soluta!</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci alias at, debitis eveniet fugit maiores minus molestias necessitatibus quaerat quasi qui quo recusandae rem sapiente tempora velit vero voluptas!</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi blanditiis eaque, earum enim exercitationem id illo iure labore magnam, minima molestias neque nesciunt perferendis provident, quibusdam quod repellat similique suscipit.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi corporis dolor dolore in incidunt obcaecati, tempore. Adipisci beatae, consequatur, fugiat ipsum magni necessitatibus officia optio possimus reiciendis sequi temporibus vel.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium id ipsam magnam maiores nulla provident unde. Cumque hic ipsa iure nam numquam provident quisquam ullam. Culpa expedita molestiae soluta!</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci alias at, debitis eveniet fugit maiores minus molestias necessitatibus quaerat quasi qui quo recusandae rem sapiente tempora velit vero voluptas!</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi blanditiis eaque, earum enim exercitationem id illo iure labore magnam, minima molestias neque nesciunt perferendis provident, quibusdam quod repellat similique suscipit.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi corporis dolor dolore in incidunt obcaecati, tempore. Adipisci beatae, consequatur, fugiat ipsum magni necessitatibus officia optio possimus reiciendis sequi temporibus vel.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium id ipsam magnam maiores nulla provident unde. Cumque hic ipsa iure nam numquam provident quisquam ullam. Culpa expedita molestiae soluta!</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci alias at, debitis eveniet fugit maiores minus molestias necessitatibus quaerat quasi qui quo recusandae rem sapiente tempora velit vero voluptas!</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi blanditiis eaque, earum enim exercitationem id illo iure labore magnam, minima molestias neque nesciunt perferendis provident, quibusdam quod repellat similique suscipit.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi corporis dolor dolore in incidunt obcaecati, tempore. Adipisci beatae, consequatur, fugiat ipsum magni necessitatibus officia optio possimus reiciendis sequi temporibus vel.</p>
            </Modal>}

            <h2>Save Modal</h2>
            <div>
                <p>Note that this will actually save a quantum query to your account. You can manage it from the save data page.</p>
                <Button color="light" onClick={() => setSaveModal(!saveModal)}>Toggle Save Modal</Button>
            </div>
            {saveModal && <SaveModal onClose={() => setSaveModal(false)} graph={new V1QuantumGraph()} reference={'UI-Library'}/>}

            <h2>Page Menu</h2>
            <p>
                For consistent sub-menus across pages, use the PageMenu component as the first component of a page. Put
                anything you want as the children. For centered content, give it the <code>center</code> class. This is
                positioned absolutely, so you can position the other content normally. For right-anchored content, add
                a <code>flex-grow</code> element before it.
            </p>
            <PageMenu>
                <Button color="dark" onClick={() => true}>This is an example</Button>
                <div className="flex-grow" />
                <Badge color="primary" pill>Right Content</Badge>

                <h1 className="center">Page Title</h1>
            </PageMenu>

            <h2>Page Title</h2>
            <p>
                For a consistent page banner title with subpage navigation. The banner gives the classic blue gradiant and an h1 text.
                The name and link attributes take a React Element so you can customize it as needed.
                You can give it an attribute of sticky to make it a stick at the top of the page when you scroll down.
            </p>
            <PageTitle name={<span>Page Title Example</span>} links={pageTitleLinks} />

            <h2>Pagination</h2>
            <Pagination count={100} page={9} pageSize={10} setPage={page => console.log(page)} />

            <h2>Popup</h2>
            <div>
                Great for menus.
                <div className="relative inline-block">
                    <Button color="light" onClick={() => setPopup(!popupVisible)} ref={popupButtonRef}>Toggle popup</Button>
                    <Popup targetRef={popupButtonRef}>
                        <MenuItem to="/">Thing 1</MenuItem>
                        <MenuItem to="/">Thing 2</MenuItem>
                        <MenuItem to="/">Thing 3</MenuItem>
                        <MenuItem to="/">Thing 4</MenuItem>
                        <div className="menu-item">This is not a link</div>
                    </Popup>
                </div>
                <p>
                    You can use the <code>MenuItem</code> component within a popup for a menu link, or use the <code>menu-item</code>
                    class on any non-links.
                </p>
            </div>

            <h2>Select</h2>
            <p>
                For input use. It uses a popup but adds in a bunch of keyboard accessibility features, such as arrow navigation and typing.
            </p>
            <p>
                Provide the array of options to the <code>data</code> parameter. It takes two child functions. The first
                takes an item and returns a string for the <code>value</code>. The second takes an item and returns a
                <code>ReactNode</code> used for display.
            </p>
            <Select<BasicSelectType> data={selectContents} value={selectValue} onChange={setSelectValue}>
                {v => v.value}
                {v => v.label}
            </Select>
            <p>
                You can make the contents searchable for long or complicated lists.
            </p>
            <Select<BasicSelectType> data={selectContents} value={selectValue} onChange={setSelectValue} searchable>
                {v => v.value}
                {v => v.label}
            </Select>

            <h2>Spinner</h2>
            <Spinner />

            <h2>Source Modal</h2>
            <p>Provide a variable and it will show the source information for that variable.</p>
            <Button color="primary" onClick={() => setSourceModal(true)}>Toggle Source Modal</Button>
            {sourceModal && <SourceModal variable={exampleVariable} onClose={() => setSourceModal(false)} />}

            <h2>Table</h2>
            <p>There is a general-purpose table you can use. But you usually will use the data table insight container</p>
            <div className="inline-block">
                <Table headers={[
                    new Variable('a', 'Thing A', '', 'text', {}, new StringFormatter()),
                    new Variable('b', 'Thing B', '', 'text', {}, new StringFormatter()),
                    new Variable('c', 'Thing C', '', 'text', {}, new StringFormatter()),
                ]} data={[
                    {a: 1, b: 'One', c: 'Un'},
                    {a: 2, b: 'Two', c: 'Deux'},
                    {a: 3, b: 'Three', c: 'Trois'},
                    {a: 4, b: 'Four', c: 'Quatre'},
                    {a: 5, b: 'Five', c: 'Cinq'},
                ]} />
            </div>

            <h2>Toggle</h2>
            <div>
                <Toggle checked={toggle1} onChange={() => setToggle1(!toggle1)} />
                <Toggle checked={toggle2} onChange={() => setToggle2(!toggle2)} />
                <Toggle checked onChange={() => true} disabled />
            </div>

            <h2>Tooltip</h2>
            <div>
                <Tooltip content="You can put tooltips over anything">
                    <Badge color="green">Hover over me!</Badge>
                </Tooltip>
                <br/>
                <Tooltip position="above" content={<div>
                    <p>Put any content you want.</p>
                    <p>Strings, JSX, it all <span className="text-green-500">works</span>!</p>
                </div>}>
                    <span className="underline text-red-500">Hover over me too!</span>
                </Tooltip>
            </div>

            <h2>VariableFilter</h2>
            <pre>{'<VariableFilter />'}</pre>

            <div style={{height: '1000px', width: '100%'}}>
                <h2>Map Selection</h2>
                <select className="my-5" onChange={e => setShape(e.target.value)}>
                    <option value="state">State</option>
                    <option value="county">County</option>
                    <option value="cbsa">Cbsa</option>
                </select>
                <MapSelection mapStyle={mapboxConfig.darkStyle} style={{height: 800}} shape={shape} values={[]} onClick={geoInfo => console.log(geoInfo)} year="2021"/>
            </div>

        </div>
    </Template>;
}