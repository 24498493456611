import React, {useState} from 'react';
import Modal from '../modal';
import Variable from '../../lib/metadata';
import {TestType} from 'quantum-graph';
import Select, {BasicSelectType} from '../select';
import {ViewType} from '../year-modal';
import Button from '../button';
import Filter from '../../lib/filter';

type Props = {
    variable: Variable,
    onCancel: () => void,
    onClose: (filters: Array<{time: string, v: Variable, testType: TestType}>) => void,
    filter?: Filter | null,
}

const ViewOptions: Array<BasicSelectType> = [
    {value: ViewType.Single, label: 'Single Date'},
    {value: ViewType.Range, label: 'Range of Dates'},
    {value: ViewType.Greater, label: 'After Date'},
    {value: ViewType.Lesser, label: 'Before Date'},
    {value: ViewType.Latest, label: 'Latest Date'},
    {value: ViewType.Earliest, label: 'Earliest Date'},
];

const ViewTestType: {[key: string]: TestType} = {
    [ViewType.Single]: TestType.Eq,
    [ViewType.Greater]: TestType.GtEq,
    [ViewType.Lesser]: TestType.LtEq,
    [ViewType.Latest]: TestType.Latest,
    [ViewType.Earliest]: TestType.Earliest
};

export default function TimeFilterModal(props: Props) {
    const {filter, variable, onCancel, onClose} = props;
    const [type, setType] = useState<string>(filter ? Object.keys(ViewTestType).find(key => ViewTestType[key] === filter.test as string) || ViewType.Single : ViewType.Single);
    const [startDate, setStartDate] = useState<Date>(filter ? parseDateValue(formatTimeToDate(filter.value as string)) : new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());

    function formatDate(date: Date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    function formatYearMonth(date: Date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');

        return `${year}${month}`;
    }

    function formatTimeToDate(value: string) {
        if (value.length <= 6)
            value += '01';
        return `${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6)}`;
    }

    function parseDateValue(value: string) {
        let date = value.split('-');
        return new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]));
    }

    function handleClose() {
        let date = formatDate(startDate);
        if (variable.name === 'year_month_key')
            date = formatYearMonth(startDate);

        if (type === ViewType.Range)
            onClose([{time: date, v: variable, testType: TestType.GtEq}, {time: formatYearMonth(endDate), v: variable, testType: TestType.LtEq}]);
        else
            onClose([{time: date, v: variable, testType: ViewTestType[type]}]);
    }

    return <Modal title="Edit Time" onClose={onCancel} hasButtons>
        <label className="font-bold block">
            Time View
        </label>
        <Select<BasicSelectType> data={ViewOptions} value={type} onChange={setType}>
            {_ => _.value}
            {_ => _.label}
        </Select>
        {type !== ViewType.Latest && type !== ViewType.Earliest && <div className="flex justify-between">
            <div className="mt-5 flex flex-col space-y-3">
                <label className="font-bold block">{type === ViewType.Range ? 'Start Date' : 'Enter a Date'}</label>
                <div>
                    <input type="date" value={formatDate(startDate)} onChange={(e) => setStartDate(parseDateValue(e.target.value))} />
                </div>
            </div>
            {type === ViewType.Range && <div className="flex items-center mt-12"><i className="icon-minus" /></div>}
            {type === ViewType.Range && <div className="mt-5 flex flex-col space-y-3">
                <label className="font-bold block">End Date</label>
                <div>
                    <input type="date" value={formatDate(endDate)} onChange={(e) => setEndDate(parseDateValue(e.target.value))} />
                </div>
            </div>}
        </div>}

        <div className="modal-buttons">
            <Button color="primary" onClick={handleClose}>Save</Button>
            <Button color="white" onClick={onCancel}>Cancel</Button>
        </div>
    </Modal>;
}