

export const PythonNLibs = `from urllib import request
import json`;

export const PythonPLibs = `import pandas as pd
import requests
import json
import io`;

export const PythonNExample1 = `# Replace with your own query
query_json = '''@query'''.encode('utf-8')

# Your current token
torch_insight_token = '@token'`;

export const PythonPExample1 = `# Replace with your own query
query_json = '''@query'''

# Your current token
torch_insight_token = '@token'`;

export const PythonNExample2 = `req = request.Request('https://api-prod.torchinsight.com/v2/quantum/data/execute', method="POST", data=query_json)
req.add_header('Content-Type', 'application/json')
req.add_header('Authorization', 'Bearer ' + torch_insight_token)
resp = request.urlopen(req)
compressed_data = json.loads(resp.read())
data = [dict(zip(compressed_data['fields'], row)) for row in compressed_data['data']]`;

export const PythonPExample2 = `resp = requests.post('https://api-prod.torchinsight.com/v2/quantum/data/execute?format=csv', json=json.loads(query_json), headers={'Authorization': 'Bearer ' + torch_insight_token})
data = pd.read_csv(io.StringIO(resp.text))`;

export const RExample1 = `# Replace with your own query
query_json <- '@query'

# Your current token
torch_insight_token <- '@token'`;

export const RExample2 = `resp <- httr::POST('https://api-prod.torchinsight.com/v2/quantum/data/execute?format=csv',
    body=query_json,
    httr::content_type_json(),
    httr::add_headers('Authorization' = paste('Bearer', torch_insight_token)))
data = read.csv(text = httr::content(resp, 'text'))`;