import {QuantumGraph} from 'quantum-graph';
import {useState} from 'react';
import Button from '../button';

type Props = {
  query: QuantumGraph;
  name: string;
  onDelete?: (index:number) => void;
  index:number
};

export default function QueryListItem(props: Props) {
    const {query, name, onDelete, index} = props;
    const [isJsonEditorVisible, setIsJsonEditorVisible] = useState(false);

    const toggleJsonEditorVisibility = () => {
        setIsJsonEditorVisible(!isJsonEditorVisible);
    };

    return (
        <div className="border-2 border-gray-300 rounded-md p-4 mb-4">
            <div className="flex justify-between items-center">
                <p className="text-lg font-semibold">{name}</p>
                {onDelete && <Button
                    color='red'
                    onClick={()=>onDelete(index + 1)}
                >
                 Delete
                </Button> }
            </div>
            <div className="mt-4">
                <button
                    onClick={toggleJsonEditorVisibility}
                    className="w-full py-2 px-4 border border-gray-300 rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    {isJsonEditorVisible ? 'Hide JSON Editor' : 'Show JSON Editor'}
                </button>
                {isJsonEditorVisible && (
                    <div className="mt-4">
                        <textarea value={JSON.stringify(query)} />
                    </div>
                )}
            </div>
        </div>
    );
}
