import React from 'react';
import {ExampleQueryBody} from '../quick-start';
import auth from '../../../../lib/auth';
import {
    PythonNExample1,
    PythonNExample2,
    PythonNLibs,
    PythonPExample1,
    PythonPExample2,
    PythonPLibs, RExample1, RExample2
} from './code-examples';
import QueryExample from './query-example';
import {AdvancedFiltering, AffiliationQuery, DimensionAndFact, ScaffoldQuery} from './query-examples';
import SiteName from '../../../../components/SiteName';


export default function APIDocExamples() {

    function sub(text: string) {
        return text
            .replace('@query', ExampleQueryBody)
            .replace('@token', auth.token!);
    }
    return <div>
        <h2>v2 Query Authoring Guide</h2>

        <p>
            Here are some example of different types of queries and examples of calling the <SiteName /> API from different languages and frameworks.
        </p>

        <p className="text-orange-400">Query Examples</p>
        <ol className="list-decimal list-inside">
            <li><a href="#simple-dimension">Simple dimension</a></li>
            <li><a href="#dimension-and-fact">Dimension and fact</a></li>
            <li><a href="#advanced-filtering">Advanced filtering</a></li>
            <li><a href="#scaffold">Dimension and multiple facts using a scaffold</a></li>
            <li><a href="#affiliations">Multiple dimensions using affiliations</a></li>
        </ol>

        <p className="text-orange-400">Language API Examples</p>
        <ol className="list-decimal list-inside">
            <li><a href="#python-native">Python - native</a></li>
            <li><a href="#python-pandas">Python - pandas and requests</a></li>
            <li><a href="#r-httr">R - httr</a></li>
        </ol>

        <h3>Query Examples</h3>

        <h4 id="simple-dimension">Simple Dimension</h4>
        <QueryExample query={ExampleQueryBody}>
            This pulls the first 10 rows (using pagination) of the <code>hospital</code> dataset.
        </QueryExample>

        <h4 id="dimension-and-fact">Dimension and Fact</h4>
        <QueryExample query={DimensionAndFact}>
            This connects the <code>facility</code> dataset to a fact to get back hospital bed counts.
        </QueryExample>

        <h4 id="advanced-filtering">Advanced Filtering</h4>
        <QueryExample query={AdvancedFiltering}>
            This uses an <code>in</code> filter to only look at facilities in certain states. It also uses a <code>is not null</code> filter
            to only include those that have another name they're known by.
        </QueryExample>

        <h4 id="scaffold">Dimension and multiple facts using a scaffold</h4>
        <QueryExample query={ScaffoldQuery}>
            This adds in multiple facts and sets up a scaffold across the <code>year_key</code> field. This is the best approach when working with time-based data.
        </QueryExample>

        <h4 id="affiliations">Multiple dimensions using affiliations</h4>
        <QueryExample query={AffiliationQuery}>
            This joins two <code>BaseQuery</code> groups together using an affiliation dataset. This returns all health system–facility ownership affiliations.
        </QueryExample>


        <h3>Language API Examples</h3>

        <h4 id="python-native">Python - Native</h4>
        <p>For python, you can use the built-in <code>urllib</code> and <code>json</code> libraries.</p>
        <pre>{PythonNLibs}</pre>

        <p>Prepare your query JSON and authentication token:</p>
        <pre>{sub(PythonNExample1)}</pre>

        <p>Prepare and submit the request, which returns in compressed JSON format. The last line converts it to a list of dicts.</p>
        <pre>{PythonNExample2}</pre>


        <h4 id="python-pandas">Python - Pandas and Requests</h4>
        <p>
            The <code>pandas</code> library is a staple in python data processing. The `requests` library facilitates
            working with web-based APIs.
        </p>

        <p>We begin with our imports:</p>
        <pre>{PythonPLibs}</pre>

        <p>As before, prepare your query JSON and authentication token. Note that this time we do not encode query <code>query_json</code> string.</p>
        <pre>{sub(PythonPExample1)}</pre>

        <p>We make the POST request with <code>requests</code> and load the CSV contents into a <code>pandas</code> data frame:</p>
        <pre>{PythonPExample2}</pre>

        <h4 id="r-httr">R - httr</h4>

        <p>You will first need to install the <code>httr</code> package:</p>
        <pre>install.packages("httr")</pre>

        <p>Prepare your query JSON and authentication token:</p>
        <pre>{sub(RExample1)}</pre>

        <p>With R, it's useful to pull the data back in CSV format and read it into a data frame:</p>
        <pre>{RExample2}</pre>


    </div>;
}
