import {observer} from 'mobx-react';
import {useContext, useState} from 'react';
import Button from '../../../../../components/button';
import CustomTextRenderer from '../../../../../insight-container/custom-text';
import {CanvasInsertContext} from '../../../lib/context';

function ConfigText() {
    const insertStore = useContext(CanvasInsertContext);
    let [templateText, setTemplateText] = useState(insertStore.templateText);
    
    return <div className='flex flex-col space-y-8'>
        <div>
            <h2>How to use</h2>
            <p>This insight container will take any rich text and allow you to specify a variable that will be dynamically updated based on the data model you previously created.</p>
            <p>To create a formatted variable use this template: &#123;&#123;=f["variable name here"]&#125;&#125;</p>
            <p>To create an unformatted variable use this template: &#123;&#123;=d["variable name here"]&#125;&#125;</p>
        </div>
        <div className='flex flex-grow'>
            <div className='w-1/2 flex flex-col space-y-5'>
                <textarea value={templateText} onChange={(e) => setTemplateText(e.target.value)} />
                <div>
                    <Button onClick={() => insertStore.templateText = templateText} color={'blue'}>Test</Button>
                </div>
            </div> 
            <div className='w-1/2 px-2'>
                <CustomTextRenderer dataConfig={insertStore.dataConfig} />
            </div>
        </div>
        <div>
            See documentation: <a href="https://olado.github.io/doT/index.html">dot templating</a>
        </div>
    </div>;
}

export default observer(ConfigText);
