import {QuantumGraph, GraphVersions} from 'quantum-graph';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import {CanvasBoardJSON, CvInsertScreen, ICConfig} from './types';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';

/**
 * Returns the index of the last section in the given board.
 * If the board is undefined or has no sections, returns 0.
 * @param board The board to get the last section index from.
 * @returns The index of the last section in the board, or 0 if the board is undefined or has no sections.
 */
export function getLastSection(board?: CanvasBoardJSON) {
    if (board && board.sections && board.sections.length)
        return board.sections.length - 1;
    return 0;
}

/**
 * Validates if the given QuantumGraph has all the required columns.
 * @param graph The QuantumGraph to validate.
 * @param requiredColumns The list of required columns.
 * @returns True if the graph has all the required columns, false otherwise.
 */
export function validateQuery(graph: QuantumGraph, requiredColumns: string[]): boolean {
    if(!graph.validate(getDefaultDataDefRepo())) {
        return false;
    }
    if (graph.getVersion() === GraphVersions.V1) {
        return true;
    }

    if (graph.getVersion() === GraphVersions.V2) {
        const v2Graph = graph as V2QuantumGraph;
        const tableNodes = v2Graph.baseQueryGraph.baseQuery.tableNodes;
  
        const columnPresence = requiredColumns.map(() => false);
  
        for (const tableNode of tableNodes) {
            requiredColumns.forEach((column, index) => {
                if (tableNode.columns.includes(column)) {
                    columnPresence[index] = true;
                }
            });
  
            if (columnPresence.every(present => present)) {
                return true;
            }
        }
  
        return false;
    }
    else{
        return false;
    }
}

/**
 * Checks if the given visualization configuration is a map configuration.
 * @param visConfig The visualization configuration to check.
 * @returns True if the configuration is a map configuration, false otherwise.
 */
export function isMapConfig(visConfig?: ICConfig) {
    return !!visConfig && visConfig.screens.includes(CvInsertScreen.mapQuerySelection);
}

/**
 * Checks if the given visualization configuration is a chart configuration.
 * A chart configuration is defined as a configuration that includes
 * the screens for configuring a chart, text, or single value visualization.
 * @param visConfig The visualization configuration to check.
 * @returns True if the configuration is a chart configuration, false otherwise.
 */
export function isChartConfig(visConfig?: ICConfig) {
    const chartTypes = [CvInsertScreen.configVis, CvInsertScreen.configText, CvInsertScreen.configSingleValue, CvInsertScreen.configSankey];
    return !!visConfig && (visConfig.screens.some(s => chartTypes.includes(s)));
}