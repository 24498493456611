import React from 'react';

export const NoAccessErrMsg = 'You are not allowed access to the dataset';


export default function NoAccessMsg() {

    return <div className="border border-gray-200 bg-gray-100 text-gray-300 rounded py-4 text-center">
        <i className="icon-warning" /> You don't have access to this content
    </div>;
}