import GraphRectifier from './graph-rectifier';
import Variable from '../../../../metadata';
import {JoinNode, QuantumNode, TableInputNode} from 'quantum-graph/out/v1';
import V1GraphHandler from '../../../graph-handler/v1-graph-handler';


/**
 * This prunes out all the empty table input nodes. Note: this does not need to recuse up the tree because the apply
 * method is applied with every addition/removal so there will only ever be a single empty node at a time... I think
 */
export default class EmptyNodeRectifier extends GraphRectifier {

    findParentJoin(gh: V1GraphHandler, node: QuantumNode): [JoinNode | null, number] {
        while (true) {
            const [parent, port] = gh.findParent(node);
            if (!parent || parent instanceof JoinNode)
                return [parent, port];
            node = parent;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    apply(gh: V1GraphHandler, metadata: Array<Variable>, dataLibrary: Array<Array<Variable>>): void {
        gh.topNode.dfs((node: QuantumNode) => {
            if (node instanceof TableInputNode && !node.columns.length) {
                // Prune this node. We do this by finding the table node this is connected to and replace the join with it
                const [parentJoin, port] = this.findParentJoin(gh, node);
                if (parentJoin) {
                    const otherChild = parentJoin.getIncoming((port + 1) % 2);
                    const [nextParent, nextPort] = gh.findParent(parentJoin);
                    if (nextParent)
                        otherChild.connect(nextParent, nextPort);
                    else
                        gh.topNode = otherChild;
                }
            }
        });
    }

}