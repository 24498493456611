import ContentSection, {MyContentSection} from './content-section';
import {useMemo} from 'react';
import activityTracker from '../../../lib/activity-tracker';

const MAX_PREVIOUSLY_VIEWED = 5;
export default function PreviouslyViewed() {
    const previousViewedList: Array<MyContentSection> = useMemo(() => {
        return activityTracker.activityQueue.map((item: any) => ({title: `${item.toolName} ${item.pageName ? `- ${item.pageName}` : ''}`, url: item.pageUrl, timestamp: item.timestamp}));
    }, [activityTracker.activityQueue]);


    return <ContentSection title='Recently Viewed' content={previousViewedList.slice(0, MAX_PREVIOUSLY_VIEWED)} />;
}