import Variable from './metadata';


function checkString(value: string | undefined, search: string) {
    if (!value)
        return false;
    return value.toLowerCase().replace(/[^a-z0-9]/g, '').includes(search);
}

export function applySearch(variable: Variable, search: string): boolean {
    return checkString(variable.label, search) || checkString(variable.name, search) || checkString(variable.description, search);
}

export type VariableFilter = (v: Variable) => boolean;

export function searchFilter(search: string): VariableFilter {
    search = search.trim().toLowerCase().replace(/[^a-z0-9]/g, '');
    if (!search || search.length < 3)
        return () => true;
    return (variable: Variable) => applySearch(variable, search);
}
