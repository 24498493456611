import Template from '../../../components/template';
import PageTitle from '../../../components/page-title';
import toolDiscoveryData, {links, ToolDiscovery, ToolDiscoveryCategory} from './tool-discovery-data';
import {useEffect, useMemo, useState} from 'react';
import {MapConfig, Visualization} from '../../../lib/data-types';
import {mapConfigs, siteConfig, visualizations} from '../../../lib/api';
import Button from '../../../components/button';
import auth from '../../../lib/auth';
import SiteName from '../../../components/SiteName';

type Map = {
    type: string,
    map?: MapConfig,
    vis?: Visualization
}

export default function ToolDiscoveryPage() {
    const [userMaps, setUserMaps] = useState<Array<Map>>([]);
    const [mapRetrofit, setMapRetrofit] = useState<boolean>(false);
    const [seeMoreMaps, setSeeMoreMaps] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        const mapPromise = Promise.all([
            mapConfigs.listByUser(controller.signal),
            mapConfigs.listByUserOrgs(controller.signal)
        ]).then(([m1, m2]) => m1.concat(m2));

        const visPromise = Promise.all([
            visualizations.listByUser(controller.signal),
            visualizations.listByUserOrgs(controller.signal)
        ]).then(([v1, v2]) => v1.concat(v2));

        Promise.all([mapPromise, visPromise]).then(([mapConfigs, visualizations]) => {
            mapConfigs = mapConfigs.map((map: MapConfig) => ({type: 'map', map}));
            visualizations = visualizations.filter((v: Visualization) => v.type.startsWith('heatmap')).map((vis: Visualization) => ({type: 'vis', vis}));
            setUserMaps([...mapConfigs, ...visualizations]);
        });

        siteConfig.get('map-retrofit', controller.signal).then(result => {
            if (result)
                setMapRetrofit(JSON.parse(result.value));
        });

        return () => controller.abort();
    }, []);

    const filteredToolData = useMemo(() => {
        const tempData: Array<ToolDiscoveryCategory> = [];
        for (const category of toolDiscoveryData) {
            const tools: Array<ToolDiscovery> = category.tools.filter(t => t.authRole ? auth.hasRole(t.authRole) : true);
            if (tools.length > 0) {
                category.tools = tools;
                tempData.push(category);
            }
        }

        return tempData;
    }, []);

    function getMapLink(map: MapConfig) {
        const config = JSON.parse(map.config);
        if (mapRetrofit || (config.version && config.version.startsWith('pophealth:')))
            return `//${process.env.REACT_APP_TI_DOMAIN}/pophealth/${map.id}`;
        return `//${process.env.REACT_APP_TI_DOMAIN}/map/saved/${map.id}`;
    }

    function renderSavedMaps() {
        const MAX_MAPS = 5;
        let maps = userMaps;
        if (!seeMoreMaps)
            maps = userMaps.slice(0, MAX_MAPS);
        return <ul>
            {maps.map((m, i) => <li key={i}>
                {m.type === 'map' && <a href={getMapLink(m.map!)}>{m.map?.name}</a>}
                {m.type === 'vis' && <a href={`//${process.env.REACT_APP_TI_DOMAIN}/map/${m.vis?.id}`}>{m.vis?.name}</a>}
            </li>)}
            {userMaps.length > MAX_MAPS && <li className="mt-3">
                <Button color="blue" onClick={() => setSeeMoreMaps(!seeMoreMaps)}>{seeMoreMaps ? 'See Less' : 'See All'}</Button>
            </li>}
        </ul>;
    }

    return <Template showFooter>
        <div className="bg-gray-200" style={{minHeight: '65vh'}}>
            <PageTitle sticky name={<span>Discover <SiteName /> Tools</span>} links={links} />
            <div className="container">
                {filteredToolData.map((toolSection, index) => <div key={index} id={toolSection.name} className="p-8 border shadow-lg bg-white my-5">
                    <div className="flex flex-col border-b pb-10 mb-10 items-center">
                        <div className="w-full flex flex-col justify-center space-y-5 items-center">
                            <img className="h-16" src={toolSection.img} alt={toolSection.name} />
                            <h2 className="text-4xl">{toolSection.name}</h2>
                        </div>
                        <div className="mt-5 p-3">{toolSection.titleText}</div>
                    </div>
                    {toolSection.tools.map((tool, index) => <div key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} p-3 flex items-center space-around`}>
                        <div className="w-2/3 flex flex-col space-y-3">
                            <h3>{tool.name}</h3>
                            <div>{tool.description}</div>
                            {tool.buttonLink && <div>
                                <a href={tool.buttonLink} className="button blue hover:no-underline hover:bg-gray-300">{tool.buttonText}</a>
                            </div>}
                            {tool.name === 'Saved Maps' && renderSavedMaps()}
                        </div>
                        <div className="flex-grow flex justify-center">
                            <img className="h-24" src={tool.img} alt={tool.name} />
                        </div>
                    </div>)}
                </div>)}
            </div>
        </div>
    </Template>;
}