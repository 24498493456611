import {useState, useEffect} from 'react';
import {canvas} from '../../lib/api';
import Loading from '../../components/loading';
import {CanvasCard, CanvasLink} from '../canvas/landing';
import iconDashboard from './assets/icon-dashboard.png';

export default function CanvasSection() {
    const [sections, setSections] = useState<Array<any>>([]);


    useEffect(() => {
        const controller = new AbortController();
        canvas.listSections(controller.signal).then(sections => {
            setSections(sections);
        });
        return () => controller.abort();
    }, []);

    return (
        <div className="py-20 bg-gray-200">
            <h2 className="text-blue-500 flex uppercase items-center justify-center">
                <img src={iconDashboard} alt="Dashboards" className="h-10 mr-2"/>
            Canvases
            </h2>
            <p className="text-center text-blue-500 font-light">
            Expert curated content, just for you.
            </p>
            <div className="container my-10">
                {!sections.length && <Loading />}
                <div className='grid grid-cols-2 gap-4'>
                    {!!sections.length && sections.map((section, i, sections) => 
                        <CanvasCard 
                            key={i} 
                            className={`h-full ${(i === sections.length - 1 && sections.length % 2) ? 'col-span-2' : ''}`}
                            title={section.title}
                        >
                            {section.links.map((l: any) => <CanvasLink key={l.url} name={l.title} url={l.url} />)}
                        </CanvasCard>
                    )}         
                </div>
            </div>
        </div>
    );
}