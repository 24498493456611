import Variable from '../../../metadata';
import Library from '../../../library';
import {DataDefRepo} from '../../../user-data-defs';
import {DatasetDefinition} from 'torch-data-defs';
import {JoinType, QuantumGraph} from 'quantum-graph';
import V2QuantumGraph from 'quantum-graph/out/v2/v2-graph';
import TableNode from 'quantum-graph/out/v2/base-query/table-node';
import BaseQuery, {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import BaseQueryNode from 'quantum-graph/out/v2/base-query/base-query-graph';
import {pruneFacts, pruneFiltersSorts, pruneJoins} from './prune';
import {updateYearKey} from './update-year';
import createGraphHandler from '../../graph-handler/create';
import {V2Section} from '../../../library/v2-library';

function findNode(dataset: string, graph: V2QuantumGraph): TableNode | null {
    let node: TableNode | null = null;
    graph.forEachTable(n => {
        if (node) return;
        if (n.dataset === dataset)
            node = n;
    });
    return node;
}

function findBaseQuery(dataset: string, graph: V2QuantumGraph, dataDefRepo: DataDefRepo): BaseQuery | null {
    let baseQuery: BaseQuery | null = null;
    graph.forEachTable((n, q) => {
        if (baseQuery) return;
        if (n.dataset === dataset)
            baseQuery = q;

        // Also check if we have another table that has the appropriate grain
        const dataDef = dataDefRepo.datasetByName[n.dataset];
        const notFact = dataDef.tableType === 'composite' || dataDef.tableType === 'dim';
        if (notFact && dataDef.tableGrain.includes('!' + dataset))
            baseQuery = q;
    });
    return baseQuery;
}

function groupVariablesByDataset(variables: Array<Variable>) {
    const varsByDs: Record<string, Array<Variable>> = {};
    variables.forEach(v => {
        const ds = v.dataset || 'none';
        if (!varsByDs[ds])
            varsByDs[ds] = [];
        varsByDs[ds].push(v);
    });
    return varsByDs;
}

function toggleVars(node: TableNode, metadata: Array<Variable>, change: Array<Variable>) {
    for (const variable of change) {
        const i = metadata.findIndex(v => v.getId() === variable.getId());
        if (i >= 0) {
            node.columns = node.columns.filter(c => c !== variable.name);
            metadata.splice(i, 1);
        } else {
            node.columns.push(variable.name);
            metadata.push(variable);
        }
    }
    return metadata;
}

function addFact(graph: V2QuantumGraph, dataDef: DatasetDefinition, dataDefRepo: DataDefRepo, currentSection?: V2Section): TableNode {
    let dimGrain: string | undefined;
    if (currentSection) {
        dimGrain = currentSection.baseDataset;
    } else {
        const gh = createGraphHandler(graph, dataDefRepo);
        const graphDatasets = gh.getDatasets();
        dimGrain = dataDef.tableGrain.filter(g => g[0] === '!').find(g => graphDatasets.some(gd => gd === g.substring(1)));
        if (!dimGrain)
            dimGrain = dataDef.tableGrain.find(g => g[0] === '!');
        if (!dimGrain)
            throw new Error(`Dim Grain ${dataDef.name} not found`);
        dimGrain = dimGrain.substring(1);
    }
    const dimDataDef = dataDefRepo.datasetByName[dimGrain];
    let baseQuery = findBaseQuery(dimDataDef.name, graph, dataDefRepo);

    if (!baseQuery)
        baseQuery = addDim(graph, dimDataDef.name);

    const node = TableNode.deserialize({
        id: dataDef.name,
        dataset: dataDef.name,
        columns: [],
    });
    baseQuery.tableNodes.push(node);
    return node;
}

function addDim(graph: V2QuantumGraph, dataset: string): BaseQuery {
    const baseQuery = BaseQuery.deserialize({
        queryType: QueryType.Dim,
        tableNodes: [{
            id: dataset,
            dataset,
            columns: [],
        }],
    });
    const bqn = new BaseQueryNode();
    bqn.baseQuery = baseQuery;
    bqn.joins = [];
    graph.baseQueryGraph.joins.push({
        node: bqn,
        joinType: JoinType.Left,
    });
    return baseQuery;
}

export default function updateV2GraphSelection(graph: V2QuantumGraph, metadata: Array<Variable>, change: Array<Variable>, library: Library, dataDefRepo: DataDefRepo, currentSection?: V2Section): [QuantumGraph, Array<Variable>] {
    graph = V2QuantumGraph.deserialize(graph.serialize()) as V2QuantumGraph; // Clone
    metadata = metadata.slice();
    const varsByDs = groupVariablesByDataset(change);

    for (const [dataset, vars] of Object.entries(varsByDs)) {
        // Attempt to find an existing node for this dataset
        let node = findNode(dataset, graph);

        // If it doesn't exist then add it if possible
        if (!node) {
            const dataDef = dataDefRepo.datasetByName[dataset];
            if (!dataDef)
                continue;

            if (dataDef.tableType === 'fact') {
                node = addFact(graph, dataDef, dataDefRepo, currentSection);
            } else if (dataDef.tableType === 'dim' || dataDef.tableType === 'composite') {
                const baseQuery = addDim(graph, dataDef.name);
                node = baseQuery.tableNodes[0];
            }

            if (!node)
                continue;
        }

        // Toggle the vars in the query
        metadata = toggleVars(node, metadata, vars);
    }

    pruneFacts(graph.baseQueryGraph, dataDefRepo);
    pruneJoins(graph);
    pruneFiltersSorts(graph);
    updateYearKey(graph, library, dataDefRepo);

    return [graph, metadata];
}