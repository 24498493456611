import StandardDataRenderer from './renderers/standard-data-renderer';
import React from 'react';
import {DataConfig} from './types';


export default function TestRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void}) {
    return <StandardDataRenderer dataConfig={props.dataConfig} loadCB={props.loadCB}>
        {() => {
            return <div>
                Hi there. Test renderer here
            </div>;
        }}
    </StandardDataRenderer>;
}