import {ConditionalRender, ParameterConfig} from './types';
import {useEffect, useState} from 'react';
import {dataRow} from '../../../lib/data-types';
import fetchData from '../../../lib/data';
import {deserialize} from 'quantum-graph';
import {compareOperands} from './row-count-compare';
import {DataConfig} from '../../../insight-container/types';
import {applyParameters} from './parameters';


export default function useConditionalRender(parameterConfigs: Array<ParameterConfig> | undefined, conditionalRender: ConditionalRender | undefined, parameterRow: dataRow, handleError: (e: Error) => void) {
    const [conditionMet, setConditionMet] = useState<boolean>(false);
    const [conditionalRenderQuery, setConditionalRenderQuery] = useState<object | undefined>();
    const [loading, setLoading] = useState<boolean>(!!conditionalRender?.showLoading);

    useEffect(() => {
        setConditionMet(false);
        const controller = new AbortController();
        if (!conditionalRender)
            setConditionMet(true);
        else if (conditionalRenderQuery)
            fetchData(deserialize(conditionalRenderQuery), controller.signal)
                .then((data) => setConditionMet(compareOperands(data.length, conditionalRender.operator ,conditionalRender.rowCount)))
                .catch(e => handleError(e))
                .finally(() => setLoading(false));

        () => controller.abort();
    }, [conditionalRender, conditionalRenderQuery]);

    useEffect(() => {
        if (parameterConfigs && conditionalRender?.queryObj && Object.keys(parameterRow).length) {
            let config: DataConfig = {
                queries: [deserialize(conditionalRender?.queryObj)],
                options: []
            };
            config = applyParameters(config, parameterConfigs, parameterRow);
            setConditionalRenderQuery(config.queries[0].serialize());
        }

    }, [parameterConfigs, conditionalRender, parameterRow]);

    return [conditionMet, loading];
}