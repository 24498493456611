import AuthWall from '../../../components/auth-wall';
import './timeDistanceAccessPage.scss';
import RosterTable from '../components/roster-table';
import {Button, Layer, Loading} from '@carbon/react';
import {networkOptimization} from '../../../lib/api';
import {Download} from '@carbon/icons-react';
import download from '../../../lib/download';
import {useEffect, useState} from 'react';

function ProviderRosterPage() {
    const [exporting, setExporting] = useState(false);

    useEffect(() => {
        if (exporting) {
            networkOptimization.downloadProviderReport({}, 1, (new AbortController()).signal).then(response => {
                download(response, 'provider-roster.csv', 'text/csv');
                setExporting(false);
            });
        }
    }, [exporting]);
    return <AuthWall>
        <>
            <div className="time-distance-access py-8 bg-white border-b border-background-gray-200 flex justify-between items-center px-12">
                <h1 className="m-0 text-[28px] font-normal font-inherit leading-none">Roster</h1>
                <Button className="!pr-4 justify-between items-center" disabled={exporting} kind="primary" onClick={() => setExporting(true)}>
                    Export
                    <div className='w-9' />
                    {exporting ? <div className='w-4'><Loading small withOverlay={false} /></div> : <Download />}
                </Button>
            </div>
            <Layer className='p-6 pl-12 h-[calc(100vh-11rem+2px)]'>
                <div className="">
                    <RosterTable rosterNumber={1} />
                </div>
            </Layer>
        </>
    </AuthWall>;
}

export default ProviderRosterPage;
