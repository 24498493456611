import {DataConfig} from '../types';
import StandardDataRenderer, {StandardRenderChildProps} from '../renderers/standard-data-renderer';
import {getAffiliationConfig} from 'torch-affiliation-defs';
import {useMemo} from 'react';
import V2GraphHandler from '../../lib/graph-tools/graph-handler/v2-graph-handler';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import TabBar from '../../components/tab-bar';
import EntityAffiliationTable from './entity-affiliation-table';
import {affiliationDisplayNames} from '../../pages/tools/crosswalk-builder/query';

const affiliationConfig = getAffiliationConfig();

export function EntityAffiliationsVis(props: StandardRenderChildProps) {
    const {metadata, data, dataConfig} = props;
    const row = data[0];
    const dataset = useMemo(() => {
        const gh = new V2GraphHandler(dataConfig.queries[0] as V2QuantumGraph);
        return gh.getDatasets()[0];
    }, [dataConfig]);

    const datasetAffiliations = useMemo(() => {
        const affiliations = new Set<string>();
        for (const affConf of affiliationConfig) {
            if (affConf.From === dataset)
                affiliations.add(affConf.To);
            else if (affConf.To === dataset)
                affiliations.add(affConf.From);
        }
        return Array.from(affiliations).sort();
    }, [dataset]);

    const idVar = useMemo(() => {
        return metadata.find(v => v.getTag('FieldRole') === 'id');
    }, [metadata]);

    const entityId = useMemo(() => {
        if (idVar)
            return idVar.getValue(row) as number;
    }, [idVar, row]);


    return <div>
        {datasetAffiliations.length > 0 && <TabBar>
            {datasetAffiliations.map(aff => ({
                name: affiliationDisplayNames[aff],
                component: () => <EntityAffiliationTable dataset={`${dataset}:${aff}`} entityId={entityId} idVar={idVar} />
            }))}
        </TabBar>}
    </div>;
}

export default function EntityAffiliationsRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void}) {
    return <StandardDataRenderer dataConfig={props.dataConfig} loadCB={props.loadCB}>
        {EntityAffiliationsVis}
    </StandardDataRenderer>;
}