import React, {useEffect, useState} from 'react';
import {Navigate, useParams, useLocation} from 'react-router-dom';
import {QuantumGraph} from 'quantum-graph';
import Template from '../../../components/template';
import AuthWall from '../../../components/auth-wall';
import DataExplorer from './data-explorer';
import {QueryTableContext} from '../../../insight-container/query-table';
import './data-explorer.scss';
import loadQueryInfo from './loaders';
import Loading from '../../../components/loading';
import handleError from '../../../lib/error';
import ErrorMessage from '../../../components/error-message';
import {DataConfig} from '../../../insight-container/types';


export default function DataExplorerPage() {
    const [loading, setLoading] = useState(true);
    const [graph, setGraph] = useState<QuantumGraph | null>(null);
    const [title, setTitle] = useState('');
    const [error, setError] = useState('');
    const location = useLocation();
    const {id} = useParams<{id: string}>();

    useEffect(() => {
        const controller = new AbortController();
        loadQueryInfo(location.state, id!, controller.signal).then(queryInfo => {
            if (queryInfo) {
                setGraph(queryInfo.graph);
                setTitle(queryInfo.title);
            }
            setLoading(false);
        }).catch(err => handleError(err, setError));
        return () => controller.abort();
    }, [location.state, id]);

    if (!loading && !graph)
        return <Navigate replace to="/" />;

    const dataConfig: DataConfig = {
        queries: [graph!],
        options: {
            hideExplore: true,
            colorColumns: true,
            reference: id,
            title
        },
    };
    const back = location.state?.back || false;
    return <Template>
        <AuthWall>
            <div id="data-explorer-page">
                {error && <ErrorMessage error={error} />}
                {loading ?
                    <Loading /> :
                    <QueryTableContext dataConfig={dataConfig}>
                        <DataExplorer back={back} title={title} />
                    </QueryTableContext>}
            </div>
        </AuthWall>
    </Template>;
}