import React, {useCallback, useEffect, useState} from 'react';
import {organizations} from '../../../lib/api';
import Modal from '../../../components/modal';
import {useNavigate, useParams} from 'react-router-dom';
import Button from '../../../components/button';
import SimpleTable from '../../../components/simple-table';
import {dataRow, dataRows} from '../../../lib/data-types';
import ErrorMessage from '../../../components/error-message';
import Loading from '../../../components/loading';
import handleError from '../../../lib/error';

export default function AllowedDomains() {
    const [items, setItems] = useState<dataRows>([]);
    const [loading, setLoading] = useState(true);
    const [newDomain, setNewDomain] = useState('');
    const [err, setErr] = useState('');
    const navigate = useNavigate();
    const {id: idStr} = useParams<{id: string}>();
    const id = Number(idStr);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        organizations.domains.list(id, controller.signal)
            .then(items => {
                setItems(items);
                setLoading(false);
            })
            .catch(err => handleError(err, setErr));

        return () => controller.abort();
    }, [id]);

    const deleteDomain = useCallback((row: dataRow) => {
        const idx = items.findIndex(item => item.id === row.id);
        if (idx === -1)
            return;

        organizations.domains.delete(id, row['id'] as number)
            .catch(err => handleError(err, setErr));
        const newItems = items.slice();
        newItems.splice(idx, 1);
        setItems(newItems);
    }, [id, items]);

    async function addDomain() {
        try {
            const item = await organizations.domains.create(id, newDomain);
            setItems(items.slice().concat([item]));
            setNewDomain('');
        } catch (err) {
            handleError(err as Error, setErr);
        }
    }

    const actions = [
        {label: 'Delete', color: 'red', icon: 'icon-trash', onClick: deleteDomain}
    ];

    return <Modal title="Allowed Domains" onClose={() => navigate(-1)}>
        <Button color="light" onClick={() => navigate(-1)}>
            <i className="icon-arrow-left" /> Back
        </Button>

        {err && <ErrorMessage error={err} onClick={() => setErr('')} />}

        {loading ?
            <div className="mt-10 text-center">
                <Loading />
            </div> :
            <div>
                <div className={'flex space-x-5 my-3'}>
                    <input type="text" value={newDomain} onChange={e => setNewDomain(e.target.value)} />
                    <Button color="light" onClick={addDomain} disabled={!newDomain}>
                        <i className="icon-plus"/>
                    </Button>
                </div>
                <SimpleTable items={items} fields={[['domain', 'Domain']]} actions={actions} />
            </div>}
    </Modal>;
}