import {useContext, useMemo, useState} from 'react';
import Select from '../../components/select';
import {ChartType, getYOptions} from './common';
import MultiSelect from '../../components/multi-select';
import ChartContext from './context';
import {observer} from 'mobx-react';
import Checkbox from '../../components/checkbox';
import QueryDataContext from '../renderers/query-data-renderer/context';
import Loading from '../../components/loading';
import MultiEntryValue from '../../components/multi-entry-value';

let EditChart = observer(function EditChart({xAxisLabel, yAxisLabel, maxRows, multiAxis = true, chartType = ChartType.Bar}: {xAxisLabel:string, yAxisLabel:string, maxRows:number, multiAxis?:boolean, chartType?:ChartType}) {
    const chartContext = useContext(ChartContext);
    const queryContext = useContext(QueryDataContext);
    const {metadata} = queryContext;
    const legendLabelOptions = useMemo(() => metadata.map((m) => ({value: m.name, label: m.label})), [metadata]);
    const [useIdVariableForLine, setUseIdVariableForLine] = useState(!!chartContext.idVariable);

    if (!chartContext)
        return <Loading />;
    
    return <div className='flex flex-col space-y-3 gap-3'>
        <div>
            <label className='font-bold text-blue-600'>{xAxisLabel}</label>
            <div>
                <Select data={metadata} value={chartContext.xAxisName} onChange={(s) => chartContext.xAxisName = s}>
                    {(t) => t.name}
                    {(t) => t.label}
                </Select>
            </div>
        </div>
        <div>
            <label className='font-bold text-blue-600'>X Axis Label Character Limit</label>
            <div>
                <input type="number" value={chartContext.maxXLabelLength} onChange={(e) => chartContext.maxXLabelLength = Number(e.target.value)} min={15} />
            </div>
        </div>
        <div>
            <label className='font-bold text-blue-600'>{yAxisLabel}</label>
            {multiAxis && !useIdVariableForLine ? <MultiSelect
                data={getYOptions(metadata)}
                value={chartContext.yAxisNames}
                onChange={(s) => chartContext.yAxisNames = s}
                optionDisabled={(item) => chartContext.yAxisNames.includes(item.name)}
            >
                {(t) => t.name}
                {(t) => t.label}
            </MultiSelect> : <div>
                <Select
                    data={getYOptions(metadata)}
                    value={chartContext.yAxisNames[0]}
                    onChange={(s) => {
                        const newYAxisNames = [...chartContext.yAxisNames];
                        newYAxisNames[0] = s;
                        chartContext.yAxisNames = newYAxisNames;
                    }                        
                    }
                    optionDisabled={(item) => chartContext.yAxisNames[0] === item.name}
                >
                    {(t) => t.name}
                    {(t) => t.label}
                </Select>
            </div>}
            {chartType === ChartType.Line &&
                <div>
                    <label className='font-bold text-blue-600'>Use ID Variable</label>
                    <Checkbox checked={useIdVariableForLine} onChange={()=> setUseIdVariableForLine(!useIdVariableForLine)}/>
                    <div>
                        <Select
                            disabled={!useIdVariableForLine}
                            data={metadata}
                            value={chartContext.idVariable}
                            onChange={(s) => chartContext.idVariable = s}
                            optionDisabled={(item) => chartContext.idVariable === item.name}
                        >
                            {(t) => t.name}
                            {(t) => t.label}
                        </Select>
                    </div>
                </div>
            }

        </div>
        {chartType === ChartType.Scatter &&
            <div>
                <label className='font-bold text-blue-600'>ID Variable</label>
                <div>
                    <Select
                        data={metadata}
                        value={chartContext.idVariable}
                        onChange={(s) => chartContext.idVariable = s}
                        optionDisabled={(item) => chartContext.idVariable === item.name}
                    >
                        {(t) => t.name}
                        {(t) => t.label}
                    </Select>
                </div>
            </div>
        }
        {chartType === ChartType.Scatter &&
            <div>
                <label className='font-bold text-blue-600'>Use dynamic node size</label>
                <Checkbox checked={chartContext.nodeSize} onChange={()=>chartContext.nodeSize = !chartContext.nodeSize}/>
                <div>
                    <Select
                        disabled={!chartContext.nodeSize}
                        data={metadata}
                        value={chartContext.nodeSizeVariable}
                        onChange={(s) => chartContext.nodeSizeVariable = s}
                        optionDisabled={(item) => chartContext.nodeSizeVariable === item.name}
                    >
                        {(t) => t.name}
                        {(t) => t.label}
                    </Select>
                </div>
            </div>
        }
        <div>
            <label className='font-bold text-blue-600'>Slice Limit</label>
            <div>
                <input
                    type='number'
                    value={chartContext.rowsToShow}
                    max={maxRows}
                    min={1}
                    onChange={(e) => chartContext.rowsToShow = parseInt(e.target.value)}
                />
            </div>
        </div>
        <div>
            <label className='font-bold text-blue-600'>Chart Title</label>
            <div>
                <input
                    type='input'
                    value={chartContext.title}
                    onChange={(e) => chartContext.title = e.target.value}
                />
            </div>
        </div>
        <div>
            <label className='font-bold text-blue-600'>Label Font Size</label>
            <div>
                <input
                    type='number'
                    value={chartContext.labelFontSize}
                    max={64}
                    min={8}
                    onChange={(e) => chartContext.labelFontSize = parseInt(e.target.value)}
                />
            </div>
        </div>
        {(chartContext.isYearChart || chartContext.isYearMonthChart) && <div>
            <label className='font-bold text-blue-600'>Always Show Latest Years</label>
            <Checkbox checked={chartContext.showLatestYears} onChange={() => chartContext.showLatestYears = !chartContext.showLatestYears} />
        </div>}
        <div>
            <label className='font-bold text-blue-600'>Additional Tooltip Labels</label>
            <MultiSelect
                data={getYOptions(metadata)}
                value={chartContext.additionalToolTipVariables}
                onChange={(s) => chartContext.additionalToolTipVariables = s}
                optionDisabled={(item) => chartContext.xAxisName.includes(item.name)}
            >
                {(t) => t.name}
                {(t) => t.label}
            </MultiSelect>
        </div>
        <div className="pt-8">
            <label className='font-bold text-blue-600'>Legend Label Overrides</label>
            <MultiEntryValue selectOptions={legendLabelOptions} values={chartContext.legendLabelOverrides} onChange={(legendLabels) => chartContext.legendLabelOverrides = legendLabels} />
        </div>
    </div>;
});

export default EditChart;