import {dataRows, QuantumQuery} from '../../lib/data-types';
import Variable from '../../lib/metadata';


export default abstract class CompareTable {
    protected readonly _baseQuery: QuantumQuery;
    protected readonly _compareQueries: Array<QuantumQuery>;
    protected readonly _asPercentage: boolean;
    protected _compareMetadata: Array<Variable>;
    protected _compareData: dataRows;

    protected constructor(queries: Array<QuantumQuery>, asPercentage: boolean) {
        this._baseQuery = queries[0];
        this._compareQueries = queries.slice(1, queries.length);
        this._asPercentage = asPercentage;
        this._compareMetadata = [];
        this._compareData = [];
    }

    protected getBaseQueryValue(variable: Variable, index: number): number {
        const baseRow = this._baseQuery.data[index];
        return variable.getValue(baseRow) as number;
    }

    get baseQuery() {
        return this._baseQuery;
    }

    get compareMetadata() {
        return this._compareMetadata;
    }

    get compareData() {
        return this._compareData;
    }
}