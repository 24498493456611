import Variable from '../../lib/metadata';
import {QuantumGraph} from 'quantum-graph';
import {isDataVariable} from '../charts/common';
import {geoConnectingGrain} from '../map/map-libs/map-utils';
import {getDefaultDataDefRepo} from '../../lib/data-defs';

type ICCheck = (count: number, metadata: Array<Variable>, query?: QuantumGraph) => boolean;

const plotCheck: ICCheck = (count, metadata) => {
    if (count <= 1)
        return false;
    const timeVar = metadata.find(v => v.getTag('Time'));
    const dataVar = metadata.find(isDataVariable);
    return count > 1 && !!timeVar && !!dataVar;
};

const mapCheck: ICCheck = (count, metadata) => {
    if (count <= 1)
        return false;
    const hasGeography = metadata.find(v => v.dataset && geoConnectingGrain[v.dataset]);

    return count > 1 && !!hasGeography;
};

const entityAffiliationsCheck: ICCheck = (count, metadata) => {
    const isDim = metadata.find(v => (v.dataset && getDefaultDataDefRepo().datasetByName[v.dataset].tableType === 'dim'));
    return count <= 1 && !!isDim;
};

const pieChartCheck: ICCheck = (count, metadata) => {
    return metadata.length > 2;
};

const ICChecks: Record<string, ICCheck> = {
    'eoc-2':         () => false,
    'table':         () => false,
    'query':         () => true,
    'cr-benchmarks': () => false,
    'common-core':   () => false,
    'bar':           plotCheck,
    'plot':          plotCheck,
    'map':           mapCheck,
    'pie':           pieChartCheck,
    'profile':       count => count === 1,
    'entity-affiliations': entityAffiliationsCheck,
    'test':          () => false,
};

export default ICChecks;