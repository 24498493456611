
function capitalize(s: string) {
    return s[0].toUpperCase() + s.substr(1);
}

export default function titleCase(s: string) {
    const parts = s.split(/[^a-zA-Z]/);
    return parts
        .filter(_ => _)
        .map(capitalize)
        .join(' ');
}