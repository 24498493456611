const mapboxConfig = {
    accessToken: 'pk.eyJ1Ijoiam9zaG1vbnNvbmxwIiwiYSI6ImNpdTVra3ppODBocWkyeWxrZXBzNGFtaTMifQ.UHgpqEHghwKtcp0KQWpyNQ',
    darkStyle: 'mapbox://styles/mapbox/dark-v11',
    defaultStyle: 'mapbox://styles/joshmonsonlp/cjkst4vf477qi2so2ug9zux9g',
    darkv2Style: 'mapbox://styles/mapbox/navigation-night-v1',
    defaultLongitude: -98,
    defaultLatitude: 38.705494,
    defaultZoom: 4,
};

export default mapboxConfig;