import React, {useEffect} from 'react';
import auth from '../../lib/auth';


export default function AuthReceiverPage() {

    useEffect(() => {
        const listener = (m: MessageEvent) => {
            if (m.origin.endsWith(String(process.env.REACT_APP_TI_DOMAIN))) {
                const token = m.data;
                auth.setToken(token);
            }
        };
        window.addEventListener('message', listener);

        return function cleanup() {
            window.removeEventListener('message', listener);
        };
    });

    return <div />;
}