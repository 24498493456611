import React, {useMemo, useCallback} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList as List} from 'react-window';
import {ArrowRight, ArrowLeft} from '@carbon/icons-react';
import './pagination.scss'; 

const PAGE_SIZE_OPTIONS = [
    {text: '15', value: 15},
    {text: '20', value: 20},
    {text: '50', value: 50},
    {text: '100', value: 100},
    {text: '200', value: 200},
];

interface PaginationProps {
  totalItems: number;
  page: number;
  pageSize: number;
  onChange: (data: { page: number; pageSize: number }) => void;
}

const CustomPagination: React.FC<PaginationProps> = ({
    totalItems,
    page,
    pageSize,
    onChange,
}) => {
    const totalPages = useMemo(
        () => Math.ceil(totalItems / pageSize),
        [totalItems, pageSize]
    );

    const pageRangeText = useMemo(
        () => `of ${totalPages} ${totalPages === 1 ? 'page' : 'pages'}`,
        [totalPages]
    );

    const handlePageChange = useCallback(
        (newPage: number) => {
            onChange({page: newPage, pageSize});
        },
        [onChange, pageSize]
    );

    const handlePageSizeChange = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange({page: 1, pageSize: Number(event.target.value)});
        },
        [onChange]
    );

    const handlePageSelectChange = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            handlePageChange(Number(event.target.value));
        },
        [handlePageChange]
    );

    const handlePreviousPage = () => {
        if (page > 1) {
            handlePageChange(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            handlePageChange(page + 1);
        }
    };

    const PageDropdown = useMemo(() => {
        const items = Array.from({length: totalPages}, (_, index) => ({
            value: index + 1,
            text: index + 1,
        }));
        return (
            <select
                id="pageSelect"
                value={page}
                onChange={handlePageSelectChange}
                className="border border-gray-300 rounded px-2 py-1"
            >
                {items.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.text}
                    </option>
                ))}
            </select>
        );
    }, [totalPages, page, handlePageSelectChange]);

    return (
        <div className="pagination-container flex justify-between items-center py-2">
            <div className="pagination-left flex items-center gap-2">
                <div>
                    <label htmlFor="pageSize"></label>
                    <select
                        id="pageSize"
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        className="border border-gray-300 rounded px-2 py-1"
                    >
                        {PAGE_SIZE_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                </div>
                <span className="ml-2">
                    {(page - 1) * pageSize + 1}–{Math.min(page * pageSize, totalItems)} of {totalItems} records
                </span>
            </div>
            <div className="pagination-right flex items-center gap-2">
                <div>
                    <label htmlFor="pageSelect"></label>
                    {PageDropdown}
                </div>
                <div className="pagination-list">
                    <AutoSizer disableHeight>
                        {({width}: any) => (
                            <List
                                height={35 * 10} 
                                itemCount={totalPages}
                                itemSize={20} 
                                width={width}
                            >
                                {({index}) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(index + 1)}
                                        className={`pagination-button border border-gray-300 rounded px-2 py-1 ${
                                            index + 1 === page ? 'active bg-blue-500 text-white' : ''
                                        }`}
                                        disabled={index + 1 === page}
                                    >
                                        {index + 1}
                                    </button>
                                )}
                            </List>
                        )}
                    </AutoSizer>
                </div>
                <span>{pageRangeText}</span>
                <div className="pagination-icons flex items-center gap-1">
                    <button
                        onClick={handlePreviousPage}
                        className="pagination-icon-button flex items-center justify-center"
                        disabled={page <= 1}
                    >
                        <ArrowLeft />
                    </button>
                    <button
                        onClick={handleNextPage}
                        className="pagination-icon-button flex items-center justify-center"
                        disabled={page >= totalPages}
                    >
                        <ArrowRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomPagination;
