import Variable from '../../lib/metadata';
import {dataRows} from '../../lib/data-types';

const labels: Record<string, string> = {
    MED: 'Medical',
    SURG: 'Surgical',
    MAT: 'Maternity',
    NB: 'Newborn',
    MHSA: 'MH/SA',
    Total: 'Total',
    ER: 'Emergency Room',
    LAB: 'Lab',
    OTHER: 'Other',
    'OTHER PROF': 'Other',
    ANESTHESIA: 'Anesthesia',
    RAD: 'Radiology',
    SURGASC: 'ASC Surgery',
    SURGHOSP: 'Hospital Surgery',
    'E&M': 'E&M',
};

const descriptions: Record<string, Record<string, string>> = {
    Inpatient: {
        MED: 'IP Medical Admissions (excluding SNF)',
        SURG: 'IP Surgical Admissions',
        MAT: 'IP Maternity Admissions',
        NB: 'IP Newborn Admissions',
        MHSA: 'IP Mental Health and Substance Abuse Admissions',
    },
    Outpatient: {
        ER: 'OP Facility Emergency Room Services',
        SURGHOSP: 'OP Facility Surgical Services in a Hospital Setting',
        SURGASC: 'OP Facility Surgical Services in an Ambulatory Surgical Center Setting',
        RAD: 'OP Facility Radiology Services',
        LAB: 'OP Facility Laboratory Services',
        OTHER: 'Other OP Facility Services',
    },
    Physician: {
        SURG: 'Professional Surgical Services',
        RAD: 'Professional Radiology Services',
        LAB: 'Professional Laboratory Services',
        'E&M': 'Professional Evaluation & Management Services',
        ANESTHESIA: 'Professional Anesthesia Services',
        'OTHER PROF': 'Other Professional Services',
    },
};

const categoryOrder: Record<string, number> = {
    MED: 0,
    SURG: 1,
    MAT: 2,
    NB: 3,
    MHSA: 4,
    ER: 0,
    SURGHOSP: 1,
    SURGASC: 2,
    RAD: 3,
    LAB: 4,
    OTHER: 5,
    'E&M': 6,
    ANESTHESIA: 7,
    'OTHER PROF': 8,
};

export function addSummaryPivotMetadata(metadata: Array<Variable>, measures: Array<string>) {
    if (!measures.length)
        return metadata;
    let newMetadata = metadata.filter(v => v.name !== 'type_of_service' && !measures.includes(v.name));
    measures.forEach(measure => {
        const measureVar = metadata.find(v => v.name === measure)!;
        if (!measureVar)
            return;
        newMetadata = newMetadata.concat([
            new Variable('Inpatient_' + measureVar.name, measureVar.label + ' - Inpatient', measureVar.description, measureVar.type, measureVar.tags, measureVar.formatter, measureVar.dataset),
            new Variable('Outpatient_' + measureVar.name, measureVar.label + ' - Outpatient', measureVar.description, measureVar.type, measureVar.tags, measureVar.formatter, measureVar.dataset),
            new Variable('Physician_' + measureVar.name, measureVar.label + ' - Professional', measureVar.description, measureVar.type, measureVar.tags, measureVar.formatter, measureVar.dataset),
            new Variable('Total_' + measureVar.name, measureVar.label + ' - Total', measureVar.description, measureVar.type, measureVar.tags, measureVar.formatter, measureVar.dataset),
        ]);
    });
    return newMetadata;
}

function sortCategories(vars: Array<Variable>): Array<Variable> {
    const newVars: Array<Variable> = new Array(10).fill(null);
    for (const variable of vars) {
        const category = variable.getTag('BreakdownCategory');
        if (category && categoryOrder.hasOwnProperty(category)) {
            const index = categoryOrder[category];
            newVars[index] = variable;
        } else
            newVars.push(variable);
    }
    return newVars.filter(_ => _);
}

export function addBreakdownPivotMetadata(metadata: Array<Variable>, measures: Array<string>, typeOfService: string, data: dataRows) {
    if (!measures.length)
        return metadata;
    let newMetadata = metadata.filter(v => v.name !== 'category' && !measures.includes(v.name));
    measures.forEach(measure => {
        const values = Object.keys(data[0]).filter(v => v.endsWith(measure));
        const measureVar = metadata.find(v => v.name === measure)!;
        if (!measureVar)
            return;
        let newVars = values.map(v => {
            const category = v.split('_')[0];
            const label = measureVar.label + ' - ' + (labels[category] || category);
            let description = measureVar.description;
            if (descriptions[typeOfService][category])
                description = measureVar.label + ': ' + descriptions[typeOfService][category] + '.\n\n' + measureVar.description;
            const tags = Object.assign({}, measureVar.tags, {BreakdownCategory: category});
            return new Variable(v, label, description, measureVar.type, tags, measureVar.formatter, measureVar.dataset);
        });
        newVars = sortCategories(newVars);
        newMetadata = newMetadata.concat(newVars);
    });
    return newMetadata;
}