import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {CanvasType} from '../../canvas/lib/types';
import Loading from '../../../components/loading';
import {canvas} from '../../../lib/api';
import Button from '../../../components/button';
import checkAccess, {SiteFeature} from '../../../lib/check-access';
import auth from '../../../lib/auth';
import generateLink, {LinkType} from '../../../lib/links';
import {HeaderContext} from '../../../components/header/context';
import {TrashCan} from '@carbon/icons-react';

export default function CanvasPage() {
    const [canvases, setCanvases] = useState<Array<CanvasType>>([]);
    const [loading, setLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    const navigate = useNavigate();
    const [hoveredRow, setHoveredRow] = useState<number | null>(null);
    const headerStore = useContext(HeaderContext);

    useEffect(() => {
        const controller = new AbortController();
        canvas.listByUser(controller.signal).then(canvases => {
            setCanvases(canvases);
            setLoading(false);
        });
        return () => controller.abort();
    }, []);

    async function createBoard() {
        setCreating(true);
        canvas.create({name: '', title: '', userId: auth.principal?.sub}).then(({id}) => {
            navigate('/canvas/' + id + '/0' + '?draft=true');
        });
    }

    async function deleteCanvas(canvasObj: CanvasType) {
        if (window.confirm('Are you sure you want to delete?')) {
            await canvas.delete(canvasObj.id!);
            const idx = canvases.findIndex(c => c.id === canvasObj.id);
            const newCanvases = canvases.slice();
            newCanvases.splice(idx, 1);
            setCanvases(newCanvases);
        }
    }

    function legacyCanvasPage() {

        return <div className="container pb-10">
            <h1>Your Canvas Boards</h1>

            {loading ?
                <Loading /> :
                <div>
                    {canvases.length ?
                        <div className="insight-table mt-5">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Canvas Board</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {canvases.map(canvasObj => <tr key={canvasObj.id}>
                                        <td><Link to={generateLink(LinkType.Canvas, canvasObj.id)}>
                                            {canvasObj.name || <em>Untitled</em>}
                                        </Link></td>
                                        <td>
                                            <Button color="red" onClick={() => deleteCanvas(canvasObj)}>
                                                <i className="icon-trash" />
                                            </Button>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div> :
                        <div className={'w-full bg-white flex flex-col md:flex-row p-5 items-center'}>
                            <div className={'h-full mr-5 text-orange-500'}>
                                <i className={'icon-info text-3xl'} />
                            </div>
                            <div>
                                <p>You don't have any canvas boards created.</p>
                            </div>
                        </div>}

                    {checkAccess(SiteFeature.CreateDashboards) && <div className="mt-2">
                        <Button color="orange" disabled={creating} onClick={createBoard}><i className="icon-plus" /> New Canvas Board</Button>
                    </div>}
                </div>}
        </div>;
    }

    function dppCanvasPage() {

        return <div className="p-8">
            <div className='text-xl'>My Canvas Boards</div>
    
            {loading ?
                <Loading /> :
                <div>
                    {canvases.length ?
                        <div className="dpp-insight-table mt-5">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Canvas Board</th>
                                        <th className='w-4'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {canvases.map((canvasObj, i) => <tr onMouseOver={() => setHoveredRow(i)} onMouseLeave={() => setHoveredRow(null)} key={canvasObj.id}>
                                        <td><Link to={generateLink(LinkType.Canvas, canvasObj.id)}>
                                            {canvasObj.name || <em>Untitled</em>}
                                        </Link></td>
                                        <td>
                                            { i === hoveredRow && <TrashCan onClick={() => deleteCanvas(canvasObj)} size={16} /> }
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div> :
                        <div className={'w-full bg-white flex flex-col md:flex-row pt-5 items-center'}>
                            <div>
                                <p>You don't have any canvas boards created.</p>
                            </div>
                        </div>}
    
                    {checkAccess(SiteFeature.CreateDashboards) && <div className="mt-2">
                        <Button color="bg-milliman-blue-500" className='text-white !rounded-none' disabled={creating} onClick={createBoard}>New Canvas</Button>
                    </div>}
                </div>}
        </div>;

    }
    return headerStore.useNewDesign ? dppCanvasPage() : legacyCanvasPage();
}