import React from 'react';
import ReactDOM from 'react-dom/client';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import './index.scss';
import './carbonTheme.scss';
import reportWebVitals from './reportWebVitals';
import {MenuContext, MenuStore} from './components/popup/context';
import NotFoundPage from './pages/misc/not-found';
import AuthPage from './pages/user/auth';
import LandingPage from './pages/landing';
import UILibraryPage from './pages/misc/ui-library';
import TestPage from './pages/misc/test';
import LogInPage from './pages/user/login';
import PopupMenu from './components/popup/menu';
import DataExplorerPage from './pages/tools/data-explorer';
import HCIndexViewPage from './pages/tools/hc-index/view';
import AttributionPage from './pages/misc/attribution';
import WhatsNewPage from './pages/whats-new';
import APIDocumentation from './pages/documentation/api';
import AuthReceiverPage from './pages/user/auth-receiver';
import SiteAdminPage from './pages/site-admin';
import LookupDrgPage from './pages/tools/drg-tool/table-view';
import CrmApiDocumentation from './pages/documentation/crm-api';
import OrgMembership from './pages/org/membership';
import UserOrgsPage from './pages/user/orgs';
import UserPage from './pages/user/index';
import CRBenchmarks from './pages/tools/cr-benchmarks';
import CRBOverviewPage from './pages/tools/cr-benchmarks/overview';
import InsightContainerViewPage from './pages/insight-container';
import SearchPage from './pages/search';
import ToolDiscoveryPage from './pages/tools/tool-discovery';
import {initAnalyticsProvider} from './lib/analytics-provider';
import ActivityTrackerIframe from './pages/activity-tracker-iframe';
import HCIndexListPage from './pages/tools/hc-index/list/list';
import CommonCoreBenchmarkPage from './pages/tools/common-core';
import VisBuilderPage from './insight-container/vis-builder';
import CrosswalkBuilderPage from './pages/tools/crosswalk-builder';
import DownloadingPage from './pages/misc/downloading';
import CommonCoreOverviewPage from './pages/tools/common-core/overview';
import ManageMember from './pages/org/manage-member';
import InviteMember from './pages/org/invite-member';
import CanvasViewPage from './pages/canvas';
import AffiliationExplorerPage from './pages/tools/affiliation-explorer';
import HCGCostModelPage from './pages/tools/hcg-cost-model';
import HPTOverviewPage from './pages/tools/hpt-medicare-relativity/overview';
import PasswordResetPage from './pages/user/password-reset';
import ChangePasswordPage from './pages/user/change-password';
import JoinPage from './pages/user/join';
import {CanvasLandingPage} from './pages/canvas/landing';
import HPTInstitutionalTool from './pages/tools/hpt-medicare-relativity/institutional-tool';
import HPTProfessionalTool from './pages/tools/hpt-medicare-relativity/professional-tool';
import CanvasRedirect from './pages/canvas/canvas-redirect';
import {HeaderStore, HeaderContext} from './components/header/context';
import DataDictionaryPage from './pages/data-dictionary';
import {GlobalTheme} from '@carbon/react';
import NetworkOptimizationViewPage from './pages/network-optimization/index';
import TimeDistanceAccessPage from './pages/network-optimization/pages/timeDistanceAccessPage';
import NetworkAccessSummaryPage from './pages/network-optimization/pages/networkAccessSummaryPage';
import ProviderRosterPage from './pages/network-optimization/pages/providerRosterPage';
import MyFiles from './pages/network-optimization/pages/myFiles';

initAnalyticsProvider(process.env.REACT_APP_AP_KEY);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <MenuContext.Provider value={MenuStore.empty().setup()}>
            <HeaderContext.Provider value={HeaderStore.empty()}>
                <GlobalTheme theme={'g10'}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/ui-library" element={<UILibraryPage />} />
                            <Route path="/attribution" element={<AttributionPage />} />
                            <Route path="/whats-new" element={<WhatsNewPage />} />
                            <Route path="/test" element={<TestPage />} />
                            <Route path="/file-download/:id" element={<DownloadingPage />} />

                            <Route path="/auth" element={<AuthPage />} />
                            <Route path="/auth-receiver" element={<AuthReceiverPage />} />
                            <Route path="/login" element={<LogInPage />} />
                            <Route path="/reset-password" element={<PasswordResetPage />} />
                            <Route path="/change-password" element={<ChangePasswordPage />} />
                            <Route path="/join/:code" element={<JoinPage />} />
                            <Route path="/track-activity" element={<ActivityTrackerIframe />} />

                            <Route path="/ic/:id" element={<InsightContainerViewPage />} />

                            <Route path="/user/orgs" element={<UserOrgsPage />} />
                            <Route path="/user/orgs/:id" element={<UserOrgsPage />} />
                            <Route path="/org/:id/membership/:userId" element={<ManageMember />} />
                            <Route path="/org/:orgId/invite" element={<InviteMember />} />
                            <Route path="/user/*" element={<UserPage />} />

                            <Route path="/canvas/:id/:section" element={<CanvasViewPage />} />
                            <Route path="/canvas" element={<CanvasLandingPage />} />
                            <Route path="/profile/:title" element={<CanvasViewPage />} />
                            <Route path="/canvas-redirect/:dataset/:value" element={<CanvasRedirect />} />

                            <Route path="/vis-builder/:id" element={<VisBuilderPage />} />

                            <Route path="/search" element={<SearchPage />} />

                            {/*Tool Discovery*/}
                            <Route path="/tools" element={<ToolDiscoveryPage />} />

                            {/*Site Admin*/}
                            <Route path="/site-admin/*" element={<SiteAdminPage />} />

                            {/*Org Admin*/}
                            <Route path="/org/:id/membership" element={<OrgMembership />} />

                            {/* Data Explorer */}
                            <Route path="/data/explore" element={<DataExplorerPage />} />
                            <Route path="/data/:id" element={<DataExplorerPage />} />

                            <Route path="/data-dictionary" element={<DataDictionaryPage />} />

                            {/* HC Index */}
                            <Route path="/healthcare-index" element={<HCIndexListPage />} />
                            <Route path="/healthcare-index/:key" element={<HCIndexViewPage />} />
                            <Route path="/healthcare-index/:key/:queryId" element={<HCIndexViewPage />} />

                            <Route path="/drg/table" element={<LookupDrgPage />} />
                            <Route path="/cr-benchmarks" element={<CRBenchmarks />} />
                            <Route path="/cr-benchmarks/overview" element={<CRBOverviewPage />} />
                            <Route path="/cr-benchmarks/:region/:reportType/:metricType" element={<CRBenchmarks />} />
                            <Route path="/cr-benchmarks/:region/:reportType/:metricType/:qualifier" element={<CRBenchmarks />} />

                            <Route path="/common-core" element={<CommonCoreBenchmarkPage />} />
                            <Route path="/common-core/overview" element={<CommonCoreOverviewPage />} />
                            <Route path="/common-core/:key" element={<CommonCoreBenchmarkPage />} />

                            <Route path="/crosswalk-builder" element={<CrosswalkBuilderPage />} />
                            <Route path="/crosswalk-builder/:from/:to/:type" element={<CrosswalkBuilderPage />} />
                            <Route path="/affiliations" element={<AffiliationExplorerPage />} />
                            <Route path="/affiliations/:from/:to/:type" element={<AffiliationExplorerPage />} />

                            <Route path="/hpt/medicare-relativity-score" element={<HPTInstitutionalTool />} />
                            <Route path="/hpt/professional/medicare-relativity-score" element={<HPTProfessionalTool />} />
                            <Route path="/hpt/medicare-relativity-score/overview" element={<HPTOverviewPage />} />

                            <Route path="/hcg_cost_model" element={<HCGCostModelPage />} />

                            {/* Network Optimzation */}
                            <Route path="/network-optimization" element={<NetworkOptimizationViewPage />}>
                                <Route path="time-distance-access" element={<TimeDistanceAccessPage />} />
                                <Route path="network-access-summary" element={<NetworkAccessSummaryPage />} />
                                <Route path="my-files" element={<MyFiles />} /> 
                                <Route path="roster" element={<ProviderRosterPage />} />
                            </Route>

                            {/* Documentation */}
                            <Route path="/docs/api/*" element={<APIDocumentation />} />
                            <Route path="/docs/crm-api/*" element={<CrmApiDocumentation />} />

                            <Route element={<NotFoundPage />} />
                        </Routes>
                        <PopupMenu />
                    </BrowserRouter>
                </GlobalTheme>
            </HeaderContext.Provider>
        </MenuContext.Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
