import React from 'react';
import rStar from './images/r-star.png';
import affSnowflake from './images/aff-snowflake.png';
import Button from '../../../components/button';
import SiteName from '../../../components/SiteName';


export default function APIDocDataWarehouse() {
    return <div>
        <h2>Data Warehouse Structure</h2>
        <p>
            The data within our data warehouse is structured using standard warehousing practices.
        </p>
        <p>
            The primary data sets are either a <strong>dimension</strong>—core data around an entity or subject—or
            a <strong>fact</strong>—supplemental data usually on a geographic and/or temporal grain. We additionally
            have <strong>affiliation</strong> data that shows connections.
        </p>

        <h3>Reverse Star Schema</h3>
        <p>
            The dimensions in our data warehouse have many facts that are able to join to them. This pattern is sometimes
            referred to as the reverse star schema.
        </p>
        <div className="text-center my-6">
            <img src={rStar} alt="Reverse Star Schema" className="w-64 inline-block"/>
        </div>
        <p>
            This pattern also enables you to easily pull out many data points pertaining to certain entities. For example,
            for each hospital facility (dimension) you could add in the number of beds (fact) and revenue (fact).
        </p>

        <h3>Affiliation Snowflake Schema</h3>
        <p>
            In addition to information about entities, <SiteName /> also offers connections between entities. These
            connections we call <strong>affiliations</strong>. These affiliations connect dimensions together based on
            some sort of relationship.
        </p>
        <p>
            This is a variation of the snowflake schema, with these affiliations connecting inverse stars.
        </p>
        <div className="text-center my-6">
            <img src={affSnowflake} alt="Affiliation Snowflake Schema" className="inline-block" style={{width: 500}}/>
        </div>
        <p>
            Our affiliation data is crucial in understanding relationships and networks within healthcare.
        </p>

        <hr/>
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/api/query-construction">Query Construction</Button>
        </p>
    </div>;
}