import Variable, {ValueType} from '../../lib/metadata';
import {DataConfig} from '../types';
import {NavigateFunction} from 'react-router-dom';
import {Theme} from '@nivo/core';
import createGraphHandler from '../../lib/graph-tools/graph-handler/create';
import {dataRow, dataRows} from '../../lib/data-types';
import {BarDatum} from '@nivo/bar';
import {getDefaultDataDefRepo} from '../../lib/data-defs';
import {BasicFilter} from 'quantum-graph/out/v2/filter';
import {CanvasParameter} from '../../pages/canvas/lib/types';
import {DatumValue} from '@nivo/line';
import {V2QuantumGraph} from 'quantum-graph/out/v2';

export enum ChartType {
    Bar = 'bar',
    Line = 'plot',
    Scatter = 'scatter',
    Pie = 'pie',
}

export function isDataVariable(v: Variable) {
    return (
        v.getValueType() === ValueType.Number &&
        !Object.keys(v.tags).some((k) => k === 'FieldRole') &&
        !v.name.includes('dim') &&
        !v.name.includes('key')
    );
}

export const PlotColors = [
    '#ff8e3c',
    '#47639f',
    '#f43f5e',
    '#fbdd74',
    '#10b981',
    '#ef4444',
    '#8b5cf6',
    '#3b82f6',
    '#ee7d2b',
    '#36528e',
    '#e32e4d',
    '#eacc63',
    '#0fa870',
    '#de3333',
    '#7a4be5',
    '#2a71e5',
    '#dd6c1a',
    '#25417d',
    '#d21d3c',
    '#d9bb52',
    '#0e9760',
    '#cd2222',
    '#693ad4',
    '#1960d4',
];

export const PlotTheme: Theme = {
    textColor: '#6b7280',
    fontSize: 14,
    fontFamily:
        '"Nunito Sans", "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
};

export function getFilterById(idField: string, filters: BasicFilter[]): BasicFilter | null {
    for (let filter of filters) {
        if (filter.column.column === idField) {
            return new BasicFilter(filter.column, filter.test, filter.value);
        }
    }
    return null;
}

export function explore(navigate: NavigateFunction, query: V2QuantumGraph, dataConfig: DataConfig, parameter?: CanvasParameter) {
    if (dataConfig.options.exploreLink) {
        let navigationState: {query: string, filters?: string} = {query: JSON.stringify(query.serialize())};

        if (parameter) {
            const parameterFilter = getFilterById(parameter.idField, query.filters as Array<BasicFilter>);
            if (parameterFilter) {
                query.filters = query.filters.filter((f: any) => !(f.column && f.column.column === parameter.idField));
                navigationState.filters = JSON.stringify(parameterFilter.serialize());
            }
        }
        navigate(dataConfig.options.exploreLink, {state: navigationState});
    } else {
        sessionStorage.setItem('query', JSON.stringify(query.serialize()));
        navigate('/data/explore');
    }
}

export function getYOptions(metadata: Array<Variable>) {
    let options = metadata.filter((v) => isDataVariable(v));
    return options;
}

//Source https://github.com/evargast/getTextWidth/blob/master/src/index.ts
export const getTextWidth = (() => {
    const container = document.createElement('canvas');

    return function (inputText?: string | number | null, backupRatio = 0.52): number {
        let width = 0;
        let text = inputText ?? '';
        text = text.toString();

        let context = container.getContext('2d');

        if (context) {
            context.font = `${PlotTheme.fontSize}px ${PlotTheme.fontFamily}`;
            width = context.measureText(text).width;
            return width;
        } else {
            /* if something goes wrong mounting the canvas, return an estimate calculated using
             * the backup ratio, the average open-sans font height-width ratio of 0.5
             */
            let fontSize = parseFloat(
                window.getComputedStyle(document.body).getPropertyValue('font-size'),
            );
            return fontSize * backupRatio * text.length;
        }
    };
})();

export function limitRowsForChart(dataConfig:DataConfig, maxRows:number): DataConfig {
    const gh = createGraphHandler(dataConfig.queries[0], getDefaultDataDefRepo());
    gh.applyPagination(0, maxRows);
    return {...dataConfig, queries: [gh.build()]};
}

export function formatData(data: dataRows, showLatestYears: boolean, rowsToShow: number, xAxisName?: string, maxXLabelLength?: number, idVariable?: string) {
    //create deep copy of data array
    let rows: BarDatum[] = []; 
    if (idVariable) {
        let uniqueIds = Array.from(new Set(data.map(row => row[idVariable]))).slice(0, rowsToShow);
        rows = uniqueIds.flatMap(id => data.filter(row => row[idVariable] === id)) as BarDatum[];
    } else {
        rows = JSON.parse(JSON.stringify((showLatestYears ? data.slice(-rowsToShow) : data.slice(0, rowsToShow)))) as BarDatum[];
    }
    if (maxXLabelLength && xAxisName) {
        rows = rows.map(row => {
            let xValue = row[xAxisName] as string;
            row.untrucatedXAxisName = row[xAxisName];
            if (xValue.length > maxXLabelLength!)
                row[xAxisName] = (row[xAxisName] as string).substring(0, maxXLabelLength) + '...';
            return row;
        }) as BarDatum[];
    }
    return rows;
}

export function formatXAxisByType(xAxis: string, row: dataRow): DatumValue {
    let value: DatumValue = row[xAxis] as DatumValue;
    if (xAxis === 'year_key')
        value = new Date(Number(row[xAxis]), 11, 31);
    else if (xAxis === 'year_month_key') {
        let year = value.toString().substring(0, 4);
        let month = value.toString().substring(4, 6);
        value = new Date(Number(year), Number(month) - 1, 1);
    }

    return value;
}

export function generateAxisBottomConfig(isYearChart: boolean, isYearMonthChart:boolean) {
    if (isYearChart)
        return {
            tickValues: 'every 1 year',
            tickRotation: 30,
            format: '%Y',
        };
    else if (isYearMonthChart)
        return {
            tickValues: 'every 1 month',
            tickRotation: 30,
            format: '%Y-%m',
        };

    return {tickRotation: 30};
}