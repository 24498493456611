import React, {memo, useCallback, useEffect, useMemo} from 'react';
import {Handle, Position, useNodeId, useReactFlow, useStore, useUpdateNodeInternals} from 'reactflow';
import {getLayoutedElements} from './util';
import {loadDatasetInfo} from '../../lib/metadata';
import {NumberFormatter} from '../../lib/formatter';
import {affiliationDisplayNames} from '../../pages/tools/crosswalk-builder/query';

const displayNames: Record<string, string> = {
    ...affiliationDisplayNames,
    'aco': 'ACOs',
    'payment-arrangement': 'Payment Arrangements',
    'payer': 'Payers',
    'provider': 'Providers',
    'provider-group': 'Provider Groups'
};

async function getDatasetLabel(affDataset: string): Promise<string> {
    let parts = affDataset.split(':');
    let datasetName = parts.find((p) => !p.includes('health-system')) || '';
    let signal = new AbortController().signal;
    return displayNames[datasetName] || await loadDatasetInfo(datasetName, signal).then((info) => info?.label || datasetName);
}

let SystemNode = memo(({data}: {data: any}) => {
    let [collapsed, setCollapsed] = React.useState(true);
    const nodeInternals = useStore((state) => state.nodeInternals);
    const nodeId = useNodeId();
    const updateNodeInternals = useUpdateNodeInternals();
    const instance = useReactFlow();
    const [datasets, setDatasets] = React.useState<{[key: string]: string}>();

    const collapse = useCallback((c: boolean) => {
        setCollapsed(c);
        updateNodeInternals(nodeId!);
    }, []);

    useEffect(() => {
        const {nodes: newNodes, edges: newEdges} = getLayoutedElements(Array.from(nodeInternals.values()), instance.getEdges(), 'LR');
        instance.setNodes(newNodes);
        instance.setEdges(newEdges);
    }, [collapsed]);

    const stats = useMemo(() => 
        Object.keys(data).filter(key => key.includes(':')).map((key) => ({
            [key]: data[key].length
        })).reduce((acc, cur) => ({...acc, ...cur}), {})
    , [data]);

    useEffect(() => {
        async function getDatasetLabels(): Promise<{[key: string]: string}> {
            let test = await Promise.all(Object.keys(data).map(async (key) => {
                let label = await getDatasetLabel(key);
                return {
                    [key]: label
                };
            }));
            return test.reduce((acc, cur) => ({...acc, ...cur}), {});
        }

        getDatasetLabels().then((labels) => {
            setDatasets(labels);
        });
    }, [data]);

    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
            />
            <div className='bg-blue-600 rounded border-gray-800 border-2 pt-2 relative transition text-white' style={{width: '400px'}}>
                <div className='text-center font-semibold text-sm px-2' >
                    {data.label}
                </div>
                <div className='bg-blue-700 pb-1'>
                    <div className='text-center text-xs cursor-pointer text-blue-500 border-t mt-2 border-gray-800' onClick={() => collapse(!collapsed)}>
                        {collapsed ? '▼' : '▲'}
                    </div>
                    {!collapsed && <>
                        <div className='grid grid-cols-2 gap-2 m-2'>
                            {
                                Object.keys(stats).map((key) => {
                                    return <div className='rounded bg-blue-500' key={key}>
                                        <div className='text-large bg-blue-600 p-2 font-semibold'>
                                            {datasets ? datasets[key.toString()] : ''}
                                        </div>
                                        <div className='text-sm p-2'>
                                            {(new NumberFormatter(0, `No ${datasets ? datasets[key.toString()] : ''}`)).format(data[key].length)}
                                        </div>
                                    </div>;
                                })
                            }
                            {data.facilities && <div className='rounded bg-blue-500' key='facilities'>
                                <div className='text-large bg-blue-600 p-2 font-semibold'>
                                    Facilities
                                </div>
                                <div className='text-sm p-2'>
                                    {(new NumberFormatter(0, `No Facilities`)).format(data.facilities.length)}
                                </div>
                            </div>}
                        </div>
                    </>}
                </div>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                className='-z-10'
            />
        </>
    );
});

export default SystemNode;
