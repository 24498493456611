import {Source, useMap, Layer} from 'react-map-gl';
import {LayerProps} from './types';
import {useState, useEffect} from 'react';
import {useDebounce} from './layer-utils';
import {CirclePaint} from 'mapbox-gl';

export default function Circles(props: LayerProps) {
    const {data, id, paint, cluster = false} = props;
    const circlePaint = paint as Exclude<CirclePaint, undefined>;

    const {current: map} = useMap();
    const [zoom, setZoom] = useState(map ? map.getZoom() : 0);

    const debouncedZoom = useDebounce(zoom, 250);

    useEffect(() => {
        if (map) {
            const handleZoom = () => {
                setZoom(map.getZoom());
            };

            map.on('zoom', handleZoom);

            return () => {
                map.off('zoom', handleZoom);
            };
        }
    }, [map]);

    const size = debouncedZoom * (typeof circlePaint['circle-radius'] === 'number' ? circlePaint['circle-radius'] : 2.5);

    return (
        <Source id={String(id)} key={id} type='geojson' cluster={cluster} data={data}>
            <Layer
                id={String(id)}
                type='circle'
                paint={{...paint, 'circle-radius': size} as CirclePaint}
            />
        </Source>
    );
}
