import {CanvasBoardJSON, CanvasSection, CvRichText, CvRowType} from './types';
import Fuse from 'fuse.js';
import {deserialize} from 'quantum-graph';
import createGraphHandler from '../../../lib/graph-tools/graph-handler/create';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';

export function searchBoard(keyword:string, board?:CanvasBoardJSON):CanvasBoardJSON | null {
    if(board) {
        const output: (CvRowType | string)[] = [];
        const {segmentSearchedBoard,matchingSegments} = searchSegments(board,keyword);
        matchingSegments.forEach(segment => {
            output.push(...segment);
        });
        const {titleSearchedBoard,matchingRows} = searchTitles(segmentSearchedBoard,keyword);
        output.push(...matchingRows);
        const {varSearchedBoard, varMatchRows} = searchVariables(titleSearchedBoard,keyword);
        output.push(...varMatchRows);
        const {textMatchRows} = searchRichText(varSearchedBoard,keyword);
        output.push(...textMatchRows);


        const section: CanvasSection = {
            title: 'Search Results',
            rows: output
        };

        const searchResults: CanvasBoardJSON = {
            ...board,
            sections: [section]
        };

        return searchResults;
    }
    return null;
}

export const searchSegments = (board: CanvasBoardJSON, keyword: string): { segmentSearchedBoard: CanvasBoardJSON, matchingSegments: Array<Array<CvRowType | string>> } => {
    let segments: Array<Array<CvRowType | string>> = [];

    board.sections.forEach(section => {
        let currentSegment: Array<CvRowType | string> = [];
        section.rows.forEach(row => {
            if (typeof row === 'string') {
                if (currentSegment.length) {
                    segments.push(currentSegment);
                    currentSegment = [];
                }
                currentSegment.push(row);
            } else {
                currentSegment.push(row);
            }
        });
        if (currentSegment.length) {
            segments.push(currentSegment);
        }
    });

    const fuse = new Fuse(segments, {
        keys: ['0'],
        threshold: 0.5,
        isCaseSensitive: false
    });


    const results = fuse.search(keyword);
    const matchingSegments = results.map(result => result.item);

    const isSegmentMatching: boolean[] = segments.map(segment => {
        const title = segment[0];
        if (typeof title === 'string') {
            return matchingSegments.some(match => match[0] === title);
        }
        return false;
    });

    const segmentSearchedBoard: CanvasBoardJSON = {...board};
    let segmentIndex = -1;

    segmentSearchedBoard.sections = segmentSearchedBoard.sections.map(section => {
        const newSection: CanvasSection = {...section};
        newSection.rows = newSection.rows.filter(row => {
            if (typeof row === 'string') {
                segmentIndex++;
            }
            return !isSegmentMatching[segmentIndex];
        });
        return newSection;
    });

    return {
        segmentSearchedBoard,
        matchingSegments
    };
};

export const searchTitles = (board: CanvasBoardJSON, keyword: string): { titleSearchedBoard: CanvasBoardJSON, matchingRows: Array<CvRowType> } => {
    let matchingRows: Array<CvRowType> = [];

    const updatedSections: Array<CanvasSection> = board.sections.map(section => {
        const newRows: Array<CvRowType | string> = [];

        for (const row of section.rows) {
            if (typeof row !== 'string' && row.some(item => {
                if (item.type === 'data' && item.options?.title) {
                    const title: string = item.options.title;
                    const fuse = new Fuse([title], {
                        threshold: 0.3,
                        isCaseSensitive: false
                    });
                    const results = fuse.search(keyword);
                    return results.length > 0;
                }
                return false;
            })) {
                matchingRows.push(row);
            } else {
                newRows.push(row);
            }
        }

        return {
            ...section,
            rows: newRows
        };
    });

    const titleSearchedBoard: CanvasBoardJSON = {
        ...board,
        sections: updatedSections
    };

    return {
        titleSearchedBoard,
        matchingRows
    };
};


export const searchVariables = (board: CanvasBoardJSON, keyword: string): { varSearchedBoard: CanvasBoardJSON, varMatchRows: Array<CvRowType> } => {
    let matchingRows: Array<CvRowType> = [];

    const updatedSections: Array<CanvasSection> = board.sections.map(section => {
        const newRows: Array<CvRowType | string> = [];

        for (const row of section.rows) {
            if (typeof row !== 'string') {
                let isMatch = false;

                for (const item of row) {
                    if (item.type === 'data' && item.queryObj) {
                        let graph = item.queryObj.length ? deserialize(item.queryObj[0]) : deserialize(item.queryObj);
                        const graphHandler = createGraphHandler(graph, getDefaultDataDefRepo());
                        const vars = graphHandler.extractVariables();

                        const fuse = new Fuse(vars.map(v => v.variable), {
                            threshold: 0.3,
                            isCaseSensitive: false
                        });
                        const results = fuse.search(keyword);

                        if (results.length > 0) {
                            isMatch = true;
                            break;
                        }
                    }
                }

                if (isMatch) {
                    matchingRows.push(row);
                } else {
                    newRows.push(row);
                }
            } else {
                newRows.push(row);
            }
        }

        return {
            ...section,
            rows: newRows
        };
    });

    const varSearchedBoard: CanvasBoardJSON = {
        ...board,
        sections: updatedSections
    };

    return {
        varSearchedBoard,
        varMatchRows: matchingRows
    };
};

export const searchRichText = (board: CanvasBoardJSON, keyword: string): { textSearchedBoard: CanvasBoardJSON; textMatchRows: CvRowType[] } => {
    const matchingRows: CvRowType[] = [];
    const updatedBoard: CanvasBoardJSON = {...board};

    // Extract text from rich-text value
    const extractTextFromRichText = (richTextValue: string): string[] => {
        const parsedValue = JSON.parse(richTextValue);
        let texts: string[] = [];

        parsedValue.root.children.forEach((child:any) => {
            child.children.forEach((textChild:any) => {
                if (textChild.text) {
                    texts.push(textChild.text);
                }
            });
        });

        return texts;
    };

    const containsKeyword = (richTextValue: string): boolean => {
        const texts = extractTextFromRichText(richTextValue);
        const fuse = new Fuse(texts, {threshold: 0.3,isCaseSensitive: false});
        const result = fuse.search(keyword);

        return result.length > 0;
    };

    updatedBoard.sections = updatedBoard.sections.map(section => {
        const newRows: Array<CvRowType | string> = [];

        section.rows.forEach(row => {
            if (Array.isArray(row)) {
                const richTextElement = row.find(item => item.type === 'rich-text');

                if (richTextElement && containsKeyword((richTextElement as CvRichText).value)) {
                    matchingRows.push(row);
                } else {
                    newRows.push(row);
                }
            } else {
                newRows.push(row);
            }
        });

        return {
            ...section,
            rows: newRows
        };
    });

    return {
        textSearchedBoard: updatedBoard,
        textMatchRows: matchingRows
    };
};
