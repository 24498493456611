import React from 'react';
import './container.scss';
import findRenderer from './renderers';
import {InsightContainerConfig} from './types';


export default function InsightContainer(props: InsightContainerConfig) {
    const Renderer = findRenderer(props.type);

    return <div className="insight-container">
        <Renderer dataConfig={props.dataConfig} loadCB={props.loadCB} transforms={props.transforms}/>
    </div>;
}