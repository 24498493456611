import {observer} from 'mobx-react';
import {useContext, useMemo, useState} from 'react';
import {QueryTableContext, QueryTableVis} from '../../../../insight-container/query-table';
import {CanvasContext, CanvasInsertContext} from '../../lib/context';
import AffiliationExplorer from '../../../tools/affiliation-explorer/view';
import {QuantumGraph} from 'quantum-graph';
import ParameterDataModel from './parameter-data-model';
import Alert from '../../../../components/alert';
import ErrorMessage from '../../../../components/error-message';
import {isChartConfig, isMapConfig} from '../../lib/util';

function ModelVisData() {
    const insertStore = useContext(CanvasInsertContext);
    const canvasStore = useContext(CanvasContext);
    const [affConfig, setAffConfig] = useState<{from: string, to: string, type: string}>();
    const [error, setError] = useState<false | string>(false);

    const dataConfig = useMemo(() => insertStore.dataConfig, [insertStore.query, canvasStore.parameterRow, insertStore.visTitle]);

    function onAffiliationSelect(graph: QuantumGraph, affData: {from: string, to: string, type: string}) {
        insertStore.reset();
        insertStore.isAffiliationQuery = true;
        insertStore.query = graph;
        setAffConfig(affData);
    }

    function renderModelConfigMenu() {
        return <div>
            <div className="w-full flex space-x-12 mb-3">
                <div className="flex-grow">
                    {!canvasStore.setupParameter && <div>
                        {!!Object.keys(canvasStore.parameterRow).length && <ParameterDataModel />}
                        {!Object.keys(canvasStore.parameterRow).length && <Alert color="orange" icon="icon-warning">Canvas parameter not set</Alert>}
                    </div>}
                    {error && <ErrorMessage error={error} onClick={()=>setError(false)} actionText='Ok'/>}
                </div>
            </div>
        </div>;
    }

    return <div className="flex-auto">
        <div className="w-full flex flex-col px-5">
            <div className="flex space-x-5 items-center">
                <h2 className="m-0">Model Data</h2>
                <p>Build the data you want to use with your visualization</p>
            </div>
            {insertStore.isAffiliationQuery && <AffiliationExplorer onSelect={onAffiliationSelect} from={affConfig?.from} to={affConfig?.to} type={affConfig?.type} />}
        </div>
        {!!dataConfig.queries.length && <QueryTableContext dataConfig={dataConfig}>
            <div className="px-5 mt-3">
                {renderModelConfigMenu()}
                <QueryTableVis titleOverride={insertStore.visTitle} saveDataCB={(q) => {
                    try {
                        insertStore.query = q;
                        if (isChartConfig(insertStore.visConfig) || canvasStore.isAddConditionalRender())
                            insertStore.nextScreen();
                        else if (isMapConfig(insertStore.visConfig))
                            insertStore.addQuery(q);
                        else
                            insertStore.applyInsert();
                    } catch(e) {
                        setError((e as Error).message);
                    }
                    
                }}/>
            </div>
        </QueryTableContext>}
    </div>;
}

export default observer(ModelVisData);