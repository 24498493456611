import {ReactElement, RefObject, cloneElement} from 'react';
import './header.scss';
import Tooltip from '../../../../components/tooltip';

type Props = {
    id: string,
    icon: ReactElement,
    tooltipName: string
    popupRef?: RefObject<HTMLButtonElement>,
    onClick?: () => void,
    highlight?: boolean
}
export default function HeaderButton(props: Props) {
    const {id, icon, tooltipName, popupRef, onClick, highlight} = props;    

    const iconWithClass = cloneElement(icon, {
        className: 'icon'
    });

    return <Tooltip position="below" className="h-full w-full px-3 py-4" narrow content={<span className="w-full flex justify-center">{tooltipName}</span>}>
        <div className={`w-full h-full ${highlight ? 'bg-gray-500 bg-opacity-40' : ''}`}>
            <button
                className='w-full h-full flex text-xl focus:border-2 border-white text-white items-center justify-center'
                id={id}
                aria-haspopup={!!popupRef}
                ref={popupRef}
                onClick={onClick}
            >
                {iconWithClass}
            </button>
        </div>
    </Tooltip>;
}