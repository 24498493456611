import React from 'react';
import Button from '../../../components/button';


export default function APIDocDataFormats() {
    return <div>
        <h2>Supported Data Formats</h2>

        <p>
            You can specify the format you want the data to be returned as. There are currently two supported data formats: <code>json</code> and <code>csv</code>.
        </p>
        <p>
            You specify the format in the url by appending <code>{'?format={format}'}</code> to the path. If no format
            is supplied, then <code>json</code> is the default value. For example, for <code>csv</code>, the URL would be:
        </p>
        <pre>{'https://api-prod.torchinsight.com/v2/quantum/data/execute?format=csv'}</pre>

        <p>
            You can also specify in the query parameters the Stream-Type in order to give you a streamed response. This is useful for html form elements.
        </p>
        <div>Example: <pre>{'https://api-prod.torchinsight.com/v2/quantum/data/execute?format=csv&stream-type=chunked'}</pre></div>

        <hr/>
        <p>
            Next up: <Button className="ml-5" color="blue" to="/docs/api/metadata">Retrieving Metadata</Button>
        </p>
    </div>;
}