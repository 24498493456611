import React, {ReactNode} from 'react';
import classNames from '../../../lib/class-names';

type Props = {
    method: string,
    path: string,
    description: ReactNode,
    response?: string,
    children?: ReactNode,
};

const methodColors: Record<string, string> = {
    get: 'bg-blue-500',
    post: 'bg-orange-500',
};

export default function EndpointDoc(props: Props) {
    const {method, path, description, children, response} = props;

    return <div className="rounded overflow-hidden shadow-md my-6">
        <div className="flex bg-gray-100 items-center">
            <div className={classNames('uppercase text-white px-4 py-2', methodColors[method])}>{method}</div>
            <div className="font-mono text-sm ml-3">{path}</div>
        </div>
        <div className="px-4 py-4">
            {description}
        </div>
        {children && <div className="px-4 pb-4">
            <h4 className="text-lg font-semibold mb-2">Payload</h4>
            {children}
        </div>}
        {response && <div className="px-4 pb-4">
            <h4 className="text-lg font-semibold mb-2">Response</h4>
            {response}
        </div>}
    </div>;
}