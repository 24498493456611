import GraphRectifier from './graph-rectifier';
import Variable from '../../../../metadata';
import {DataDefRepo} from '../../../../user-data-defs';
import V1GraphHandler from '../../../graph-handler/v1-graph-handler';

export default class CompositeRectifier extends GraphRectifier {

    private readonly rectifiers: Array<GraphRectifier>;

    constructor(rectifiers: Array<GraphRectifier>) {
        super();
        this.rectifiers = rectifiers;
    }

    apply(gh: V1GraphHandler, metadata: Array<Variable>, dataLibrary: Array<Array<Variable>>, datasetRepo: DataDefRepo): void {
        for (const rectifier of this.rectifiers) {
            rectifier.apply(gh, metadata, dataLibrary, datasetRepo);
        }
    }

}