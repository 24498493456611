import React, {useState, useMemo, useEffect} from 'react';
import Badge from '../../components/badge';
import {dataRow, dataRows} from '../../lib/data-types';
import Select from '../../components/select';
import Button from '../../components/button';
import Toggle from '../../components/toggle';
import DRGData, {DRGCode, DRGDataFilters, MDCCode} from '../../components/drg-select/types';
import {formatDescription} from './util';
import './drg-select.scss';
import {TestType} from 'quantum-graph';
import fetchData from '../../lib/data';
import handleError from '../../lib/error';
import Loading from '../../components/loading';
import ErrorMessage from '../../components/error-message';
import getDataDefRepo from '../../lib/user-data-defs';
import {EntityHelper} from 'quantum-graph/out/v1';

function keywordMDCFilter(keyword:string) : (mdc: MDCCode) => boolean {
    return (mdc: MDCCode) => {
        return mdc.major_diagnostic_category_description.toLocaleLowerCase().includes(keyword) ||
            mdc.drgCodes.some(drg => drg.diagnosis_related_group_description.toLocaleLowerCase().includes(keyword));
    };
}

function keywordDRGFilter(keyword:string) : (mdc: MDCCode) => MDCCode {
    return code => ({
        mdc: code.mdc,
        major_diagnostic_category_description: code.major_diagnostic_category_description,
        drgCodes: code.drgCodes.filter(drg => {
            return drg.diagnosis_related_group_description.toLocaleLowerCase().includes(keyword) ||
                code.major_diagnostic_category_description.toLocaleLowerCase().includes(keyword);
        })
    });
}

function DRGRow(props: {row: DRGCode, onAdd: (opt: DRGCode, add?: boolean) => void, selected: boolean}) {
    return <div className='text-sm flex flex-initial flex-row items-center h-10 w-full justify-start'>
        <Badge color='blue' pill>{props.row['drg']}</Badge>
        {formatDescription(props.row['diagnosis_related_group_description'] as string)}
        <div className='ml-auto mr-2 leading-none bg-gray-50 rounded-full'>
            <Button color='orange' pill outlined onClick={() => props.onAdd(props.row, !props.selected)} ><i className={props.selected ? 'icon-minus' : 'icon-plus'}/></Button>
        </div>
    </div>;
}

function DRGList(props: {
    data: DRGData,
    filters: DRGDataFilters,
    selectedData: dataRows,
    onSelect: (opt: DRGCode | DRGCode[], add?: boolean) => void
}) {

    return <div className='h-96 w-full bg-gray-100 rounded overflow-y-scroll'>
        {props.data.applyFilters(props.filters).map(code => {
            const mdcSelected = code.drgCodes.every(r => props.selectedData.some(s => r.drg === s.drg));

            return <div key={code.mdc} className='bg-gray-300 rounded m-2 my-6 p-1'>
                <div className='text-sm flex flex-initial flex-row items-center h-10 justify-start -mt-6 -mr-2 -ml-2'>
                    <div className='z-10 mdc'><Badge color='green' pill>{code.mdc}</Badge></div> 
                    <div className='bg-gray-300 rounded-full rounded-l-none text-lg font-bold leading-8 -ml-4 px-4'>{formatDescription(code.major_diagnostic_category_description)}</div>
                    <div className='ml-auto mr-2 leading-none bg-gray-50 rounded-full'><Button color='orange' pill outlined onClick={() => props.onSelect(code.drgCodes, !mdcSelected)}>MDC <i className={mdcSelected ? 'icon-minus' : 'icon-plus'}/></Button></div>
                </div>
                {code.drgCodes.map(r => <DRGRow key={r.drg} row={r} onAdd={props.onSelect} selected={props.selectedData.some(s => r.drg === s.drg)} />)}
            </div>;
        })}
    </div>;
}

export function DRGSelectForm({data, select, selected}: {data:dataRows, select:(row: dataRow | dataRows, add?:boolean) => void, selected:dataRows}) {
    const [keyword, setKeyword] = useState('');
    const [mdcFilter, setMDCFilter] = useState('');
    const [includeMCC, setIncludeMCC] = useState(true);
    const [includeCC, setIncludeCC] = useState(true);
    
    const codes = useMemo(() => new DRGData(data), [data]);
    const filters = useMemo(() => {
        const filters = {} as DRGDataFilters;
        if (keyword.length >= 2) {
            filters['keyword'] = [keywordMDCFilter(keyword), keywordDRGFilter(keyword)];
        }

        if (mdcFilter) {
            filters['mdc'] = [(mdc: MDCCode) => mdc.mdc.toString() === mdcFilter, (mdc: MDCCode) => mdc];
        }

        filters['cc'] = [(mdc: MDCCode) => mdc.drgCodes.some(drg => includeCC || !drg.CC), (mdc: MDCCode) => ({...mdc, drgCodes: mdc.drgCodes.filter(drg => includeCC || !drg.CC)})];
        filters['mcc'] = [(mdc: MDCCode) => mdc.drgCodes.some(drg => includeMCC || !drg.MCC), (mdc: MDCCode) => ({...mdc, drgCodes: mdc.drgCodes.filter(drg => includeMCC || !drg.MCC)})];

        return filters;
    }, [keyword, mdcFilter, includeMCC, includeCC]);

    return <div className='drg-select'>
        <div className="grid grid-cols-2 gap-2 mb-4">
            <div className="col-span-2 flex flex-col">
                <label className="text-sm" htmlFor="keyword">Keyword</label>
                <input id="keyword" type="text" value={keyword} onChange={({currentTarget: v}) => {setKeyword(v.value);}} />
            </div>
            <div className="col-span-1 flex flex-col">
                <label className="text-sm">Category</label>
                <Select data={codes.mdcCodes} 
                    value={mdcFilter} 
                    onChange={s => setMDCFilter(s)}>
                    {c => c.mdc.toString()}
                    {c => <p key={c.mdc} className="text-sm">{c.mdc} {formatDescription(c.major_diagnostic_category_description)}</p>}
                </Select>
            </div>
            <div className="col-span-1 justify-end flex flex-col">
                <div className="flex flex-row justify-between">
                    <div className="self-center"><label className="text-sm">Major Complication or Comorbidity (MCC)</label></div>
                    <Toggle className="self-center" checked={includeMCC} onChange={() => setIncludeMCC(!includeMCC)} />
                </div>
                <div className="flex flex-row justify-between mt-1">
                    <div className="self-center"><label className="text-sm">Complication or Comorbidity (CC)</label></div>
                    <Toggle className="self-center" checked={includeCC} onChange={() => setIncludeCC(!includeCC)} />
                </div>
            </div>
        </div>
        <DRGList data={codes} filters={filters} selectedData={selected} onSelect={select} />
        <h3>Selected</h3>
        <div id='selected' className='h-24 flex w-full flex-wrap items-start content-start bg-gray-100 rounded overflow-y-auto p-1 mb-8'>
            {selected.map((code, i) => 
                <div key={i} className='my-1'> 
                    <Badge color='blue' pill onClose={() => {select(code, false);}}>{code['drg'] as string}</Badge>
                </div>)}
        </div>
    </div>;
    
}

export default function DRGSelect(props: {selected:dataRows, select:(row:dataRow | dataRows, add?:boolean) => void}) {
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState<string>('');
    const [data, setData] = useState<dataRows>([]);

    useEffect(() => {
        setLoading(true);

        const controller = new AbortController();
        getDataDefRepo(controller.signal).then(dataDefRepo => {
            const graph = new EntityHelper('drg', [
                'drg_dim_key',
                'drg',
                'diagnosis_related_group_description',
                'major_diagnostic_category',
                'major_diagnostic_category_description'
            ], dataDefRepo).buildHelper().filterGrain(null, 'year_key', TestType.Eq, 2018).build().toGraph();

            return fetchData(graph, controller.signal);
        })
            .then(rows => {
                setData(rows);
                setLoading(false);
            })
            .catch(err => handleError(err, setErr));

        return () => controller.abort();
    }, []);

    if (err)
        return <ErrorMessage />;
    if (loading)
        return <Loading />;

    return <div className='drg-select'>
        <DRGSelectForm data={data} select={props.select} selected={props.selected} />
    </div>;
}