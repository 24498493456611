
/*
<h4>Dimension and fact</h4>
        <h4>Advanced filtering</h4>
        <h4>Multiple sorts</h4>
        <h4>Dimension and multiple facts using a scaffold</h4>
        <h4>Multiple dimensions using affiliations</h4>
 */

export const DimensionAndFact = `{
    "version": 2,
    "baseQueryGraph": {"baseQuery": {
        "queryType": "dim",
        "tableNodes": [{
            "id": "facility",
            "dataset": "facility",
            "columns": [
                "torch_facility_id",
                "facility_name"
            ]
        }, {
            "id": "hospital-cost-report-beds",
            "dataset": "hospital-cost-report-beds",
            "columns": [
                "beds"
            ]
        }]
    }},
    "pagination": {
        "page": 0,
        "pageSize": 10
    },
    "filters": [{
        "column": {"nodeId": "hospital-cost-report-beds", "column": "year_key"},
        "test": "=",
        "value": 2019
    }]
}`;

export const AdvancedFiltering = `{
    "version": 2,
    "baseQueryGraph": {"baseQuery": {
        "queryType": "dim",
        "tableNodes": [{
            "id": "facility",
            "dataset": "facility",
            "columns": [
                "torch_facility_id",
                "facility_name",
                "abbreviated_state",
                "formerly_known_as"
            ]
        }, {
            "id": "hospital-cost-report-beds",
            "dataset": "hospital-cost-report-beds",
            "columns": [
                "beds"
            ]
        }]
    }},
    "pagination": {
        "page": 0,
        "pageSize": 10
    },
    "filters": [{
        "column": {"nodeId": "facility", "column": "abbreviated_state"},
        "test": "in",
        "value": ["CA", "NY", "FL"]
    }, {
        "column": {"nodeId": "hospital-cost-report-beds", "column": "year_key"},
        "test": "=",
        "value": 2019
    }, {
        "column": {"nodeId": "facility", "column": "formerly_known_as"},
        "test": "is not null",
        "value": 0
    }, {
        "column": {"nodeId": "facility", "column": "formerly_known_as"},
        "test": "<>",
        "value": ""
    }]
}`;

export const ScaffoldQuery = `{
    "version": 2,
    "baseQueryGraph": {"baseQuery": {
        "queryType": "scaffold",
        "scaffoldField": "year_key",
        "tableNodes": [{
            "id": "facility",
            "dataset": "facility",
            "columns": [
                "torch_facility_id",
                "facility_name"
            ]
        }, {
            "id": "hospital-cost-report-beds",
            "dataset": "hospital-cost-report-beds",
            "columns": [
                "beds"
            ]
        }, {
            "id": "hospital-cost-report-total-revenue",
            "dataset": "hospital-cost-report-total-revenue",
            "columns": [
                "net_patient_revenue"
            ]
        }]
    }},
    "pagination": {
        "page": 0,
        "pageSize": 10
    },
    "filters": [{
        "column": {"nodeId": "facility", "column": "torch_facility_id"},
        "test": "=",
        "value": 1
    }]
}`;

export const AffiliationQuery = `{
    "version": 2,
    "baseQueryGraph": {
        "baseQuery": {
            "queryType": "dim",
            "tableNodes": [{
                "id": "facility",
                "dataset": "facility",
                "columns": [
                    "torch_facility_id",
                    "facility_name"
                ]
            }]
        },
        "joins": [{
            "joinType": "inner",
            "node": {
                "baseQuery": {
                    "queryType": "aff",
                    "tableNodes": [{
                        "id": "affiliation",
                        "dataset": "health-system:facility",
                        "columns": [
                            "type"
                        ]
                    }, {
                        "id": "health-system",
                        "dataset": "health-system",
                        "columns": [
                            "torch_health_system_id",
                            "name"
                        ]
                    }]
                }
            }
        }]
    },
    "pagination": {
        "page": 0,
        "pageSize": 10
    },
    "filters": [{
        "column": {"nodeId": "affiliation", "column": "type"},
        "test": "=",
        "value": "owner"
    }]
}`;