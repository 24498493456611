import Template from '../../../components/template';
import AuthWall from '../../../components/auth-wall';
import {SiteFeature} from '../../../lib/check-access';
import PageMenu from '../../../components/page-menu';
import Button from '../../../components/button';
import {useNavigate} from 'react-router-dom';


export default function HPTOverviewPage() {
    const navigate = useNavigate();

    return <Template>
        <AuthWall siteFeature={SiteFeature.AccessHPT}>
            <div className="pb-8">
                <PageMenu>
                    <Button color="link" onClick={() => navigate(-1)}><i className="icon-left" /> Back</Button><div className="flex-grow" />
                    <div className="center">
                        <h1>Milliman Insights</h1>
                    </div>
                </PageMenu>
                <div className="container mt-10">
                    <h2>Overview and Notes</h2>

                    <p>Welcome to Milliman Insights!</p>

                    <p>We have a number of key resources to help you navigate:</p>

                    <p><a href="https://torchinsight-file-uploads.s3.us-west-2.amazonaws.com/hcindex-overview/Milliman+Insights+-+User+Guide.pdf" target="_blank" rel="noreferrer">User Guide</a> – Includes step-by-step information regarding accessing the Milliman Insights tool, setting up customized reports, and utilizing the tool’s output.</p>

                    <p><a href="https://torchinsight-file-uploads.s3.us-west-2.amazonaws.com/hcindex-overview/Milliman+Insights+-+Methodology+Guide+20221222.pdf" target="_blank" rel="noreferrer">Methodology Guide</a> – Overview of the analytical processes that we apply to the data, including our enhancement engines and data assets we apply in order to create our aggregated pricing relativities.</p>

                    <p><a href="https://torchinsight-file-uploads.s3.us-west-2.amazonaws.com/hcindex-overview/Milliman+Insights+Data+Dictionary+-+Torch.xlsx" target="_blank" rel="noreferrer">Data Dictionary</a> – Definitions for all of the data fields available in Milliman Insights.</p>
                </div>
            </div>
        </AuthWall>
    </Template>;
}