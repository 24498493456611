import Variable from '../../lib/metadata';
import React from 'react';
import {useDeepCompareMemo} from 'use-deep-compare';

type Props = {
    variable: Variable,
    sorts: Array<[Variable, boolean]>,
}

export default function SortIcon(props: Props) {
    const {variable, sorts} = props;
    const sortIcon = useDeepCompareMemo(() => {
        const idx = sorts.findIndex(s => s[0] === variable);
        let sortIcon = 'icon-sort';
        if (idx >= 0) {
            const asc = sorts[idx][1];
            if (asc)
                sortIcon = 'icon-sort_ascend';
            else
                sortIcon = 'icon-sort_descend';
        }
        return sortIcon;
    }, [variable, sorts]);

    return <span className="sort-icon">
        <i className={sortIcon} />
    </span>;
}