
const CRBRegions = [
    {name: 'MSA/Division', value: 'msa'},
    {name: 'State', value: 'state'},
];

const CRBReportTypes = [
    {name: 'Summary', value: 'summary'},
    {name: 'Breakdown by Service Category', value: 'breakdown'},
    {name: 'Breakdown by DRG', value: 'drg'},
    {name: 'Breakdown by MDC', value: 'mdc'},
];

const CRBTypesOfService = [
    {name: 'Inpatient', value: 'Inpatient'},
    {name: 'Outpatient', value: 'Outpatient'},
    {name: 'Professional', value: 'Physician'},
];

const CRBMetricTypes = [
    {name: 'Nationwide', value: 'Nationwide'},
    {name: 'Full', value: 'Full'},
    {name: 'Area', value: 'Area'},
];

export {CRBRegions, CRBReportTypes, CRBTypesOfService, CRBMetricTypes};