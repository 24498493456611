import React, {useEffect, useMemo, useState} from 'react';
import {DatasetDefinition} from 'torch-data-defs';
import {datasets, datasetByName, resolveField} from '../../../lib/data-defs';
import Select from '../../../components/select';
import Variable, {DatasetInfo, loadDatasetInfo} from '../../../lib/metadata';
import Loading from '../../../components/loading';
import Tooltip from '../../../components/tooltip';
import Table from '../../../components/table';
import {StringFormatter} from '../../../lib/formatter';


export default function APIDocDatasets() {
    const [dataset, setDataset] = useState('');
    const datasetNames = useMemo(() => [''].concat(datasets.map(ds => ds.name)).sort(), []);
    const [dsInfo, setDsInfo] = useState<DatasetInfo | null>(null);
    const [loading, setLoading] = useState(false);
    const grainHeaders = useMemo(() => [
        new Variable('dataset', 'Dataset', undefined, '', {}, new StringFormatter()),
        new Variable('field', 'Field', undefined, '', {}, new StringFormatter()),
    ], []);
    const datasetDef: DatasetDefinition = datasetByName[dataset];

    useEffect(() => {
        setDsInfo(null);
        if (!dataset) {
            return;
        }
        setLoading(true);
        const controller = new AbortController();
        loadDatasetInfo(dataset, controller.signal).then(dsInfo => {
            setDsInfo(dsInfo);
            setLoading(false);
        });
        return () => controller.abort();
    }, [dataset]);

    function getGrainData() {
        return datasetDef.tableGrain!.map(g => {
            const parts = resolveField(g, dataset, datasetByName);
            return {
                dataset: parts[0],
                field: parts[2] || parts[1],
            };
        });
    }

    return <div>
        <h2>Available Datasets</h2>

        <p>
            Select one of the available datasets for more information.
        </p>
        <Select<string> data={datasetNames} value={dataset} onChange={ds => setDataset(ds)}>
            {_ => _}
            {_ => _}
        </Select>
        <hr/>
        {loading && <Loading />}
        {dsInfo && <div>
            <div className="my-2">
                <h3 className="bold text-blue-700">Name</h3>
                <p><code>{dataset}</code></p>
            </div>
            <div className="my-2">
                <h3 className="bold text-blue-700">Label</h3>
                <p>{dsInfo.label}</p>
            </div>
            {dsInfo.description && <div className="my-2">
                <h3 className="bold text-blue-700">Description</h3>
                <p>{dsInfo.description}</p>
            </div>}
            {dsInfo.source && <div className="my-2">
                <h3 className="bold text-blue-700">Source</h3>
                <p>{dsInfo.source}</p>
            </div>}
            {dsInfo.sourceDescription && <div className="my-2">
                <h3 className="bold text-blue-700">Source Description</h3>
                <p>{dsInfo.sourceDescription}</p>
            </div>}
            {!!datasetDef.tableGrain!.length && <div className="my-2">
                <h3 className="bold text-blue-700">Dataset Grain <Tooltip content="This describe the shape of the data. Datasets with common grain are able to join."><i className="icon-question" /></Tooltip></h3>
                <Table headers={grainHeaders} data={getGrainData()} />
            </div>}
        </div>}
    </div>;
}