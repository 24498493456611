import React, {useContext, useMemo, useRef, useState} from 'react';
import {DataConfig, getConfigOption} from '../types';
import StandardDataRenderer, {StandardRenderChildProps} from '../renderers/standard-data-renderer';
import Table from '../../components/table';
import Variable from '../../lib/metadata';
import sortData, {updateSorts} from '../../lib/sort';
import Button from '../../components/button';
import {downloadCsv} from '../../lib/download';
import VariableFilter from '../../components/variable-filter';
import Filter, {applyFilter} from '../../lib/filter';
import FilterBar from '../../components/filter-bar';
import SourceModal from '../../components/source-modal';
import checkAccess, {SiteFeature} from '../../lib/check-access';
import Pagination from '../../components/pagination';
import Popup from '../../components/popup';
import {LocationDescriptor} from 'history';
import createGraphHandler from '../../lib/graph-tools/graph-handler/create';
import {getDefaultDataDefRepo} from '../../lib/data-defs';
import {Pagination as CarbonPagination} from '@carbon/react';
import {HeaderContext} from '../../components/header/context';
import {NumberFormatter} from '../../lib/formatter';
import './data-table.scss';

const PageSize = 20;


type DataTableVisProps = {
    customDownload?: Array<[string, () => void] | null>
}

export function DataTableVis(props: StandardRenderChildProps & DataTableVisProps) {
    let {data, metadata, dataConfig, customDownload} = props;
    const [sorts, setSorts] = useState(createGraphHandler(dataConfig.queries[0], getDefaultDataDefRepo()).getSort(metadata));
    const [filters, setFilters] = useState<Array<Filter>>([]);
    const [filterModal, setFilterModal] = useState<{variable: Variable, filter: Filter | null} | null>(null);
    const [infoModal, setInfoModal] = useState<Variable | null>(null);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(PageSize);
    const btnRef = useRef<HTMLButtonElement>(null);
    const {useNewDesign} = useContext(HeaderContext);

    for (const filter of filters) {
        data = applyFilter(data, filter);
    }

    const appliedData = useMemo(() => {
        const sorted = sortData(data, sorts);
        return sorted.slice(page * pageSize, (page + 1) * pageSize);
    }, [data, sorts, page, pageSize]);

    function handleSort(variable: Variable) {
        setSorts(updateSorts(sorts, variable));
    }

    function addFilter(filter: Filter) {
        const existingFilterIndex = filters.findIndex(f => f === filterModal!.filter);
        let newFilters = filters;
        if (existingFilterIndex >= 0)
            newFilters = newFilters.splice(existingFilterIndex, 1);
        newFilters = newFilters.concat([filter]);
        setFilters(newFilters);
        setFilterModal(null);
    }

    function removeFilter(i: number) {
        const newFilters = filters.slice();
        newFilters.splice(i, 1);
        setFilters(newFilters);
    }

    const showExplore = !getConfigOption<boolean>(dataConfig, 'hideExplore');
    const exploreLink: string | LocationDescriptor = getConfigOption(dataConfig, 'exploreLink') || {pathname: '/data/explore', state: {query: dataConfig.queries[0].serialize(), back: true}};
    const queryName: string = getConfigOption<string>(dataConfig, 'title') || 'Data Explorer';
    return <div className="data-table-vis">
        <FilterBar queryName={queryName} filters={filters} sorts={sorts} onRemove={removeFilter} />
        <Table headers={props.metadata} data={appliedData} onSort={handleSort} sorts={sorts}
            onFilter={v => setFilterModal({variable: v, filter: null})} onInfo={setInfoModal} />
        {useNewDesign ? <CarbonPagination totalItems={data.length}
            pageSizes={[{text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '200', value: 200}]}
            page={page + 1}
            pageSize={pageSize}
            itemRangeText={(min, max, total) => `${ min }–${ max } of ${ new NumberFormatter(1, 'Unkown').formatSimple(total) } records`}
            itemsPerPageText=''
            pageRangeText={(_current, total) => `of ${ new NumberFormatter(1, 'Unkown').formatSimple(total) } ${ total === 1 ? 'page' : 'pages' }`}
            onChange={({page, pageSize: pageSizeChange}) => {setPageSize(pageSizeChange);setPage(page - 1);}} 
        /> : <div className="flex bg-white px-3 py-3 border border-t-0 rounded-b shadow-lg text-gray-500">
            <Pagination count={data.length} page={page} pageSize={PageSize} setPage={setPage} showCount />
            <div className="flex-grow" />
            <div className="flex items-center">
                {showExplore && <Button color="light" small to={exploreLink} className="mr-2">
                    Explore Data
                </Button>}
                {!customDownload && checkAccess(SiteFeature.Download) && <Button color="light" small onClick={() => downloadCsv(data, metadata)}>
                    <i className="icon-download"/> Download .csv
                </Button>}
                {customDownload && customDownload.length === 1 && checkAccess(SiteFeature.Download) && <Button color="light" small ref={btnRef} onClick={() => customDownload![0]![1]()}>
                    <i className="icon-download"/> {customDownload[0]![0]}
                </Button>}
                {customDownload && customDownload.length > 1 && checkAccess(SiteFeature.Download) && <div>
                    <Button color="light" small ref={btnRef}>
                        <i className="icon-download"/> Download
                    </Button>
                    <Popup targetRef={btnRef}>
                        {customDownload.map((dl, i) => {
                            if (!dl)
                                return <div key={i} className="border-t" />;
                            else
                                return <div key={i} className="menu-item cursor-pointer" onClick={dl[1]}>{dl[0]}</div>;
                        })}
                    </Popup>
                </div>}
            </div>
        </div>}
        {filterModal && <VariableFilter config={filterModal} onApply={addFilter} onCancel={() => setFilterModal(null)} data={data} />}
        {infoModal && <SourceModal variable={infoModal} onClose={() => setInfoModal(null)} />}
    </div>;
}

export default function DataTableRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void}) {
    return <StandardDataRenderer dataConfig={props.dataConfig} loadCB={props.loadCB}>
        {DataTableVis}
    </StandardDataRenderer>;
}