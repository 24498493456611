import Choropleth from './choropleth';
import Circles from './circles';
import Points from './points';
import {LayerPropsWithType, Layers} from './types';


function findLayer(type: string) {
    if (type === Layers.point)
        return Points;
    else if (type === Layers.choropleth)
        return Choropleth;
    else if (type === Layers.circle)
        return Circles;
    throw new Error('Unknown layer type: ' + type);
}



export default function LayerFinder(props: LayerPropsWithType) {
    const {layerType, ...rest} = props;

    const FoundLayer = findLayer(layerType);

    return <FoundLayer {...rest}/>;
}