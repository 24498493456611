import {dataRow, dataRows} from './data-types';
import Variable, {ValueType} from './metadata';

function sortCompare(a: dataRow, b: dataRow, sortBy: Variable, asc: boolean, isNumerical: boolean) {
    const aValue = a[sortBy.name];
    const bValue = b[sortBy.name];

    let aFalsey = a === null || a === undefined;
    let bFalsey = b === null || b === undefined;
    if (isNumerical) {
        aFalsey = aFalsey || isNaN(Number(aValue));
        bFalsey = bFalsey || isNaN(Number(bValue));
    }
    if (aFalsey && bFalsey)
        return 0;
    if (aFalsey)
        return asc ? -1 : 1;
    if (bFalsey)
        return asc ? 1 : -1;

    if (isNumerical)
        return asc ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue);

    const aStr = String(aValue).toLowerCase();
    const bStr = String(bValue).toLowerCase();
    if (aStr < bStr)
        return asc ? -1 : 1;
    if (aStr > bStr)
        return asc ? 1 : -1;
    return 0;
}

export default function sortData(data: dataRows, sorts: Array<[Variable, boolean]>) {
    if (!data.length || !sorts.length)
        return data;

    const valueTypes = sorts.map(s => s[0].getValueType());
    const isNumerical = valueTypes.map(vt => vt === ValueType.Number || vt === ValueType.DateTime);
    return data.slice().sort((a, b) => {
        for (let i = 0; i < sorts.length; i++) {
            const sort = sorts[i];
            const sortValue = sortCompare(a, b, sort[0], sort[1], isNumerical[i]);
            if (sortValue !== 0)
                return sortValue;
        }
        return 0;
    });
}

export function updateSorts(sorts: Array<[Variable, boolean]>, variable: Variable, numSorts = Infinity, removeSort = false): Array<[Variable, boolean]> {
    const idx = sorts.findIndex(s => s[0] === variable);
    const defaultAsc = variable.getValueType() === ValueType.Text;
    if (idx >= 0) {
        sorts = sorts.slice();
        const [variable, asc] = sorts[idx];
        if (removeSort) {
            if (asc === defaultAsc)
                sorts[idx] = [variable, !asc];
            else
                sorts.splice(idx, 1);
        } else {
            sorts[idx] = [variable, !asc];
        }
        return sorts;
    } else if (sorts.length < numSorts) {
        return [[variable, defaultAsc], ...sorts];
    } else {
        return [[variable, defaultAsc]];
    }
}