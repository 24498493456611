import {Dispatch, SetStateAction, useState} from 'react';
import Toggle from '../../components/toggle';
import Badge from '../../components/badge';

type Props = {
    onChange: (m: MemberFormData) => void,
    allowAdmin: boolean
    allowModerator: boolean
    isAdmin: boolean
    isModerator: boolean
    inviteCount: number
    invitesSent: number
    showInvites?: boolean
}

export type MemberFormData = {
    inactive: boolean
    orgAdmin: boolean
    moderator: boolean
    inviteCount: number
}

export default function MemberForm(props: Props) {
    const {onChange, allowAdmin, isAdmin, allowModerator, isModerator, inviteCount, invitesSent, showInvites} = props;
    const [orgAdmin, setOrgAdmin] = useState<boolean>(isAdmin);
    const [inactive, setInactive] = useState<boolean>(false);
    const [moderator, setModerator] = useState<boolean>(isModerator);
    const [numInvites, setNumInvites] = useState<number>(inviteCount);

    function renderToggle(label: string, prop: any, onChange: () => void) {
        return <div className="w-1/3 grid grid-cols-2 gap-3">
            <strong>{label}</strong>
            <Toggle checked={prop} onChange={onChange}/>
        </div>;
    }

    function handleChange(property: string, value: boolean | number, setter: Dispatch<SetStateAction<any>>) {
        const formObject: MemberFormData = {
            inactive,
            inviteCount: numInvites,
            orgAdmin: orgAdmin || false,
            moderator: moderator || false
        };
        //@ts-ignore
        formObject[property] = value;

        onChange(formObject);
        setter(value);
    }

    return <div className="flex flex-col space-y-2 mt-5">
        {allowAdmin && renderToggle('Organization Admin', orgAdmin, () => handleChange('orgAdmin', !orgAdmin, setOrgAdmin))}
        {renderToggle('Set User as Inactive', inactive, () => handleChange('inactive', !inactive, setInactive))}
        {allowModerator && renderToggle('Can Invite Others', moderator, () => handleChange('moderator', !moderator, setModerator))}
        {allowModerator && moderator && <div className="mt-5 flex flex-col space-y-3">
            <div className="flex items-center space-x-5">
                <div># Invitations</div>
                <input style={{width: '100px', minWidth: '0'}} type="number" value={numInvites} min={0} step={1} onChange={e => handleChange('inviteCount', Number(e.target.value), setNumInvites)} />
            </div>
            {showInvites && <div>
                <Badge color={'light'} pill>{invitesSent} Invitations Sent</Badge>
            </div>}
        </div>}
    </div>;
}