import StandardDataRenderer, {StandardRenderChildProps} from '../renderers/standard-data-renderer';
import React, {useMemo} from 'react';
import {DataConfig} from '../types';
import Profile from './profile';
import TabBar from '../../components/tab-bar';
import ProfileGroupDisplay from './profile-group-display';
import createGraphHandler from '../../lib/graph-tools/graph-handler/create';
import {getDefaultDataDefRepo} from '../../lib/data-defs';
import Badge from '../../components/badge';

export function ProfileVis(props: StandardRenderChildProps) {
    let {metadata, data} = props;
    const row = data[0];

    if (!row || !Object.keys(row).length)
        return null;

    const profile = useMemo(() => new Profile(metadata), [metadata]);
    const name = profile.getName(row) as string;

    return (
        <div>
            {!!name && <div className='mb-10'>
                <h1 className='text-gray-500 font-light'>{name}</h1>
                <div className='h-1 w-1/2 bg-blue-500 mt-1 mb-5 rounded-full' />
            </div>}

            {profile.groups.length < 1 && (
                <div className='text-red-500'>
                    Metadata missing Category and Subcategory for this query
                </div>
            )}

            {profile.groups.length === 1 && (
                <ProfileGroupDisplay metadata={profile.groups[0].metadata} row={row} />
            )}

            {profile.groups.length > 1 && (
                <TabBar>
                    {profile.groups.map((group) => ({
                        name: group.name,
                        component: () => (
                            <ProfileGroupDisplay metadata={group.metadata} row={row} />
                        ),
                    }))}
                </TabBar>
            )}

            {profile.year_key && <div className="w-full flex justify-end">
                <Badge color="blue" pill>{profile.year_key.getValue(row) as string}</Badge>
            </div>}
        </div>
    );
}

export default function ProfileRenderer(props: {dataConfig: DataConfig, loadCB?: (promise: Promise<void>) => void}) {
    const dataConfig = useMemo(() => {
        const dc = props.dataConfig;
        const gh = createGraphHandler(dc.queries[0], getDefaultDataDefRepo());
        gh.applyPagination(0, 1);
        return {
            queries: [gh.build()],
            options: dc.options
        };
    }, [props.dataConfig]);

    return <StandardDataRenderer dataConfig={dataConfig} loadCB={props.loadCB}>
        {ProfileVis}
    </StandardDataRenderer>;
}