import Variable from '../../lib/metadata';
import {dataRow} from '../../lib/data-types';
import React from 'react';

type Props = {
    metadata: Array<Variable>,
    row: dataRow,
}

function VariableDisplay(props: {variable: Variable, row: dataRow}) {
    const {variable, row} = props;
    return <div className="mt-2">
        <div className="text-gray-400 text-sm">
            {variable.label}
        </div>
        <div>
            {variable.formatter.format(row[variable.name], row)}
        </div>
    </div>;
}

export default function ProfileGroupDisplay(props: Props) {
    const {metadata, row} = props;

    const p1 = Math.ceil(metadata.length / 3);
    const p2 = Math.ceil(metadata.length * 2 / 3);
    const group1 = metadata.slice(0, p1);
    const group2 = metadata.slice(p1, p2);
    const group3 = metadata.slice(p2);
    return <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/3">
            {group1.map(v => <VariableDisplay key={v.getId()} variable={v} row={row} />)}
        </div>
        <div className="w-full md:w-1/3">
            {group2.map(v => <VariableDisplay key={v.getId()} variable={v} row={row} />)}
        </div>
        <div className="w-full md:w-1/3">
            {group3.map(v => <VariableDisplay key={v.getId()} variable={v} row={row} />)}
        </div>
    </div>;
}