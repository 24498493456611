import {observer} from 'mobx-react';
import {useContext, useMemo, useState, useEffect} from 'react';
import {CanvasInsertContext} from '../../../lib/context';
import {generateTitle} from './map-query-selection-screen';
import {QuantumGraph} from 'quantum-graph';
import createGraphHandler from '../../../../../lib/graph-tools/graph-handler/create';
import {ColorPicker} from '../../../../../components/color-picker';
import Button from '../../../../../components/button';
import {CvInsertScreen, PointLayerOptions} from '../../../lib/types';
import Checkbox from '../../../../../components/checkbox';
import {getDefaultDataDefRepo} from '../../../../../lib/data-defs';


function processQuantumVariables(graph:QuantumGraph) {
    const gh = createGraphHandler(graph, getDefaultDataDefRepo());
    const variables = gh.extractVariables();
    return variables.map(v => ({
        label: `${v.variable}`,
        value: `${v.variable}`
    }));
}

const colorPresets = [
    '#ff6400', // medinsight-orange 500
    '#ffc800', // medinsight-yellow 500
    '#47639f', // blue 500
    '#0081e2', // medinsight-vibrant-blue 500
    '#004877', // milliman-blue 500
    '#74c061', // medinsight-spring 500
    '#EF4444', // red-500
    '#FFFFFF', // white
    '#000000', // black
];

const ColumnConfig = observer(()=>{
    const insertStore = useContext(CanvasInsertContext);
    const [highColor, setHighColor] = useState(colorPresets[0]);
    const [lowColor, setLowColor] = useState(colorPresets[1]);
    const [pointOptions, setPointOptions] = useState<PointLayerOptions[]>(insertStore.mapOptions.pointOptions);
    const [saveAllowed, setSaveAllowed] = useState(false);
    const [pointsAllowed, setPointsAllowed] = useState(true);

    const [choroplethQuery, ...pointsQueries] = insertStore.mapOptions.mapQueries;

    const choroOptions = useMemo(()=>processQuantumVariables(choroplethQuery),[choroplethQuery]);

    function handleColumnSelect(e:any) {
        insertStore.mapOptions.choroplethColumn = e.target.value;
        setSaveAllowed(true);
    }

    function saveColumnConfig() {
        insertStore.mapOptions.highColor = highColor;
        insertStore.mapOptions.lowColor = lowColor;    
        insertStore.goToVisScreen(CvInsertScreen.mapConfig);
    }

    useEffect(() => {
        if (pointOptions.length) {
            const allowed = pointOptions.every((p) => {
                return 'column' in p;
            });
            setPointsAllowed(allowed);
        }
    }, [pointOptions]);



    return (
        <div>
            <h2>Configure Data Relationships</h2>

            <div className='flex justify-between'>
                <div className='w-1/3 mx-2'>
                    <h3>Select Choropleth Variable:</h3>
                    <h4>{generateTitle(choroplethQuery)}</h4>
                    <select onChange={handleColumnSelect} className='w-full' data-testid='choropleth-select'>
                        <option value=''>Select a variable</option>
                        {choroOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='w-1/3 mx-2'>
                    <h3>Select Color Scheme</h3>
                    <div className='flex-col justify-between'>
                        <div className='w-1/2'>
                            <h4>High</h4>
                            <ColorPicker
                                presetColors={colorPresets}
                                color={highColor}
                                onChange={setHighColor}
                            />
                        </div>
                        <div className='w-1/2'>
                            <h4>Low</h4>
                            <ColorPicker
                                presetColors={colorPresets}
                                color={lowColor}
                                onChange={setLowColor}
                            />
                        </div>
                    </div>
                </div>
                <div className='w-1/3 mx-2'>
                    <h3>Show Choropleth Data on Hover</h3>
                    <Checkbox
                        checked={insertStore.mapOptions.choroHover}
                        onChange={() =>
                            (insertStore.mapOptions.choroHover = !insertStore.mapOptions.choroHover)
                        }
                    />
                </div>
            </div>
            {pointsQueries.length && <h3>Configure Point Queries</h3>}
            {pointsQueries.length &&
                pointsQueries.map((q, index) => (
                    <div key={index} className='flex justify-between'>
                        <div className='w-1/2'>
                            <h4>{generateTitle(q)}</h4>
                            <h4>Point Color</h4>
                            <ColorPicker
                                presetColors={colorPresets}
                                color={pointOptions[index].color}
                                onChange={(color)=>{
                                    let newArr = [...pointOptions];
                                    newArr[index].color = color;
                                    insertStore.mapOptions.pointOptions[index].color = color;
                                    setPointOptions(newArr);
                                }}
                            />
                        </div>
                        <div className='w-1/2'>
                            <h3>Show Point Label on Hover</h3>
                            <Checkbox
                                checked={insertStore.mapOptions.pointOptions[index].hover}
                                onChange={() =>
                                    (insertStore.mapOptions.pointOptions[index].hover = !insertStore.mapOptions.pointOptions[index].hover)
                                }
                            />
                            <h3>Select Display Variable:</h3>
                            <select onChange={(e:any)=>{
                                insertStore.mapOptions.pointOptions[index].column = e.target.value;
                            }} className='w-full'>
                                <option value=''>Select a variable</option>
                                {processQuantumVariables(q).map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                ))}

            <Button color='blue' onClick={saveColumnConfig} disabled={!saveAllowed || !pointsAllowed}>
                Add
            </Button>
        </div>
    );
});



export default ColumnConfig;

