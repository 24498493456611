import {useEffect, useState} from 'react';
import Tool, {ToolProps} from './tool';
import {hcIndex} from '../../../lib/api';
import handleError from '../../../lib/error';
import {HCIndexLink, HCIndexSection} from '../../tools/hc-index/types';
import Loading from '../../../components/loading';
import auth from '../../../lib/auth';

const HC_TOOL_SECTION = 'Benchmarks';
const MaxTools = 3;
export default function ToolSection() {
    const [toolList, setToolList] = useState<Array<ToolProps>>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        hcIndex.sections(controller.signal).then((sections: Array<HCIndexSection>) => {
            const toolSection = sections.find((section: HCIndexSection) => section.name === HC_TOOL_SECTION);
            if (toolSection) {
                const entries = toolSection.links.filter((tool: HCIndexLink) => tool.path && (!tool.accessRole || auth.hasRole(tool.accessRole)));
                setToolList(entries.slice(0, MaxTools).map((entry: HCIndexLink) => ({
                    name: entry.name,
                    description: entry.description,
                    link: entry.path,
                    style: 'dark'
                })));
            }
        }).catch(err => handleError(err, setErrorMessage))
            .finally(() => setLoading(false));

        () => controller.abort();
    }, []);

    if (loading)
        return <div className='flex justify-center'><Loading /></div>;

    return <div className="flex">
        {errorMessage && <div className='text-red-500'>{errorMessage}</div>}
        {toolList.map((tool: ToolProps, i: number) => <Tool {...tool} key={i} />)}
    </div>;
}