import React, {useEffect, useState} from 'react';
import {dataRow, dataRows} from '../../../../lib/data-types';
import Loading from '../../../../components/loading';
import Autocomplete from '../../../../components/autocomplete';
import searchItems from '../search';
import Select from '../../../../components/select';
import sortData from '../../../../lib/sort';
import Variable from '../../../../lib/metadata';
import {StringFormatter} from '../../../../lib/formatter';
import Button from '../../../../components/button';

function formatObj(additionalFields: Array<string>) {
    return (obj: dataRow) => {
        const {id, name} = obj;
        let str = `[${id}] ${name}`;
        if (additionalFields.length) {
            const extra = additionalFields.map(f => obj[f]).join(', ');
            str += ` (${extra})`;
        }
        return str;
    };
}

type Props = {
    value: number | null,
    onChange: (id: number | null) => void,
    additionalFields?: Array<string>,
    list: (signal: AbortSignal) => Promise<dataRows>,
    autocomplete?: boolean,
    sortField?: string,
}

export default function EntityInput(props: Props) {
    const {value, onChange, list, autocomplete, sortField} = props;
    const additionalFields = props.additionalFields || [];
    const [objList, setObjList] = useState<dataRows>([]);

    useEffect(() => {
        const controller = new AbortController();
        list(controller.signal).then(objList => {
            const sortVar = new Variable(sortField || 'id', 'id', '', sortField ? 'text' : 'int', {}, new StringFormatter());
            objList = sortData(objList, [[sortVar, true]]);
            setObjList(objList);
        });
        return () => controller.abort();
    }, [list, sortField]);

    if (!objList.length)
        return <Loading small />;

    async function getOptions(value: string): Promise<Array<string>> {
        const results = searchItems(objList, value, ['id', 'name'].concat(additionalFields));
        return results.map(formatObj(additionalFields));
    }

    function handleChange(value: string) {
        if (!value) {
            onChange(null);
            return;
        }
        const match = value.match(/\[(\d+)].*/);
        if (!match)
            return;
        onChange(Number(match[1]));
    }

    // Find the obj associated with the id, and format it to a string for the inputs
    const obj = (value && objList.find(o => o['id'] === value)) || null;
    const inputValue = obj ? formatObj(additionalFields)(obj) : undefined;

    return <div className="flex items-center">
        <div>
            {autocomplete ?
                <Autocomplete value={inputValue} onChange={handleChange} getOptions={getOptions}/> :
                <Select value={inputValue || ''} onChange={handleChange} data={objList}>
                    {formatObj(additionalFields)}
                    {formatObj(additionalFields)}
                </Select>}
        </div>
        <div className="ml-3">
            {value && <Button color="light" onClick={() => onChange(null)}><i className="icon-times" /></Button>}
        </div>
    </div>;
}