import React, {useContext, useEffect, useMemo, useState} from 'react';
import {ResultType, SearchResult} from './search-types';
import Template from '../../components/template';
import Select from '../../components/select';
import Loading from '../../components/loading';
import handleError from '../../lib/error';
import ErrorMessage from '../../components/error-message';
import {performQueries, SearchFilterValues} from './search-common';
import {SearchResultCard} from './search-result-card';
import './search.scss';
import PageTitle from '../../components/page-title';
import CityStateSelect from './city-state-select';
import {trackEvent} from '../../lib/analytics-provider';
import AuthWall from '../../components/auth-wall';
import {HeaderContext} from '../../components/header/context';
import SiteName from '../../components/SiteName';
import SiteSearchView from './site-search-view';

export default function SearchPage() {
    const headerStore = useContext(HeaderContext);
    const SearchPageView = headerStore.useNewDesign ? SiteSearchView : DefaultSearchView;

    return <AuthWall>
        <SearchPageView />
    </AuthWall>;
}

let queryTimeout: any;
function debounce(func: any, time: number) {
    clearTimeout(queryTimeout);
    queryTimeout = setTimeout(func, time);
}
function DefaultSearchView() {
    const queryFromParam = useMemo(() => {
        return new URLSearchParams(window.location.search).get('q') || '';
    }, []);

    const [searchQuery, setSearchQuery] = useState<string>(queryFromParam);
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [searchFilter, setSearchFilter] = useState<string>(SearchFilterValues[0].value);
    const [searchResults, setSearchResults] = useState<Array<SearchResult<ResultType>>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showAdvance, setShowAdvance] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        debounce(() => {
            if (searchQuery) {
                setErrorMessage('');
                setLoading(true);
                performQueries(searchFilter, searchQuery, state, city, controller)
                    .then((results: Array<SearchResult<ResultType>>) => {
                        setSearchResults(results);
                        trackEvent('site-search', {
                            query: searchQuery,
                            city,
                            state
                        });
                    })
                    .catch(e => handleError(e, setErrorMessage))
                    .finally(() => setLoading(false));
            } else {
                setSearchResults([]);
            }
        }, 550);

        return () => controller.abort();
    }, [searchQuery, searchFilter, city, state]);

    function setCityState(city: string, state: string) {
        setCity(city);
        setState(state);
    }

    return <Template showFooter>
        <div className="bg-gray-100" style={{minHeight: '65vh'}}>
            <div className="w-full sticky top-0 bg-gray-100 border-b z-50">
                <PageTitle name={<span>Search <SiteName /></span>} />
                <div className="w-full flex flex-col space-y-3 items-center justify-center mt-5 mb-3">
                    <div className="w-full p-2 sm:p-0 sm:w-2/3 relative flex space-x-5">
                        <div className="flex-grow relative">
                            <div className="absolute left-4 top-5">
                                <i className="icon-search" />
                            </div>
                            <input className="w-full text-xl rounded-full shadow-lg pl-10 mt-2" type="search" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
                        </div>
                    </div>
                    <div className="w-full p-2 sm:p-0 sm:w-2/3 flex">
                        <div className="cursor-pointer flex flex-col sm:flex-row items-center mt-3" onClick={() => setShowAdvance(!showAdvance)}>
                            <span className="ml-2 text-orange-600 flex items-center">{showAdvance ? 'Hide' : 'Advanced'} <i className={`icon-arrow-${showAdvance ? 'left' : 'right'} ml-1`} /></span>
                            {showAdvance && <div className="flex-grow ml-5 flex flex-col lg:flex-row items-center space-y-3 lg:space-y-0 lg:space-x-8">
                                <div className="flex-grow ml-5 flex flex-col">
                                    <small className="ml-1 mb-1">Categories</small>
                                    <Select data={SearchFilterValues} value={searchFilter} onChange={setSearchFilter}>
                                        {_ => _.value}
                                        {_ => _.name}
                                    </Select>
                                </div>
                                <div className="flex-grow ml-5 flex flex-col">
                                    <small className="ml-1 mb-1">City or State</small>
                                    <CityStateSelect onChange={setCityState} />
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            {errorMessage && <ErrorMessage error={errorMessage} />}
            {!loading && <div>
                <div className="w-full mt-3 flex justify-center">
                    <div className="w-2/3 flex justify-end">{searchResults.length} Results</div>
                </div>
                <div className="w-full py-10 flex justify-center">
                    <ul className={`${searchResults.length > 0 ? 'grid-layout' : 'w-2/3'}`}>
                        {searchResults.map((result, index) => <li key={index} className="flex items-center">
                            <SearchResultCard searchResult={result} />
                        </li>)}
                        {searchResults.length === 0 && <li className="p-5 flex flex-col space-y-3 border bg-white">
                            <i className="icon-warning text-2xl" /> <div className="text-lg">Sorry, no results found for "{searchQuery}". Try refining your search by clicking Advanced.</div>
                        </li>}
                    </ul>
                </div>
            </div>}
            {loading && searchQuery !== '' && <div className="w-full flex items-center justify-center mt-24">
                <Loading large />
            </div>}
        </div>
    </Template>;
}