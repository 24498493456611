import mapboxgl from 'mapbox-gl';
import {useEffect, useState} from 'react';
import {geography} from '../../../lib/api';
import {FeatureCollection} from '@turf/turf';


const geoConnectingGrain: {[key: string]: string} = {
    state: 'state_fips',
    cbsa: 'cbsa',
    county: 'fips',
    zcta: 'zcta'
};

const shapeColors = {
    lowColor: '#1B6798',
    highColor: '#d12d2a',
    noDataColor: '#aaaaaa'
};

function parseParts(color: string) {
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);
    return {r, g, b};
}

function generateColor(parts: any) {
    let r = Math.floor(parts.r).toString(16);
    let g = Math.floor(parts.g).toString(16);
    let b = Math.floor(parts.b).toString(16);
    r = r.length === 1 ? '0' + r : r;
    g = g.length === 1 ? '0' + g : g;
    b = b.length === 1 ? '0' + b : b;
    return '#' + r + g + b;
}

const interpolateColor = function(color1: string, color2: string, p: number) {
    if (p < 0) { return color1; }
    if (p > 1) { return color2; }
    const parts1 = parseParts(color1);
    const parts2 = parseParts(color2);
    return generateColor({
        r: (1 - p) * parts1.r + p * parts2.r,
        g: (1 - p) * parts1.g + p * parts2.g,
        b: (1 - p) * parts1.b + p * parts2.b
    });
};

function setupMapControls(map: mapboxgl.Map) {
    if (map) {
        map.addControl(
            new mapboxgl.NavigationControl({
                visualizePitch: true
            }), 'top-left');
    }
}

function useGeoData(shape:string, year:string, renditionSize:string) {
    if (shape === 'state_fips') {
        shape = 'state';
    }
    year = shape === 'cbsa' ? year : 'latest';
    const [shapes, setShapes] = useState<FeatureCollection | undefined>();
    const controller = new AbortController();
    async function getGeographyShapes() {
        const geo = await geography.get(shape, year, renditionSize, controller.signal);
        if (geo) {
            setShapes(geo);                  
        }
    }
    
    useEffect(()=>{
        getGeographyShapes();

    },[shape, year, renditionSize]);

    return shapes;
}

export {
    geoConnectingGrain,
    shapeColors,
    interpolateColor,
    setupMapControls,
    useGeoData
};