import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router';
import Navbar, {NavbarProps} from './navbar';
import saveEvent from '../lib/analytics';
import Footer from './footer';
import {datasets, initTorchDataDefs} from '../lib/data-defs';
import {observer} from 'mobx-react';
import {HeaderContext} from './header/context';
import Header from './header';
import MyContent from './header/my-content/my-content';
import SiteFooter from './footer-new';
import SideNav from './side-nav/index';
import classNames from '../lib/class-names';

type Props = NavbarProps & {
    children?: React.ReactNode,
    showFooter?: boolean
};

const noDSPages = [
    '/login',
    '/change-password',
    '/reset-password'
];

function Template(props: Props) {
    const {float, light, className, showFooter} = props;
    const [datasetsLoaded, setDatasetsLoaded] = useState<boolean>(!!datasets.length);
    const location = useLocation();
    const noDSPage = useMemo(() => noDSPages.includes(location.pathname), [location.pathname]);
    const headerStore = useContext(HeaderContext);

    useEffect(() => {
        if (process.env.NODE_ENV !== 'test') // window.scrollTo isn't implemented in the testing framework
            window.scrollTo(0, 0);
        saveEvent('pageView', {page: location.pathname});
    }, []);

    useEffect(() => {
        if (!datasets.length && !noDSPage) {
            initTorchDataDefs().then(() => setDatasetsLoaded(true));
        }
    }, [noDSPage]);

    useEffect(() => {
        headerStore.showMyContent = (location.pathname === '/' && headerStore.getShowMyContentPreference());
    }, [location]);

    useEffect(() => {
        document.documentElement.dataset.carbonTheme = 'g10';
    }, []);

    const showSideNav = useMemo(() => {
        return !noDSPages.includes(location.pathname) && headerStore.useNewDesign;
    }, [location.pathname, headerStore.useNewDesign]);

    const bgColor = useMemo(() => {
        if (!showFooter)
            return 'bg-white';
        else if (headerStore.useNewDesign)
            return 'bg-milliman-slate-500';
        else
            return 'bg-gray-700';
    }, [showFooter, headerStore.useNewDesign]);

    return <div className={`${bgColor}`}>
        {showSideNav && <SideNav/>}
        {headerStore.showMyContent && headerStore.useNewDesign && <div className="fixed right-0 top-0 h-screen w-1/5 z-[100]">
            <MyContent />
        </div>}
        {headerStore.useNewDesign ? <Header className={className} /> : <Navbar float={float} light={light} className={className} />}
        {(datasetsLoaded || noDSPage || process.env.NODE_ENV === 'test') && <div className={classNames({'ml-14': showSideNav}, {'bg-white': showFooter})}>
            {props.children}
        </div>}
        {showFooter ? (headerStore.useNewDesign ? <SiteFooter/> : <Footer />) : null}
    </div>;
}

export default observer(Template);