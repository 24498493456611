import {DataConfig} from '../../../insight-container/types';
import createGraphHandler from '../../../lib/graph-tools/graph-handler/create';
import {deserialize, TestType} from 'quantum-graph';
import {dataRow} from '../../../lib/data-types';
import {CanvasParameter, ParameterConfig} from './types';
import Variable from '../../../lib/metadata';
import {StringFormatter} from '../../../lib/formatter';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';

export const ParameterEntities = [
    {value: 'aco', label: 'ACO'},
    {value: 'home-health-agency-directory', label: 'HHA'},
    {value: 'hospice-directory', label: 'Hospice'},
    {value: 'hospital-directory', label: 'Hospital'},
    {value: 'payer-group', label: 'Payer'},
    {value: 'provider', label: 'Provider'},
    {value: 'provider-group', label: 'Provider Group'},
    {value: 'snf-directory', label: 'SNF'},
];

export const ParameterRegions = [
    {value: 'state', label: 'State'},
    {value: 'county', label: 'County'},
    {value: 'cbsa', label: 'CBSA'},
];

export function applyParameters(config: DataConfig, parameterConfigs: Array<ParameterConfig>, parameterRow: dataRow): DataConfig {
    if (!parameterConfigs.length || !config.queries.length)
        return config;

    const gh = createGraphHandler(config.queries[0], getDefaultDataDefRepo());
    parameterConfigs.forEach(pc => {
        gh.applyOverride({
            field: pc.dataModelField,
            test: pc.test,
            value: parameterRow[pc.parameterField] as string
        });
    });

    const graph = gh.build();
    return {
        queries: [graph, ...config.queries.slice(1)],
        options: config.options
    };
}

export function loadParameterValues(search: string): Array<string> {
    const searchParams = new URLSearchParams(search);
    const values: Array<string> = [];
    const add = (key: string, idx: number) => {
        const valueStr = searchParams.get(key);
        if (!valueStr)
            return;
        values[idx] = valueStr;
    };

    add('id', 0);
    add('p0', 0);
    add('p1', 1);
    add('p2', 2);
    add('p3', 3);

    return values;
}

export function createParameterQuery(parameter: CanvasParameter, parameterValue: string) {
    let gh = createGraphHandler(deserialize(parameter.query), getDefaultDataDefRepo());
    gh.applyFilters([{
        variable: new Variable(parameter.idField, '', '', '', {}, new StringFormatter(), gh.getDatasetFromVariable(parameter.idField)),
        test: TestType.Eq,
        value: parameterValue
    }]);

    return gh.build();
}