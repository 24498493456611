export const templates:[string,any][] = [
    [
        'PATIENT_FLOW_GEO_MSA_PROVIDER_TYPE_FACT',
        {
            version: 2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'patient-flow-geo-msa-provider-type',
                            dataset: 'patient-flow-geo-msa-provider-type',
                            columns: [
                                'BEGIN_YEAR_KEY',
                                'END_YEAR_KEY',
                                'LOB',
                                'CBSA_DIM_KEY',
                                'ORIGIN_PROVIDER_TYPE',
                                'DEST_PROVIDER_TYPE',
                            ],
                        },
                    ],
                },
                joins: [],
            },
            sort: [],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'patient-flow-geo-msa-provider-type',
                        column: 'lob',
                    },
                    test: '=',
                    value: 'CHSD_COM_IND',
                },
            ],
            transforms: [],
        },
    ],
    [
        'PATIENT_FLOW_GEO_MSA_PROVIDER_FACT',
        {
            version: 2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'patient-flow-geo-msa-provider-type',
                            dataset: 'patient-flow-geo-msa-provider-type',
                            columns: [
                                'BEGIN_YEAR_KEY',
                                'END_YEAR_KEY',
                                'LOB',
                                'CBSA_DIM_KEY',
                                'ORIGIN_PROVIDER_TYPE',
                                'ORIGIN_PROVIDER_NUMBER',
                                'ORIGIN_FACILITY_DIM_KEY',
                                'DEST_PROVIDER_TYPE',
                                'DEST_PROVIDER_NUMBER',
                                'DEST_FACILITY_DIM_KEY',
                            ],
                        },
                    ],
                },
                joins: [],
            },
            sort: [],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'patient-flow-geo-msa-provider-type',
                        column: 'lob',
                    },
                    test: '=',
                    value: 'CHSD_COM_IND',
                },
            ],
            transforms: [],
        },
    ],
    [
        'map-cbsa-net-patient-revenue',
        {
            version: 2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'scaffold',
                    tableNodes: [
                        {
                            id: 'facility',
                            dataset: 'facility',
                            columns: [
                                'torch_facility_id',
                                'facility_name',
                                'longitude',
                                'latitude'
                            ]
                        },
                        {
                            id: 'hospital-market-share-cbsa',
                            dataset: 'hospital-market-share-cbsa',
                            columns: [
                                'cbsa',
                                'net_patient_revenue'
                            ]
                        }
                    ],
                    scaffoldField: 'year_key',
                    scaffoldType: 'max'
                },
                joins: []
            },
            sort: [
                {
                    column: {
                        nodeId: 'hospital-market-share-cbsa',
                        column: 'cbsa'
                    },
                    asc: true
                }
            ],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hospital-market-share-cbsa',
                        column: 'net_patient_revenue'
                    },
                    test: 'is not null',
                    value: ''
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hospital-market-share-cbsa',
                        column: 'cbsa'
                    },
                    test: 'is not null',
                    value: ''
                }
            ],
            transforms: []
        }
    ],
    [
        'sankey-vis-1',
        {
            version: 2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'patient-flow-provider-condition-sankey',
                            dataset: 'patient-flow-provider-condition-sankey',
                            columns: [
                                'begin_year_key',
                                'end_year_key',
                                'lob',
                                'origin_provider_type',
                                'origin_provider_number',
                                'origin_facility_dim_key',
                                'origin_condition_category',
                                'origin_condition_category_code',
                                'dest_provider_type',
                                'dest_provider_number',
                                'dest_facility_dim_key',
                                'dest_condition_category',
                                'dest_condition_category_code',
                                'patients',
                                'episodes',
                                'origin_allowed',
                                'dest_allowed'
                            ]
                        }
                    ],
                    scaffoldType: ''
                },
                joins: []
            },
            sort: [],
            filters: [],
            transforms: []
        }
    ],
    [
        'scatter',
        {version: 2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'scaffold',
                    tableNodes: [
                        {
                            id: 'facility',
                            dataset: 'facility',
                            columns: [
                                'torch_facility_id',
                                'facility_name',
                                'category',
                                'category_subtype',
                                'city_name',
                                'abbreviated_state',
                                'ownership_type',
                                'provider_number',
                                'owner_torch_health_system_id',
                                'cost_reporting_category',
                                'milliman_hospital_type',
                                'termination_or_expiration_date_key'
                            ]
                        },
                        {
                            id: 'hospital-cost-report-beds',
                            dataset: 'hospital-cost-report-beds',
                            columns: [
                                'beds'
                            ]
                        },
                        {
                            id: 'hospital-cost-report-total-revenue',
                            dataset: 'hospital-cost-report-total-revenue',
                            columns: [
                                'net_patient_revenue',
                                'total_patient_revenue'
                            ]
                        },
                        {
                            id: 'structural-measures',
                            dataset: 'structural-measures',
                            columns: [
                                'hospital_star_rating'
                            ]
                        },
                        {
                            id: 'value-based-purchasing',
                            dataset: 'value-based-purchasing',
                            columns: [
                                'total_performance_score'
                            ]
                        },
                        {
                            id: 'hospital-cost-report-calculated-metrics',
                            dataset: 'hospital-cost-report-calculated-metrics',
                            columns: [
                                'bed_utilization',
                                'average_length_of_stay',
                                'average_daily_census',
                                'percent_revenue_medicare',
                                'percent_revenue_medicaid',
                                'percent_revenue_commercial',
                                'operating_margin'
                            ]
                        }
                    ],
                    scaffoldField: 'year_key',
                    scaffoldType: ''
                },
                joins: []
            },
            sort: [
                {
                    column: {
                        nodeId: 'facility',
                        column: 'torch_facility_id'
                    },
                    asc: true
                },
                {
                    column: {
                        nodeId: 'facility_scaffold',
                        column: 'year_key'
                    },
                    asc: false
                }
            ],
            filters: [
                {
                    type: 'basic',
                    column: {
                        column: 'category',
                        nodeId: 'facility'
                    },
                    test: '=',
                    value: 'Hospital'
                },
                {
                    type: 'basic',
                    column: {
                        column: 'cost_reporting_category',
                        nodeId: 'facility'
                    },
                    test: '=',
                    value: 'Reported Recently'
                },
                {
                    type: 'basic',
                    column: {
                        column: 'termination_or_expiration_date_key',
                        nodeId: 'facility'
                    },
                    test: 'is null',
                    value: ''
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'facility',
                        column: 'facility_name'
                    },
                    test: 'in',
                    value: [
                        'Southeastern Regional Medical Center, Inc.',
                        'Intermountain Hospital',
                        'Ucsf Medical Center',
                        'Harborview Medical Center'
                    ]
                },
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hospital-cost-report-calculated-metrics',
                        column: 'average_daily_census'
                    },
                    test: 'is not null',
                    value: ''
                }
            ],
            transforms: [],
            columnOrder: []
        }
    ],
];
