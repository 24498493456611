import {HCIndexSection} from '../../tools/hc-index/types';
import {useState, useEffect} from 'react';
import {CanvasLink, CanvasSectionType} from '../../canvas/lib/types';
import {HCIndexEntry} from '../../tools/hc-index/types';
import {canvas, hcIndex} from '../../../lib/api';
import Cache from '../../../lib/cache';
import {CacheMiss} from '../../../lib/cache';
import handleError from '../../../lib/error';
import {getIcon} from '../../../components/side-nav';
import SelectorTab from './selector-tab';
import Loading from '../../../components/loading';
import {useNavigate, NavigateFunction} from 'react-router-dom';
import '../landing-new.scss';

enum TabTypes {
    canvas = 'canvas',
    hcIndex = 'hcIndex'
}

type SelectorTabData = {
    id:string,
    type: TabTypes,
    data: CanvasLink[] | HCIndexEntry[],
    selected: boolean
}

export default function Selector() {
    const [hcIndices, setHcIndices] = useState<HCIndexSection[]>([]);
    const [canvasSections, setCanvasSections] = useState<CanvasSectionType[]>([]);
    const [tabData, setTabData] = useState<SelectorTabData[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [err, setErr] = useState('');

    const setTabDataWithName = (name: string) => {
        setTabData((prevTabData) => 
            prevTabData.map((tab) => ({
                ...tab,
                selected: tab.id === name
            }))
        );
    };

    useEffect(() => {
        const newTabData = [];
        canvasSections.forEach((section)=>{
            newTabData.push(
                {
                    id: section.title,
                    type: TabTypes.canvas,
                    data: section.links,
                    selected: false
                }
            );
        });

        newTabData.push({
            id: 'Build a Dataset',
            type: TabTypes.hcIndex,
            data:  hcIndices.flatMap(section => section.entries),
            selected: false
        });

        if (newTabData.length > 0) {
            newTabData[0].selected = true;
        }

        setTabData(newTabData);
    }, [canvasSections, hcIndices]);

    useEffect(() => {
        const controller = new AbortController();
        const cacheKey = 'canvasSections';

        try {
            const cachedData = Cache.get(cacheKey);
            if (cachedData) {
                setCanvasSections(cachedData as CanvasSectionType[]);
            }
        } catch (error) {
            if (error instanceof CacheMiss) {
                canvas
                    .listSections(controller.signal)
                    .then((apiSections) => {
                        setCanvasSections(apiSections);
                        Cache.set(cacheKey, apiSections);
                    })
                    .catch((err) => handleError(err, setErr));
            } else {
                handleError(error as Error, setErr);
            }
        }

        return () => controller.abort();
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const cacheKey = 'hcIndices';
        try {
            const cachedData = Cache.get(cacheKey);
            if (cachedData) {
                setHcIndices(cachedData as HCIndexSection[]);
            }
        } catch (error) {
            if (error instanceof CacheMiss) {
                hcIndex
                    .sections(controller.signal)
                    .then((sections: Array<HCIndexSection>) =>
                        sections.filter((s) => s.entries.length || s.links.length),
                    )
                    .then(setHcIndices)
                    .catch((err) => handleError(err, setErr));
            } else {
                handleError(error as Error, setErr);
            }
        }

        return () => controller.abort();
    }, []);

    return (
        <div>
            <div className='flex mt-4'>
                {tabData.map((tab) => (
                    <SelectorTab
                        key={tab.id}
                        icon={getIcon(tab.id)}
                        name={tab.id}
                        selected={tab.selected}
                        setter={setTabDataWithName}
                    />
                ))}
            </div>
            <SectionRenderer data={tabData} />
        </div>
    );
}
    
function SectionRenderer(props: {data:SelectorTabData[]}) {
    const {data} = props;
    const selected = data.find((tab)=>tab.selected);
    const navigate = useNavigate();
    
    
    function findRenderer(type:TabTypes, selected:SelectorTabData) {
        if (type === TabTypes.canvas) {
            return <CanvasRenderer data={selected.data as CanvasLink[]} navigate={navigate} title={selected.id}/>;
        }
        else if (type === TabTypes.hcIndex) {
            return <HCIndexRenderer data={selected.data as HCIndexEntry[]} navigate={navigate} />;
        }
        else {
            return <Loading/>;
        }
    }
    
    if (selected) {
        return findRenderer(selected.type, selected);
    } else{
        return <div>Error</div>;
    }
}
    
function CanvasRenderer(props: { data: CanvasLink[], navigate: NavigateFunction, title: string }) {
    const {data, navigate, title} = props;
    return (
        <div className='section-content'>
            <p className="mb-4">Explore Canvases related to {title}</p>
            <div className="columns-1 md:columns-5">
                {data.map(({title, url}) => (
                    <div
                        className="data-item"
                        onClick={() => navigate(url)}
                        key={title}
                    >
                        {title}
                    </div>
                ))}
            </div>
        </div>
    );
}
    
function HCIndexRenderer(props: { data: HCIndexEntry[], navigate: NavigateFunction }) {
    const {data, navigate} = props;
    return (
        <div className='section-content'>
            <p className="mb-4">Build a dataset from our curated Healthcare Index list</p>
            <div className="columns-1 md:columns-5">
                {data.map(({name, shortName}) => (
                    <div
                        className="data-item"
                        onClick={() => navigate(`/healthcare-index/${shortName}`)}
                        key={name}
                    >
                        {name}
                    </div>
                ))}
            </div>
        </div>
    );
}


