import {dataRows, Entity, QueryObj} from '../../lib/data-types';
import {Feature, FeatureCollection, GeoJsonProperties, Geometry} from 'geojson';
import  {MapboxGeoJSONFeature} from 'mapbox-gl';
import {deserialize, GraphVersions, TestType} from 'quantum-graph';
import fetchData from '../../lib/data';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import {geography} from '../../lib/api';
import {getCBSARestrictions, getStateRestrictions} from '../../lib/geo-restrictions';
import {PermissionModule} from '../../lib/check-module-access';
import {LayerPropsWithType, Layers} from '../base-map/layers/types';
import {makeAutoObservable} from 'mobx';
import {MapLayerMouseEvent} from 'react-map-gl';


export default class MapSelectionController {
    public readonly shape: string;
    private readonly year: string;
    private readonly renditionSize: string ; 
    private readonly fillColor: string ;
    private readonly selectionColor: string; 
    private readonly disabledColor: string;
    private readonly opacity: number; 
    public allowedValues: Entity[]; 
    private readonly geoRestrictionModules: Array<PermissionModule>;
    private readonly onClick: ((selected: Entity[]) => void) | undefined;
    private readonly cbsaColumns = {
        key: 'cbsa_dim_key',
        id:  'cbsa',
        name: 'cbsa_name'};
    
    private readonly stateColumns = {
        key: 'state_dim_key',
        id:  'state_fips',
        name: 'state'
    };
    public readonly paint: any;
    public geoRestrictedValues: number[] = [];
    public selectedShapes: Entity[] = [];
    public layer: LayerPropsWithType | undefined;
    private id:string;
    public features: Array<Feature> = [];
    public popupOptions:any;
  
    constructor(
        shape: string,
        year: string,
        renditionSize?: string,
        fillColor?: string,
        selectionColor?: string,
        disabledColor?:string,
        opacity?: number,
        onClick?: ((selected: Entity[]) => void),
        geoRestrictionModules?: Array<PermissionModule>,
        allowedValues?: Entity[],
        id?:string,
    ) {
        this.shape = shape;
        this.year = year;
        this.fillColor = fillColor || '#BFBEBC';
        this.selectionColor = selectionColor || '#FF8D3C';
        this.disabledColor = disabledColor || '#7c7a76';
        this.opacity = opacity || .6;
        this.paint = {
            'fill-color': ['get','color'],
            'fill-opacity': this.opacity || 0.6,
            'fill-outline-color': '#000000',
            'fill-antialias': true
        };
        this.allowedValues = allowedValues || [];
        this.onClick = onClick;
        this.renditionSize = renditionSize || 'sm';
        this.geoRestrictionModules = geoRestrictionModules || [];
        this.id = id || 'shapes';
        this.popupOptions = {
            closeButton: false,
            closeOnClick: false,
            longitude: 0,
            latitude: 0,
        };
        makeAutoObservable(this);
    }
  
    public processSelectedShapes() {
        if (this.features.length) {
            for (const feature of this.features) {
                if (!feature.properties) {
                    feature.properties = {};
                }
      
                let color = this.fillColor || '#BFBEBC';
      
                if (this.shape === 'national' || this.isShapeSelected(feature)) {
                    color = this.selectionColor || '#FF8D3C'; // Set to selected color.
                } else if (!this.isShapeAllowed(feature)) {
                    color = this.disabledColor || '#7c7a76'; // Set to disabled color.
                }
      
                feature.properties.color = color;
            }
            this.buildLayer();
        }
    }
  
    private isShapeSelected(feature: Feature<Geometry, GeoJsonProperties>) {
        return this.selectedShapes.some(selected => this.getShapeId(feature as MapboxGeoJSONFeature) === selected.id);
    }
  
    private getShapeId(feature: MapboxGeoJSONFeature): number {
        const key = this.shape === 'cbsa' ? 'CBSAFP' : 'STATEFP';
        return Number(feature.properties![key]);
    }
  
    private isShapeAllowed(feature: Feature<Geometry, GeoJsonProperties>): boolean {
        if (this.shape === 'cbsa') {
            return this.isCBSAAllowed(feature);
        } else if (this.shape === 'state') {
            return this.isStateAllowed(feature);
        }
  
        return true;
    }
  
    private isCBSAAllowed(feature: Feature<Geometry, GeoJsonProperties>): boolean {
        return (
            (!this.allowedValues.length || this.allowedValues.some(allowedEntity => this.getShapeId(feature as MapboxGeoJSONFeature).toString() === allowedEntity.id.toString().padStart(2, '0'))) &&
        (!this.geoRestrictedValues.length || this.geoRestrictedValues.some(geoRestriction => this.getShapeId(feature as MapboxGeoJSONFeature).toString() === geoRestriction.toString().padStart(2, '0')))
        );
    }
  
    private isStateAllowed(feature: Feature<Geometry, GeoJsonProperties>): boolean {
        return (
            (!this.allowedValues.length || this.allowedValues.some(allowedEntity => this.getShapeId(feature as MapboxGeoJSONFeature) === Number(allowedEntity.id))) &&
            (!this.geoRestrictedValues.length || this.geoRestrictedValues.some(geoRestriction => this.getShapeId(feature as MapboxGeoJSONFeature) === Number(geoRestriction)))
        );
    }
    
    public mapboxOnClickCb = async (event: MapLayerMouseEvent) => {
        if (this.shape !== 'national') {
            const feature = event.features![0];
            const shapeId = this.getShapeId(feature as any);
            const selected = [...this.selectedShapes];
            if (this.isShapeAllowed(feature)) {
                if (this.isShapeSelected(feature)) {
                    selected.splice(selected.findIndex(s => s.id === shapeId), 1);
                } else {
                    const geoData = await this.getGeoId(shapeId);
                    selected.push(geoData);
                }
                this.onClick && this.onClick(selected);
            }
        }
    };
    
    private async getGeoId(shapeId: number): Promise<{name: string, id: number, key: number}> {
        let queryObject = this.generateGeoQuantumQuery();
        queryObject.filters = [
            {
                type: 'basic',
                column: {
                    nodeId: this.shape,
                    column: this.shape === 'cbsa' ? 'cbsa' : 'state_fips'
                },
                test: TestType.Eq,
                value: shapeId.toString().padStart(2, '0')
            }
        ];
    
        if (this.shape === 'cbsa') {
          queryObject.filters!.push({
              type: 'basic',
              column: {
                  nodeId: this.shape,
                  column: 'year_key'
              },
              test: TestType.Eq,
              value: this.year
          });
        }
    
        const geoData: dataRows = await fetchData(deserialize(queryObject), new AbortController().signal);
        const geoKey: string = this.shape === 'cbsa' ? 'cbsa_dim_key' : 'state_dim_key';
        const geoNameKey: string = this.shape === 'cbsa' ? 'cbsa_name' : 'state';
        return {name: geoData[0][geoNameKey] as string, id: shapeId, key: geoData[0][geoKey] as number};
    }
    
    private generateGeoQuantumQuery(): QueryObj {
        return {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: QueryType.Dim,
                    tableNodes: [
                        {
                            id: this.shape,
                            dataset: this.shape,
                            columns: this.shape === 'cbsa' ? Object.values(this.cbsaColumns) : Object.values(this.stateColumns)
                        }
                    ]
                }
            }
        };
    }
    
    private async getGeoData() {
        let year = this.shape === 'cbsa' ? this.year : 'latest';
        const controller = new AbortController();
        const geo = await geography.get(this.shape, year, this.renditionSize, controller.signal);
        if (geo) {
            this.features = geo.features;                  
        }
    }

    private async getGeoRestrictions() {
        if (this.shape === 'cbsa')
            this.geoRestrictedValues = await getCBSARestrictions(this.geoRestrictionModules);
        else if (this.shape === 'state')
            this.geoRestrictedValues = await getStateRestrictions(this.geoRestrictionModules);
    }

    private buildLayer() {
        const layer: LayerPropsWithType = {id: this.id, data: this.shapes, paint: this.paint, layerType: Layers.choropleth};
        this.layer = {...layer};
    }

    public async init() {
        try{
            await this.getGeoData();
            await this.getGeoRestrictions();
            await this.processSelectedShapes();
        } catch(e) {
            console.log(e);
        }
        
    }

    public updateSelectedValues(values:Entity[]) {
        this.selectedShapes = values;
        this.processSelectedShapes();
    }

    public setAllowedValues(values: Array<Entity>) {
        this.allowedValues = values;
        this.processSelectedShapes();
    }

    public mouseMoveCB = (features:any) => {
        if (this.shape !== 'national' && features.length) {
            const feature = features![0];
            const name = feature.properties!.NAME;
            const disabledText = this.isShapeAllowed(feature) ? null : 'Data unavailable';
            const newPopupOptions = {
                latitude: feature.properties!.INTPTLAT,
                longitude: feature.properties!.INTPTLON ,
                name: name,
                disabledText: disabledText
            };
            this.popupOptions = newPopupOptions;
        }
    };

    get shapes(): FeatureCollection {
        return {
            type: 'FeatureCollection',
            features: this.features ? this.features : []
        };
    }
}
