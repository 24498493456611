import {V2QuantumGraph} from 'quantum-graph/out/v2';
import {DataDefRepo} from '../../../user-data-defs';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import Library from '../../../library';
import Variable from '../../../metadata';

const YearKey = 'year_key';
const YearMonthKey = 'year_month_key';

function findTimeVar(library: Library, dataset: string, timeVarName: string = YearKey, timeTag = 'year'): Variable | null {
    for (const section of library.getSections()) {
        const vars = section.index.getVariables();
        for (const v of vars) {
            if (v.dataset === dataset && (v.name === timeVarName || v.getTag('Time') === timeTag))
                return v;
        }
    }
    return null;
}

export function updateYearKey(graph: V2QuantumGraph, library: Library, dataDefRepo: DataDefRepo) {
    // Reset graph back to Dim query type in case scaffold fact was removed.
    const baseQuery = graph.baseQueryGraph.baseQuery;
    if (baseQuery.queryType === QueryType.DimScaffold) {
        baseQuery.queryType = QueryType.Dim;
        delete baseQuery.scaffoldField;
        delete baseQuery.scaffoldType;
    }

    // Reevaluate each graph table to see if it's a fact with a year_key grain. If so convert to a DimScaffold query.
    const isAffiliation = graph.baseQueryGraph.joins.flatMap(j => j.node.baseQuery.tableNodes).some(n => dataDefRepo.datasetByName[n.dataset].tableType === 'aff');
    graph.forEachTable((node, baseQuery) => {
        const dataDef = dataDefRepo.datasetByName[node.dataset];
        const yearVariable = findTimeVar(library, node.dataset);

        const isFact = dataDef.tableType === 'fact';
        const hasYearGrain = dataDef.tableGrain.includes(YearKey);
        const isPrimaryBaseQuery = graph.baseQueryGraph.baseQuery.tableNodes.includes(node);
        const isDimQuery = baseQuery.queryType === QueryType.Dim;

        if (isFact && hasYearGrain && yearVariable) {
            // Check if the primary dim query is suitable for a dim query
            if (isPrimaryBaseQuery && isDimQuery) {
                baseQuery.queryType = isAffiliation ? QueryType.AffiliationScaffold : QueryType.DimScaffold;
                baseQuery.scaffoldField = yearVariable.name;
                //remove year_key variable from graph. It will automatically be re-added by quantum-api
                removeScaffoldFields(graph);
            } else if (isAffiliation) {
                let primaryBaseQuery = graph.baseQueryGraph.baseQuery;
                primaryBaseQuery.queryType = QueryType.AffiliationScaffold;
                primaryBaseQuery.scaffoldField = yearVariable.name;
                removeScaffoldFields(graph);
            }
            graph.baseQueryGraph.joins.forEach(join => {
                join.node.baseQuery.queryType = isAffiliation && join.node.baseQuery.queryType === QueryType.Affiliation ? QueryType.TiedAffiliationScaffold : join.node.baseQuery.queryType;
            });
        }

        const hasYearMonthGrain = dataDef.tableGrain.includes(YearMonthKey);
        const yearMonthVariable = findTimeVar(library, node.dataset, YearMonthKey, 'month');
        const includesYearMonthColumn = hasYearMonthGrain && yearMonthVariable && node.columns.includes(YearMonthKey);

        if (isFact && hasYearMonthGrain && yearMonthVariable && !includesYearMonthColumn) {
            node.columns.push(YearMonthKey);
        }
    });
}

function removeScaffoldFields(graph: V2QuantumGraph) {
    graph.forEachTable(table => {
        let yearKeyIndex = table.columns.indexOf(YearKey);
        if (yearKeyIndex !== -1)
            table.columns.splice(yearKeyIndex, 1);
    });
}