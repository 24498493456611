import React, {useContext, useEffect, useState} from 'react';
import Filter from '../../lib/filter';
import Badge from '../badge';
import {TestType} from 'quantum-graph';
import {dataValue} from '../../lib/data-types';
import Variable, {batchLoadDatasetInfo} from '../../lib/metadata';
import SortIcon from '../table/sort-icon';
import {HeaderContext} from '../header/context';
import {ExpandableTile, TileAboveTheFoldContent, TileBelowTheFoldContent, DismissibleTag, Tag} from '@carbon/react';
import './filter-bar.scss';

type Props = {
    filters: Array<Filter>,
    sorts: Array<[Variable, boolean]>,
    queryName: string,
    lockedFilters?: Array<Filter>,
    scaffoldFilter?: {field: string, type: string},
    onRemove?: (i: number) => void,
    onModify?: (filter: Filter) => void,
    onSortToggle?: (sort: [Variable, boolean]) => void,
    onSortRemove?: (sort: [Variable, boolean]) => void,
    onScaffoldRemove?: () => void
    onClearAll?: () => void,
};

function formatTest(test: TestType) {
    if (test === TestType.Like)
        return 'includes text';
    else if (test === TestType.NEq)
        return 'not =';
    else if (test === TestType.Null)
        return 'not has data';
    else if (test === TestType.NotNull)
        return 'has data';
    else if (test === TestType.In)
        return 'is one of';
    else if (test === TestType.NotIn)
        return 'is not one of';
    else
        return test;
}

function formatValue(filter: Filter) {
    if (filter.test === TestType.Null || filter.test === TestType.NotNull)
        return null;
    else if (filter.test === TestType.Like) {
        const s = String(filter.value);
        return '"' + s.substring(1, s.length - 1) + '"';
    } else if (filter.value instanceof Array) {
        return '(' + filter.variable.formatter.formatSimple(filter.value[0] as dataValue) + ' , ...)';
        // return '(' + filter.value.map(v => filter.variable.formatter.formatSimple(v as dataValue)).join(', ') + ')';
    } else
        return filter.variable.formatter.formatSimple(filter.value);
}

function colorIndex(i: number) {
    if (i === 0)
        return 'light';
    if (i % 5 === 0)
        return 'milliman-blue';
    if (i % 4 === 0)
        return 'medinsight-orange';
    if (i % 3 === 0)
        return 'medinsight-spring';
    if (i % 2 === 0)
        return 'medinsight-yellow';
    if (i % 1 === 0)
        return 'medinsight-vibrant-blue';

    return 'light';
}

function cleanupDSName(ds: string) {
    const dsParts = ds.split('-');
    let name = '';
    for (const part of dsParts) {
        let capPart = '';
        if (part.length > 2)
            capPart = part[0].toUpperCase() + part.substring(1, part.length);
        else
            capPart = part;
        name += capPart + ' ';
    }

    return name;
}


export default function FilterBar(props: Props) {
    const {filters, sorts, scaffoldFilter, onRemove, onModify, lockedFilters, queryName, onSortToggle, onSortRemove, onScaffoldRemove, onClearAll} = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filterDSInfo, setFilterDSInfo] = useState<Array<{ dataset: string, label: string, filters: Array<Filter>}>>([]);
    const {useNewDesign} = useContext(HeaderContext);


    useEffect(() => {
        const controller = new AbortController();
        const filterDS = filters.map(f => f.variable.dataset!).filter(_ => _);
        batchLoadDatasetInfo(filterDS, controller.signal).then(info => {
            const filterDSInfoArray: Array<{ dataset: string, label: string, filters: Array<Filter> }> = [];
            filterDS.forEach((ds, i) => {
                if (!filterDSInfoArray.some(fDSI => fDSI.dataset === ds)) {
                    const filterDS = {
                        dataset: ds,
                        label: ds.includes('_scaffold') ? 'Fact Year' : info[i].label || cleanupDSName(ds),
                        filters: filters.filter(f => f.variable.dataset === ds)
                    };
                    filterDSInfoArray.push(filterDS);
                }
            });
            const noDSFilters = filters.filter(f => !f.variable.dataset);
            if (noDSFilters.length > 0) {
                filterDSInfoArray.push({
                    dataset: 'unknown_dataset',
                    label: 'Page Level',
                    filters: noDSFilters
                });
            }
            setFilterDSInfo(filterDSInfoArray);
        });
    }, [filters]);

    function findFilterIndex(filter: Filter) {
        return filters.findIndex(f => f.variable.getId() === filter.variable.getId() && f.test === filter.test && f.value === filter.value);
    }

    function getFilterLength() {
        let length = filters.length;
        if (scaffoldFilter)
            length++;

        return length;
    }

    function canRemove(filter: Filter) {
        const isPinned = !!filter.variable.getTag('Pinned');
        const isLocked = lockedFilters?.includes(filter);

        return !!onRemove && !isPinned && !isLocked;
    }

    function canModify(filter: Filter) {
        return !!onModify && canRemove(filter);
    }

    function modifyFilter(filter: Filter) {
        if (canModify(filter))
            onModify!(filter);
    }

    let FilterBarView = useNewDesign ? NewFilterBarView : DefaultFilterBarView ;
    let filterViewProps: FilterBarViewProps = {
        isOpen,
        queryName,
        getFilterLength,
        sorts,
        setIsOpen,
        onClearAll,
        filterDSInfo,
        canModify,
        modifyFilter,
        canRemove,
        onRemove,
        findFilterIndex,
        formatTest,
        formatValue,
        colorIndex,
        scaffoldFilter,
        onScaffoldRemove,
        onSortToggle,
        onSortRemove,
    };
    return <FilterBarView {...filterViewProps} />;
}

interface FilterBarViewProps {
    isOpen: boolean;
    queryName: string;
    getFilterLength: () => number;
    sorts: Array<[Variable, boolean]>;
    setIsOpen: (open: boolean) => void;
    onClearAll?: () => void;
    filterDSInfo: Array<{ dataset: string, label: string, filters: Array<Filter>}>;
    canModify: (filter: Filter) => boolean; 
    modifyFilter: (filter: Filter) => void; 
    canRemove: (filter: Filter) => boolean; 
    onRemove?: (index: number) => void;
    findFilterIndex: (filter: Filter) => number; 
    formatTest: (test: TestType) => string; 
    formatValue: (value: Filter) => string | null; 
    colorIndex: (index: number) => string;
    scaffoldFilter?: {field: string, type: string};
    onScaffoldRemove?: () => void;
    onSortToggle?: (sort: [Variable, boolean]) => void;
    onSortRemove?: (sort: [Variable, boolean]) => void;
}

function DefaultFilterBarView({
    isOpen,
    queryName,
    getFilterLength,
    sorts,
    setIsOpen,
    onClearAll,
    filterDSInfo,
    canModify,
    modifyFilter,
    canRemove,
    onRemove,
    findFilterIndex,
    formatTest,
    formatValue,
    colorIndex,
    scaffoldFilter,
    onScaffoldRemove,
    onSortToggle,
    onSortRemove,
}: FilterBarViewProps) {
    
    return (
        <div className={`w-full px-4 py-2 bg-white ${isOpen && 'shadow-md mb-1'}`}>
            {queryName && <div className="text-xl">{queryName}</div>}
            <div className="w-full flex justify-between mt-3">
                <div className="flex space-x-8">
                    <div data-testid="filter-bar" className="flex space-x-3 items-center hover:cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                        <i className="icon-down"/>
                        <div><strong>Filters:</strong> {getFilterLength()}</div>
                        {sorts.length > 0 && <div><strong>Sorts:</strong> {sorts.length}</div>}
                    </div>
                </div>
                {onClearAll && <div onClick={onClearAll} className="hover:cursor-pointer flex space-x-2 items-center">
                    <span>Clear all</span>
                    <i className="icon-times"/>
                </div>}
            </div>

            {isOpen && <div className="w-full mt-5 flex space-x-5">
                <div>
                Filters
                    {filterDSInfo.map((fDS, i) => <div key={i} className="flex space-x-3 items-center py-1">
                        <div>{fDS.label}</div>
                        <div className="grid grid-cols-3 gap-1">
                            {fDS.filters.map((filter, j) => {
                                return <Badge color={colorIndex(i)} pill key={j}
                                    onClick={canModify(filter) ? () => modifyFilter(filter) : undefined}
                                    onClose={canRemove(filter) ? () => onRemove!(findFilterIndex(filter)) : undefined}>
                                    <strong>{filter.variable.label}</strong> {formatTest(filter.test)} {formatValue(filter)}
                                </Badge>;
                            })}
                        </div>
                    </div>)}
                    {scaffoldFilter && <div className="flex space-x-3 items-center">
                        <div>Fact Date</div>
                        <Badge color={colorIndex(filterDSInfo.length)} pill onClose={onScaffoldRemove}>
                            {scaffoldFilter.field} <span className="capitalize">{scaffoldFilter.type}</span>
                        </Badge>
                    </div>}
                </div>
                {sorts.length > 0 && <div>
                Sorts
                    <div className="grid grid-cols-3 gap-1">
                        {sorts.map((sort, i) => <Badge color="blue" pill key={i}
                            onClick={() => onSortToggle && onSortToggle(sort)}
                            onClose={() => onSortRemove && onSortRemove(sort)}>
                            {i + 1}: {sort[0].label} <SortIcon variable={sort[0]} sorts={sorts} />
                        </Badge>)}
                    </div>
                </div>}
            </div>}
        </div>
    );
};

function NewFilterBarView({
    isOpen,
    getFilterLength,
    sorts,
    setIsOpen,
    onClearAll,
    filterDSInfo,
    canModify,
    modifyFilter,
    canRemove,
    onRemove,
    findFilterIndex,
    formatTest,
    formatValue,
    scaffoldFilter,
    onScaffoldRemove,
    onSortToggle,
    onSortRemove,
}: FilterBarViewProps) {
    
    return (
        <div className={`w-full`} >
            <ExpandableTile id="expandable-tile-1" tileCollapsedIconText="Expand" tileExpandedIconText="Collapse" expanded={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <TileAboveTheFoldContent>
                    <div className="w-full flex mr-12 p-4" onClick={() => setIsOpen(!isOpen)}>
                        <div className="">
                            <div data-testid="filter-bar" className="items-center hover:cursor-pointer">
                                <strong>{getFilterLength()} Filters</strong>{sorts.length > 0 && <strong>, {sorts.length} Sorts</strong>} applied to table
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        {onClearAll && <div onClick={onClearAll} className="hover:cursor-pointer inline-flex space-x-2 items-center">
                            <span>Clear all</span>
                            <i className="icon-times"/>
                        </div>}
                    </div>
                </TileAboveTheFoldContent>
                <TileBelowTheFoldContent>
                    <div className="w-full mt-5 flex space-x-5 font-bold">
                        <div className='w-1/2'>
                Filters
                            {filterDSInfo.map((fDS, i) => <div key={i} className="flex flex-col space-x-3 items-start py-1">
                                <div>{fDS.label}</div>
                                <div className="flex flex-wrap gap-1">
                                    {fDS.filters.map((filter, j) => {
                                        let TagType = canRemove(filter) ? DismissibleTag : Tag;
                                        return <TagType type='warm-gray' key={j}
                                            onClick={canModify(filter) ? () => modifyFilter(filter) : undefined}
                                            onClose={canRemove(filter) ? () => onRemove!(findFilterIndex(filter)) : undefined}
                                        >
                                            <span className='pr-1'>{filter.variable.label}</span><strong>{formatTest(filter.test)} {formatValue(filter)}</strong>
                                        </TagType>;
                                    })}
                                </div>
                            </div>)}
                            {scaffoldFilter && <div className="flex space-x-3 items-center">
                                <div>Fact Date</div>
                                <DismissibleTag type='warm-gray' onClose={onScaffoldRemove}>
                                    {scaffoldFilter.field} <span className="capitalize">{scaffoldFilter.type}</span>
                                </DismissibleTag>
                            </div>}
                        </div>
                        {sorts.length > 0 && <div>
                Sorts
                            <div className="flex flex-wrap gap-1">
                                {sorts.map((sort, i) => <DismissibleTag type="blue" key={i}
                                    onClick={() => onSortToggle && onSortToggle(sort)}
                                    onClose={() => onSortRemove && onSortRemove(sort)}>
                                    <span className='pr-1'>{i + 1}:</span><strong>{sort[0].label}</strong>
                                </DismissibleTag>)}
                            </div>
                        </div>}
                    </div>
                </TileBelowTheFoldContent>
            </ExpandableTile>
        </div>
    );
};