import {useContext} from 'react';
import {HeaderContext} from '../context';
import PreviouslyViewed from './previously-viewed';
import MyFavorites from './my-favorites';
import MySavedData from './my-saved-data';
import {Email} from '@carbon/icons-react';
import Spotlight from './spotlight';


const contentSections = [
    <PreviouslyViewed key="0"/>,
    <MyFavorites key="1"/>,
    <MySavedData key="2"/>,
    <Spotlight key="3"/>
];

export default function MyContent() {
    const headerStore = useContext(HeaderContext);

    return <div className="bg-milliman-slate-500 h-full w-full text-gray-300 px-8 py-12 overflow-y-auto">
        <div className="w-full flex justify-between items-center">
            <h2>My Content</h2>
            <span className="text-medinsight-blue-300 hover:cursor-pointer mt-4" onClick={() => {headerStore.showMyContent = false; headerStore.setShowMyContentPreference(false);}}>Hide Panel</span>
        </div>
        {contentSections.map((section, index) => <div key={index}>
            {section}
            <hr className="border-gray-400" />
        </div>)}
        <div>
            <h4>Contact Us</h4>
            <div className="flex">
                <div className="border border-white p-3 hover:cursor-pointer mt-5">
                    <a href="mailto:sales.torchinsight@milliman.com" className="text-white hover:no-underline">
                        Send a Message <Email className="inline ml-1" />
                    </a>
                </div>
                <div className="flex-grow"></div>
            </div>
        </div>
    </div>;
}