import {useEffect} from 'react';
import activityTracker from '../lib/activity-tracker';


export default function ActivityTrackerIframe() {

    useEffect(() => {
        const listener_type = 'message';
        const trackActivity = (msg: any) => {
            const domain = process.env.REACT_APP_TI_DOMAIN;
            if (msg.origin.endsWith(domain)) {
                const msgData = msg.data;
                activityTracker.setRecentActivity(msgData.toolName, msgData.pageName, undefined, msgData.pageUrl);
            }
        };

        window.addEventListener(listener_type, trackActivity);

        return () => window.removeEventListener(listener_type, trackActivity);
    }, []);

    return <div></div>;
}