import {MenuStore} from './context';
import {Dispatch, SetStateAction} from 'react';


export default function generateKeyHandler(store: MenuStore, focusIndex: number, size: number,
    setFocus: (i: number) => void, handleClick: (i: number) => void,
    setSearch: Dispatch<SetStateAction<string>>,
    timeout: any, setTimeoutVar: (to: any) => void) {

    return function handleKey(e: any) {
        if (!store.visible)
            return;
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            setFocus(Math.max(0, Math.min(size - 1, focusIndex - 1)));
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setFocus(Math.max(0, Math.min(size - 1, focusIndex + 1)));
        } else if (e.key === 'Enter') {
            if (focusIndex >= 0)
                handleClick(focusIndex);
        } else if (e.key === 'Escape') {
            e.stopPropagation();
            store.hide();
        } else if (e.key === 'Tab') {
            // Do this instead of onblur so the click events in the menu are handled properly
            store.hide();
        }

        // Handle typing to search for items
        const code = e.key.toLowerCase().charCodeAt(0);
        const isLetter = code >= 97 && code <= 122;
        const isNumber = code >= 48 && code <= 57;
        if (e.key.length === 1 && (isLetter || isNumber)) {
            if (timeout)
                clearTimeout(timeout);
            setTimeoutVar(setTimeout(() => {
                setSearch('');
                setTimeoutVar(null);
            }, 2000));

            setSearch(prev => prev + e.key.toLowerCase());
        }
    };
}