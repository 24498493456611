import Variable, {ValueType} from '../../lib/metadata';
import fetchData from '../../lib/data';
import {dataRows} from '../../lib/data-types';
import {QuantumGraph} from 'quantum-graph';
import GraphHandler from '../../lib/graph-tools/graph-handler';

const maxValues = 200;


export type VariableStats = {
    min?: number,
    max?: number,
    count?: number,
    values?: Array<string>,
}

export async function countField(gh: GraphHandler<QuantumGraph>, variable: Variable, signal: AbortSignal): Promise<number> {
    const graph = gh.buildCountDistinct(variable);
    const data = await fetchData(graph, signal);
    return data[0]['count'] as number;
}

export async function getFieldValues(gh: GraphHandler<QuantumGraph>, variable: Variable, signal: AbortSignal): Promise<Array<string>> {
    const graph = gh.buildDistinct(variable);
    const data = await fetchData(graph, signal);
    return data.map(row => {
        let v = variable.getValue(row) as string;
        if (variable.type === 'bit' && v !== null)
            return v.toString();
        return v;
    }).filter(_ => _).sort();
}

export default async function loadStats(gh: GraphHandler<QuantumGraph>, variable: Variable, signal: AbortSignal): Promise<VariableStats> {
    const stats: VariableStats = {};

    if (variable.getValueType() === ValueType.Text) {
        const count = await countField(gh, variable, signal);
        stats.count = count;
        if (count && count <= maxValues) {
            const values = Array.from(new Set(await getFieldValues(gh, variable, signal)));
            stats.values = values.filter(_ => _).sort();
        }
    }

    return stats;
}

export function calculateStats(data: dataRows, variable: Variable) {
    const stats: VariableStats = {};
    stats.count = data.length;
    if (variable.getValueType() === ValueType.Text) {
        const values = Array.from(new Set(data.map(row => String(variable.getValue(row)))));
        stats.values = values.filter(_ => _).sort();
    }

    return stats;
}