import Library, {Section} from '../../../lib/library';
import titleCase from '../../../lib/title-case';
import Variable from '../../../lib/metadata';
import LibraryIndex from '../../../lib/library/library-index';

export class CommonCoreSection extends Section {

    dataset: string;

    constructor(dataset: string) {
        super();
        this.dataset = dataset;
        this.name = titleCase(dataset.split('-')[3]);
    }

    protected getDatasets(): Array<string> {
        return [this.dataset];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async loadName(signal: AbortSignal) {}

    async loadMetadata(signal: AbortSignal): Promise<void> {
        await super.loadMetadata(signal);
        const variables = this.index.getVariables().filter(v => v.getTag('Role'));
        this.index = new LibraryIndex(variables);
    }
}


export default class CommonCoreLibrary extends Library {

    public readonly dataset: string;

    constructor(regionType: string) {
        super();
        this.dataset = `common-core-benchmarks-metrics-${regionType}`;
        this.sections = [new CommonCoreSection(this.dataset)];
    }

    getDefaultSelection(): Array<Variable> {
        const init = ['utilspkpy', 'allowed_util', 'allowedpmpm', 'utils'];
        const vars = this.sections[0].index.getVariables();
        return init.map(name => vars.find(v => v.name === name)) as Array<Variable>;
    }

    translateSelection(old: Array<Variable>): Array<Variable> {
        let selection = old.length ? old : this.getDefaultSelection();
        const vars = this.sections[0].index.getVariables();
        selection = selection
            .map(v => vars.find(variable => variable.name === v.name)!)
            .filter(_ => _);
        return selection;
    }
}