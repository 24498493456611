import Modal from '../modal';
import React, {ChangeEvent, useContext, useEffect, useMemo, useState} from 'react';
import Filter from '../../lib/filter';
import Button from '../button';
import Variable, {ValueType} from '../../lib/metadata';
import Select from '../select';
import {QuantumGraph, TestType} from 'quantum-graph';
import UploadLink from './upload-link';
import Badge from '../badge';
import loadStats, {calculateStats, VariableStats} from './stats';
import FilterAutocomplete from './filter-autocomplete';
import Spinner from '../spinner';
import {dataRows} from '../../lib/data-types';
import Autocomplete from '../autocomplete';
import GraphHandler from '../../lib/graph-tools/graph-handler';
import checkCode from './analytics-check';
import {HeaderContext} from '../header/context';
import {TextInput} from '@carbon/react';
import './variable-filter.scss';

export type TestDataType = {
    value: string,
    label: string,
};

export const numberTests = [
    {value: TestType.Lt, label: '<'},
    {value: TestType.LtEq, label: '<='},
    {value: TestType.Eq, label: 'Equals'},
    {value: TestType.NEq, label: 'Not Equals'},
    {value: TestType.GtEq, label: '>='},
    {value: TestType.Gt, label: '>'},
    {value: TestType.In, label: 'Is One Of'},
    {value: TestType.NotIn, label: 'Is Not One Of'},
    {value: TestType.Null, label: 'Not Has Data'},
    {value: TestType.NotNull, label: 'Has Data'},
];

export const textTests = [
    {value: TestType.Eq, label: 'Equals'},
    {value: TestType.NEq, label: 'Not Equals'},
    {value: TestType.Like, label: 'Text Includes'},
    {value: TestType.In, label: 'Is One Of'},
    {value: TestType.NotIn, label: 'Is Not One Of'},
    {value: TestType.Null, label: 'Not Has Data'},
    {value: TestType.NotNull, label: 'Has Data'},
];

export const uploadTests: Array<string> = [
    TestType.Eq,
    TestType.NEq,
    TestType.In,
    TestType.NotIn,
];

type Props = {
    config: {variable: Variable, filter: Filter | null},
    gh?: GraphHandler<QuantumGraph>,
    data?: dataRows, // For offline
    onApply: (f: Filter) => void,
    onCancel: () => void,
};

export default function VariableFilter(props: Props) {
    const {gh, config, data} = props;
    const variable = config.variable;
    const existingFilter = config.filter;
    const [test, setTest] = useState<string>(existingFilter?.test || TestType.Eq);
    const [value, setValue] = useState<string>(((existingFilter?.test !== TestType.In && existingFilter?.test !== TestType.NotIn) && existingFilter?.value as string) || '');
    const [values, setValues] = useState<Array<string>>((existingFilter && (existingFilter.value instanceof Array ? existingFilter.value as Array<string> : [existingFilter?.value as string])) || []);
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState<VariableStats>({});
    const {useNewDesign} = useContext(HeaderContext);


    const isNumber = variable.getValueType() === ValueType.Number || variable.getValueType() === ValueType.DateTime;
    const tests = isNumber ? numberTests : textTests;
    const isIn = test === TestType.In || test === TestType.NotIn;
    const isNull = test === TestType.Null || test === TestType.NotNull;
    const isLike = test === TestType.Like;
    const inputValid = useMemo(() => {
        if (variable.getValueType() === ValueType.DateTime && test !== TestType.Null && test !== TestType.NotNull) {
            const dateRegEx = /^\d{4}-\d{2}-\d{2}$/;
            return dateRegEx.test(value);
        }

        return isNumber ? !isNaN(Number(value)) : true;
    }, [value, test]);
    const isValid = isNull ? true : (isIn ? !!values.length : !!value && inputValid);
    const canUpload = uploadTests.includes(test);

    const showAuto = !isNull && !loading && !stats.values && variable.getValueType() === ValueType.Text && !isLike;
    const showInput = isLike || (!isNull && !stats.values && !showAuto);
    const showSelect = !isLike && !isNull && !!stats.values && !showAuto;

    useEffect(() => {
        if (data) { // If data is provided, then do an offline calculation of the stats
            const stats = calculateStats(data, variable);
            setStats(stats);
            setLoading(false);
            return;
        }
        if (!gh)
            return;

        if (existingFilter)
            gh.removeFilter(existingFilter);
        const controller = new AbortController();
        loadStats(gh, variable, controller.signal).then(stats => {
            setStats(stats);
            setLoading(false);
        });
        return () => controller.abort();
    }, [data, gh, variable, existingFilter]);

    function add() {
        values.push(value);
        setValues(values);
        setValue('');
    }

    function addMultiple(items: Array<string>) {
        if (isNumber)
            items = items.filter(v => !isNaN(Number(v)));
        setValues(v => v.concat(items));
        if (test === TestType.Eq)
            setTest(TestType.In);
        else if (test === TestType.NEq)
            setTest(TestType.NotIn);
    }

    function remove(i: number) {
        const newValues = values.slice();
        newValues.splice(i, 1);
        setValues(newValues);
    }

    function applyFilter() {
        let filterValue: string | boolean = checkPercentage(value);
        if (variable.type === 'bit')
            filterValue = filterValue === 'true';
        const filter: Filter = {
            variable,
            test: test as TestType,
            value: isIn ? values : filterValue,
        };
        checkCode(value);
        if (test === TestType.Like)
            filter.value = '%' + value + '%';
        props.onApply(filter);
    }

    function checkPercentage(value: string): string {
        let rv = value;
        if (variable.getTag('Format')?.toLowerCase() === 'percent') {
            let valuePercent = Number(value);
            if (valuePercent >= 1)
                rv = (Number(valuePercent) / 100).toString();
        }

        return rv;
    }

    function getDataOptions(input: string) {
        let options: Array<string> = [];
        if (data) {
            const lower = input.toLowerCase();
            options = data.map(row => String(variable.getValue(row)))
                .filter(value => value.toLowerCase().includes(lower));
        }
        return Promise.resolve(options);
    }

    let inputProps = {id: 'filter-input', labelText: '', type: 'text', className: inputValid ? '' : 'invalid', value, onChange: (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value), disabled: loading, placeholder: variable.getValueType() === ValueType.DateTime ? 'YYYY-MM-DD' : ''};

    return <Modal title="Filter" onClose={props.onCancel} className='!p-0 flex flex-col' headerClassName='scrollable-modal-header' {...(useNewDesign ? {primaryButtonText: 'Save', secondaryButtonText: 'Cancel', primaryButtonOnClick: applyFilter, secondaryButtonOnClick: props.onCancel} : {})} >
        <div className='p-4 pb-3'>
            <div>
                <strong>{variable.label}</strong>
            </div>
            <div className="mt-2">
                <Select<TestDataType> data={tests} value={test} onChange={setTest}>
                    {d => d.value}
                    {d => d.label}
                </Select>
            </div>
            <div className="mt-2">
                {showAuto && gh && <FilterAutocomplete value={value} onChange={setValue} variable={variable} gh={gh} />}
                {showAuto && data && <Autocomplete value={value} onChange={setValue} getOptions={getDataOptions} />}
                {showInput && (useNewDesign ? <TextInput {...inputProps} /> : <input {...inputProps} />)}
                {showSelect && <Select data={stats.values as Array<string>} value={value} onChange={e => setValue(e)}>
                    {_ => _}
                    {_ => _}
                </Select>}
                {loading && <div className="inline-block ml-3">
                    <Spinner />
                </div>}
                {isIn && <Button color="blue" onClick={add} className="h-10 ml-2" disabled={!value || !inputValid}><i className="icon-plus" /> Add</Button>}
                {!inputValid && <div className="text-sm text-orange-500">Invalid value</div>}
            </div>
            {canUpload && <div className="mt-2">
                <UploadLink onChange={addMultiple} />
            </div>}
            {isIn && <div>
                <hr/>
                {!values.length && <em className="text-gray-500">No values added</em>}
                {values.map((v, i) => <Badge color="light" key={i} pill onClose={() => remove(i)}>{v}</Badge>)}
            </div>}
        </div>     
        {!useNewDesign && <div className="mb-no-absolute">
            <Button color="primary" onClick={applyFilter} disabled={!isValid}>Save</Button>
            <Button color="white" onClick={props.onCancel}>Cancel</Button>
        </div>}     
    </Modal>;
}
