import React from 'react';
import AdminCrudTable from '../crud-table';
import {organizations} from '../../../lib/api';
import {ActionObj} from '../crud-table/types';
import {Route, Routes} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import AllowedDomains from './allowed-domains';


export default function OrganizationAdmin() {
    const navigate = useNavigate();

    const fields = [
        {name: 'id', label: 'Id', show: true, type: 'numeric'},
        {name: 'name', label: 'Name', show: true, type: 'text'},
        {name: 'contractStart', label: 'Contract Start', show: true, type: 'date'},
        {name: 'contractEnd', label: 'Contract End', show: true, type: 'date'},
        {name: 'sizeLimit', label: 'Size Limit', show: true, type: 'numeric'},
        {name: 'lookupLimit', label: 'Lookup Limit', show: false, type: 'numeric'},
        {name: 'searchLimit', label: 'Search Limit', show: false, type: 'numeric'},
        {name: 'allowModerators', label: 'Allow Moderators', show: true, type: 'boolean'},
        {name: 'isInactive', label: 'Is Inactive', show: true, type: 'boolean'},
        {name: 'demoAccount', label: 'Demo Account', show: true, type: 'boolean'},
        {name: 'payingFlag', label: 'Paying Organization', show: true, type: 'boolean'},
        {name: 'adminUsers', label: 'Admin Users', show: false, type: 'multi-user'},
        {name: 'accessRoles', label: 'Access Roles', show: false, type: 'accessRoles'},
        {name: 'modules', label: 'Modules', show: false, type: 'modules'}
    ];

    const actions: ActionObj = {
        Membership: async org => {
            navigate(`/org/${org.id}/membership`);
        },
        Domains: async org => {
            navigate(`/site-admin/organizations/${org.id}/allowed-domains`);
        },
        Analytics: async org => {
            window.location.href = `//${process.env.REACT_APP_TI_DOMAIN}/org/${org.id}/analytics`;
        }
    };

    return <AdminCrudTable api={organizations} title="Organization" urlPath="/site-admin/organizations" fields={fields} actions={actions} notice={<p>All changes must have a support ticket created in the <a target="_blank" rel="noreferrer" href="https://torchinsight.atlassian.net/jira/software/projects/SOPS/boards/13">Service Ops</a> board.</p>}>
        <Routes>
            {/*<Route path=":id/membership" element={<OrgMembership />}/>*/}
            {/*<Route path=":id/bulk-invite" element={<BulkInviteTool />}/>*/}
            <Route path=":id/allowed-domains" element={<AllowedDomains />}/>
        </Routes>
    </AdminCrudTable>;
}