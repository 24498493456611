import React from 'react';

type Props = {
    startColor:string,
    endColor:string,
    numIntervals: number,
}

const GradientBar = (props:Props) => {
    const {startColor, endColor, numIntervals} = props;

    const gradientStyle: React.CSSProperties = {
        height: '30px',
        width: '100%',
        background: `linear-gradient(to right, ${startColor}, ${endColor})`,
        position: 'relative',
    };

    const intervalIndicators = Array.from({length: numIntervals - 1});

    return (
        <div style={gradientStyle}>
            {
                intervalIndicators.map((_, i) => (
                    <div
                        key={i}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '1px',
                            backgroundColor: 'black',
                            left: `${((i + 1) / numIntervals) * 100}%`,
                        }}
                    />
                ))
            }
        </div>
    );
};

export default GradientBar;