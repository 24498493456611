import {QueryFilter, QueryObj} from '../../../../../lib/data-types';
import {deserialize, GraphVersions, TestType} from 'quantum-graph';

const createHPTQuery = function (view: string, reportedBy: string, filters?: Array<QueryFilter>) {
    let obj: QueryObj;
    
    if (view === 'relativity') {
        obj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'hospital',
                            dataset: 'pt-facility',
                            columns: [
                                'cbsa_name',
                                'state',
                                'facility_name',
                                'beds',
                                'milliman_facility_type',
                                'provider_number',
                                'torch_health_system_name'
                            ]
                        },
                        {
                            id: 'hpt-fact',
                            dataset: reportedBy === 'payer' ? 'pt-servicelinecffactrecent-payer' : 'pt-servicelinecffactrecent-hospital',
                            columns: [
                                'plan_name',
                                'payer',
                                'lob',
                                'product',
                                'hpt_hcg_2_service_description',
                                'percent_medicare',
                                'pct_allowed_included',
                                'pct_rvus_included'
                            ]
                        }
                    ]
                },
                joins: []
            },
            sort: [
                {
                    column: {
                        nodeId: 'hospital',
                        column: 'facility_name'
                    },
                    asc: true
                }
            ],
            filters: [
                {
                    type: 'basic',
                    column: {
                        nodeId: 'hpt-fact',
                        column: 'percent_medicare'
                    },
                    test: TestType.NotNull,
                    value: '1'
                }
            ]
        };
    } else {
        obj = {
            version: GraphVersions.V2,
            baseQueryGraph: {
                baseQuery: {
                    queryType: 'dim',
                    tableNodes: [
                        {
                            id: 'hospital',
                            dataset: 'pt-facility',
                            columns: [
                                'cbsa_name',
                                'state',
                                'facility_name',
                                'torch_health_system_name',
                                'beds',
                                'milliman_facility_type',
                                'provider_number'
                            ]
                        },
                        {
                            id: 'hpt-fact',
                            dataset: reportedBy === 'payer' ? 'pt-codedetailfactpayer' : 'pt-codedetailfacthospital',
                            columns: [
                                'payer',
                                'lob',
                                'product',
                                'planname',
                                'amount',
                                'ratemethodology',
                                'code',
                                'codetype',
                                'hcg',
                                'u_rate',
                                'rvufinalrvus',
                                'includeflag',
                                'percentrate',
                                'exclusionreason'
                            ]
                        }
                    ]
                },
            },
            filters: [],
        };
    }

    obj.filters!.push({
        type: 'basic',
        column: {
            nodeId: 'hpt-fact',
            column: 'payer'
        },
        test: 'is not null',
        value: 1
    });

    if (filters)
        obj.filters = [...obj.filters!, ...filters];

    return deserialize(obj);
};

function generateStandardFacilityFilters(dataView: string) {
    return [
        {
            type: 'basic',
            column: {
                nodeId: 'hospital',
                column: 'Category'
            },
            test: TestType.Eq,
            value: 'Hospital'
        },
        {
            type: 'basic',
            column: {
                nodeId: 'hospital',
                column: 'milliman_facility_type'
            },
            test: TestType.NotNull,
            value: 1
        },
        {
            type: 'basic',
            column: {
                nodeId: 'hospital',
                column: 'milliman_facility_type'
            },
            test: TestType.NEq,
            value: 'UNK'
        },
        {
            type: 'basic',
            column: {
                nodeId: 'hospital',
                column: dataView === 'payer' ? 'payerdatalastupdated' : 'hospitaldatalastupdated',
            },
            test: TestType.Gt,
            value: '1900-01-02',
        }
    ];
}

export {
    createHPTQuery,
    generateStandardFacilityFilters
};