import {Aggregation, GraphVersions, TestType} from 'quantum-graph';
import {QueryObj} from '../../../../lib/data-types';
import {LATEST_YEAR} from './datasets';

export function buildMSAPivotQuery(dataset: string, pivotMeasures: Array<string>, pivotColumn: string, metricType: string, fields: Array<string>): QueryObj {
    return {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: 'dim',
                tableNodes: [
                    {
                        id: 'pom',
                        dataset,
                        columns: [
                            'msa_division',
                            'msa_division_name',
                            'msa_states',
                            pivotColumn,
                            ...fields,
                            'year_key',
                            'version'
                        ],
                    }
                ],
            },
        },
        sort: [
            {column: {nodeId: 'pom', column: 'msa_states'}, asc: true},
            {column: {nodeId: 'pom', column: 'msa_division_name'}, asc: true},
        ],
        filters: [
            {column: {nodeId: 'pom', column: 'metric_type'}, test: TestType.Eq, value: metricType},
            {column: {nodeId: 'pom', column: 'year_key'}, test: TestType.Eq, value: LATEST_YEAR},
            {column: {nodeId: 'pom', column: 'under_age_65_pop'}, test: TestType.NotNull, value: 0},
        ],
        transforms: [{
            type: 'pivot',
            idColumns: [{nodeId: 'pom', column: 'msa_division'}],
            pivotColumn: {nodeId: 'pom', column: pivotColumn},
            aggregations: pivotMeasures.map(column => [{nodeId: 'pom', column}, Aggregation.Average]),
        }],
    };
}

export function buildStatePivotQuery(dataset: string, pivotMeasures: Array<string>, pivotColumn: string, metricType: string, fields: Array<string>): QueryObj {
    return {
        version: GraphVersions.V2,
        baseQueryGraph: {
            baseQuery: {
                queryType: 'dim',
                tableNodes: [
                    {
                        id: 'state',
                        dataset: 'state',
                        columns: ['state_fips', 'state']
                    },
                    {
                        id: 'pom',
                        dataset,
                        columns: [
                            pivotColumn,
                            ...fields,
                            'year_key',
                            'version'
                        ],
                    }
                ],
            },
        },
        sort: [{column: {nodeId: 'state', column: 'state'}, asc: true}],
        filters: [
            {column: {nodeId: 'pom', column: 'metric_type'}, test: TestType.Eq, value: metricType},
            {column: {nodeId: 'pom', column: 'year_key'}, test: TestType.Eq, value: LATEST_YEAR},
            {column: {nodeId: 'pom', column: 'under_age_65_pop'}, test: TestType.NotNull, value: 0},
        ],
        transforms: [{
            type: 'pivot',
            idColumns: [{nodeId: 'pom', column: 'state'}],
            pivotColumn: {nodeId: 'pom', column: pivotColumn},
            aggregations: pivotMeasures.map(column => [{nodeId: 'pom', column}, Aggregation.Average]),
        }],
    };
}
