import {makeAutoObservable} from 'mobx';
import Filter from '../../../lib/filter';
import Variable from '../../../lib/metadata';
import {DataConfig} from '../../types';
import {dataRows, TransformCBs} from '../../../lib/data-types';
import {downloadGraph} from '../../../lib/download';
import {DataDefRepo} from '../../../lib/user-data-defs';
import {LinkFormatter} from '../../../lib/formatter';
import Library from '../../../lib/library';
import createLibrary from '../../../lib/library/create';
import {deserialize, QuantumGraph, TestType} from 'quantum-graph';
import createGraphHandler from '../../../lib/graph-tools/graph-handler/create';
import {updateSorts} from '../../../lib/sort';
import {V1QuantumGraph} from 'quantum-graph/out/v1';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import {getDefaultDataDefRepo} from '../../../lib/data-defs';
import {QueryType} from 'quantum-graph/out/v2/base-query/base-query';
import IQueryData from './query-data-interface';

let store: QueryDataStore | null = null;

export function getQueryStoreGraph() {
    return store?.getGH(true, true).build();
}

export default class QueryDataStore implements IQueryData {

    dataConfig: DataConfig;
    filters: Array<Filter>;
    _scaffoldType: string | undefined;

    _scaffoldField: string | undefined;
    graphFilters: Array<Filter>;
    private _metadataCounter = 0;
    private _dataCounter = 0;
    private _countCounter = 0;
    private _sorts: Array<[Variable, boolean]>;
    private _page: number;
    private _pageSize: number;

    private _loading = true;
    private _mdLoading = true;
    private _countLoading = true;
    private _data: dataRows = [];
    private _metadata: Array<Variable> = [];
    private _count: number | undefined;
    private _pristine = true;
    private _unAuthorizedVariables: Array<string> = [];

    // For ordering and coloring
    colorColumns: boolean;

    // for undo
    private prevQuery: QuantumGraph | null;
    private prevFilters: Array<Filter>;
    private prevMetadata: Array<Variable>;
    private lastAction: string;
    readonly dataDefRepo: DataDefRepo;

    // data library
    library: Library;


    static empty() {
        const datasetRepo = {datasets: [], datasetByName: {}};
        return new QueryDataStore({queries: []}, datasetRepo, [], 0);
    }

    constructor(dataConfig: DataConfig, datasetRepo: DataDefRepo, sorts: Array<[Variable, boolean]>, page: number, pageSize = 20) {
        this.dataConfig = dataConfig;
        this.filters = [];
        this._scaffoldType = dataConfig.queries.length ? createGraphHandler(dataConfig.queries[0], getDefaultDataDefRepo()).getScaffoldType() : undefined;
        this.graphFilters = [];
        this._sorts = sorts;
        this._page = page;
        this._pageSize = pageSize;
        this.colorColumns = false;
        this.prevQuery = null;
        this.prevFilters = [];
        this.prevMetadata = [];
        this.lastAction = '';
        this.dataDefRepo = datasetRepo;
        this.library = createLibrary(dataConfig.queries[0], datasetRepo, this.dataConfig.options?.connectingFacts);
        makeAutoObservable(this);
        store = this;
    }

    initSort() {
        this._sorts = this.getGH().getSort(this.metadata);
    }

    async initMetadata(metadata: Array<Variable>, transforms?: TransformCBs) {
        this.colorColumns = !!this.dataConfig.options && this.dataConfig.options.colorColumns;
        this.updateLinks(metadata);
        this._metadata = metadata;
        if (transforms) {
            for (const transform of transforms) {
                const transformed = await transform(this._metadata, this._data);
                this._metadata = transformed.metadata;
            }
        }
    }

    updateLinks(metadata: Array<Variable>) {
        const linkNewTab = !!this.dataConfig.options && this.dataConfig.options.linkNewTab;
        if (linkNewTab) {
            metadata.forEach(variable => {
                if (variable.formatter instanceof LinkFormatter)
                    variable.formatter.newTab = true;
            });
        }
    }

    updateConfig(dataConfig: DataConfig) {
        // For undo
        this.prevQuery = this.dataConfig.queries[0];
        this.lastAction = 'updateConfig';

        let roughEquals = true;

        if (this.dataConfig.queries[0].getVersion() !== dataConfig.queries[0].getVersion()) {
            roughEquals = false;
        } else {
            roughEquals = this.getGH().roughEquals(dataConfig.queries[0]);
        }

        this._pristine = this._pristine && roughEquals;
        this.dataConfig = dataConfig;
        this.library = createLibrary(dataConfig.queries[0], this.dataDefRepo, this.dataConfig.options?.connectingFacts);
        this.updateGraphFilters();
        if (roughEquals)
            this.updateData();
        else {
            this.filters = [];
            this.sorts = [];
            this.page = 0;
            this.updateMetadata();
        }
        this.updateCount();
    }

    updateGraphFilters() {
        this.graphFilters = this.getGH().getFilters(this.metadata, this.library);
    }

    getFilters() {
        return this.filters.concat(this.graphFilters);
    }

    getGraph() {
        return this.dataConfig.queries[0];
    }

    getGraphs() {
        return this.dataConfig.queries;
    }

    getGH(filter = false, sort = false, paginate = false) {
        const gh = createGraphHandler(this.getGraph(), this.dataDefRepo);
        if (filter)
            gh.applyFilters(this.filters);
        if (sort && this.sorts.length)
            gh.applySort(this._sorts);
        if (paginate)
            gh.applyPagination(this.page, this.pageSize);
        else
            gh.removePagination();
        if (this._scaffoldField)
            gh.applyScaffoldField(this._scaffoldField);
        gh.applyScaffoldType(this._scaffoldType);
        return gh;
    }

    getLocked(): Array<Variable> {
        const lockedVars = new Set<Variable>();
        this.sorts.forEach(s => lockedVars.add(s[0]));
        this.filters.forEach(f => lockedVars.add(f.variable));
        return Array.from(lockedVars);
    }

    getDatasetNames(): Array<string> {
        return Array.from<string>(new Set(this.metadata.map(v => v.dataset!).filter(v => !!v)));
    }

    get sorts() {
        return this._sorts;
    }

    set sorts(sorts) {
        this._sorts = sorts;
    }

    applySort(value: Variable, removeSort = false) {
        const numSorts = this.getGraph() instanceof V1QuantumGraph ? 1 : Infinity; // Only allow sorting by one column for v1 graphs
        this._sorts = updateSorts(this._sorts, value, numSorts, removeSort);
        this._pristine = false;
    }

    removeSort(variable: Variable) {
        this.sorts = this.sorts.filter(s => s[0] !== variable);
    }

    get page(): number {
        return this._page;
    }

    set page(value: number) {
        this._page = value;
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    get mdLoading(): boolean {
        return this._mdLoading;
    }

    set mdLoading(value: boolean) {
        this._mdLoading = value;
    }

    get countLoading(): boolean {
        return this._countLoading;
    }

    set countLoading(value: boolean) {
        this._countLoading = value;
    }

    get data(): dataRows {
        return this._data;
    }

    set data(value: dataRows) {
        this._data = value;
    }

    get metadata(): Array<Variable> {
        return this._metadata;
    }

    set metadata(value: Array<Variable>) {
        this._metadata = value;
    }

    get count(): number | undefined {
        return this._count;
    }

    set count(value: number | undefined) {
        this._count = value;
    }

    get metadataCounter(): number {
        return this._metadataCounter;
    }

    get dataCounter(): number {
        return this._dataCounter;
    }

    get countCounter(): number {
        return this._countCounter;
    }

    get pristine(): boolean {
        return this._pristine;
    }

    set pristine(value: boolean) {
        this._pristine = value;
    }

    get scaffoldType() {
        return this._scaffoldType;
    }

    set scaffoldType(type) {
        this._scaffoldType = type;
    }

    get scaffoldField() {
        return this._scaffoldField;
    }

    set scaffoldField(field) {
        this._scaffoldField = field;
    }

    updateMetadata() {
        this._metadataCounter++;
    }

    updateData() {
        this._dataCounter++;
    }

    updateCount() {
        this._countCounter++;
    }

    getGroup(_variable: Variable): number {
        if (!this.colorColumns)
            return -1;
        
        const datasets = this.metadata.reduce<Record<string, Variable[]>>((acc, v) => {
            if (!v.dataset)
                return acc;

            if (!acc[v.dataset])
                acc[v.dataset] = [v];
            else 
                acc[v.dataset].push(v);
            return acc;
        }, {});
        return Object.keys(datasets).findIndex(d => d === _variable.dataset!);
    }

    addFilter(filter: Filter) {
        // For undo
        this.prevFilters = this.filters.slice();
        this.lastAction = 'filters';

        this.filters = this.filters.concat([filter]);
        this._pristine = JSON.stringify(this.prevFilters) === JSON.stringify(this.filters);
        this.updateData();
        this.updateCount();
    }

    removeFilter(i: number) {
        // For undo
        this.prevFilters = this.filters.slice();
        this.prevQuery = deserialize(this.getGraph().serialize());
        this.lastAction = 'filters';

        if (i >= this.filters.length) {
            if (this.filters[i]?.variable.name === this._scaffoldField) {
                this._scaffoldField = undefined;
                this._scaffoldType = undefined;
            }

            // Graph filter
            i -= this.filters.length;
            const filters = this.graphFilters.slice();
            const filter = filters.splice(i, 1)[0];
            this.graphFilters = filters;

            // Remove the filter from the graph
            this.dataConfig.queries[0] = this.getGH().removeFilter(filter).build();
        } else {
            // Other filter
            const filters = this.filters.slice();
            filters.splice(i, 1);
            this.filters = filters;
        }

        this._pristine = JSON.stringify(this.prevFilters) === JSON.stringify(this.filters);
        this.updateData();
        this.updateCount();
    }

    clearAllNonGraphFilters() {
        this.prevFilters = this.filters.slice();
        this.prevQuery = deserialize(this.getGraph().serialize());
        this.lastAction = 'filters';
        this.filters = [];

        this.updateData();
        this.updateCount();
    }

    getYearFiltersValues(): [number, number] {
        let yearKeyFilters = this.filters.filter(f => f.variable.name === 'year_key');
        yearKeyFilters = [...yearKeyFilters, ...this.graphFilters.filter(f => f.variable.name === 'year_key')];
        const years = yearKeyFilters.map(f => f.value as number);
        return [Math.min(...years), Math.max(...years)];
    }

    removeAllYearGraphFilters() {
        const filters = this.graphFilters.slice();
        const yearKeyFilterIndex = filters.findIndex(f => f.variable.name === 'year_key');
        if (yearKeyFilterIndex >= 0) {
            this.removeFilter(yearKeyFilterIndex);
            this.removeAllYearGraphFilters();
        }
    }

    removeAllYearFilters() {
        this.filters = this.filters.filter(f => f.variable.name !== 'year_key');
        this.removeAllYearGraphFilters();
    }

    createYearKeyFilter(variable: Variable) {
        const queryType = (this.getGraph() as V2QuantumGraph).baseQueryGraph.baseQuery.queryType;
        const isScaffold = this.getGraph() instanceof V2QuantumGraph && (queryType === QueryType.DimScaffold || queryType === QueryType.AffiliationScaffold);
        const nodeId = this.getGraph() instanceof V2QuantumGraph ? (this.getGraph() as V2QuantumGraph).baseQueryGraph.baseQuery.tableNodes[0].id : '';
        let yearKeyFilter = {
            variable: new Variable(variable.name, variable.label, variable.description, variable.type, variable.tags, variable.formatter, `${nodeId}${isScaffold ? '_scaffold' : ''}`),
            test: TestType.Eq,
            value: ''
        };
        this.filters.push(yearKeyFilter);
        return this.filters[this.filters.length - 1];
    }

    applyMinMaxTimeFilter(variable: Variable, useLatest: boolean) {
        this.scaffoldField = variable.name;
        this.scaffoldType = useLatest ? 'max' : 'min';
        this.updateData();
    }
    updateYear(years: Array<number>, testType: TestType = TestType.Eq, variable: Variable) {
        // Only for V2 graphs right now
        if (this.getGraph() instanceof V2QuantumGraph) {
            this._scaffoldType = '';
            this.removeAllYearFilters();
            if (testType === TestType.Latest || testType === TestType.Earliest) {
                this._scaffoldType = testType === TestType.Latest ? 'max' : 'min';
            } else {
                const filter = this.createYearKeyFilter(variable);

                this.lastAction = 'filters';
                if (years.length === 1) {
                    filter!.test = testType;
                    filter!.value = years[0];
                } else {
                    filter!.test = TestType.GtEq;
                    filter!.value = Math.min(...years);
                    // Add second range filter
                    this.addFilter({
                        variable: filter!.variable,
                        test: TestType.LtEq,
                        value: Math.max(...years)
                    });
                }
            }
            this._pristine = false;
            this.updateData();
            this.updateCount();
        }
    }

    download() {
        const graph = this.dataConfig.options.maxDownloadRowLimit ? this.getGH(true, true, false).applyPagination(0, this.dataConfig.options.maxDownloadRowLimit).build() : this.getGH(true, true, false).build();
        downloadGraph(graph, this.count || 0, this.dataDefRepo);
    }

    get canUndo() {
        return !!this.lastAction;
    }

    undo() {
        if (!this.prevQuery || !this.lastAction)
            return;

        if (this.lastAction === 'filters')
            this.filters = this.prevFilters;
        this.dataConfig.queries = [this.prevQuery];
        if (this.prevMetadata.length) {
            this.metadata = this.prevMetadata;
        }

        this.updateGraphFilters();
        this.updateData();
        this.updateCount();

        this.prevQuery = null;
        this.prevFilters = [];
        this.prevMetadata = [];
        this.lastAction = '';
    }

    get pageSize() {
        return this._pageSize;
    }

    set pageSize(value) {
        this._pageSize = value;
    }

    get unAuthorizedVariables() {
        return this._unAuthorizedVariables;
    }

    set unAuthorizedVariables(value) {
        this._unAuthorizedVariables = value;
    }
}