import React, {ComponentType, useEffect, useMemo, useState} from 'react';
import {QuantumQuery, TransformCBs} from '../../lib/data-types';
import fetchData, {fetchMetadata} from '../../lib/data';
import handleError from '../../lib/error';
import {DataConfig} from '../types';
import ErrorMessage from '../../components/error-message';
import NoAccessMsg, {NoAccessErrMsg} from '../../components/no-access-msg';
import {V2QuantumGraph} from 'quantum-graph/out/v2';
import {QuantumGraph} from 'quantum-graph';
import Modal from '../../components/modal';
import ProgressBar from '../../components/progress-bar';
import Loading from '../../components/loading';

export type MultiQueryRenderChildProps = {
    queries: Array<QuantumQuery>
    dataConfig: DataConfig
    loadCB?: (promise: Promise<void>) => void
};

type Props = {
    dataConfig: DataConfig,
    children: ComponentType<MultiQueryRenderChildProps>,
    loadCB?: (promise: Promise<void>) => void
    transforms?: TransformCBs
};
const BATCH_SIZE = 10;
let allQueryPromises: Array<Promise<any>> = [];
let quantumQueries: Array<QuantumQuery> = [];
let queryIndex = 0;
/**
 * The multi-query data renderer is to be used for all data rendering where there is multiple data queries to load. It
 * takes care of loading data, loading metadata, cancelling/cleanup, loading display, and error handling.
 * @constructor
 */
export default function MultiQueryDataRenderer(props: Props) {
    const {dataConfig, loadCB, transforms} = props;
    const [batching, setBatching] = useState<boolean>(true);
    const [batchIndex, setBatchIndex] = useState<number>(0);
    const [percentageComplete, setPercentageComplete] = useState<number>(0);
    const [queries, setQueries] = useState<Array<QuantumQuery>>([]);
    const [err, setErr] = useState<string>('');
    const [noAccess, setNoAccess] = useState(false);
    const graphs = dataConfig.queries;

    async function executeQuantumQuery(graph: QuantumGraph, index: number, controller: AbortController): Promise<QuantumQuery> {
        const quantumQuery: QuantumQuery = {
            graph: graph as V2QuantumGraph,
            data: [],
            metadata: [],
            index
        };

        try {
            quantumQuery.data = await fetchData(graph, controller.signal);
        } catch (err: any) {
            if (err.message.startsWith(NoAccessErrMsg))
                setNoAccess(true);
            else
                handleError(err, setErr);
            throw err;
        }

        try {
            quantumQuery.metadata = await fetchMetadata(graph, controller.signal);
        } catch (err: any) {
            if (err.message.startsWith(NoAccessErrMsg))
                setNoAccess(true);
            else
                handleError(err, setErr);
            throw err;
        }

        return quantumQuery;
    }

    const graphBatch = useMemo(() => {
        allQueryPromises = [];
        quantumQueries = [];
        queryIndex = 0;
        setPercentageComplete(0);
        setBatchIndex(0);
        setBatching(true);

        let batchedGraphs: Array<Array<QuantumGraph>> = [];
        if (dataConfig.options.disableBatch) {
            batchedGraphs = [graphs];
        } else {
            let batch: Array<QuantumGraph> = [];

            let batchCount = 0;
            for (let i = 0; i < graphs.length; i++) {
                batch.push(graphs[i]);
                batchCount++;

                if (batchCount === BATCH_SIZE || i === graphs.length - 1) {
                    batchedGraphs.push(batch);
                    batch = [];
                    batchCount = 0;
                }
            }
        }

        return batchedGraphs;
    }, [graphs, dataConfig.options.disableBatch]);

    useEffect(() => {
        const controller = new AbortController();
        if (graphBatch.length > 0) {
            if (batchIndex < graphBatch.length) {
                for (const graph of graphBatch[batchIndex]) {
                    //eslint-disable-next-line no-loop-func
                    const promise = executeQuantumQuery(graph, queryIndex, controller).then(q => quantumQueries.push(q))
                        .catch(e => console.error('Error in quantum query: ', e));
                    allQueryPromises.push(promise);
                    queryIndex++;
                }

                Promise.allSettled(allQueryPromises).then(async promises => {
                    if (promises.length >= graphs.length) {
                        if (promises.every(p => p.status === 'fulfilled') && quantumQueries.length > 0) {
                            if (transforms && transforms.length > 0) {
                                for (const query of quantumQueries) {
                                    for (const transform of transforms) {
                                        const {metadata, data} = await transform!(query.metadata, query.data);
                                        query.metadata = metadata;
                                        query.data = data;
                                    }
                                }
                            }
                            quantumQueries = quantumQueries.sort((a, b) => a.index - b.index);
                            setQueries(quantumQueries);
                            setBatching(false);
                        }
                    } else {
                        setBatchIndex(batchIndex + 1);
                        if (promises.length > graphs.length)
                            setPercentageComplete(100);
                        else
                            setPercentageComplete((promises.length / graphs.length) * 100);
                    }
                });
            }
        }

        return () => {
            controller.abort();
        };
    }, [graphBatch, batchIndex, graphs.length, loadCB, transforms]);

    if (noAccess)
        return <NoAccessMsg />;
    if (err)
        return <ErrorMessage />;
    if (batching) {
        return <Modal title="Your data is loading . . .">
            <div className="flex space-x-8 m-8">
                <Loading />
                <div className="flex items-center">
                    Sometimes greatness takes time.
                    <br />
                    Please be patient while we load the data...
                </div>
            </div>
            <div style={{height: '25px', marginBottom: '10px'}}>
                <ProgressBar progress={percentageComplete} color="orange" border showPercentage />
            </div>
        </Modal>;
    }

    return React.createElement(props.children, {dataConfig, queries, loadCB});
}